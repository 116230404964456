import React, { useEffect, useState } from 'react';
import useEffectAfterFirstRender from '../../../../Hooks/useEffectAfterFirstRender';
import { Container, Modal, ModalBody } from 'reactstrap';
import classes from '../EngagementEmails.module.css';

import { getPreviousEngagementEmailsAction } from '../../../../Store/actions/trials/engagementEmailsAction';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import List from '../../../../Components/List/List';
import EmailPreview from '../EmailPreview/EmailPreview';
import CreateEmailNameModal from '../Modals/CreateEmailNameModal/CreateEmailNameModal';
import LoadMoreButton from '../../../../Components/LoadMoreButton/LoadMoreButton';
import ComponentLoading from '../../../../Components/ComponentLoading/ComponentLoading';
import ErrorMessage from '../../../../Components/ErrorMessage/ErrorMessage';
import NoResults from '../../../../Components/NoResults/NoResults';
import CloseModalButton from '../../../../Components/CloseModalButton/CloseModalButton';

import _ from 'lodash';

const PreviousEngagementEmailsCreate = ({ searchValue, setSearchValue }) => {
	const [ pageCounterForAPI, setPageCounterForAPI ] = useState(1);
	const dispatch = useDispatch();
	const { id } = useParams();
	useEffect(() => {
		if (pageCounterForAPI === 1 && previousEngagementEmailsFromAPI.length === 0)
		{
			dispatch(getPreviousEngagementEmailsAction({ trialID: id, currentPageNumber: pageCounterForAPI }));
		}
	}, []);

	const isFetchingPreviousEngagementEmails = useSelector(state => state.engagementEmails.isFetchingPreviousEngagementEmails);
	const previousEngagementEmailsFromAPI = useSelector((state) => state.engagementEmails.previousEngagementEmails);
	const previousEngagementEmailsErrorFromAPI = useSelector(
		(state) => state.engagementEmails.previousEngagementEmailsError
	);
	const isLastPageInPreviousEngagementEmails = useSelector(
		(state) => state.engagementEmails.isLastPageInPreviousEngagementEmails
	);

	useEffectAfterFirstRender(
		() => {
			dispatch(getPreviousEngagementEmailsAction({ trialID: id, currentPageNumber: pageCounterForAPI }));
		},
		[ pageCounterForAPI ]
	);

	const handleLoadMore = () => {
		setPageCounterForAPI((previousPageCounter) => previousPageCounter + 1);

		setSearchValue('');
	};

	const [ sortAlphabeticallyDetailsObject, setSortAlphabeticallyDetailsObject ] = useState({
		pathUsedToSortAlphabetically: 'user_template_name',
		order: 'asc'
	});
	const handleSortAlphabeticallyByTitle = (pathUsedToSortAlphabetically) => {
		if (sortAlphabeticallyDetailsObject.order === 'asc')
		{
			setSortAlphabeticallyDetailsObject({ pathUsedToSortAlphabetically, order: 'desc' });
		} else if (sortAlphabeticallyDetailsObject.order === 'desc')
		{
			setSortAlphabeticallyDetailsObject({ pathUsedToSortAlphabetically, order: 'asc' });
		}
	};

	const previousEngagementEmailsAlphabeticallySorted = _.orderBy(
		previousEngagementEmailsFromAPI,
		[ sortAlphabeticallyDetailsObject.pathUsedToSortAlphabetically ],
		[ sortAlphabeticallyDetailsObject.order ]
	);
	const filterPreviousEngagementEmails = previousEngagementEmailsAlphabeticallySorted.filter((item) => {
		if (item.user_template_name.toLowerCase().includes(searchValue.toLowerCase().trim()))
		{
			return true;
		}

		return false;
	});

	const [ engagementEmailData, setEngagementEmailData ] = useState({});
	const [ isCreateEmailNameModalOpen, setIsCreateEmailNameModalOpen ] = useState(false);
	const toggleCreateEmailNameModal = (engagementEmailData) => {
		if (engagementEmailData?.content_json)
		{
			setEngagementEmailData(engagementEmailData);
		}
		setIsCreateEmailNameModalOpen(previousIsCreateEmailNameModalOpen => !previousIsCreateEmailNameModalOpen);
	}

	const [ isEmailPreviewModalOpen, setIsEmailPreviewModalOpen ] = useState(false);
	function toggleEmailPreviewModal (engagementEmailData) {
		if (engagementEmailData.content_json)
		{
			setEngagementEmailData(engagementEmailData);
		}

		setIsEmailPreviewModalOpen(previousIsEmailPreviewModalOpen => !previousIsEmailPreviewModalOpen);
	}

	const actions = [
		{
			callback: toggleCreateEmailNameModal,
			link: false,
			icon: <i className="las la-pen" />
		},
		{
			callback: toggleEmailPreviewModal,
			link: false,
			icon: <i className="las la-eye" />
		}
	];

	return (
		<Container>
			<List
				list={ filterPreviousEngagementEmails }
				actions={ actions }
				sortAlphabeticallyByTitle={ handleSortAlphabeticallyByTitle }
				sortBy="user_template_name"
				view="previous-engagement-emails" />

			{ filterPreviousEngagementEmails.length === 0 && !isFetchingPreviousEngagementEmails ? (
				<NoResults />
			) : null }

			{ isFetchingPreviousEngagementEmails ? (
				<ComponentLoading 
					message="Loading..."
					removeHeight={ true } />
			) : (null) }

			{ previousEngagementEmailsErrorFromAPI ? (
				<ErrorMessage message={ previousEngagementEmailsErrorFromAPI } />
			) : (null) }

			{ !isLastPageInPreviousEngagementEmails && !isFetchingPreviousEngagementEmails ? (
				<LoadMoreButton handleClick={ handleLoadMore } />
			) : (null) }

			<CreateEmailNameModal
				engagementEmailData={ engagementEmailData }
				toggleModal={ toggleCreateEmailNameModal }
				isModalOpen={ isCreateEmailNameModalOpen }
				isPreviousEmail={ true } />

			<Modal
				centered
				isOpen={ isEmailPreviewModalOpen }
				toggle={ toggleEmailPreviewModal }
				backdrop="static">
				<CloseModalButton onClick={ toggleEmailPreviewModal } />

				<ModalBody className={ classes[ "email-preview-modal" ] }>
					<EmailPreview emailPreview={ engagementEmailData } />
				</ModalBody>
			</Modal>
		</Container>
	);
};

export default PreviousEngagementEmailsCreate;
