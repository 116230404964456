import React, { Component } from 'react';

import { Modal } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createCaseAction, getSurgeonsForCreatingAndUpdatingACaseAction } from '../../../Store/actions/casesAction';

import classes from './CreateCaseModal.module.css';
import Search from '../../../Utils/Search';

import ProfileImage from '../../../Utils/ProfileImage';
import getInitialsOfFirstAndLastName from '../../../Utils/getInitialsOfFirstAndLastName';
import AddAndEditVerbatim from '../AddAndEditVerbatim/AddAndEditVerbatim';
import OverlayHeaderForPopups from '../../../Components/OverlayHeaderForPopups/OverlayHeaderForPopups';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ErrorMessage from '../../../Components/ErrorMessage/ErrorMessage';
import AddPersonalDetails from '../MultipleStepsModalsForm/AddPersonalDetails/AddPersonalDetails';
import NoResults from '../../../Components/NoResults/NoResults';
import SelectSurgeon from '../Components/SelectSurgeon/SelectSurgeon';
import CloseModalButton from '../../../Components/CloseModalButton/CloseModalButton';

import { cloneDeep } from 'lodash';

class CreateCase extends Component {
	state = {
		selectedSurgeonsFromDropdown: [],
		procedureDetails: {
			procedureName: '',
			procedureDate: new Date(),
			quantity: '',
			yourCaseNote: '',
			utilizedICG: false
		},
		verbatims: [
			{
				customerName: '',
				verbatim: ''
			}
		],
		missingRequired: false,
		isAddPersonalDetailsModalOpen: false,
		searchValue: '',
		currentPageNumber: 1,
		isLoadingMore: false
	};

	handleProcedureDetailChange = (ev) => {
		this.setState({
			procedureDetails: {
				...this.state.procedureDetails,
				[ev.currentTarget.id]: ev.currentTarget.value
			}
		});
	};

	handleProcedureDateChange = (date) => {
		this.setState({
			procedureDetails: {
				...this.state.procedureDetails,
				procedureDate: date
			}
		});
	};

	formatDateToMonthDayYear = (date) => {
		return `${date.getMonth() + 1} - ${date.getDate()} - ${date.getFullYear()}`;
	};

	handleSubmit = (ev) => {
		ev.preventDefault();

		if (this.state.procedureDetails.procedureName !== '' || this.state.procedureDetails.quantity !== '') {
			let surgeonIDs = this.state.selectedSurgeonsFromDropdown.map(selectedSurgeon => selectedSurgeon.id);
			const formatData = {
				trialID: this.props.match.params.id,
				surgeonIDs: surgeonIDs,
				procedureName: this.state.procedureDetails.procedureName,
				procedureDate: this.formatDateToMonthDayYear(this.state.procedureDetails.procedureDate),
				quantity: this.state.procedureDetails.quantity,
				yourCaseNote: this.state.procedureDetails.yourCaseNote,
				utilizedICG: this.state.procedureDetails.utilizedICG,
				verbatims: this.state.verbatims
			};

			this.setState({ selectedSurgeonsFromDropdown: [] });

			this.props.createCase(formatData, this.props.toggleCreateCaseModal);
		} else {
			this.setState({ missingRequired: true });
		}
	};

	selectSurgeonsFromDropDown = (surgeon) => {
		let cloneSurgeons = cloneDeep(this.state.selectedSurgeonsFromDropdown);
		cloneSurgeons.push(surgeon);

		this.setState({ 
			searchValue: '',
			selectedSurgeonsFromDropdown: cloneSurgeons
		});
	}

	deselectSurgeon = (deselectedSurgeon) => {
		let cloneSelectedSurgeonsFromDropdown = cloneDeep(this.state.selectedSurgeonsFromDropdown);
		cloneSelectedSurgeonsFromDropdown = cloneSelectedSurgeonsFromDropdown.filter(surgeon => surgeon.id !== deselectedSurgeon.id);

		this.setState({ selectedSurgeonsFromDropdown: cloneSelectedSurgeonsFromDropdown });
	};

	verbatimsFromChildComponent = (verbatims) => {
		this.setState({ verbatims: verbatims });
	};

	liftIsLoadingMore = (isLoadingMore) => {
		this.setState({ isLoadingMore: isLoadingMore })
	};

	setSearchValueIntoStateAndFetchCaseSurgeons = (event) => {
		if (this.state.isLoadingMore) {
			return;
		}

		this.setState({
			searchValue: event.currentTarget.value,
		});
	}

	toggleAddPersonalDetailsModal = (event) => {
		if (event) {
			event.preventDefault();
		}

		this.setState(previousState => ({
			isAddPersonalDetailsModalOpen: !previousState.isAddPersonalDetailsModalOpen
		}));
	}

	fetchSurgeons = () => {
		const trialID = this.props.match.params.id;
		this.props.getSurgeonsForCreatingAndUpdatingACase({ trialID, currentPageNumber: this.state.currentPageNumber, keyword: this.state.searchValue });
	}

	handleToggleInput = (event, surgeon) => {
		let input = event.currentTarget.querySelector('input');
		if (!input?.disabled) input.checked = !input.checked;

		this.deselectSurgeon(surgeon);
	}

	render() {
		const { createCaseModalIsOpen, toggleCreateCaseModal } = this.props;

		return (
			<Modal
				id="create-case-modal"
				className={[ classes['modal--margin'] ].join(' ')}
				isOpen={createCaseModalIsOpen}
				toggle={toggleCreateCaseModal}
				backdrop="static"
			>
				<OverlayHeaderForPopups
					title="Add case"
					togglePopup={toggleCreateCaseModal}
					buttonSubmitCallback={false}
				/>

				<div className={classes['background-color-gray']}>
					<form id="form-create-case" className={classes['form-create-case']}>
						<div className={[ classes['card--checkbox'], classes['card'] ].join(' ')}>
							<div
								className={[
									classes['card__header'],
									this.state.missingRequired && !this.state.selectedSurgeonsFromDropdown.length > 0
										? classes['requiredError']
										: ''
								].join(' ')}
							>
								<h2 className={classes['card__title']}>Select Surgeon(s)</h2>
								<div>
									<button
										onClick={ this.toggleAddPersonalDetailsModal }
										className="modal-button">
										<img
											src="/images/icon-plus.png"
											className="modal-button-image"
											alt="plus icon" />
									</button>
									<span className={ classes[ 'card__title--amount-selected' ] }>
										{ this.state.selectedSurgeonsFromDropdown.length } selected
									</span>
								</div>
							</div>
							<div className={classes['search-container']}>
								<Search 
									searchingValue={ this.state.searchValue } 
									handleInputChange={ this.setSearchValueIntoStateAndFetchCaseSurgeons }
									disabled={ this.state.isLoadingMore } />
							</div>

							<div className={classes['card-form-container']}>
								<SelectSurgeon
									selectSurgeonsFromDropDown={ this.selectSurgeonsFromDropDown }
									searchValue={ this.state.searchValue }
									liftIsLoadingMore={ this.liftIsLoadingMore }
									selectedSurgeons={ this.state.selectedSurgeonsFromDropdown } />
								{ this.state.selectedSurgeonsFromDropdown.length ? (
									this.state.selectedSurgeonsFromDropdown.map(surgeon => {
										return (
											<div key={surgeon.id}>
												<label
													className="hidden"
													htmlFor="person">
														{ surgeon.people } information
												</label>
												<div
													className={ classes[ 'checkbox' ] }
													onClick={ (event) => this.handleToggleInput(event, surgeon) }>
													<ProfileImage
														profile={ {
															profileImage: getInitialsOfFirstAndLastName(surgeon.people)
														} }
														cb={ null }
														profileImageClasses="profile__image--small"
													/>
													<div className={ classes[ 'checkbox__information-contain' ] }>
														<div className={ classes[ 'checkbox__name' ] }>{ surgeon.people }</div>
														<div className={ classes[ 'checkbox__specialty' ] }>
															{ surgeon.specialty }
														</div>
														<div className={ classes[ 'checkbox__case' ] }>
															{ surgeon.surgeon_case_count } cases | last case:{ ' ' }
															{ surgeon.last_case_date }
														</div>
													</div>
													<input
														defaultChecked={ true }
														id="person"
														name="person"
														className="hidden hidden-checkbox-input"
														type="checkbox" />
														
													<div className="input-checkmark-circle input-checkmark-circle--small ml-auto">
														<i className="las la-check" />
													</div>
												</div>
											</div>
										);
									})
								) : (this.state.selectedSurgeonsFromDropdown.length === 0 ? (
										<NoResults message="no surgeons selected" />
									) : (null) 
								) }
							</div>
						</div>

						<div className={classes['card']}>
							<div className={classes['card__header']}>
								<h2 className={classes['card__title']}>Add procedure details</h2>
							</div>
							<div className={classes['card-form-container']}>
								<div
									className={
										this.state.missingRequired &&
										this.state.procedureDetails.procedureName === '' ? (
											classes['requiredError']
										) : (
											''
										)
									}
								>
									<label className={classes['label']} htmlFor="procedureName">
										PROCEDURE NAME *
									</label>
									<input
										className={classes['input']}
										type="text"
										name="procedureName"
										id="procedureName"
										onChange={this.handleProcedureDetailChange}
										placeholder="procedure name"
										value={this.state.procedureDetails.procedureName}
									/>
								</div>
								<div
									id="procedure-details__date-picker"
									className={
										this.state.missingRequired &&
										this.state.procedureDetails.procedureDate === '' ? (
											classes['requiredError']
										) : (
											''
										)
									}
								>
									<label className={classes['label']} htmlFor="procedureDate">
										PROCEDURE DATE *
									</label>
									<div className="position-relative date-inputs-container">
										<DatePicker
											className={ classes[ 'input' ] }
											name="procedureDate"
											id="procedureDate"
											onChange={ this.handleProcedureDateChange }
											placeholder="procedure date"
											selected={ this.state.procedureDetails.procedureDate }
										/>
										<i className="las la-calendar input__icon input__icon--position"></i>
									</div>
								</div>
								<div
									className={
										this.state.missingRequired && this.state.procedureDetails.quantity === '' ? (
											classes['requiredError']
										) : (
											''
										)
									}
								>
									<label className={classes['label']} htmlFor="quantity">
										QUANTITY *
									</label>
									<input
										className={classes['input']}
										type="number"
										name="quantity"
										id="quantity"
										value={this.state.procedureDetails.quantity}
										onChange={this.handleProcedureDetailChange}
										placeholder="quantity"
									/>
								</div>
								<div>
									<label className={classes['label']} htmlFor="yourCaseNote">
										YOUR CASE NOTES
									</label>
									<textarea
										type="textarea"
										name="text"
										id="yourCaseNote"
										className={classes['input--textarea']}
										placeholder="your case notes"
										value={this.state.procedureDetails.yourCaseNote}
										onChange={this.handleProcedureDetailChange}
									/>
								</div>
								<div>
									<input
									className={classes['inputCheckbox']}
									id="utilizedICG"
									type="checkbox"
									name="utilizedICG"
									checked={this.state.procedureDetails.utilizedICG}
									onChange={e=> this.setState({procedureDetails :{...this.state.procedureDetails, utilizedICG: e.target.checked}})}></input>
									<label className={classes['label']} htmlFor="utilizedICG">UTILIZED ICG</label>
								</div>
							</div>
						</div>

						<AddAndEditVerbatim
							title="Add verbatims"
							verbatimsFromEditOrAddPage="add"
							updateVerbatim={this.verbatimsFromChildComponent}
							verbatims={this.state.verbatims}
						/>

						<button className={classes['button']} onClick={this.handleSubmit}>
							SUBMIT
						</button>

						{ this.props.postCaseError ? (
							<ErrorMessage message={ this.props.postCaseError } />
						) : (null) }
					</form>

					<Modal 
						isOpen={ this.state.isAddPersonalDetailsModalOpen } 
						toggle={ this.toggleAddPersonalDetailsModal }
						centered
						backdrop="static">
						<CloseModalButton onClick={ this.toggleAddPersonalDetailsModal } />

						<AddPersonalDetails 
							accountID={ this.props.accountID } 
							refreshPeople={ this.fetchSurgeons }
							addSurgeon={ true } 
							toggleModal={ this.toggleAddPersonalDetailsModal } />
					</Modal>
				</div>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		isFetchingCaseSurgeons: state.cases.isFetchingCaseSurgeons,
		isLastPageInSurgeonsForCreatingACase: state.cases.isLastPageInSurgeonsForCreatingACase,

		postCaseError: state.cases.postCaseError
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		createCase: (formData, toggleCreateCaseModal) => {
			dispatch(createCaseAction(formData, toggleCreateCaseModal));
		},

		getSurgeonsForCreatingAndUpdatingACase: ({ trialID, currentPageNumber, keyword }) => {
			dispatch(getSurgeonsForCreatingAndUpdatingACaseAction({ trialID, currentPageNumber, keyword }));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateCase));
