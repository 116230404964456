import React from 'react';

import classes from './PeopleList.module.css';

import ProfileImage from '../../../../Utils/ProfileImage';
import CreateRatingCircles from '../../../../Utils/CreateRatingCircles/CreateRatingCircles';
import NoResults from '../../../../Components/NoResults/NoResults';

import { useDispatch } from 'react-redux';
import { deleteSurgeonAction, deleteStakeholderAction } from '../../../../Store/actions/trialsAction';
import { useParams } from 'react-router-dom';

const PeopleList = ({ peopleArray, currentlyEditing, type }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const handleRemovePersonClick = person => {
        if (type === "Surgeons")
        {
            return dispatch(deleteSurgeonAction({ trialID: id, personID: person.id }));
        }
        return dispatch(deleteStakeholderAction({ trialID: id, personID: person.id }));
    };

    if (peopleArray.length === 0)
    {
        return (
            <NoResults />
        );
    }

    return (
        <ul className={ classes[ "list" ] }>
            {
                peopleArray ? (peopleArray.map(person => {
                    return (
                        <li
                            key={ person.id }
                            className={ classes[ "list-item" ] }>
                            <div className={ classes[ "list-item__profile-image" ] }>
                                <ProfileImage profile={ person } cb={ null } profileImageClasses={ [ "profile__image--small", classes[ "list-item__profile-image" ] ].join(" ") } />
                            </div>
                            <div className={ classes[ "list-item__information" ] }>
                                <div className={ classes[ "list-item__heading" ] }>{ person.name }</div>
                                <div className="circles">
                                    <CreateRatingCircles amountOfCircles={ person.rating } classesToAdd={ classes[ "circle" ] } />
                                </div>
                                <div className={ classes[ "list-item__subheading" ] }>
                                    { person.title } | { person.count } CASES COMPLETED
                                </div>
                            </div>
                            <button
                                onClick={ () => handleRemovePersonClick(person) }
                                className={ classes[ "list-item__button" ] }>
                                <i className={ `las la-times-circle ${ currentlyEditing ? (null) : ("hidden") }` }></i>
                            </button>
                        </li>
                    );
                })
                ) : (null)
            }
        </ul>
    )
}

export default PeopleList;