import React, { Component } from 'react';

import listClasses from '../List/List.module.css';
import classes from './ListUsingIndexedDB.module.css';

import { Link, withRouter } from 'react-router-dom';

import ListStar from './ListStar/ListStar';
import ListSort from '../List/ListSort/ListSort';
import NoResults from '../NoResults/NoResults';
import ComponentLoading from '../ComponentLoading/ComponentLoading';

import { indexedDBAPI } from '../../indexedDBAPI';

import _ from 'lodash';

class ListUsingIndexedDB extends Component {
	state = {
		STORE_NAME: 'COMPETITIVE_INTEL',
		arrayOfURLSInIndexedDB: [],
		arrayOfItemsFromIndexedDB: [],
		sortAlphabeticallyDetailsObject: { pathUsedToSortAlphabetically: 'content_name', order: 'asc' }
	};

	componentDidMount() {
		indexedDBAPI.openIndexedDB((arrayFromIndexedDB) => {
			let arrayOfItemsFromIndexedDB = arrayFromIndexedDB.map((item) => item.data);

			let arrayOfURLSInIndexedDB = arrayFromIndexedDB.map((res) => {
				return res.path;
			});

			this.setState({
				arrayOfURLSInIndexedDB: arrayOfURLSInIndexedDB,
				arrayOfItemsFromIndexedDB: arrayOfItemsFromIndexedDB
			});
		}, this.state.STORE_NAME);
	}

	storeContentIntoIndexedDB = (ev, item) => {
		ev.stopPropagation();

		let element = ev.currentTarget.querySelector('#saveForOffline');

		if (element.textContent === 'Available Offline') {
			return;
		}

		element.textContent = 'downloading...';

		fetch(item.file_path)
			.then(function(res) {
				return res.blob();
			})
			.then((blob) => {
				item.blob = blob;

				indexedDBAPI.storeGetResponseIntoIndexedDB(item, item.file_path, this.state.STORE_NAME);
				element.textContent = 'Available Offline';
			});
	};

	redirectToPDF = (item) => {
		indexedDBAPI.retrieveGetResponseFromIndexedDB(
			item.file_path,
			(results) => {
				if (results) {
					let url = URL.createObjectURL(results.data.blob);
					let link = `/pdf-viewer/?file=${url}&filePath=${item.file_path}&title=${item.content_name}&page=${this
						.props.page}`;

					return this.props.history.push(link);
				} else {
					let link = `/pdf-viewer/?file=${item.file_path}&title=${item.content_name}&page=${this.props.page}`;
					return this.props.history.push(link);
				}
			},
			this.state.STORE_NAME
		);
	};

	redirectToFile = (item) => {
		indexedDBAPI.retrieveGetResponseFromIndexedDB(
			item.file_path,
			(results) => {
				if (results) {
					let url = URL.createObjectURL(results.data.blob);
					return (window.location = url);
				} else {
					let url = item.file_path;
					return (window.location = url);
				}
			},
			this.state.STORE_NAME
		);
	};

	isFileDownloaded = (item) => {
		if (this.state.arrayOfURLSInIndexedDB.includes(item.file_path)) {
			return true;
		}
		return false;
	};

	handleSortItemsInIndexedDBAlphabeticallyByTitle = (pathUsedToSortAlphabetically) => {
		const sortAlphabeticallyDetailsObject = JSON.parse(JSON.stringify(this.state.sortAlphabeticallyDetailsObject));
		if (sortAlphabeticallyDetailsObject.order === 'asc') {
			this.setState({ sortAlphabeticallyDetailsObject: { pathUsedToSortAlphabetically, order: 'desc' } });
		} else if (sortAlphabeticallyDetailsObject.order === 'desc') {
			this.setState({ sortAlphabeticallyDetailsObject: { pathUsedToSortAlphabetically, order: 'asc' } });
		}
	};

	sortByFavourite = () => {
		let enabled = false;
		if (this.state.sortAlphabeticallyDetailsObject.pathUsedToSortAlphabetically === 'is_favourite') {
			this.props.sortAlphabeticallyByTitle('content_name');
			this.setState({
				sortAlphabeticallyDetailsObject: { pathUsedToSortAlphabetically: 'content_name', order: 'asc' }
			});
			enabled = false;
		} else {
			this.props.sortAlphabeticallyByTitle('is_favourite');
			this.setState({
				sortAlphabeticallyDetailsObject: { pathUsedToSortAlphabetically: 'is_favourite', order: 'desc' }
			});
			enabled = true;
		}
		//console.log(enabled);
		return enabled;
	};

	render() {
		if (this.props.isLoading) {
			return <ComponentLoading message="Loading..." />;
		}

		let list = this.props.list;
		let sortBy = this.props.sortBy;
		let sortAlphabeticallyByTitle = this.props.sortAlphabeticallyByTitle;

		if (this.props.isDownloadedView) {
			list = _.orderBy(
				this.state.arrayOfItemsFromIndexedDB,
				[ this.state.sortAlphabeticallyDetailsObject.pathUsedToSortAlphabetically ],
				[ this.state.sortAlphabeticallyDetailsObject.order ]
			);
			list = list.filter((item) => {
				if (item.content_name.toLowerCase().includes(this.props.searchingValue.toLowerCase().trim())) {
					return true;
				}

				return false;
			});
			sortAlphabeticallyByTitle = this.handleSortItemsInIndexedDBAlphabeticallyByTitle;
			sortBy = 'content_name';
		}
		return (
			<div className={classes['list-container']}>
				{list.length ? (
					<div className={classes['list-actions-container']}>
						{/* <ListStar sortByFavorite={this.sortByFavourite} /> */}
						<ListSort sortBy={sortBy} sortAlphabeticallyByTitle={sortAlphabeticallyByTitle} />
					</div>
				) : null}
				{this.props.isDownloadedView && list.length === 0 ? (
					<NoResults />
				) : (
					<ul className={classes['list']}>
						{list.map((item) => {
							//console.log('LIST_ITEM', item);
							let fileType = item.content_type;
							if (
								item.content_type === 'pdf' ||
								(item.content_type === 'files' && item.file_name.endsWith('.pdf'))
							) {
								fileType = 'PDF';
							}

							if (fileType === 'PDF') {
								return item.path || item.file_path ? (
									<li
										onClick={() => this.redirectToPDF(item)}
										className={classes['list__item']}
										key={item.id}
									>
										<div className={classes['list__item-image-container']}>
											<i
												className={[ classes['list__item-icon'], 'las la-file-pdf' ].join(' ')}
											/>
											{/* item.is_favourite === '1' && (
												<div className={classes['list__item-favorite-indicator']}>
													<i className="las la-star" />
												</div>
											) */}
										</div>
										<div className={classes['list__item-content']}>
											<div className={classes['list__item-name']}>{item.content_name}</div>
											<div className="list__item-information">
												modified {item.updated_date} |{' '}
												{this.isFileDownloaded(item) ? (
													<div className="list__item-information">
														<img
															className="list__text-inline-image"
															src="/images/icon-cloud-download.png"
															alt="cloud icon"
														/>
														<span>Available Offline</span>
													</div>
												) : (
													<button
														onClick={(ev) => this.storeContentIntoIndexedDB(ev, item)}
														className="list__item-information"
													>
														<img
															className="list__text-inline-image"
															src="/images/icon-cloud-download.png"
															alt="cloud icon"
														/>
														<span id="saveForOffline">Save for offline</span>
													</button>
												)}
											</div>
										</div>
										<i
											className={[ listClasses['link__item-icon'], 'las la-angle-right' ].join(
												' '
											)}
										/>
									</li>
								) : (
									''
								);
							} else if (fileType === 'folder') {
								return (
									<li key={item.id}>
										<Link
											className={classes['list__item']}
											to={'/competitiveintel/folder/' + item.id + '/' + item.content_name}
										>
											<div className={classes['list__item-image-container']}>
												<img
													className={classes['list__item-image']}
													src="/images/icon-folder.jpg"
													alt="folder icon"
												/>
												{/* item.is_favourite === '1' && (
													<div className={classes['list__item-favorite-indicator']}>
														<i className="las la-star" />
													</div>
												) */}
											</div>
											<div className={classes['list__item-content']}>
												<div className={classes['list__item-name']}>{item.content_name}</div>
												<div className="list__item-information">
													modified {item.updated_date}
												</div>
											</div>
											<i
												className={[
													listClasses['link__item-icon'],
													'las la-angle-right'
												].join(' ')}
											/>
										</Link>
									</li>
								);
							} else {
								return (
									<li
										onClick={() => this.redirectToFile(item)}
										className={classes['list__item']}
										key={item.id}
									>
										<div className={classes['list__item-image-container']}>
											<i
												className={[ classes['list__item-icon'], 'las la-file-alt' ].join(' ')}
											/>
											{item.is_favourite === '1' && (
												<div className={classes['list__item-favorite-indicator']}>
													<i className="las la-star" />
												</div>
											)}
										</div>
										<div className={classes['list__item-content']}>
											<div className={classes['list__item-name']}>{item.content_name}</div>
											<div className="list__item-information">
												modified {item.updated_date} |
												{this.isFileDownloaded(item) ? (
													<div className="list__item-information">
														<img
															className="list__text-inline-image"
															src="/images/icon-cloud-download.png"
															alt="cloud icon"
														/>
														<span>Available Offline</span>
													</div>
												) : (
													<button
														onClick={(ev) => this.storeContentIntoIndexedDB(ev, item)}
														className="list__item-information"
													>
														<img
															className="list__text-inline-image"
															src="/images/icon-cloud-download.png"
															alt="cloud icon"
														/>
														<span id="saveForOffline">Save for offline</span>
													</button>
												)}
											</div>
										</div>
										<i
											className={[ listClasses['link__item-icon'], 'las la-angle-right' ].join(
												' '
											)}
										/>
									</li>
								);
							}
						})}
					</ul>
				)}
			</div>
		);
	}
}

export default withRouter(ListUsingIndexedDB);
