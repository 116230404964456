import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Search from '../Utils/Search';
import { getGroup } from '../Data/database';

import { useParams } from 'react-router-dom';
import { getTrialPeopleAction } from '../Store/actions/trialsAction';
import { useDispatch, useSelector } from 'react-redux';
import useEffectAfterFirstRender from '../Hooks/useEffectAfterFirstRender';

import AddPersonalDetails from './Trial/MultipleStepsModalsForm/AddPersonalDetails/AddPersonalDetails';
import ComponentLoading from '../Components/ComponentLoading/ComponentLoading';
import ErrorMessage from '../Components/ErrorMessage/ErrorMessage';
import LoadMoreButton from '../Components/LoadMoreButton/LoadMoreButton';
import TableTrialsPeople from '../Components/TableTrialsPeople/TableTrialsPeople';
import { ListGroupTrialsPeople } from '../Components/ListGroup';
import { Modal, Container } from 'reactstrap';
import NoResults from '../Components/NoResults/NoResults';
import CloseModalButton from '../Components/CloseModalButton/CloseModalButton';

const TrialsPeople = ({ accountID }) => {
	const [ content, setContent ] = useState([]);
	const { id } = useParams();
	const [ pageCounterForAPI, setPageCounterForAPI ] = useState(1);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getTrialPeopleAction({ trialID: id, currentPageNumber: pageCounterForAPI }));
	}, []);

	useEffectAfterFirstRender(
		() => {
			dispatch(getTrialPeopleAction({ trialID: id, currentPageNumber: pageCounterForAPI }));
		},
		[ pageCounterForAPI ]
	);

	const handleLoadMore = () => {
		setPageCounterForAPI((previousPageCounterForAPI) => previousPageCounterForAPI + 1);

		setSearchValue('');
	};

	let trialPeople = useSelector((state) => state.trials.trialPeople);
	let isLastPageInTrialPeople = useSelector((state) => state.trials.isLastPageInTrialPeople);
	const trialPeopleError = useSelector((state) => state.trials.trialPeopleError);
	const isFetchingTrialPeople = useSelector((state) => state.trials.isFetchingTrialPeople);

	const mapPeople = (trialPeople) =>{
		const peopleStakeholders = trialPeople
			? trialPeople
				.filter((person) => {
					return person.type === 'stakeholder';
				})
				.map((person) => {
					return {
						category: person.title,
						firstName: person.first_name,
						group: { id: '99', name: 'stakeholders' },
						id: person.id,
						lastName: person.last_name,
						numberOfCases: person.count,
						rating: person.rating,
						title: person.title,
						profileImage: person.profile || person.profile_image,
						name: `${ person.first_name } ${ person.last_name }`,
						trialID: id
					};
				})
			: [];

		const peopleSurgeons = trialPeople
			? trialPeople
				.filter((person) => {
					return person.type === 'surgeon';
				})
				.map((person) => {
					return {
						category: person.title,
						firstName: person.first_name,
						group: { id: '98', name: 'surgeons' },
						id: person.id,
						lastName: person.last_name,
						numberOfCases: person.count,
						rating: person.rating,
						title: person.title,
						profileImage: person.profile || person.profile_image,
						name: `${ person.first_name } ${ person.last_name }`,
						trialID: id
					};
				})
			: [];

		return peopleStakeholders.concat(peopleSurgeons);
	}
	
	useEffect(
		() => {
			setContent(mapPeople(trialPeople));
		},
		[ trialPeople ]
	);

	const trialsPeople = content || [];

	const group = getGroup();
	const [ selectedCategory, setSelectedCategory ] = useState(group[0]);
	const [ sortColumn, setSortColumn ] = useState({ path: 'name', order: 'asc' });

	const handleCategorySelect = (category) => {
		setSelectedCategory(category);
	};

	const handleSort = (path) => {
		if (sortColumn.path === path) {
			sortColumn.order === 'asc' ? setSortColumn({ path, order: 'desc' }) : setSortColumn({ path, order: 'asc' });
		} else {
			sortColumn.path = path;
			setSortColumn({ path, order: 'asc' });
		}

		const sortedPeopleInTrials = _.orderBy(trialsPeople, [ sortColumn.path ], [ sortColumn.order ]);

		setContent(sortedPeopleInTrials);
	};

	const filteredPeopleInTrials =
		selectedCategory && selectedCategory.id
			? trialsPeople.filter((i) => i.group.id === selectedCategory.id)
			: trialsPeople;

	const [ searchValue, setSearchValue ] = useState('');
	const filterPeopleInTrialsBySearch = (event) => {
		let filteredPeopleInTrialsArray = trialPeople.filter((personInTrials) => {
			return personInTrials.name.toLowerCase().includes(event.currentTarget.value.toLowerCase().trim());
		});

		setSearchValue(event.currentTarget.value)
		setContent(mapPeople(filteredPeopleInTrialsArray));
	};

	const [ isModalOpen, setIsModalOpen ] = useState(false);
	const toggleModal = () => setIsModalOpen((previousIsModalOpen) => !previousIsModalOpen);

	return (
		<div>
			<Container className="tab-title-main">
				<button onClick={toggleModal} className="open-modal-button">
					<img className="open-modal-button__image" src="/images/icon-plus.png" alt="plus symbol icon" />
				</button>

				<h1 className="section-title">
					People <span className="lt-grey">in my trials</span>
				</h1>
				<div className="nav-pagelink">
					<ListGroupTrialsPeople
						items={group}
						onItemSelect={handleCategorySelect}
						selectedItem={selectedCategory}
					/>
				</div>
				<div className="hr-bk-expand-ml-mr-15" />
				<Search
					searchingValue={ searchValue } 
					handleFilter={ isFetchingTrialPeople ? (null) : (filterPeopleInTrialsBySearch) }
					disabled={ isFetchingTrialPeople } />
			</Container>
			{ isFetchingTrialPeople ? (
				<ComponentLoading message="Loading..." />
			) : (
				<React.Fragment>
					<TableTrialsPeople peopleFiltered={ filteredPeopleInTrials } onSort={ handleSort } />

					{ trialPeopleError ? (
						<ErrorMessage message={ trialPeopleError } />
					) : (null) }

					{ filteredPeopleInTrials.length === 0 ? (
						<NoResults />
					) : (null) }

					{ !isLastPageInTrialPeople && !isFetchingTrialPeople ? (
						<LoadMoreButton handleClick={ handleLoadMore } />
					 ) : (null) }
				</React.Fragment>
			) }

			<Modal 
				isOpen={ isModalOpen }
				toggle={ toggleModal }
				centered
				backdrop="static">
				<CloseModalButton onClick={ toggleModal } />

				<AddPersonalDetails accountID={accountID} toggleModal={toggleModal} />
			</Modal>
		</div>
	);
};

export default TrialsPeople;
