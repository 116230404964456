import React from 'react';
import classes from './AddChecklist.module.css';
import { postChecklistAction } from '../../../../Store/actions/checklistsAction';
import { useDispatch, useSelector } from 'react-redux';
import useHandleSubmit from '../../../../Hooks/useHandleSubmit';

const AddChecklist = ({ toggleModal, trialID }) => {
	const dispatch = useDispatch();

	const handleCancelClick = (ev) => {
		ev.preventDefault();
		toggleModal();
	};

	const handleChecklistSubmit = () => {
		//dispatch(postStakeholderAction(formData));
		dispatch(postChecklistAction({ trialID: trialID, groupName: inputs.groupName }));
		toggleModal();
	};

	const { inputs, handleInputChange, handleSubmit } = useHandleSubmit({
		callback: handleChecklistSubmit,
		initialState: {
			groupName: ''
		}
	});

	return (
		<form className={classes['form']} onSubmit={handleSubmit}>
			<label className={classes['form__label']} htmlFor="addChecklist">
				Add a checklist
			</label>
			<input
				className={classes['form__input']}
				type="text"
				onChange={handleInputChange}
				value={inputs.groupName}
				placeholder="Name your checklist"
				name="groupName"
				id="groupName"
			/>
			<div className={classes['form__buttons-container']}>
				<button
					onClick={handleCancelClick}
					className={[ classes['form__button'], classes['form__button--cancel'] ].join(' ')}
				>
					cancel
				</button>
				<button className={[ classes['form__button'], classes['form__button--submit'] ].join(' ')}>
					submit
				</button>
			</div>
		</form>
	);
};

export default AddChecklist;
