import * as ActionTypes from './ActionTypes';

import API from '../../API/API';

export const getUserCountersAction = () => {
    return (dispatch) => {
        const parameters = {
            user_id: localStorage.getItem('user_id'),
        };

        API().post('/api/v7/trial/usersCounterv5', parameters)
            .then(res => {
                return dispatch({ type: ActionTypes.GET_USER_COUNTERS_SUCCESS, userCounters: res.data.data })
            })
            .catch(err => {
                console.log(err)
                return dispatch({ type: ActionTypes.GET_USER_COUNTERS_ERROR, userCountersError: err.message });
            })
    }
}