import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom"
import { Container } from "reactstrap";
import Search from "../Utils/Search";
import TableTalkTrk from "../Components/TableTalkTrk";
import _ from "lodash";

import List from '../Components/List/List';
import ErrorMessage from '../Components/ErrorMessage/ErrorMessage';
import NoResults from '../Components/NoResults/NoResults';


const TalkTracks = (props) => {
    const [ sortColumn, setSortColumn ] = useState({ path: "name", order: "asc" });

    const handleSort = path => {
        if (sortColumn.path === path)
        {
            (sortColumn.order === "asc")
                ? setSortColumn({ path, order: "desc" })
                : setSortColumn({ path, order: "asc" });
        } else
        {
            sortColumn.path = path;
            setSortColumn({ path, order: "asc" })
        }
    }

    const { stakeholdersFromAPI, stakeholdersError, isFetchingStakeholders } = props;

    const filterStakeholdersBySearch = () => {
        if (searchValue === '') {
            return stakeholdersFromAPI
        }
        
        return stakeholdersFromAPI.filter(stakeholder => {
            return stakeholder.name.toLowerCase().includes(searchValue.toLowerCase().trim());
        });
    }

    const [ searchValue, setSearchValue ] = useState('');
    const handleInputChange = (event) => {
        setSearchValue(event.currentTarget.value);
    }

    const sortedStakeholders = _.orderBy(filterStakeholdersBySearch(), [ sortColumn.path ], [ sortColumn.order ]);

    return (
        <>
            <Container className="tab-title-main">
                <h1 className="section-title">Talk Tracks</h1>
                <div className="nav-pagelink">
                    <Link to={ "/talktracks/specialty" } className="pagelink-sml inactive mr-3half" >
                        by Specialty
                        </Link>
                    <Link to={ "/talktracks/stakeholder" } className="pagelink-sml active">
                        by Stakeholder
                        </Link>
                </div>
                <div className="hr-bk-expand-ml-mr-15" />
                <Search handleInputChange={ handleInputChange } />
            </Container>

            <Container>
                <List
                    list={ sortedStakeholders }
                    actions={ null }
                    sortAlphabeticallyByTitle={ handleSort }
                    sortBy="name"
                    view="talk-tracks"
                    isLoading={ isFetchingStakeholders } />

                { stakeholdersError ? (
                    <ErrorMessage message={ stakeholdersError } />
                ) : (null) }

                { !isFetchingStakeholders && sortedStakeholders.length === 0 ? (
                    <NoResults />
                ) : (null) }
            </Container>
        </>
    );
}

export default TalkTracks;
