import * as ActionTypes from './ActionTypes';

import API from '../../API/API';

export const getChecklistsAction = (trialID) => {
	return (dispatch, getState) => {
		const parameters = {
			user_id: localStorage.getItem('user_id'),
			trial_id: trialID
		};

		dispatch({ type: ActionTypes.REQUEST_GET_CHECKLISTS });

		API()
			.post('/api/v7/checklist/gettrialchecklist', parameters)
			.then((res) => {
				if (res.data.message === 'No record found.') {
					return dispatch({ type: ActionTypes.GET_CHECKLISTS_SUCCESS, getChecklists: [] });
				}

				return dispatch({ type: ActionTypes.GET_CHECKLISTS_SUCCESS, getChecklists: res.data.data });
			})
			.catch((err) => {
				console.log(err);
				return dispatch({ type: ActionTypes.GET_CHECKLISTS_ERROR, getChecklistsError: err.message });
			});
	};
};

export const postChecklistAction = ({ trialID, groupName }) => {
	return (dispatch, getState) => {
		const parameters = {
			user_id: localStorage.getItem('user_id'),
			trial_id: trialID,
			group_name: groupName
		};

		API()
			.post('/api/v7/checklist/addchecklist', parameters)
			.then((res) => {
				dispatch(getChecklistsAction(trialID));
				return dispatch({ type: ActionTypes.POST_CHECKLIST_SUCCESS });
			})
			.catch((error) => {
				console.log(error);
				return dispatch({ type: ActionTypes.POST_CHECKLIST_ERROR, postChecklistError: error.message });
			});
	};
};

export const putChecklistAction = ({ trialID, checklistID, name }) => {
	return (dispatch, getState) => {
		const parameters = {
			id: checklistID,
			name: name
		};

		API()
			.post('/api/v7/checklist/editchecklist', parameters)
			.then((res) => {
				console.log(trialID);
				dispatch(getChecklistsAction(trialID));
				return dispatch({ type: ActionTypes.PUT_CHECKLIST_SUCCESS });
			})
			.catch((error) => {
				console.log(error);
				return dispatch({ type: ActionTypes.PUT_CHECKLIST_ERROR, putChecklistError: error.message });
			});
	};
};

export const deleteChecklistAction = ({ trialID, checklistID }) => {
	return (dispatch, getState) => {
		const parameters = {
			id: checklistID
		};

		API()
			.post('api/v7/checklist/deletechecklist', parameters)
			.then((res) => {
				dispatch(getChecklistsAction(trialID));
				return dispatch({ type: ActionTypes.DELETE_CHECKLIST_SUCCESS });
			})
			.catch((error) => {
				console.log(error);
				return dispatch({ type: ActionTypes.DELETE_CHECKLIST_ERROR, deleteChecklistError: error.message });
			});
	};
};

export const postChecklistItemAction = ({ trialID, title, groupID, description = '', dueDate = '' }) => {
	return (dispatch, getState) => {
		const parameters = {
			user_id: localStorage.getItem('user_id'),
			trial_id: trialID,
			title: title,
			group_id: groupID,
			description: description,
			due_date: dueDate
		};

		API()
			.post('/api/v7/checklist/addchecklistitem', parameters)
			.then((res) => {
				dispatch(getChecklistsAction(trialID));
				return dispatch({ type: ActionTypes.POST_CHECKLIST_ITEM_SUCCESS });
			})
			.catch((error) => {
				console.log(error);
				return dispatch({ type: ActionTypes.POST_CHECKLIST_ITEM_ERROR, postChecklistItemError: error.message });
			});
	};
};

export const deleteChecklistItemAction = ({ checklistID, onDelete }) => {
	return (dispatch, getState) => {
		const parameters = {
			id: checklistID
		};

		API()
			.post('/api/v7/checklist/deletechecklistitem', parameters)
			.then((res) => {
				onDelete();
				return dispatch({ type: ActionTypes.DELETE_CHECKLIST_ITEM_SUCCESS });
			})
			.catch((error) => {
				console.log(error);
				return dispatch({
					type: ActionTypes.DELETE_CHECKLIST_ITEM_ERROR,
					deleteChecklistItemError: error.message
				});
			});
	};
};

export const putChecklistItemAction = ({ trialID, title, groupID, itemID, description = '', dueDate = '' }) => {
	return (dispatch, getState) => {
		const parameters = {
			user_id: localStorage.getItem('user_id'),
			trial_id: trialID,
			title: title,
			group_id: groupID,
			item_id: itemID,
			description: description,
			due_date: dueDate
		};

		API()
			.post('/api/v7/checklist/addchecklistitem', parameters)
			.then((res) => {
				dispatch(getChecklistsAction(trialID));
				dispatch(getChecklistCommentsAction({ trialID: trialID, checklistID: itemID }));
				return dispatch({ type: ActionTypes.PUT_CHECKLIST_ITEM_SUCCESS });
			})
			.catch((error) => {
				console.log(error);
				return dispatch({ type: ActionTypes.PUT_CHECKLIST_ITEM_ERROR, putChecklistItemError: error.message });
			});
	};
};

export const putChecklistsItemStatus = ({ trialID, itemID, isCompleted }) => {
	return (dispatch, getState) => {
		const parameters = {
			item_id: itemID,
			is_completed: isCompleted
		};

		API()
			.post('/api/v7/checklist/itemstatus', parameters)
			.then((res) => {
				dispatch(getChecklistsAction(trialID));
				dispatch(getChecklistCommentsAction({ trialID: trialID, checklistID: itemID }));
				return dispatch({ type: ActionTypes.PUT_CHECKLIST_ITEM_STATUS_SUCCESS });
			})
			.catch((error) => {
				console.log(error);
				return dispatch({
					type: ActionTypes.PUT_CHECKLIST_ITEM_STATUS_ERROR,
					putChecklistItemStatusError: error.message
				});
			});
	};
};

export const getChecklistCommentsAction = ({ trialID, checklistID }) => {
	return (dispatch, getState) => {
		const parameters = {
			user_id: localStorage.getItem('user_id'),
			trial_id: trialID,
			item_id: checklistID
		};

		API()
			.post('/api/v7/checklist/trialchecklistcomments', parameters)
			.then((res) => {
				console.log(res);
				return dispatch({ type: ActionTypes.GET_CHECKLIST_COMMENTS_SUCCESS, checklistComments: res.data.data });
			})
			.catch((err) => {
				console.log(err);
				return dispatch({
					type: ActionTypes.GET_CHECKLIST_COMMENTS_ERROR,
					checklistCommentsError: err.message
				});
			});
	};
};

export const postChecklistCommentAction = ({ trialID, itemID, commentText }) => {
	return (dispatch, getState) => {
		const parameters = {
			user_id: localStorage.getItem('user_id'),
			trial_id: trialID,
			item_id: itemID,
			comment_text: commentText
		};

		API()
			.post('/api/v7/checklist/addcomment', parameters)
			.then((res) => {
				dispatch(getChecklistCommentsAction({ trialID: trialID, checklistID: itemID }));
				return dispatch({ type: ActionTypes.POST_CHECKLIST_COMMENT_SUCCESS });
			})
			.catch((error) => {
				console.log(error);
				return dispatch({
					type: ActionTypes.POST_CHECKLIST_COMMENT_ERROR,
					postChecklistCommentError: error.message
				});
			});
	};
};

export const putChecklistCommentAction = ({ trialID, itemID, commentText, commentID }) => {
	return (dispatch, getState) => {
		const parameters = {
			user_id: localStorage.getItem('user_id'),
			trial_id: trialID,
			item_id: itemID,
			comment_text: commentText,
			comment_id: commentID
		};

		API()
			.post('/api/v7/checklist/editcomment', parameters)
			.then((res) => {
				dispatch(getChecklistCommentsAction({ trialID: trialID, checklistID: itemID }));
				return dispatch({ type: ActionTypes.POST_CHECKLIST_COMMENT_SUCCESS });
			})
			.catch((error) => {
				console.log(error);
				return dispatch({
					type: ActionTypes.POST_CHECKLIST_COMMENT_ERROR,
					postChecklistCommentError: error.message
				});
			});
	};
};
export const deleteChecklistCommentAction = ({ trialID, itemID, commentID }) => {
	return (dispatch, getState) => {
		const parameters = {
			user_id: localStorage.getItem('user_id'),
			trial_id: trialID,
			item_id: itemID,
			comment_id: commentID
		};

		API()
			.post('/api/v7/checklist/deletecomment', parameters)
			.then((res) => {
				dispatch(getChecklistCommentsAction({ trialID: trialID, checklistID: itemID }));
				return dispatch({ type: ActionTypes.POST_CHECKLIST_COMMENT_SUCCESS });
			})
			.catch((error) => {
				console.log(error);
				return dispatch({
					type: ActionTypes.POST_CHECKLIST_COMMENT_ERROR,
					postChecklistCommentError: error.message
				});
			});
	};
};

export const deleteChecklistTeammateAction = ({ trialID, itemID, teammateID }) => {
	return (dispatch, getState) => {
		const parameters = {
			item_id: itemID,
			teammate_id: teammateID
		};

		API()
			.post('/api/v7/checklist/removeTeammate', parameters)
			.then((res) => {
				dispatch(getChecklistCommentsAction({ trialID: trialID, checklistID: itemID }));
				return dispatch({ type: ActionTypes.POST_CHECKLIST_COMMENT_SUCCESS });
			})
			.catch((error) => {
				console.log(error);
				return dispatch({
					type: ActionTypes.POST_CHECKLIST_COMMENT_ERROR,
					postChecklistCommentError: error.message
				});
			});
	};
};
export const postChecklistTeammateAction = ({ trialID, itemID, teammateID }) => {
	return (dispatch, getState) => {
		const parameters = {
			item_id: itemID,
			teammate_id: teammateID
		};

		API()
			.post('/api/v7/checklist/assignTeammate', parameters)
			.then((res) => {
				dispatch(getChecklistCommentsAction({ trialID: trialID, checklistID: itemID }));
				return dispatch({ type: ActionTypes.POST_CHECKLIST_ASSIGN_TEAMMATE_SUCCESS });
			})
			.catch((error) => {
				console.log(error);
				return dispatch({
					type: ActionTypes.POST_CHECKLIST_ASSIGN_TEAMMATE_ERROR
				});
			});
	};
};
