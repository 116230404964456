import React, { useState } from 'react';

import LogoPreview from '../../Shared/LogoPreview/LogoPreview';
import Footer from '../../Shared/Footer/Footer';
import AddDashedButton from '../../../../../Components/AddDashedButton/AddDashedButton';
import AddASurveyOrEvaluationStatistic from '../Forms/AddASurveyOrEvaluationStatistic';
import DisplayChart from '../../../../../Components/DisplayChart/DisplayChart';
import DisplayBarChart from '../../../../../Components/DisplayBarChart/DisplayBarChart';
import CloseModalButton from '../../../../../Components/CloseModalButton/CloseModalButton';
import { Modal } from 'reactstrap';
import Toggle from 'react-toggle';

import classes from '../../EmailPreview/WeeklyEngagement/WeeklyEngagement.module.css';
import emailPreviewClasses from '../../EmailPreview/EmailPreview.module.css';
import customizeEmailClasses from '../CustomizeEmail.module.css';

import handleToggleChange from './handleToggleChange';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

const SortableItem = SortableElement((
    {
        weeklyStatistic,
        handleRemoveSurveyOrEvaluationStatistic,
        handleCurrentlyChangingSurveyOrEvaluationStatistic
    }) => {

    return weeklyStatistic.questions_type === "rating" ? (
        <DisplayBarChart
            key={ weeklyStatistic.id }
            removeFunction={ event => handleRemoveSurveyOrEvaluationStatistic(event, weeklyStatistic) }
            swapFunction={ event => handleCurrentlyChangingSurveyOrEvaluationStatistic(event, weeklyStatistic) }
            chartStatistics={ weeklyStatistic } />
    ) : (
            <DisplayChart
                key={ weeklyStatistic.id }
                removeFunction={ event => handleRemoveSurveyOrEvaluationStatistic(event, weeklyStatistic) }
                swapFunction={ event => handleCurrentlyChangingSurveyOrEvaluationStatistic(event, weeklyStatistic) }
                chartStatistics={ weeklyStatistic } />
        );
});

const SortableList = SortableContainer((
    {
        weekly_statistic,
        handleRemoveSurveyOrEvaluationStatistic,
        handleCurrentlyChangingSurveyOrEvaluationStatistic
    }) => {

    return (
        <div>
            {
                weekly_statistic?.map((weeklyStatistic, index) => {
                    return (
                        <SortableItem
                            key={ `item-${ weeklyStatistic.id }` }
                            index={ index }
                            weeklyStatistic={ weeklyStatistic }
                            handleRemoveSurveyOrEvaluationStatistic={ handleRemoveSurveyOrEvaluationStatistic }
                            handleCurrentlyChangingSurveyOrEvaluationStatistic={ handleCurrentlyChangingSurveyOrEvaluationStatistic } />
                    );
                })
            }
        </div>
    );
});

const WeeklyEngagementEndoscopy = ({ inputs, handleInputChange, formValidationErrors }) => {
    const [ isAddASurveyOrEvaluationStatisticModalOpen, setIsAddASurveyOrEvaluationStatisticModalOpen ] = useState(false);
    const toggleAddASurveyOrEvaluationStatisticModalOpen = (event) => {
        if (event) event.preventDefault();
        setIsAddASurveyOrEvaluationStatisticModalOpen(previousIsAddASurveyOrEvaluationStatisticModalOpen => !previousIsAddASurveyOrEvaluationStatisticModalOpen)
    };

    const handleRemoveSurveyOrEvaluationStatistic = (event, statistic) => {
        event.preventDefault();
        event.stopPropagation();
        let updatedWeekyStatistic = inputs.weekly_statistic.filter(statisticStoredInState => statisticStoredInState.id !== statistic.id);

        handleInputChange({
            target: {
                name: 'weekly_statistic',
                value: updatedWeekyStatistic
            }
        });
    }

    let [ currentlyChangingSurveyOrEvaluationStatistic, setCurrentlyChangingSurveyOrEvaluationStatistic ] = useState(false);
    const handleCurrentlyChangingSurveyOrEvaluationStatistic = (event, surveyOrEvaluationStatistic) => {
        event.preventDefault();

        setCurrentlyChangingSurveyOrEvaluationStatistic(surveyOrEvaluationStatistic);
        toggleAddASurveyOrEvaluationStatisticModalOpen();
    }

    const onSortEnd = ({ oldIndex, newIndex }) => {
        handleInputChange({
            target: {
                name: 'weekly_statistic',
                value: arrayMove(inputs.weekly_statistic, oldIndex, newIndex)
            }
        });
    }

    const shouldCancelStart = (event) => {
        if (event.target.id === 'icon-swap' || event.target.id === 'icon-delete')
        {
            return true;
        }
    }


    return (
        <div className="customize-email__preview-wrapper">
            <LogoPreview />

            <div className={ classes[ "weekly-engagement__header" ] }>
                <div className={ classes[ "weekly-engagement__header-heading" ] }>
                    <label htmlFor="title" className="hidden">
                        title
                    </label>

                    <textarea
                        onChange={ handleInputChange }
                        value={ inputs.title }
                        className={ customizeEmailClasses[ "email-preview__textarea" ] }
                        name="title"
                        id="title"></textarea>

                    { formValidationErrors?.title ? (
                        <span className={ customizeEmailClasses[ "input__error" ] }>{ formValidationErrors?.title }</span>
                    ) : (null) }

                    <label htmlFor="sub_title" className="hidden">
                        sub title
                    </label>

                    <textarea
                        onChange={ handleInputChange }
                        value={ inputs.sub_title }
                        className={ [ classes[ "weekly-engagement__header-heading-subtext" ], customizeEmailClasses[ "email-preview__textarea" ] ].join(" ") }
                        name="sub_title"
                        id="sub_title"></textarea>

                    { formValidationErrors?.sub_title ? (
                        <span className={ customizeEmailClasses[ "input__error" ] }>{ formValidationErrors?.sub_title }</span>
                    ) : (null) }
                </div>
                <div className={ classes[ "weekly-engagement__header-image-container" ] }>
                    <img
                        className={ classes[ "weekly-engagement__header-image" ] }
                        src="/images/email/popup-email-bg.png"
                        alt="workstation" />
                </div>
                <div className={ classes[ "weekly-engagement__header-text" ] }>
                    <label htmlFor="title_description" className="hidden">
                        title description
                    </label>

                    <textarea
                        onChange={ handleInputChange }
                        value={ inputs.title_description }
                        className={ customizeEmailClasses[ "email-preview__textarea" ] }
                        name="title_description"
                        id="title_description"></textarea>

                    { formValidationErrors?.title_description ? (
                        <span className={ customizeEmailClasses[ "input__error" ] }>{ formValidationErrors?.title_description }</span>
                    ) : (null) }
                </div>
            </div>

            <div className={ classes[ "weekly-engagement__paragraph-container" ] }>
                <label htmlFor="content" className="hidden">
                    content
                </label>

                <textarea
                    onChange={ handleInputChange }
                    value={ inputs.content }
                    className="customize-email__content-textarea"
                    rows="15"
                    name="content"
                    id="content"></textarea>

                { formValidationErrors?.content ? (
                    <span className={ customizeEmailClasses[ "input__error" ] }>{ formValidationErrors?.content }</span>
                ) : (null) }
            </div>

            <div className={ classes[ "weekly-engagement__section" ] }>
                <h2 className={ classes[ "weekly-engagement__section-heading" ] }>
                    Weekly <br /> <span className={ classes[ "weekly-engagement__section-heading-subtext" ] }>Statistics</span>
                </h2>
                <div className={ [ emailPreviewClasses[ "ribbon" ], "m-0" ].join(" ") }></div>

                <div className={ customizeEmailClasses[ "weekly-engagement__dashed-button-container" ] }>

                    <SortableList
                        shouldCancelStart={ shouldCancelStart }
                        onSortEnd={ onSortEnd }
                        weekly_statistic={ inputs?.weekly_statistic }
                        handleRemoveSurveyOrEvaluationStatistic={ handleRemoveSurveyOrEvaluationStatistic }
                        handleCurrentlyChangingSurveyOrEvaluationStatistic={ handleCurrentlyChangingSurveyOrEvaluationStatistic } />

                    <AddDashedButton handleClick={ toggleAddASurveyOrEvaluationStatisticModalOpen } text="Add another statistic" />
                </div>

                <div className="text-center">
                    <Toggle
                        onChange={ event => handleToggleChange({ event, handleInputChange }) }
                        checked={ inputs.weekly_statistic_is_on === "1" }
                        className={ [ customizeEmailClasses[ "toggle" ], "toggle-yellow" ].join(" ") }
                        icons={ true }
                        name="weekly_statistic_is_on"
                        id="weekly_statistic_is_on" />
                </div>

                { inputs.weekly_statistic_is_on === "0" ? (
                    <div className={ customizeEmailClasses[ "overlay" ] } ></div>
                ) : (null) }
            </div>

            <div className={ classes[ "weekly-engagement__mission" ] }>
                <div className={ classes[ "weekly-engagement__mission-title" ] }>
                    { inputs.mission_title }
                </div>

                <div className={ classes[ "weekly-engagement__mission-text" ] }>
                    { inputs.mission_description }
                </div>

                <div className="text-center">
                    <Toggle
                        onChange={ event => handleToggleChange({ event, handleInputChange }) }
                        checked={ inputs.mission_statistic_is_on === "1" }
                        className={ [ customizeEmailClasses[ "toggle" ], "toggle-yellow" ].join(" ") }
                        icons={ true }
                        name="mission_statistic_is_on"
                        id="mission_statistic_is_on" />
                </div>

                { inputs.mission_statistic_is_on === "0" ? (
                    <div className={ customizeEmailClasses[ "overlay" ] } ></div>
                ) : (null) }
            </div>

            <Footer text={ inputs.footer } />

            <Modal
                isOpen={ isAddASurveyOrEvaluationStatisticModalOpen }
                toggle={ toggleAddASurveyOrEvaluationStatisticModalOpen }
                centered
                backdrop="static">
                <CloseModalButton onClick={ toggleAddASurveyOrEvaluationStatisticModalOpen } />

                <AddASurveyOrEvaluationStatistic
                    toggleAddASurveyOrEvaluationStatisticModalOpen={ toggleAddASurveyOrEvaluationStatisticModalOpen }
                    inputs={ inputs }
                    handleInputChange={ handleInputChange }
                    currentlyChangingSurveyOrEvaluationStatistic={ currentlyChangingSurveyOrEvaluationStatistic }
                    setCurrentlyChangingSurveyOrEvaluationStatistic={ setCurrentlyChangingSurveyOrEvaluationStatistic } />
            </Modal>
        </div>
    );
}

export default WeeklyEngagementEndoscopy;