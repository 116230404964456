import { useEffect, useRef } from 'react';

export default function useEffectAfterFirstRender (effect, deps) {
    const isFirstRenderRef = useRef(true);

    useEffect(() => {
        if (!isFirstRenderRef.current)
        {
            effect();
        }
    }, deps)

    useEffect(() => {
        isFirstRenderRef.current = false;
    }, []);
}
