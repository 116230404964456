import React from 'react';

import CancelButton from '../../../Components/FormButtons/CancelButton';
import SubmitButton from '../../../Components/FormButtons/SubmitButton';

import { useDispatch } from 'react-redux';
import { putTrialStatusAction } from '../../../Store/actions/trialsAction';
import { useParams } from 'react-router-dom';

const UnarchiveTrial = ({ toggleUnarchiveTrialModal }) => {
    const { id } = useParams();

    const dispatch = useDispatch();

    return (
        <div className="modal-buttons">
            <h2 className='modal-buttons__title'>
                Are you sure?
            </h2>

            <p className="modal-buttons__text">
                Unarchiving this trial will restore uploading and data entry permissions for you and anyone on your team for this trial.
            </p>

            <p className="modal-buttons__subtext">
                You can archive this trial again at any time.
            </p>

            <div className="cancel-and-submit-buttons-container">
                <CancelButton handleClick={ toggleUnarchiveTrialModal } />

                <SubmitButton
                    handleClick={ () => {
                        dispatch(putTrialStatusAction({
                            trialID: id,
                            trialStatus: 'active',
                            toggleModal: toggleUnarchiveTrialModal
                        }));
                    } }
                    text="unarchive" />
            </div>
        </div>
    );
}

export default UnarchiveTrial;