import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import StatsMyHistory from "./StatsMyHistory";
import TableTrialsMyTrialHistory from "./TrialHistory";
import TableTrialsMyCaseHistory from "./TrialCaseHistory";
import { selectedSurvey } from "../../../Data/database";
import { connect } from 'react-redux';

import { getTrialPeopleStakeholderOrSurgeonAction } from '../../../Store/actions/trialsAction';
import ComponentLoading from "../../../Components/ComponentLoading/ComponentLoading";

class TrialPeopleHistory extends Component {

    componentDidMount () {
        const { stakeholderOrSurgeonID, type } = this.props.match.params;
        this.props.getTrialPeopleStakeholderOrSurgeon(stakeholderOrSurgeonID, type)
    }

    render () {
        const { type,stakeholderOrSurgeonID } = this.props.match.params;
        const { trialPeopleStakeholderOrSurgeon } = this.props;

        let mapTrialPeopleStakeholderOrSurgeon = null;

        if (trialPeopleStakeholderOrSurgeon && trialPeopleStakeholderOrSurgeon[ `${ type.substring(0, type.length - 1) }_data` ])
        {
            const STAKEHOLDER_OR_SURGEON = trialPeopleStakeholderOrSurgeon[ `${ type.substring(0, type.length - 1) }_data` ];
            mapTrialPeopleStakeholderOrSurgeon = {
                category: STAKEHOLDER_OR_SURGEON.title,
                email: STAKEHOLDER_OR_SURGEON.email,
                firstName: STAKEHOLDER_OR_SURGEON.first_name,
                lastName: STAKEHOLDER_OR_SURGEON.last_name,
                id: STAKEHOLDER_OR_SURGEON.id,
                mobilePhone: STAKEHOLDER_OR_SURGEON.mobile_phone,
                workPhone: STAKEHOLDER_OR_SURGEON.work_phone,
                secondaryEmail: STAKEHOLDER_OR_SURGEON.secondary_email,
                customerID: STAKEHOLDER_OR_SURGEON.customer_id,
                rating: STAKEHOLDER_OR_SURGEON.rating,
                profileImage: STAKEHOLDER_OR_SURGEON.profile || STAKEHOLDER_OR_SURGEON.profile_image,
                trialID: STAKEHOLDER_OR_SURGEON.trial_id || STAKEHOLDER_OR_SURGEON.s_trial_id || "",
                totalCase: trialPeopleStakeholderOrSurgeon.current_case_count,
                totalTrial: trialPeopleStakeholderOrSurgeon.trial_count,
                title: type,
                cases: trialPeopleStakeholderOrSurgeon.cases.map((item) => {
                    return {
                        id: item.id,
                        name: item.equipment,
                        institution: item.case_procedure,
                        procedureDate: item.case_date,
                        trialID: item.trial_id
                    }
                }),
                trials: trialPeopleStakeholderOrSurgeon.trials.map(item => {
                    return {
                        id: item.id,
                        name: item.account_name,
                        institution: item.account_name,
                        createdDate: item.created_date,
                        active: item.trial_status,
                    }
                })
            }

            if (type === 'surgeons')
            {
                mapTrialPeopleStakeholderOrSurgeon.personTitle = STAKEHOLDER_OR_SURGEON.speciality_name;
                mapTrialPeopleStakeholderOrSurgeon.personTitleID = STAKEHOLDER_OR_SURGEON.specialty_id;
                mapTrialPeopleStakeholderOrSurgeon.customerName = STAKEHOLDER_OR_SURGEON.customer_name;
            } else
            {
                mapTrialPeopleStakeholderOrSurgeon.personTitle = STAKEHOLDER_OR_SURGEON.title;
                mapTrialPeopleStakeholderOrSurgeon.personTitleID = STAKEHOLDER_OR_SURGEON.title_id;
                mapTrialPeopleStakeholderOrSurgeon.customerName = STAKEHOLDER_OR_SURGEON.facility;
            }
        }

        return (
            <>
                <div className="page pb-80">
                    <div className="tab-title-survey">

                        { mapTrialPeopleStakeholderOrSurgeon &&
                            <StatsMyHistory
                                person={ mapTrialPeopleStakeholderOrSurgeon }
                                selectedSurvey={ selectedSurvey }
                            /> }
                    </div>

                    { mapTrialPeopleStakeholderOrSurgeon &&
                        <TableTrialsMyTrialHistory
                            trials={ mapTrialPeopleStakeholderOrSurgeon.trials }
                            selectedSurvey={ selectedSurvey }
                        /> }

                    { mapTrialPeopleStakeholderOrSurgeon &&
                        <TableTrialsMyCaseHistory
                            peopleID={stakeholderOrSurgeonID}
                            cases={ mapTrialPeopleStakeholderOrSurgeon.cases }
                        /> }

                    { !mapTrialPeopleStakeholderOrSurgeon ? (<ComponentLoading message="Loading..."/>) : (null) }
                </div >
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        trialPeopleStakeholderOrSurgeon: state.trials.trialPeopleStakeholderOrSurgeon,
        trialPeopleStakeholderOrSurgeonError: state.trials.trialPeopleStakeholderOrSurgeonError
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getTrialPeopleStakeholderOrSurgeon: (stakeholderOrSurgeonID, type) => {
            dispatch(getTrialPeopleStakeholderOrSurgeonAction(stakeholderOrSurgeonID, type));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TrialPeopleHistory));
