import React from 'react';
import { Link, useParams } from 'react-router-dom';

import classes from './classes.module.css';

const EngagementEmailSentNotification = () => {
    const { id, engagementEmailID } = useParams();

    return (
        <div className={ classes[ "send-engagement-email-container" ] }>
            <h2 className={ classes[ "send-engagement-email__title" ] }>
                Email sent
            </h2>
            <p className={ classes[ "send-engagement-email__text" ] }>
                Congratulations! Your email has successfully been sent to your recipients.
            </p>
            <p className={ classes[ "send-engagement-email__subtext" ] }>
                Keep track of your email's progress in the "Review results" tab.
            </p>

            <Link
                to={ `/trial/${ id }/engagement-emails/${ engagementEmailID }/results` }
                className={ classes[ "send-engagement-email__image-container" ] } >
                <img
                    className={ [ classes[ "send-engagement-email__image" ], classes[ "send-engagement-email__image--yellow" ] ].join(" ") }
                    src="/images/email/icon-crush.png"
                    alt="crush icon" />
            </Link>

            <div className={ classes[ "send-engagement-email__button-container" ] }>
                <Link
                    to={ `/trial/${ id }/engagement-emails/${ engagementEmailID }/results` }
                    className={ [ classes[ "send-engagement-email__button" ], classes[ "send-engagement-email__button--yellow" ] ].join(" ") }>
                    continue to results
                </Link>
            </div>
        </div>
    );
}

export default EngagementEmailSentNotification; 