import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getRespondentsForSurveyAction } from '../Store/actions/surveysAction';
import ProfileImage from "../Utils/ProfileImage";

import getInitialsOfFirstAndLastName from "../Utils/getInitialsOfFirstAndLastName";

const TrialsQuestionaireUser = ({ surveyAndEvaluationInformation }) => {
    const dispatch = useDispatch();

    const { trialID, personID, surveyAndEvaluationID } = useParams();

    useEffect(() => {
        dispatch(getRespondentsForSurveyAction({ trialID, templateID: surveyAndEvaluationID }))
    }, []);

    const surveysAndEvaluationsRespondents = useSelector(state => state.surveysAndEvaluations.surveysAndEvaluationsRespondents);

    let personResponseInformation = surveysAndEvaluationsRespondents?.filter(person => {
        return person.id === personID;
    })[ 0 ];

    return (
        <div className="page pb-35">
            <div className="tab-title-main">
                <div className="title-user">
                    <div>
                        <div className="float-left adjust-mt">
                            <ProfileImage profile={ { profileImage: getInitialsOfFirstAndLastName(personResponseInformation?.name) } } cb={ null } profileImageClasses='profile__image--small' />
                        </div>
                        <div className="file-details user-survey">
                            <div>
                                <ul>
                                    <li>{ personResponseInformation?.name }</li>
                                    <li className="subtitle-tiny">{ personResponseInformation?.title } <br />
                                        <span className="subtitle-tiny-ltGrey">COMPLETED: NOT IN API</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <Row>
                            <Col className="file-details2 user-survey">
                                <ul className="subtitle-tiny-grey float-left">
                                    <li className="completed-sml">COMPLETED: </li>
                                    <li>{ surveyAndEvaluationInformation?.last_taken }</li>
                                </ul>
                                <ul className="subtitle-tiny-grey float-left adjust-ml">
                                    <li className="collected-sml">COLLECTED BY: </li>
                                    <li>NOT IN API</li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="tab-action">
                    <i className="las la-file-export square-white-90" />
                    <span className="">Export
                                <img src="/images/icon-arrow.jpg" className="angle3" alt="" />
                    </span>
                </div>

                <div className="tab-action">
                    <i className="las la-trash-alt square-white-0 red" />
                    <span className="">Delete
                                <img src="/images/icon-arrow.jpg" className="angle3" alt="" />
                    </span>
                </div>

            </div>
        </div >
    );
}

export default TrialsQuestionaireUser;
