import React, { useState, useEffect } from 'react';
import useEffectAfterFirstRender from '../../../../Hooks/useEffectAfterFirstRender';

import classes from '../EngagementEmails.module.css';

import {
	getDraftEngagementEmailsAction,
	deleteEngagementEmailDraftAction
} from '../../../../Store/actions/trials/engagementEmailsAction';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { Container, Modal, ModalBody } from 'reactstrap';
import List from '../../../../Components/List/List';
import EmailPreview from '../EmailPreview/EmailPreview';
import LoadMoreButton from '../../../../Components/LoadMoreButton/LoadMoreButton';
import ComponentLoading from '../../../../Components/ComponentLoading/ComponentLoading';
import ErrorMessage from '../../../../Components/ErrorMessage/ErrorMessage';
import NoResults from '../../../../Components/NoResults/NoResults';

import _ from 'lodash';
import CloseModalButton from '../../../../Components/CloseModalButton/CloseModalButton';

const EngagementEmailsDraft = ({ searchValue, setSearchValue }) => {
	const [ pageCounterForAPI, setPageCounterForAPI ] = useState(1);
	const dispatch = useDispatch();
	const { id } = useParams();
	useEffect(() => {
		if (pageCounterForAPI === 1 && draftEngagementEmailsFromAPI.length === 0) {
			dispatch(getDraftEngagementEmailsAction({ trialID: id, currentPageNumber: pageCounterForAPI }));
		}
	}, []);

	const isFetchingDraftEngagementEmails = useSelector(state => state.engagementEmails.isFetchingDraftEngagementEmails);
	const draftEngagementEmailsFromAPI = useSelector((state) => state.engagementEmails.draftEngagementEmails);
	const draftEngagementEmailsErrorFromAPI = useSelector((state) => state.engagementEmails.draftEngagementEmailsError);
	const isLastPageInDraftEngagementEmails = useSelector(
		(state) => state.engagementEmails.isLastPageInDraftEngagementEmails
	);

	useEffectAfterFirstRender(
		() => {
			dispatch(getDraftEngagementEmailsAction({ trialID: id, currentPageNumber: pageCounterForAPI }));
		},
		[ pageCounterForAPI ]
	);

	const handleLoadMore = () => {
		setPageCounterForAPI((previousPageCounter) => previousPageCounter + 1);

		setSearchValue('');
	};

	const [ sortAlphabeticallyDetailsObject, setSortAlphabeticallyDetailsObject ] = useState({
		pathUsedToSortAlphabetically: 'user_template_name',
		order: 'asc'
	});
	const handleSortAlphabeticallyByTitle = (pathUsedToSortAlphabetically) => {
		if (sortAlphabeticallyDetailsObject.order === 'asc') {
			setSortAlphabeticallyDetailsObject({ pathUsedToSortAlphabetically, order: 'desc' });
		} else if (sortAlphabeticallyDetailsObject.order === 'desc') {
			setSortAlphabeticallyDetailsObject({ pathUsedToSortAlphabetically, order: 'asc' });
		}
	};

	const filterDraftEngagementEmails = (draftEngagementEmailsFromAPI || []).filter((item) => {
		if (item.user_template_name.toLowerCase().includes(searchValue.toLowerCase().trim())) {
			return true;
		}

		return false;
	});

	const draftEngagementEmailsAlphabeticallySorted = _.orderBy(
		filterDraftEngagementEmails,
		[ sortAlphabeticallyDetailsObject.pathUsedToSortAlphabetically ],
		[ sortAlphabeticallyDetailsObject.order ]
	);

	const [ isEmailPreviewModalOpen, setIsEmailPreviewModalOpen ] = useState(false);
	const [ engagementEmailData, setEngagementEmailData ] = useState({});
	const toggleEmailPreviewModal = (engagementEmailData) => {
		if (engagementEmailData.content_json) {
			setEngagementEmailData(engagementEmailData);
		}
		setIsEmailPreviewModalOpen((previousIsEmailPreviewModalOpen) => !previousIsEmailPreviewModalOpen);
	};

	const deleteEngagementEmail = (engagementEmailData) => {
		setPageCounterForAPI(1);
		dispatch(
			deleteEngagementEmailDraftAction({
				trialID: engagementEmailData.trial_id,
				userTemplateID: engagementEmailData.user_template_id
			})
		);
	};

	const history = useHistory();
	const redirectToCustomizeEmailPage = (engagementEmailData) => {
		history.push(`${history.location.pathname}/${engagementEmailData.user_template_id}/customize-email`);
	};

	const actions = [
		{
			callback: redirectToCustomizeEmailPage,
			link: false,
			icon: <i className="las la-pen" />
		},
		{
			callback: deleteEngagementEmail,
			link: false,
			icon: <i className="las la-trash-alt" />
		},
		{
			callback: toggleEmailPreviewModal,
			link: false,
			icon: <i className="las la-eye" />
		}
	];

	return (
		<Container>
			<List
				list={ draftEngagementEmailsAlphabeticallySorted }
				actions={ actions }
				sortAlphabeticallyByTitle={ handleSortAlphabeticallyByTitle }
				sortBy="user_template_name"
				view="draft-engagement-emails" />
			
			{ draftEngagementEmailsAlphabeticallySorted.length === 0 && !isFetchingDraftEngagementEmails ? (
				<NoResults />
			) : null }

			{ isFetchingDraftEngagementEmails ? (
				<ComponentLoading 
					message="Loading..."
					removeHeight={ true } />
			 ) : (null) }

			{ draftEngagementEmailsErrorFromAPI ? (
				<ErrorMessage message={ draftEngagementEmailsErrorFromAPI } />
			) : (null) }

			{ !isLastPageInDraftEngagementEmails && !isFetchingDraftEngagementEmails ? (
				<LoadMoreButton handleClick={ handleLoadMore } />
			) : (null) }

			<Modal
				centered
				isOpen={ isEmailPreviewModalOpen }
				toggle={ toggleEmailPreviewModal }
				backdrop="static">
				<CloseModalButton onClick={ toggleEmailPreviewModal  } />
				
				<ModalBody className={classes['email-preview-modal']}>
					<EmailPreview emailPreview={engagementEmailData} />
				</ModalBody>
			</Modal>
		</Container>
	);
};

export default EngagementEmailsDraft;
