import React from 'react';

import CancelButton from '../../../Components/FormButtons/CancelButton';
import SubmitButton from '../../../Components/FormButtons/SubmitButton';

import { useDispatch } from 'react-redux';
import { putTrialStatusAction } from '../../../Store/actions/trialsAction';
import { useParams } from 'react-router-dom';

const ArchiveTrial = ({ toggleArchiveTrialModal }) => {
    const { id } = useParams();

    const dispatch = useDispatch();

    return (
        <div className="modal-buttons">
            <h2 className='modal-buttons__title'>
                Are you sure?
            </h2>

            <p className="modal-buttons__text">
                Archiving this trial will restrict uploading and data entry for you and anyone on your team for this trial.
            </p>

            <p className="modal-buttons__subtext">
                Archiving does not delete this trial and all information will still be accessible. You can unarchive this trial at any time.
            </p>

            <div className="cancel-and-submit-buttons-container">
                <CancelButton handleClick={ toggleArchiveTrialModal } />

                <SubmitButton
                    handleClick={ () => {
                        dispatch(putTrialStatusAction({
                            trialID: id,
                            trialStatus: 'archived',
                            toggleModal: toggleArchiveTrialModal
                        }));
                    } }
                    text='archive'
                    isWhite={ true } />
            </div>
        </div>
    );
}

export default ArchiveTrial;