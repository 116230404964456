import React from 'react';
import { useParams } from 'react-router-dom';

import TrialsSurveyQuestionaireUserTab from '../../../../Section/TrialsSurveyQuestionaireUserTab';
import QuestionsAnswered from './QuestionsAnswered';

const PersonResponse = ({ surveysAndEvaluations }) => {
	const { id } = useParams();

	let surveyAndEvaluationInformation = surveysAndEvaluations
		? surveysAndEvaluations.filter((survey) => survey.template_id === id)[0]
		: {};

	return (
		<React.Fragment>
			<TrialsSurveyQuestionaireUserTab surveyAndEvaluationInformation={surveyAndEvaluationInformation} />
			<QuestionsAnswered surveyAndEvaluationInformation={surveyAndEvaluationInformation} />
		</React.Fragment>
	);
};

export default PersonResponse;
