import React from 'react';
import { Col } from 'reactstrap';
import classes from './results.module.css';
import Toggle from 'react-toggle';
import DoughnutChart from '../../../../../Components/DisplayChart/DoughnutChart';

export const MultipleResults = ({ question, exportable, onExportToggle, onChartDone }) => {
	return (
		<React.Fragment>
			<div className={classes['pie-container']}>
				<div className={classes['pie-info']}>
					<DoughnutChart
						question={question}
						onChartDone={onChartDone}
						className="chart__graph"
						chartId={question.id}
						data={question.question_options.map((option) => {
							return { label: option.options, percentage: option.option_response_percentage };
						})}
					/>
				</div>
				<div className="no-of-stats">
					<span className="stronger">{question.question_response_count}</span> TOTAL RESPONSE{question.question_response_count !== '1' ? 'S' : ''}
				</div>
				{question.question_options.map((option) => {
					const progressGradient = `linear-gradient(90deg, #85458a ${option.option_response_percentage}%,#85458a ${option.option_response_percentage}%,#85458a ${option.option_response_percentage}%, #efeff3 ${option.option_response_percentage}%, #efeff3 100%)`;

					return (
						<div className="result-stats survey-ans-pie" key={`option-${option.option_id}`}>
							<div className="stats">
								<div className="stats-percent">{option.option_response_percentage}%</div>
								<div className="stats-no">{option.option_response_count}</div>
							</div>
							<div className={classes['option-container']}>
								<p className="pl-1">{option.options}</p>
								<div
									className={classes['response-percentage-bar']}
									style={{ background: progressGradient }}
								/>
							</div>
						</div>
					);
				})}

				<div className="line-grey" />
				<div className="inclue-export">
					<div className="include">
						<i className="las la-file-export circle" />
						INCLUDE IN EXPORT
					</div>

					<Toggle
						onChange={onExportToggle}
						checked={exportable}
						className="toggle-yellow"
						icons={false}
						name="exportable"
					/>
				</div>
			</div>
		</React.Fragment>
	);
};
