import React, { useState, useEffect } from 'react';
import useEffectAfterFirstRender from '../../../../Hooks/useEffectAfterFirstRender';

import { getPreviousEngagementEmailsAction } from '../../../../Store/actions/trials/engagementEmailsAction';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Container } from 'reactstrap';
import List from '../../../../Components/List/List';
import LoadMoreButton from '../../../../Components/LoadMoreButton/LoadMoreButton';
import ComponentLoading from '../../../../Components/ComponentLoading/ComponentLoading';
import ErrorMessage from '../../../../Components/ErrorMessage/ErrorMessage';
import NoResults from '../../../../Components/NoResults/NoResults';

import _ from 'lodash';

const EngagementEmailsSent = ({ searchValue, setSearchValue }) => {
	const [ pageCounterForAPI, setPageCounterForAPI ] = useState(1);
	const dispatch = useDispatch();
	const { id } = useParams();

	useEffect(() => {
		if (pageCounterForAPI === 1 && previousEngagementEmailsFromAPI.length === 0)
		{
			dispatch(getPreviousEngagementEmailsAction({ trialID: id, currentPageNumber: pageCounterForAPI }));
		}
	}, []);

	const isFetchingPreviousEngagementEmails = useSelector(state => state.engagementEmails.isFetchingPreviousEngagementEmails);
	const previousEngagementEmailsFromAPI = useSelector((state) => state.engagementEmails.previousEngagementEmails);
	const previousEngagementEmailsErrorFromAPI = useSelector(
		(state) => state.engagementEmails.previousEngagementEmailsError
	);
	const isLastPageInPreviousEngagementEmails = useSelector(
		(state) => state.engagementEmails.isLastPageInPreviousEngagementEmails
	);

	useEffectAfterFirstRender(
		() => {
			dispatch(getPreviousEngagementEmailsAction({ trialID: id, currentPageNumber: pageCounterForAPI }));
		},
		[ pageCounterForAPI ]
	);

	const handleLoadMore = () => {
		setPageCounterForAPI((previousPageCounter) => previousPageCounter + 1);

		setSearchValue('');
	};

	const [ sortAlphabeticallyDetailsObject, setSortAlphabeticallyDetailsObject ] = useState({
		pathUsedToSortAlphabetically: 'user_template_name',
		order: 'asc'
	});
	const handleSortAlphabeticallyByTitle = (pathUsedToSortAlphabetically) => {
		if (sortAlphabeticallyDetailsObject.order === 'asc') {
			setSortAlphabeticallyDetailsObject({ pathUsedToSortAlphabetically, order: 'desc' });
		} else if (sortAlphabeticallyDetailsObject.order === 'desc') {
			setSortAlphabeticallyDetailsObject({ pathUsedToSortAlphabetically, order: 'asc' });
		}
	};

	const previousEngagementEmailsAlphabeticallySorted = _.orderBy(
		previousEngagementEmailsFromAPI,
		[ sortAlphabeticallyDetailsObject.pathUsedToSortAlphabetically ],
		[ sortAlphabeticallyDetailsObject.order ]
	);

	const filterPreviousEngagementEmails = previousEngagementEmailsAlphabeticallySorted.filter((item) => {
		if (item.user_template_name.toLowerCase().includes(searchValue.toLowerCase().trim())) {
			return true;
		}

		return false;
	});

	return (
		<Container>
			<List
				list={ filterPreviousEngagementEmails }
				sortAlphabeticallyByTitle={ handleSortAlphabeticallyByTitle }
				sortBy="user_template_name"
				view="sent-engagement-emails" />

			{ filterPreviousEngagementEmails.length === 0 && !isFetchingPreviousEngagementEmails ? (
				<NoResults />
			) : null }

			{ isFetchingPreviousEngagementEmails ? (
				<ComponentLoading 
					message="Loading..."
					removeHeight={ true } />
			 ) : null }

			{ previousEngagementEmailsErrorFromAPI ? (
				<ErrorMessage message={ previousEngagementEmailsErrorFromAPI } />
			) : (null) }

			{ !isLastPageInPreviousEngagementEmails && !isFetchingPreviousEngagementEmails ? (
				<LoadMoreButton handleClick={ handleLoadMore } />
			) : (null) }
		</Container>
	);
};

export default EngagementEmailsSent;
