import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import ProfileImage from '../../Utils/ProfileImage';
import CreateRatingCircles from '../../Utils/CreateRatingCircles/CreateRatingCircles';

const TableTrialsPeople = (props) => {
	let { peopleFiltered, onSort } = props;
	let pageURL = window.location.href;
	let trialName = pageURL.substr(pageURL.lastIndexOf('/') + 1);

	const [ isAscended, setIsAscended ] = useState(true);

	return (
		<Container>
			<table className="table filelist">
				<thead>
					<tr>
						<th
							onClick={() => {
								setIsAscended(!isAscended);
								onSort('name');
							}}
							className="sort-order cursor"
						>
							SORT: A-Z{' '}
							{isAscended ? <i className="las la-angle-up" /> : <i className="las la-angle-down" />}
						</th>
					</tr>
				</thead>

				<tbody className="table-sort">
					{peopleFiltered.map((item) => {
						return (
							<tr key={item.id}>
								<td>
									<Link
										to={`/trial/${item.trialID}/organization/people/${item.group
											.name}/history/${item.id}/${trialName}`}
										key={item.id}
										value={item.name}
									>
										<div className="person-details-container">
											<ProfileImage
												profile={item}
												cb={null}
												profileImageClasses="profile__image--list"
											/>
											<div className="person-details">
												<p className={'futurastdbold'}>{item.name}</p>
												<div className="person-details__circles">
													<CreateRatingCircles amountOfCircles={item.rating} />
												</div>
												<div className="list__item-dark-gray">
													{item.title ? `${item.title} |` : ''}{' '}
													{item.numberOfCases ? item.numberOfCases : 0} CASES COMPLETED
												</div>
											</div>
											<div>
												<i className="las la-angle-right list-details__icon"></i>
											</div>
										</div>
									</Link>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			<hr />
		</Container>
	);
};

export default TableTrialsPeople;
