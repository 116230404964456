import React, { Component } from 'react';
import classes from '../List.module.css';

class ListSort extends Component {
    state = {
        isAscended: true
    }
    render () {
        const { sortAlphabeticallyByTitle, sortBy } = this.props;

        return (
            <button
                className={ classes[ "list__sort" ] }
                onClick={ () => {
                    sortAlphabeticallyByTitle(sortBy);
                    this.setState({ isAscended: !this.state.isAscended })
                } }>
                <div>
                    <span
                        className={ classes[ "list__sort-text" ] }>
                        SORT: A-Z
                    </span>

                    { this.state.isAscended ? (
                        <i className="las la-angle-up"></i>
                    ) : (
                            <i className="las la-angle-down"></i>
                        ) }

                </div>
            </button>
        )
    }
}

export default ListSort;