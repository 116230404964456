import React from 'react';

import classes from './Footer.module.css';
import LogoPreview from '../LogoPreview/LogoPreview';

const Footer = ({ text, containerClasses }) => {
    return (
        <div className={ [ classes[ "footer" ], containerClasses ].join(" ") }>
            <LogoPreview removeMargin={ true } />
            <div className={ classes[ "footer__text" ] }>
                { text }
            </div>
        </div>
    )
}

export default Footer;