import React from 'react';

import LogoPreview from '../../Shared/LogoPreview/LogoPreview';
import Footer from '../../Shared/Footer/Footer';
import TrialStatistic from '../../../../../Components/TrialStatistic/TrialStatistic';
import EngagementEmailVerbatim from '../../../../../Components/EngagementEmailVerbatim/EngagementEmailVerbatim'
import DisplayChart from '../../../../../Components/DisplayChart/DisplayChart'
import DisplayBarChart from '../../../../../Components/DisplayBarChart/DisplayBarChart';

import emailPreviewClasses from '../EmailPreview.module.css';
import classes from './TrialRecapUpdate.module.css';

const TrialRecapUpdateEndoscopy = ({ emailPreview }) => {
    const emailPreviewContent = emailPreview?.content.split(/[\r\n]+/g);

    const BASE_URL = process.env.REACT_APP_API_URL;
    let facilityImage = BASE_URL + emailPreview.facility_image;
    let facilityLogo = BASE_URL + emailPreview.facility_logo;

    if (emailPreview.facility_image === "")
    {
        facilityImage = '/images/email/facility-image-placeholder.png';
    }

    if (emailPreview.facility_logo === "")
    {
        facilityLogo = '/images/email/facility-logo-placeholder.png';
    }

    return (
        <div className={ classes[ "trial-recap" ] }>
            <LogoPreview />
            <div className={ classes[ "trial-recap__header" ] }>
                <div className={ classes[ "trial-recap__header-item" ] }>

                    <div className={ classes[ "trial-recap__header-heading" ] }>
                        { emailPreview.title }
                    </div>
                    <div className={ classes[ "trial-recap__header-heading-subtext" ] }>
                        { emailPreview.sub_title }
                    </div>

                    <img className={ classes[ "trial-recap__header-image" ] } src="/images/email/customize-email-bg-1.jpg" alt="rectangles" />
                </div>

                <div className={ classes[ "trial-recap__header-item" ] }>
                    <div className={ classes[ "trial-recap__header-image-container" ] }>
                        <img
                            className={ classes[ "trial-recap__header-image" ] }
                            src={ facilityImage }
                            alt="facility image" />
                    </div>
                    <div className={ classes[ "trial-recap__header-image-container" ] }>
                        <img
                            className={ classes[ "trial-recap__header-image" ] }
                            src={ facilityLogo }
                            alt="facility logo" />
                    </div>
                </div>
            </div>

            <p className={ classes[ "trial-recap__quote" ] }>
                { emailPreview.title_description }
            </p>

            <div className={ classes[ "trial-recap__paragraphs-container" ] }>
                { emailPreviewContent?.map((item, index) => {
                    return (
                        <p
                            key={ index }
                            className={ emailPreviewClasses[ "email-preview__paragraph" ] }>
                            { item }
                        </p>
                    );
                }) }
            </div>

            { emailPreview?.advanced_imaging_is_on === "1" ? (
                <div className={ classes[ "trial-recap__gallery-section" ] }>
                    <div className={ classes[ "trial-recap__gallery-section-header" ] }>
                        <h2 className={ classes[ "trial-recap__gallery-section-heading" ] }>Advanced Imaging Modalities</h2>
                        <div className={ emailPreviewClasses[ "ribbon" ] }></div>
                    </div>

                    <div className={ classes[ "trial-recap__gallery-container" ] }>
                        <div className={ classes[ "trial-recap__gallery-wrapper" ] }>
                            { emailPreview?.advanced_imaging.map((item, index) => {
                                return (
                                    <div
                                        key={ index }
                                        className={ classes[ "trial-recap__gallery-item" ] }>
                                        <img
                                            className={ classes[ "trial-recap-gallery__image" ] }
                                            src={ `${ BASE_URL }/${ item.url }` } alt={ item.name } />
                                        <div
                                            className={ classes[ "trial-recap-gallery__text" ] }>
                                            { item.name }
                                        </div>
                                    </div>
                                )
                            }) }
                            { emailPreview?.advanced_imaging_1?.map((item, index) => {
                                return (
                                    <div
                                        key={ index }
                                        className={ classes[ "trial-recap__gallery-item" ] }>
                                        <img
                                            className={ classes[ "trial-recap-gallery__image" ] }
                                            src={ `${ BASE_URL }/${ item.url }` } alt={ item.name } />
                                        <div
                                            className={ classes[ "trial-recap-gallery__text" ] }>
                                            { item.name }
                                        </div>
                                    </div>
                                )
                            }) }
                        </div>
                    </div>
                </div>
            ) : (null) }

            { emailPreview?.statistic_is_on === "1" ? (
                <div className={ [ "trial-statistics-container", classes[ "trial-statistics-container--padding" ], emailPreview?.statistic?.length === 1 ? "justify-content-center" : "" ].join(" ") }>
                    {emailPreview?.statistic?.map(statistic => {
                        return (
                            <TrialStatistic
                                key={ statistic.name }
                                trialStatistic={ statistic }
                                removeButtons={ true } />
                        );
                    }) }
                </div>
            ) : (null) }

            { emailPreview?.verbatim_is_on === "1" ? (
                <div className={ classes[ "engagement-email-verbatim-container" ] }>
                    { emailPreview?.verbatim?.map(verbatimComment => {
                        return (
                            <EngagementEmailVerbatim
                                key={ verbatimComment.comment_id }
                                verbatimComment={ verbatimComment }
                                removeButtons={ true } />
                        );
                    }) }
                </div>
            ) : (null) }

            {emailPreview?.weekly_statistic_is_on === "1" ? (
                <div className={ classes[ "trial-recap__responses" ] }>
                    <h2 className={ classes[ "trial-recap__responses-title" ] }>
                        { emailPreview.survey_responses_title } <br /> <span className={ classes[ "trial-recap__responses-subtext" ] }>{ emailPreview.survey_responses_sub_title }</span>
                    </h2>
                    <div className={ emailPreviewClasses[ "ribbon" ] }></div>

                    <div className="survey-or-evaluation-statistics">
                        { emailPreview?.weekly_statistic?.map(weeklyStatistic => {
                            return weeklyStatistic.questions_type === "rating" ? (
                                <DisplayBarChart
                                    key={ weeklyStatistic.id }
                                    chartStatistics={ weeklyStatistic }
                                    removeButtons={ true }
                                    onConfirmAndReviewPage={ true } />
                            ) : (
                                    <DisplayChart
                                        key={ weeklyStatistic.id }
                                        chartStatistics={ weeklyStatistic }
                                        removeButtons={ true }
                                        onConfirmAndReviewPage={ true } />
                                );
                        }) }
                    </div>
                </div>
            ) : (null) }
            <Footer text={ emailPreview.footer } />
        </div>
    )
}

export default TrialRecapUpdateEndoscopy;