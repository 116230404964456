/** @format */

import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import useHandleSubmit from "../../../../Hooks/useHandleSubmit";

import FirstView from "./Views/FirstView";
import SecondView from "./Views/SecondView";
import ThirdView from "./Views/ThirdView";
import SendTestEmail from "../SendTestEmail/SendTestEmail";
import SendEngagementEmail from "../SendEngagementEmail/SendEngagementEmail";
import EngagementEmailSentNotification from "../SendEngagementEmail/EngagementEmailSentNotification";
import CloseModalButton from "../../../../Components/CloseModalButton/CloseModalButton";

import { Modal } from "reactstrap";

import classes from "./CustomizeEmail.module.css";

import {
  getEngagementEmailAction,
  postTemplateToEngagementEmailAction,
  postRecipientsToEngagementEmailAction,
} from "../../../../Store/actions/trials/engagementEmailsAction";
import { useDispatch, useSelector } from "react-redux";

import setEngagementEmailDataFromAPIIntoState from "./setValuesIntoStateForCreateEmailTemplates";

import {
  createFormValidationPreTrialCommunicationThankYouEndoscopy,
  createFormValidationForPreTrialCommunicationThankYouCommunications,
  createFormValidationInServiceInviteCommunicationsEmail,
  createFormValidationInServiceInviteEndoscopyEmail,
  createFormValidationInServiceInviteMedEmail,
  createFormValidationInServiceRecapCommunications,
  createFormValidationForInServiceRecapEndoscopy,
  createFormValidationForTrialRecapUpdateEndoscopy,
  createFormValidationForWeeklyEngagementEndoscopy,
  createFormValidationForTrialRecapUpdateCommunications,
} from "./createFormValidation";

import createFormData from "../Shared/createFormData";

const CustomizeEmail = () => {
  const { id, engagementEmailID } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getEngagementEmailAction({
        trialID: id,
        userTemplateID: engagementEmailID,
      })
    );
  }, [id, engagementEmailID]);

  const engagementEmailDataFromAPI = useSelector(
    (state) => state.engagementEmails.engagementEmail
  );

  const [currentFormViewCounter, setCurrentFormViewCounter] = useState(0);

  const handleSetPreviousView = () => {
    if (currentFormViewCounter === 0) return;
    setCurrentFormViewCounter(
      (previousCurrentFormViewCounter) => previousCurrentFormViewCounter - 1
    );
  };

  const [formValidationErrors, setFormValidationErrors] = useState({});
  const handleSetNextView = () => {
    if (currentFormViewCounter == 0) {
      setFormValidationErrors({});
      let isFormValidated = false;
      if (engagementEmailDataFromAPI.template_type === "service_invite") {
        isFormValidated =
          createFormValidationInServiceInviteCommunicationsEmail({
            inputs,
            setFormValidationErrors,
          });
      } else if (
        engagementEmailDataFromAPI.template_type === "service_invite_endo"
      ) {
        isFormValidated = createFormValidationInServiceInviteEndoscopyEmail({
          inputs,
          setFormValidationErrors,
        });
      } else if (
        engagementEmailDataFromAPI.template_type === "service_invite_med"
      ) {
        isFormValidated = createFormValidationInServiceInviteMedEmail({
          inputs,
          setFormValidationErrors,
        });
      } else if (engagementEmailDataFromAPI.template_type === "service_recap") {
        isFormValidated = createFormValidationInServiceRecapCommunications({
          inputs,
          setFormValidationErrors,
        });
      } else if (
        engagementEmailDataFromAPI.template_type === "service_recap_endo"
      ) {
        isFormValidated = createFormValidationForInServiceRecapEndoscopy({
          inputs,
          setFormValidationErrors,
        });
      } else if (
        engagementEmailDataFromAPI.template_type === "integration_trial"
      ) {
        isFormValidated =
          createFormValidationForPreTrialCommunicationThankYouCommunications({
            inputs,
            setFormValidationErrors,
          });
      } else if (engagementEmailDataFromAPI.template_type === "trial_recap") {
        isFormValidated = createFormValidationForTrialRecapUpdateEndoscopy({
          inputs,
          setFormValidationErrors,
        });
      } else if (
        engagementEmailDataFromAPI?.template_type === "weekly_engagement"
      ) {
        isFormValidated = createFormValidationForWeeklyEngagementEndoscopy({
          inputs,
          setFormValidationErrors,
        });
      } else if (
        engagementEmailDataFromAPI?.template_type ===
        "integration_trial_complete"
      ) {
        isFormValidated = createFormValidationForTrialRecapUpdateCommunications(
          { inputs, setFormValidationErrors }
        );
      } else {
        isFormValidated =
          createFormValidationPreTrialCommunicationThankYouEndoscopy({
            inputs,
            setFormValidationErrors,
          });
      }

      if (!isFormValidated) return;

      let formData = createFormData({ engagementEmailDataFromAPI, inputs, id });

      dispatch(
        postTemplateToEngagementEmailAction({ formData, redirect: null })
      );
    } else if (currentFormViewCounter === 1) {
      let addTypeToRecipientsAddedByEmail = inputs.recipientsAddedByEmail.map(
        (email) => {
          return {
            type: "custom",
            email: email,
          };
        }
      );

      let concatRecipients = inputs.recipients.concat(
        addTypeToRecipientsAddedByEmail
      );

      if (concatRecipients.length === 0) {
        return alert("Please select at least one recipient.");
      }

      dispatch(
        postRecipientsToEngagementEmailAction({
          trialID: id,
          userTemplateID: engagementEmailDataFromAPI.user_template_id,
          recipientsArray: concatRecipients,
        })
      );
    }

    if (currentFormViewCounter === 2) return handleSubmit();

    setCurrentFormViewCounter(
      (previousCurrentFormViewCounter) => previousCurrentFormViewCounter + 1
    );
  };

  const postTemplateToEngagementEmailError = useSelector(
    (state) => state.engagementEmails.postTemplateToEngagementEmailError
  );
  const postRecipientsToEngagementEmailError = useSelector(
    (state) => state.engagementEmails.postRecipientsToEngagementEmailError
  );

  const history = useHistory();

  const handleSaveDraftAndExit = (event) => {
    event.preventDefault();
    if (inputs.subjectLine) {
      let formData = createFormData({ engagementEmailDataFromAPI, inputs, id });
      dispatch(
        postTemplateToEngagementEmailAction({ formData, redirect: null })
      );
      return history.push(`/trial/${id}/engagement-email`);
    }

    setFormValidationErrors((previousFormValidationErrors) => ({
      ...previousFormValidationErrors,
      subjectLine: "This field is required.",
    }));
  };

  const handleFormSubmit = () => {
    toggleSendEngagementEmailModal();
  };

  const { inputs, handleInputChange, handleSubmit } = useHandleSubmit({
    callback: handleFormSubmit,
  });

  useEffect(() => {
    setEngagementEmailDataFromAPIIntoState({
      engagementEmailDataFromAPI,
      handleInputChange,
    });

    handleInputChange({
      target: {
        name: "emailName",
        value: engagementEmailDataFromAPI.user_template_name,
      },
    });

    handleInputChange({
      target: {
        name: "subjectLine",
        value: engagementEmailDataFromAPI.subject,
      },
    });

    handleInputChange({
      target: {
        name: "recipientsAddedByEmail",
        value: [],
      },
    });
  }, [engagementEmailDataFromAPI]);

  const engagementEmailImagesFromAPI = useSelector(
    (state) => state.engagementEmails.engagementEmailImages
  );
  const engagementEmailImagesError = useSelector(
    (state) => state.engagementEmails.engagementEmailImagesError
  );

  useEffect(() => {
    if (engagementEmailImagesFromAPI.content_json) {
      handleInputChange({
        target: {
          name: "facility_image",
          value: engagementEmailImagesFromAPI.content_json.facility_image,
        },
      });

      handleInputChange({
        target: {
          name: "facility_logo",
          value: engagementEmailImagesFromAPI.content_json.facility_logo,
        },
      });
    }
  }, [engagementEmailImagesFromAPI]);

  useEffect(() => {
    handleSetViewAndActiveIcon(currentFormViewCounter);
  }, [
    currentFormViewCounter,
    inputs,
    formValidationErrors,
    engagementEmailDataFromAPI,
  ]);

  const checkIfAbleToSendTestEmail = (event) => {
    event.preventDefault();
    if (inputs.subjectLine) {
      let formData = createFormData({ engagementEmailDataFromAPI, inputs, id });

      return dispatch(
        postTemplateToEngagementEmailAction({
          formData,
          redirect: null,
          onSuccessCallbacks: () => {
            dispatch(
              getEngagementEmailAction({
                trialID: id,
                userTemplateID: engagementEmailID,
              })
            );
            setIsSubjectUpdated(false);
            setFormValidationErrors({});
          },
        })
      );
    }

    setFormValidationErrors((previousFormValidationErrors) => ({
      ...previousFormValidationErrors,
      subjectLine: "This field is required.",
    }));
  };

  const [isSubjectUpdated, setIsSubjectUpdated] = useState(false);
  useEffect(() => {
    if (
      engagementEmailDataFromAPI.subject !== undefined &&
      inputs.subjectLine !== undefined
    ) {
      if (inputs.subjectLine !== engagementEmailDataFromAPI.subject) {
        return setIsSubjectUpdated(true);
      }

      setIsSubjectUpdated(false);
    }
  }, [inputs.subjectLine, engagementEmailDataFromAPI]);

  const [formView, setFormView] = useState(
    <FirstView
      handleInputChange={handleInputChange}
      inputs={inputs}
      formValidationErrors={formValidationErrors}
    />
  );

  let firstProgressIcon = useRef(null);
  let secondProgressIcon = useRef(null);
  let thirdProgressIcon = useRef(null);

  const [pageTitle, setPageTitle] = useState("Customize email");
  const [backButtonText, setBackButtonText] = useState("back");
  const [nextButtonText, setNextButtonText] = useState("save &#38; continue");

  function handleSetViewAndActiveIcon(currentFormViewCounter) {
    if (currentFormViewCounter === 0) {
      secondProgressIcon.current.classList.remove(
        classes["form-menu__icon--active"]
      );

      firstProgressIcon.current.classList.add(
        classes["form-menu__icon--active"]
      );

      setBackButtonText("back");
      setNextButtonText("save & continue");
      setPageTitle("Customize email");
      setFormView(
        <FirstView
          engagementEmailDataFromAPI={engagementEmailDataFromAPI}
          handleInputChange={handleInputChange}
          inputs={inputs}
          formValidationErrors={formValidationErrors}
        />
      );
    } else if (currentFormViewCounter === 1) {
      firstProgressIcon.current.classList.remove(
        classes["form-menu__icon--active"]
      );
      thirdProgressIcon.current.classList.remove(
        classes["form-menu__icon--active"]
      );

      secondProgressIcon.current.classList.add(
        classes["form-menu__icon--active"]
      );

      setBackButtonText("customize your email");
      setNextButtonText("review & confirm");
      setPageTitle("Choose recipients");
      setFormView(
        <SecondView inputs={inputs} handleInputChange={handleInputChange} />
      );
    } else if (currentFormViewCounter === 2) {
      secondProgressIcon.current.classList.remove(
        classes["form-menu__icon--active"]
      );

      thirdProgressIcon.current.classList.add(
        classes["form-menu__icon--active"]
      );
      setBackButtonText("choose recipients");
      setPageTitle("Review & confirm");
      setFormView(
        <ThirdView
          engagementEmailDataFromAPI={engagementEmailDataFromAPI}
          inputs={inputs}
        />
      );
    }
  }

  const [isSendTestEmailModalOpen, setIsSendTestEmailModalOpen] =
    useState(false);
  const toggleSendTestEmailModal = () =>
    setIsSendTestEmailModalOpen(
      (previousIsSendTestEmailModalOpen) => !previousIsSendTestEmailModalOpen
    );

  const [isSendEngagementEmailModalOpen, setIsSendEngagementEmailModalOpen] =
    useState(false);
  const toggleSendEngagementEmailModal = () =>
    setIsSendEngagementEmailModalOpen(
      (previousIsSendEngagementEmailModalOpen) =>
        !previousIsSendEngagementEmailModalOpen
    );

  const [
    isEngagementEmailSentNotificationModalOpen,
    setIsEngagementEmailSentNotificationModalOpen,
  ] = useState(false);
  const toggleEngagementEmailSentNotificationModal = () =>
    setIsEngagementEmailSentNotificationModalOpen(
      (previousIsEngagementEmailSentNotificationModalOpen) =>
        !previousIsEngagementEmailSentNotificationModalOpen
    );

  return (
    <div className={classes["container"]}>
      <div className={classes["header--wrapper"]}>
        <div className={classes["header"]}>
          <h1 className={classes["header__title"]}>{pageTitle}</h1>

          <div className={classes["header__buttons"]}>
            {engagementEmailDataFromAPI.subject && !isSubjectUpdated ? (
              <button
                onClick={toggleSendTestEmailModal}
                className={classes["header__button"]}
              >
                send a test email
              </button>
            ) : (
              <button
                onClick={checkIfAbleToSendTestEmail}
                className={[
                  classes["header__button"],
                  classes["header__button--disabled"],
                ].join(" ")}
              >
                send a test email (subject req’d)
              </button>
            )}

            <button
              onClick={handleSaveDraftAndExit}
              className={classes["header__button"]}
            >
              save draft &#38; exit
            </button>
          </div>
        </div>
      </div>
      <div className={classes["form-menu"]}>
        {currentFormViewCounter !== 0 ? (
          <button
            onClick={handleSetPreviousView}
            className={classes["form-menu__back-button"]}
          >
            <i
              className={[
                "las la-angle-left",
                classes["form-menu__navigation-icon"],
              ].join(" ")}
            ></i>
            {backButtonText}
          </button>
        ) : (
          <div></div>
        )}

        <div className={classes["form-menu__icons"]}>
          <div
            ref={firstProgressIcon}
            className={[
              classes["form-menu__icon"],
              classes["form-menu__icon--active"],
            ].join(" ")}
          >
            <i className="las la-cog"></i>
          </div>
          <div className={classes["horizontal-line"]}></div>
          <div ref={secondProgressIcon} className={classes["form-menu__icon"]}>
            <i className="las la-users"></i>
          </div>
          <div className={classes["horizontal-line"]}></div>
          <div ref={thirdProgressIcon} className={classes["form-menu__icon"]}>
            <i className="las la-glasses"></i>
          </div>
        </div>
        {currentFormViewCounter === 2 ? (
          <button
            onClick={handleSetNextView}
            className={[
              classes["form-menu__next-button"],
              classes["form-menu__next-button--send"],
            ].join(" ")}
          >
            send
            <i
              className={[
                "las la-angle-right",
                classes["form-menu__navigation-icon"],
                classes["form-menu__navigation-icon--white"],
              ].join(" ")}
            ></i>
          </button>
        ) : (
          <button
            onClick={handleSetNextView}
            className={classes["form-menu__next-button"]}
          >
            {nextButtonText}
            <i
              className={[
                "las la-angle-right",
                classes["form-menu__navigation-icon"],
              ].join(" ")}
            ></i>
          </button>
        )}
      </div>
      <div className="max-width">{formView}</div>
      <Modal
        centered
        isOpen={isSendTestEmailModalOpen}
        toggle={toggleSendTestEmailModal}
        backdrop="static"
      >
        <CloseModalButton onClick={toggleSendTestEmailModal} />

        <SendTestEmail toggleModal={toggleSendTestEmailModal} />
      </Modal>

      <Modal
        centered
        isOpen={isSendEngagementEmailModalOpen}
        toggle={toggleSendEngagementEmailModal}
        backdrop="static"
      >
        <CloseModalButton onClick={toggleSendEngagementEmailModal} />

        <SendEngagementEmail
          amountOfRecipients={
            inputs?.recipients?.length + inputs?.recipientsAddedByEmail?.length
          }
          toggleSendEngagementEmailModal={toggleSendEngagementEmailModal}
          toggleEngagementEmailSentNotificationModal={
            toggleEngagementEmailSentNotificationModal
          }
        />
      </Modal>

      <Modal
        centered
        isOpen={isEngagementEmailSentNotificationModalOpen}
        toggle={toggleEngagementEmailSentNotificationModal}
        backdrop="static"
      >
        <EngagementEmailSentNotification
          toggleEngagementEmailSentNotificationModal={
            toggleEngagementEmailSentNotificationModal
          }
        />
      </Modal>
    </div>
  );
};

export default CustomizeEmail;
