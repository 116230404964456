import React, { Component, createRef } from 'react';
import OverlayHeaderForPopups from '../../../../Components/OverlayHeaderForPopups/OverlayHeaderForPopups';
import classes from './CreateOrEdit.module.css';
import { Modal } from 'reactstrap';

import MultipleChoice from './FormElements/MultipleChoice';
import YesNo from './FormElements/YesNo';
import TextResponse from './FormElements/TextResponse';
import Rating from './FormElements/Rating';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createSurveyTemplateNameAction, createSurveyQuestionAction } from '../../../../Store/actions/surveysAction';

import {
	createEvaluationTemplateNameAction,
	createEvaluationQuestionAction
} from '../../../../Store/actions/evaluationsAction';
import SavePopUp from '../SavePopUp/SavePopUp';

const availableQuestions = {
	multiple_choice: {
		question: '',
		requiredResponse: false,
		questionAnswers: [],
		allowMultipleResponses: false,
		id: 0,
		order: 0,
		type: 'multiple'
	},
	yes_no: {
		question: '',
		requiredResponse: false,
		allowNAResponse: false,
		questionAnswers: [
			{ option_id: 0, options: 'yes', is_display: '1' },
			{ option_id: 1, options: 'no', is_display: '1' }
		],
		id: 0,
		order: 0,
		type: 'yesno'
	},
	text_response: {
		question: '',
		requiredResponse: false,
		id: 0,
		order: 0,
		type: 'text'
	},
	rating: {
		question: '',
		questionAnswers: [],
		requiredResponse: false,
		allowMultipleResponses: false,
		selectedScale: 5,
		minimumAndMaximumScaleLabels: [ '', '' ],
		id: 0,
		order: 0,
		type: 'rating'
	}
};

class Create extends Component {
	state = {
		templateName: '',
		questions: [],
		questionIds: 0,
		savePopUpOpen: false,
		savingQuestions: false
	};
	AddAQuestionContainerRef = createRef();

	handleFormSubmit = (ev, saveInto) => {
		ev.preventDefault();
		const { trialID } = this.props.match.params;

		const parameters = {
			trialID,
			templateName: this.state.templateName,
			saveInto: saveInto,
			templateID: this.props.templateID
		};
		this.setState({ savingQuestions: true });

		this.props.type === 'survey'
			? this.props.saveSurveyTemplateName(parameters, this.saveQuestions)
			: this.props.saveEvaluationTemplateName(parameters, this.saveQuestions);
	};

	saveQuestions = (templateID) => {
		this.state.questions.length > 0 && this.saveQuestion(templateID, 0);
	};

	saveQuestion = (id, questionIndex) => {
		const { trialID } = this.props.match.params;
		const question = questionIndex < this.state.questions.length ? this.state.questions[questionIndex] : false;
		if (question) {
			if (question.type === 'multiple') {
				let formatMultipleChoiceQuestionData = {
					...question,
					questionAnswers: question.questionAnswers,
					questionType: 'multiple',
					trialID,
					templateID: id,
					order: questionIndex + 1,
					requiredResponse: question.requiredResponse === true ? '1' : '0',
					allowMultipleResponses: question.allowMultipleResponses === true ? '1' : '0'
				};

				this.props.type === 'survey'
					? this.props.createSurveyQuestion(formatMultipleChoiceQuestionData, this.saveQuestion)
					: this.props.createEvaluationQuestion(formatMultipleChoiceQuestionData, this.saveQuestion);
			} else if (question.type === 'yesno') {
				let formatYesNoQuestionData = {
					...question,
					question: question.question,
					questionAnswers: question.questionAnswers,
					trialID,
					order: questionIndex + 1,
					questionType: 'yesno',
					templateID: id,
					requiredResponse: question.requiredResponse === true ? '1' : '0',
					allowNAResponse: question.allowNAResponse === true ? '1' : '0'
				};

				this.props.type === 'survey'
					? this.props.createSurveyQuestion(formatYesNoQuestionData, this.saveQuestion)
					: this.props.createEvaluationQuestion(formatYesNoQuestionData, this.saveQuestion);
			} else if (question.type === 'text') {
				let formatTextReponseQuestionData = {
					...question,
					question: question.question,
					trialID,
					order: questionIndex + 1,
					questionType: 'text',
					templateID: id,
					requiredResponse: question.requiredResponse === true ? '1' : '0'
				};

				this.props.type === 'survey'
					? this.props.createSurveyQuestion(formatTextReponseQuestionData, this.saveQuestion)
					: this.props.createEvaluationQuestion(formatTextReponseQuestionData, this.saveQuestion);
			} else if (question.type === 'rating') {
				let formatRatingQuestionData = {
					...question,
					question: question.question,
					trialID,
					questionAnswers: question.questionAnswers.map((qa) => {
						if (qa.option_id === '' && qa.is_display === '0') qa.option_id = '0';
						return qa;
					}),
					order: questionIndex + 1,
					questionType: 'rating',
					templateID: id,
					requiredResponse: question.requiredResponse === true ? '1' : '0',
					allowMultipleResponses: question.allowMultipleResponses === true ? '1' : '0',
					minRatingLabel: question.minimumAndMaximumScaleLabels[0],
					maxRatingLabel: question.minimumAndMaximumScaleLabels[1],
					ratingScale: question.selectedScale
				};
				this.props.type === 'survey'
					? this.props.createSurveyQuestion(formatRatingQuestionData, this.saveQuestion)
					: this.props.createEvaluationQuestion(formatRatingQuestionData, this.saveQuestion);
			}
		} else {
			this.setState({
				savingQuestions: false
			});
			window.location.href = `/trial/${trialID}/surveyevals/${this.props.type}/templates/${id}`;
			//this.props.toggleSurveyCreateOrEditQuestionairePopup();
		}
	};

	handleInputChange = (ev) => {
		this.setState({
			[ev.currentTarget.id]: ev.currentTarget.value
		});
	};

	handleQuestionChange = (changedQuestion) => {
		let cloneQuestions = this.state.questions.map((q) => (q.id === changedQuestion.id ? changedQuestion : q));

		this.setState({
			questions: cloneQuestions
		});
	};

	toggleAddQuestionContainer = () => {
		this.AddAQuestionContainerRef.current.classList.toggle(classes['hidden']);
	};

	addQuestion = (keyword) => {
		const currentQuestions = this.state.questions;
		availableQuestions[keyword] &&
			currentQuestions.push({
				...availableQuestions[keyword],
				id: this.state.questionIds + 1
			});
		this.setState(
			{ questions: currentQuestions, questionIds: this.state.questionIds + 1 },
			this.toggleAddQuestionContainer
		);
	};

	removeQuestion = (questionId) => {
		let deletedQuestion = this.state.questions.filter((q) => q.id !== questionId);
		this.setState({
			questions: deletedQuestion
		});
	};

	moveQuestionDown = (questionId) => {
		const clonedQuestions = this.state.questions;
		const orderedQuestions = [ clonedQuestions.length ];
		const limit = clonedQuestions.length - 1;
		for (let i = 0; i < clonedQuestions.length; i++) {
			const currentQuestion = clonedQuestions[i];
			if (currentQuestion.id === questionId && i !== limit) {
				const nextQuestion = clonedQuestions[i + 1];
				nextQuestion.order = i;
				orderedQuestions[i] = nextQuestion;
				currentQuestion.order = i + 1;
				orderedQuestions[i + 1] = currentQuestion;
				i++;
			} else {
				orderedQuestions[i] = currentQuestion;
			}
		}

		this.setState({ questions: orderedQuestions });

		/* const currentPlace = clonedQuestions.findIndex(q => q.id === questionId);
		const splicedQuestions = clonedQuestions.splice(clonedQuestions.findIndex(q => q.id === questionId), 1); */
	};

	moveQuestion = (questionId, place) => {
		const { questions } = this.state;
		const questionPosition = questions.findIndex((q) => q.id === questionId);
		const movingQuestion = questions[questionPosition];
		const orderedQuestions = [ questions.length ];
		movingQuestion.order = place;
		orderedQuestions[place] = movingQuestion;

		questions.forEach((question, index) => {
			if (!orderedQuestions[index] && question.id !== questionId) {
				question.order = index;
				orderedQuestions[index] = question;
			}
		});
		this.setState({ questions: orderedQuestions });
	};

	openSaveMenu = (ev) => {
		ev.preventDefault();
		this.setState({ savePopUpOpen: !this.state.savePopUpOpen });
	};

	render() {
		const {
			surveyCreateOrEditQuestionairePopupIsOpen,
			toggleSurveyCreateOrEditQuestionairePopup,
			type
		} = this.props;
		const { templateName, questions, savePopUpOpen, savingQuestions } = this.state;

		return (
			<Modal
				id="form-survey"
				className={classes['form-edit-and-create-survey-container']}
				isOpen={surveyCreateOrEditQuestionairePopupIsOpen}
				toggle={toggleSurveyCreateOrEditQuestionairePopup}
				backdrop="static"
			>
				<OverlayHeaderForPopups
					title={`Add ${type}`}
					togglePopup={toggleSurveyCreateOrEditQuestionairePopup}
					buttonSubmitCallback={this.openSaveMenu}
					editing={templateName != '' && questions.length > 0}
				/>

				<form className={classes['form-edit-and-create-survey']}>
					<div className={classes['form-survey__card']}>
						<div className={classes['form-survey__card-title-container']}>
							<h3 className={classes['form-survey__card-title']}>Add {type} details</h3>
						</div>

						<div className={classes['form-survey__card-group']}>
							<label className={classes['form-survey__card-label']} htmlFor="templateName">
								{type} name
							</label>
							<input
								onChange={this.handleInputChange}
								className={classes['form-survey__card-input']}
								type="text"
								id="templateName"
								placeholder="template name"
							/>
						</div>
					</div>
					{questions.map((item, index) => {
						if (item.type === 'multiple') {
							return (
								<MultipleChoice
									key={index}
									id={item.id}
									item={item}
									order={index + 1}
									handleQuestionChange={this.handleQuestionChange}
									moveQuestionDown={() => this.moveQuestion(item.id, index + 1)}
									onDelete={this.removeQuestion}
								/>
							);
						} else if (item.type === 'yesno') {
							return (
								<YesNo
									key={index}
									id={item.id}
									order={index + 1}
									item={item}
									handleQuestionChange={this.handleQuestionChange}
									moveQuestionDown={() => this.moveQuestion(item.id, index + 1)}
									onDelete={this.removeQuestion}
								/>
							);
						} else if (item.type === 'text') {
							return (
								<TextResponse
									key={index}
									id={item.id}
									item={item}
									order={index + 1}
									handleQuestionChange={this.handleQuestionChange}
									moveQuestionDown={() => this.moveQuestion(item.id, index + 1)}
									onDelete={() => this.removeQuestion(item.id)}
								/>
							);
						} else if (item.type === 'rating') {
							return (
								<Rating
									key={index}
									id={item.id}
									item={item}
									order={index + 1}
									handleQuestionChange={this.handleQuestionChange}
									moveQuestionDown={() => this.moveQuestion(item.id, index + 1)}
									onDelete={() => this.removeQuestion(item.id)}
								/>
							);
						}
					})}

					{/* <DragAndDrop
                        handleDragAndDropQuestionChange={ this.handleDragAndDropQuestionChange } /> */}
				</form>

				<div className={classes['form-survey__add-question-wrapper']}>
					<div className="right-min">
						<div
							ref={this.AddAQuestionContainerRef}
							className={[ classes['form-survey__buttons-container'], classes['hidden'] ].join(' ')}
						>
							<button
								data-question-type="rating"
								className={classes['form-survey__button']}
								onClick={() => this.addQuestion('rating')}
							>
								Rating
							</button>
							<hr />
							<button
								data-question-type="text-response"
								className={classes['form-survey__button']}
								onClick={() => this.addQuestion('text_response')}
							>
								Text Response
							</button>
							<hr />
							<button
								data-question-type="multiple"
								className={classes['form-survey__button']}
								onClick={() => this.addQuestion('multiple_choice')}
							>
								Multiple Choice
							</button>
							<hr />
							<button
								data-question-type="yes-no"
								className={classes['form-survey__button']}
								onClick={() => this.addQuestion('yes_no')}
							>
								Yes/No
							</button>
						</div>
						<i
							onClick={this.toggleAddQuestionContainer}
							className={[ classes['form-survey__add-icon'], 'las la-plus' ].join(' ')}
						/>
					</div>
				</div>
				{savePopUpOpen && (
					<SavePopUp
						saving={savingQuestions}
						saveTemplate={this.handleFormSubmit}
						surveyAndEvaluationSaveFormPopUpIsOpen={savePopUpOpen}
						toggleSurveyAndEvaluationSaveFormPopUp={this.openSaveMenu}
					/>
				)}
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		createSurveyAndEvaluationTemplateID:
			state.surveysAndEvaluations.createSurveysAndEvaluationsTemplateName.template_id
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		saveSurveyTemplateName: (parameters, callback) => {
			dispatch(createSurveyTemplateNameAction(parameters, callback));
		},
		saveEvaluationTemplateName: (parameters, callback) => {
			dispatch(createEvaluationTemplateNameAction(parameters, callback));
		},
		createSurveyQuestion: (parameters, saveNextQuestion) => {
			dispatch(createSurveyQuestionAction(parameters, saveNextQuestion));
		},
		createEvaluationQuestion: (parameters, saveNextQuestion) => {
			dispatch(createEvaluationQuestionAction(parameters, saveNextQuestion));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Create));
