import * as ActionTypes from './ActionTypes';

import API from '../../API/API';

export const relatedTrialListingAction = (trialID) => {
	return (dispatch, getState) => {

		dispatch({ type: ActionTypes.REQUEST_RELATED_TRIALS });

		API()
			.post(`/api/v7/relatedtrial/reletedtriallisting`, {
				user_id: localStorage.getItem('user_id'),
				trial_id: trialID
			})
			.then(function(res) {
				return dispatch({
					type: ActionTypes.GET_RELATED_TRIAL_SUCCESS,
					getRelatedTrials: res.data.data
				});
			})
			.catch((err) => {
				return dispatch({ type: ActionTypes.GET_RELATED_TRIAL_ERROR, getRelatedTrialsError: err });
			});
	};
};

export const itemDetailAction = ({ itemID, itemType }) => {
	return (dispatch, getState) => {
		const parameter = {
			user_id: localStorage.getItem('user_id'),
			item_id: itemID,
			item_type: itemType
		};

		dispatch({ type: ActionTypes.REQUEST_RELATED_TRIAL_ITEM_DETAILS });

		API()
			.post(`/api/v7/relatedtrial/itemdetail`, parameter)
			.then(function(res) {
				return dispatch({
					type: ActionTypes.GET_RELATED_TRIAL_DETAIL_SUCCESS,
					getRelatedTrialDetail: res.data.data
				});
			})
			.catch((err) => {
				return dispatch({
					type: ActionTypes.GET_RELATED_TRIAL_DETAIL_ERROR,
					getRelatedTrialDetailErr: err
				});
			});
	};
};
