import * as ActionTypes from '../ActionTypes';

import API from '../../../API/API';

export const getDraftEngagementEmailsAction = ({ trialID, currentPageNumber }) => {
    return (dispatch, getState) => {
        const parameters = {
            user_id: localStorage.getItem('user_id'),
            trial_id: trialID,
            page: currentPageNumber
        };

        dispatch({ type: ActionTypes.REQUEST_DRAFT_ENGAGEMENT_EMAILS });

        API().post('/api/v7/engagementemail/getdraftemails', parameters)
            .then(res => {
                console.log(res)
                return dispatch({
                    type: ActionTypes.GET_DRAFT_ENGAGEMENT_EMAILS_SUCCESS,
                    currentPageNumber: currentPageNumber,
                    draftEngagementEmails: res.data.data
                });
            })
            .catch(err => {
                return dispatch({ type: ActionTypes.GET_DRAFT_ENGAGEMENT_EMAILS_ERROR, draftEngagementEmailsError: err.message });
            });
    }
}

export const getPreviousEngagementEmailsAction = ({ trialID, currentPageNumber }) => {
    return (dispatch, getState) => {
        const parameters = {
            user_id: localStorage.getItem('user_id'),
            trial_id: trialID,
            page: currentPageNumber
        };

        dispatch({ type: ActionTypes.REQUEST_PREVIOUS_ENGAGEMENT_EMAILS });

        API().post('/api/v7/engagementemail/getpreviousemails', parameters)
            .then(res => {
                console.log(res);
                return dispatch({
                    type: ActionTypes.GET_PREVIOUS_ENGAGEMENT_EMAILS_SUCCESS,
                    currentPageNumber: currentPageNumber,
                    previousEngagementEmails: res.data.data
                });
            })
            .catch(err => {
                return dispatch({ type: ActionTypes.GET_PREVIOUS_ENGAGEMENT_EMAILS_ERROR, previousEngagementEmailsError: err.message });
            });
    }
}

export const getTemplateEngagementEmailsAction = ({ trialID, currentPageNumber }) => {
    return (dispatch, getState) => {
        const parameters = {
            user_id: localStorage.getItem('user_id'),
            trial_id: trialID,
            page: currentPageNumber
        };

        dispatch({ type: ActionTypes.REQUEST_TEMPLATE_ENGAGEMENT_EMAILS });

        API().post('/api/v7/engagementemail/standardtemplate', parameters)
            .then(res => {
                console.log(res);
                return dispatch({
                    type: ActionTypes.GET_TEMPLATE_ENGAGEMENT_EMAILS_SUCCESS,
                    currentPageNumber: currentPageNumber,
                    templateEngagementEmails: res.data.data
                });
            })
            .catch(err => {
                return dispatch({ type: ActionTypes.GET_TEMPLATE_ENGAGEMENT_EMAILS_ERROR, templateEngagementEmailsError: err.message });
            });
    }
}

export const getEngagementEmailResultsAction = ({ trialID, engagementEmailID }) => {
    return (dispatch) => {
        const parameters = {
            user_id: localStorage.getItem('user_id'),
            trial_id: trialID,
            user_template_id: engagementEmailID
        };

        API().post('/api/v7/engagementemail/emailresult', parameters)
            .then(res => {
                console.log(res);
                if (res.data.success === "0")
                {
                    return dispatch({ type: ActionTypes.GET_ENGAGEMENT_EMAIL_RESULTS_ERROR, engagementEmailResultsError: res.data.message });
                }
                return dispatch({ type: ActionTypes.GET_ENGAGEMENT_EMAIL_RESULTS_SUCCESS, engagementEmailResults: res.data.data })
            })
            .catch(err => {
                return dispatch({ type: ActionTypes.GET_ENGAGEMENT_EMAIL_RESULTS_ERROR, engagementEmailResultsError: err.message });
            });
    }
}

export const getRecipientsAction = ({ trialID, userTemplateID }) => {
    return (dispatch) => {
        const parameters = {
            user_id: localStorage.getItem('user_id'),
            trial_id: trialID,
            user_template_id: userTemplateID
        };

        dispatch({ type: ActionTypes.REQUEST_RECIPIENTS });

        API().post('/api/v7/engagementemail/getrecipients', parameters)
            .then(res => {
                return dispatch({ type: ActionTypes.GET_RECIPIENTS_SUCCESS, recipients: res.data.data });
            })
            .catch(err => {
                return dispatch({ type: ActionTypes.GET_RECIPIENTS_ERROR, recipientsError: err.message });
            });
    }
}

export const getEngagementEmailAction = ({ trialID, userTemplateID }) => {
    return (dispatch) => {
        const parameters = {
            user_id: localStorage.getItem('user_id'),
            trial_id: trialID,
            user_template_id: userTemplateID
        };

        dispatch({ type: ActionTypes.RESET_RECIPIENTS_IN_REDUCER });

        API().post('/api/v7/engagementemail/usertemplatedetail', parameters)
            .then(res => {
                console.log(res);
                return dispatch({ type: ActionTypes.GET_ENGAGEMENT_EMAIL_SUCCESS, engagementEmail: res.data.data });
            })
            .catch(error => {
                return dispatch({ type: ActionTypes.GET_ENGAGEMENT_EMAIL_ERROR, engagementEmailError: error.message });
            });
    }
}

export const clonePreviousEmailAction = ({ parameters: { trialID, userTemplateName, cloneTemplateID }, redirect }) => {
    return (dispatch) => {
        const parameters = {
            user_id: localStorage.getItem('user_id'),
            trial_id: trialID,
            user_template_name: userTemplateName,
            clone_template_id: cloneTemplateID
        };

        API().post('/api/v7/engagementemail/clonepreviousemail', parameters)
            .then(res => {
                console.log(res);
                redirect(res.data.data.template_id);
                return dispatch({ type: ActionTypes.CLONE_PREVIOUS_EMAIL_SUCCESS, clonePreviousEmail: res.data.data });
            })
            .catch(error => {
                return dispatch({ type: ActionTypes.CLONE_PREVIOUS_EMAIL_ERROR, clonePreviousEmailError: error.message });
            });
    }
}

export const deleteEngagementEmailDraftAction = ({ trialID, userTemplateID }) => {
    return (dispatch) => {
        const parameters = {
            user_id: localStorage.getItem('user_id'),
            user_template_id: userTemplateID,
        };

        API().post('/api/v7/engagementemail/deleteDraft', parameters)
            .then(res => {
                console.log(res);
                dispatch(getDraftEngagementEmailsAction({ trialID, currentPageNumber: 1 }))
                return dispatch({ type: ActionTypes.DELETE_ENGAGEMENT_EMAIL_DRAFT_SUCCESS });
            })
            .catch(error => {
                return dispatch({ type: ActionTypes.DELETE_ENGAGEMENT_EMAIL_DRAFT_ERROR, deleteEngagementEmailDraftError: error.message });
            });
    }
}

export const sendTestEngagementEmailAction = ({ trialID, userTemplateID, email }) => {
    return (dispatch) => {
        const parameters = {
            user_id: localStorage.getItem('user_id'),
            trial_id: trialID,
            user_template_id: userTemplateID,
            email: email
        };

        API().post('/api/v7/engagementemail/sendtestmail', parameters)
            .then(res => {
                console.log(res);
                return dispatch({ type: ActionTypes.SEND_TEST_ENGAGEMENT_EMAIL_SUCCESS });
            })
            .catch(error => {
                return dispatch({ type: ActionTypes.SEND_TEST_ENGAGEMENT_EMAIL_ERROR, sendTestEngagementEmailError: error.message });
            });
    }
}

export const postTemplateImagesToEngagementEmailAction = (formData) => {
    return (dispatch) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

        API().post('/api/v7/engagementemail/uploadTemplateImage', formData, config)
            .then(res => {
                console.log(res);
                dispatch(getEngagementEmailImagesAction({ trialID: formData.get('trial_id'), userTemplateID: formData.get('template_id') }));
                return dispatch({ type: ActionTypes.POST_TEMPLATE_IMAGES_ENGAGEMENT_EMAIL_SUCCESS });
            })
            .catch(error => {
                console.log(error);
                return dispatch({ type: ActionTypes.POST_TEMPLATE_IMAGES_ENGAGEMENT_EMAIL_ERROR, postTemplateImagesEngagementEmailError: error.message });
            });
    }
}

export const getEngagementEmailImagesAction = ({ trialID, userTemplateID }) => {
    return (dispatch) => {
        const parameters = {
            user_id: localStorage.getItem('user_id'),
            trial_id: trialID,
            user_template_id: userTemplateID
        };

        API().post('/api/v7/engagementemail/usertemplatedetail', parameters)
            .then(res => {
                return dispatch({ type: ActionTypes.GET_ENGAGEMENT_EMAIL_IMAGES_SUCCESS, engagementEmailImages: res.data.data });
            })
            .catch(error => {
                console.log(error);
                return dispatch({ type: ActionTypes.GET_ENGAGEMENT_EMAIL_IMAGES_ERROR, engagementEmailImagesError: error.message });
            });
    }
}

export const postEmailToRecipientAction = ({ trialID, personType, email, personID, userTemplateID }) => {
    return (dispatch) => {
        const parameters = {
            user_id: localStorage.getItem('user_id'),
            trial_id: trialID,
            people_type: personType,
            email: email,
            people_id: personID
        }

        API().post('/api/v7/engagementemail/recipientaddemail', parameters)
            .then(res => {
                console.log(res);
                if (res.data.success === "0") return dispatch({ type: ActionTypes.POST_EMAIL_TO_RECIPIENT_ERROR, postEmailToRecipientError: `${ res.data.message }-${ personID }` });
                dispatch(getRecipientsAction({ trialID, userTemplateID }));
                return dispatch({ type: ActionTypes.POST_EMAIL_TO_RECIPIENT_SUCCESS });
            })
            .catch(error => {
                return dispatch({ type: ActionTypes.POST_EMAIL_TO_RECIPIENT_ERROR, postEmailToRecipientError: error.message });
            });
    }
}

export const postTemplateToEngagementEmailAction = ({ formData, redirect, onSuccessCallbacks }) => {
    return (dispatch) => {
        if (formData.template_type === 'trial_recap' || formData.template_type === 'weekly_engagement')
        {
            const config = {
                headers: {
                    'template_type': formData.template_type
                }
            }

            return API().post('/api/v7/engagementemail/createtemplate', formData, config)
                .then(res => {
                    if (redirect) redirect(res.data.data.template_id);
                    if (onSuccessCallbacks) onSuccessCallbacks();
                    return dispatch({ type: ActionTypes.POST_TEMPLATE_TO_ENGAGEMENT_EMAIL_SUCCESS });
                })
                .catch(error => {
                    console.log(error);
                    return dispatch({ type: ActionTypes.POST_TEMPLATE_TO_ENGAGEMENT_EMAIL_ERROR, postTemplateToEngagementEmailError: error.message });
                });
        }

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

        API().post('/api/v7/engagementemail/createtemplate', formData, config)
            .then(res => {
                console.log(res);
                if (redirect) redirect(res.data.data.template_id);
                if (onSuccessCallbacks) onSuccessCallbacks();
                return dispatch({ type: ActionTypes.POST_TEMPLATE_TO_ENGAGEMENT_EMAIL_SUCCESS });
            })
            .catch(error => {
                console.log(error)
                return dispatch({ type: ActionTypes.POST_TEMPLATE_TO_ENGAGEMENT_EMAIL_ERROR, postTemplateToEngagementEmailError: error.message });
            });
    }
}

export const postRecipientsToEngagementEmailAction = ({ trialID, userTemplateID, recipientsArray }) => {
    return (dispatch) => {
        let formatPeopleInRecipientsArray = recipientsArray.map(person => {
            return {
                id: person.id,
                type: person.type,
                email: person.email
            }
        });

        const parameters = {
            user_id: localStorage.getItem('user_id'),
            trial_id: trialID,
            user_template_id: userTemplateID,
            recipients: formatPeopleInRecipientsArray
        }

        API().post('/api/v7/engagementemail/saverecipients', parameters)
            .then(res => {
                console.log(res);
                dispatch(getRecipientsAction({ trialID, userTemplateID }));
                return dispatch({ type: ActionTypes.POST_RECIPIENTS_TO_ENGAGEMENT_EMAIL_SUCCESS });
            })
            .catch(error => {
                return dispatch({ type: ActionTypes.POST_RECIPIENTS_TO_ENGAGEMENT_EMAIL_ERROR, postRecipientsToEngagementEmailError: error.message });
            });
    }
}

export const postSendMailAction = ({ userTemplateID, toggleSendEngagementEmailModal, toggleEngagementEmailSentNotificationModal }) => {
    return (dispatch) => {
        const parameters = {
            user_template_id: userTemplateID,
        }

        API().post('/api/v7/engagementemail/sendmail', parameters)
            .then(res => {
                console.log(res);
                toggleSendEngagementEmailModal();
                toggleEngagementEmailSentNotificationModal();
                return dispatch({ type: ActionTypes.POST_SEND_MAIL_SUCCESS });
            })
            .catch(error => {
                return dispatch({ type: ActionTypes.POST_SEND_MAIL_ERROR, postSendMailError: error.message });
            });
    }
}

export const getEngagementEmailVerbatimAction = (trialID) => {
    return (dispatch) => {
        const parameters = {
            user_id: localStorage.getItem('user_id'),
            trial_id: trialID,
        };

        dispatch({ type: ActionTypes.REQUEST_ENGAGEMENT_EMAIL_VERBATIM });

        API().post('/api/v7/engagementemail/verbatim', parameters)
            .then(res => {
                if (res.data.message === "No record found.")
                {
                    return dispatch({ type: ActionTypes.GET_ENGAGEMENT_EMAIL_VERBATIM_SUCCESS, engagementEmailVerbatim: [] })
                }
                return dispatch({ type: ActionTypes.GET_ENGAGEMENT_EMAIL_VERBATIM_SUCCESS, engagementEmailVerbatim: res.data.data })
            })
            .catch(err => {
                return dispatch({ type: ActionTypes.GET_ENGAGEMENT_EMAIL_VERBATIM_ERROR, engagementEmailVerbatimError: err.message });
            });
    }
}

export const getSurveyReviewResultDetailAllAction = (trialID) => {
    return (dispatch) => {
        const parameters = {
            user_id: localStorage.getItem('user_id'),
            trial_id: trialID
        }

        const config = {
            headers: {
                module: 'survey'
            }
        };

        API().post('/api/v7/evaluation/reviewresultdetailall', parameters, config)
            .then(res => {
                if (res.data.message === 'No record found.')
                {
                    return dispatch({ type: ActionTypes.GET_SURVEY_REVIEW_RESULT_DETAIL_ALL_SUCCESS, surveyReviewResultDetailAllQuestionList: [] })
                }

                return dispatch({ type: ActionTypes.GET_SURVEY_REVIEW_RESULT_DETAIL_ALL_SUCCESS, surveyReviewResultDetailAllQuestionList: res.data.data.question_list })
            })
            .catch(error => {
                console.log(error);
                return dispatch({ type: ActionTypes.GET_SURVEY_REVIEW_RESULT_DETAIL_ALL_ERROR, surveyReviewResultDetailAllError: error.message })
            })
    }
}

export const getEvaluationReviewResultDetailAllAction = (trialID) => {
    return (dispatch) => {
        const parameters = {
            user_id: localStorage.getItem('user_id'),
            trial_id: trialID
        }

        const config = {
            headers: {
                module: 'evaluation'
            }
        };

        API().post('/api/v7/evaluation/reviewresultdetailall', parameters, config)
            .then(res => {
                if (res.data.message === 'No record found.')
                {
                    return dispatch({ type: ActionTypes.GET_EVALUATION_REVIEW_RESULT_DETAIL_ALL_SUCCESS, evaluationReviewResultDetailAllQuestionList: [] })
                }

                return dispatch({ type: ActionTypes.GET_EVALUATION_REVIEW_RESULT_DETAIL_ALL_SUCCESS, evaluationReviewResultDetailAllQuestionList: res.data.data.question_list })
            })
            .catch(error => {
                console.log(error);
                return dispatch({ type: ActionTypes.GET_EVALUATION_REVIEW_RESULT_DETAIL_ALL_ERROR, evaluationReviewResultDetailAllError: error.message })
            })
    }
}