import React, { Component } from 'react';
import classes from '../Collateral.module.css'

class CollateralItem extends Component {
    render () {
        return (
            <div className={ classes[ "collateral-container" ] }>
                <h1 className={ classes[ "collateral-header" ] }>
                    title
                </h1>
                <div>
                    <ul
                        className={ classes[ "collateral-list" ] }>
                        <li
                            className={ classes[ "collateral-list-item" ] }>
                            <div className={ classes[ "collateral-item-left" ] }>
                                <div className={ classes[ "collateral-item-title" ] }>
                                    title
                                </div>
                                <div className={ classes[ "collateral-item-subtitle" ] }>
                                    subtitle
                                </div>
                            </div>
                            <div className={ classes[ "collateral-item-right" ] }>
                                <i className="las la-angle-right"></i>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default CollateralItem;