import React from 'react';

import classes from './CloseModalButton.module.css';

const CloseModalButton = ({ onClick }) => {
    return (
        <button
            onClick={ onClick }
            className={ classes[ "button" ] }
            title="close">
            <i className="las la-times"></i>
        </button>
    );
}

export default CloseModalButton;