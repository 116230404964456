import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "reactstrap";
import Search from "../Utils/Search";
import NavBar from '../Navi/NavBar';

import _ from "lodash";
import ListUsingIndexedDB from '../Components/ListUsingIndexedDB/ListUsingIndexedDB';
import useEffectAfterFirstRender from '../Hooks/useEffectAfterFirstRender';
import { getProductEducationSearchResultsAction, productEducationSingleAction } from '../Store/actions/productEducationAction';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ComponentLoading from '../Components/ComponentLoading/ComponentLoading';
import ErrorMessage from '../Components/ErrorMessage/ErrorMessage';
import NoResults from '../Components/NoResults/NoResults';

const imageURL = `${process.env.REACT_APP_API_URL}uploads/pages/`;

const ProductEducationSub = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(productEducationSingleAction(id));
    }, []);

    const productEducationSingle = useSelector((state) => {
        return state.productEducation.productEducationSingle
    });

    const productEducationSingleError = useSelector((state) => {
        return state.productEducation.productEducationSingleError
    });

    const pageInformation = productEducationSingle ? productEducationSingle.page : ([]);

    let formatInformation = pageInformation ? (
        <div>
            <h4 className="section-title-prod-edu">{ pageInformation[ 0 ].name }</h4>
            <h4 className="section-title-sub text-center">{ pageInformation[ 0 ].subtitle }</h4>
            <div className="text-center">
                <img className="title-prod-tab" style={ { padding: '10px' } } src={ imageURL + pageInformation[ 0 ].page_logo } alt={ pageInformation[ 0 ].name } />
            </div>
            <div className="line-blk"></div>
            <p className="text-left product-education__description">
                { pageInformation[ 0 ].description }
            </p>
            <p className="text-left product-education__tags">
                { pageInformation[ 0 ].tags }
            </p>
            <hr className="mb-0" />
        </div>) : (<ComponentLoading message="Loading..." />)

    const [ sortColumn, setSortColumn ] = useState({ path: "content_name", order: "asc" });

    const handleSort = path => {
        if (sortColumn.path === path)
        {
            (sortColumn.order === "asc")
                ? setSortColumn({ path, order: "desc" })
                : setSortColumn({ path, order: "asc" });
        } else
        {
            sortColumn.path = path;
            setSortColumn({ path, order: "asc" })
        }

    }

    const [ searchValue, setSearchValue ] = useState('');
    const handleInputChange = (ev) => {
        setSearchValue(ev.currentTarget.value)
    }

    useEffectAfterFirstRender(() => {
        dispatch(getProductEducationSearchResultsAction({ productEducationID: id, searchValue: searchValue }))
    }, [ searchValue ]);

    const productEducationSearchResults = useSelector(state => state.productEducation.productEducationSearchResults.content);
    const productEducationSearchResultsError = useSelector(state => state.productEducation.productEducationSearchResultsError);

    let sortedProductEducation = _.orderBy(productEducationSingle.content, [ sortColumn.path ], [ sortColumn.order ]);
    let sortedProductEducationSearchResults = _.orderBy(productEducationSearchResults, [ sortColumn.path ], [ sortColumn.order ]);

    return (
        <>
            <NavBar />
            <Container className="tab-title-main">
                <Row >
                    <Col sm={ 12 }>
                        { formatInformation }
                        <Search handleInputChange={ handleInputChange } />
                    </Col>
                </Row>
            </Container>

            {searchValue.length === 0 ? (
                <React.Fragment>
                    <ListUsingIndexedDB
                        list={ sortedProductEducation }
                        sortBy={ "content_name" }
                        sortAlphabeticallyByTitle={ handleSort } />

                    { sortedProductEducation.length === 0 ? (
                        <NoResults />
                    ) : (null) }

                    { productEducationSingleError ? (
                        <ErrorMessage message={ productEducationSingleError } />
                    ) : (null) }
                </React.Fragment>
            ) : (
                    <React.Fragment>
                        <ListUsingIndexedDB
                            list={ sortedProductEducationSearchResults }
                            sortBy={ "content_name" }
                            sortAlphabeticallyByTitle={ handleSort } />
                        
                        { sortedProductEducationSearchResults.length === 0 ? (
                            <NoResults />
                        ) : (null) }

                        { productEducationSearchResultsError ? (
                            <ErrorMessage message={ productEducationSearchResultsError } />
                        ) : (null) }
                    </React.Fragment>
                ) }
        </>
    );
}

export default ProductEducationSub;