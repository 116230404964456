import * as ActionTypes from '../actions/ActionTypes';

const initState = {
    specialty: [],
    error: '',
    isFetchingSpecialty: false,

    isFetchingTalkTrackSingle: false,
    groupTrackSingle: {},
    groupTrackSingleError: '',

    groupTrackAudio: [],
    groupTrackAudioError: '',

    stakeholders: [],
    stakeholdersError: '',
    isFetchingStakeholders: false
}

const tracksReducers = (state = initState, action) => {
    switch (action.type)
    {

        case ActionTypes.REQUEST_SPECIALTY:
            return {
                ...state,
                isFetchingSpecialty: true
            }

        case ActionTypes.GET_SPECIALTY_SUCCESS:
            return {
                ...state,
                specialty: action.specialty,
                error: '',
                isFetchingSpecialty: false
            }

        case ActionTypes.GET_SPECIALTY_ERROR:
            return {
                ...state,
                error: action.error,
                isFetchingSpecialty: false
            }

        case ActionTypes.REQUEST_TALK_TRACK_SINGLE:
            return {
                ...state,
                isFetchingTalkTrackSingle: true
            }

        case ActionTypes.GET_GROUP_TRACK_SINGLE_SUCCESS:
            return {
                ...state,
                groupTrackSingle: action.groupTrackSingle,
                groupTrackSingleError: '',
                isFetchingTalkTrackSingle: false
            }

        case ActionTypes.GET_GROUP_TRACK_SINGLE_ERROR:
            return {
                ...state,
                groupTrackSingleError: action.groupTrackSingleError,
                isFetchingTalkTrackSingle: false
            }

        case ActionTypes.GET_GROUP_TRACK_AUDIO_SUCCESS:
            return {
                ...state,
                groupTrackAudio: action.groupTrackAudio,
                groupTrackAudioError: ''
            }

        case ActionTypes.GET_GROUP_TRACK_AUDIO_ERROR:
            return {
                ...state,
                groupTrackAudioError: action.groupTrackAudioError
            }

        case ActionTypes.REQUEST_STAKEHOLDERS:
            return {
                ...state,
                isFetchingStakeholders: true
            }

        case ActionTypes.GET_STAKEHOLDERS_SUCCESS:
            return {
                ...state,
                stakeholders: action.stakeholders,
                stakeholdersError: '',
                isFetchingStakeholders: false
            }

        case ActionTypes.GET_STAKEHOLDERS_ERROR:
            return {
                ...state,
                stakeholdersError: action.stakeholdersError,
                isFetchingStakeholders: false
            }

        default:
            return state;
    }
}

export default tracksReducers;