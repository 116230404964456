import React, { useState, useEffect } from "react";
import { Container, Row, Col, Modal } from "reactstrap";
import SummaryList from "../../../TrialSummary/SummaryList";
import trialSummaryClasses from "../../../TrialSummary/TrialSummary.module.css";
import InputElementclasses from '../../../MultipleStepsModalsForm/InputElements.module.css';
import classes from '../FormElements.module.css';
import { useDispatch, useSelector } from 'react-redux';

import {
    postNewTrial,
} from '../../../../../Store/actions/trialsAction';
import EditCards from "../../../TrialSummary/Forms/EditCards/EditCards";
import EditCardCompetitors from "../../../TrialSummary/Forms/EditCards/EditCardsCompetitors";
import listClasses from '../../../../../Components/List/List.module.css';
import ProfileImage from "../../../../../Utils/ProfileImage";
import CreateRatingCircles from "../../../../../Utils/CreateRatingCircles/CreateRatingCircles";
import ComponentLoading from "../../../../../Components/ComponentLoading/ComponentLoading";
import NoResults from "../../../../../Components/NoResults/NoResults";

const ReviewAndSubmit = ({customer, details, people, specialties, competitors, goToPage, trialDetails, finishSetup}) => {

    const redirectToNewTrial = (trialID) => {
        window.location.replace(`/trial/${ trialID }`)
    }

    const dispatch = useDispatch();
    const submitTrialDetails = () => { 
        trialDetails.start_date = formatDateToMonthDayYear(trialDetails.start_date);
        trialDetails.end_date = formatDateToMonthDayYear(trialDetails.end_date);
        trialDetails.budget_cycle = formatDateToMonthDayYear(trialDetails.budget_cycle);
        trialDetails.expected_purchase = formatDateToMonthDayYear(trialDetails.expected_purchase);
        trialDetails.people_id = people;

        dispatch(postNewTrial(trialDetails, redirectToNewTrial));
    }

    const formatDateToMonthDayYear = (date) => {
        if (date) {
            return `${ date.getMonth() + 1 } - ${ date.getDate() } - ${ date.getFullYear() }`;
        }

        return date;
    };

    details.start_date = formatDateToMonthDayYear(details.start_date);
    details.end_date = formatDateToMonthDayYear(details.end_date);
    details.budget_cycle = formatDateToMonthDayYear(details.budget_cycle);
    details.expected_purchase = formatDateToMonthDayYear(details.expected_purchase);
    
    return (
        <>
            <Container className="pb-80">
                <Row className={classes['Form__section-container']}>
                    <Col sm={ 12 }>
                        <div className="pb-0">
                            <div className={ [ trialSummaryClasses[ "trial-summary__card-title" ]].join(" ") }>
				<h2 className={classes['form__title']}>Customer</h2> <i className={ [ "las la-edit", trialSummaryClasses[ "trial-summary__pencil-icon" ] ].join(" ") } onClick={()=> goToPage(0)}></i>
                            </div>
                            <div className="line-blk-shrink mb-1" />
                            <p><span className="futurastdbold">{ customer.name }</span><br/>{customer.address}<br/>{customer.total_trial} TRIALS</p>
                        </div>

                       
                    </Col>
                </Row>
                 <div className={classes['Form__section-container']}>
                            <div className={ [ trialSummaryClasses[ "trial-summary__card-title" ]].join(" ") }><h2 className={classes['form__title']}>Trial Details</h2><i className={ [ "las la-edit", trialSummaryClasses[ "trial-summary__pencil-icon" ] ].join(" ") } onClick={()=> goToPage(1)}></i></div>
                            <div className="line-blk-shrink" />

                            <div>
                                <div className="trial-name">
                                    <p className={['futurastdbook']}>TRIAL NAME</p>
                                    <p className={ trialSummaryClasses[ "trial-details__information" ] }>
                                        { details.trial_name }
                                    </p>
                                </div>
                                    <div className="trial-name">
                                        <p  className={['futurastdbook']}>TRIAL START DATE</p>
                                        <p className={ trialSummaryClasses[ "trial-details__information" ] }>
                                            { details.start_date}
                                        </p>
                                    </div>
                                    <div className="trial-name">
                                        <p  className={['futurastdbook']}>TRIAL END DATE</p>
                                        <p className={ trialSummaryClasses[ "trial-details__information" ] }>
                                            { details.end_date }
                                        </p>
                                    </div>
                                    <div className="trial-name">
                                        <p className={['futurastdbook']}>BUDGET CYCLE</p>
                                        <p className={ trialSummaryClasses[ "trial-details__information" ] }>
                                            { details.budget_cycle }
                                        </p>
                                    </div>
                                    <div className="trial-name ">
                                        <p className={['futurastdbook']}>EXPECTED PURCHASE</p>
                                        <p className={ trialSummaryClasses[ "trial-details__information" ] }>
                                            { details.expected_purchase }
                                        </p>
                                    </div>
                            </div>

                        </div>

                <div  className={classes['Form__section-container']}>
                    <div className={ [ trialSummaryClasses[ "trial-summary__card-title" ] ].join(" ") }><h2 className={classes['form__title']}>People</h2>
						 <i className={ [ "las la-edit", trialSummaryClasses[ "trial-summary__pencil-icon" ] ].join(" ") } onClick={()=> goToPage(2)}></i>
                    </div>
                    <div className="line-blk-shrink mb-1" />
                    <div>
                        {people.map((item) => {
                            return (
                                <div className={[ listClasses['list__item'] ]} htmlFor={`person-${item.id}`} key={item.id}>
                                    <div className={listClasses['list__item-profile-image']}>
                                        <ProfileImage
                                            profile={{ id: item.id, profileImage: item.profile_image }}
                                            cb={null}
                                            profileImageClasses="profile__image--small"
                                        />
                                    </div>

                                    <div className={[ listClasses['list__item-content'], listClasses['-line-height'] ].join(' ')}>
                                        <div className={listClasses['list__item-name']}>{item.name}</div>
                                        <div className="circles">
                                            <CreateRatingCircles amountOfCircles={item.rating} />
                                        </div>
                                        <div className={listClasses['list__item-gray']}>specialty / title: {item.type} </div>
                                        <div className={listClasses['list__item-gray']}>email: {item.email} </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>  
                </div>
			

                <Row className={classes['Form__section-container']}>
                    <Col sm={ 12 }>
                        <div className=" mt-0" >
                            <div className={ [ trialSummaryClasses[ "trial-summary__card-title" ] ].join(" ") }>
                                <h2 className={classes['form__title']}>Specialties</h2> <i className={ [ "las la-edit", trialSummaryClasses[ "trial-summary__pencil-icon" ] ].join(" ") } onClick={()=> goToPage(3)}></i>
                            
                            </div>
                            <div className="line-blk-shrink"> </div>
                            <div className={ [ trialSummaryClasses[ "trial-summary__card-images-container" ]].join(" ") }>
                                { specialties?.length ? specialties.map(SummaryList) :
                                    (
                                        specialties?.length === 0 ? (
                                            <NoResults message="no specialties selected" />
                                        ) : (
                                            <ComponentLoading message="Loading..."/>
                                        )
                                    ) }
                            </div>
                           {specialties.map((item) => {

				return (
					<label
						htmlFor={item.name}
						key={item.id}
						className={[
							trialSummaryClasses['trial-summary__image-container'],
							'specialty_select_list_item'
						].join(' ')}
					>
						<span
							className={[
								InputElementclasses['form__select-image-border'],
							].join(' ')}
						>
							<img
								className={[
									trialSummaryClasses['trial-summary__card-image--specialty'],
									'specialty_selected'
								].join(' ')}
								src={item.icon_selected}
								alt={item.name}
							/>
							<img
								className={[
									trialSummaryClasses['trial-summary__card-image--specialty'],
									'specialty_not_selected'
								].join(' ')}
								src={item.icon}
								alt={item.name}
							/>
						</span>

						<input
							value={item.name}
							id={item.name}
							className="hidden"
							name={"Specialty"}
							type="radio"
						/>
						<span className={[ trialSummaryClasses['trial-summary__card-name'] ].join(' ')}>
							{item.name}
						</span>
					</label>
				);
			})}
                        </div>
                    </Col>
                </Row>
                <Row className={classes['Form__section-container']}>
                    <Col sm={ 12 }>
                        <div className="mt-0">
                            <div className={ [ trialSummaryClasses[ "trial-summary__card-title" ] ].join(" ") }>
                                <h2 className={classes['form__title']}>Competitors</h2> <i className={ [ "las la-edit", trialSummaryClasses[ "trial-summary__pencil-icon" ] ].join(" ") } onClick={()=> goToPage(4)}></i>
                                
                            </div>
                            <div className="line-blk-shrink" />
                            <div className={ [ trialSummaryClasses[ "trial-summary__card-select-container--competitors" ]].join(" ") }>
                                { competitors?.length ? competitors.map(SummaryList) :
                                    (
                                        competitors?.length === 0 ? (
                                            <NoResults message="no competitors selected" />
                                        ) : (
                                            <ComponentLoading message="Loading..."/>
                                        )
                                    ) }
                            </div>
                           {competitors.map((item) => {
				let imageURL = `${process.env.REACT_APP_API_URL}images/competitors/`;
				let imageName = item.name.toLowerCase().replace(' ', '-').replace('&', '-');
				imageURL = imageURL + imageName + '.png';
				return (
					<label
						htmlFor={item.name}
						key={item.id}
						className={[
							trialSummaryClasses['trial-summary__image-container'],
							'customer_select_list_item'
						].join(' ')}
					>
						<span
							className={[
								InputElementclasses['form__select-image-border'],
							].join(' ')}
						>
							<img
								className={[
									trialSummaryClasses['trial-summary__card-select-image--competitors'],
									'competitor_img'
								].join(' ')}
								src={imageURL}
								alt={item.name}
							/>
						</span>

						<input
							value={item.name}
							id={item.name}
							className="hidden"
							name={'Competitor'}
							type="radio"
						/>
						<span
							className={[
								trialSummaryClasses['trial-summary__card-name'],
							].join(' ')}
						>
							{item.name}
						</span>
					</label>
				);
			})}
                        </div>
                    </Col>
                </Row>
            
                <button className={classes['form_final-submit']} onClick={submitTrialDetails}>START CRUSHING IT</button>
            </Container>
        </>
    );
}


export default ReviewAndSubmit;
