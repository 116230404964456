import React, { Component } from 'react';

import createCaseModalClasses from '../CreateCaseModal/CreateCaseModal.module.css';
import classes from './AddAndEditVerbatim.module.css';

class AddAndEditVerbatim extends Component {
    state = {
        verbatims: [],
        filledOnce: false
    }

    static getDerivedStateFromProps (props, state) {

        if (props.verbatims && state.filledOnce === false)
        {
            return {
                verbatims: props.verbatims,
                filledOnce: true
            }
        }

        return null;
    }

    addVerbatim = (ev) => {
        ev.preventDefault();
        let cloneVerbatims = JSON.parse(JSON.stringify(this.state.verbatims));

        if (this.props.verbatimsFromEditOrAddPage === 'edit')
        {
            cloneVerbatims.push({
                name: '',
                description: ''
            });
        } else
        {
            cloneVerbatims.push({
                customerName: '',
                verbatim: ''
            });
        }

        this.props.updateVerbatim(cloneVerbatims);
        return this.setState({ verbatims: cloneVerbatims });
    }

    removeVerbatim (index) {
        let cloneVerbatims = JSON.parse(JSON.stringify(this.state.verbatims));
        cloneVerbatims.splice(index, 1);

        this.props.updateVerbatim(cloneVerbatims);
        return this.setState({ verbatims: cloneVerbatims });
    }

    handleVerbatimsChange = (ev, index) => {
        let cloneVerbatims = JSON.parse(JSON.stringify(this.state.verbatims));
        cloneVerbatims[ index ][ ev.currentTarget.id ] = ev.currentTarget.value;

        this.props.updateVerbatim(cloneVerbatims)
        this.setState({
            verbatims: cloneVerbatims
        });
    }

    render () {
        const { title, verbatimsFromEditOrAddPage } = this.props;
        let customerName = 'customerName';
        let verbatim = 'verbatim';

        if (verbatimsFromEditOrAddPage === 'edit')
        {
            customerName = 'name';
            verbatim = 'description';
        }

        return (
            <div className={ createCaseModalClasses[ "card" ] }>
                <div className={ createCaseModalClasses[ "card__header" ] }>
                    <h3 className={ createCaseModalClasses[ "card__title" ] }>{ title }</h3>
                </div>
                <div className={ classes[ "card-verbatims-container" ] }>

                    { this.state.verbatims.map((verbatimObject, index) => {
                        return (
                            <div
                                key={ index }
                                className={ classes[ "card-verbatim" ] }>
                                <i
                                    onClick={ () => this.removeVerbatim(index) }
                                    className={ [ classes[ "card-verbatim__remove-icon" ], "las la-times-circle" ].join(" ") }></i>
                                <div>
                                    <label
                                        className={ [ classes[ "card-verbatim__label" ], classes[ "card-verbatim__label-input" ] ].join(" ") }
                                        htmlFor={ customerName }>
                                        CUSTOMER NAME
                                    </label>
                                    <input className={ createCaseModalClasses[ "input" ] }
                                        type="text"
                                        name={ customerName }
                                        id={ customerName }
                                        placeholder="customer name"
                                        value={ this.state.verbatims[ index ][ customerName ] }
                                        onChange={ (ev) => this.handleVerbatimsChange(ev, index) } />
                                </div>
                                <div>
                                    <label
                                        className={ [ classes[ "card-verbatim__label" ], classes[ "card-verbatim__label-textarea" ] ].join(" ") }
                                        htmlFor={ verbatim }>
                                        VERBATIM
                                    </label>
                                    <textarea
                                        value={ this.state.verbatims[ index ][ verbatim ] }
                                        onChange={ (ev) => this.handleVerbatimsChange(ev, index) }
                                        className={ createCaseModalClasses[ "input--textarea" ] }
                                        placeholder="verbatim"
                                        type="textarea" name={ verbatim } id={ verbatim } />
                                </div>
                            </div>
                        )
                    }) }

                    <button
                        className={ classes[ "button" ] }
                        onClick={ this.addVerbatim }>
                        add another verbatim
                    </button>
                </div>
            </div>
        )
    }
}

export default AddAndEditVerbatim;