import * as ActionTypes from './ActionTypes';

import API from '../../API/API';

export const getCaseAction = (trialID) => {
	return (dispatch, getState) => {
		const parameters = {
			user_id: localStorage.getItem('user_id'),
			trial_id: trialID,
			page: ''
		};

		dispatch({ type: ActionTypes.REQUEST_GET_CASE });

		API()
			.post('/api/v7/case/list', parameters)
			.then((res) => {
				if (res.data.success === '0')
					return dispatch({ type: ActionTypes.GET_CASE_ERROR, caseError: res.data.message });
				return dispatch({ type: ActionTypes.GET_CASE_SUCCESS, case: res.data.data });
			})
			.catch((err) => {
				console.log(err);
				return dispatch({ type: ActionTypes.GET_CASE_ERROR, caseError: err.message });
			});
	};
};

export const getSurgeonsForCreatingAndUpdatingACaseAction = ({ trialID, currentPageNumber = 1, keyword = '' }) => {
	return (dispatch, getState) => {
		const parameters = {
			trial_id: trialID,
			page: currentPageNumber,
			keyword: keyword.toLowerCase().trim()
		};

		dispatch({ type: ActionTypes.REQUEST_CASE_SURGEONS });

		API()
			.post('/api/v7/case/searchsurgeon', parameters)
			.then((res) => {
				return dispatch({
					type: ActionTypes.GET_CASE_SURGEONS_SUCCESS,
					surgeonsForCreatingACase: res.data.data,
					currentPageNumber: currentPageNumber
				});
			})
			.catch((err) => {
				console.log(err);
				return dispatch({
					type: ActionTypes.GET_CASE_SURGEONS_ERROR,
					surgeonsForCreatingACaseError: err.message
				});
			});
	};
};

export const createCaseAction = (
	{ trialID, surgeonIDs, procedureName, quantity, procedureDate,utilizedICG, yourCaseNote, verbatims },
	toggleCreateCaseModal
) => {
	return (dispatch) => {
		const parameters = {
			trial_id: trialID,
			user_id: localStorage.getItem('user_id'),
			surgeon_id: surgeonIDs.join(','),
			procedure: procedureName,
			case_quantity: quantity,
			case_date: procedureDate,
			utilized_icg: utilizedICG,
			note: yourCaseNote,
			verbatims: verbatims.map((verbatim) => {
				return {
					name: verbatim.customerName,
					description: verbatim.verbatim
				};
			})
		};

		console.log('ENTERED');
		API()
			.post('/api/v7/case/create', parameters)
			.then((res) => {
				console.log(res);
				window.gtag('event', 'Create New', {
					event_category: 'Cases',
					event_label: procedureName
				});
				if (res.data.success === '0')
					return dispatch({ type: ActionTypes.POST_CASE_ERROR, postCaseError: res.data.message });
				toggleCreateCaseModal();
				dispatch(getCaseAction(trialID));
				dispatch({ type: ActionTypes.POST_CASE_SUCCESS });
			})
			.catch((err) => {
				console.log(err);
				return dispatch({ type: ActionTypes.POST_CASE_ERROR, postCaseError: err.message });
			});
	};
};

export const getSurgeonAction = ({ trialID, surgeonID }) => {
	return (dispatch, getState) => {
		const parameters = {
			trial_id: trialID,
			surgeon_id: surgeonID,
			user_id: localStorage.getItem('user_id'),
			page: 1
		};

		dispatch({ type: ActionTypes.REQUEST_GET_SURGEON });

		API()
			.post('/api/v7/case/surgeoncases', parameters)
			.then((res) => {
				if (res.data.success === '0') {
					return dispatch({ type: ActionTypes.GET_SURGEON_ERROR, surgeonError: res.data.message });
				}
				return dispatch({ type: ActionTypes.GET_SURGEON_SUCCESS, surgeon: res.data.data });
			})
			.catch((err) => {
				console.log(err);
				return dispatch({ type: ActionTypes.GET_SURGEON_ERROR, surgeonError: err.message });
			});
	};
};

export const getCaseDetailsForEditAndDeletePageAction = ({ trialID, surgeonID, caseID }) => {
	return (dispatch) => {
		const parameters = {
			trial_id: trialID,
			surgeon_id: surgeonID,
			user_id: localStorage.getItem('user_id'),
			case_id: caseID,
			page: 1
		};

		API()
			.post('/api/v7/case/detail', parameters)
			.then((res) => {
				return dispatch({
					type: ActionTypes.GET_CASE_DETAILS_FOR_EDIT_AND_DELETE_PAGE_SUCCESS,
					getCaseDetailsForEditAndDeletePage: res.data.data
				});
			})
			.catch((err) => {
				console.log(err);
				return dispatch({
					type: ActionTypes.GET_CASE_DETAILS_FOR_EDIT_AND_DELETE_PAGE_ERROR,
					getCaseDetailsForEditAndDeletePageErrorrgeonError: err.message
				});
			});
	};
};

export const addSurgeonToTrialAction = ({ trialID, surgeonID, caseID }) => {
	return (dispatch) => {
		const parameters = {
			trial_id: trialID,
			surgeon_id: surgeonID,
			user_id: localStorage.getItem('user_id'),
			case_id: caseID,
			page: 1
		};

		API()
			.post('/api/v7/case/surgeonaddtotrial', parameters)
			.then((res) => {
				return dispatch({ type: ActionTypes.POST_SURGEON_TO_TRIAL_SUCCESS });
			})
			.catch((err) => {
				console.log(err);
				return dispatch({
					type: ActionTypes.POST_SURGEON_TO_TRIAL_ERROR,
					postSurgeonToTrialError: err.message
				});
			});
	};
};

export const deleteSurgeonFromTrialAction = ({ surgeonID, caseID, trialID, surgeonIDFromTrial }) => {
	return (dispatch) => {
		const parameters = {
			surgeon_id: surgeonID,
			case_id: caseID
		};

		const parametersToGetCaseDetails = {
			surgeonID: surgeonIDFromTrial,
			caseID,
			trialID
		};

		API()
			.post('api/v7/case/removesurgeon', parameters)
			.then((res) => {
				dispatch(getCaseDetailsForEditAndDeletePageAction(parametersToGetCaseDetails));
				return dispatch({ type: ActionTypes.DELETE_SURGEON_FROM_TRIAL_SUCCESS });
			})
			.catch((err) => {
				console.log(err);
				return dispatch({
					type: ActionTypes.DELETE_SURGEON_FROM_TRIAL_ERROR,
					deleteSurgeonFromTrialError: err.message
				});
			});
	};
};

export const updateCaseAction = (
	{ surgeon, surgeonID, caseID, trialID, procedureName, procedureDate, yourCaseNotes, quantity,utilizedICG, verbatims },
	goBackAPage
) => {
	return (dispatch) => {
		const parameters = {
			surgeon_id: surgeonID,
			case_id: caseID,
			trial_id: trialID,
			procedure: procedureName,
			case_quantity: quantity,
			case_date: procedureDate,
			utilized_icg: utilizedICG,
			note: yourCaseNotes,
			verbatims: verbatims,
			user_id: localStorage.getItem('user_id')
		};

		const parametersToGetCaseDetails = {
			surgeonID: surgeon,
			caseID,
			trialID: trialID
		};

		API()
			.post('/api/v7/case/update', parameters)
			.then((res) => {
				if (goBackAPage) {
					goBackAPage();
				}
				dispatch(getCaseDetailsForEditAndDeletePageAction(parametersToGetCaseDetails));
				return dispatch({ type: ActionTypes.PUT_CASE_SUCCESS, updateCaseError: '' });
			})
			.catch((err) => {
				console.log(err);
				return dispatch({ type: ActionTypes.PUT_CASE_ERROR, updateCaseError: err.message });
			});
	};
};

export const deleteCaseAction = ({ caseID, trialID }, goBackAPage) => {
	return (dispatch) => {
		const parameters = {
			case_id: caseID,
			trial_id: trialID
		};

		API()
			.post('/api/v7/case/delete', parameters)
			.then((res) => {
				goBackAPage();
				return dispatch({ type: ActionTypes.DELETE_CASE_SUCCESS, deleteCaseError: '' });
			})
			.catch((err) => {
				console.log(err);
				return dispatch({ type: ActionTypes.DELETE_CASE_ERROR, deleteCaseError: err.message });
			});
	};
};

export const downloadCasesPDFSummary = (trialID) => {
	return (dispatch) => {
		const parameters = {
			trial_id: trialID,
			page: '1'
		};
		API()
			.post('/api/v7/case/getCasesReport', parameters)
			.then((res) => {
				if (res.data.success === '1') {
					window.open(res.data.data.PDFUrl, '_blank');
				}
			})
			.catch((error) => {});
	};
};
export const downloadVerbatimsPDFSummary = (trialID) => {
	return (dispatch) => {
		const parameters = {
			trial_id: trialID
		};
		API()
			.post('/api/v7/case/getVerbatimsReport', parameters)
			.then((res) => {
				if (res.data.success === '1') {
					window.open(res.data.data.PDFUrl, '_blank');
				}
			})
			.catch((error) => {});
	};
};
