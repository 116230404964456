import React from 'react';

import LogoPreview from '../../Shared/LogoPreview/LogoPreview';
import Footer from '../../Shared/Footer/Footer';

import classes from '../CustomizeEmail.module.css';
import emailResultsClasses from '../../EmailResults/EmailResults.module.css';
import PreTrialCommunicationThankYouClasses from '../../EmailPreview/PreTrialCommunicationThankYou/PreTrialCommunicationThankYou.module.css';

const PreTrialCommunicationThankYouCommunications = ({ inputs, handleInputChange, formValidationErrors }) => {
    return (
        <div className="customize-email__preview-wrapper">
            <div className={ classes[ "preview-wrapper-box-shadow" ] }>
                <LogoPreview />
                <div className={ classes[ "preview__header" ] }>
                    <div className={ emailResultsClasses[ "email-results-preview__header-left" ] }>
                        <div className={ emailResultsClasses[ "email-results-preview__title" ] }>
                            <label htmlFor="account_name" className="hidden">
                                account name
                                </label>
                            <textarea
                                onChange={ handleInputChange }
                                value={ inputs.account_name || '' }
                                name="account_name"
                                id="account_name"
                                className="w-100 mb-2 d-block"></textarea>

                            { formValidationErrors?.account_name ? (
                                <span className={ classes[ "input__error" ] }>{ formValidationErrors?.account_name }</span>
                            ) : (null) }
                        </div>
                        <div className={ emailResultsClasses[ "email-results-preview__headline" ] }>
                            <label htmlFor="head_line" className="hidden">
                                headline
                            </label>
                            <textarea
                                onChange={ handleInputChange }
                                value={ inputs.head_line || '' }
                                name="head_line"
                                id="head_line"
                                className="w-100 mb-2 d-block"></textarea>

                            { formValidationErrors?.head_line ? (
                                <span className={ classes[ "input__error" ] }>{ formValidationErrors?.head_line }</span>
                            ) : (null) }
                        </div>
                    </div>

                    <div className={ PreTrialCommunicationThankYouClasses[ "email-preview__header-right" ] }>
                        <img
                            className={ emailResultsClasses[ "email-results-preview__header-image" ] }
                            src={`${process.env.REACT_APP_API_URL}images/recap-111.png`} alt="workstation" />
                    </div>
                </div>

                <div className={ PreTrialCommunicationThankYouClasses[ "email-preview-border-wrapper" ] }>
                    <div className={ PreTrialCommunicationThankYouClasses[ "email-preview-border" ] }></div>
                </div>

                <div className="customize-email__content-textarea-container">
                    <label htmlFor="content" className="hidden">
                        content
                    </label>
                    <textarea
                        onChange={ handleInputChange }
                        value={ inputs.content || '' }
                        name="content"
                        id="content"
                        cols="75"
                        rows="15"
                        className="customize-email__content-textarea"></textarea>

                    { formValidationErrors?.content ? (
                        <span className={ classes[ "input__error" ] }>{ formValidationErrors?.content }</span>
                    ) : (null) }
                </div>
            </div>

            <div className={ classes[ "preview__subsection" ] }>
                <div className={ classes[ "pre-trial-subsection-wrapper" ] }>
                    <label
                        className="hidden"
                        htmlFor="header_two">
                        header two
                    </label>

                    <textarea
                        onChange={ handleInputChange }
                        value={ inputs.header_two }
                        id="header_two"
                        name="header_two"
                        className={ classes[ "preview__subsection-textarea" ] }></textarea>

                    { formValidationErrors?.header_two ? (
                        <span className={ classes[ "input__error" ] }>{ formValidationErrors?.header_two }</span>
                    ) : (null) }
                </div>

                <div className={ [ emailResultsClasses[ "email-results-border" ], emailResultsClasses[ "-thin" ] ].join(" ") }></div>
            </div>

            <a
                className={ classes[ "pre-trial__video-link" ] }
                href={ inputs.video }
                title="video player"
                target="_blank"
                rel="noopener noreferrer">
                <img
                    className={ classes[ "pre-trial__video-link-image" ] }
                    src="/images/email/video-banner.png" alt="video" />
            </a>

            <Footer text={ inputs.footer } containerClasses={ classes[ "email-preview__footer" ] } />
        </div>
    )
}

export default PreTrialCommunicationThankYouCommunications;