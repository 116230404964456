import React, { createRef, Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	getSurgeonsForCreatingAndUpdatingACaseAction,
	updateCaseAction,
	addSurgeonToTrialAction
} from '../../../../../Store/actions/casesAction';

import { FormGroup } from 'reactstrap';
import AddATeammateClasses from '../../../AddATeammate/AddATeammate.module.css';
import classes from './SearchForSurgeonToAdd.module.css';
import NoResults from '../../../../../Components/NoResults/NoResults';
import ComponentLoading from '../../../../../Components/ComponentLoading/ComponentLoading';

import { debounce, cloneDeep } from 'lodash';

class SearchForSurgeonToAdd extends Component {
	searchSurgeonRef = createRef();

	state = {
		searchForSurgeonsToAdd: '',
		currentPageNumber: 1,
		isLoadingMore: false
	};

	debounceGetCaseSurgeons = debounce((parameters) => {
		this.props.getCaseSurgeons(parameters);
	}, 250);

	handleChange = (event) => {
		if (this.state.isLoadingMore) {
			return;
		}

		const { searchSurgeonRef } = this;

		if (event.currentTarget.value !== '') {
			searchSurgeonRef.current.classList.remove('hidden');
		} else {
			searchSurgeonRef.current.classList.add('hidden');

			return this.setState({ searchForSurgeonsToAdd: '' });
		}

		this.setState({
			searchForSurgeonsToAdd: event.currentTarget.value,
			isLoadingMore: false,
			currentPageNumber: 1
		});

		const trialID = this.props.match.params.id;
		this.debounceGetCaseSurgeons({ trialID, currentPageNumber: 1, keyword: event.currentTarget.value });
	};

	choosePerson = (surgeon) => {
		const { searchSurgeonRef } = this;
		const { id, caseID, surgeonID } = this.props.match.params;
		searchSurgeonRef.current.classList.add('hidden');

		this.setState({ searchForSurgeonsToAdd: '' });

		let cloneSurgeons = this.props.caseDetails.surgeons.map((s) => (s.id ? s.id : s));
		cloneSurgeons.push(surgeon);
		let currentState = this.props.getCurrentState();

		this.props.updateCase({
			surgeon: surgeonID,
			trialID: id,
			surgeonID: cloneSurgeons.join(','),
			caseID: caseID,
			...currentState
		});
	};

	componentDidUpdate(prevProps) {
		if (prevProps.isFetchingCaseSurgeons === true && this.props.isFetchingCaseSurgeons === false) {
			this.setState({
				isLoadingMore: false
			});
		}
	}

	handleScroll = (event) => {
		const reachedBottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
		const trialID = this.props.match.params.id;

		if (reachedBottom && !this.props.isFetchingCaseSurgeons && !this.props.isLastPageInSurgeonsForCreatingACase) {
			this.setState(
				(previousState) => ({
					currentPageNumber: previousState.currentPageNumber + 1,
					isLoadingMore: true
				}),
				() => {
					this.props.getCaseSurgeons({
						trialID,
						currentPageNumber: this.state.currentPageNumber,
						keyword: this.state.searchForSurgeonsToAdd
					});
				}
			);
		}
	};

	render() {
		const { searchSurgeonRef, choosePerson } = this;

		let renderCaseSurgeons = !this.props.isFetchingCaseSurgeons;

		if (this.state.isLoadingMore) {
			renderCaseSurgeons = true;
		}

		const surgeonsList =
			this.props.caseSurgeons.length && renderCaseSurgeons ? (
				this.props.caseSurgeons.map((surgeon) => {
					return (
						<div
							className={AddATeammateClasses['drop-down']}
							onClick={() => choosePerson(surgeon.id)}
							key={surgeon.id}
						>
							<div className="d-flex justify-content-between align-items-center">
								<div>
									<div className={AddATeammateClasses['drop-down__name']}>{surgeon.people}</div>
									<div className={AddATeammateClasses['drop-down__specialty']}>
										{surgeon.specialty}
									</div>
								</div>

								{surgeon.is_in_trial_flag === '0' ? (
									<div className="form-add-surgeon__not-in-trial-text">{surgeon.is_in_trial}</div>
								) : null}
							</div>
						</div>
					);
				})
			) : !this.props.isFetchingCaseSurgeons ? (
				<NoResults />
			) : null;

		return (
			<FormGroup className={classes['form-edit-case__search-to-add']}>
				<label className={classes['form-add-surgeon__label']} htmlFor="searchForSurgeonsToAdd">
					SURGEONS
				</label>
				<div className={classes['form-add-surgeon__container-input']}>
					<input
						className={classes['form-add-surgeon__input']}
						type="text"
						name="searchForSurgeonsToAdd"
						id="searchForSurgeonsToAdd"
						onChange={this.handleChange}
						placeholder="Search for a surgeon to add..."
						autoComplete="off"
						value={this.state.searchForSurgeonsToAdd}
						disabled={this.state.isLoadingMore}
					/>
				</div>

				<div
					onScroll={this.handleScroll}
					className={[ AddATeammateClasses['drop-down-container'], 'hidden' ].join(' ')}
					ref={searchSurgeonRef}
				>
					{surgeonsList}

					{this.props.isFetchingCaseSurgeons ? <ComponentLoading removeHeight={true} /> : null}
				</div>
			</FormGroup>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		caseDetails: state.cases.getCaseDetailsForEditAndDeletePage,
		caseDetailsError: state.cases.getCaseDetailsForEditAndDeletePageError,

		caseSurgeons: state.cases.surgeonsForCreatingACase,
		isFetchingCaseSurgeons: state.cases.isFetchingCaseSurgeons,
		isLastPageInSurgeonsForCreatingACase: state.cases.isLastPageInSurgeonsForCreatingACase
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getCaseSurgeons: (parameters) => {
			dispatch(getSurgeonsForCreatingAndUpdatingACaseAction(parameters));
		},

		updateCase: (parameters) => {
			dispatch(updateCaseAction(parameters));
		},

		addSurgeonToTrial: (details) => {
			dispatch(addSurgeonToTrialAction(details));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchForSurgeonToAdd));
