import React, { useState } from 'react';
import TextResponse from './Questions/TextResponse';
import MultipleChoiceQuestion from './Questions/MultipleChoiceQuestion';
import RatingQuestion from './Questions/RatingQuestion';
import YesNoQuestion from './Questions/YesNoQuestion';

import classes from './FormQuestions.module.css';

const FormQuestions = ({ questionsArray = [], answeredQuestionsArray = [], submitAnswers, errors }) => {
	let answeredQuestionsIDS;
	answeredQuestionsIDS = answeredQuestionsArray.map((answeredQuestion) => {
		if (answeredQuestion.questions_type === 'text') {
			return {
				questionID: answeredQuestion.question_id,
				answer: answeredQuestion.question_option_id.join(' ')
			};
		}
		return answeredQuestion.question_option_id;
	});
	answeredQuestionsIDS = answeredQuestionsIDS.flat();

	const [ answers, setAnswers ] = useState({});

	const handleSelectedAnswer = (questionId, selectedAnswer) => {
		const updatedAnswers = answers;
		updatedAnswers[questionId] = selectedAnswer;
		setAnswers(updatedAnswers);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const formattedAnswers = Object.keys(answers).map((questionId) => {
			const selectedOptions = answers[questionId];
			return {
				questionID: questionId,
				selectedOptions: selectedOptions
			};
		});
		submitAnswers(formattedAnswers);
	};
	return (
		<form className={classes['form-survey']} onSubmit={handleSubmit}>
			{!submitAnswers && <div className={classes['form-survey--disabled']} />}

			{questionsArray.map((question, index) => {
				const containsError = errors && errors.includes(question.id) ? 'form_input_error' : '';
				if (question.questions_type === 'text') {
					return (
						<React.Fragment key={question.id}>
							<TextResponse
								answeredQuestionsIDS={answeredQuestionsIDS}
								question={question}
								questionNumber={index}
								selectedAnswer={answers[question.id]}
								selectAnswer={handleSelectedAnswer}
								containsError={containsError}
							/>
						</React.Fragment>
					);
				} else if (question.questions_type === 'multiple') {
					return (
						<React.Fragment key={question.id}>
							<MultipleChoiceQuestion
								answeredQuestionsIDS={answeredQuestionsIDS}
								question={question}
								questionNumber={index}
								selectedAnswer={answers[question.id]}
								selectAnswer={handleSelectedAnswer}
								containsError={containsError}
							/>
						</React.Fragment>
					);
				} else if (question.questions_type === 'rating') {
					return (
						<React.Fragment key={question.id}>
							<RatingQuestion
								answeredQuestionsIDS={answeredQuestionsIDS}
								question={question}
								questionNumber={index}
								selectedAnswer={answers[question.id]}
								selectAnswer={handleSelectedAnswer}
								containsError={containsError}
							/>
						</React.Fragment>
					);
				} else if (question.questions_type === 'yesno') {
					return (
						<React.Fragment key={question.id}>
							<YesNoQuestion
								answeredQuestionsIDS={answeredQuestionsIDS}
								question={question}
								questionNumber={index}
								selectedAnswer={
									answers[question.id] || (answeredQuestionsIDS.length > 0 && answeredQuestionsIDS[0])
								}
								selectAnswer={handleSelectedAnswer}
								containsError={containsError}
							/>
						</React.Fragment>
					);
				} else if (question.questions_type === 'checkbox') {
					return (
						<React.Fragment key={question.id}>
							<YesNoQuestion
								answeredQuestionsIDS={answeredQuestionsIDS}
								question={question}
								questionNumber={index}
								selectedAnswer={
									answers[question.id] || (answeredQuestionsIDS.length > 0 && answeredQuestionsIDS[0])
								}
								selectAnswer={handleSelectedAnswer}
								containsError={containsError}
							/>
						</React.Fragment>
					);
				}
			})}

			{submitAnswers && <button className="sykButton">SUBMIT</button>}
		</form>
	);
};

export default FormQuestions;
