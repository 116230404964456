import React from 'react';

import classes from './AddDashedButton.module.css';

const AddDashedButton = ({ text, handleClick }) => {
    return (
        <button onClick={ handleClick } className={ classes[ "button" ] }>
            <img
                className={ classes[ "button__icon" ] }
                src="/images/icon-plus.png"
                alt="plus symbol" />
            <span className={ classes[ "button__text" ] }>{ text }</span>
        </button>
    );
}

export default AddDashedButton;