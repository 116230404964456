import React, { useRef, useEffect } from 'react';
import { Col } from 'reactstrap';
import classes from './results.module.css';
import './results.css';
import Toggle from 'react-toggle';
import html2canvas from 'html2canvas';

export const RatingResults = ({ question, exportable, onExportToggle, onChartDone }) => {
	useEffect(() => {
		html2canvas(document.getElementById(`barchart-${question.id}`)).then((canvas) => {
			const barChartImage = canvas.toDataURL();
			onChartDone(question.id, barChartImage);
		});
	});

	return (
		<React.Fragment>
			<div>
				<div className={classes['bar-info']}>
					<p>{question.min_rating_label}</p>
					<p>{question.max_rating_label}</p>
				</div>
				<div className="no-of-stats">
					<span className="stronger">{question.question_response_count}</span> TOTAL RESPONSE{question.question_response_count !== '1' ? 'S' : ''}
				</div>
				<div id={`barchart-${question.id}`} className={classes['summary-bars-container']}>
					{question.question_options.map((option) => {
						const progressGradient = `linear-gradient(0deg, #015688 ${option.option_response_percentage}%,#015688 ${option.option_response_percentage}%,#015688 ${option.option_response_percentage}%, #efeff3 ${option.option_response_percentage}%, #efeff3 100%)`;
						const labelFontSize =
							question.question_options.length > 5
								? 100 - (question.question_options.length - 5) * 10
								: 100;
						const labelFontSizePercentage = `${labelFontSize}%`;
						return (
							<div className={classes['result-stats survey-ans-pie']} key={`option-${option.option_id}`}>
								<div className={classes['stats']}>
									<div className={classes['stats-bar']} style={{ background: progressGradient }}>
										<div
											className={classes['stats-percent']}
											style={{ fontSize: labelFontSizePercentage }}
										>
											{parseFloat(option.option_response_percentage).toFixed(0)}%
										</div>
									</div>

									<div className="stats-no">{option.option_response_count}</div>
								</div>
							</div>
						);
					})}

					{/* <BarGraph
						onChartDone={onChartDone}
						className="chart__graph"
						chartId={question.id}
						data={question.question_options.map((option) => {
							return { label: option.options, percentage: option.option_response_percentage };
						})}
					/> */}
				</div>

				<div className="line-grey" />
				<div className="inclue-export">
					<div className="include">
						<i className="las la-file-export circle" />
						INCLUDE IN EXPORT
					</div>

					<Toggle
						onChange={onExportToggle}
						checked={exportable}
						className="toggle-yellow"
						icons={false}
						name="exportable"
						value={`exportable-${question.id}`}
					/>
				</div>
			</div>
		</React.Fragment>
	);
};
