import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { Col, Modal, ModalBody } from "reactstrap";
import StatsTrials from "../../../Section/StatsTrials";
import CrushTrialProgress from "../../../Section/CrushTrialProgress";
import Organization from "../../../Section/Organization";
import Engagement from "../../../Section/Engagement";
import ErrorMessage from "../../../Components/ErrorMessage/ErrorMessage";
import ArchiveTrial from "../TrialStatus/ArchiveTrial";
import UnarchiveTrial from "../TrialStatus/UnarchiveTrial";
import { myAccount } from "../../../Data/database";
import Intelligence from "../../../Section/Intelligence";
import ProfileImage from '../../../Utils/ProfileImage';
import ComponentLoading from "../../../Components/ComponentLoading/ComponentLoading";
import CancelButton from "../../../Components/FormButtons/CancelButton";
import SubmitButton from "../../../Components/FormButtons/SubmitButton";
import CloseModalButton from "../../../Components/CloseModalButton/CloseModalButton";

import { useDispatch, useSelector } from 'react-redux';
import { getTeammatesAction, deleteTeammateAction } from '../../../Store/actions/trialsAction';

import classes from './TrialInfo.module.css';

const TrialInfo = (props) => {
  let pageURL = window.location.href;
  let lastURLSegment = pageURL.substr(pageURL.lastIndexOf('/') + 1).replace(/-/g, ' ');
  const trialSelected = lastURLSegment.replace(/\w\S*/g, w => (w.replace(/^\w/, c => c.toUpperCase())));
  const trialName = trialSelected.replace(/ /g, '-').toLowerCase();

  const { mainCount, crushTrialProgressData, trialStatusText, isFetchingTrialDetails, name } = props;

  const { id } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTeammatesAction(id))
  }, [])

  let teammates = useSelector(state => state.trials.teammates);

  teammates = teammates?.map((user) => {
    return {
      firstName: user.first_name,
      lastName: user.last_name,
      id: user.id,
      profileImage: user.profile_image,
      email: user.email
    }
  });

  const teammatesError = useSelector((state) => state.trials.teammatesError);

  const [ removeUserModal, setRemoveUserModal ] = useState(false);
  const [ removeUserInformation, setRemoveUserInformation ] = useState({});
  const toggleRemoveUserModal = () => setRemoveUserModal(!removeUserModal);

  const handleUserProfileClick = (ev, chosenTeammate) => {
    toggleRemoveUserModal();
    setRemoveUserInformation(chosenTeammate);
  }

  const [ isArchiveTrialModalOpen, setIsArchiveTrialModalOpen ] = useState(false);
  const toggleArchiveTrialModal = () => setIsArchiveTrialModalOpen(previousIsArchiveTrialModalOpen => !previousIsArchiveTrialModalOpen);

  const [ isUnarchiveTrialModalOpen, setIsUnarchiveTrialModalOpen ] = useState(false);
  const toggleUnarchiveTrialModal = () => setIsUnarchiveTrialModalOpen(previousIsUnarchiveTrialModalOpen => !previousIsUnarchiveTrialModalOpen);

  const trialStatusError = useSelector(state => state.trials.putTrialStatusError);

  if (isFetchingTrialDetails)
  {
    return <ComponentLoading
      message="Loading..."
      isFullHeight={ true } />
  }

  return (
    <>
      <div className="page pb-80">
        <div className="tab-title-main pb-2">
          <div>
            <h1 className="section-title-single-lg">{ name } </h1>
            <div className="cuddle">
              { trialStatusText === "active" ? (
                <div
                  onClick={ toggleArchiveTrialModal }
                  className="section-title statusbub mb-1 uppercased cursor">
                  { trialStatusText }
                </div>
              ) : (
                  <div
                    onClick={ toggleUnarchiveTrialModal }
                    className="statusbub trial-status__archived">
                    { trialStatusText }
                  </div>
                ) }
              <div className="line-blk-shrink cuddle" />
            </div>
          </div>

          <StatsTrials mainCount={ mainCount } />
          <Col className="nav-pagelink-no-line pt-0 pb-1">
            <div className="line-blk-expand mb-2" />
            <Link to={ `/trial/${ id }/add-a-teammate` } className="inactive addteammate" >
              <img src="/images/icon-plus.png" className="plus float-left margin-5" alt="plus icon" />
            </Link>

            <div className="flex-center-vertical">
              { (teammates && teammates.length) ? (teammates.map(teammate => {
                return (
                  <ProfileImage key={ teammate.id } profile={ teammate } cb={ handleUserProfileClick } profileImageClasses='margin-5 cursor profile__image--xtra-small' />
                );
              })) : (
                  <Link to={ `/trial/${ id }/add-a-teammate` } className="inactive addteammate" >
                    <img src="/images/bar-vert.jpg" className="bar-vert inactive" alt="vertical bar" /> Add a teammate
                  </Link>
                ) }

              { teammatesError ? (
                <ErrorMessage message={ teammatesError } />
                ) : (null) }
            </div>

          </Col>
        </div>

        <CrushTrialProgress trialName={ trialName } crushTrialProgressData={ crushTrialProgressData } />
        <Organization trialName={ trialName } />
        <Engagement myAccount={ myAccount } />
        <Intelligence />

        <Modal
          id="remove-a-teammate-modal"
          isOpen={ removeUserModal }
          toggle={ toggleRemoveUserModal }
          centered
          backdrop="static">
          <CloseModalButton onClick={ toggleRemoveUserModal } />

          <ModalBody
            className={ classes[ "modal" ] }>
            <h3 className={ classes[ "modal__title" ] }>Remove Teammate</h3>
            <div className={ classes[ "modal__profile" ] }>
              <ProfileImage profile={ removeUserInformation } cb={ toggleRemoveUserModal } profileImageClasses={ `profile__image--small ${ classes[ "modal__profile-icon" ] }` } />
              <div className={ classes[ "modal__information-container" ] }>
                <p className={ classes[ "modal__name" ] }>
                  { removeUserInformation.firstName } { removeUserInformation.lastName }
                </p>
                <p className={ classes[ "modal__email" ] }>
                  { removeUserInformation.email }
                </p>
              </div>
            </div>

            <p className={ classes[ "modal__warning-text" ] }>
              You are removing the above teammmate from this trial.
            </p>

            <p className={ classes[ "modal__warning-subtext" ] }>
              This person will not longer be able to view, contribute, or modify this trial.
            </p>

            <div className={ classes[ "modal__buttons" ] }>
              <CancelButton handleClick={ toggleRemoveUserModal } />

              <SubmitButton 
                handleClick={ () => dispatch(deleteTeammateAction({ trialID: id, teammateID: removeUserInformation.id }, toggleRemoveUserModal)) }
                text='delete' />
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={ isArchiveTrialModalOpen }
          toggle={ toggleArchiveTrialModal }
          centered
          backdrop="static">
          <CloseModalButton onClick={ toggleArchiveTrialModal } />

          <ArchiveTrial toggleArchiveTrialModal={ toggleArchiveTrialModal } currentTrialStatus={ trialStatusText } />
        </Modal>

        <Modal
          isOpen={ isUnarchiveTrialModalOpen }
          toggle={ toggleUnarchiveTrialModal }
          centered
          backdrop="static">
          <CloseModalButton onClick={ toggleUnarchiveTrialModal } />

          <UnarchiveTrial toggleUnarchiveTrialModal={ toggleUnarchiveTrialModal } />
        </Modal>
      </div>
    </>
  );
}

export default TrialInfo;