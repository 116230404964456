import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

const TableTrialsRespondent = (props) => {
	const { respondentFiltered, onSort } = props;

	const [ isAscended, setIsAscended ] = useState(true);

	return (
		<Container className="pb-80">
			<table className="table filelist">
				<thead>
					<tr>
						<th
							onClick={() => {
								setIsAscended(!isAscended);
								onSort('firstname');
							}}
							className="sort-order cursor"
						>
							SORT: A-Z{' '}
							{isAscended ? <i className="las la-angle-up" /> : <i className="las la-angle-down" />}
						</th>
					</tr>
				</thead>

				<tbody className="table-sort">
					{respondentFiltered.map((item) => {
						const splitName = item.name.split(' ');
						const initials = `${splitName[0].charAt(0)}${splitName.length > 1
							? splitName[1].charAt(0)
							: ''}`.toUpperCase();
						return (
							<tr key={item.id}>
								<td className="file-details ">
									<Link
										to={
											item.general_status === '1' ? (
												`review/person/${item.type}/${item.id}`
											) : (
												`fillout/person/${item.type}/${item.id}`
											)
										}
										key={item.id}
										value={item.name}
									>
										<div className="person-details-container ">
											<div className="person-bubble-name"> {initials}</div>
											<div className="person-details">
												<p className="futurastdbold">{item.name}</p>
												<p>{item.title === '' ? '--' : item.title}</p>
												<p className="subtitle-tiny-grey futurastdbook">
													{item.type} |{' '}
													{item.general_status === '1' ? 'Complete' : 'Incomplete'}
												</p>
											</div>
											<div>
												<i className="las la-angle-right" />
											</div>
										</div>
									</Link>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			<hr />
		</Container>
	);
};

export default TableTrialsRespondent;
