import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

const TableCustomers = (props) => {
	let { customersFiltered, onSort } = props;
	let pageURL = window.location.href;
	let trialName = pageURL.substr(pageURL.lastIndexOf('/') + 1);

	const [ isAscended, setIsAscended ] = useState(true);

	return (
		<Container>
			<table className="table filelist">
				<thead>
					<tr>
						<th
							onClick={() => {
								setIsAscended(!isAscended);
								onSort('name');
							}}
							className="sort-order cursor"
						>
							SORT: A-Z{' '}
							{isAscended ? <i className="las la-angle-up" /> : <i className="las la-angle-down" />}
						</th>
					</tr>
				</thead>

				<tbody className="table-sort">
					{customersFiltered.map((item) => {
						const address = `${item.address} ${item.address2} ${item.city}`;
						return (
							<tr key={item.id}>
								<td className="file-details trials">
									<Link to={`/customers/${item.id}`} key={item.id} value={item.name}>
										<div className="person-details-container">
											<div className="person-details">
												<p className={'futurastdbold'}>{item.name}</p>
												<div className="subtitle-tiny-grey">
													{address}
													{address.trim() !== '' && <br />}
													{item.total_cases ? item.total_cases : 0} CASE{item.total_cases != 1 ? 'S' : ''}{' '}
													| {item.total_trial ? item.total_trial : 0} TRIAL{item.total_trial != 1 ? 'S' : ''}
												</div>
											</div>
											<div>
												<i className="las la-angle-right list-details__icon"></i>
											</div>
										</div>
									</Link>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			<hr />
		</Container>
	);
};

export default TableCustomers;
