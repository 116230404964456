/** @format */

import React from "react";

import classes from "./InServiceInvite.module.css";
import emailPreviewClasses from "../EmailPreview.module.css";

import Footer from "../../Shared/Footer/Footer";
import LogoPreview from "../../Shared/LogoPreview/LogoPreview";

const InServiceInviteMed = ({ emailPreview }) => {
  const emailPreviewContent = emailPreview?.content.split(/[\r\n]+/g);

  return (
    <div>
      <LogoPreview />

      <div className={classes["header"]}>
        <div className={classes["header__left"]}>
          <div className={classes["header__heading"]}>
            <div className={emailPreviewClasses["header__text-light"]}>
              {emailPreview.title}
            </div>
            <div className={emailPreviewClasses["header__text-bold"]}>
              {emailPreview.sub_title}
            </div>
          </div>

          <div className={classes["header__image-container"]}>
            <img
              className={classes["header__image"]}
              src="/images/email/inservice-img02.png"
              alt="workstation"
            />
          </div>

          <div className={classes["header__description"]}>
            {emailPreview.title_description}
          </div>
        </div>
      </div>

      <div className={classes["information-container"]}>
        <div className={classes["information"]}>
          <div className={classes["information__image-container"]}>
            <img
              className={classes["information__image"]}
              src="/images/email/icon-clock.png"
              alt="clock icon"
            />
          </div>
          <div className={classes["information__right"]}>
            <div className={classes["information__title"]}>
              {emailPreview.when_title}
            </div>

            <div className={classes["information__text-container"]}>
              <div className={classes["information__text-light"]}>
                {emailPreview.when_content}
              </div>
              <div className={classes["information__text-bold"]}>
                {emailPreview.when_time}
              </div>
            </div>
          </div>
        </div>
        <div className={classes["information"]}>
          <div className={classes["information__image-container"]}>
            <img
              className={classes["information__image"]}
              src="/images/email/icon-location.png"
              alt="clock icon"
            />
          </div>
          <div className={classes["information__right"]}>
            <div className={classes["information__title"]}>
              {emailPreview.where_title}
            </div>

            <div className={classes["information__text-container"]}>
              <div className={classes["information__text-light"]}>
                {emailPreview.where_content}
              </div>
              <div className={classes["information__text-bold"]}>
                {emailPreview.where_content_two}
              </div>
            </div>
          </div>
        </div>
        <div className={classes["information"]}>
          <div className={classes["information__image-container"]}>
            <img
              className={classes["information__image"]}
              src="/images/email/icon-box.png"
              alt="clock icon"
            />
          </div>
          <div className={classes["information__right"]}>
            <div className={classes["information__title"]}>
              {emailPreview.what_title}
            </div>

            <div className={classes["information__text-container"]}>
              <div className={classes["information__text-light"]}>
                {emailPreview.what_content}
              </div>
              <div className={classes["information__text-bold"]}>
                {emailPreview.what_content_two}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes["section"]}>
        <div className={classes["section__text-bold"]}>
          {emailPreview.content_fname_lname}
        </div>

        {emailPreviewContent.map((item, index) => {
          return (
            <div key={index} className={classes["section__text-light"]}>
              {item}
            </div>
          );
        })}
      </div>

      <Footer text={emailPreview.footer} />
    </div>
  );
};

export default InServiceInviteMed;
