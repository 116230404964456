import React from 'react';
import { Col } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import '../assets/css/app.css';

const Engagement = (props) => {
	const { trialName, myAccount } = props;

	const { id } = useParams();

	return (
		<div className="tab-section">
			<Col sm={12} className="tab-tabs">
				<div className="d-board-sect-title pb-1">Engagement</div>
				<div className="toolkit">
					<div className="d-board-item">
						<Link to={`/trial/${id}/surveyevals/`}>
							<div className="d-board-thumb survey" />
							<div>SURVEYS</div>
						</Link>
					</div>
					<div className="d-board-item">
						<Link to={`/trial/${id}/engagement-email`}>
							<div className="d-board-thumb engagement" />
							<div className="">ENGAGEMENT EMAILS</div>
						</Link>
					</div>
					<div className="d-board-item">
						{/* <Link to="/trial/engagement/collateral">
                            <div className="d-board-thumb engagement" />
                            <div className="">COLLATERAL</div>
                        </Link> */}
					</div>
				</div>
			</Col>
		</div>
	);
};

export default Engagement;
