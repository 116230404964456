import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from "reactstrap";
import Search from "../../../Utils/Search";
import classes from './TrialSummary.module.css';

import AddPerson from './Forms/AddPerson/AddPerson';
import PeopleList from './PeopleList/PeopleList';
import ComponentLoading from "../../../Components/ComponentLoading/ComponentLoading";
import CloseModalButton from "../../../Components/CloseModalButton/CloseModalButton";

const TrialPeopleTable = ({ people, type, accountID, isLoading }) => {
    const filterPeopleInTrialSummaryBySearch = (ev) => {
        return people.filter(personInTrialSummary => {
            return personInTrialSummary.name.toLowerCase().includes(searchValue.toLowerCase().trim());
        });
    }
    const [ searchValue, setSearchValue ] = useState('');
    const handleInputChange = (event) => {
        setSearchValue(event.currentTarget.value);
    }

    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const toggleModal = () => setIsModalOpen(previousIsModalOpen => !previousIsModalOpen);

    const [ currentlyEditing, setCurrentlyEditing ] = useState(false);
    const toggleCurrentlyEditing = () => setCurrentlyEditing(previousCurrentlyEditing => !previousCurrentlyEditing);

    return (
        <Row>
            <Col sm={ 12 }>
                <div className="tab-text pb-0 mt-0">
                    <div className={ [ classes[ "trial-summary__card-title" ], "tab-title" ].join(" ") }>
                        { type }
                        { isLoading ? (null) : (
                            currentlyEditing ? (
                                <button
                                    className={ classes[ "trial-summary__card-complete-button" ] }
                                    onClick={ toggleCurrentlyEditing }>
                                    DONE
                                </button>
                            ) : (
                                    <i
                                        onClick={ toggleCurrentlyEditing }
                                        className={ [ "las la-edit", classes[ "trial-summary__pencil-icon" ] ].join(" ") }></i>
                                )
                        ) }
                    </div>
                    <div className="line-blk-shrink mb-0" />
                    { isLoading ? (
                        <ComponentLoading message="Loading..." />
                    ) : (
                            <React.Fragment>
                                <Search searchingValue={ searchValue } handleInputChange={ handleInputChange } />
                                <PeopleList
                                    currentlyEditing={ currentlyEditing }
                                    peopleArray={ filterPeopleInTrialSummaryBySearch() }
                                    type={ type } />
                                <div className={ [ classes[ "add-person-button-container" ], currentlyEditing ? (null) : ("hidden") ].join(" ") }>
                                    <button
                                        onClick={ toggleModal }
                                        className="modal-button modal-button--large">
                                        <img
                                            src="/images/icon-plus.png"
                                            className="modal-button-image"
                                            alt="plus icon" />
                                    </button>
                                </div>
                            </React.Fragment>
                        ) }
                </div>
            </Col>
            <Modal
                isOpen={ isModalOpen }
                toggle={ toggleModal }
                centered
                backdrop="static">
                <CloseModalButton onClick={ toggleModal } />

                <AddPerson
                    accountID={ accountID }
                    toggleModal={ toggleModal }
                    type={ type } />
            </Modal>
        </Row>
    );
}

export default TrialPeopleTable;