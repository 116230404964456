import React, { Component } from "react";

class NavBar extends Component {
    goBack = () => {
        console.log("go back.");
        window.history.back();
    };
    render () {
        return (
            <div className="tab-navbar-preview">
                <div className="close-preview" onClick={ this.goBack } ><i className="las la-times" /></div>
                <div className="preview ">
                    <h4 className="section-title-edit-survey ">Edit Survey</h4>
                </div>
                <div className="navi-save">SAVE</div>
            </div>
        );
    }
}

export default NavBar;

