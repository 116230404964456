import React, { useState, useEffect } from 'react';
import { Modal } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CreateRatingCircles from '../../../../../Utils/CreateRatingCircles/CreateRatingCircles';
import ProfileImage from '../../../../../Utils/ProfileImage';
import Search from '../../Search/Search';
import SelectPersonList from '../../../../../Components/SelectPersonList/SelectPersonList';

import listClasses from '../../../../../Components/List/List.module.css';
import classes from '../FormElements.module.css';

import { getPeopleAction } from '../../../../../Store/actions/peopleActions';
import { resetCreatedPersonIDAction } from '../../../../../Store/actions/trialsAction';
import { useDispatch } from 'react-redux';

import AddPersonalDetails from '../../../MultipleStepsModalsForm/AddPersonalDetails/AddPersonalDetails';
import ComponentLoading from '../../../../../Components/ComponentLoading/ComponentLoading';
import useHandleSubmit from '../../../../../Hooks/useHandleSubmit';
import NoResults from '../../../../../Components/NoResults/NoResults';
import CloseModalButton from '../../../../../Components/CloseModalButton/CloseModalButton';

const SelectPeople = ({
	submittedPeople,
	people,
	getPeople,
	submitPeopleSelection,
	selectedCustomerID,
	createdPersonID,
	isFetchingGetPeople
}) => {
	const [ selectedPeople, setSelectedPeople ] = useState({});
	const [ peopleFetched, setPeopleFetched ] = useState(false);

	const selectPerson = (ev, person) => {
		ev.currentTarget.classList.toggle(classes['list__item--active']);
		const peopleSelected = selectedPeople;
		if (!peopleSelected[person.id]) {
			peopleSelected[person.id] = person;
		} else {
			delete peopleSelected[person.id];
		}
		setSelectedPeople(peopleSelected);
	};

	const [ isModalOpen, setIsModalOpen ] = useState(false);

	const toggleModal = () => setIsModalOpen((previousIsModalOpen) => !previousIsModalOpen);

	const openModalInForm = (ev) => {
		ev.preventDefault();

		toggleModal();
	};

	useEffect(
		() => {
			getPeople();
			if (submittedPeople) setSelectedPeople(submittedPeople);
			setPeopleFetched(true);
		},
		[ peopleFetched ]
	);

	const dispatch = useDispatch();
	useEffect(
		() => {
			if (createdPersonID) {
				let cloneSelectedPeople = JSON.parse(JSON.stringify(selectedPeople));
				let createdPerson = people.filter((person) => person.id === createdPersonID)[0];
				cloneSelectedPeople[createdPersonID] = createdPerson;
				setSelectedPeople(cloneSelectedPeople);
				dispatch(resetCreatedPersonIDAction());
			}
		},
		[ people ]
	);

	const passBackSelectedPeople = () => {
		submitPeopleSelection(selectedPeople);
	};

	const { handleSubmit } = useHandleSubmit({
		callback: passBackSelectedPeople
	});

	const [ searchValue, setSearchValue ] = useState('');

	useEffect(
		() => {
			getPeople(searchValue);
		},
		[ searchValue ]
	);

	const handleClearButtonOnClick = () => {
		setSearchValue('');
	};

	return (
		<form className={classes['form']} onSubmit={handleSubmit}>
			<div className={classes['form__header-row']}>
				<h2 className={classes['form__title']}>Select people</h2>
				<button className={classes['form__button']}>
					next <i className="las la-angle-right" />
				</button>
			</div>
			<div className={classes['form__header-row']}>
				<Search
					searchValue={searchValue}
					onChange={setSearchValue}
					handleClearButtonOnClick={handleClearButtonOnClick}
				/>
				<button onClick={(ev) => openModalInForm(ev)} className={classes['form__image-container']}>
					<img className={classes['form__image']} src="/images/icon-plus.png" alt="plus symbol icon" />
				</button>
			</div>

			{isFetchingGetPeople ? (
				<ComponentLoading message="Loading..." />
			) : (
				<React.Fragment>
					<SelectPersonList
						people={people}
						selectedPeople={selectedPeople}
						personType="person"
						callback={selectPerson}
						refreshPeople={() => getPeople(searchValue)}
					/>

					{ people.length === 0 ? (
						<NoResults />
					) : (null) }
				</React.Fragment>
			)}

			<Modal
				isOpen={ isModalOpen }
				toggle={ toggleModal }
				centered
				backdrop="static">
				<CloseModalButton onClick={ toggleModal } />

				<AddPersonalDetails toggleModal={toggleModal} accountID={selectedCustomerID} noTrialID={true} />
			</Modal>
		</form>
	);
};

const mapStateToProps = (state) => {
	return {
		people: state.people.getPeople,
		createdPersonID: state.trials.createdPersonID,
		isFetchingGetPeople: state.people.isFetchingGetPeople
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getPeople: (searchValue) => {
			dispatch(
				getPeopleAction({
					search: searchValue
				})
			);
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SelectPeople));
