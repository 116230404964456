import React, { Component } from 'react';

import classes from './SurgeonInformationEditAndDelete.module.css';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	getCaseDetailsForEditAndDeletePageAction,
	updateCaseAction,
	deleteCaseAction
} from '../../../../Store/actions/casesAction';

import { Form, Label, Input, Button } from 'reactstrap';
import SearchForSurgeonToAdd from './SearchForSurgeonToAdd/SearchForSurgeonToAdd';
import RemoveSurgeon from './RemoveSurgeon/RemoveSurgeon';
import AddAndEditVerbatim from '../../AddAndEditVerbatim/AddAndEditVerbatim';
import ComponentLoading from '../../../../Components/ComponentLoading/ComponentLoading';
import ErrorMessage from '../../../../Components/ErrorMessage/ErrorMessage';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { formatDateToMonthDayYear, preventTypingInDateInputs } from '../../../../Utils/dates';

import { cloneDeep } from 'lodash';

class SurgeonInformationEditAndDelete extends Component {
	state = {
		procedureName: '',
		procedureDate: '',
		quantity: '',
		yourCaseNotes: '',
		utilizedICG: false,
		verbatims: [],
		filledOnce: false,
		isFetching: true
	};

	static getDerivedStateFromProps(props, state) {
		if (props.caseDetails && 
			props.caseDetails.case_data &&
			props.match.params.caseID === props.caseDetails.case_data.id &&
			!state.filledOnce
		) {
			return {
				procedureName: props.caseDetails.case_data.procedure,
				procedureDate: props.caseDetails.case_data.date,
				quantity: props.caseDetails.case_data.quantity,
				utilizedICG: props.caseDetails.case_data.utilized_icg == '1',
				yourCaseNotes: props.caseDetails.case_data.note,
				filledOnce: true,
				isFetching: false
			};
		}

		return null;
	}

	componentDidMount() {
		const { id, surgeonID, caseID } = this.props.match.params;
		this.props.getCaseDetailsForEditAndDeletePage({ trialID: id, surgeonID, caseID });

	}

	handleChange = (ev) => {
		this.setState({
			[ev.currentTarget.id]: ev.currentTarget.value
		});
	};

	handleSaveAndExit = (ev) => {
		ev.preventDefault();
		const { id, surgeonID, caseID } = this.props.match.params;

		const parameters = {
			surgeonID: surgeonID,
			caseID: caseID,
			trialID: id,
			procedureName: this.state.procedureName,
			quantity: this.state.quantity,
			utilizedICG: this.state.utilizedICG,
			procedureDate: this.state.procedureDate,
			yourCaseNotes: this.state.yourCaseNotes,
			verbatims: this.state.verbatims
		};

		this.props.updateCase(parameters, this.props.history.goBack);
	};

	handleDelete = (ev) => {
		ev.preventDefault();
		const { id, caseID } = this.props.match.params;

		const parameters = {
			caseID: caseID,
			trialID: id
		};

		this.props.deleteCase(parameters, this.props.history.goBack);
	};

	verbatimFromChildComponent = (verbatims) => {
		this.setState({ verbatims: verbatims });
	};

	handleProcedureDateChange = (date) => {
		this.setState({
			procedureDate: formatDateToMonthDayYear(date)
		});
	};

	getCurrentState = () => {
		return cloneDeep(this.state);
	};

	render() {
		if (this.state.isFetching) {
			return <ComponentLoading message="Loading..." isFullHeight={true} />;
		}
		const { surgeons } = this.props.caseDetails;

		const surgeonList = surgeons || [];

		return (
			<div className={classes['edit-surgeon-information']}>
				<h1 className={classes['edit-surgeon-information__title']}>Edit case</h1>
				<Form id="form-edit-case">
					<SearchForSurgeonToAdd getCurrentState={this.getCurrentState} />
					<RemoveSurgeon surgeons={surgeonList} />
					<div>
						<h3 className={classes['form-edit-case-information__title']}>procedure details</h3>
						<div className={classes['form-edit-case-information__container']}>
							<div className={classes['form-edit-case-information__group']}>
								<Label className="hidden" htmlFor="procedureName">
									PROCEDURE NAME
								</Label>
								<Input
									className={classes['form-edit-case-information__input']}
									type="text"
									name="procedureName"
									id="procedureName"
									onChange={this.handleChange}
									placeholder="Procedure Name"
									value={this.state.procedureName}
								/>
							</div>
							<div className="full-width-form-element">
								<div className={classes['form-edit-case-information__group']}>
									<Label className="hidden" htmlFor="procedureDate">
										PROCEDURE DATE
									</Label>
									<div className="position-relative date-inputs-container">
										<DatePicker
											className={classes['form-edit-case-information__input']}
											name="procedureDate"
											id="procedureDate"
											placeholder="procedure date"
											onSelect={this.handleProcedureDateChange}
											onChangeRaw={preventTypingInDateInputs}
											selected={
												this.state.procedureDate ? (
													new Date(this.state.procedureDate.replace(/ /g, ''))
												) : (
													''
												)
											}
										/>
										<i className="las la-calendar input__icon" />
									</div>
								</div>
							</div>
							<div className={classes['form-edit-case-information__group']}>
								<Label className="hidden" htmlFor="quantity">
									QUANTITY
								</Label>
								<Input
									className={classes['form-edit-case-information__input']}
									type="number"
									name="quantity"
									id="quantity"
									value={this.state.quantity}
									onChange={this.handleChange}
									placeholder="Quantity"
								/>
							</div>
							<div className={classes['form-edit-case-information__textarea-container']}>
								<Label className="hidden" for="yourCaseNote">
									YOUR CASE NOTES
								</Label>
								<Input
									type="textarea"
									name="text"
									id="yourCaseNotes"
									className={classes['form-procedure-details__textarea']}
									value={this.state.yourCaseNotes}
									placeholder="Your case notes"
									onChange={this.handleChange}
								/>
							</div>

							<div className={classes['form-edit-case-information__group']}>

								<input
									className={classes['inputCheckbox']}

									type="checkbox"
									name="utilizedICG"
									id="utilizedICG"
									checked={this.state.utilizedICG}
									onChange={(e)=> this.setState({utilizedICG: e.target.checked})}
								/>								
								<Label  htmlFor="utilizedICG">
									UTILIZED ICG
								</Label>
							</div>
						</div>
					</div>

					{/*       <div>
                        <h3 className={ classes[ "form-edit-case-information__title" ] }>
                            equipment used
                        </h3>

                        <div className={ classes[ "equipment-container" ] }>
                            { (this.props.caseDetails && this.props.caseDetails.case_data) ? (this.props.caseDetails.case_data.equipment.map(equipment => {
                                return (
                                    <div
                                        style={ { backgroundColor: (equipment.is_selected === "1" ? ("#b1b4b3") : ("#ffb500")) } }
                                        className={ classes[ "equipment" ] }
                                        key={ equipment.product_id } >
                                        { equipment.name }
                                    </div>
                                )
                            })) : (null) }
                        </div>
                    </div> */}

					<AddAndEditVerbatim
						title="Edit verbatims"
						verbatimsFromEditOrAddPage="edit"
						updateVerbatim={this.verbatimFromChildComponent}
						verbatims={
							this.props.caseDetails.case_data ? this.props.caseDetails.case_data.verbatims : false
						}
					/>

					<div className={classes['form-edit-case__buttons']}>
						<button
							className={[ classes['form-edit-case__save'], classes['button'] ].join(' ')}
							onClick={this.handleSaveAndExit}
						>
							save &amp; exit
						</button>
						<button
							className={[ classes['form-edit-case__delete'], classes['button'] ].join(' ')}
							onClick={this.handleDelete}
						>
							Delete Case
						</button>
					</div>
					{this.props.updateCaseError ? <ErrorMessage message={this.props.updateCaseError} /> : null}

					{this.props.deleteCaseError ? <ErrorMessage message={this.props.deleteCaseError} /> : null}
				</Form>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		caseDetails: state.cases.getCaseDetailsForEditAndDeletePage,
		caseDetailsError: state.cases.getCaseDetailsForEditAndDeletePageError,

		updateCaseError: state.cases.updateCaseError,
		deleteCaseError: state.cases.deleteCaseError
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getCaseDetailsForEditAndDeletePage: (details) => {
			dispatch(getCaseDetailsForEditAndDeletePageAction(details));
		},
		updateCase: (parameters, goBackAPage) => {
			dispatch(updateCaseAction(parameters, goBackAPage));
		},
		deleteCase: (parameters, goBackAPage) => {
			dispatch(deleteCaseAction(parameters, goBackAPage));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SurgeonInformationEditAndDelete));
