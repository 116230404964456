import React, { Component } from 'react';

import CreateOrEditClasses from '../CreateOrEdit/CreateOrEdit.module.css';
import classes from './DuplicatePopUp.module.css';

import { duplicateSurveyAction } from '../../../../Store/actions/surveysAction';
import { duplicateEvaluationAction } from '../../../../Store/actions/evaluationsAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Modal } from 'reactstrap';
import ComponentLoading from '../../../../Components/ComponentLoading/ComponentLoading';
import ErrorMessage from '../../../../Components/ErrorMessage/ErrorMessage';
import CloseModalButton from '../../../../Components/CloseModalButton/CloseModalButton';

class DuplicatePopUp extends Component {
	state = {
		templateName: '',
		saving: false
	};

	cancelPopUp = (ev) => {
		ev.preventDefault();
		this.props.toggleSurveyAndEvaluationDuplicateFormPopUp();
	};

	cloneTemplate = (saveInto) => {
		const { trialID, id, type } = this.props.match.params;

		const templateName = this.state.templateName === '' ? this.props.templateName : this.state.templateName;

		const parameters = {
			trialID: trialID,
			templateID: id,
			templateName: templateName,
			saveInto: saveInto
		};
		this.setState({ saving: true });
		type === 'survey' ? this.props.duplicateSurvey(parameters) : this.props.duplicateEvaluation(parameters);
	};

	handleSurveyNameChange = (ev) => {
		this.setState({
			templateName: ev.currentTarget.value
		});
	};

	render() {
		const { surveyAndEvaluationDuplicateFormPopUpIsOpen, toggleSurveyAndEvaluationDuplicateFormPopUp } = this.props;
		const templateName = this.state.templateName === '' ? this.props.templateName : this.state.templateName;
		return (
			<Modal
				className={classes['form-duplicate-pop-up']}
				id="form-duplicate-survey-and-evaluation"
				isOpen={surveyAndEvaluationDuplicateFormPopUpIsOpen}
				toggle={toggleSurveyAndEvaluationDuplicateFormPopUp}
				backdrop="static"
			>
				<CloseModalButton onClick={ toggleSurveyAndEvaluationDuplicateFormPopUp } />
				
				{!this.state.saving ? (
					<form className={classes['form-duplicate']}>
						<h2 className={classes['form-duplicate__title']}>Copy template</h2>
						<div className={classes['form-duplicate__card-group']}>
							<label className={CreateOrEditClasses['form-survey__card-label']} htmlFor="surveyName">
								template name
							</label>
							<input
								onChange={this.handleSurveyNameChange}
								className={CreateOrEditClasses['form-survey__card-input']}
								type="text"
								value={templateName}
								id="templateName"
								placeholder="template name"
							/>
						</div>

						<button
							className={classes['form-duplicate__submit']}
							onClick={(e) => {
								e.preventDefault();
								this.cloneTemplate('trial');
							}}
						>
							add to trial as is
						</button>
						<button
							className={classes['form-duplicate__edit']}
							onClick={(e) => {
								e.preventDefault();
								this.cloneTemplate('library');
							}}
						>
							copy to my templates &amp; edit
						</button>

						<div>
							{ this.props.duplicateSurveyAndEvaluationError ? (
								<ErrorMessage message={`${this.props.duplicateSurveyAndEvaluationError} error`} />
							) : null}
						</div>

						<button onClick={this.cancelPopUp} className={classes['form-duplicate__cancel']}>
							cancel
						</button>
					</form>
				) : (
					<ComponentLoading message="Saving..." />
				)}
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		duplicateSurveyAndEvaluationError: state.surveysAndEvaluations.duplicateSurveyAndEvaluationError
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		duplicateSurvey: (parameters) => {
			dispatch(duplicateSurveyAction(parameters));
		},
		duplicateEvaluation: (parameters) => {
			dispatch(duplicateEvaluationAction(parameters));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DuplicatePopUp));
