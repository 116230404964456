import React from 'react';

import ChartButtons from '../ChartButtons/ChartButtons';

import classes from './EngagementEmailVerbatim.module.css';


const EngagementEmailVerbatim = ({ verbatimComment, handleRemoveVerbatimComment, handleCurrentlyChangingVerbatimComment, removeButtons }) => {
    return (
        <div
            className={ classes[ "verbatim" ] }>
            <div className={ classes[ "verbatim-quote-image-container" ] }>
                <img
                    className={ classes[ "verbatim-quote-image" ] }
                    src="/images/email/quote-trial-template.png"
                    alt="quote icon" />
            </div>

            <div className={ classes[ "verbatim-text" ] }>
                <p className={ classes[ "verbatim-quote" ] }>
                    { verbatimComment.comment }
                </p>

                <div className={ classes[ "verbatim-person" ] }>
                    - { verbatimComment.people_name }
                </div>
            </div>

            {removeButtons ? (null) : (
                <div className={ classes[ "verbatim-buttons" ] }>
                    <ChartButtons removeFunction={ handleRemoveVerbatimComment } swapFunction={ handleCurrentlyChangingVerbatimComment } />
                </div>
            ) }

        </div>
    )
}

export default EngagementEmailVerbatim;