import React from 'react';
import { Container } from 'reactstrap';
import List from '../../../../../Components/List/List';
import ComponentLoading from '../../../../../Components/ComponentLoading/ComponentLoading';

const SurveysAndEvaluationsView = (props) => {
	const { 
		surveysAndEvaluations, 
		onSort, 
		type, 
		isFetchingSurveys, 
		isFetchingEvaluations 
	} = props;

	return (
		<Container>
			<List
				list={surveysAndEvaluations}
				actions={null}
				sortAlphabeticallyByTitle={onSort}
				sortBy="template_name"
				view={type}
			/>

			{ isFetchingSurveys ||
				isFetchingEvaluations ? (
					<ComponentLoading 
						message="Loading..."
						removeHeight={ true } />
			) : (null) }
		</Container>
	);
};

export default SurveysAndEvaluationsView;
