import React from 'react';

import classes from './InServiceRecap.module.css';
import emailPreviewClasses from '../EmailPreview.module.css';

import Footer from '../../Shared/Footer/Footer';
import LogoPreview from '../../Shared/LogoPreview/LogoPreview';

const InServiceRecapEndoscopy = ({ emailPreview }) => {
    const emailPreviewContent = emailPreview?.content.split(/[\r\n]+/g);
    const attendeesLineOne = emailPreview.inservice_attendees_line1.replace(/&bull;/g, '').split('\n');
    const sterileProcessingPreparationlineOne = emailPreview.sterile_processing_preparation_line1.replace(/&bull;/g, '').split('\n');
    const pharmacyPreparationLineOne = emailPreview.pharmacy_preparation_line1.replace(/&bull;/g, '').split('\n');
    const pharmacyPreparationSurgeonNameOne = emailPreview.pharmacy_preparation_s_name1.split('\n');

    const BASE_URL = process.env.REACT_APP_API_URL;
    let facilityImage = BASE_URL + emailPreview.facility_image;
    let facilityLogo = BASE_URL + emailPreview.facility_logo;

    if (emailPreview.facility_image === "")
    {
        facilityImage = '/images/email/facility-image-placeholder.png';
    }

    if (emailPreview.facility_logo === "")
    {
        facilityLogo = '/images/email/facility-logo-placeholder.png';
    }

    return (
        <div>
            <LogoPreview />
            <div className={ classes[ "header" ] }>
                <div className={ classes[ "header__left" ] }>
                    <div className={ classes[ "header__image-container" ] }>
                        <img
                            className={ classes[ "header__image" ] }
                            src={ facilityImage }
                            alt="facility image" />
                    </div>
                    <div className={ classes[ "header__image-container" ] }>
                        <img
                            className={ classes[ "header__image" ] }
                            src={ facilityLogo }
                            alt="facility logo"
                        />
                    </div>
                </div>

                <div className={ classes[ "header__right" ] }>
                    <div className={ classes[ "header__heading" ] }>
                        <div className={ classes[ "header__text-light" ] }>
                            { emailPreview.title }
                        </div>
                        <div className={ emailPreviewClasses[ "header__text-bold" ] }>
                            { emailPreview.sub_title }
                        </div>
                    </div>

                    <div className={ emailPreviewClasses[ "ribbon" ] }></div>

                    <div className={ classes[ "header__description" ] }>
                        { emailPreview.title_description }
                    </div>
                </div>
            </div>

            <div className={ classes[ "paragraphs-container" ] }>
                <p className={ [ emailPreviewClasses[ "email-preview__paragraph" ], "light-text" ].join(" ") }>
                    { emailPreview.recipient_name }
                </p>
                { emailPreviewContent?.map((item, index) => {
                    return (
                        <p
                            key={ index }
                            className={ emailPreviewClasses[ "email-preview__paragraph" ] }>
                            { item }
                        </p>
                    );
                }) }
            </div>

            { emailPreview?.inservice_attendees_is_on === "1" ? (
                <div className={ [ classes[ "section" ], classes[ "section--background-image" ] ].join(" ") }>
                    <div className="flex-shrink-0">
                        <div className={ classes[ "section__image-wrapper" ] }>
                            <img
                                className={ classes[ "section__image" ] }
                                src="/images/email/recap-1.png"
                                alt="workstation" />
                        </div>
                    </div>

                    <div className={ classes[ "section__item" ] }>
                        <div className={ classes[ "section__number" ] }>
                            { emailPreview.inservice_attendees_number }
                        </div>
                        <div className={ classes[ "section__text-light" ] }>
                            { emailPreview.inservice_attendees_title }
                        </div>
                        <div className={ classes[ "section__text-bold" ] }>
                            { emailPreview.inservice_attendees_subtitle }
                        </div>

                        <div className={ emailPreviewClasses[ "ribbon" ] }></div>

                        <div className={ classes[ "tutorial" ] }>
                            <div className={ [ classes[ "tutorial__text" ], classes[ "tutorial__text--margin" ] ].join(" ") }>
                                Tutorials:
                        </div>

                            <ul className={ [ classes[ "tutorial__list" ], classes[ "tutorial__text--margin" ] ].join(" ") }>
                                { attendeesLineOne.map((item, index) => {
                                    return (
                                        <li
                                            key={ index }
                                            className={ classes[ "tutorial__list-item" ] }>
                                            {item }
                                        </li>
                                    );
                                }) }
                            </ul>
                        </div>
                    </div>
                </div>
            ) : (null) }


            { emailPreview?.sterile_processing_preparation_is_on === "1" ? (
                <div className={ [ classes[ "section" ], classes[ "section--background-image" ] ].join(" ") }>

                    <div className={ classes[ "section__item" ] }>
                        <div className={ classes[ "section__text-container" ] }>
                            <div className={ classes[ "section__text-light" ] }>
                                { emailPreview.sterile_processing_preparation_title }
                            </div>
                            <div className={ classes[ "section__text-bold" ] }>
                                { emailPreview.sterile_processing_preparation_subtitle }
                            </div>
                            <div className={ classes[ "section__point-of-contact" ] }>
                                Point of contact:
                        </div>
                            <div className={ classes[ "section__point-of-contact-name" ] }>
                                { emailPreview.sterile_processing_preparation_contact_name }
                            </div>
                        </div>
                        <div className={ [ emailPreviewClasses[ "ribbon" ], emailPreviewClasses[ "ribbon--margin" ] ].join(" ") }></div>

                        <ul className={ classes[ "list" ] }>
                            { sterileProcessingPreparationlineOne.map((item, index) => {
                                return (
                                    <li
                                        key={ index }
                                        className={ classes[ "list__item" ] }>
                                        {item }
                                    </li>
                                );
                            }) }
                        </ul>
                    </div>


                    <div className="flex-shrink-0">
                        <div className={ classes[ "section__image-wrapper" ] }>
                            <img
                                className={ classes[ "section__image" ] }
                                src="/images/email/recap-2.png"
                                alt="workstation" />
                        </div>
                    </div>
                </div>
            ) : (null) }

            { emailPreview?.pharmacy_preparation_is_on === "1" ? (
                <div className={ classes[ "section" ] }>
                    <div className="flex-shrink-0">
                        <div className={ [ classes[ "section__image-wrapper" ], "align-items-start" ].join(" ") }>
                            <img
                                className={ classes[ "section__image" ] }
                                src="/images/email/recap-3.png"
                                alt="workstation" />
                        </div>
                    </div>

                    <div className={ classes[ "section__item" ] }>
                        <div className={ [ classes[ "section__text-container" ], "m-0" ].join(" ") }>
                            <div className={ classes[ "section__text-light" ] }>
                                { emailPreview.pharmacy_preparation_title }
                            </div>
                            <div className={ classes[ "section__text-bold" ] }>
                                { emailPreview.pharmacy_preparation_subtitle }
                            </div>
                            <div className={ classes[ "section__point-of-contact" ] }>
                                Point of contact:
                        </div>
                            <div className={ classes[ "section__point-of-contact-name" ] }>
                                { emailPreview.pharmacy_preparation_contact_name }
                            </div>
                        </div>
                        <div className={ [ emailPreviewClasses[ "ribbon" ], emailPreviewClasses[ "ribbon--margin" ] ].join(" ") }></div>

                        <ul className={ [ classes[ "list" ], classes[ "list--margin-right" ] ].join(" ") }>
                            { pharmacyPreparationLineOne.map((item, index) => {
                                return (
                                    <li
                                        key={ index }
                                        className={ classes[ "list__item" ] }>
                                        {item }
                                    </li>
                                );
                            }) }
                        </ul>

                        <ul className={ classes[ "list-names" ] }>
                            { pharmacyPreparationSurgeonNameOne.map((item, index) => {
                                return (
                                    <li
                                        key={ index }
                                        className={ classes[ "list-names__item" ] }>
                                        { item }
                                    </li>
                                )
                            }) }
                        </ul>
                    </div>
                </div>
            ) : (null) }


            <Footer text={ emailPreview.footer } />
        </div>
    );
}

export default InServiceRecapEndoscopy;