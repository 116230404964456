import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classes from '../FormElements.module.css';
import { getCompetitorsAction } from '../../../../../Store/actions/trialsAction';

import trialSummaryClasses from '../../../TrialSummary/TrialSummary.module.css';
import InputElementclasses from '../../../MultipleStepsModalsForm/InputElements.module.css';
import ComponentLoading from '../../../../../Components/ComponentLoading/ComponentLoading';

import useHandleSubmit from '../../../../../Hooks/useHandleSubmit';

const SelectCompetitors = ({
	submittedCompetitors,
	competitors,
	getCompetitors,
	submitCompetitorsSelection,
	isFetchingCompetitorsArray
}) => {
	const [ selectedCompetitors, setSelectedCompetitors ] = useState({});
	const [ competitorsFetched, setCompetitorsFetched ] = useState(false);

	const selectCompetitor = (ev, competitor) => {
		//console.log(ev.currentTarget.parentNode.classList(classes['list__item--active']));
		ev.currentTarget.parentNode.classList.toggle('selected');
		const cloneSelectedCompetitors = JSON.parse(JSON.stringify(selectedCompetitors));
		if (!cloneSelectedCompetitors[competitor.id]) {
			cloneSelectedCompetitors[competitor.id] = competitor;
		} else {
			delete cloneSelectedCompetitors[competitor.id];
		}
		setSelectedCompetitors(cloneSelectedCompetitors);
	};

	useEffect(
		() => {
			if (submittedCompetitors) setSelectedCompetitors(submittedCompetitors);
			getCompetitors();
			setCompetitorsFetched(true);
		},
		[ competitorsFetched ]
	);

	const passBackSelectedCompetitors = () => {
		submitCompetitorsSelection(selectedCompetitors);
	};

	const { handleSubmit } = useHandleSubmit({
		callback: passBackSelectedCompetitors
	});

	const type = 'competitor';

	if (isFetchingCompetitorsArray) {
		return <ComponentLoading message="Loading..." />;
	}

	return (
		<form className={classes['form']} onSubmit={handleSubmit}>
			<div className={classes['form__header-row']}>
				<h2 className={classes['form__title']}>Select competitors</h2>
				<button className={classes['form__button']}>
					next <i className="las la-angle-right" />
				</button>
			</div>
			{competitors.map((item) => {
				const isSelected = selectedCompetitors[item.id];
				let imageURL = `${process.env.REACT_APP_API_URL}images/competitors/`;
				let imageName = item.name.toLowerCase().replace(' ', '-').replace('&', '-');
				imageURL = imageURL + imageName + '.png';
				return (
					<label
						htmlFor={item.name}
						key={item.id}
						className={[
							trialSummaryClasses['trial-summary__image-container'],
							'customer_select_list_item',
							isSelected ? 'selected' : '',
							submittedCompetitors[item.id] ? classes['list__item--active'] : ''
						].join(' ')}
					>
						<span
							className={[
								InputElementclasses['form__select-image-border'],
								isSelected ? trialSummaryClasses['trial-summary__card-image--active'] : null
							].join(' ')}
						>
							<img
								className={[
									trialSummaryClasses['trial-summary__card-select-image--competitors'],
									'competitor_img'
								].join(' ')}
								src={imageURL}
								alt={item.name}
							/>
						</span>

						<input
							onClick={(ev) => selectCompetitor(ev, item)}
							value={item.name}
							id={item.name}
							className="hidden"
							name={type}
							type="checkbox"
						/>
						<span
							className={[
								trialSummaryClasses['trial-summary__card-name'],
								isSelected ? trialSummaryClasses['trial-summary__card-name--active'] : null
							].join(' ')}
						>
							{item.name}
						</span>
					</label>
				);
			})}
		</form>
	);
};

const mapStateToProps = (state) => {
	if (state.trials.competitorsArray) {
		return {
			competitors: state.trials.competitorsArray,
			isFetchingCompetitorsArray: state.trials.isFetchingCompetitorsArray
		};
	} else {
		return {
			competitors: [],
			isFetchingCompetitorsArray: state.trials.isFetchingCompetitorsArray
		};
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		getCompetitors: () => {
			dispatch(getCompetitorsAction({}));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SelectCompetitors));
