import React from 'react';
import classes from './Stats.module.css';

const Stats = (props) => {
    const { item } = props;

    return (
        <div className={ classes[ "stat-container" ] }>
            <div className={ classes[ "stat-content" ] }>
                <div className={ classes[ "stat-info" ] }>
                    <div className={ classes[ "stat__number" ] }>
                        { item.number }
                    </div>
                    <div className={ classes[ "stat__type" ] }>
                        { item.text }
                    </div>
                </div>
                <div className={ classes[ "stat__percentage" ] }>
                    { item.percentage }&#37;
                </div>
            </div>
            <div
                style={ { width: `${ item.percentage }%`, backgroundColor: item.percentageBarColor } }
                className={ classes[ "stat__percentage-bar" ] }>
            </div>
        </div>
    );
}

export default Stats;