import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { productEducationAction } from '../Store/actions/productEducationAction';
import { Container, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import '../assets/css/app.css';

import ComponentLoading from '../Components/ComponentLoading/ComponentLoading';

const imageURL = `${process.env.REACT_APP_API_URL}uploads/pages/`;

const ProductEducation = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(productEducationAction());
	}, []);

	const getProductEducation = useSelector((state) => {
		return state.productEducation.productEducation.filter((pe) => pe.status === '1');
	});
	const isFetchingProductEducation = useSelector((state) => state.productEducation.isFetchingProductEducation);

	function wheelHandler(event) {
		const element = document.getElementsByClassName('prod-tabs-wrapper')[0];
		const toLeft = event.deltaY < 0 && element.scrollLeft > 0;
		const toRight = event.deltaY > 0 && element.scrollLeft < element.scrollWidth - element.clientWidth;

		if (toLeft || toRight) {
			//event.preventDefault();
			event.stopPropagation();

			element.scrollBy({ left: event.deltaY });
		}
	}

	// split array into two to display two rows

	const productEducationList = (product) => {
		const image = imageURL + product.page_logo;
		return (
			<div key={product.id} className="d-board-item">
				<Link to={'/product-education/' + product.id}>
					<div className="product-education__image-container flex">
						<img className="d-board-thumb product-education__image" src={image} alt={product.name} />
					</div>
					<div className="dashboard-item-name">{product.name}</div>
				</Link>
			</div>
		);
	};
	const indicators = [];
	for (let c = 0; c < Math.ceil(getProductEducation.length / 6); c++) {
		indicators.push(c);
	}

	let selectedIndicator = 0;
	return (
		<Container className="product-edu-wrap">
			<Col sm={12} className="product-edu-container">
				<div className="d-board-sect-title">Product education</div>
				{isFetchingProductEducation ? (
					<ComponentLoading />
				) : (
					<React.Fragment>
						<div
							className="prod-tabs-wrapper"
							style={{ width: '90vw' }}
							onScroll={(e) => {
								const selectedIndex = Math.floor(
									e.currentTarget.scrollLeft / (e.currentTarget.scrollWidth / 3)
								);
								if (selectedIndex !== selectedIndicator) {
									const indicators = document.getElementsByClassName('indicators')[0].children;
									for (let indi = 0; indi < indicators.length; indi++) {
										const indicator = indicators[indi];
										if (indi === selectedIndex) {
											indicator.classList.add('selected');
											selectedIndicator = indi;
										} else {
											indicator.classList.remove('selected');
										}
									}
								}
							}}
							onWheel={(event) => wheelHandler(event)}
						>
							<div className="prod-tabs">
								<div className="product-edu">
									{getProductEducation.map((product, i) => {
										if (i % 2 == 0) {
											return (
												<div key={product.id} className="product_education_column">
													{productEducationList(product)}
													{i + 1 < getProductEducation.length &&
														productEducationList(getProductEducation[i + 1])}
												</div>
											);
										}
									})}
								</div>
							</div>
						</div>
						<div className="indicatorsContainer">
							<div className="indicators">
								{indicators.map((i, c) => {
									return <div key={i} className={c == 0 ? 'indicator selected' : 'indicator'} />;
								})}
							</div>
						</div>
					</React.Fragment>
				)}
			</Col>
		</Container>
	);
};

export default ProductEducation;
