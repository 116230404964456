import React from 'react';
import { Link, useParams } from 'react-router-dom';
import '../assets/css/app.css';
import CircleProgressBar from '../Components/CircleProgressBar';

let trialsprogress = {
	checklist_count: {
		type: 'trialslist',
		name: 'Review your checklists',
		thumbnail: '/images/icon-checklist.png',
		active: 'active',
		target: 'organization/checklist/',
		icon: (
			<text x="50%" y="50%" className="chart-number las">
				&#xf0c5;
			</text>
		)
	},
	engagement_emails_count: {
		type: 'trialslist',
		name: 'Send engagement emails',
		thumbnail: '/images/icon-email.png',
		active: 'active',
		dontShowRemainder: true,
		target: 'engagement-email',
		icon: (
			<text x="50%" y="50%" className="chart-number las">
				&#xf658;
			</text>
		)
	},
	survey_count: {
		type: 'trialslist',
		name: 'Fill out surveys & evals',
		thumbnail: '/images/icon-survey.png',
		active: 'active',
		target: 'surveyevals/',
		icon: (
			<text x="50%" y="50%" className="chart-number las">
				&#xf002;
			</text>
		)
	}
};

const CrushTrialProgress = (props) => {
	let { trialName, crushTrialProgressData } = props;
	const { id } = useParams();
	return (
		<div className="tab-flush-rt pb-1">
			<div className="tab-title2">Crush trial progress</div>
			<div className="line-blk-shrink mb-0 " />
			<table className="table mb-0">
				<tbody>
					{crushTrialProgressData &&
						Object.keys(trialsprogress).map((key) => {
							const item = { ...trialsprogress[key], ...crushTrialProgressData[key] };

							if (key === 'survey_count') {
								console.log(item.completed, crushTrialProgressData['evaluation_count'].completed);
								let completed =
									parseInt(item.completed) +
									parseInt(crushTrialProgressData['evaluation_count'].completed);
								let remaining =
									parseInt(item.remaining) +
									parseInt(crushTrialProgressData['evaluation_count'].remaining);
								item.completed = completed;
								item.remaining = remaining;
							}

							return (
								<tr key={key}>
									<td className="file-details">
										<Link
											to={`/trial/${id}/${item.target}`}
											key={item.id}
											value={item.name}
											className={`${item.active} trial-progress-item-container`}
											disabled={item.active === 'inactive'}
										>
											<div className="circle-inidicator-container">
												<CircleProgressBar
													trailStrokeColor="gray"
													strokeColor="#ffb500"
													percentage={
														item.remaining === 0 && item.completed === 0 ? (
															0
														) : item.remaining > 0 ? (
															Math.floor(
																item.completed / (item.remaining + item.completed) * 100
															)
														) : (
															100
														)
													}
													icon={item.icon}
													innerText="complete"
												/>
											</div>
											<ul className="trials-progress">
												<li className="crush-trial-progress__name">{item.name}</li>
												<li className="complete crush-trial-progress__information">
													<span>{item.completed} COMPLETED</span>

													{!item.dontShowRemainder && (
														<span className="crush-trial-progress__information-remaining">
															{' | '}
															{item.remaining} REMAINING
														</span>
													)}
													<span />
												</li>
											</ul>
											<div>
												<i className="las la-angle-right" />
											</div>
										</Link>
									</td>
								</tr>
							);
						})}
				</tbody>
			</table>
		</div>
	);
};

export default CrushTrialProgress;
