import * as ActionTypes from '../../actions/ActionTypes';

const initState = {
    isFetchingDraftEngagementEmails: false,
    draftEngagementEmails: [],
    draftEngagementEmailsError: '',
    isLastPageInDraftEngagementEmails: false,

    isFetchingPreviousEngagementEmails: false,
    previousEngagementEmails: [],
    previousEngagementEmailsError: '',
    isLastPageInPreviousEngagementEmails: false,

    isFetchingTemplateEngagementEmails: false,
    templateEngagementEmails: [],
    templateEngagementEmailsError: '',
    isLastPageInTemplateEngagementEmails: false,

    engagementEmailResults: false,
    engagementEmailResultsError: '',

    isFetchingRecipients: false,
    recipients: false,
    recipientsError: '',
    postEmailToRecipientError: '',
    postRecipientsToEngagementEmailError: '',

    engagementEmail: {},
    engagementEmailError: '',

    engagementEmailImages: {},
    engagementEmailError: '',

    clonePreviousEmailError: '',

    deleteEngagementEmailDraftError: '',

    sendTestEngagementEmailError: '',

    postTemplateImagesEngagementEmailError: '',

    postTemplateToEngagementEmailError: '',

    postSendMailError: '',

    isFetchingEngagementEmailVerbatim: false,
    engagementEmailVerbatim: [],
    engagementEmailVerbatimError: '',

    surveyReviewResultDetailAllQuestionList: false,
    surveyReviewResultDetailAllError: '',

    evaluationReviewResultDetailAllQuestionList: false,
    evaluationReviewResultDetailAllError: ''
}

const engagementEmailsReducer = (state = initState, action) => {
    const MAX_RESULTS_TO_BE_LAST_PAGE = 9;
    const FIRST_PAGE = 1;

    switch (action.type)
    {
        case ActionTypes.REQUEST_DRAFT_ENGAGEMENT_EMAILS:
            return {
                ...state,
                isFetchingDraftEngagementEmails: true
            }

        case ActionTypes.GET_DRAFT_ENGAGEMENT_EMAILS_SUCCESS:
            let draftEngagementEmailsArray;
            let isLastPageInDraftEngagementEmails = false;

            if (action.draftEngagementEmails === undefined)
            {
                draftEngagementEmailsArray = state.draftEngagementEmails;
                isLastPageInDraftEngagementEmails = true;
            }
            else if (action.currentPageNumber === FIRST_PAGE)
            {
                draftEngagementEmailsArray = action.draftEngagementEmails;
                isLastPageInDraftEngagementEmails = false;
            }
            else if (action.draftEngagementEmails && state.draftEngagementEmails)
            {
                draftEngagementEmailsArray = [ ...state.draftEngagementEmails, ...action.draftEngagementEmails ];
            }

            if (Array.isArray(action.draftEngagementEmails) && action.draftEngagementEmails.length <= MAX_RESULTS_TO_BE_LAST_PAGE)
            {
                isLastPageInDraftEngagementEmails = true;
            }

            return {
                ...state,
                isFetchingDraftEngagementEmails: false,
                draftEngagementEmails: draftEngagementEmailsArray,
                draftEngagementEmailsError: '',
                isLastPageInDraftEngagementEmails: isLastPageInDraftEngagementEmails
            }

        case ActionTypes.GET_DRAFT_ENGAGEMENT_EMAILS_ERROR:
            return {
                ...state,
                isFetchingDraftEngagementEmails: false,
                draftEngagementEmailsError: action.draftEngagementEmailsError
            }

        case ActionTypes.REQUEST_PREVIOUS_ENGAGEMENT_EMAILS:
            return {
                ...state,
                isFetchingPreviousEngagementEmails: true
            }

        case ActionTypes.GET_PREVIOUS_ENGAGEMENT_EMAILS_SUCCESS:
            let previousEngagementEmailsArray;
            let isLastPageInPreviousEngagementEmails = false;

            if (action.previousEngagementEmails === undefined)
            {
                previousEngagementEmailsArray = state.previousEngagementEmails;
                isLastPageInPreviousEngagementEmails = true;
            }
            else if (action.currentPageNumber === FIRST_PAGE)
            {
                previousEngagementEmailsArray = action.previousEngagementEmails;
                isLastPageInPreviousEngagementEmails = false;
            }
            else if (action.previousEngagementEmails && state.previousEngagementEmails)
            {
                previousEngagementEmailsArray = [ ...state.previousEngagementEmails, ...action.previousEngagementEmails ];
            }

            if (Array.isArray(action.previousEngagementEmails) && action.previousEngagementEmails.length <= MAX_RESULTS_TO_BE_LAST_PAGE)
            {
                isLastPageInPreviousEngagementEmails = true;
            }

            return {
                ...state,
                isFetchingPreviousEngagementEmails: false,
                previousEngagementEmails: previousEngagementEmailsArray,
                previousEngagementEmailsError: '',
                isLastPageInPreviousEngagementEmails: isLastPageInPreviousEngagementEmails
            }

        case ActionTypes.GET_PREVIOUS_ENGAGEMENT_EMAILS_ERROR:
            return {
                ...state,
                isFetchingPreviousEngagementEmails: false,
                previousEngagementEmailsError: action.previousEngagementEmailsError
            }

        case ActionTypes.REQUEST_TEMPLATE_ENGAGEMENT_EMAILS:
            return {
                ...state,
                isFetchingTemplateEngagementEmails: true
            }

        case ActionTypes.GET_TEMPLATE_ENGAGEMENT_EMAILS_SUCCESS:
            let templateEngagementEmailsArray;
            let isLastPageInTemplateEngagementEmails = false;

            if (action.templateEngagementEmails === undefined)
            {
                templateEngagementEmailsArray = state.templateEngagementEmails;
                isLastPageInTemplateEngagementEmails = true;
            }
            else if (action.currentPageNumber === FIRST_PAGE)
            {
                templateEngagementEmailsArray = action.templateEngagementEmails;
                isLastPageInTemplateEngagementEmails = false;
            }
            else if (action.templateEngagementEmails && state.templateEngagementEmails)
            {
                templateEngagementEmailsArray = [ ...state.templateEngagementEmails, ...action.templateEngagementEmails ];
            }

            if (Array.isArray(action.templateEngagementEmails) && action.templateEngagementEmails.length <= MAX_RESULTS_TO_BE_LAST_PAGE)
            {
                isLastPageInTemplateEngagementEmails = true;
            }

            return {
                ...state,
                isFetchingTemplateEngagementEmails: false,
                templateEngagementEmails: templateEngagementEmailsArray,
                templateEngagementEmailsError: '',
                isLastPageInTemplateEngagementEmails: isLastPageInTemplateEngagementEmails
            }

        case ActionTypes.GET_TEMPLATE_ENGAGEMENT_EMAILS_ERROR:
            return {
                ...state,
                isFetchingTemplateEngagementEmails: false,
                templateEngagementEmailsError: action.templateEngagementEmailsError
            }

        case ActionTypes.GET_ENGAGEMENT_EMAIL_RESULTS_SUCCESS:
            return {
                ...state,
                engagementEmailResults: action.engagementEmailResults,
                engagementEmailResultsError: ''
            }

        case ActionTypes.GET_ENGAGEMENT_EMAIL_RESULTS_ERROR:
            return {
                ...state,
                engagementEmailResultsError: action.engagementEmailResultsError
            }

        case ActionTypes.RESET_RECIPIENTS_IN_REDUCER:
            return {
                ...state,
                recipients: false
            }

        case ActionTypes.REQUEST_RECIPIENTS:
            return {
                ...state,
                isFetchingRecipients: true
            }

        case ActionTypes.GET_RECIPIENTS_SUCCESS:
            return {
                ...state,
                isFetchingRecipients: false,
                recipients: action.recipients,
                recipientsError: ''
            }

        case ActionTypes.GET_RECIPIENTS_ERROR:
            return {
                ...state,
                isFetchingRecipients: false,
                recipientsError: action.recipientsError
            }

        case ActionTypes.GET_ENGAGEMENT_EMAIL_SUCCESS:
            return {
                ...state,
                engagementEmail: action.engagementEmail,
                engagementEmailError: ''
            }

        case ActionTypes.GET_ENGAGEMENT_EMAIL_ERROR:
            return {
                ...state,
                engagementEmailError: action.engagementEmailError
            }

        case ActionTypes.GET_ENGAGEMENT_EMAIL_IMAGES_SUCCESS:
            return {
                ...state,
                engagementEmailImages: action.engagementEmailImages,
                engagementEmailImagesError: ''
            }

        case ActionTypes.GET_ENGAGEMENT_EMAIL_IMAGES_ERROR:
            return {
                ...state,
                engagementEmailImagesError: action.engagementEmailImagesError
            }

        case ActionTypes.CLONE_PREVIOUS_EMAIL_SUCCESS:
            return {
                ...state,
                clonePreviousEmailError: ''
            }

        case ActionTypes.CLONE_PREVIOUS_EMAIL_ERROR:
            return {
                ...state,
                clonePreviousEmailError: action.clonePreviousEmailError
            }

        case ActionTypes.DELETE_ENGAGEMENT_EMAIL_DRAFT_SUCCESS:
            return {
                ...state,
                deleteEngagementEmailDraftError: ''
            }

        case ActionTypes.DELETE_ENGAGEMENT_EMAIL_DRAFT_ERROR:
            return {
                ...state,
                deleteEngagementEmailDraftError: action.deleteEngagementEmailDraftError
            }

        case ActionTypes.SEND_TEST_ENGAGEMENT_EMAIL_SUCCESS:
            return {
                ...state,
                sendTestEngagementEmailError: ''
            }

        case ActionTypes.SEND_TEST_ENGAGEMENT_EMAIL_ERROR:
            return {
                ...state,
                sendTestEngagementEmailError: action.sendTestEngagementEmailError
            }

        case ActionTypes.POST_TEMPLATE_IMAGES_ENGAGEMENT_EMAIL_SUCCESS:
            return {
                ...state,
                postTemplateImagesEngagementEmailError: ''
            }

        case ActionTypes.POST_TEMPLATE_IMAGES_ENGAGEMENT_EMAIL_ERROR:
            return {
                ...state,
                postTemplateImagesEngagementEmailError: action.postTemplateImagesEngagementEmailError
            }

        case ActionTypes.POST_EMAIL_TO_RECIPIENT_SUCCESS:
            return {
                ...state,
                postEmailToRecipientError: ''
            }

        case ActionTypes.POST_EMAIL_TO_RECIPIENT_ERROR:
            return {
                ...state,
                postEmailToRecipientError: action.postEmailToRecipientError
            }

        case ActionTypes.POST_TEMPLATE_TO_ENGAGEMENT_EMAIL_SUCCESS:
            return {
                ...state,
                postTemplateToEngagementEmailError: ''
            }

        case ActionTypes.POST_TEMPLATE_TO_ENGAGEMENT_EMAIL_ERROR:
            return {
                ...state,
                postTemplateToEngagementEmailError: action.postTemplateToEngagementEmailError
            }

        case ActionTypes.POST_RECIPIENTS_TO_ENGAGEMENT_EMAIL_SUCCESS:
            return {
                ...state,
                postRecipientsToEngagementEmailError: ''
            }

        case ActionTypes.POST_RECIPIENTS_TO_ENGAGEMENT_EMAIL_ERROR:
            return {
                ...state,
                postRecipientsToEngagementEmailError: action.postRecipientsToEngagementEmailError
            }

        case ActionTypes.POST_SEND_MAIL_SUCCESS:
            return {
                ...state,
                postSendMailError: ''
            }

        case ActionTypes.POST_SEND_MAIL_ERROR:
            return {
                ...state,
                postSendMailError: action.postSendMailError
            }

        case ActionTypes.REQUEST_ENGAGEMENT_EMAIL_VERBATIM:
            return {
                ...state,
                isFetchingEngagementEmailVerbatim: true,
            }

        case ActionTypes.GET_ENGAGEMENT_EMAIL_VERBATIM_SUCCESS:
            return {
                ...state,
                engagementEmailVerbatim: action.engagementEmailVerbatim,
                engagementEmailVerbatimError: '',
                isFetchingEngagementEmailVerbatim: false,
            }

        case ActionTypes.GET_ENGAGEMENT_EMAIL_VERBATIM_ERROR:
            return {
                ...state,
                engagementEmailVerbatimError: action.engagementEmailVerbatimError,
                isFetchingEngagementEmailVerbatim: false,
            }

        case ActionTypes.GET_SURVEY_REVIEW_RESULT_DETAIL_ALL_SUCCESS:
            return {
                ...state,
                surveyReviewResultDetailAllQuestionList: action.surveyReviewResultDetailAllQuestionList,
                surveyReviewResultDetailAllError: ''
            }

        case ActionTypes.GET_SURVEY_REVIEW_RESULT_DETAIL_ALL_ERROR:
            return {
                ...state,
                surveyReviewResultDetailAllError: action.surveyReviewResultDetailAllError
            }

        case ActionTypes.GET_EVALUATION_REVIEW_RESULT_DETAIL_ALL_SUCCESS:
            return {
                ...state,
                evaluationReviewResultDetailAllQuestionList: action.evaluationReviewResultDetailAllQuestionList,
                evaluationReviewResultDetailAllError: ''
            }

        case ActionTypes.GET_EVALUATION_REVIEW_RESULT_DETAIL_ALL_ERROR:
            return {
                ...state,
                evaluationReviewResultDetailAllError: action.evaluationReviewResultDetailAllError
            }

        default:
            return state;
    }
}

export default engagementEmailsReducer;