import React, { useEffect, useState } from 'react';
import { Col } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal } from 'reactstrap';
import {
	getRespondentsForSurveyAction,
	getSurveyTemplateAction,
	submitSurveyResponses,
	getSurveyResultSummaryDetails
} from '../../../../Store/actions/surveysAction';
import {
	getRespondentsForEvaluationAction,
	getResultSummaryDetails,
	submitEvaluationResponses,
	getEvaluationTemplateAction
} from '../../../../Store/actions/evaluationsAction';
import FormQuestions from '../FormQuestions/FormQuestions';
import { BlockSurvey, BlockSurvey2 } from '../../../../Form/BlockSurvey';
import { MultipleResults } from './Charts/MultipleResults';
import { RatingResults } from './Charts/RatingResults';
import { TextResults } from './Charts/TextResults';
import ExportSummaryModal from './ExportSummaryModal';
import CloseModalButton from '../../../../Components/CloseModalButton/CloseModalButton';

const TrialSurveyEvalResultSummary = (props) => {
	const { type, trialID, id, personID, personType } = props.match.params;
	const singularType = type.endsWith('s') ? type.substring(0, type.length - 1) : type;
	const {
		getEvaluation,
		getSurvey,
		getEvaluationRespondents,
		getSurveyRespondents,
		getEvaluationTemplate,
		getSurveyTemplate
	} = props;
	const selectedTemplate = props[singularType];
	const surveyEvalSummary = props[`${type}_summary`] || {};
	useEffect(
		() => {
			if (type === 'survey') {
				getSurvey({ trialID: trialID, templateID: id, personID: personID, personType: personType });
				getSurveyTemplate({ trialID: trialID, templateID: id });
				getSurveyRespondents({ trialID: trialID, templateID: id });
			} else {
				getEvaluation({ trialID: trialID, templateID: id, personID: personID, personType: personType });
				getEvaluationTemplate({ trialID: trialID, templateID: id });
				getEvaluationRespondents({ trialID: trialID, templateID: id });
			}
		},
		[ !selectedTemplate || selectedTemplate.template_id === '' ]
	);
	const [ exportModalOpen, setExportModalOpen ] = useState(false);
	const [ exportableQuestions, setExportableQuestions ] = useState([]);
	const [ updateView, setUpdateView ] = useState(0);

	useEffect(
		() => {
			surveyEvalSummary.question_list && setExportableQuestions(surveyEvalSummary.question_list.map((q) => q.id));
		},
		[ surveyEvalSummary.question_list ]
	);

	const toggleExportableQuestion = (questionId) => {
		let eqs = exportableQuestions;
		if (eqs.includes(questionId)) {
			eqs = eqs.filter((q) => q !== questionId);
		} else {
			eqs.push(questionId);
		}
		setExportableQuestions(eqs);
		setUpdateView((updateView) => ++updateView);
	};

	const chartImages = {};

	const saveChartImage = (chartId, base64Image) => {
		//console.log(chartId, base64Image);
		chartImages[`${chartId}`] = base64Image;
	};

	return (
		<div className="page pb-5">
			<div>
				<div className="tab-title-main">
					<h4 className="section-title-survey">{surveyEvalSummary.template_name} </h4>
					<div className="line-blk-shrink mb-1" />

					<div className="dashboard-stats ">
						<div className="stats-survey-detail">
							<div className="surveys-and-evaluations__details-label">QUESTION COUNT</div>
							<div className="stats-survey-date surveys-and-evaluations__details-text">
								{surveyEvalSummary.questions}
							</div>
						</div>
						<div className="stats-survey-detail">
							<div className="surveys-and-evaluations__details-label">RESPONDENTS COUNT</div>
							<div className="stats-survey-date surveys-and-evaluations__details-text">
								{surveyEvalSummary.respondents}
							</div>
						</div>
					</div>

					<div className="dashboard-stats ">
						<div className="stats-survey-detail">
							<div className="surveys-and-evaluations__details-label">CREATED</div>
							<div className="stats-survey-date surveys-and-evaluations__details-text">
								{surveyEvalSummary.created_date}
							</div>
						</div>
						<div className="stats-survey-detail">
							<div className="surveys-and-evaluations__details-label">LAST ENTRY</div>
							<div className="stats-survey-date surveys-and-evaluations__details-text">
								{surveyEvalSummary.last_taken}
							</div>
						</div>
					</div>

					<div className="line-grey mb-3" />
					<div className="tab-bar">
						<i className="las la-file-export square-white" />
						<div className="tab-bar-text" onClick={() => setExportModalOpen(true)}>
							Export
							<span>
								<img src="/images/icon-arrow.jpg" className="angle2" alt="" />
							</span>
						</div>
					</div>
				</div>
			</div>
			{surveyEvalSummary.question_list &&
				surveyEvalSummary.question_list.map((question, index) => {
					return (
						<div className="text-block mb-4" key={`question${index}`}>
							<Col>
								<div className="question ">
									{' '}
									{index + 1}. {question.questions}
								</div>
								{question.questions_type === 'multiple' && (
									<MultipleResults
										onChartDone={saveChartImage}
										question={question}
										onExportToggle={() => {
											toggleExportableQuestion(question.id);
										}}
										exportable={exportableQuestions.includes(question.id)}
									/>
								)}
								{question.questions_type === 'rating' && (
									<RatingResults
										onChartDone={saveChartImage}
										question={question}
										onExportToggle={() => {
											toggleExportableQuestion(question.id);
										}}
										exportable={exportableQuestions.includes(question.id)}
									/>
								)}
								{question.questions_type === 'text' && (
									<TextResults
										question={question}
										onExportToggle={() => {
											toggleExportableQuestion(question.id);
										}}
										exportable={exportableQuestions.includes(question.id)}
									/>
								)}
								{question.questions_type === 'yesno' && (
									<MultipleResults
										onChartDone={saveChartImage}
										question={question}
										onExportToggle={() => {
											toggleExportableQuestion(question.id);
										}}
										exportable={exportableQuestions.includes(question.id)}
									/>
								)}
							</Col>
						</div>
					);
				})}
			<Modal
				centered
				isOpen={exportModalOpen}
				toggle={() => setExportModalOpen(false)}
				backdrop="static">
				<CloseModalButton onClick={ () => setExportModalOpen(false) } />

				<ExportSummaryModal
					toggleModal={() => setExportModalOpen(false)}
					trialID={trialID}
					templateType={type}
					templateID={id}
					questionIDs={exportableQuestions}
					questionChartImages={chartImages}
				/>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		evaluationsRespondents: state.evaluations.evaluationsRespondents,
		surveysRespondents: state.surveys.surveysRespondents,
		evaluation_summary: state.evaluations.evaluationResultSummary,
		survey_summary: state.surveys.surveyResultSummary
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getEvaluationTemplate: (parameters) => {
			dispatch(getEvaluationTemplateAction(parameters));
		},
		getEvaluation: (parameters) => {
			dispatch(getResultSummaryDetails(parameters));
		},
		getEvaluationRespondents: (parameters) => {
			dispatch(getRespondentsForEvaluationAction(parameters));
		},
		submitEvaluation: (parameters) => {
			dispatch(submitEvaluationResponses(parameters));
		},

		getSurveyTemplate: (parameters) => {
			dispatch(getSurveyTemplateAction(parameters));
		},

		getSurvey: (parameters) => {
			dispatch(getSurveyResultSummaryDetails(parameters));
		},

		getSurveyRespondents: (parameters) => {
			dispatch(getRespondentsForSurveyAction(parameters));
		},
		submitSurvey: (parameters) => {
			dispatch(submitSurveyResponses(parameters));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TrialSurveyEvalResultSummary));
