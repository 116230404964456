import React from 'react';

import classes from './ChartButtons.module.css';

const ChartButtons = ({ swapFunction, removeFunction }) => {
    return (
        <React.Fragment>
            <button
                onClick={ event => swapFunction(event) }
                className={ classes[ "button" ] }>
                <img id="icon-swap" className={ classes[ "button__image" ] } src="/images/email/icon-swap.png" alt="swap icon" />
            </button>
            <button
                onClick={ event => removeFunction(event) }
                className={ classes[ "button" ] }>
                <img id="icon-delete" className={ classes[ "button__image" ] } src="/images/email/icon-delete.png" alt="delete icon" />
            </button>
        </React.Fragment>
    );
}

export default ChartButtons;