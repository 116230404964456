import React from 'react';

export default ({ profile, cb, profileImageClasses }) => {
    if (profile.profile_image)
    {
        profile.profileImage = profile.profile_image;
    }

    if (!profile.profileImage)
    {
        return null;
    }

    if (profile.profileImage.includes('http'))
    {
        return (
            <div key={ profile.id }
                onClick={ (ev) => cb ? (cb(ev, profile)) : (null) }
                className={ `profile__image-container ${ profileImageClasses }` }>
                <img className="profile__image" src={ profile.profileImage } alt="profile image" />
            </div>
        )
    }

    return (
        <div key={ profile.id }
            onClick={ (ev) => cb ? (cb(ev, profile)) : (null) }
            className={ `profile__image--letters ${ profileImageClasses }` }>
            { profile.profileImage }
        </div>
    )
}