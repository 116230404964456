import React from "react";
import { useHistory } from "react-router-dom";
import "../assets/css/app.css";

function NavBarFile (props) {
  let history = useHistory();

  function handleClick (path) {
    // window.location.href = "../"
    //history.push(path) ? history.push(path) : history.push("/competitiveIntel");
    history.goBack();
  }

  const params = new URLSearchParams(props.location.search);
  const title = params.get('title');

  return (
    <>
      <div className="tab-navbar">
        <div className="navbarfile">

          <div className="back" onClick={ handleClick }>
            <i className="las la-angle-left" />
          BACK
        </div><p className="navbarfilename">{ title }</p>
        </div>
      </div>
    </>
  );
}

export default NavBarFile;
