import React, { useState, useEffect } from 'react';
import { Modal } from 'reactstrap';
import { Container } from 'reactstrap';
import _ from 'lodash';
import Search from '../Utils/Search';
import Paginate from '../Utils/Paginate';
import { getTrialsPeople, getGroup } from '../Data/database';
import Pagination from '../Components/Pagination';
import TablePeople from '../Components/TablePeople/TablePeople';
import { ListGroupTrialsPeople } from '../Components/ListGroup';

import AddPeopleDetails from './Trial/MultipleStepsModalsForm/AddPeopleDetails/AddPeopleDetails';

import LoadMoreButton from '../Components/LoadMoreButton/LoadMoreButton';
import { useParams } from 'react-router-dom';
import { getPeopleAction } from '../Store/actions/trialsAction';
import { useDispatch, useSelector } from 'react-redux';
import useEffectAfterFirstRender from '../Hooks/useEffectAfterFirstRender';
import ErrorMessage from '../Components/ErrorMessage/ErrorMessage';
import NoResults from '../Components/NoResults/NoResults';
import ComponentLoading from '../Components/ComponentLoading/ComponentLoading';
import CloseModalButton from '../Components/CloseModalButton/CloseModalButton';

const TrialsPeople = ({ accountID }) => {
	const [ content, setContent ] = useState([]);
	const id = '';
	const [ pageCounterForAPI, setPageCounterForAPI ] = useState(1);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getPeopleAction({ trialID: id, currentPageNumber: pageCounterForAPI }));
	}, []);

	useEffectAfterFirstRender(
		() => {
			dispatch(getPeopleAction({ trialID: id, currentPageNumber: pageCounterForAPI }));
		},
		[ pageCounterForAPI ]
	);

	const [ searchValue, setSearchValue ] = useState('');
	const handleLoadMore = () => {
		setPageCounterForAPI((previousPageCounterForAPI) => previousPageCounterForAPI + 1);
		setSearchValue('');
	};

	let trialPeople = useSelector((state) => state.trials.trialPeople);
	let isLastPageInTrialPeople = useSelector((state) => state.trials.isLastPageInTrialPeople);
	const trialPeopleError = useSelector((state) => state.trials.trialPeopleError);
	const isFetchingTrialPeople = useSelector((state) => state.trials.isFetchingTrialPeople);

	const mapPeople = (trialPeople) => {
		const peopleStakeholders = trialPeople
			? trialPeople
				.filter((person) => {
					return person.type === 'stakeholder';
				})
				.map((person) => {
					return {
						category: person.title,
						firstName: person.first_name,
						group: { id: '99', name: 'stakeholders' },
						id: person.id,
						lastName: person.last_name,
						numberOfCases: person.count,
						rating: person.rating,
						title: person.title,
						profileImage: person.profile || person.profile_image,
						name: `${ person.first_name } ${ person.last_name }`,
						trialID: id
					};
				})
			: [];

		const peopleSurgeons = trialPeople
			? trialPeople
				.filter((person) => {
					return person.type === 'surgeon';
				})
				.map((person) => {
					return {
						category: person.title,
						firstName: person.first_name,
						group: { id: '98', name: 'surgeons' },
						id: person.id,
						lastName: person.last_name,
						numberOfCases: person.count,
						rating: person.rating,
						title: person.title,
						profileImage: person.profile || person.profile_image,
						name: `${ person.first_name } ${ person.last_name }`,
						trialID: id
					};
				})
			: [];

		return peopleStakeholders.concat(peopleSurgeons);
	}

	useEffect(
		() => {
			setContent(mapPeople(trialPeople));
		},
		[ trialPeople ]
	);

	const trialsPeople = content || []; //getTrialsPeople();

	const group = getGroup();
	const [ selectedCategory, setSelectedCategory ] = useState(group[0]);
	const [ sortColumn, setSortColumn ] = useState({ path: 'name', order: 'asc' });

	const handleCategorySelect = (category) => {
		setSelectedCategory(category);
	};

	const handleSort = (path) => {
		if (sortColumn.path === path) {
			sortColumn.order === 'asc' ? setSortColumn({ path, order: 'desc' }) : setSortColumn({ path, order: 'asc' });
		} else {
			sortColumn.path = path;
			setSortColumn({ path, order: 'asc' });
		}

		let sortedPeopleInTrials = _.orderBy(trialsPeople, [ sortColumn.path ], [ sortColumn.order ]);

		setContent(sortedPeopleInTrials);
	};

	const filteredPeople =
		selectedCategory && selectedCategory.id
			? trialsPeople.filter((i) => i.group.id === selectedCategory.id)
			: trialsPeople;

	const filterPeopleInTrialsBySearch = (ev) => {
		let filteredPeopleInTrialsArray = trialPeople.filter((personInTrials) => {
			if (personInTrials.name.toLowerCase().includes(ev.currentTarget.value.toLowerCase().trim())) {
				return true;
			}

			return false;
		});

		setSearchValue(ev.currentTarget.value);
		setContent(mapPeople(filteredPeopleInTrialsArray));
	};

	const [ isModalOpen, setIsModalOpen ] = useState(false);
	const toggleModal = () => setIsModalOpen((previousIsModalOpen) => !previousIsModalOpen);

	return (
		<div>
			<Container className="tab-title-main">
				<button onClick={toggleModal} className="open-modal-button">
					<img className="open-modal-button__image" src="/images/icon-plus.png" alt="plus symbol icon" />
				</button>

				<h1 className="section-title">
					People <span className="lt-grey">in my trials</span>
				</h1>
				<div className="nav-pagelink">
					<ListGroupTrialsPeople
						items={group}
						onItemSelect={handleCategorySelect}
						selectedItem={selectedCategory}
					/>
				</div>
				<div className="hr-bk-expand-ml-mr-15" />
				<Search searchingValue={ searchValue } handleFilter={ filterPeopleInTrialsBySearch } />
			</Container>

			<TablePeople peopleFiltered={ filteredPeople } onSort={ handleSort } />

			{ isFetchingTrialPeople ? (
				<ComponentLoading 
					message="Loading..."
					removeHeight={ true } />
			) : (null) }
			
			{ trialPeopleError && !isFetchingTrialPeople ? (
				<ErrorMessage message={ trialPeopleError } />
			) : (null) }

			{ filteredPeople.length === 0 && !isFetchingTrialPeople ? (
				<NoResults />
			) : (null) }

			{ !isLastPageInTrialPeople && !isFetchingTrialPeople ? (
				<LoadMoreButton handleClick={ handleLoadMore } />
			 ) : (null) }

			<Modal
				isOpen={ isModalOpen }
				toggle={ toggleModal }
				centered
				backdrop="static">
				<CloseModalButton onClick={ toggleModal } />
				
				<AddPeopleDetails accountID={accountID} toggleModal={toggleModal} />
			</Modal>
		</div>
	);
};

export default TrialsPeople;
