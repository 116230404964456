import React, { useEffect, useRef, useState } from 'react';

import { getSurgeonsForCreatingAndUpdatingACaseAction } from '../../../../Store/actions/casesAction';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import ComponentLoading from '../../../../Components/ComponentLoading/ComponentLoading';

import AddATeammateClasses from '../../AddATeammate/AddATeammate.module.css';

import usePrevious from '../../../../Hooks/usePrevious';

import { debounce } from 'lodash';
import NoResults from '../../../../Components/NoResults/NoResults';

const SelectSurgeon = ({ selectSurgeonsFromDropDown, searchValue, liftIsLoadingMore, selectedSurgeons }) => {
    const dispatch = useDispatch();
    const debounceGetCaseSurgeons = useRef(
        debounce(parameters => {
            dispatch(getSurgeonsForCreatingAndUpdatingACaseAction(parameters));
        }, 250)
    ).current;

    const { id: trialID } = useParams();
    useEffect(() => {
        if (isLoadingMore)
        {
            return;
        }

        if (searchValue !== '')
        {
            setIsLoadingMore(false);
            setCurrentPageNumber(1);

            debounceGetCaseSurgeons({ trialID, currentPageNumber: 1, keyword: searchValue });
        }
    }, [ searchValue ]);

    const caseSurgeons = useSelector(state => state.cases.surgeonsForCreatingACase);
    const isFetchingCaseSurgeons = useSelector(state => state.cases.isFetchingCaseSurgeons);
    const isLastPageInSurgeonsForCreatingACase = useSelector(state => state.cases.isLastPageInSurgeonsForCreatingACase);
    const caseSurgeonsError = useSelector(state => state.cases.surgeonsForCreatingACaseError);

    const [ currentPageNumber, setCurrentPageNumber ] = useState(1);
    const [ isLoadingMore, setIsLoadingMore ] = useState(false);

    useEffect(() => {
        liftIsLoadingMore(isLoadingMore);
    }, [ isLoadingMore ]);

    const previousIsFetchingCaseSurgeons = usePrevious(isFetchingCaseSurgeons);
    useEffect(() => {
        if (previousIsFetchingCaseSurgeons === true && isFetchingCaseSurgeons === false)
        {
            setIsLoadingMore(false);
        }
    }, [ isFetchingCaseSurgeons ]);

    const handleScroll = (event) => {
        const reachedBottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;

        if (reachedBottom &&
            !isFetchingCaseSurgeons &&
            !isLastPageInSurgeonsForCreatingACase)
        {
            setCurrentPageNumber(previousCurrentPageNumber => previousCurrentPageNumber + 1);
            setIsLoadingMore(true);
        }
    }

    useEffect(() => {
        if (searchValue !== '')
        {
            dispatch(getSurgeonsForCreatingAndUpdatingACaseAction({ trialID, currentPageNumber: currentPageNumber, keyword: searchValue }));
        }
    }, [ currentPageNumber ]);

    let renderCaseSurgeons = !isFetchingCaseSurgeons;

    if (isLoadingMore)
    {
        renderCaseSurgeons = true;
    }

    let mapSelectedSurgeonsIDs = selectedSurgeons.map(selectedSurgeon => {
        return selectedSurgeon.id;
    });

    return (
        <div
            onScroll={ handleScroll }
            className={ [ AddATeammateClasses[ 'drop-down-container' ], searchValue === '' ? ("hidden") : ("") ].join(" ") }>
            { caseSurgeons.length && renderCaseSurgeons ? (
                caseSurgeons.map(surgeon => {
                    if (mapSelectedSurgeonsIDs.includes(surgeon.id)) {
                        return;
                    }

                    return (
                        <div
                            key={ surgeon.id }
                            className={ AddATeammateClasses[ 'drop-down' ] }
                            onClick={ () => selectSurgeonsFromDropDown(surgeon) }>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <div className={ AddATeammateClasses[ 'drop-down__name' ] }>{ surgeon.people }</div>
                                    <div className={ AddATeammateClasses[ 'drop-down__specialty' ] }>{ surgeon.specialty }</div>
                                </div>

                                { surgeon.is_in_trial_flag === "0" ? (
                                    <div className="form-add-surgeon__not-in-trial-text">
                                        { surgeon.is_in_trial }
                                    </div>
                                ) : (null) }
                            </div>
                        </div>
                    );
                })) : (!isFetchingCaseSurgeons ? (
                    <NoResults />
                ) : (null)) }

            { isFetchingCaseSurgeons ? (
                <ComponentLoading removeHeight={ true } />
            ) : (null) }
        </div>
    )
}

export default SelectSurgeon;