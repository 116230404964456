import React, { Component } from 'react';
import classes from './CasesStats.module.css';
import Stats from '../../../../../Components/Stats/Stats';
import PopoverReportsButton from '../../../../../Components/Popups/PopoverReports';

class CasesStats extends Component {
	render() {
		const { toggleCreateCaseModal, caseCount, ICGCount, caseAnalytics } = this.props;

		return (
			<div className={classes['cases-stats-container']}>
				<div className={classes['cases-stats-header']}>
					<h1 className={classes['cases-stats-header__title']}>Cases</h1>
					<div className={classes['cases-stats-header__image-container']}>
						<PopoverReportsButton
							downloadCases={this.props.downloadCaseSummary}
							downloadVerbatims={this.props.downloadVerbatimsSummary}
						/>
						<button className="modal-button ml-2" onClick={toggleCreateCaseModal}>
							<img className="modal-button-image" src="/images/icon-plus.png" alt="plus icon" />
						</button>
					</div>
				</div>
				<div className={classes['cases-stats__row']}>
					{caseAnalytics ? (
						caseAnalytics.map((item) => {
							let formatItemForStatsComponent = {
								number: item.totalCase,
								text: `${item.specialtyName} case${item.totalCase !== 1 ? 's' : ''}`,
								percentage: item.percentage,
								percentageBarColor: item.totalCase_color
							};

							return (
								item.totalCase > 0 &&
								item.specialtyName !== 'Undefined' && (
									<div key={item.specialty_id} className={classes['case-stat-item']}>
										<div className={classes['case-stat__image-container']}>
											<img
												className={classes['case-stat__image']}
												src={
													item.totalCase_color === '#54575a' ? item.icon : item.icon_selected
												}
												alt={`${item.specialtyName} icon`}
											/>
										</div>

										<Stats item={formatItemForStatsComponent} />
									</div>
								)
							);
						})
					) : null}

					<div className={classes['cases-stats__total']}>
						<div className={classes['case-stat__image-container']} />
						<div className={classes['cases-stats__total-left']}>
							{caseCount || 0}
							<span className={classes['cases-stats__total-text']}>
								{' '}
								total case{caseCount !== 1 && 's'}
							</span>
						</div>

						<div className={classes['cases-stats__total-left']} style={{marginLeft:'auto'}}>
							{ICGCount || 0}
							<span className={classes['cases-stats__total-text']}>
								{' '}
								case{ICGCount !== 1 && 's'} with ICG
							</span>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default CasesStats;
