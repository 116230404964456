import React from 'react';

import BarGraph from '../BarGraph/BarGraph';
import ChartButtons from '../ChartButtons/ChartButtons';

const DisplayBarGraph = ({ chartStatistics, removeFunction, swapFunction, removeButtons, onConfirmAndReviewPage }) => {
    return (
        <div className={ `chart ${ onConfirmAndReviewPage ? ("border-0 mb-0") : (null) }` }>
            {
                onConfirmAndReviewPage ? (null) : (
                    <img className="chart__icon" src="/images/email/icon-box.jpeg" alt="icon box" />
                )
            }

            <h3 className="chart__header-title">
                { chartStatistics.question_name }
            </h3>
            <BarGraph chartStatistics={ chartStatistics.question_options } />
            {
                removeButtons ? (null) : (
                    <div className="chart__buttons-container">
                        <ChartButtons
                            removeFunction={ removeFunction }
                            swapFunction={ swapFunction } />
                    </div>
                )
            }
        </div>
    )
}

export default DisplayBarGraph;