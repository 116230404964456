import React, { useState, useEffect } from 'react';
import { Modal } from 'reactstrap';
import { Container } from 'reactstrap';
import _ from 'lodash';
import Search from '../Utils/Search';
import LoadMoreButton from '../Components/LoadMoreButton/LoadMoreButton';
import { useParams } from 'react-router-dom';
import { getTrialsAvailableCustomers } from '../Store/actions/trialsAction';
import { useDispatch, useSelector } from 'react-redux';
import TableCustomers from '../Components/TableCustomers/TableCustomers';
import AddCustomer from './Trial/MultipleStepsModalsForm/AddCustomer/AddCustomer';
import ErrorMessage from '../Components/ErrorMessage/ErrorMessage';
import NoResults from '../Components/NoResults/NoResults';
import ComponentLoading from '../Components/ComponentLoading/ComponentLoading';
import CloseModalButton from '../Components/CloseModalButton/CloseModalButton';

const Customers = ({ accountID }) => {
	const [ content, setContent ] = useState([]);
	const [ pageCounter, setPageCounter ] = useState(1);
	const [ pageLimitReached, setPageLimitReached ] = useState(false);
	const [ customersFetched, setCustomersFetched ] = useState(false);
	const [ searchValue, setSearchValue ] = useState('');
	const [ sortColumn, setSortColumn ] = useState({ path: 'name', order: 'asc' });

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getTrialsAvailableCustomers());
		setCustomersFetched(true);
	}, [ customersFetched ]);

	const isFetchingGetTrialsAvailableCustomer = useSelector(state => state.trials.isFetchingGetTrialsAvailableCustomer);
	let customers = useSelector((state) => state.trials.availableCustomers);
	let customersError = useSelector((state) => state.trials.availableCustomersError);

	const handleLoadMore = () => {
		dispatch(getTrialsAvailableCustomers({ page: pageCounter }));
		setPageCounter(++pageCounter);
	};

	const handleSort = (path) => {
		if (sortColumn.path === path) {
			sortColumn.order === 'asc' ? setSortColumn({ path, order: 'desc' }) : setSortColumn({ path, order: 'asc' });
		} else {
			sortColumn.path = path;
			setSortColumn({ path, order: 'asc' });
		}
	};

	const filterCustomersBySearch = () => {
		return customers.filter(customer => {
			return customer.name.toLowerCase().includes(searchValue.toLowerCase().trim());
		});
	}
	
	const sorted = _.orderBy(filterCustomersBySearch(), [ sortColumn.path ], [ sortColumn.order ]);

	const [ isModalOpen, setIsModalOpen ] = useState(false);
	const toggleModal = () => setIsModalOpen((previousIsModalOpen) => !previousIsModalOpen);

	const handleInputChange = (event) => {
		setSearchValue(event.currentTarget.value);
	}

	return (
		<div>
			<Container className="tab-title-main">
				<button onClick={toggleModal} className="open-modal-button">
					<img className="open-modal-button__image" src="/images/icon-plus.png" alt="plus symbol icon" />
				</button>

				<h4 className="section-title">Customers</h4>
				<div className="hr-bk-expand-ml-mr-15" />
				{ isFetchingGetTrialsAvailableCustomer ? (null) : (
					<Search searchingValue={ searchValue } handleInputChange={ handleInputChange } />
				) }
			</Container>
			{ isFetchingGetTrialsAvailableCustomer ? (
				<ComponentLoading />
			) : (
				<TableCustomers customersFiltered={ sorted } onSort={ handleSort } />
			) }
			

			{ customersError && !isFetchingGetTrialsAvailableCustomer ? (
				<ErrorMessage message={ customersError } />
			) : (null) }

			{ sorted.length === 0 && !isFetchingGetTrialsAvailableCustomer ? (
				<NoResults />
			) : (null) }

			{/* !pageLimitReached ? <LoadMoreButton handleClick={handleLoadMore} /> : null */}

			<Modal
				isOpen={ isModalOpen }
				toggle={ toggleModal }
				centered
				backdrop="static">
				<CloseModalButton onClick={ toggleModal } />

				<AddCustomer accountID={ accountID } callback={ toggleModal } isInModal={ true } />
			</Modal>
		</div>
	);
};

export default Customers;
