import React from 'react';

import DonutChart from './DoughnutChart.js';
import ChartButtons from '../ChartButtons/ChartButtons';
import PieChart from './PieChart.js';

import createChartColors from './createChartColors';

const DisplayChart = ({ chartStatistics, removeFunction, swapFunction, removeButtons, onConfirmAndReviewPage }) => {

    let getPercentages = chartStatistics.question_options.map(option => {
        return option.option_response_percentage;
    });

    let gradient = createChartColors(getPercentages.length);

    return (
        <div className={ `chart ${ onConfirmAndReviewPage ? ("border-0 mb-0") : (null) }` }>
            {onConfirmAndReviewPage ? (null) : (
                <img className="chart__icon" src="/images/email/icon-box.jpeg" alt="icon box" />
            ) }

            <div className="chart__content">
                <div className="chart__graph-container">
                    { chartStatistics.questions_type === 'multiple' ? (
                        <DonutChart
                            className="chart__graph"
                            data={ chartStatistics.question_options.map(option => {
                                return { label: option.options, percentage: option.option_response_percentage };
                            }) } />
                    ) : (
                            <PieChart
                                className="chart__graph"
                                data={ chartStatistics.question_options.map(option => {
                                    return { label: option.options, percentage: option.option_response_percentage };
                                }) } />)
                    }
                </div>
                <div className="chart__text">
                    <h3 className="chart__title">{ chartStatistics.question_name }</h3>

                    <div className="chart__statistics">
                        { chartStatistics.question_options.map((option, index) => {
                            if (gradient[ index ])
                            {
                                return (
                                    <div
                                        key={ option.option_id }
                                        className="chart__statistic">
                                        <span
                                            style={ { color: gradient[ index ] } }
                                            className="chart__statistics-percentage">{ option.option_response_percentage }&#37;</span> <span className="chart__statistics-text">{ option.options }</span>
                                    </div>
                                )
                            }

                            return (
                                <div
                                    key={ option.option_id }
                                    className="chart__statistic">
                                    <span
                                        className="chart__statistics-percentage">{ option.option_response_percentage }&#37;</span> <span className="chart__statistics-text">{ option.options }</span>
                                </div>
                            )
                        }) }
                    </div>
                </div>
            </div>

            {
                removeButtons ? (null) : (
                    <div className="chart__buttons-container">
                        <ChartButtons removeFunction={ removeFunction } swapFunction={ swapFunction } />
                    </div>
                )
            }
        </div>
    )
}

export default DisplayChart;