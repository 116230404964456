import * as ActionTypes from './ActionTypes';

import API from '../../API/API';

import jwt_decode from 'jwt-decode';

import axios from 'axios';

export const postLoginAction = (email, redirect) => {
	return (dispatch, getState) => {
		API()
			.post(`api/v7/user/login`, { email: email })
			.then(function(res) {
				if (res.data.success === '1') {
					const { first_name, last_name, phone, email, profile_image, id, user_type } = res.data.data;

					const userData = {
						firstName: first_name,
						lastName: last_name,
						phone: phone,
						email: email,
						profileImage: profile_image
					};

					localStorage.setItem('user_id', id);
					localStorage.setItem('profile_image', profile_image);
					localStorage.setItem('email', email);
					localStorage.setItem('user_type', user_type);

					if (redirect) {
						window.location.reload();
					}
					return dispatch({ type: ActionTypes.POST_LOGIN_SUCCESS, userData: userData });
				} else {
					return dispatch({ type: ActionTypes.POST_LOGIN_ERROR, error: res.data.message });
				}
			})
			.catch((err) => {
				return dispatch({ type: ActionTypes.POST_LOGIN_ERROR, error: err });
			});
	};
};

export const updateUserInformationAction = ({ formData, toggleMyProfileModal }) => {
	return (dispatch, getState) => {
		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		};
		API()
			.post('api/v7/user/edit', formData, config)
			.then((res) => {
				const { first_name, last_name, phone, email, profile_image } = res.data.data;

				const userData = {
					firstName: first_name,
					lastName: last_name,
					phone: phone,
					email: email,
					profileImage: profile_image
				};

				toggleMyProfileModal();

				return dispatch({ type: ActionTypes.POST_USER_INFORMATION_SUCCESS, userData: userData });
			})
			.catch((err) => {
				let error = err.message;

				if (err.message) {
					error = err.message;
				} else {
					error = 'There was an error';
				}

				return dispatch({ type: ActionTypes.POST_USER_INFORMATION_ERROR, postUserInformationError: error });
			});
	};
};

export const getBusinessUnitAction = () => {
	return (dispatch, getState) => {
		API()
			.post(`api/v7/businessunit/IOSBusinessUnitList`, { user_id: localStorage.getItem('user_id') })
			.then(function(res) {
				let storeBUIntoArray = res.data.data
					.filter((bu) => {
						if (bu['is_selected'] === '1') {
							return bu.id;
						}
						return false;
					})
					.map((bu) => bu.id);

				localStorage.setItem('BU', JSON.stringify(storeBUIntoArray));

				return dispatch({ type: ActionTypes.GET_BU_SUCCESS, BU: res.data.data });
			})
			.catch((err) => {
				return dispatch({ type: ActionTypes.GET_BU_ERROR, BUError: err });
			});
	};
};

export const postBusinessUnitAction = (BU_ID, status) => {
	return (dispatch, getState) => {
		const params = {
			bu_id: [ BU_ID.toString() ],
			status: status.toString(),
			user_id: localStorage.getItem('user_id')
		};

		API()
			.post(`/api/v7/businessunit/IOSBusinessUnitSelection`, params)
			.then(function(res) {
				if (res.data.success === '0') {
					return dispatch({ type: ActionTypes.POST_BU_ERROR, BUPostError: '0' });
				}
				return dispatch(getBusinessUnitAction());
			})
			.catch((err) => {
				return dispatch({ type: ActionTypes.POST_BU_ERROR, BUPostError: err });
			});
	};
};

export const postSSOLoginAction = (jwt, redirect) => {
	var decoded = jwt_decode(jwt);

	return (dispatch, getState) => {
		API()
			.post(`api/v7/user/login`, { email: decoded.eid[0], fn: decoded.first[0], ln: decoded.last[0] })
			.then(function(res) {
				if (res.data.success === '1') {
					const { first_name, last_name, phone, email, profile_image, id } = res.data.data;
					console.log(res.data);
					const userData = {
						firstName: first_name,
						lastName: last_name,
						phone: phone,
						email: email,
						profileImage: profile_image
					};

					localStorage.setItem('user_id', id);
					localStorage.setItem('profile_image', profile_image);
					localStorage.setItem('email', email);

					window.location.replace('/');
					return dispatch({ type: ActionTypes.POST_LOGIN_SUCCESS, userData: userData });
				} else {
					return dispatch({ type: ActionTypes.POST_LOGIN_ERROR, error: res.data.message });
				}
			})
			.catch((err) => {
				return dispatch({ type: ActionTypes.POST_LOGIN_ERROR, error: err });
			});
	};
};
