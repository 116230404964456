import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import classes from './SurveysAndEvaluationsTemplateInformation.module.css';

import { getSurveyTemplateAction } from '../../../../Store/actions/surveysAction';
import { getEvaluationTemplateAction } from '../../../../Store/actions/evaluationsAction';
import Edit from '../CreateOrEdit/Edit';

import Preview from '../Preview/Preview';
import DuplicatePopUp from '../DuplicatePopUp/DuplicatePopUp';
import DeletePopUp from '../DeletePopUp/DeletePopUp';

class SurveysAndEvaluationsTemplateInformation extends Component {
	state = {
		surveyPreviewQuestionairePopupIsOpen: false,
		surveyAndEvaluationDuplicateFormPopUpIsOpen: false,
		surveyAndEvaluationDeletePopUpIsOpen: false,
		surveyCreateOrEditQuestionairePopupIsOpen: false
	};

	toggleSurveyAndEvaluationDuplicateFormPopUp = () => {
		this.setState({
			surveyAndEvaluationDuplicateFormPopUpIsOpen: !this.state.surveyAndEvaluationDuplicateFormPopUpIsOpen
		});
	};

	toggleSurveyAndEvaluationDeletePopUp = () => {
		this.setState({
			surveyAndEvaluationDeletePopUpIsOpen: !this.state.surveyAndEvaluationDeletePopUpIsOpen
		});
	};

	toggleSurveyPreviewQuestionairePopup = () => {
		this.setState({
			surveyPreviewQuestionairePopupIsOpen: !this.state.surveyPreviewQuestionairePopupIsOpen
		});
	};

	goToEditPage = () => {
		const { id, trialID, type } = this.props.match.params;
		this.props.history.push(`/trial/${trialID}/surveyevals/${type}/${id}/edit`);
	};

	toggleSurveyCreateOrEditQuestionairePopup = () => {
		const { id, trialID, type } = this.props.match.params;
		if (type === 'survey') {
			this.props.getSurvey({ trialID: trialID, templateID: id });
		} else {
			this.props.getEvaluation({ trialID: trialID, templateID: id });
		}
		this.setState({
			surveyCreateOrEditQuestionairePopupIsOpen: !this.state.surveyCreateOrEditQuestionairePopupIsOpen
		});
	};

	componentDidMount() {
		const { id, trialID, type } = this.props.match.params;
		if (type === 'survey') {
			this.props.getSurvey({ trialID: trialID, templateID: id });
		} else {
			this.props.getEvaluation({ trialID: trialID, templateID: id });
		}
	}

	render() {
		const { type, trialID, id } = this.props.match.params;
		const singularType = type.endsWith('s') ? type.substring(0, type.length - 1) : type;
		const selectedTemplate = this.props[singularType];

		const FormatSurveyAndEvaluationTemplateInformation = selectedTemplate ? (
			<React.Fragment>
				<h1 className={classes['surveys-and-evaluations__title']}>{selectedTemplate.template_name}</h1>

				<div className={classes['surveys-and-evaluations__information-container']}>
					<div className={classes['surveys-and-evaluations__information']}>
						<div className={classes['surveys-and-evaluations__information-label']}>Created</div>
						<div className={classes['surveys-and-evaluations__information-text']}>
							{selectedTemplate.created_date}
						</div>
					</div>
					<div className={classes['surveys-and-evaluations__information']}>
						<div className={classes['surveys-and-evaluations__information-label']}>Questions</div>
						<div className={classes['surveys-and-evaluations__information-text']}>
							{selectedTemplate.questions}
						</div>
					</div>
					<div className={classes['surveys-and-evaluations__information']}>
						<div className={classes['surveys-and-evaluations__information-label']}>last updated</div>
						<div className={classes['surveys-and-evaluations__information-text']}>
							{selectedTemplate.updated_date || selectedTemplate.created_date}
						</div>
					</div>
				</div>
			</React.Fragment>
		) : null;

		return (
			<div className={classes['surveys-and-evaluations']}>
				{FormatSurveyAndEvaluationTemplateInformation}

				<div className={classes['surveys-and-evaluations__actions']}>
					<button
						onClick={this.toggleSurveyPreviewQuestionairePopup}
						className={classes['surveys-and-evaluations__action']}
					>
						<div className={classes['surveys-and-evaluations__action-icon']}>
							<i className="las la-eye" />
						</div>
						<div className={classes['surveys-and-evaluations__action-label']}>
							<h2 className={classes['surveys-and-evaluations__action-label-text']}>Preview</h2>

							<i className="las la-angle-right" />
						</div>
					</button>
					<button
						onClick={this.toggleSurveyAndEvaluationDuplicateFormPopUp}
						className={classes['surveys-and-evaluations__action']}
					>
						<div className={classes['surveys-and-evaluations__action-icon']}>
							<i className="las la-copy" />
						</div>
						<div className={classes['surveys-and-evaluations__action-label']}>
							<h2 className={classes['surveys-and-evaluations__action-label-text']}>
								Add to Trial / Copy to My Templates
							</h2>

							<i className="las la-angle-right" />
						</div>
					</button>
					{selectedTemplate.created_by !== 'admin' && (
						<button
							onClick={this.toggleSurveyCreateOrEditQuestionairePopup}
							className={classes['surveys-and-evaluations__action']}
						>
							<div className={classes['surveys-and-evaluations__action-icon']}>
								<i className="las la-pen" />
							</div>
							<div className={classes['surveys-and-evaluations__action-label']}>
								<h2 className={classes['surveys-and-evaluations__action-label-text']}>Edit</h2>

								<i className="las la-angle-right" />
							</div>
						</button>
					)}
					{selectedTemplate.created_by !== 'admin' && (
						<button
							onClick={this.toggleSurveyAndEvaluationDeletePopUp}
							className={classes['surveys-and-evaluations__action']}
						>
							<div className={classes['surveys-and-evaluations__action-icon-red']}>
								<i className="las la-trash" />
							</div>
							<div className={classes['surveys-and-evaluations__action-label']}>
								<h2 className={classes['surveys-and-evaluations__action-label-text']}>Delete</h2>

								<i className="las la-angle-right" />
							</div>
						</button>
					)}
				</div>

				{this.state.surveyPreviewQuestionairePopupIsOpen && (
					<Preview
						surveysAndEvaluations={selectedTemplate}
						surveyPreviewQuestionairePopupIsOpen={this.state.surveyPreviewQuestionairePopupIsOpen}
						toggleSurveyPreviewQuestionairePopup={this.toggleSurveyPreviewQuestionairePopup}
					/>
				)}

				{this.state.surveyCreateOrEditQuestionairePopupIsOpen && selectedTemplate ? (
					<Edit
						type={type}
						templateName={selectedTemplate.template_name}
						questions={selectedTemplate.question_list}
						surveyCreateOrEditQuestionairePopupIsOpen={this.state.surveyCreateOrEditQuestionairePopupIsOpen}
						toggleSurveyCreateOrEditQuestionairePopup={this.toggleSurveyCreateOrEditQuestionairePopup}
					/>
				) : null}

				<DuplicatePopUp
					templateName={selectedTemplate.template_name + ' copy'}
					duplicatingATemplate={true}
					surveyAndEvaluationDuplicateFormPopUpIsOpen={this.state.surveyAndEvaluationDuplicateFormPopUpIsOpen}
					toggleSurveyAndEvaluationDuplicateFormPopUp={this.toggleSurveyAndEvaluationDuplicateFormPopUp}
				/>

				<DeletePopUp
					templateName={selectedTemplate.template_name}
					surveyAndEvaluationDeletePopUpIsOpen={this.state.surveyAndEvaluationDeletePopUpIsOpen}
					toggleSurveyAndEvaluationDeletePopUp={this.toggleSurveyAndEvaluationDeletePopUp}
				/>
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		evaluation: state.evaluations.selectedTemplate,
		survey: state.surveys.selectedTemplate
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getEvaluation: (parameters) => {
			dispatch(getEvaluationTemplateAction(parameters));
		},
		getSurvey: (parameters) => {
			dispatch(getSurveyTemplateAction(parameters));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SurveysAndEvaluationsTemplateInformation));
