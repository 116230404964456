/** @format */

import React from "react";

import PreTrialCommunicationThankYouEndoscopy from "./PreTrialCommunicationThankYou/PreTrialCommunicationThankYouEndoscopy";
import PreTrialCommunicationThankYouCommunications from "./PreTrialCommunicationThankYou/PreTrialCommunicationThankYouCommunications";
import InServiceInviteCommunications from "./InServiceInvite/InServiceInviteCommunications";
import InServiceInviteEndoscopy from "./InServiceInvite/InServiceInviteEndoscopy";
import InServiceInviteMed from "./InServiceInvite/InServiceInviteMed";
import InServiceRecapCommunications from "./InServiceRecap/InServiceRecapCommunications";
import InServiceRecapEndoscopy from "./InServiceRecap/InServiceRecapEndoscopy";
import TrialRecapUpdateEndoscopy from "./TrialRecapUpdate/TrialRecapUpdateEndoscopy";
import TrialRecapUpdateCommunications from "./TrialRecapUpdate/TrialRecapUpdateCommunications";
import WeeklyEngagement from "./WeeklyEngagement/WeeklyEngagement";

const EmailPreview = ({ emailPreview }) => {
  let templateToShow = null;
  if (emailPreview === undefined) {
    emailPreview = {};
  }

  if (emailPreview.template_type === "pre_trial") {
    templateToShow = (
      <PreTrialCommunicationThankYouEndoscopy
        emailPreview={emailPreview?.content_json}
      />
    );
  } else if (emailPreview.template_type === "integration_trial") {
    templateToShow = (
      <PreTrialCommunicationThankYouCommunications
        emailPreview={emailPreview?.content_json}
      />
    );
  } else if (emailPreview.template_type === "service_invite") {
    templateToShow = (
      <InServiceInviteCommunications
        emailPreview={emailPreview?.content_json}
      />
    );
  } else if (emailPreview.template_type === "service_invite_endo") {
    templateToShow = (
      <InServiceInviteEndoscopy emailPreview={emailPreview?.content_json} />
    );
  } else if (emailPreview.template_type === "service_invite_med") {
    templateToShow = (
      <InServiceInviteMed emailPreview={emailPreview?.content_json} />
    );
  } else if (emailPreview.template_type === "service_recap") {
    templateToShow = (
      <InServiceRecapCommunications emailPreview={emailPreview?.content_json} />
    );
  } else if (emailPreview.template_type === "service_recap_endo") {
    templateToShow = (
      <InServiceRecapEndoscopy emailPreview={emailPreview?.content_json} />
    );
  } else if (emailPreview.template_type === "trial_recap") {
    templateToShow = (
      <TrialRecapUpdateEndoscopy emailPreview={emailPreview?.content_json} />
    );
  } else if (emailPreview.template_type === "integration_trial_complete") {
    templateToShow = (
      <TrialRecapUpdateCommunications
        emailPreview={emailPreview?.content_json}
      />
    );
  } else if (emailPreview.template_type === "weekly_engagement") {
    templateToShow = (
      <WeeklyEngagement emailPreview={emailPreview?.content_json} />
    );
  }

  return templateToShow;
};

export default EmailPreview;
