import React from 'react';

import emailResultsClasses from '../../EmailResults/EmailResults.module.css';
import classes from './PreTrialCommunicationThankYou.module.css';

import PreTrialCommunicationThankYouContent from '../Shared/PreTrialCommunicationThankYouContent';
import LogoPreview from '../../Shared/LogoPreview/LogoPreview';
import Footer from '../../Shared/Footer/Footer';

const PreTrialCommunicationThankYouCommunications = ({ emailPreview }) => {
    return (
        <div className={ emailResultsClasses[ "email-results-section" ] }>
            <div className={ emailResultsClasses[ "email-results-preview-container" ] }>
                <LogoPreview />

                <div
                    className={ emailResultsClasses[ "email-results-preview__header" ] }>

                    <div className={ emailResultsClasses[ "email-results-preview__header-left" ] }>
                        <div className={ emailResultsClasses[ "email-results-preview__title" ] }>
                            { emailPreview?.account_name }
                        </div>
                        <h2 className={ emailResultsClasses[ "email-results-preview__headline" ] }>
                            { emailPreview?.head_line }
                        </h2>
                    </div>

                    <div className={ classes[ "email-preview__header-right" ] }>
                        <img
                            className={ emailResultsClasses[ "email-results-preview__header-image" ] }
                            src={`${process.env.REACT_APP_API_URL}images/recap-111.png`} alt="workstation" />
                    </div>
                </div>
                <div className={ classes[ "email-preview-border-wrapper" ] }>
                    <div className={ classes[ "email-preview-border" ] }></div>
                </div>

                <PreTrialCommunicationThankYouContent
                    emailPreviewImagePath="/images/email/video-banner.png"
                    emailPreview={ emailPreview } />

                <Footer text={ emailPreview.footer } />
            </div>
        </div>
    );
}

export default PreTrialCommunicationThankYouCommunications;