import React, { useState, useEffect } from 'react';
import classes from './CancelTrialModal.module.css';
import { useDispatch, useSelector } from 'react-redux';
import useHandleSubmit from '../../../../../Hooks/useHandleSubmit';

const CancelTrial = ({ toggleModal, cancelTrialSetup }) => {
	const dispatch = useDispatch();

	const handleCancelClick = (ev) => {
		ev.preventDefault();
		toggleModal();
	};

	const { handleSubmit } = useHandleSubmit({
		callback: cancelTrialSetup
	});

	return (
		<form className={classes['form']} onSubmit={handleSubmit}>
			<label className={classes['form__label']} htmlFor="addChecklist">
				Are you sure?
			</label>
			<p>Any progress you've made will not be saved.</p>
			<div className={classes['form__buttons-container']}>
				<button className={[ classes['form__button'], classes['form__button--cansubmit'] ].join(' ')}>
					<i className="las la-times" />cancel trial setup
				</button>
				<button
					onClick={handleCancelClick}
					className={[ classes['form__button'], classes['form__button--cancel'] ].join(' ')}
				>
					<i className="las la-undo" /> finish setting up my trial
				</button>
			</div>
		</form>
	);
};

export default CancelTrial;
