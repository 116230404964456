import React, { Component } from "react";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getBusinessUnitAction, postBusinessUnitAction } from '../../Store/actions/authActions';

import { Container, Row, Col } from "reactstrap";
import Toggle from 'react-toggle'
import classes from './BusinessUnitSelection.module.css';

class BusinessUnitSelection extends Component {
    handleSetBU = (e) => {
        let isInactive = e.currentTarget.parentNode.parentNode.querySelector('[data-label]').classList.toggle('inactive');
        this.props.postBusinessUnit(e.currentTarget.value, !isInactive);
    }

    componentDidUpdate () {
        let storeIDsIntoArray = this.props.BUFromAPI.filter((bu) => {
            if (bu[ 'is_selected' ] === '1')
            {
                return bu.id;
            }
            return undefined
        }).map((bu) => bu.id);

        localStorage.setItem('BU', JSON.stringify(storeIDsIntoArray));
    }

    componentDidMount () {
        this.props.getBusinessUnit();
    }

    render () {
        const { handleSetBU } = this;

        if (this.props.BUPostError === "0") return <Redirect to="/auth" />

        let formatBU = this.props.BUFromAPI.map((bu) => {
            const { name, id, is_selected } = bu;

            return (
                <div className={ classes[ "business-units-checkboxes-container" ] } key={ id } >
                    <label htmlFor={ name } className={ classes[ "business-units-checkboxes-container__label" ] }>
                        <span data-label={ id } className={ is_selected === "1" ? null : "inactive" }>
                            { name }
                        </span>
                    </label>
                    <Toggle
                        onChange={ handleSetBU }
                        defaultChecked={ is_selected === "1" }
                        className="toggle-yellow"
                        icons={ false }
                        name={ name }
                        id={ name }
                        value={ id } />
                </div>
            )
        });

        return (
            <>
                <Container className="competitive-intel">
                    <Row>

                        <Col xs={ 12 }>
                            <h4 className="section-title-business">Business Unit Selection</h4>
                        </Col>
                    </Row>
                    <Row className="business">
                        <Col xs={ 12 }>
                            <div className="biz-unit">
                                { formatBU }
                            </div>
                        </Col>
                    </Row>
                    <Row className="panel-low">
                        <Col xs={ 12 }>
                            <p className="warning">WARNING</p>
                            <p className="warning-bold">Any changes to you Business Unit preferences will effect visibility of surgical specialties, products, survey templates, and talk tracks in your trials.</p>
                            <p className="warning-normal">
                                None of your existing trial information will change, however surgical specialties and products will become uneditable if your preferences do not include the Business Units they are attributed to.
                            </p>
                        </Col>
                    </Row>
                </Container>

            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        BUFromAPI: state.auth.BU,
        BUPostError: state.auth.BUPostError
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getBusinessUnit: () => {
            dispatch(getBusinessUnitAction())
        },
        postBusinessUnit: (BU_ID, status) => {
            dispatch(postBusinessUnitAction(BU_ID, status))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessUnitSelection);