/** @format */

import React from 'react';

import { Container, Row } from 'reactstrap';
import ErrorMessage from '../Components/ErrorMessage/ErrorMessage';

const Stats = (props) => {
	let { active_trial_count, total_people_count, total_customer_count } = props.userCounters;
	let userCountersError = props.userCountersError;

	return (
		<Container>
			<Row className="dashboard-stats">
				<div className="stats2">
					<label>TRIALS</label>
					<div className="number">{ active_trial_count }</div>
				</div>
				<div className="stats2">
					<label>PEOPLE</label>
					<div className="number">{ total_people_count }</div>
				</div>
				<div className="stats2">
					<label>CUSTOMERS</label>
					<div className="number">{ total_customer_count }</div>
				</div>
				{ userCountersError ? (
					<ErrorMessage message={ userCountersError } />
				) : (null) }
			</Row>
		</Container>
	);
};

export default Stats;
