import React, { useState, useEffect } from 'react';
import classes from './AddChecklistItem.module.css';
import { postChecklistItemAction } from '../../../../Store/actions/checklistsAction';
import { useDispatch, useSelector } from 'react-redux';
import useHandleSubmit from '../../../../Hooks/useHandleSubmit';

const AddChecklistItem = ({ toggleModal, trialID, checklist }) => {
	const dispatch = useDispatch();

	const handleCancelClick = (ev) => {
		ev.preventDefault();
		toggleModal();
	};

	const handleChecklistSubmit = () => {
		//dispatch(postStakeholderAction(formData));
		dispatch(
			postChecklistItemAction({
				trialID,
				title: inputs.title,
				groupID: checklist.id,
				description: inputs.description,
				dueDate: inputs.dueDate
			})
		);
		toggleModal();
	};

	const [ canSubmit, setCanSubmit ] = useState(false);
	const allowSubmission = (ev) => {
		handleInputChange(ev);
		setCanSubmit(true);
	};

	const { inputs, handleInputChange, handleSubmit } = useHandleSubmit({
		callback: handleChecklistSubmit,
		initialState: {
			title: '',
			description: '',
			dueDate: ''
		}
	});

	return (
		<form className={classes['form']} onSubmit={handleSubmit}>
			<label className={classes['form__label']} htmlFor="addChecklist">
				Add checklist item
			</label>
			<input
				className={classes['form__input']}
				type="text"
				onChange={allowSubmission}
				value={inputs.title}
				placeholder="Title"
				name="title"
				id="title"
			/>
			<input
				className={classes['form__input']}
				type="text"
				onChange={handleInputChange}
				value={inputs.description}
				placeholder="Description"
				name="description"
				id="description"
			/>
			<input
				className={classes['form__input']}
				type="date"
				onChange={handleInputChange}
				value={inputs.dueDate}
				placeholder="Due date"
				name="dueDate"
				id="dueDate"
			/>
			<div className={classes['form__buttons-container']}>
				<button
					onClick={handleCancelClick}
					className={[ classes['form__button'], classes['form__button--cancel'] ].join(' ')}
				>
					cancel
				</button>
				<button
					className={[
						classes['form__button'],
						canSubmit ? classes['form__button--cansubmit'] : classes['form__button--submit']
					].join(' ')}
					disabled={!canSubmit}
				>
					submit
				</button>
			</div>
		</form>
	);
};

export default AddChecklistItem;
