import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import LogoPreview from '../../Shared/LogoPreview/LogoPreview';
import Footer from '../../Shared/Footer/Footer';
import { Modal } from 'reactstrap';
import ReactEasyCrop from '../../../../../Components/ReactEasyCrop/ReactEasyCrop';
import CloseModalButton from '../../../../../Components/CloseModalButton/CloseModalButton';

import classes from '../CustomizeEmail.module.css';
import emailResultsClasses from '../../EmailResults/EmailResults.module.css';

import { postTemplateImagesToEngagementEmailAction } from '../../../../../Store/actions/trials/engagementEmailsAction';
import { useDispatch, useSelector } from 'react-redux';

import resetInputFileValue from '../../../../../Utils/resetInputFileValue';

const PreTrialCommunicationThankYouEndoscopy = ({ handleInputChange, inputs, formValidationErrors }) => {
    const dispatch = useDispatch();
    const { id, engagementEmailID } = useParams();

    const [ isImageEditingOpen, setIsImageEditingOpen ] = useState(false);
    const toggleIsImageEditingOpen = () => {
        setIsImageEditingOpen(previousIsImageEditingOpen => !previousIsImageEditingOpen)
    };

    const [ uploadedImage, setUploadedImage ] = useState(null);

    const handleImageEditing = (event) => {
        let imageFile = event.target.files[ 0 ];
        setIsImageEditingOpen(true);
        let imageDimensions = {
            height: 136,
            width: 233
        };

        if (event.target.name === 'facility_logo')
        {
            imageDimensions = {
                height: 92,
                width: 460
            }
        }

        setUploadedImage({
            name: event.target.name,
            dimensions: imageDimensions,
            file: URL.createObjectURL(imageFile)
        });
    }

    const handleImageUpload = (imageObject) => {
        let formData = new FormData();
        formData.append('template_id', engagementEmailID);
        formData.append('trial_id', id);
        formData.append('user_id', localStorage.getItem('user_id'));

        if (imageObject.name === 'facility_image')
        {
            formData.append('facility_image', imageObject.file);
            formData.append('facility_logo', inputs.facility_logo);
        } else if (imageObject.name === 'facility_logo')
        {
            formData.append('facility_image', inputs.facility_image);
            formData.append('facility_logo', imageObject.file);
        }

        dispatch(postTemplateImagesToEngagementEmailAction(formData));
    }


    const BASE_URL = process.env.REACT_APP_API_URL;
    let facilityImage = BASE_URL + inputs.facility_image;
    let facilityLogo = BASE_URL + inputs.facility_logo;

    if (inputs.facility_image === "")
    {
        facilityImage = '/images/email/facility-image-placeholder.png';
    }

    if (inputs.facility_logo === "")
    {
        facilityLogo = '/images/email/facility-logo-placeholder.png';
    }

    const postTemplateImagesEngagementEmailError = useSelector(state => state.engagementEmails.postTemplateImagesEngagementEmailError);

    return (
        <div className="customize-email__preview-wrapper">
            <div className={ classes[ "preview-wrapper-box-shadow" ] }>
                <LogoPreview />
                <div className={ classes[ "preview__header" ] }>
                    <div className={ classes[ "preview__header-left" ] }>
                        <label htmlFor="account_name" className="hidden">
                            account name
                        </label>
                        <textarea
                            onChange={ handleInputChange }
                            value={ inputs.account_name }
                            name="account_name"
                            id="account_name"
                            className={ classes[ "preview__header-title-textarea" ] }></textarea>

                        { formValidationErrors?.account_name ? (
                            <span className={ classes[ "input__error" ] }>{ formValidationErrors?.account_name }</span>
                        ) : (null) }

                        <label htmlFor="head_line" className="hidden">
                            headline
                        </label>
                        <textarea
                            onChange={ handleInputChange }
                            value={ inputs.head_line }
                            name="head_line"
                            id="head_line"
                            className={ classes[ "preview__header-headline-textarea" ] }>
                        </textarea>
                        { formValidationErrors?.head_line ? (
                            <span className={ classes[ "input__error" ] }>{ formValidationErrors?.head_line }</span>
                        ) : (null) }

                    </div>

                    <div className={ emailResultsClasses[ "email-results-preview__header-right" ] }>
                        <label htmlFor="facility_image">
                            <img
                                className={ [ emailResultsClasses[ "email-results-preview__header-image" ], "cursor" ].join(" ") }
                                src={ facilityImage }
                                alt="facility image" />
                        </label>

                        <input
                            onChange={ handleImageEditing }
                            onClick={ resetInputFileValue }
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg"
                            name="facility_image"
                            id="facility_image" />

                        <label htmlFor="facility_logo">
                            <img
                                className={ [ emailResultsClasses[ "email-results-preview__header-image" ], "cursor" ].join(" ") }
                                src={ facilityLogo }
                                alt="facility logo" />
                        </label>
                        <input
                            onChange={ handleImageEditing }
                            onClick={ resetInputFileValue }
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg"
                            name="facility_logo"
                            id="facility_logo" />
                    </div>
                </div>

                <div className={ emailResultsClasses[ "email-results-border" ] }></div>

                <div className="customize-email__content-textarea-container">
                    <label htmlFor="content" className="hidden">
                        content
                    </label>
                    <textarea
                        onChange={ handleInputChange }
                        value={ inputs.content }
                        name="content"
                        id="content"
                        cols="75"
                        rows="15"
                        className="customize-email__content-textarea"></textarea>

                    { formValidationErrors?.content ? (
                        <span className={ classes[ "input__error" ] }>{ formValidationErrors?.content }</span>
                    ) : (null) }
                </div>
            </div>

            <div className={ classes[ "preview__subsection" ] }>
                <div className={ classes[ "pre-trial-subsection-wrapper" ] }>
                    <label htmlFor="header_two" className="hidden">
                        header two
                    </label>

                    <textarea
                        onChange={ handleInputChange }
                        value={ inputs.header_two }
                        id="header_two"
                        name="header_two"
                        className={ classes[ "preview__subsection-textarea" ] }></textarea>

                    { formValidationErrors?.header_two ? (
                        <span className={ classes[ "input__error" ] }>{ formValidationErrors?.header_two }</span>
                    ) : (null) }
                </div>
                <div className={ [ emailResultsClasses[ "email-results-border" ], emailResultsClasses[ "-thin" ] ].join(" ") }></div>
            </div>

            <a
                className={ classes[ "pre-trial__video-link" ] }
                href={ inputs.video }
                title="video player"
                target="_blank"
                rel="noopener noreferrer">
                <img
                    className={ classes[ "pre-trial__video-link-image" ] }
                    src="/images/email/video-img_new.png"
                    alt="video" />
            </a>

            <Footer text={ inputs.footer } containerClasses={ classes[ "email-preview__footer" ] } />

            <Modal
                isOpen={ isImageEditingOpen }
                toggle={ toggleIsImageEditingOpen }
                centered
                backdrop="static">
                <CloseModalButton onClick={ toggleIsImageEditingOpen } />

                <ReactEasyCrop
                    image={ uploadedImage }
                    toggleIsImageEditingOpen={ toggleIsImageEditingOpen }
                    onSuccessCallback={ handleImageUpload } />
            </Modal>
        </div>
    );
}

export default PreTrialCommunicationThankYouEndoscopy;