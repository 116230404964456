import React from 'react';
import _ from "lodash";
import "./pagination.css";

const Pagination = props => {
    const { itemsCount, pageSize, onPageChange, currentPage } = props;
    const pagesCount = Math.ceil(itemsCount / pageSize);
    if (pagesCount === 1) return null;

    const pages = _.range(1, pagesCount + 1);

    return (
        <nav>
            <ul className="pagination justify-content-center mt-3">
                { pages.map(page =>
                    <li key={ page }>
                        <div onClick={ () => onPageChange(page) }
                            className={ page === currentPage ? "pagelink active" : "pagelink" }>{ page }</div>
                    </li>)
                }
            </ul>
        </nav>
    );
}

export default Pagination;