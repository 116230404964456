import { useState } from 'react';
import formatPhoneNumber from '../Utils/formatPhoneNumber';

const useHandleSubmit = ({ callback, initialState = {} }) => {
    const [ inputs, setInputs ] = useState(initialState);
    const handleInputChange = (ev) => {
        if (ev.persist)
        {
            ev.persist();
        }

        if (ev.target.name.toLowerCase().includes('phone'))
        {
            let phoneNumber = formatPhoneNumber(ev.target.value);
            if (phoneNumber !== false) return setInputs((previousInputs) => ({ ...previousInputs, [ ev.target.name ]: formatPhoneNumber(ev.target.value) }));
            return;
        }

        setInputs((previousInputs) => ({ ...previousInputs, [ ev.target.name ]: ev.target.value }));
    }

    function handleSubmit (ev) {
        if (ev)
        {
            ev.preventDefault();
        }

        callback(...arguments);
    }

    return {
        inputs,
        handleInputChange,
        handleSubmit
    };
}

export default useHandleSubmit;