import React, { Component } from 'react';

import CreateOrEditClasses from '../CreateOrEdit/CreateOrEdit.module.css';
import classes from './SavePopUp.module.css';

import { Modal } from 'reactstrap';
import ComponentLoading from '../../../../Components/ComponentLoading/ComponentLoading';
import ErrorMessage from '../../../../Components/ErrorMessage/ErrorMessage';
import CloseModalButton from '../../../../Components/CloseModalButton/CloseModalButton';

class SavePopUp extends Component {
	cancelPopUp = (ev) => {
		ev.preventDefault();
		this.props.toggleSurveyAndEvaluationSaveFormPopUp();
	};

	render() {
		const {
			saveTemplate,
			surveyAndEvaluationSaveFormPopUpIsOpen,
			toggleSurveyAndEvaluationSaveFormPopUp,
			saving,
			duplicateSurveyAndEvaluationError
		} = this.props;
		
		return (
			<Modal
				className={classes['form-duplicate-pop-up']}
				id="form-duplicate-survey-and-evaluation"
				isOpen={surveyAndEvaluationSaveFormPopUpIsOpen}
				toggle={toggleSurveyAndEvaluationSaveFormPopUp}
				backdrop="static"
			>
				<CloseModalButton onClick={ toggleSurveyAndEvaluationSaveFormPopUp } />
				
				{!saving ? (
					<form className={classes['form-duplicate']}>
						<h2 className={classes['form-duplicate__title']}>Save template</h2>
{/* 
						<button
							className={classes['form-duplicate__submit']}
							onClick={(ev) => saveTemplate(ev, 'trial')}
						>
							save to trial
						</button> */}
						<button
							className={classes['form-duplicate__edit']}
							onClick={(ev) => saveTemplate(ev, 'library')}
						>
							save &amp; exit
						</button>

						{ duplicateSurveyAndEvaluationError ? (
							<ErrorMessage message={`${ duplicateSurveyAndEvaluationError } error`} />
						) : (null) }

						<button onClick={this.cancelPopUp} className={classes['form-duplicate__cancel']}>
							cancel
						</button>
					</form>
				) : (
					<div>
						<ComponentLoading />
					</div>
				)}
			</Modal>
		);
	}
}

export default SavePopUp;
