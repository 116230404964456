import React, { useState, useEffect } from 'react';

import CancelButton from '../../../../../Components/FormButtons/CancelButton';
import SubmitButton from '../../../../../Components/FormButtons/SubmitButton';
import BarGraph from '../../../../../Components/BarGraph/BarGraph';
import DoughnutChart from '../../../../../Components/DisplayChart/DoughnutChart';
import PieChart from '../../../../../Components/DisplayChart/PieChart';
import NoResults from '../../../../../Components/NoResults/NoResults';

import classes from './Forms.module.css';

import { useDispatch, useSelector } from 'react-redux';
import { getSurveyReviewResultDetailAllAction, getEvaluationReviewResultDetailAllAction } from '../../../../../Store/actions/trials/engagementEmailsAction';
import { useParams } from 'react-router-dom';
import ComponentLoading from '../../../../../Components/ComponentLoading/ComponentLoading';

const AddASurveyOrEvaluationStatistic = (
    {
        toggleAddASurveyOrEvaluationStatisticModalOpen,
        inputs,
        handleInputChange,
        setCurrentlyChangingSurveyOrEvaluationStatistic,
        currentlyChangingSurveyOrEvaluationStatistic
    }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const surveyReviewResultDetailAllQuestionList = useSelector(state => state.engagementEmails.surveyReviewResultDetailAllQuestionList);
    const surveyReviewResultDetailAllError = useSelector(state => state.engagementEmails.surveyReviewResultDetailAllError);

    const evaluationReviewResultDetailAllQuestionList = useSelector(state => state.engagementEmails.evaluationReviewResultDetailAllQuestionList);
    const evaluationReviewResultDetailAllError = useSelector(state => state.engagementEmails.evaluationReviewResultDetailAllError);

    useEffect(() => {
        dispatch(getSurveyReviewResultDetailAllAction(id));
        dispatch(getEvaluationReviewResultDetailAllAction(id));
    }, []);

    const [ singleTypeOfQuestions, setSingleTypeOfQuestions ] = useState([])
    const [ currentView, setCurrentView ] = useState(0);

    const handleGoNextView = (event) => {
        let [ statisticType, selectedQuestionType ] = event.target.getAttribute('data-type').split('-');
        if (statisticType === 'surveys')
        {
            setSingleTypeOfQuestions(surveyReviewResultDetailAllQuestionList.filter(question => question.questions_type === selectedQuestionType));
        } else
        {
            setSingleTypeOfQuestions(evaluationReviewResultDetailAllQuestionList.filter(question => question.questions_type === selectedQuestionType));
        }
        setCurrentView(1);
    }

    const [ chosenSurveyOrEvalution, setChosenSurveyOrEvaluation ] = useState(false);

    const handleAddSurveyOrEvaluationStatistic = (event) => {
        event.preventDefault();

        if (chosenSurveyOrEvalution === false) return;

        let formatChosenSurveyOrEvalution = {
            question_name: chosenSurveyOrEvalution.questions,
            question_id: chosenSurveyOrEvalution.id,
            question_options: chosenSurveyOrEvalution.question_options,
            questions_type: chosenSurveyOrEvalution.questions_type,
            id: chosenSurveyOrEvalution.id,
        }

        let cloneWeeklyStatistic = JSON.parse(JSON.stringify(inputs.weekly_statistic));

        if (currentlyChangingSurveyOrEvaluationStatistic)
        {
            cloneWeeklyStatistic = inputs.weekly_statistic.filter(surveyOrEvaluationStatisticStoredInState => surveyOrEvaluationStatisticStoredInState.id !== currentlyChangingSurveyOrEvaluationStatistic.id);
        }

        cloneWeeklyStatistic.push(formatChosenSurveyOrEvalution);

        handleInputChange({
            target: {
                name: 'weekly_statistic',
                value: cloneWeeklyStatistic
            }
        });

        setCurrentlyChangingSurveyOrEvaluationStatistic(false);

        setChosenSurveyOrEvaluation(false);

        toggleAddASurveyOrEvaluationStatisticModalOpen();
    }

    const goBack = (event) => {
        event.preventDefault();
        setCurrentView(0);
    }

    const selectChart = ({ type, question }) => {
        if (type === 'rating')
        {
            return (
                <BarGraph
                    chartStatistics={ question.question_options }
                    isSelectOption={ true } />
            );
        } else if (type === 'multiple')
        {
            return (
                <div className={ classes[ "form__chart-container" ] }>
                    <DoughnutChart
                        data={ question.question_options.map(option => {
                            return { label: option.options, percentage: option.option_response_percentage };
                        }) } />
                </div>
            );
        } else
        {
            return (
                <div className={ classes[ "form__chart-container" ] }>
                    <PieChart
                        data={ question.question_options.map(option => {
                            return { label: option.options, percentage: option.option_response_percentage };
                        }) } />
                </div>
            );
        }
    }

    const selectViewToShow = () => {
        if (surveyReviewResultDetailAllQuestionList === false || evaluationReviewResultDetailAllQuestionList === false)
        {
            return (
                <ComponentLoading message="Loading..."/>
            );
        }

        if (currentView === 0)
        {
            return (
                <div>
                    <div className={ classes[ "form__options" ] }>
                        <h3 className={ classes[ "form__sub-title" ] }>surveys</h3>
                        <div
                            onClick={ handleGoNextView }
                            data-type="surveys-multiple"
                            className={ classes[ "form__option" ] }>
                            Multiple choice questions <i className={ [ "las la-angle-right", classes[ "form__option-icon" ] ].join(" ") }></i>
                        </div>
                        <div
                            onClick={ handleGoNextView }
                            data-type="surveys-yesno"
                            className={ classes[ "form__option" ] }>
                            Yes / No questions <i className={ [ "las la-angle-right", classes[ "form__option-icon" ] ].join(" ") }></i>
                        </div>
                        <div
                            onClick={ handleGoNextView }
                            data-type="surveys-rating"
                            className={ classes[ "form__option" ] }>
                            Rating questions <i className={ [ "las la-angle-right", classes[ "form__option-icon" ] ].join(" ") }></i>
                        </div>
                    </div>
                    <div className={ classes[ "form__options" ] }>
                        <h3 className={ classes[ "form__sub-title" ] }>evaluations</h3>
                        <div
                            onClick={ handleGoNextView }
                            data-type="evaluations-multiple"
                            className={ classes[ "form__option" ] }>
                            Multiple choice questions <i className={ [ "las la-angle-right", classes[ "form__option-icon" ] ].join(" ") }></i>
                        </div>
                        <div
                            onClick={ handleGoNextView }
                            data-type="evaluations-yesno"
                            className={ classes[ "form__option" ] }>
                            Yes / No questions <i className={ [ "las la-angle-right", classes[ "form__option-icon" ] ].join(" ") }></i>
                        </div>
                        <div
                            onClick={ handleGoNextView }
                            data-type="evaluations-rating"
                            className={ classes[ "form__option" ] }>
                            Rating questions <i className={ [ "las la-angle-right", classes[ "form__option-icon" ] ].join(" ") }></i>
                        </div>
                        <div className={ classes[ "form__buttons-container" ] }>
                            <CancelButton handleClick={ toggleAddASurveyOrEvaluationStatisticModalOpen } />
                        </div>
                    </div>
                </div>
            );
        } else
        {
            return (
                <React.Fragment>
                    { singleTypeOfQuestions.length > 0 ? (
                        singleTypeOfQuestions.map(question => {
                            let isChosen;
                            if (inputs.weekly_statistic === undefined) isChosen = -1;
                            isChosen = inputs?.weekly_statistic > 0 ? inputs.weekly_statistic.findIndex(weeklyStatisticStoredInState => weeklyStatisticStoredInState.id === question.id) : -1;

                            let isChangingSurveyOrEvaluationStatistic = question.id === currentlyChangingSurveyOrEvaluationStatistic.id;

                            return (
                                <div
                                    key={ question.id }
                                    className={ classes[ "form__group" ] } >
                                    <input
                                        onChange={ () => setChosenSurveyOrEvaluation(question) }
                                        disabled={ isChangingSurveyOrEvaluationStatistic ? false : isChosen !== -1 }
                                        defaultChecked={ isChangingSurveyOrEvaluationStatistic }
                                        name="survey-or-evaluation-option"
                                        id={ `survey-or-evaluation-option-${ question.id }` }
                                        className="hidden-radio-input"
                                        type="radio" />

                                    <label
                                        htmlFor={ `survey-or-evaluation-option-${ question.id }` }
                                        className="input-checkmark-circle">
                                        <i className="las la-check" />
                                    </label>
                                    <label
                                        htmlFor={ `survey-or-evaluation-option-${ question.id }` }
                                        className={ classes[ "form__chart-label" ] }>
                                        { selectChart({ type: question.questions_type, question }) }
                                        <span className={ classes[ "form__label-text" ] }>
                                            { question.questions }
                                        </span>
                                    </label>
                                </div>
                            )
                        })
                    ) : (
                            <NoResults message="no data found" />
                        ) }

                    <div className={ classes[ "form__buttons-container" ] }>
                        <CancelButton handleClick={ toggleAddASurveyOrEvaluationStatisticModalOpen } />

                        <SubmitButton handleClick={ handleAddSurveyOrEvaluationStatistic } isGray={ true } />
                    </div>

                    <button className="left-arrow-icon-button" onClick={ goBack }>
                        <img className="left-arrow-icon" src="/images/email/icon-left-arrow-big.png" alt="left arrow icon" />
                    </button>
                </React.Fragment>
            )
        }
    }

    return (
        <form className={ classes[ "form" ] }>
            <h2 className={ classes[ "form__title" ] }>
                Change statistic
            </h2>
            { selectViewToShow() }
        </form>
    );
}

export default AddASurveyOrEvaluationStatistic;