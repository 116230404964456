import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { deleteSurgeonFromTrialAction } from '../../../../../Store/actions/casesAction';
import classes from './RemoveSurgeon.module.css';

class RemoveSurgeon extends Component {
	deleteSurgeonFromTrial = (surgeonID) => {
		const { id, surgeonID: surgeonIDFromTrial, caseID } = this.props.match.params;

		this.props.deleteSurgeonFromTrial({ surgeonID, caseID: caseID, trialID: id, surgeonIDFromTrial });
	};

	render() {
		const { deleteSurgeonFromTrial } = this;
		const { surgeons } = this.props;
		const surgeonsList = surgeons.map((surgeon) => {
			return (
				<div className={classes['surgeon-container']} key={surgeon.id}>
					<i
						onClick={() => {
							deleteSurgeonFromTrial(surgeon.id);
						}}
						className={[ classes['surgeon__x-icon'], 'las la-times-circle' ].join(' ')}
					/>
					<div className={classes['surgeon']}>
						<div className={classes['surgeon__name']}>{surgeon.people}</div>
						<div className={classes['surgeon__specialty']}>{surgeon.specialty}</div>
					</div>
				</div>
			);
		});
		return <div className={classes['surgeon-wrapper']}>{surgeonsList}</div>;
	}
}

const mapStateToProps = (state) => {
	return {
		deleteSurgeonFromTrialError: state.cases.deleteSurgeonFromTrialError
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		deleteSurgeonFromTrial: (details) => {
			dispatch(deleteSurgeonFromTrialAction(details));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RemoveSurgeon));
