import React from 'react';

import classes from './Loading.module.css';

const ComponentLoading = ({ message = "Loading...", isFullHeight = false, removeHeight = false }) => {
	return (
		<div className={ [ classes[ 'loading-container' ], isFullHeight ? ("vh-100") : (""), removeHeight ? ("h-auto py-3") : ("") ].join(" ") }>
			<div className={classes['loading-indicator']}>
				<div />
				<div />
				<div />
				<div />
				<div />
				<div />
				<div />
				<div />
			</div>

			<div className={ classes[ 'loading-text' ] }>{ message }</div>
		</div>
	);
};

export default ComponentLoading;
