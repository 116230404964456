import * as ActionTypes from '../actions/ActionTypes';

const initState = {
    isFetchingGetChecklists: false,
    getChecklists: [],
    getChecklistsError: '',
    postChecklistError: '',
    putChecklistError: '',
    deleteChecklistError: '',
    postChecklistItemError: '',
    putChecklistItemError: '',
    deleteChecklistItemError: '',
    putChecklistItemStatusError: '',

    checklistComments: {},
    checklistCommentsError: '',
    postChecklistCommentError: ''
}

const checklistsReducer = (state = initState, action) => {
    switch (action.type)
    {
        case ActionTypes.REQUEST_GET_CHECKLISTS:
            return {
                ...state,
                isFetchingGetChecklists: true
            }

        case ActionTypes.GET_CHECKLISTS_SUCCESS:
            return {
                ...state,
                getChecklists: action.getChecklists,
                getChecklistsError: '',
                isFetchingGetChecklists: false
            }

        case ActionTypes.GET_CHECKLISTS_ERROR:
            return {
                ...state,
                getChecklistsError: action.getChecklistsError,
                isFetchingGetChecklists: false
            }

        case ActionTypes.POST_CHECKLIST_SUCCESS:
            return {
                ...state,
                postChecklistError: ''
            }

        case ActionTypes.POST_CHECKLIST_ERROR:
            return {
                ...state,
                postChecklistError: action.postChecklistError
            }

        case ActionTypes.PUT_CHECKLIST_SUCCESS:
            return {
                ...state,
                putChecklistError: ''
            }

        case ActionTypes.PUT_CHECKLIST_ERROR:
            return {
                ...state,
                putChecklistError: action.putChecklistError
            }

        case ActionTypes.DELETE_CHECKLIST_SUCCESS:
            return {
                ...state,
                deleteChecklistError: ''
            }

        case ActionTypes.DELETE_CHECKLIST_ERROR:
            return {
                ...state,
                deleteChecklistError: action.deleteChecklistError
            }

        case ActionTypes.POST_CHECKLIST_ITEM_SUCCESS:
            return {
                ...state,
                postChecklistItemError: ''
            }

        case ActionTypes.POST_CHECKLIST_ITEM_ERROR:
            return {
                ...state,
                postChecklistItemError: action.postChecklistItemError
            }

        case ActionTypes.PUT_CHECKLIST_ITEM_SUCCESS:
            return {
                ...state,
                putChecklistItemError: ''
            }

        case ActionTypes.PUT_CHECKLIST_ITEM_ERROR:
            return {
                ...state,
                putChecklistItemError: action.putChecklistItemError
            }

        case ActionTypes.DELETE_CHECKLIST_ITEM_SUCCESS:
            return {
                ...state,
                deleteChecklistItemError: ''
            }

        case ActionTypes.DELETE_CHECKLIST_ITEM_ERROR:
            return {
                ...state,
                deleteChecklistItemError: action.deleteChecklistItemError
            }

        case ActionTypes.PUT_CHECKLIST_ITEM_STATUS_SUCCESS:
            return {
                ...state,
                putChecklistItemStatusError: ''
            }

        case ActionTypes.PUT_CHECKLIST_ITEM_STATUS_ERROR:
            return {
                ...state,
                putChecklistItemStatusError: action.putChecklistItemStatusError
            }

        case ActionTypes.GET_CHECKLIST_COMMENTS_SUCCESS:
            return {
                ...state,
                checklistComments: action.checklistComments,
                checklistCommentsError: ''
            }

        case ActionTypes.GET_CHECKLIST_COMMENTS_ERROR:
            return {
                ...state,
                checklistCommentsError: action.checklistCommentsError
            }

        case ActionTypes.POST_CHECKLIST_COMMENT_SUCCESS:
            return {
                ...state,
                postChecklistCommentError: ''
            }

        case ActionTypes.POST_CHECKLIST_COMMENT_ERROR:
            return {
                ...state,
                postChecklistCommentError: action.postChecklistCommentError
            }

        default:
            return state;
    }
}

export default checklistsReducer;