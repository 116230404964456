const setEngagementEmailDataFromAPIIntoState = ({ engagementEmailDataFromAPI, handleInputChange }) => {
    let engagementEmailDataFromAPIContentJSON = engagementEmailDataFromAPI?.content_json;
    for (const property in engagementEmailDataFromAPIContentJSON)
    {
        handleInputChange({
            target: {
                name: property,
                value: engagementEmailDataFromAPIContentJSON[ property ]
            }
        });
    }

    if (engagementEmailDataFromAPI?.template_type === 'service_recap')
    {
        handleInputChange({
            target: {
                name: 'inservice_attendees_is_on',
                value: engagementEmailDataFromAPIContentJSON.inservice_attendees_is_on || "0"
            }
        });

        let addBulletPoints = engagementEmailDataFromAPIContentJSON.inservice_attendees_line1.replaceAll('&bull;', String.fromCharCode(8226));

        handleInputChange({
            target: {
                name: 'inservice_attendees_line1',
                value: addBulletPoints
            }
        });
    }

    if (engagementEmailDataFromAPI?.template_type === 'integration_trial_complete')
    {
        handleInputChange({
            target: {
                name: 'statistic',
                value: Array.isArray(engagementEmailDataFromAPI.statistic) ? (engagementEmailDataFromAPI.statistic) : ([])
            }
        });

        handleInputChange({
            target: {
                name: 'verbatim',
                value: Array.isArray(engagementEmailDataFromAPI.verbatim) ? (engagementEmailDataFromAPI.verbatim) : ([])
            }
        });

        handleInputChange({
            target: {
                name: 'weekly_statistic',
                value: Array.isArray(engagementEmailDataFromAPI.weekly_statistic) ? (engagementEmailDataFromAPI.weekly_statistic) : ([])
            }
        });
    }
}

export default setEngagementEmailDataFromAPIIntoState;