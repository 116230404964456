import { combineReducers } from 'redux';
import tracksReducer from './tracksReducer';
import authReducer from './authReducer';
import trialsReducer from './trialsReducer';
import engagementEmailsReducer from './trials/engagementEmailsReducer';
import productEducationReducer from './productEducationReducer';
import casesReducer from './casesReducer';
import checklistsReducer from './checklistsReducer';
import peopleReducer from './peopleReducer';
import surveysReducer from './surveysReducer';
import evaluationsReducer from './evaluationsReducer';
import surveysAndEvaluationsReducer from './surveysAndEvaluationsReducer';
import relatedTrialsReducer from './relatedTrialsReducer';
import userCountersReducer from './userCountersReducer';
import * as ActionTypes from '../actions/ActionTypes';

const appReducer = combineReducers({
	tracks: tracksReducer,
	auth: authReducer,
	trials: trialsReducer,
	engagementEmails: engagementEmailsReducer,
	productEducation: productEducationReducer,
	relatedTrials: relatedTrialsReducer,
	cases: casesReducer,
	checklists: checklistsReducer,
	people: peopleReducer,
	surveysAndEvaluations: surveysAndEvaluationsReducer,
	evaluations: evaluationsReducer,
	surveys: surveysReducer,
	userCounters: userCountersReducer
});

const rootReducer = (state, action) => {
	if (action.type === ActionTypes.RESET_TRIALS_REDUCER) {
		state = {
			...state,
			trials: {
				...state.trials,
				trialDetails: {},
				trialPeople: []
			}
		};
	}

	if (action.type === ActionTypes.RESET_TALK_TRACK_SINGLE_IN_REDUCER) {
		state = {
			...state,
			tracks: {
				...state.tracks,
				groupTrackSingle: {}
			}
		};
	}

	if (action.type === ActionTypes.RESET_COMPETIIVE_INTEL_FOLDER_IN_REDUCER)
	{
		state = {
			...state,
			productEducation: {
				...state.productEducation,
				competitiveIntelFolder: false
			}
		};
	}

	return appReducer(state, action);
};

export default rootReducer;
