import React from 'react';
import { Link } from "react-router-dom";
import "./listGroup.css"

export const ListGroup = (props) => {
    const { items, onItemSelect, selectedItem } = props;
    return (
        <>
            {items.map((item) =>
                <div onClick={ () => onItemSelect(item) }
                    key={ item.id }
                    className={ ((item.name === selectedItem.name) ? "pagelink-sml active" : "pagelink-sml inactive") }
                >
                    { `${item.name.substring(0, 1).toUpperCase()}${item.name.substring(1)}`  }
                </div>)
            }
        </>
    );
}

//TalkTracks
export const ListGroupTalk = (props) => {
    const { items, onItemSelect } = props;
    let pageURL = window.location.href;
    let lastURLSegment = pageURL.substr(pageURL.lastIndexOf('/') + 1);

    return (
        <>
            <Link onClick={ () => onItemSelect(items[ 0 ]) }
                className={
                    (lastURLSegment === "specialty")
                        ? "pagelink-sml active"
                        : "pagelink-sml inactive" }
                to={ { pathname: "/talktracks/specialty" } } >
                by { items[ 0 ].name }
            </Link>
            <Link onClick={ () => onItemSelect(items[ 1 ]) }
                className={ (
                    (lastURLSegment === "stakeholder")
                        ? "pagelink-sml active"
                        : "pagelink-sml inactive") }
                to={ { pathname: "/talktracks/stakeholder" } } >
                by { items[ 1 ].name }
            </Link>
        </>
    );
}

//TalkTracks
export const ListGrpSpecial = (props) => {
    let { items, selectedName, onItemSelect } = props;
    selectedName = selectedName.replace('-', '/');

    return (
        <>
            <Link
                onClick={ () => onItemSelect(items[ 0 ]) }
                className="pagelink-sml inactive"
                to={ { pathname: "/talktracks/specialty" } } >
                by Specialty
            </Link>
            <span className="triangle mr-2 grey">  ▸ </span>
            <div className="pagelink-sml active">  { selectedName }</div>
        </>
    );
}

//TalkTracks
export const ListGrpStake = (props) => {
    const { items, selectedName, onItemSelect } = props;

    return (
        <>
            <Link
                onClick={ () => onItemSelect(items[ 1 ]) }
                className="pagelink-sml inactive"
                to={ { pathname: "/talktracks/stakeholder" } } >
                by Stakeholder
            </Link>
            <span className="triangle mr-2">  ▸ </span>
            <div className="pagelink-sml active">  { selectedName }</div>
        </>
    );
}

//TalkTracks
export const ListGrpSpeSingle = (props) => {
    const { items, selectedName, onItemSelect, lastURLSegment, history, type } = props;
    const selectedFile = lastURLSegment.replace(/%20/g, ' ');

    const pathName = history.location.pathname;

    const goBack = () => {
        let pathSplit = pathName.split('/');
        if (typeof parseInt(pathSplit[ pathSplit.length ]) === "number")
        {
            pathSplit.pop()
            pathSplit.pop();
            let oneLayerUpLinkPath = pathSplit.join('/');
            history.push(oneLayerUpLinkPath)
        } else
        {
            history.goBack();
        }
    };

    return (
        <>
            {type === 'stakeholder' ? (
                <Link
                    className="pagelink-sml inactive"
                    to={ { pathname: "/talktracks/stakeholder" } } >
                    by Stakeholder
                </Link>
            ) : (
                    <Link
                        onClick={ () => onItemSelect(items[ 0 ]) }
                        className="pagelink-sml inactive"
                        to={ { pathname: "/talktracks/specialty" } } >
                        by Specialty
                    </Link>
                )
            }
            <span className="triangle mr-2 grey">  ▸ </span>
            <Link to='#' onClick={ goBack } className="pagelink-sml inactive">  { selectedName }</Link>
            <span className="triangle mr-2">  ▸ </span>
            <div className="pagelink-sml active">  { selectedFile }</div>
        </>
    );
}

//TalkTracks
export const ListGrpAudio = props => {
    const { selectedName, lastURLSegment } = props;

    return (
        <>
            <Link to={ `/talktracks/specialty/${ selectedName }` } className="pagelink-sml inactive"> { selectedName.toUpperCase() }</Link>
            <span className="triangle mr-2">  ▸ </span>
            <div className="pagelink-sml active">  { lastURLSegment }</div>
        </>
    );
}

//Trials
export const ListGroupTrials = (props) => {
    const { items, onItemSelect, selectedItem } = props;

    return (
        <>
            {items.map((item) =>
                <div onClick={ () => onItemSelect(item) }
                    key={ item.id }
                    className={ ((item === selectedItem) ? "pagelink-sml active" : "pagelink-sml inactive") }
                >
                    { item.name }
                </div>)
            }
        </>
    );
}

//Trials-Survey
export const LisTrialsSurveyEval = (props) => {
    const { items, onItemSelect, selectedItem } = props;

    return (
        <>
            {items.map((item) =>
                <div onClick={ () => onItemSelect(item) }
                    key={ item.id }
                    className={ ((item === selectedItem) ? "pagelink-sml active" : "pagelink-sml inactive") }
                >
                    { item.name }
                </div>)
            }
        </>
    );
}


//Trials-Popple
export const ListGroupTrialsPeople = (props) => {
    const { items, onItemSelect, selectedItem } = props;

    return (
        <>
            {items.map((item) =>
                <div onClick={ () => onItemSelect(item) }
                    key={ item.id }
                    className={ ((item === selectedItem) ? "pagelink-sml active" : "pagelink-sml inactive") }
                >
                    { item.name }
                </div>)
            }
        </>
    );
}

//Trials-Popple
export const ListGroupTrialsRespondent = (props) => {
    const { items, onItemSelect, selectedItem } = props;

    return (
        <>
            {items.map((item) =>
                <div onClick={ () => onItemSelect(item) }
                    key={ item.id }
                    className={ ((item === selectedItem) ? "pagelink-sml active" : "pagelink-sml inactive") }
                >
                    { item.name }
                </div>)
            }
        </>
    );
}

//Trials-Engagement Emails
export const ListGroupEngagementEmails = props => {
    const { items, onItemSelect, selectedItem} = props;

    return (
        <>
            {items.map((item) =>
                <div onClick={ () => onItemSelect(item) }
                    key={ item.id }
                    className={ ((item === selectedItem) ? "pagelink-sml active" : "pagelink-sml inactive") }
                >
                    { item.name }
                </div>)
            }
        </>
    );
}



