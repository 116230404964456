import React from 'react';
import { Switch, Route } from 'react-router-dom';
import NavBar from '../Navi/NavBar';

import People from '../Pages/People';
import TrialPeopleHistory from '../Pages/Trial/TrialPeopleHistory/TrialPeopleHistory';

const PeopleRoute = () => {
	return (
		<React.Fragment>
			<NavBar />
			<Switch>
				<Route exact path="/people">
					<People />
				</Route>
				<Route exact path="/people/:type/:stakeholderOrSurgeonID">
					<TrialPeopleHistory />
				</Route>
			</Switch>
		</React.Fragment>
	);
};

export default PeopleRoute;
