import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';

import classes from './classes.module.css';

import {
    postSendMailAction,
} from '../../../../Store/actions/trials/engagementEmailsAction';
import { useDispatch, useSelector } from 'react-redux';

const SendEngagementEmail = ({ toggleSendEngagementEmailModal, toggleEngagementEmailSentNotificationModal, amountOfRecipients }) => {
    const iconContainerRef = useRef(null);
    const toggleClass = () => {
        iconContainerRef.current.classList.toggle(classes[ "hover-over-cancel-button" ])
    }

    const postSendMailError = useSelector(state => state.engagementEmails.postSendMailError);

    const dispatch = useDispatch();
    const { engagementEmailID } = useParams();

    return (
        <div className={ classes[ "send-engagement-email-container" ] }>
            <h2 className={ classes[ "send-engagement-email__title" ] }>
                Ready to send?
            </h2>
            <p className={ classes[ "send-engagement-email__text" ] }>
                You are about to send this engagement email to { amountOfRecipients } CRUSH recipients.
            </p>
            <p className={ classes[ "send-engagement-email__subtext" ] }>
                Engagement emails can not be unsent.
            </p>

            <div
                ref={ iconContainerRef }
                className={ classes[ "send-engagement-email__image-container" ] }>
                <img
                    onClick={ () => dispatch(postSendMailAction({ userTemplateID: engagementEmailID, toggleSendEngagementEmailModal, toggleEngagementEmailSentNotificationModal })) }
                    className={ [ classes[ "send-engagement-email__image" ], classes[ "send-engagement-email__image--yellow" ] ].join(" ") }
                    src="/images/email/icon-email-send.png"
                    alt="send engagement email icon" />
                <img
                    className={ [ classes[ "send-engagement-email__image" ], classes[ "send-engagement-email__image--gray" ] ].join(" ") }
                    src="/images/email/icon-email-send-gray.png"
                    alt="send engagement email icon" />
            </div>

            <div className={ classes[ "send-engagement-email__button-container" ] }>
                <button
                    onClick={ toggleSendEngagementEmailModal }
                    onMouseOver={ toggleClass }
                    onMouseOut={ toggleClass }
                    className={ [ classes[ "send-engagement-email__button" ], classes[ "send-engagement-email__button--gray" ] ].join(" ") }>
                    cancel
                </button>
            </div>
        </div>
    );
}

export default SendEngagementEmail;