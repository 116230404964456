import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import NavBarSub from '../Navi/NavBarSub';
import TalkTrkSpecialty from '../Pages/TalkTrkSpecialty';
import TalkTrkStakeholder from '../Pages/TalkTrkStakeholder';
import TalkTrkSpecialtySub from '../Pages/TalkTrkSpecialtySub';
import TalkTrkStakeholderSub from '../Pages/TalkTrkStakeholderSub';
import TalkTrkSubSingle from '../Pages/TalkTrkSubSingle';
// import TalkTrkAudio from "../Pages/TalkTrkAudio";
import PendingContent from '../Pages/PendingContent';

import { getStakeholdersAction, getSpecialtyAction } from '../Store/actions/tracksAction';
import { connect } from 'react-redux';

class TalkTracksRoute extends Component {
	componentDidMount() {
		this.props.getStakeholders();
		this.props.getSpecialty();
	}

	render() {
		const {
			stakeholdersFromAPI,
			stakeholdersError,
			isFetchingStakeholders,
			specialtyFromAPI,
			specialtyError,
			isFetchingSpecialty
		} = this.props;

		const mappedStakeholders = stakeholdersFromAPI.map((record) => {
			return {
				...record,
				id: record.id,
				type: 'track',
				name: record.name,
				noOfTracks: record.product ? record.product.length : 0
			};
		});

		const mappedSpecialty = specialtyFromAPI.map((record) => {
			return {
				...record,
				id: record.id,
				type: 'track',
				name: record.name,
				noOfTracks: record.product ? record.product.length : 0,
				active: 'active',
				category: { id: '8881', name: 'Specialty' }
			};
		});

		return (
			<React.Fragment>
				<NavBarSub />
				<Switch>
					<Route
						exact
						path="/talktracks/specialty"
						component={() => (
							<TalkTrkSpecialty
								specialtyFromAPI={mappedSpecialty}
								specialtyError={specialtyError}
								isFetchingSpecialty={isFetchingSpecialty}
							/>
						)}
					/>
					<Route
						exact
						path="/talktracks/stakeholder"
						component={() => (
							<TalkTrkStakeholder
								stakeholdersFromAPI={mappedStakeholders}
								stakeholdersError={stakeholdersError}
								isFetchingStakeholders={isFetchingStakeholders}
							/>
						)}
					/>
					<Route
						exact
						path="/talktracks/specialty/:groupName/:productId"
						component={(props) => (
							<TalkTrkSpecialtySub
								{...props}
								specialtyFromAPI={mappedSpecialty}
								specialtyError={specialtyError}
								isFetchingSpecialty={isFetchingSpecialty}
							/>
						)}
					/>
					<Route
						exact
						path="/talktracks/stakeholder/:groupName/:productId"
						component={(props) => (
							<TalkTrkStakeholderSub
								{...props}
								stakeholdersFromAPI={mappedStakeholders}
								stakeholdersError={stakeholdersError}
								isFetchingStakeholders={isFetchingStakeholders}
							/>
						)}
					/>
					<Route
						exact
						path="/talktracks/:type/:groupName/:productId/:fileName/:productSubId"
						component={(props) => (
							<TalkTrkSubSingle
								{...props}
								specialtyFromAPI={mappedSpecialty}
								stakeholdersFromAPI={mappedStakeholders}
							/>
						)}
					/>
					{/* <Route exact path="/talktracks/:type/audio/:groupName/:productId/:fileName/:productSubId" component={ TalkTrkAudio } /> */}
					<Route path="/PendingContent">
						<PendingContent />
					</Route>
				</Switch>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		stakeholdersFromAPI: state.tracks.stakeholders,
		stakeholdersError: state.tracks.stakeholdersError,
		isFetchingStakeholders: state.tracks.isFetchingStakeholders,

		specialtyFromAPI: state.tracks.specialty,
		specialtyError: state.tracks.error,
		isFetchingSpecialty: state.tracks.isFetchingSpecialty
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getStakeholders: () => {
			dispatch(getStakeholdersAction());
		},
		getSpecialty: () => {
			dispatch(getSpecialtyAction());
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TalkTracksRoute);
