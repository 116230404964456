import React from 'react';
import classes from './BarGraph.module.css';

import createChartColors from '../DisplayChart/createChartColors'

const BarGraph = ({ chartStatistics, isSelectOption }) => {
    let gradient = createChartColors(chartStatistics.length);

    return (
        <div className={ classes[ "graph-container" ] }>
            {chartStatistics.map((chartStatistic, index) => {
                let formatPercentage = chartStatistic.option_response_percentage;

                if (chartStatistic.option_response_percentage === "0.0")
                {
                    formatPercentage = "0";
                } else if (chartStatistic.option_response_percentage === "100.0")
                {
                    formatPercentage = "100";
                }

                return (
                    <div
                        key={ chartStatistic.option_id }
                        className={ [ classes[ "single-bar" ], isSelectOption ? (classes[ "single-bar__bar--small" ]) : (null) ].join(" ") }>
                        <div className={ classes[ "single-bar__bar" ] }>
                            <div
                                style={ { height: `${ chartStatistic.option_response_percentage }%`, backgroundColor: gradient[ index ] } }
                                className={ classes[ "single-bar__bar-inner" ] }>
                            </div>
                        </div>
                        <div className={ classes[ "single-bar__percentage" ] }>
                            { formatPercentage }&#37;
                        </div>
                        <div className={ classes[ "single-bar__count" ] }>
                            { chartStatistic.option_response_count }
                        </div>
                    </div>
                );
            }) }
        </div>
    );
}

export default BarGraph