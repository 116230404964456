import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import classes from '../../EmailPreview/InServiceRecap//InServiceRecap.module.css';
import emailPreviewClasses from '../../EmailPreview/EmailPreview.module.css';
import customizeEmailClasses from '../CustomizeEmail.module.css';

import Footer from '../../Shared/Footer/Footer';
import LogoPreview from '../../Shared/LogoPreview/LogoPreview';
import ReactEasyCrop from '../../../../../Components/ReactEasyCrop/ReactEasyCrop';
import CloseModalButton from '../../../../../Components/CloseModalButton/CloseModalButton';
import { Modal } from 'reactstrap';

import { postTemplateImagesToEngagementEmailAction } from '../../../../../Store/actions/trials/engagementEmailsAction';
import { useDispatch, useSelector } from 'react-redux';

import Toggle from 'react-toggle';

import handleToggleChange from './handleToggleChange';
import resetInputFileValue from '../../../../../Utils/resetInputFileValue';

const InServiceRecapEndoscopy = ({ inputs, handleInputChange, formValidationErrors }) => {
    const dispatch = useDispatch();
    const { id, engagementEmailID } = useParams();

    const [ isImageEditingOpen, setIsImageEditingOpen ] = useState(false);
    const toggleIsImageEditingOpen = () => setIsImageEditingOpen(previousIsImageEditingOpen => !previousIsImageEditingOpen);

    const [ uploadedImage, setUploadedImage ] = useState(null);

    const handleImageEditing = (event) => {
        let imageFile = event.target.files[ 0 ];
        setIsImageEditingOpen(true);
        let imageDimensions = {
            height: 136,
            width: 233
        };

        if (event.target.name === 'facility_logo')
        {
            imageDimensions = {
                height: 92,
                width: 460
            }
        }

        setUploadedImage({
            name: event.target.name,
            dimensions: imageDimensions,
            file: URL.createObjectURL(imageFile)
        });
    }

    const handleImageUpload = (imageObject) => {
        let formData = new FormData();
        formData.append('template_id', engagementEmailID);
        formData.append('trial_id', id);
        formData.append('user_id', localStorage.getItem('user_id'));

        if (imageObject.name === 'facility_image')
        {
            formData.append('facility_image', imageObject.file);
            formData.append('facility_logo', inputs.facility_logo);
        } else if (imageObject.name === 'facility_logo')
        {
            formData.append('facility_image', inputs.facility_image);
            formData.append('facility_logo', imageObject.file);
        }

        dispatch(postTemplateImagesToEngagementEmailAction(formData));
    }

    const postTemplateImagesEngagementEmailError = useSelector(state => state.engagementEmails.postTemplateImagesEngagementEmailError);

    const BASE_URL = process.env.REACT_APP_API_URL;
    let facilityImage = BASE_URL + inputs.facility_image;
    let facilityLogo = BASE_URL + inputs.facility_logo;

    if (inputs.facility_image === "")
    {
        facilityImage = '/images/email/facility-image-placeholder.png';
    }

    if (inputs.facility_logo === "")
    {
        facilityLogo = '/images/email/facility-logo-placeholder.png';
    }

    const handleInputNumberChange = (event) => {
        if (event.target.value.length > 3) return;
        handleInputChange(event);
    }


    const handleSurgeonsNameKeyUp = (event) => {
        if (event.keyCode === 13)
        {
            let surgeonsNameArray = inputs.pharmacy_preparation_s_name1.replaceAll('-', '').split("\n");
            surgeonsNameArray = surgeonsNameArray.map((text, index) => {
                if (index + 1 === surgeonsNameArray.length) return '-' + text;
                return '-' + text + '\n';
            });

            handleInputChange({
                target: {
                    name: event.target.name,
                    value: surgeonsNameArray.join("")
                }
            });
        }
    }

    inputs.inservice_attendees_line1 = inputs?.inservice_attendees_line1?.replaceAll('&bull;', '').replaceAll(String.fromCharCode(8226), '');
    inputs.sterile_processing_preparation_line1 = inputs?.sterile_processing_preparation_line1?.replaceAll('&bull;', '').replaceAll(String.fromCharCode(8226), '');
    inputs.pharmacy_preparation_line1 = inputs?.pharmacy_preparation_line1?.replaceAll('&bull;', '').replaceAll(String.fromCharCode(8226), '');

    return (
        <div className="customize-email__preview-wrapper">
            <LogoPreview />
            <div className={ classes[ "header" ] }>
                <div className={ classes[ "header__left" ] }>
                    <div className={ classes[ "header__image-container" ] }>
                        <label htmlFor="facility_image">
                            <img
                                className={ [ classes[ "header__image" ], "cursor" ].join(" ") }
                                src={ facilityImage }
                                alt="facility image" />
                        </label>

                        <input
                            onChange={ handleImageEditing }
                            onClick={ resetInputFileValue }
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg"
                            name="facility_image"
                            id="facility_image" />
                    </div>
                    <div className={ classes[ "header__image-container" ] }>
                        <label htmlFor="facility_logo">
                            <img
                                className={ [ classes[ "header__image" ], "cursor" ].join(" ") }
                                src={ facilityLogo }
                                alt="facility logo" />
                        </label>
                        <input
                            onChange={ handleImageEditing }
                            onClick={ resetInputFileValue }
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg"
                            name="facility_logo"
                            id="facility_logo" />
                    </div>
                </div>

                <div className={ classes[ "header__right" ] }>
                    <div className={ classes[ "header__heading" ] }>
                        <div className={ classes[ "header__text-light" ] }>
                            <label htmlFor="title" className="hidden">
                                title
                            </label>
                            <textarea
                                onChange={ handleInputChange }
                                value={ inputs.title || '' }
                                className="w-100 mb-2 d-block"
                                name="title"
                                id="title"></textarea>

                            { formValidationErrors?.title ? (
                                <span className={ customizeEmailClasses[ "input__error" ] }>{ formValidationErrors?.title }</span>
                            ) : (null) }
                        </div>
                        <div className={ emailPreviewClasses[ "header__text-bold" ] }>
                            <label htmlFor="sub_title" className="hidden">
                                sub title
                            </label>
                            <textarea
                                onChange={ handleInputChange }
                                value={ inputs.sub_title || '' }
                                className="w-100 mb-2 d-block"
                                name="sub_title"
                                id="sub_title"> </textarea>

                            { formValidationErrors?.sub_title ? (
                                <span className={ customizeEmailClasses[ "input__error" ] }>{ formValidationErrors?.sub_title }</span>
                            ) : (null) }
                        </div>
                    </div>

                    <div className={ emailPreviewClasses[ "ribbon" ] }></div>

                    <div className={ classes[ "header__description" ] }>
                        <label htmlFor="title_description" className="hidden">
                            title description
                        </label>
                        <textarea
                            onChange={ handleInputChange }
                            value={ inputs.title_description || '' }
                            className="w-100 mb-2"
                            name="title_description"
                            id="title_description"
                            rows="4"> </textarea>

                        { formValidationErrors?.title_description ? (
                            <span className={ customizeEmailClasses[ "input__error" ] }>{ formValidationErrors?.title_description }</span>
                        ) : (null) }
                    </div>
                </div>
            </div>

            <div className={ classes[ "paragraphs-container" ] }>
                <div className={ [ emailPreviewClasses[ "email-preview__paragraph" ], "light-text" ].join(" ") }>
                    <label htmlFor="recipient_name" className="hidden">
                        recipient name
                    </label>
                    <input
                        onChange={ handleInputChange }
                        value={ inputs.recipient_name || '' }
                        type="text"
                        name="recipient_name"
                        id="recipient_name"
                        className={ [ "light-text", "w-100", "mb-2" ].join(" ") } />

                    { formValidationErrors?.recipient_name ? (
                        <span className={ customizeEmailClasses[ "input__error" ] }>{ formValidationErrors?.recipient_name }</span>
                    ) : (null) }
                </div>
                <div className={ emailPreviewClasses[ "email-preview__paragraph" ] }>
                    <label htmlFor="content" className="hidden">
                        content
                    </label>
                    <textarea
                        onChange={ handleInputChange }
                        value={ inputs.content || '' }
                        name="content"
                        id="content"
                        className="customize-email__content-textarea"
                        rows="15"></textarea>

                    { formValidationErrors?.content ? (
                        <span className={ customizeEmailClasses[ "input__error" ] }>{ formValidationErrors?.content }</span>
                    ) : (null) }
                </div>
            </div>

            <div className={ [ classes[ "section" ], "pb-0" ].join(" ") }>
                <div className="flex-shrink-0">
                    <div className={ classes[ "section__image-wrapper" ] }>
                        <img
                            className={ classes[ "section__image" ] }
                            src="/images/email/recap-1.png"
                            alt="workstation" />
                    </div>
                </div>

                <div className={ classes[ "section__item" ] }>
                    <div className={ classes[ "section__number" ] }>
                        <label htmlFor="inservice_attendees_number" className="hidden">
                            inservice attendees number
                        </label>
                        <input
                            onChange={ handleInputNumberChange }
                            value={ inputs.inservice_attendees_number || '' }
                            type="number"
                            className={ [ customizeEmailClasses[ "inservice-attendees-number" ], "w-100", "mb-2" ].join(" ") }
                            name="inservice_attendees_number"
                            id="inservice_attendees_number" />

                        { formValidationErrors?.inservice_attendees_number ? (
                            <span className={ customizeEmailClasses[ "input__error" ] }>{ formValidationErrors?.inservice_attendees_number }</span>
                        ) : (null) }
                    </div>
                    <div className={ classes[ "section__text-light" ] }>
                        <label htmlFor="inservice_attendees_title" className="hidden">
                            inservice attendees title
                        </label>
                        <input
                            onChange={ handleInputChange }
                            value={ inputs.inservice_attendees_title || '' }
                            type="text"
                            className="w-100 mb-2"
                            name="inservice_attendees_title"
                            id="inservice_attendees_title" />

                        { formValidationErrors?.inservice_attendees_title ? (
                            <span className={ customizeEmailClasses[ "input__error" ] }>{ formValidationErrors?.inservice_attendees_title }</span>
                        ) : (null) }
                    </div>
                    <div className={ classes[ "section__text-bold" ] }>
                        <label htmlFor="inservice_attendees_subtitle" className="hidden">
                            inservice attendees sub title
                        </label>
                        <input
                            onChange={ handleInputChange }
                            value={ inputs.inservice_attendees_subtitle || '' }
                            type="text"
                            className="w-100 mb-2"
                            name="inservice_attendees_subtitle"
                            id="inservice_attendees_subtitle" />

                        { formValidationErrors?.inservice_attendees_subtitle ? (
                            <span className={ customizeEmailClasses[ "input__error" ] }>{ formValidationErrors?.inservice_attendees_subtitle }</span>
                        ) : (null) }
                    </div>

                    <div className={ emailPreviewClasses[ "ribbon" ] }></div>

                    <div className={ classes[ "tutorial" ] }>
                        <div className={ [ classes[ "tutorial__text" ], classes[ "tutorial__text--margin" ] ].join(" ") }>
                            Tutorials:
                        </div>

                        <div className={ [ classes[ "tutorial__list" ], classes[ "tutorial__text--margin" ] ].join(" ") }>
                            <label htmlFor="inservice_attendees_line1" className="hidden">
                                inservice attendees line one
                            </label>
                            <textarea
                                onChange={ handleInputChange }
                                value={ inputs.inservice_attendees_line1 || '' }
                                name="inservice_attendees_line1"
                                id="inservice_attendees_line1"
                                className="w-100 mb-2"
                                rows="5"></textarea>

                            { formValidationErrors?.inservice_attendees_line1 ? (
                                <span className={ customizeEmailClasses[ "input__error" ] }>{ formValidationErrors?.inservice_attendees_line1 }</span>
                            ) : (null) }
                        </div>
                    </div>
                </div>
                { inputs.inservice_attendees_is_on === "0" ? (
                    <div className={ customizeEmailClasses[ "overlay" ] }></div>
                ) : (null) }
            </div>

            <div className={ customizeEmailClasses[ "toggle-container" ] }>
                <label htmlFor="inservice_attendees_is_on" className="hidden">
                    inservice attendees is on
                </label>
                <Toggle
                    onChange={ event => handleToggleChange({ event, handleInputChange }) }
                    checked={ inputs.inservice_attendees_is_on === "1" }
                    className="toggle-yellow"
                    icons={ true }
                    name="inservice_attendees_is_on"
                    id="inservice_attendees_is_on" />
            </div>

            <div className={ [ classes[ "section" ], "pb-0" ].join(" ") }>

                <div className={ classes[ "section__item" ] }>
                    <div className={ classes[ "section__text-container" ] }>
                        <div className={ classes[ "section__text-light" ] }>
                            <label htmlFor="sterile_processing_preparation_title" className="hidden">
                                sterile processing preparation title
                            </label>
                            <input
                                onChange={ handleInputChange }
                                value={ inputs.sterile_processing_preparation_title || '' }
                                type="text"
                                className="w-100 mb-2"
                                name="sterile_processing_preparation_title"
                                id="sterile_processing_preparation_title" />

                            { formValidationErrors?.sterile_processing_preparation_title ? (
                                <span className={ customizeEmailClasses[ "input__error" ] }>{ formValidationErrors?.sterile_processing_preparation_title }</span>
                            ) : (null) }
                        </div>
                        <div className={ classes[ "section__text-bold" ] }>
                            <label htmlFor="sterile_processing_preparation_subtitle" className="hidden">
                                sterile processing preparation subtitle
                            </label>
                            <input
                                onChange={ handleInputChange }
                                value={ inputs.sterile_processing_preparation_subtitle || '' }
                                type="text"
                                className="w-100 mb-2"
                                name="sterile_processing_preparation_subtitle"
                                id="sterile_processing_preparation_subtitle" />

                            { formValidationErrors?.sterile_processing_preparation_subtitle ? (
                                <span className={ customizeEmailClasses[ "input__error" ] }>{ formValidationErrors?.sterile_processing_preparation_subtitle }</span>
                            ) : (null) }
                        </div>
                        <div className={ classes[ "section__point-of-contact" ] }>
                            Point of contact:
                        </div>
                        <div className={ classes[ "section__point-of-contact-name" ] }>
                            <label htmlFor="sterile_processing_preparation_contact_name" className="hidden">
                                sterile processing preparation contact name
                            </label>
                            <input
                                onChange={ handleInputChange }
                                value={ inputs.sterile_processing_preparation_contact_name || '' }
                                type="text"
                                className="w-100 mb-2"
                                name="sterile_processing_preparation_contact_name"
                                id="sterile_processing_preparation_contact_name" />

                            { formValidationErrors?.sterile_processing_preparation_contact_name ? (
                                <span className={ customizeEmailClasses[ "input__error" ] }>{ formValidationErrors?.sterile_processing_preparation_contact_name }</span>
                            ) : (null) }
                        </div>
                    </div>
                    <div className={ [ emailPreviewClasses[ "ribbon" ], emailPreviewClasses[ "ribbon--margin" ] ].join(" ") }></div>

                    <div className={ classes[ "list" ] }>
                        <label htmlFor="sterile_processing_preparation_line1" className="hidden">
                            sterile processing preparation line one
                        </label>
                        <textarea
                            onChange={ handleInputChange }
                            value={ inputs.sterile_processing_preparation_line1 || '' }
                            name="sterile_processing_preparation_line1"
                            id="sterile_processing_preparation_line1"
                            className="w-100 mb-2"
                            rows="5"></textarea>

                        { formValidationErrors?.sterile_processing_preparation_line1 ? (
                            <span className={ customizeEmailClasses[ "input__error" ] }>{ formValidationErrors?.sterile_processing_preparation_line1 }</span>
                        ) : (null) }
                    </div>
                </div>


                <div className="flex-shrink-0">
                    <div className={ classes[ "section__image-wrapper" ] }>
                        <img
                            className={ classes[ "section__image" ] }
                            src="/images/email/recap-2.png"
                            alt="workstation" />
                    </div>
                </div>

                { inputs.sterile_processing_preparation_is_on === "0" ? (
                    <div className={ customizeEmailClasses[ "overlay" ] }></div>
                ) : (null) }
            </div>

            <div className={ customizeEmailClasses[ "toggle-container" ] }>
                <label htmlFor="sterile_processing_preparation_is_on" className="hidden">
                    sterile attendees is on
                </label>
                <Toggle
                    onChange={ event => handleToggleChange({ event, handleInputChange }) }
                    checked={ inputs.sterile_processing_preparation_is_on === "1" }
                    className="toggle-yellow"
                    icons={ true }
                    name="sterile_processing_preparation_is_on"
                    id="sterile_processing_preparation_is_on" />
            </div>

            <div className={ [ classes[ "section" ], "pb-0" ].join(" ") }>
                <div className="flex-shrink-0">
                    <div className={ [ classes[ "section__image-wrapper" ], "align-items-start" ].join(" ") }>
                        <img
                            className={ classes[ "section__image" ] }
                            src="/images/email/recap-3.png"
                            alt="workstation" />
                    </div>
                </div>

                <div className={ classes[ "section__item" ] }>
                    <div className={ [ classes[ "section__text-container" ], "m-0" ].join(" ") }>
                        <div className={ classes[ "section__text-light" ] }>
                            <label htmlFor="pharmacy_preparation_title" className="hidden">
                                pharmacy preparation title
                            </label>
                            <input
                                onChange={ handleInputChange }
                                value={ inputs.pharmacy_preparation_title || '' }
                                type="text"
                                className="w-100 mb-2"
                                name="pharmacy_preparation_title"
                                id="pharmacy_preparation_title" />

                            { formValidationErrors?.pharmacy_preparation_title ? (
                                <span className={ customizeEmailClasses[ "input__error" ] }>{ formValidationErrors?.pharmacy_preparation_title }</span>
                            ) : (null) }
                        </div>
                        <div className={ classes[ "section__text-bold" ] }>
                            <label htmlFor="pharmacy_preparation_subtitle" className="hidden">
                                pharmacy preparation subtitle
                            </label>
                            <input
                                onChange={ handleInputChange }
                                value={ inputs.pharmacy_preparation_subtitle || '' }
                                type="text"
                                className="w-100 mb-2"
                                name="pharmacy_preparation_subtitle"
                                id="pharmacy_preparation_subtitle" />

                            { formValidationErrors?.pharmacy_preparation_subtitle ? (
                                <span className={ customizeEmailClasses[ "input__error" ] }>{ formValidationErrors?.pharmacy_preparation_subtitle }</span>
                            ) : (null) }
                        </div>
                        <div className={ classes[ "section__point-of-contact" ] }>
                            Point of contact:
                        </div>
                        <div className={ classes[ "section__point-of-contact-name" ] }>
                            <label htmlFor="pharmacy_preparation_contact_name" className="hidden">
                                pharmacy preparation contact name
                            </label>
                            <input
                                onChange={ handleInputChange }
                                value={ inputs.pharmacy_preparation_contact_name || '' }
                                type="text"
                                className="w-100 mb-2"
                                name="pharmacy_preparation_contact_name"
                                id="pharmacy_preparation_contact_name" />

                            { formValidationErrors?.pharmacy_preparation_contact_name ? (
                                <span className={ customizeEmailClasses[ "input__error" ] }>{ formValidationErrors?.pharmacy_preparation_contact_name }</span>
                            ) : (null) }
                        </div>
                    </div>
                    <div className={ [ emailPreviewClasses[ "ribbon" ], emailPreviewClasses[ "ribbon--margin" ] ].join(" ") }></div>

                    <div className={ [ classes[ "list" ], classes[ "list--margin-right" ] ].join(" ") }>
                        <label htmlFor="pharmacy_preparation_line1" className="hidden">
                            pharmacy preparation line one
                        </label>
                        <textarea
                            onChange={ handleInputChange }
                            value={ inputs.pharmacy_preparation_line1 || '' }
                            name="pharmacy_preparation_line1"
                            id="pharmacy_preparation_line1"
                            className="w-100 mb-2"
                            rows="5"></textarea>

                        { formValidationErrors?.pharmacy_preparation_line1 ? (
                            <span className={ customizeEmailClasses[ "input__error" ] }>{ formValidationErrors?.pharmacy_preparation_line1 }</span>
                        ) : (null) }
                    </div>

                    <div className={ classes[ "list-names" ] }>
                        <label htmlFor="pharmacy_preparation_s_name1" className="hidden">
                            pharmacy preparation surgeon name one
                        </label>
                        <textarea
                            onChange={ handleInputChange }
                            onKeyUp={ handleSurgeonsNameKeyUp }
                            value={ inputs.pharmacy_preparation_s_name1 || '' }
                            name="pharmacy_preparation_s_name1"
                            id="pharmacy_preparation_s_name1"
                            className="w-100 mb-2"
                            rows="5"></textarea>

                        { formValidationErrors?.pharmacy_preparation_s_name1 ? (
                            <span className={ customizeEmailClasses[ "input__error" ] }>{ formValidationErrors?.pharmacy_preparation_s_name1 }</span>
                        ) : (null) }
                    </div>
                </div>
                { inputs.pharmacy_preparation_is_on === "0" ? (
                    <div className={ customizeEmailClasses[ "overlay" ] }></div>
                ) : (null) }
            </div>
            <div className="text-center mb-4">
                <label htmlFor="pharmacy_preparation_is_on" className="hidden">
                    pharmacy preparation is on
                </label>
                <Toggle
                    onChange={ event => handleToggleChange({ event, handleInputChange }) }
                    checked={ inputs.pharmacy_preparation_is_on === "1" }
                    className="toggle-yellow"
                    icons={ true }
                    name="pharmacy_preparation_is_on"
                    id="pharmacy_preparation_is_on" />
            </div>

            <Footer text={ inputs.footer } />

            <Modal
                isOpen={ isImageEditingOpen }
                toggle={ toggleIsImageEditingOpen }
                centered
                backdrop="static">
                <CloseModalButton onClick={ toggleIsImageEditingOpen } />

                <ReactEasyCrop
                    image={ uploadedImage }
                    toggleIsImageEditingOpen={ toggleIsImageEditingOpen }
                    onSuccessCallback={ handleImageUpload } />
            </Modal>
        </div>
    );
}

export default InServiceRecapEndoscopy;