import React, { useState } from 'react';
import classes from '../FormQuestions.module.css';
import Toggle from 'react-toggle';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
	editSurveyQuestionAction
} from '../../../../../Store/actions/surveysAction';

import {
	editEvaluationQuestionAction
} from '../../../../../Store/actions/evaluationsAction';

const MultipleChoiceQuestion = ({
	question,
	questionNumber,
	answeredQuestionsIDS = [],
	selectAnswer,
	isReadOnly,
	containsError,
	saveSurveyQuestion,
	saveEvaluationQuestion,
	match
}) => {
	const IS_READ_ONLY = isReadOnly;

        const { id, trialID, type } = match.params;
	const [ selectedOptions, setSelectedOptions ] = useState([]);

	const handleSelect = (optionId) => {
		let updatedSelection = selectedOptions;
		if (updatedSelection.includes(optionId)) {
			updatedSelection = updatedSelection.filter((option) => option !== optionId);
		} else if (selectedOptions.length > 0 && question.allow_multiple === '0') {
			updatedSelection = [ optionId ];
		} else {
			updatedSelection = [ ...updatedSelection, optionId ];
		}
		setSelectedOptions(updatedSelection);
		selectAnswer(question.id, updatedSelection);
	};

	const [ savingToggle, setSavingToggle ] = useState(false);
	const [toggleSaved, setToggleSaved] = useState(false);

	const handleToggle = (e) => {
		setSavingToggle(true);
		setToggleSaved(false); 
		let formatMultipleChoiceQuestionData = {
			...question,
			allowMultipleResponses: question.allow_multiple === '1',
			id: question.id,
			questionAnswers: question.question_options,
			minimumAndMaximumScaleLabels: [ question.min_rating_label, question.max_rating_label ],
			question: question.questions,
			allowNAResponse: question.question_options.find((option) => option.options === 'N/A'),
			selectedScale: question.question_options.length,
			type: question.questions_type,
			questionType: 'multiple',
			trialID,
			templateID: id,
			order: question.ordering,
			requiredResponse: question.is_required,
			allowMultipleResponses: e.target.checked == true ? '1' : '0'
		};

		type === 'survey'
			? saveSurveyQuestion(formatMultipleChoiceQuestionData, () => { setToggleSaved(true);  setTimeout(() => { setSavingToggle(false); }, 1000); })
			: saveEvaluationQuestion(formatMultipleChoiceQuestionData, ()=> { setToggleSaved(true);  setTimeout(() => { setSavingToggle(false); }, 1000); });
		
	}

	return (
		<div className={ [ classes[ 'form-survey__group' ], containsError ? classes[ 'form_input_error' ] : '' ].join(' ') }>
			<div style={{display:'flex', justifyContent:'space-between'}}>

			<h3 className={classes['form-survey__question']}>
				{questionNumber + 1}. {question.questions} {question.is_required === '1' ? <span>*</span> : null}
				</h3>
				{!match.params.personID && <div className={classes['form-survey__card-settings-option']} style={{textAlign:'right'}}>
							<label
								htmlFor={`allow-multiple-responses-${question.id}`}
								className={classes['form-survey__card-settings-option-label']}
							>
								Allow multiple responses
							</label>
					<div style={ {display:'flex', alignItems:'center', justifyContent: 'right', marginBottom:10} }className={classes['form-survey__card-toggle-container']}>
						{ savingToggle && <div style={{fontSize: 10, marginRight: 5}}>{ toggleSaved ? 'Saved' : 'Saving...' }</div> }
								<Toggle
									onChange={handleToggle}
									defaultChecked={question.allow_multiple === '1'}
									className="toggle-yellow"
									icons={false}
									id={`allow-multiple-responses-${question.id}`}
									name={`allow-multiple-responses-${question.id}`}
						/>
							</div>
						</div>}
			</div>

			<div>
				{question.question_options.map((answer) => {
					let isAnswered =
						answeredQuestionsIDS.includes(answer.option_id) || selectedOptions.includes(answer.option_id);
					return (
						<div key={answer.option_id} className={classes['form-survey__option-vertical']}>
							<label
								className={classes['form-survey__option-label-vertical']}
								htmlFor={`answer-${answer.option_id}`}
							>
								{answer.options}
							</label>
							<input
								className={classes['form-survey__input-radio']}
								id={`answer-${answer.option_id}`}
								name={`option-${question.id}`}
								type="checkbox"
								checked={isAnswered}
								readOnly={IS_READ_ONLY}
								onClick={() => handleSelect(answer.option_id)}
							/>
							<label
								htmlFor={`answer-${answer.option_id}`}
								className={classes['form-survey__option-radio']}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};


const mapDispatchToProps = (dispatch) => {
	return {
		saveSurveyQuestion: (parameters, saveNextQuestion) => {
			dispatch(editSurveyQuestionAction(parameters, saveNextQuestion));
		},
		saveEvaluationQuestion: (parameters, saveNextQuestion) => {
			dispatch(editEvaluationQuestionAction(parameters, saveNextQuestion));
		}
	};
};

export default connect(null, mapDispatchToProps)(withRouter(MultipleChoiceQuestion));
