import React from 'react';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';

const TrialsSurveyResponses = (props) => {
	const { cases, peopleID } = props;
	console.log(cases);
	return (
		<Col>
			<div className="tab-rt-extend">
				<div className="tab-title">Case History</div>
				<div className="line-blk-shrink2" />
				<table className="table">
					<tbody>
						{cases.map((item) => (
							<tr key={item.id}>
								<td className="file-details">
									<Link
										to={`/trial/${item.trialID}/organization/cases/surgeon/${peopleID}/edit/${item.id}`}
										key={item.id}
										value={item.name}
									>
										<ul className="">
											<li>{item.name}</li>
											<li className="subtitle-tiny-grey">
												{item.institution}
												<img src="/images/icon-arrow.jpg" className="angle2" alt="" />
											</li>
											<li className="subtitle-tiny-ltGrey">
												PROCEDURE DATE: {item.procedureDate}
												<span />
											</li>
										</ul>
									</Link>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</Col>
	);
};

export default TrialsSurveyResponses;
