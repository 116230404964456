import React, { useEffect } from 'react';

import classes from '../InputElements.module.css';
import useHandleSubmit from '../../../../Hooks/useHandleSubmit';
import { 
	createCustomerAction,
	getStatesAction
} from '../../../../Store/actions/trialsAction';
import { useDispatch, useSelector } from 'react-redux';

const AddCustomer = ({ callback, isInModal, prefilledData }) => {
	const toggleModalAndPreventDefault = (ev) => {
		ev.preventDefault();
		callback();
	};
	const dispatch = useDispatch();

	const handleCustomerSubmit = (event) => {
		if (event) {
			event.stopPropagation();
		}
		
		dispatch(createCustomerAction(inputs, callback));
	};
	const { inputs, handleInputChange, handleSubmit } = useHandleSubmit({
		callback: handleCustomerSubmit,
		initialState: {
			name: '',
			user_id: '',
			account_number: '',
			address: '',
			address2: '',
			city: '',
			state_id: '',
			zipcode: '',
			website: '',
			phone: ''
		}
	});

	useEffect(() => {
		if (prefilledData) {
			for (const property in inputs)
			{
				handleInputChange({
					target: {
						name: property,
						value: prefilledData[ property ]
					}
				});
			}

			if (prefilledData.id)
			{
				handleInputChange({
					target: {
						name: 'account_id',
						value: prefilledData.id
					}
				});
			}
		}
	}, [ prefilledData ]);

	useEffect(() => {
		dispatch(getStatesAction())
	}, []);

	const getStates = useSelector(state => state.trials.getStates);
	const getStatesError = useSelector(state => state.trials.getStatesError);

	return (
		<form onSubmit={handleSubmit}>
			{ isInModal ? (
				<h3 className={ classes[ 'form__title' ] }>Add customer</h3>
			) : (null) }
			
			<div className={ isInModal ? (classes[ 'form__groups-container' ]) : (classes[ 'form__groups-container-not-in-modal'])}>
				<div className={classes['form__group']}>
					<label className={classes['form__label']} htmlFor="name">
						account name &#42;
					</label>
					<input
						className={classes['form__input']}
						onChange={handleInputChange}
						value={ inputs.name }
						type="text"
						id="name"
						name="name"
						required
					/>
				</div>

				<div className={classes['form__group']}>
					<label className={classes['form__label']} htmlFor="account_number">
						account number
					</label>
					<input
						className={classes['form__input']}
						type="number"
						id="account_number"
						name="account_number"
						onChange={handleInputChange}
						value={ inputs.account_number }
					/>
				</div>
				{ isInModal ? (null) : (
					<React.Fragment>
						<div className={ classes[ 'form__group' ] }>
							<label className={ classes[ 'form__label' ] } htmlFor="phone">
								phone
							</label>
							<input
								className={ classes[ 'form__input' ] }
								type="text"
								id="phone"
								name="phone"
								onChange={ handleInputChange }
								value={ inputs.phone }
							/>
						</div>

						<div className={ classes[ 'form__group' ] }>
							<label className={ classes[ 'form__label' ] } htmlFor="website">
								website
							</label>
							<input
								className={ classes[ 'form__input' ] }
								type="text"
								id="website"
								name="website"
								onChange={ handleInputChange }
								value={ inputs.website }
							/>
						</div>
					</React.Fragment>
					) 
				}

				<div className={classes['form__group']}>
					<label className={classes['form__label']} htmlFor="address">
						street address
					</label>
					<input
						className={classes['form__input']}
						type="text"
						id="address"
						name="address"
						onChange={handleInputChange}
						value={ inputs.address }
					/>
					<input
						onChange={handleInputChange}
						value={ inputs.address2 }
						className={[ classes['form__input'], classes['form__input--margin-top'] ].join(' ')}
						type="text"
						id="address2"
						name="address2"
					/>
				</div>

				<div className={classes['form__group']}>
					<label className={classes['form__label']} htmlFor="city">
						city
					</label>
					<input
						onChange={handleInputChange}
						value={ inputs.city }
						className={classes['form__input']}
						type="text"
						id="city"
						name="city"
					/>
				</div>

				<div className={classes['form__group-split']}>
					<div className={classes['form__group']}>
						<label className={classes['form__label']} htmlFor="zipcode">
							zip code
						</label>
						<input
							onChange={handleInputChange}
							value={ inputs.zipcode }
							className={classes['form__input']}
							type="number"
							id="zipcode"
							name="zipcode"
						/>
					</div>
					<div className={classes['form__group']} style={{ paddingLeft: 20 }}>
						<label className={classes['form__label']} htmlFor="state_id">
							state
						</label>
						<select 
							name="state_id"
							id="state_id"
							onChange={ handleInputChange }
							value={ inputs.state_id }>
							{ getStates.map(state => {
								return (
									<option 
										key={ state.id }
										value={ state.id }>{ state.name }</option>
								);
							}) }
						</select>
					</div>
				</div>
			</div>

			{ isInModal ? (
				<div className={ classes[ 'form__buttons' ] }>
					<button
						onClick={ (ev) => toggleModalAndPreventDefault(ev) }
						className={ [ classes[ 'form__button' ], classes[ 'form__button--cancel' ] ].join(' ') }
					>
						<i className={ [ classes[ 'form__button-icon' ], 'las la-times' ].join(' ') } /> cancel
				</button>
					<button className={ [ classes[ 'form__button' ], classes[ 'form__button--submit' ] ].join(' ') }>
						submit <i className={ [ classes[ 'form__button-icon' ], 'las la-angle-right' ].join(' ') } />
					</button>
				</div>
			) : (
				<div className="edit-account-info-button-container">
					<button className="yellow-button">
						save account info
					</button>
				</div>
			) }
			
		</form>
	);
};

export default AddCustomer;
