import React from 'react';
import classes from './ConfirmDeleteTrial.module.css';
import { useParams, useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { deleteTrialAction } from '../../../../../Store/actions/trialsAction';

const ConfirmDeleteTrial = ({ toggleConfirmDeleteTrialModal }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();
    const handleDeleteTrial = () => {
        dispatch(deleteTrialAction(id));
        return history.replace('/trials');
    }

    const deleteTrialError = useSelector(state => state.trials.deleteTrialError);

    return (
        <div className={ classes[ "container" ] }>
            <div className={ classes[ "text-container" ] }>
                <p className={ classes[ "heading" ] }>
                    Are you sure?
                </p>

                <p className={ classes[ "text" ] }>
                    Deleting this trial will permanently remove all information associated with this trial.
                </p>

                <p className={ [ classes[ "text" ], classes[ "text--font" ] ].join(" ") }>
                    This can not be undone.
                </p>
            </div>

            <button
                onClick={ handleDeleteTrial }
                className={ [ classes[ "button" ], classes[ "button--submit" ] ].join(" ") }>
                yes, delete this trial
            </button>

            <button
                onClick={ toggleConfirmDeleteTrialModal }
                className={ [ classes[ "button" ], classes[ "button--cancel" ] ].join(" ") }>
                cancel
            </button>
        </div>
    );
}

export default ConfirmDeleteTrial;