import React, { useEffect, useState } from 'react';

import classes from './BusinessUnitSelection.module.css';

import { getBusinessUnitAction, postBusinessUnitAction } from '../../Store/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';

import Toggle from 'react-toggle';

const BusinessUnitSelection = ({ toggleBusinessUnitSelectionModal }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getBusinessUnitAction());
    }, []);

    const businessUnitsArrayFromAPI = useSelector(state => state.auth.BU);
    const getBusinessUnitsError = useSelector(state => state.auth.BUError);

    const postBusinessUnitsError = useSelector(state => state.auth.BUPostError);

    const [ buttonText, setButtonText ] = useState('Please select at least one to continue');

    let BUsAreSelected = businessUnitsArrayFromAPI.findIndex(BU => BU.is_selected === "1");

    useEffect(() => {
        if (BUsAreSelected === -1)
        {
            setButtonText('Please select at least one to continue');
        } else
        {
            setButtonText('Save & Continue');
        }
    }, [ BUsAreSelected ]);

    const handleToggleBusinessUnitSelectionModal = () => {
        if (BUsAreSelected !== -1)
        {
            toggleBusinessUnitSelectionModal();
        }
    }

    return (
        <div className={ classes[ "business-unit-selection-container" ] }>
            <h1 className={ classes[ "business-unit-selection__title" ] }>
                Business Unit Selection
            </h1>

            <p className={ classes[ "business-unit-selection__text" ] }>
                Please select your business unit(s) from the options below. You can edit your selection(s) at any time in your settings preferences.
            </p>

            <div className={ classes[ "business-units-selection__checkboxes-groups-container" ] }>
                { businessUnitsArrayFromAPI.map(BU => {
                    const { name, id, is_selected } = BU;
                    return (
                        <div
                            key={ id }
                            className={ classes[ "business-unit-selection__checkboxes-group" ] }>
                            <label
                                className={ [ classes[ "business-unit-selection__label" ], is_selected === "1" ? classes[ "business-unit-selection__label--selected" ] : null ].join(" ") }
                                htmlFor={ name }>
                                { name }
                            </label>
                            <Toggle
                                className="toggle-yellow"
                                defaultChecked={ is_selected === "1" }
                                icons={ false }
                                name={ name }
                                id={ name }
                                onChange={ () => dispatch(postBusinessUnitAction(id, is_selected === "0" ? true : false)) } />
                        </div>
                    )
                }) }
            </div>

            <p className={ classes[ "business-unit-selection__subtext" ] }>
                Any future changes to your Business Unit preferences will effect visibility of surgical specialties, products, survey templates, and talk tracks in your trials.
            </p>

            <button
                onClick={ handleToggleBusinessUnitSelectionModal }
                className={ [ classes[ "business-unit-selection__button" ], BUsAreSelected !== -1 ? classes[ "business-unit-selection__button--selected" ] : (null) ].join(" ") }>
                { buttonText }
            </button>
        </div>
    );
}

export default BusinessUnitSelection;