import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/CrushLogo-2019.png";
import { withRouter } from 'react-router-dom'

class NavBar extends Component {
    goBack = () => {
        this.props.history.goBack();
    };

    render () {
        return (
            <div className="tab-navbar">
                <div className="back" onClick={ this.goBack } >
                    <i className="las la-angle-left" />
                  BACK
                </div>
                <div className="navbar">
                    <Link to={ "/" }><img src={ logo } alt="logo" /></Link>
                </div>
            </div>
        );
    }
}

export default withRouter(NavBar);

