import React, { useEffect } from 'react';

import classes from '../../InputElements.module.css';

import { useDispatch, useSelector } from 'react-redux';
import { getSpecialitiesAction } from '../../../../../Store/actions/trialsAction';

const ThirdView = ({ handleInputChange }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getSpecialitiesAction())
    }, []);

    const specialitiesArray = useSelector(state => state.trials.specialitiesArray.speciality);
    const specialitiesError = useSelector(state => state.trials.specialitiesError);

    const handleSelectType = (ev) => {
        handleInputChange(ev);
        let activeElement = document.querySelector('label[data-active="1"]');
        if (activeElement)
        {
            let [ activeImage, activeSelectedImage ] = activeElement.querySelectorAll(`.${ classes[ "form__select-image" ] }`);
            activeSelectedImage.classList.add('hidden');
            activeImage.classList.remove('hidden');
            activeElement.dataset.active = "0";
        }

        let notActiveElement = ev.target.parentNode;
        let [ notActiveImage, notActiveSelectedImage ] = notActiveElement.querySelectorAll(`.${ classes[ "form__select-image" ] }`);
        notActiveSelectedImage.classList.remove('hidden');
        notActiveImage.classList.add('hidden');
        notActiveElement.dataset.active = "1";
    }

    return (
        <div className={ classes[ "form__select-images" ] }>
            {specialitiesArray?.map(speciality => {
                return (
                    <label
                        key={ speciality.id }
                        data-active="0"
                        htmlFor={ `${ speciality.id }-${ speciality.name }` }
                        className={ classes[ "form__select-image-container" ] }>
                        <span className={ classes[ "form__select-image-border" ] }>
                            <img
                                className={ classes[ "form__select-image" ] }
                                src={ speciality.icon }
                                alt={ speciality.name } />
                            <img
                                className={ [ classes[ "form__select-image" ], "hidden" ].join(" ") }
                                src={ speciality.icon_selected }
                                alt={ speciality.name } />
                        </span>

                        <input
                            onClick={ handleSelectType }
                            value={ `${ speciality.id }-${ speciality.name }` }
                            className="hidden"
                            id={ `${ speciality.id }-${ speciality.name }` }
                            name="personTitleAndID"
                            type="radio" />
                        <span className={ classes[ "form__select-image-text" ] }>
                            { speciality.name }
                        </span>
                    </label>
                );
            }) }

        </div>
    );
}

export default ThirdView;