import React, { useState, useEffect } from 'react';

import { Modal } from 'reactstrap';
import ReactEasyCrop from '../../../../../Components/ReactEasyCrop/ReactEasyCrop';
import CloseModalButton from '../../../../../Components/CloseModalButton/CloseModalButton';

import classes from '../../InputElements.module.css';

import resetInputFileValue from '../../../../../Utils/resetInputFileValue';

const FirstView = ({ handleInputChange, inputs }) => {
    const [ uploadedProfilePicture, setUploadedProfilePicture ] = useState(null);

    const [ isImageEditingOpen, setIsImageEditingOpen ] = useState(false);
    const toggleIsImageEditingOpen = () => setIsImageEditingOpen(previousIsImageEditingOpen => !previousIsImageEditingOpen);

    const [ uploadedImage, setUploadedImage ] = useState(null);

    const handleImageEditing = (event) => {
        let imageFile = event.target.files[ 0 ];
        setIsImageEditingOpen(true);
        let imageDimensions = {
            height: 250,
            width: 250
        };

        setUploadedImage({
            name: event.target.name,
            dimensions: imageDimensions,
            file: URL.createObjectURL(imageFile)
        });
    }

    const handleUploadImage = (image) => {
        handleInputChange({
            target: {
                name: 'profilePicture',
                value: image.file
            }
        });

        setUploadedProfilePicture(URL.createObjectURL(image.file));
    }

    const handleDeleteUploadedImage = (event) => {
        event.preventDefault();

        setUploadedProfilePicture(null);
        handleInputChange({
            target: {
                name: 'profilePicture',
                value: ''
            }
        });
    }

    useEffect(() => {
        if (inputs.profilePicture) {
            setUploadedProfilePicture(URL.createObjectURL(inputs.profilePicture));
        }
    }, []);

    return (
        <div className={ classes[ "form__groups-container" ] }>
            <div className={ classes[ "profile-picture__container" ] }>
                { uploadedProfilePicture ? (
                    <React.Fragment>
                        <img
                            className="profile__image"
                            src={ uploadedProfilePicture } alt="uploaded profile picture" />

                        <button
                            onClick={ handleDeleteUploadedImage }
                            className="profile__image-delete-button">
                            <img className="profile__image-delete-button-image" src="/images/user-close.png" alt="delete icon" />
                        </button>
                    </React.Fragment>
                ) : (
                        <i className={ [ classes[ "profile-picture__icon" ], "las la-camera" ].join(" ") }></i>
                    ) }

            </div>
            <div className={ classes[ "profile-picture__input-container" ] }>
                <label
                    className={ classes[ "profile-picture__label" ] }
                    htmlFor="profilePicture">
                    upload profile picture
                </label>
                <input
                    onChange={ handleImageEditing }
                    onClick={ resetInputFileValue }
                    className="hidden"
                    id="profilePicture"
                    name="profilePicture"
                    type="file"
                    accept="image/png, image/jpeg" />
            </div>

            <div className={ classes[ "form__group" ] }>
                <label
                    className={ classes[ "form__label" ] }
                    htmlFor="firstName">
                    first name
                </label>
                <input
                    onChange={ handleInputChange }
                    value={ inputs.firstName }
                    className={ classes[ "form__input" ] }
                    type="text"
                    id="firstName"
                    name="firstName"
                    required />
            </div>

            <div className={ classes[ "form__group" ] }>
                <label
                    className={ classes[ "form__label" ] }
                    htmlFor="lastName">
                    last name
                </label>
                <input
                    onChange={ handleInputChange }
                    value={ inputs.lastName }
                    className={ classes[ "form__input" ] }
                    type="text"
                    id="lastName"
                    name="lastName"
                    required />
            </div>

            <div className={ classes[ "form__group" ] }>
                <label
                    className={ classes[ "form__label" ] }
                    htmlFor="primaryEmail">
                    primary email

                    <span className={ classes[ "form__label-optional" ] }>
                        optional
                    </span>
                </label>
                <input
                    onChange={ handleInputChange }
                    value={ inputs.primaryEmail }
                    className={ classes[ "form__input" ] }
                    type="email"
                    id="primaryEmail"
                    name="primaryEmail" />
            </div>

            <div className={ classes[ "form__group" ] }>
                <label
                    className={ classes[ "form__label" ] }
                    htmlFor="secondaryEmail">
                    secondary email
                    <span className={ classes[ "form__label-optional" ] }>
                        optional
                    </span>
                </label>
                <input
                    onChange={ handleInputChange }
                    value={ inputs.secondaryEmail }
                    className={ classes[ "form__input" ] }
                    type="email"
                    id="secondaryEmail"
                    name="secondaryEmail" />
            </div>

            <div className={ classes[ "form__group" ] }>
                <label
                    className={ classes[ "form__label" ] }
                    htmlFor="mobilePhone">
                    mobile phone
                    <span className={ classes[ "form__label-optional" ] }>
                        optional
                    </span>
                </label>
                <input
                    onChange={ handleInputChange }
                    value={ inputs.mobilePhone }
                    className={ classes[ "form__input" ] }
                    type="text"
                    id="mobilePhone"
                    name="mobilePhone" />
            </div>

            <div className={ classes[ "form__group" ] }>
                <label
                    className={ classes[ "form__label" ] }
                    htmlFor="workPhone">
                    work phone
                    <span className={ classes[ "form__label-optional" ] }>
                        optional
                    </span>
                </label>
                <input
                    onChange={ handleInputChange }
                    value={ inputs.workPhone }
                    className={ classes[ "form__input" ] }
                    type="text"
                    id="workPhone"
                    name="workPhone" />
            </div>

            <Modal
                isOpen={ isImageEditingOpen }
                toggle={ toggleIsImageEditingOpen }
                centered
                backdrop="static">
                <CloseModalButton onClick={ toggleIsImageEditingOpen } />

                <ReactEasyCrop
                    image={ uploadedImage }
                    toggleIsImageEditingOpen={ toggleIsImageEditingOpen }
                    onSuccessCallback={ handleUploadImage } />
            </Modal>
        </div>
    );
}

export default FirstView;