import React from 'react';

import emailPreviewClasses from '../EmailPreview.module.css';
import classes from '../../EmailResults/EmailResults.module.css';

const PreTrialCommunicationThankYouContent = ({ emailPreview, emailPreviewImagePath }) => {
    const emailPreviewContent = emailPreview?.content.split(/[\r\n]+/g);
    return (
        <React.Fragment>
            <div className={ emailPreviewClasses[ "email-preview__paragraphs-container" ] }>
                { emailPreviewContent?.map((item, index) => {
                    return (
                        <p
                            key={ index }
                            className={ emailPreviewClasses[ "email-preview__paragraph" ] }>
                            { item }
                        </p>
                    );
                }) }
            </div>

            <div className={ classes[ "email-results-preview__subsection" ] }>
                <div className={ classes[ "email-results-preview__subsection-header" ] }>
                    <h3 className={ classes[ "email-results-preview__bold-headline" ] }>
                        { emailPreview?.header_two }
                    </h3>

                    <div className={ [ classes[ "email-results-border" ], classes[ "-thin" ] ].join(" ") }></div>
                </div>


                <a
                    className={ classes[ "email-results-preview__image-container" ] }
                    href={ emailPreview?.video }
                    title="video player"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img
                        className={ classes[ "email-results-preview__image" ] }
                        src={ emailPreviewImagePath } alt="workstation" />
                </a>
            </div>
        </React.Fragment>
    );
}

export default PreTrialCommunicationThankYouContent;