import React from 'react';

import classes from './NoResults.module.css';

const NoResults = ({ message = 'no results' }) => {
    return (
        <div className={ classes[ "no-results-text" ] }>
            { message }
        </div>
    );
}

export default NoResults;