import React, { useEffect } from 'react';
import classes from './AssignItem.module.css';
import { postChecklistTeammateAction } from '../../../../Store/actions/checklistsAction';
import { useDispatch, useSelector } from 'react-redux';
import useHandleSubmit from '../../../../Hooks/useHandleSubmit';
import { getTeammatesAction } from '../../../../Store/actions/trialsAction';
import ProfileImage from '../../../../Utils/ProfileImage';

const AssignItem = ({ toggleModal, trialID, item }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getTeammatesAction(trialID));
	}, []);

	let teammates = useSelector((state) => state.trials.teammates);

	const handleCancelClick = (ev) => {
		ev.preventDefault();
		toggleModal();
	};

	const handleChecklistTeammateSubmit = (teammateId) => {
		//dispatch(postStakeholderAction(formData));
		dispatch(
			postChecklistTeammateAction({
				trialID: trialID,
				itemID: item.id,
				teammateID: teammateId
			})
		);
		toggleModal();
	};

	return (
		<form className={classes['form']}>
			<label className={classes['form__label']} htmlFor="addChecklist">
				Choose a teammate
			</label>
			<div className={classes['teammates__list']}>
				{teammates.length > 0 ? (
					teammates.map((tm) => {
						return (
							<div
								className={classes['teammates__list-item']}
								onClick={() => handleChecklistTeammateSubmit(tm.id)}
							>
								<div>
									{ProfileImage({
										profile: tm,
										cb: null,
										profileImageClasses: 'profile__image--xtra-small'
									})}
								</div>
								<div>
									<p className="futurastdbold">
										{tm.first_name} {tm.last_name}
									</p>
									<p>{tm.email}</p>
								</div>
							</div>
						);
					})
				) : (
					<div>No teammates added to trial</div>
				)}
			</div>
			<div className={classes['form__buttons-container']}>
				<button
					onClick={handleCancelClick}
					className={[ classes['form__button'], classes['form__button--cancel'] ].join(' ')}
				>
					cancel
				</button>
			</div>
		</form>
	);
};

export default AssignItem;
