import React, { Component, createRef } from 'react';
import { Popover } from 'reactstrap';
import classes from '../CreateOrEdit.module.css';
import Toggle from 'react-toggle';
import '../../../../BusinessUnitSelection/BusinessUnitSelection.module.css';

class Rating extends Component {
	state = {
		question: '',
		requiredResponse: false,
		allowMultipleResponses: false,
		selectedScale: 10,
		questionAnswers: [],
		minimumAndMaximumScaleLabels: [ '', '' ],
		id: this.props.id,
		order: this.props.order,
		type: 'rating',
		popoverOpen: false
	};

	constructor(props) {
		super(props);

		if (props.item) {
			const existingAnswers = props.item.questionAnswers;
			const answerCount = existingAnswers.length;
			let possibleAdditionalAnswers = new Array(
				9 - (props.item.questionAnswers.length > 9 ? 9 : props.item.questionAnswers.length)
			);
			for (let paac = 0; paac < possibleAdditionalAnswers.length; paac++) {
				const placeholder = {
					options: answerCount + paac + 1,
					is_display: '0',
					option_id: ''
				};
				existingAnswers.push(placeholder);
			}
			this.state = {
				question: this.props.item.question,
				questionAnswers: existingAnswers,
				requiredResponse: this.props.item.requiredResponse,
				allowMultipleResponses: this.props.item.allowMultipleResponses,
				selectedScale: this.props.item.selectedScale,
				minimumAndMaximumScaleLabels: this.props.item.minimumAndMaximumScaleLabels,
				id: this.props.id,
				type: 'rating',
				popoverOpen: false
			};
		} else {
			const existingAnswers = new Array();
			for (let paac = 1; paac < 10; paac++) {
				const placeholder = {
					options: paac + 1,
					is_display: '0',
					option_id: ''
				};
				existingAnswers.push(placeholder);
			}
			this.state = { ...this.state, questionAnswers: existingAnswers };
		}
	}

	allowMultipleResponsesRef = createRef();
	requiredResponseRef = createRef();

	handleTextAreaChange = (ev) => {
		this.setState(
			{
				question: ev.currentTarget.value
			},
			() => {
				this.props.handleQuestionChange(this.state);
			}
		);
	};

	handleRequiredResponse = () => {
		this.setState(
			{
				requiredResponse: !this.state.requiredResponse
			},
			() => {
				this.props.handleQuestionChange(this.state);
			}
		);

		this.requiredResponseRef.current.classList.toggle(classes['form-survey__card-settings-option-label--enabled']);
	};

	handleAllowMultipleResponses = () => {
		this.setState(
			{
				allowMultipleResponses: !this.state.allowMultipleResponses
			},
			() => {
				this.props.handleQuestionChange(this.state);
			}
		);

		this.allowMultipleResponsesRef.current.classList.toggle(
			classes['form-survey__card-settings-option-label--enabled']
		);
	};

	handleSelectChange = (ev) => {
		const selectedScale = ev.currentTarget.value;
		const updatedAnswerDisplay = this.state.questionAnswers.map((qa, index) => {
			if (index < selectedScale) {
				qa.is_display = '1';
			} else {
				qa.is_display = '0';
			}
			return qa;
		});
		console.log(updatedAnswerDisplay);
		this.setState(
			{
				questionAnswers: updatedAnswerDisplay,
				selectedScale: selectedScale
			},
			() => {
				this.props.handleQuestionChange(this.state);
			}
		);
	};

	handleMinimumAndMaximumInputChange = (ev) => {
		let minimumAndMaximumScaleLabels = JSON.parse(JSON.stringify(this.state.minimumAndMaximumScaleLabels));
		let splitInputID = ev.currentTarget.id.split('-')[2];

		minimumAndMaximumScaleLabels[splitInputID] = ev.currentTarget.value;
		this.setState(
			{
				minimumAndMaximumScaleLabels: minimumAndMaximumScaleLabels
			},
			() => {
				this.props.handleQuestionChange(this.state);
			}
		);
	};
	toggleMenu = () => {
		this.setState({
			popoverOpen: !this.state.popoverOpen
		});
	};

	render() {
		const IDOfQuestion = this.state.id;
		const { popoverOpen } = this.state;

		return (
			<div className={classes['form-survey__card']}>
				<div className={classes['form-survey__card-title-container']}>
					<span className={classes['form-survey__card-question-number']}>{this.props.order}</span>
					<h3 className={classes['form-survey__card-title']}>Rating</h3>
					<span>
						<i className={[ classes['form-survey__arrow-down-icon'], 'las la-angle-down' ].join(' ')} />
					</span>
					<span className={classes['form-survey__card-menu-icon']}>
						<i className="las la-ellipsis-h" id="rating-menu" />

						<Popover placement="left" isOpen={popoverOpen} target={`rating-menu`} toggle={this.toggleMenu}>
							<p
								className={classes['popover_option']}
								style={styles.popover_action_red}
								onClick={() => {
									this.setState({ popoverOpen: false });
									this.props.onDelete();
								}}
							>
								REMOVE
							</p>
						</Popover>
					</span>
				</div>
				<div className={classes['form-survey__card-group']}>
					<label className={classes['form-survey__card-label']} htmlFor={`question-${IDOfQuestion}`}>
						question
					</label>
					<textarea
						className={classes['form-survey__card-textarea']}
						type="text"
						id={`question-${IDOfQuestion}`}
						placeholder="question"
						value={this.state.question}
						onChange={this.handleTextAreaChange}
					/>
				</div>

				<div className={classes['form-survey__card-settings-container']}>
					<h3
						className={[
							classes['form-survey__card-label'],
							classes['form-survey__card-label-settings']
						].join(' ')}
					>
						settings
					</h3>
					<div className={classes['form-survey__card-settings-options-container']}>
						<div className={classes['form-survey__card-settings-option']}>
							<div className={classes['form-survey__card-toggle-container']}>
								<Toggle
									onChange={this.handleRequiredResponse}
									defaultChecked={this.state.requiredResponse}
									className="toggle-yellow"
									icons={false}
									name={`required-response-${IDOfQuestion}`}
									id={`required-response-${IDOfQuestion}`}
								/>
							</div>
							<label
								htmlFor={`required-response-${IDOfQuestion}`}
								ref={this.requiredResponseRef}
								className={classes['form-survey__card-settings-option-label']}
							>
								<div className={classes['form-survey__card-settings-icon-container']}>
									<i className="las la-lock" />{' '}
								</div>
								Make this a required response
							</label>
						</div>
						<div className={classes['form-survey__card-settings-option']}>
							<div className={classes['form-survey__card-toggle-container']}>
								<Toggle
									onChange={this.handleAllowMultipleResponses}
									defaultChecked={this.state.allowMultipleResponses}
									className="toggle-yellow"
									icons={false}
									name={`allow-multiple-responses-${IDOfQuestion}`}
									id={`allow-multiple-responses-${IDOfQuestion}`}
								/>
							</div>
							<label
								htmlFor={`allow-multiple-responses-${IDOfQuestion}`}
								ref={this.allowMultipleResponsesRef}
								className={classes['form-survey__card-settings-option-label']}
							>
								<div className={classes['form-survey__card-settings-icon-container']}>
									<i className="las la-check" />{' '}
								</div>
								Allow multiple responses
							</label>
						</div>
					</div>
				</div>

				<div className={classes['form-survey__card-answers-container']}>
					<h3 className={classes['form-survey__card-label']}>Scale</h3>

					<div className={classes['form-survey__card-single-answer-container']}>
						<div className={classes['form-survey__card-single-answer']}>
							<select
								defaultValue={this.state.selectedScale}
								onChange={this.handleSelectChange}
								className={classes['form-survey__card-single-answer-select']}
								name={`scale-${IDOfQuestion}`}
								id={`scale-${IDOfQuestion}`}
							>
								<option value="2">2</option>
								<option value="3">3</option>
								<option value="4">4</option>
								<option value="5">5 (recommended)</option>
								<option value="6">6</option>
								<option value="7">7</option>
								<option value="8">8</option>
								<option value="9">9</option>
								<option value="10">10</option>
							</select>
						</div>
						<div className={classes['form-survey__card-single-answer']}>
							<div className={classes['form-survey__card-single-answer-input-container']}>
								<input
									defaultValue={this.state.minimumAndMaximumScaleLabels[0]}
									id={`scale-min-0-${IDOfQuestion}`}
									onChange={this.handleMinimumAndMaximumInputChange}
									placeholder="Minimum rating label"
									className={classes['form-survey__card-single-answer-input']}
								/>
							</div>
						</div>
						<div className={classes['form-survey__card-single-answer']}>
							<div className={classes['form-survey__card-single-answer-input-container']}>
								<input
									defaultValue={this.state.minimumAndMaximumScaleLabels[1]}
									id={`scale-max-1-${IDOfQuestion}`}
									onChange={this.handleMinimumAndMaximumInputChange}
									placeholder="Maximum rating label"
									className={classes['form-survey__card-single-answer-input']}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const styles = {
	popover_action_red: {
		color: 'red',
		margin: 5
	}
};

export default Rating;
