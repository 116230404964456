/** @format */

import React from "react";

import classes from "../../EmailPreview/InServiceInvite/InServiceInvite.module.css";
import emailPreviewClasses from "../../EmailPreview/EmailPreview.module.css";
import customizeEmailClasses from "../CustomizeEmail.module.css";

import Footer from "../../Shared/Footer/Footer";
import LogoPreview from "../../Shared/LogoPreview/LogoPreview";

const InServiceInviteMed = ({
  inputs,
  handleInputChange,
  formValidationErrors,
}) => {
  return (
    <div className="customize-email__preview-wrapper">
      <LogoPreview />

      <div className={classes["header"]}>
        <div className={classes["header__left"]}>
          <div className={classes["header__heading"]}>
            <div className={emailPreviewClasses["header__text-light"]}>
              <label htmlFor="title" className="hidden">
                title
              </label>
              <textarea
                onChange={handleInputChange}
                value={inputs.title || ""}
                name="title"
                id="title"
                className="w-100 mb-2 d-block"
              ></textarea>

              {formValidationErrors?.title ? (
                <span className={customizeEmailClasses["input__error"]}>
                  {formValidationErrors?.title}
                </span>
              ) : null}
            </div>
            <div className={emailPreviewClasses["header__text-bold"]}>
              <label htmlFor="sub_title" className="hidden">
                sub title
              </label>
              <textarea
                onChange={handleInputChange}
                value={inputs.sub_title || ""}
                className={customizeEmailClasses["customize-email__textarea"]}
                name="sub_title"
                id="sub_title"
              ></textarea>

              {formValidationErrors?.sub_title ? (
                <span className={customizeEmailClasses["input__error"]}>
                  {formValidationErrors?.sub_title}
                </span>
              ) : null}
            </div>
          </div>

          <div className={classes["header__image-container"]}>
            <img
              className={classes["header__image"]}
              src="/images/email/inservice-img02.png"
              alt="workstation"
            />
          </div>

          <div className={classes["header__description"]}>
            <label htmlFor="title_description" className="hidden">
              title description
            </label>
            <textarea
              onChange={handleInputChange}
              value={inputs.title_description || ""}
              name="title_description"
              id="title_description"
              maxLength="150"
              rows="5"
              className="w-100"
            ></textarea>

            {formValidationErrors?.title_description ? (
              <span className={customizeEmailClasses["input__error"]}>
                {formValidationErrors?.title_description}
              </span>
            ) : null}
          </div>
        </div>
      </div>

      <div className={classes["information-container"]}>
        <div className={classes["information"]}>
          <div className={classes["information__image-container"]}>
            <img
              className={classes["information__image"]}
              src="/images/email/icon-clock.png"
              alt="clock icon"
            />
          </div>
          <div className={classes["information__right"]}>
            <div className={classes["information__title"]}>
              {inputs.when_title}
            </div>

            <div className={classes["information__text-container"]}>
              <div className={classes["information__text-light"]}>
                <label htmlFor="when_content" className="hidden">
                  when one
                </label>
                <input
                  onChange={handleInputChange}
                  value={inputs.when_content || ""}
                  name="when_content"
                  id="when_content"
                  className="w-100 mb-2"
                  type="text"
                />

                {formValidationErrors?.when_content ? (
                  <span className={customizeEmailClasses["input__error"]}>
                    {formValidationErrors?.when_content}
                  </span>
                ) : null}
              </div>
              <div className={classes["information__text-bold"]}>
                <label htmlFor="when_time" className="hidden">
                  when two
                </label>
                <input
                  onChange={handleInputChange}
                  value={inputs.when_time || ""}
                  name="when_time"
                  id="when_time"
                  className="w-100"
                  type="text"
                />

                {formValidationErrors?.when_time ? (
                  <span className={customizeEmailClasses["input__error"]}>
                    {formValidationErrors?.when_time}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className={classes["information"]}>
          <div className={classes["information__image-container"]}>
            <img
              className={classes["information__image"]}
              src="/images/email/icon-location.png"
              alt="clock icon"
            />
          </div>
          <div className={classes["information__right"]}>
            <div className={classes["information__title"]}>
              {inputs.where_title}
            </div>

            <div className={classes["information__text-container"]}>
              <div className={classes["information__text-light"]}>
                <label htmlFor="where_content" className="hidden">
                  where one
                </label>
                <input
                  onChange={handleInputChange}
                  value={inputs.where_content || ""}
                  name="where_content"
                  id="where_content"
                  className="w-100 mb-2"
                  type="text"
                />

                {formValidationErrors?.where_content ? (
                  <span className={customizeEmailClasses["input__error"]}>
                    {formValidationErrors?.where_content}
                  </span>
                ) : null}
              </div>
              <div className={classes["information__text-bold"]}>
                <label htmlFor="where_content_two" className="hidden">
                  where two
                </label>
                <input
                  onChange={handleInputChange}
                  value={inputs.where_content_two || ""}
                  name="where_content_two"
                  id="where_content_two"
                  className="w-100"
                  type="text"
                  placeholder="where content two"
                />
                {formValidationErrors?.where_content_two ? (
                  <span className={customizeEmailClasses["input__error"]}>
                    {formValidationErrors?.where_content_two}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className={classes["information"]}>
          <div className={classes["information__image-container"]}>
            <img
              className={classes["information__image"]}
              src="/images/email/icon-box.png"
              alt="clock icon"
            />
          </div>
          <div className={classes["information__right"]}>
            <div className={classes["information__title"]}>
              {inputs.what_title}
            </div>

            <div className={classes["information__text-container"]}>
              <div className={classes["information__text-light"]}>
                <label htmlFor="what_content" className="hidden">
                  what one
                </label>
                <input
                  onChange={handleInputChange}
                  value={inputs.what_content || ""}
                  name="what_content"
                  id="what_content"
                  className="w-100 mb-2"
                  type="text"
                />

                {formValidationErrors?.what_content ? (
                  <span className={customizeEmailClasses["input__error"]}>
                    {formValidationErrors?.what_content}
                  </span>
                ) : null}
              </div>
              <div className={classes["information__text-bold"]}>
                <label htmlFor="what_content_two" className="hidden">
                  what two
                </label>
                <input
                  onChange={handleInputChange}
                  value={inputs.what_content_two || ""}
                  name="what_content_two"
                  id="what_content_two"
                  className="w-100"
                  type="text"
                />

                {formValidationErrors?.what_content_two ? (
                  <span className={customizeEmailClasses["input__error"]}>
                    {formValidationErrors?.what_content_two}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes["section"]}>
        <div className={classes["section__text-bold"]}>
          <label htmlFor="content_fname_lname" className="hidden">
            content first name last name
          </label>
          <input
            onChange={handleInputChange}
            value={inputs.content_fname_lname || ""}
            name="content_fname_lname"
            id="content_fname_lname"
            className="w-100 mb-2"
            type="text"
          />

          {formValidationErrors?.content_fname_lname ? (
            <span className={customizeEmailClasses["input__error"]}>
              {formValidationErrors?.content_fname_lname}
            </span>
          ) : null}
        </div>

        <div className={classes["section__text-light"]}>
          <label htmlFor="content" className="hidden">
            content
          </label>
          <textarea
            onChange={handleInputChange}
            value={inputs.content || ""}
            name="content"
            id="content"
            rows="3"
            className="w-100"
          ></textarea>

          {formValidationErrors?.content ? (
            <span className={customizeEmailClasses["input__error"]}>
              {formValidationErrors?.content}
            </span>
          ) : null}
        </div>
      </div>

      <Footer text={inputs.footer} />
    </div>
  );
};

export default InServiceInviteMed;
