import React, { Component, createRef } from "react";
import { Row, Col } from "reactstrap";
import Search from '../../../Utils/Search';

import PreviousEngagementEmailsCreate from './Views/PreviousEngagementEmailsCreate';
import TemplateEngagementEmailsCreate from './Views/TemplateEngagementEmailsCreate';
import DraftEngagementEmails from './Views/DraftEngagementEmails';
import EngagementEmailsSent from './Views/EngagementEmailsSent';
import classes from './EngagementEmails.module.css'


class EngagementEmails extends Component {
    state = {
        currentView: 0,
        currentViewForCreateEngagementEmails: 0,
        searchValue: ''
    }

    templateEmailsButtonRef = createRef();
    previousEmailsButtonRef = createRef();

    handleTabViewChange = (currentView) => {
        this.setState({ currentView: currentView });
    }

    handleCreateEngagementEmailsViewChange = (currentView) => {
        this.setState({
            currentViewForCreateEngagementEmails: currentView
        }, () => {
            if (this.state.currentViewForCreateEngagementEmails === 0)
            {
                this.templateEmailsButtonRef.current.classList.remove(classes[ 'create-engagement-emails__button--disabled' ]);
                this.previousEmailsButtonRef.current.classList.add(classes[ 'create-engagement-emails__button--disabled' ]);
            } else if (this.state.currentViewForCreateEngagementEmails === 1)
            {
                this.templateEmailsButtonRef.current.classList.add(classes[ 'create-engagement-emails__button--disabled' ]);
                this.previousEmailsButtonRef.current.classList.remove(classes[ 'create-engagement-emails__button--disabled' ]);
            }
        });
    }

    handleInputChange = (ev) => {
        this.setState({ searchValue: ev.currentTarget.value })
    }

    setSearchValue = () => {
        this.setState({ searchValue: '' });
    }

    render () {
        const {
            handleTabViewChange,
            handleCreateEngagementEmailsViewChange,
            handleInputChange,
            templateEmailsButtonRef,
            previousEmailsButtonRef } = this;

        const {
            currentViewForCreateEngagementEmails,
            currentView,
            searchValue } = this.state;

        return (
            <div>
                <div className="tab-title-main pb-2">
                    <Row>
                        <Col>
                            <h1 className="section-title trials">Engagement emails</h1>
                            <div className="nav-pagelink">
                                <div className="nav-pagelink">
                                    <div onClick={ () => handleTabViewChange(0) }
                                        className={ (0 === currentView) ? "pagelink-sml active" : "pagelink-sml inactive" }>
                                        Create
                                    </div>
                                    <div onClick={ () => handleTabViewChange(1) }
                                        className={ (1 === currentView) ? "pagelink-sml active" : "pagelink-sml inactive" }>
                                        Drafts
                                    </div>
                                    <div onClick={ () => handleTabViewChange(2) }
                                        className={ (2 === currentView) ? "pagelink-sml active" : "pagelink-sml inactive" }>
                                        Sent
                                    </div>
                                </div>
                            </div>
                            <div className="hr-bk-expand-ml-mr-15" />
                            <Search searchingValue={ searchValue } handleInputChange={ handleInputChange } />                        </Col>
                    </Row>
                </div>
                <div>
                    { currentView === 0 ? (
                        <React.Fragment>
                            <div
                                className={ classes[ "create-engagement-emails__buttons" ] }>

                                <button
                                    ref={ templateEmailsButtonRef }
                                    className={ currentViewForCreateEngagementEmails === 1 ? ([ classes[ "create-engagement-emails__button" ], classes[ "create-engagement-emails__button--disabled" ] ].join(" ")) : (classes[ "create-engagement-emails__button" ]) }
                                    onClick={ () => handleCreateEngagementEmailsViewChange(0) }>
                                    <i className={ [ classes[ "create-engagement-emails__button-icon" ], "las la-envelope" ].join(" ") }></i>
                                    <span
                                        className={ classes[ "create-engagement-emails__button-text" ] }>
                                        <span
                                            className={ classes[ "create-engagement-emails__button-label" ] }>
                                            Email
                                        </span>
                                        <br />
                                        <span
                                            className={ classes[ "create-engagement-emails__button-description" ] }>
                                            templates
                                        </span>
                                    </span>
                                </button>
                                <button
                                    ref={ previousEmailsButtonRef }
                                    className={ currentViewForCreateEngagementEmails === 0 ? ([ classes[ "create-engagement-emails__button" ], classes[ "create-engagement-emails__button--disabled" ] ].join(" ")) : (classes[ "create-engagement-emails__button" ]) }
                                    onClick={ () => handleCreateEngagementEmailsViewChange(1) }>
                                    <i className={ [ classes[ "create-engagement-emails__button-icon" ], "las la-clock" ].join(" ") }></i>
                                    <span
                                        className={ classes[ "create-engagement-emails__button-text" ] }>
                                        <span
                                            className={ classes[ "create-engagement-emails__button-label" ] }>
                                            Previous
                                        </span>
                                        <br />
                                        <span
                                            className={ classes[ "create-engagement-emails__button-description" ] }>
                                            emails
                                        </span>
                                    </span>
                                </button>
                            </div>

                            { currentViewForCreateEngagementEmails === 0 ? (
                                <TemplateEngagementEmailsCreate searchValue={ searchValue } setSearchValue={ this.setSearchValue } />
                            ) : (
                                    <PreviousEngagementEmailsCreate searchValue={ searchValue } setSearchValue={ this.setSearchValue } />
                                ) }

                        </React.Fragment>
                    ) : (null) }

                    { currentView === 1 ? (
                        <DraftEngagementEmails searchValue={ searchValue } setSearchValue={ this.setSearchValue } />
                    ) : (null) }
                    { currentView === 2 ? (
                        <EngagementEmailsSent searchValue={ searchValue } setSearchValue={ this.setSearchValue } />
                    ) : (null) }
                </div>
            </div>
        );
    }
};

export default EngagementEmails;