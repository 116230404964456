import React, { Component, createRef } from 'react';
import { Popover } from 'reactstrap';
import classes from '../CreateOrEdit.module.css';
import Toggle from 'react-toggle';
import '../../../../BusinessUnitSelection/BusinessUnitSelection.module.css';

class TextResponse extends Component {
	state = {
		question: '',
		requiredResponse: false,
		id: this.props.id,
		type: 'text',
		popoverOpen: false
	};

	constructor(props) {
		super(props);

		if (props.item) {
			this.state = {
				question: props.item.question,
				requiredResponse: props.item.requiredResponse,
				id: this.props.id,
				type: 'text',
				popoverOpen: false
			};
		}
	}

	requiredResponseRef = createRef();

	handleTextAreaChange = (ev) => {
		this.setState(
			{
				question: ev.currentTarget.value
			},
			() => {
				this.props.handleQuestionChange(this.state);
			}
		);
	};

	handleRequiredResponse = () => {
		this.setState(
			{
				requiredResponse: !this.state.requiredResponse
			},
			() => {
				this.props.handleQuestionChange(this.state);
			}
		);

		this.requiredResponseRef.current.classList.toggle(classes['form-survey__card-settings-option-label--enabled']);
	};
	toggleMenu = () => {
		this.setState({
			popoverOpen: !this.state.popoverOpen
		});
	};

	render() {
		const IDOfQuestion = this.state.id;
		const { popoverOpen } = this.state;

		return (
			<div className={classes['form-survey__card']}>
				<div className={classes['form-survey__card-title-container']}>
					<span className={classes['form-survey__card-question-number']}>{this.props.order}</span>
					<h3 className={classes['form-survey__card-title']}>Text Response</h3>
					<span>
						<i
							className={[ classes['form-survey__arrow-down-icon'], 'las la-angle-down' ].join(' ')}
							onClick={() => this.props.moveQuestionDown(this.state.id)}
						/>
					</span>
					<span className={classes['form-survey__card-menu-icon']}>
						<i className="las la-ellipsis-h" id="text-menu" />
						<Popover placement="left" isOpen={popoverOpen} target={`text-menu`} toggle={this.toggleMenu}>
							<p
								className={classes['popover_option']}
								style={styles.popover_action_red}
								onClick={() => {
									this.setState({ popoverOpen: false });
									this.props.onDelete();
								}}
							>
								REMOVE
							</p>
						</Popover>
					</span>
				</div>
				<div className={classes['form-survey__card-group']}>
					<label className={classes['form-survey__card-label']} htmlFor={`question-${IDOfQuestion}`}>
						question
					</label>
					<textarea
						className={classes['form-survey__card-textarea']}
						type="text"
						id={`question-${IDOfQuestion}`}
						name={`question-${IDOfQuestion}`}
						placeholder="question"
						value={this.state.question}
						onChange={this.handleTextAreaChange}
					/>
				</div>

				<div className={classes['form-survey__card-settings-container']}>
					<h3
						className={[
							classes['form-survey__card-label'],
							classes['form-survey__card-label-settings']
						].join(' ')}
					>
						settings
					</h3>
					<div className={classes['form-survey__card-settings-options-container']}>
						<div className={classes['form-survey__card-settings-option']}>
							<div className={classes['form-survey__card-toggle-container']}>
								<Toggle
									onChange={this.handleRequiredResponse}
									defaultChecked={this.state.requiredResponse}
									className="toggle-yellow"
									icons={false}
									name={`required-response-${IDOfQuestion}`}
									id={`required-response-${IDOfQuestion}`}
								/>
							</div>
							<label
								ref={this.requiredResponseRef}
								htmlFor={`required-response-${IDOfQuestion}`}
								className={classes['form-survey__card-settings-option-label']}
							>
								<div className={classes['form-survey__card-settings-icon-container']}>
									<i className="las la-lock" />{' '}
								</div>
								Make this a required response
							</label>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const styles = {
	popover_action_red: {
		color: 'red',
		margin: 5
	}
};

export default TextResponse;
