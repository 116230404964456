import React, { Component } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import classes from './SurveysAndEvaluations.module.css';
import Surveys from './Surveys';
import Evaluations from './Evaluations';

class SurveysAndEvaluations extends Component {
	state = {
		selectedCategory: 'surveys'
	};

	componentDidMount = () => {
		const params = new URLSearchParams(this.props.location.search);
		const type = params.get('type');
		if (type) this.setState({ selectedCategory: type });
		else {
			params.append('type', 'surveys');
			window.location.replace(window.location.pathname + '?' + params.toString());
		}
	};

	selectCategory = (category) => {
		const params = new URLSearchParams(this.props.location.search);
		const type = params.get('type');
		if (!type) {
			params.append('type', category);
		} else {
			params.set('type', category);
		}

		const section = params.get('section');
		if (!section) {
			params.append('section', 0);
		} else {
			params.set('section', 0);
		}

		window.location.replace(window.location.pathname + '?' + params.toString());
	};

	render() {
		const { selectedCategory } = this.state;
		const { trialID } = this.props;
		return (
			<div className={classes['surveyeval__category-container']}>
				<div className={classes['surveyeval__type-chooser']}>
					<button
						className={[
							classes['surveyeval__type-label'],
							selectedCategory === 'surveys' ? classes['surveyeval__type-label-selected'] : ''
						].join(' ')}
						onClick={() => this.selectCategory('surveys')}
					>
						SURVEYS
					</button>
					<button
						className={[
							classes['surveyeval__type-label'],
							selectedCategory === 'evaluations' ? classes['surveyeval__type-label-selected'] : ''
						].join(' ')}
						onClick={() => this.selectCategory('evaluations')}
					>
						EVALUATIONS
					</button>
				</div>
				{selectedCategory === 'surveys' ? <Surveys trialID={trialID} /> : <Evaluations trialID={trialID} />}
			</div>
		);
	}
}

export default withRouter(SurveysAndEvaluations);
