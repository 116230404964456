import React from 'react';

import classes from './FormButtons.module.css';

const CancelButton = ({ handleClick }) => {
    return (
        <button
            onClick={ handleClick }
            className={ [ classes[ "button" ], classes[ "button--cancel" ] ].join(" ") }>
            cancel
        </button>
    );
}

export default CancelButton;