import React from 'react';
import { Col } from 'reactstrap';
import classes from './results.module.css';
import Toggle from 'react-toggle';

export const TextResults = ({ question, exportable, onExportToggle }) => {
	return (
		<React.Fragment>
			<div>
				<div className="no-of-stats">
					<span className="stronger">{question.question_response_count}</span> TOTAL RESPONSE{question.question_response_count !== '1' ? 'S' : ''}
				</div>
				{question.question_options.map((option) => {
					return (
						<div className="result-stats survey-ans-pie" key={`option-${option.option_id}`}>
							<p className="pl-1 mb-0">
								<span className="futurastdbold">{option.people_name}</span> - {option.options}
							</p>
						</div>
					);
				})}

				<div className="line-grey" />
				<div className="inclue-export">
					<div className="include">
						<i className="las la-file-export circle" />
						INCLUDE IN EXPORT
					</div>

					<Toggle
						onChange={onExportToggle}
						checked={exportable}
						className="toggle-yellow"
						icons={false}
						name="exportable"
						value={`exportable-${question.id}`}
					/>
				</div>
			</div>
		</React.Fragment>
	);
};
