import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import Search from '../Utils/Search';
import _ from 'lodash';

import ErrorMessage from '../Components/ErrorMessage/ErrorMessage';
import List from '../Components/List/List';
import NoResults from '../Components/NoResults/NoResults';

const TalkTracks = (props) => {
	const { specialtyFromAPI, specialtyError, isFetchingSpecialty } = props;

	const [ sortColumn, setSortColumn ] = useState({ path: 'name', order: 'asc' });

	const handleSort = (path) => {
		if (sortColumn.path === path) {
			sortColumn.order === 'asc' ? setSortColumn({ path, order: 'desc' }) : setSortColumn({ path, order: 'asc' });
		} else {
			sortColumn.path = path;
			setSortColumn({ path, order: 'asc' });
		}
	};

	const filterSpecialtyBySearch = () => {
		if (searchValue === '') {
			return specialtyFromAPI;
		}
		
		return specialtyFromAPI.filter(specialty => {
			return specialty.name.toLowerCase().includes(searchValue.toLowerCase().trim());
		});
	};

	const [ searchValue, setSearchValue ] = useState('');
	const handleInputChange = (event) => {
		setSearchValue(event.currentTarget.value)
	};

	const sortedSpecialty = _.orderBy(filterSpecialtyBySearch(), [ sortColumn.path ], [ sortColumn.order ]);

	return (
		<div>
			<Container className="tab-title-main">
				<h1 className="section-title">Talk Tracks</h1>
				<div className="nav-pagelink">
					<Link to={'/talktracks/specialty'} className="pagelink-sml active mr-3half">
						by Specialty
					</Link>
					<Link to={'/talktracks/stakeholder'} className="pagelink-sml inactive">
						by Stakeholder
					</Link>
				</div>
				<div className="hr-bk-expand-ml-mr-15" />
				<Search handleInputChange={ handleInputChange } />
			</Container>

			<Container>
				<List
					list={sortedSpecialty}
					actions={null}
					sortAlphabeticallyByTitle={handleSort}
					sortBy="name"
					view="talk-tracks"
					isLoading={ isFetchingSpecialty }
				/>

				{ specialtyError ? (
					<ErrorMessage message={ specialtyError } />
				) : (null) }

				{ !isFetchingSpecialty && sortedSpecialty.length === 0 ? (
					<NoResults />
				) : (null) }
			</Container>
		</div>
	);
};

export default TalkTracks;
