import React, { Component } from 'react';
import classes from './Collateral.module.css';
import { Link } from 'react-router-dom';

class Collateral extends Component {
    render () {
        return (
            <div className={ classes[ "collateral-container" ] }>
                <h1 className={ classes[ "collateral-header" ] }>
                    Collateral
                </h1>
                <div>
                    <ul
                        className={ classes[ "collateral-list" ] }>
                        <li>
                            <Link
                                to="collateral-item"
                                className={ classes[ "collateral-list-item" ] }>
                                <div className={ classes[ "collateral-item-left" ] }>
                                    <div className={ classes[ "collateral-item-title" ] }>
                                        title
                                    </div>
                                    <div className={ classes[ "collateral-item-subtitle" ] }>
                                        subtitle
                                    </div>
                                </div>
                                <div className={ classes[ "collateral-item-right" ] }>
                                    <i className="las la-angle-right"></i>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Collateral;