import React from "react";
import "./formTrialsSurvey.css"

export const SurveyTitle =()=> {
    return (  
        <div className="tab-text survey-edit-form pb-1">
            <div className="tab-title">Add survey details</div>
            <div className="line-blk-shrink mb-1" />
            <form className="survey-edit">            
                <div className="form-group">
                    <label className="uploaded" >SURVEY NAME</label>
                    <input type="text" className="form-control2 text-inpiut" placeholder="Enter survey name" />
                </div>
            </form>
        </div>
);
}

export const MultipleChoice =()=> {
    return (  
    <div className="tab-text survey-edit-form pb-1">
        <div className="tab-title"><span className="bubble">1</span>Multiple Choice
            <i className="las la-angle-down"/>
            <i className="las la-ellipsis-h dots-survey float-right"/>
        </div>
        <div className="line-blk-shrink mb-1" />
        <form className="survey-edit">            
            <div className="form-group">
                <label  >QUESTION</label>
                <textarea type="textarea" className="form-control mb-3 q-textarea" row="10" placeholder="Enter question" />
                <label className="" >SETTINGS</label>
                <hr className="lt-grey mt-0 mb-0" />
                <div className="edit-survey-settings">
                    <img src="/images/icon-lock.png"/>Make this a required response
                    <img className="switch" src="/images/switch-on.png"/>
                </div>
                <div className="edit-survey-settings">
                    <img src="/images/icon-allow.png"/>Allow "multiple" response
                    <img className="switch" src="/images/switch-on.png"/>
                </div>
                <div className="edit-survey-settings">
                    <img src="/images/icon-chat.png"/>Allow "other" response
                <img className="switch" src="/images/switch-on.png"/>
                </div>

                <label className="mt-3" >ANSWERS</label>                    
                    <div className="multi-choice-ans mt-2">
                        <i className="las la-times"/>
                        <input type="text" className="form-control2 text-inpiut" placeholder="Example answer 1" />
                        <i className="las la-bars" />
                    </div>
                    <div className="multi-choice-ans mt-4">
                        <i className="las la-times"/>
                        <input type="text" className="form-control2 text-inpiut" placeholder="Example answer 2" />
                        <i className="las la-bars" />
                    </div>
                    <div className="multi-choice-ans mt-4">
                        <i className="las la-times"/>
                        <input type="text" className="form-control2 text-inpiut" placeholder="Example answer 3" />
                        <i className="las la-bars" />
                    </div>
                    <div className="align-center">
                        <button className="btn-large-add-answer mt-4 ">ADD AN ANSWER</button>  
                    </div>  
            </div>
        </form>
    </div>
    );
}

export const YesNo =()=> {
    return (
        <div className="tab-text survey-edit-form pb-1">
            <div className="tab-title"><span className="bubble">2</span>Yes / No
                <i className="las la-angle-down"/>
                <i className="las la-ellipsis-h dots-survey float-right"/>
            </div>
            <div className="line-blk-shrink mb-1" />
            <form className="survey-edit">            
                <div className="form-group">
                    <label  >QUESTION</label>
                    <textarea type="textarea" className="form-control mb-3 q-textarea" row="10" placeholder="Enter question" />
                    <label className="" >SETTINGS</label>
                    <hr className="lt-grey mt-0 mb-0" />
                    <div className="edit-survey-settings">
                        <img src="/images/icon-lock.png"/>Make this a required response
                        <img className="switch" src="/images/switch-on.png"/>
                    </div>
                    <div className="edit-survey-settings">
                        <img src="/images/icon-nochat.png"/>Allow "N/A" response
                        <img className="switch" src="/images/switch-on.png"/>
                    </div>

                    <label className="mt-4 mb-3" >ANSWERS</label>                    
                        <div className="multi-choice-ans">
                            <input type="text" className="form-control2 text-inpiut" placeholder="Yes" />
                        </div>
                        <div className="multi-choice-ans mt-4">
                            <input type="text" className="form-control2 text-inpiut" placeholder="No" />
                        </div>  
                </div>
            </form>
        </div>
    )
}

export const TextResponse=()=> {
    return ( 
        <div className="tab-text survey-edit-form pb-1">
            <div className="tab-title"><span className="bubble">3</span>Text Response
                <i className="las la-angle-down"/>
                <i className="las la-ellipsis-h dots-survey float-right"/>
            </div>
            <div className="line-blk-shrink mb-1" />
            <form className="survey-edit">            
                <div className="form-group">
                    <label  >QUESTION</label>
                    <textarea type="textarea" className="form-control mb-3 q-textarea" row="10" placeholder="Enter question" />
                    <label className="" >SETTINGS</label>
                    <hr className="lt-grey mt-0 mb-0" />
                    <div className="edit-survey-settings">
                        <img src="/images/icon-lock.png"/>Make this a required response
                        <img className="switch" src="/images/switch-on.png"/>
                    </div> 
                </div>
            </form>
        </div>
    );
}

export const Rating=()=> {
    return (
        <div className="tab-text survey-edit-form pb-1">
            <div className="tab-title"><span className="bubble">4</span>Rating
                <i className="las la-angle-down"/>
                <i className="las la-ellipsis-h dots-survey float-right"/>
            </div>
            <div className="line-blk-shrink mb-1" />
            <form className="survey-edit">            
                <div className="form-group">
                    <label  >QUESTION</label>
                    <textarea type="textarea" className="form-control mb-3 q-textarea" row="10" placeholder="Enter question" />
                    <label className="" >SETTINGS</label>
                    <hr className="lt-grey mt-0 mb-0" />
                    <div className="edit-survey-settings">
                        <img src="/images/icon-lock.png"/>Make this a required response
                        <img className="switch" src="/images/switch-on.png"/>
                    </div>
                    <div className="edit-survey-settings">
                        <img src="/images/icon-allow.png"/>Allow "multiple" response
                        <img className="switch" src="/images/switch-on.png"/>
                    </div>
                    <div className="edit-survey-settings">
                        <img src="/images/icon-chat.png"/>Allow "other" response
                    <img className="switch" src="/images/switch-on.png"/>
                    </div>

                    <label className="mt-4 mb-0" >SCALE</label>                    
                        <div className="rating">5 
                            <input type="text" className="form-control2 text-inpiut recomm" placeholder="(recommended)" />
                            <i className="las la-angle-down"/>
                        </div>
                        <div className="rating">
                            <input type="text" className="form-control2 text-inpiut" placeholder="Minimum rating label (optional)" />
                        </div>
                        <div className="rating">
                            <input type="text" className="form-control2 text-inpiut" placeholder="Maximum rating label (optional)" />
                        </div>  
                </div>
            </form>
        </div>
    );
}

export const DragDropRanking=()=> {
    return (  
    <div className="tab-text survey-edit-form pb-1">
        <div className="tab-title"><span className="bubble">5</span>Multiple Choice
            <i className="las la-angle-down"/>
            <i className="las la-ellipsis-h dots-survey float-right"/>
        </div>
        <div className="line-blk-shrink mb-1" />
        <form className="survey-edit">            
            <div className="form-group">
                <label  >QUESTION</label>
                <textarea type="textarea" className="form-control mb-3 q-textarea" row="10" placeholder="Enter question" />
                <label className="" >SETTINGS</label>
                <hr className="lt-grey mt-0 mb-0" />
                <div className="edit-survey-settings">
                    <img src="/images/icon-lock.png"/>Make this a required response
                    <img className="switch" src="/images/switch-on.png"/>
                </div>
 
                <label className="mt-3" >DRAG AND DROP RANKING ITEMS</label>                    
                    <div className="multi-choice-ans mt-2">
                        <i className="las la-times"/>
                        <input type="text" className="form-control2 text-inpiut" placeholder="Example answer 1" />
                        <i className="las la-bars" />
                    </div>
                    <div className="multi-choice-ans mt-4">
                        <i className="las la-times"/>
                        <input type="text" className="form-control2 text-inpiut" placeholder="Example answer 2" />
                        <i className="las la-bars" />
                    </div>
                    <div className="multi-choice-ans mt-4">
                        <i className="las la-times"/>
                        <input type="text" className="form-control2 text-inpiut" placeholder="Example answer 3" />
                        <i className="las la-bars" />
                    </div>
                    <div className="align-center">
                        <button className="btn-large-add-answer mt-4 ">ADD AN ANSWER</button>  
                    </div>  
            </div>
        </form>
    </div>
    );
}