import React, { useState, useEffect } from 'react';
import classes from './EditComment.module.css';
import { putChecklistCommentAction } from '../../../../../Store/actions/checklistsAction';
import { useDispatch, useSelector } from 'react-redux';
import useHandleSubmit from '../../../../../Hooks/useHandleSubmit';

const EditComment = ({ toggleModal, trialID, checklist, comment }) => {
	const dispatch = useDispatch();

	const handleCancelClick = (ev) => {
		ev.preventDefault();
		toggleModal();
	};

	const handleChecklistSubmit = () => {
		//dispatch(postStakeholderAction(formData));
		dispatch(
			putChecklistCommentAction({
				trialID: trialID,
				itemID: inputs.groupId,
				commentText: inputs.comment,
				commentID: comment.id
			})
		);
		toggleModal();
	};

	const [ canSubmit, setCanSubmit ] = useState(false);
	const allowSubmission = (ev) => {
		handleInputChange(ev);
		setCanSubmit(true);
	};

	const { inputs, handleInputChange, handleSubmit } = useHandleSubmit({
		callback: handleChecklistSubmit,
		initialState: {
			comment: comment ? comment.comment : '',
			groupId: checklist.id
		}
	});

	return (
		<form className={classes['form']} onSubmit={handleSubmit}>
			<label className={classes['form__label']} htmlFor="addChecklist">
				Edit comment
			</label>
			<input
				className={classes['form__input']}
				type="text"
				onChange={allowSubmission}
				value={inputs.comment}
				placeholder="Comment"
				name="comment"
				id="comment"
			/>
			<div className={classes['form__buttons-container']}>
				<button
					onClick={handleCancelClick}
					className={[ classes['form__button'], classes['form__button--cancel'] ].join(' ')}
				>
					cancel
				</button>
				<button
					className={[
						classes['form__button'],
						canSubmit ? classes['form__button--cansubmit'] : classes['form__button--submit']
					].join(' ')}
					disabled={!canSubmit}
				>
					submit
				</button>
			</div>
		</form>
	);
};

export default EditComment;
