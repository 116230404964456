import * as ActionTypes from './ActionTypes';

import API from '../../API/API';


export const getSpecialtyAction = () => {
    return (dispatch, getState) => {
        let BU = JSON.parse(localStorage.getItem("BU"));

        const filter = {
            sort_by: 'name',
            order_by: 'ASC',
            search: '',
            bu_id: BU
        };

        dispatch({ type: ActionTypes.REQUEST_SPECIALTY });

        API().post(`api/v7/trial/specialty`, filter)
            .then(function (res) {
                return dispatch({ type: ActionTypes.GET_SPECIALTY_SUCCESS, specialty: res.data.records })
            })
            .catch(err => {
                return dispatch({ type: ActionTypes.GET_SPECIALTY_ERROR, error: 'there was an error fetching tracks!' });
            })
    }
}

export const getTalkTrackSingleAction = ({ productSubId, productId, type }) => {
    return (dispatch, getState) => {
        let BU = JSON.parse(localStorage.getItem("BU"));

        const filter = {
            sort_by: 'name',
            order_by: 'ASC',
            page: 1,
            product_id: productId,
            product_sub_id: productSubId,
            type: type,
            bu_id: BU
        };

        dispatch({ type: ActionTypes.REQUEST_TALK_TRACK_SINGLE });

        API().post(`/api/v7/trial/detailtalktrack`, filter)
            .then(function (res) {
                return dispatch({ type: ActionTypes.GET_GROUP_TRACK_SINGLE_SUCCESS, groupTrackSingle: res.data.data });
            })
            .catch(err => {
                return dispatch({ type: ActionTypes.GET_GROUP_TRACK_SINGLE_ERROR, groupTracksError: 'there was an error!' });
            })
    }
}

export const getGroupTrackAudioAction = ({ productSubId, productId, type }) => {
    return (dispatch, getState) => {
        let BU = JSON.parse(localStorage.getItem("BU"));

        let parameters;
        if (type === 'specialty')
        {
            parameters = {
                sort_by: 'name',
                order_by: 'ASC',
                page: 1,
                speciality_id: productId,
                product_id: productSubId,
                type: type,
                bu_id: BU
            };
        } else if (type === 'stakeholder')
        {
            parameters = {
                sort_by: 'name',
                order_by: 'ASC',
                page: 1,
                stakeholder_id: productId,
                product_id: productSubId,
                type: type,
                bu_id: BU
            };
        }


        API().post('/api/v7/trial/gettalktrackaudiolist', parameters)
            .then(function (res) {
                return dispatch({ type: ActionTypes.GET_GROUP_TRACK_AUDIO_SUCCESS, groupTrackAudio: res.data.records });
            })
            .catch(err => {
                return dispatch({ type: ActionTypes.GET_GROUP_TRACK_AUDIO_ERROR, groupTrackAudioError: 'there was an error!' });
            })
    }
}

export const getStakeholdersAction = () => {

    return (dispatch, getState) => {
        let BU = JSON.parse(localStorage.getItem("BU"));

        const filter = {
            sort_by: 'name',
            order_by: 'ASC',
            search: '',
            bu_id: BU
        };

        dispatch({ type: ActionTypes.REQUEST_STAKEHOLDERS });

        API().post(`/api/v7/trial/stakeholdertalktrackList`, filter)
            .then(function (res) {
                return dispatch({ type: ActionTypes.GET_STAKEHOLDERS_SUCCESS, stakeholders: res.data.records });
            })
            .catch(err => {
                return dispatch({ type: ActionTypes.GET_STAKEHOLDERS_ERROR, stakeholdersError: 'there was an error!' });
            })
    }
}

export const resetTalkTrackSingleInReducerAction = () => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.RESET_TALK_TRACK_SINGLE_IN_REDUCER });
    }
}