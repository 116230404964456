import React from 'react';

import classes from './FormButtons.module.css';

const SubmitButton = ({ handleClick, isGray = false, text = 'submit', isWhite = false }) => {
    let buttonClasses = [ classes[ "button" ] ];
    if (isGray)
    {
        buttonClasses.push(classes[ "button--gray" ]);
    } else if (isWhite)
    {
        buttonClasses.push(classes[ "button--white" ]);
    } else
    {
        buttonClasses.push(classes[ "button--submit" ]);
    }

    return (
        <button
            onClick={ handleClick }
            className={ buttonClasses.join(" ") }>
            { text }
        </button>
    );
}

export default SubmitButton;