import React from "react";

const PendingContent = () => {
    return (
        <>
            <div style={ { paddingTop: "200px", textAlign: "center" } }>Pending. </div>
        </>
    )
}

export default PendingContent;