import React, { useState, useEffect } from 'react';
import classes from './ExportSummaryModal.module.css';
import { downloadPDFSummary, downloadExcelSummary } from '../../../../Store/actions/evaluationsAction';
import { downloadSurveyPDFSummary, downloadSurveyExcelSummary } from '../../../../Store/actions/surveysAction';
import { useDispatch } from 'react-redux';

const ExportSummaryModal = ({ toggleModal, trialID, templateID, questionIDs, templateType, questionChartImages }) => {
	const dispatch = useDispatch();

	const handleCancelClick = (ev) => {
		ev.preventDefault();
		toggleModal();
	};

	const exportFile = (ev, type) => {
		ev.preventDefault();
		if (templateType === 'survey') {
			type === 'pdf'
				? dispatch(downloadSurveyPDFSummary({ trialID, templateID, questionIDs, questionChartImages }))
				: dispatch(downloadSurveyExcelSummary({ trialID, templateID, questionIDs }));
		} else {
			type === 'pdf'
				? dispatch(downloadPDFSummary({ trialID, templateID, questionIDs, questionChartImages }))
				: dispatch(downloadExcelSummary({ trialID, templateID, questionIDs }));
		}
		toggleModal();
	};

	return (
		<form className={classes['form']}>
			<label className={classes['form__label']} htmlFor="addChecklist">
				Export summary
			</label>
			<div className={classes['form__buttons-container']}>
				<button
					onClick={(ev) => exportFile(ev, 'pdf')}
					className={[ classes['form__button'], classes['form__button--cansubmit'] ].join(' ')}
				>
					export pdf
				</button>
				<button
					onClick={(ev) => exportFile(ev, 'excel')}
					className={[ classes['form__button'], classes['form__button--cansubmit'] ].join(' ')}
				>
					export excel
				</button>
				<button
					onClick={handleCancelClick}
					className={[ classes['form__button'], classes['form__button--cancel'] ].join(' ')}
				>
					cancel
				</button>
			</div>
		</form>
	);
};

export default ExportSummaryModal;
