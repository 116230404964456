/** @format */

export const createFormValidationPreTrialCommunicationThankYouEndoscopy = ({
  inputs,
  setFormValidationErrors,
}) => {
  if (inputs.emailName === "") {
    setFormValidationErrors((previousFormValidationErrors) => ({
      ...previousFormValidationErrors,
      emailName: "This field is required.",
    }));
  }

  if (inputs.subjectLine === "") {
    setFormValidationErrors((previousFormValidationErrors) => ({
      ...previousFormValidationErrors,
      subjectLine: "This field is required.",
    }));
  }

  if (inputs.account_name === "") {
    setFormValidationErrors((previousFormValidationErrors) => ({
      ...previousFormValidationErrors,
      account_name: "This field is required.",
    }));
  }

  if (inputs.content === "") {
    setFormValidationErrors((previousFormValidationErrors) => ({
      ...previousFormValidationErrors,
      content: "This field is required.",
    }));
  }

  if (inputs.head_line === "") {
    setFormValidationErrors((previousFormValidationErrors) => ({
      ...previousFormValidationErrors,
      head_line: "This field is required.",
    }));
  }

  if (inputs.header_two === "") {
    setFormValidationErrors((previousFormValidationErrors) => ({
      ...previousFormValidationErrors,
      header_two: "This field is required.",
    }));
  }

  if (
    inputs.subjectLine === "" ||
    inputs.emailName === "" ||
    inputs.account_name === "" ||
    inputs.content === "" ||
    inputs.head_line === "" ||
    inputs.header_two === ""
  )
    return false;

  return true;
};

export const createFormValidationInServiceInviteCommunicationsEmail = ({
  inputs,
  setFormValidationErrors,
}) => {
  let contentJSON = {
    title: inputs.title,
    sub_title: inputs.sub_title,
    title_description: inputs.title_description,
    when_content: inputs.when_content,
    when_time: inputs.when_time,
    where_content: inputs.where_content,
    where_content_two: inputs.where_content_two,
    what_content: inputs.what_content,
    what_content_two: inputs.what_content_two,
    content_fname_lname: inputs.content_fname_lname,
    content: inputs.content,
    emailName: inputs.emailName,
    subjectLine: inputs.subjectLine,
  };

  let inputsFilled = true;

  for (const property in contentJSON) {
    if (contentJSON[property] === "" || contentJSON[property] === undefined) {
      setFormValidationErrors((previousFormValidationErrors) => ({
        ...previousFormValidationErrors,
        [property]: "This field is required.",
      }));

      inputsFilled = false;
    }
  }

  return inputsFilled;
};

export const createFormValidationInServiceInviteEndoscopyEmail = ({
  inputs,
  setFormValidationErrors,
}) => {
  let contentJSON = {
    title: inputs.title,
    sub_title: inputs.sub_title,
    title_description: inputs.title_description,
    when_content: inputs.when_content,
    when_time: inputs.when_time,
    where_content: inputs.where_content,
    where_content_two: inputs.where_content_two,
    what_content: inputs.what_content,
    what_content_two: inputs.what_content_two,
    content_fname_lname: inputs.content_fname_lname,
    content: inputs.content,
    emailName: inputs.emailName,
    subjectLine: inputs.subjectLine,
  };

  let inputsFilled = true;

  for (const property in contentJSON) {
    if (contentJSON[property] === "" || contentJSON[property] === undefined) {
      setFormValidationErrors((previousFormValidationErrors) => ({
        ...previousFormValidationErrors,
        [property]: "This field is required.",
      }));

      inputsFilled = false;
    }
  }

  return inputsFilled;
};

export const createFormValidationInServiceRecapCommunications = ({
  inputs,
  setFormValidationErrors,
}) => {
  let contentJSON = {
    title: inputs.title,
    sub_title: inputs.sub_title,
    title_description: inputs.title_description,
    content: inputs.content,
    recipient_name: inputs.recipient_name,
    inservice_attendees_is_on: inputs.inservice_attendees_is_on,
    inservice_attendees_number: inputs.inservice_attendees_number,
    inservice_attendees_title: inputs.inservice_attendees_title,
    inservice_attendees_subtitle: inputs.inservice_attendees_subtitle,
    inservice_attendees_line1: inputs.inservice_attendees_line1,
    emailName: inputs.emailName,
    subjectLine: inputs.subjectLine,
  };

  let inputsFilled = true;

  for (const property in contentJSON) {
    if (contentJSON[property] === "" || contentJSON[property] === undefined) {
      setFormValidationErrors((previousFormValidationErrors) => ({
        ...previousFormValidationErrors,
        [property]: "This field is required.",
      }));

      inputsFilled = false;
    }
  }

  return inputsFilled;
};

export const createFormValidationInServiceInviteMedEmail = ({
  inputs,
  setFormValidationErrors,
}) => {
  let contentJSON = {
    title: inputs.title,
    sub_title: inputs.sub_title,
    title_description: inputs.title_description,
    when_content: inputs.when_content,
    when_time: inputs.when_time,
    where_content: inputs.where_content,
    where_content_two: inputs.where_content_two,
    what_content: inputs.what_content,
    what_content_two: inputs.what_content_two,
    content_fname_lname: inputs.content_fname_lname,
    content: inputs.content,
    emailName: inputs.emailName,
    subjectLine: inputs.subjectLine,
  };

  let inputsFilled = true;

  for (const property in contentJSON) {
    if (contentJSON[property] === "" || contentJSON[property] === undefined) {
      setFormValidationErrors((previousFormValidationErrors) => ({
        ...previousFormValidationErrors,
        [property]: "This field is required.",
      }));

      inputsFilled = false;
    }
  }

  return inputsFilled;
};

export const createFormValidationForPreTrialCommunicationThankYouCommunications =
  ({ inputs, setFormValidationErrors }) => {
    let contentJSON = {
      emailName: inputs.emailName,
      subjectLine: inputs.subjectLine,
      account_name: inputs.account_name,
      head_line: inputs.head_line,
      content: inputs.content,
      header_two: inputs.header_two,
    };

    let inputsFilled = true;

    for (const property in contentJSON) {
      if (contentJSON[property] === "" || contentJSON[property] === undefined) {
        setFormValidationErrors((previousFormValidationErrors) => ({
          ...previousFormValidationErrors,
          [property]: "This field is required.",
        }));

        inputsFilled = false;
      }
    }

    return inputsFilled;
  };

export const createFormValidationForInServiceRecapEndoscopy = ({
  inputs,
  setFormValidationErrors,
}) => {
  let contentJSON = {
    emailName: inputs.emailName,
    subjectLine: inputs.subjectLine,
    title: inputs.title,
    sub_title: inputs.sub_title,
    title_description: inputs.title_description,
    content: inputs.content,
    recipient_name: inputs.recipient_name,
    inservice_attendees_is_on: inputs.inservice_attendees_is_on,
    inservice_attendees_number: inputs.inservice_attendees_number,
    inservice_attendees_title: inputs.inservice_attendees_title,
    inservice_attendees_subtitle: inputs.inservice_attendees_subtitle,
    inservice_attendees_line1: inputs.inservice_attendees_line1,
    sterile_processing_preparation_is_on:
      inputs.sterile_processing_preparation_is_on,
    sterile_processing_preparation_title:
      inputs.sterile_processing_preparation_title,
    sterile_processing_preparation_subtitle:
      inputs.sterile_processing_preparation_subtitle,
    sterile_processing_preparation_contact_name:
      inputs.sterile_processing_preparation_contact_name,
    sterile_processing_preparation_line1:
      inputs.sterile_processing_preparation_line1,
    pharmacy_preparation_is_on: inputs.pharmacy_preparation_is_on,
    pharmacy_preparation_title: inputs.pharmacy_preparation_title,
    pharmacy_preparation_subtitle: inputs.pharmacy_preparation_subtitle,
    pharmacy_preparation_contact_name: inputs.pharmacy_preparation_contact_name,
    pharmacy_preparation_line1: inputs.pharmacy_preparation_line1,
    pharmacy_preparation_s_name1: inputs.pharmacy_preparation_s_name1,
  };

  let inputsFilled = true;

  for (const property in contentJSON) {
    if (contentJSON[property] === "" || contentJSON[property] === undefined) {
      setFormValidationErrors((previousFormValidationErrors) => ({
        ...previousFormValidationErrors,
        [property]: "This field is required.",
      }));

      inputsFilled = false;
    }
  }

  return inputsFilled;
};

export const createFormValidationForTrialRecapUpdateEndoscopy = ({
  inputs,
  setFormValidationErrors,
}) => {
  let contentJSON = {
    emailName: inputs.emailName,
    subjectLine: inputs.subjectLine,
    title: inputs.title,
    sub_title: inputs.sub_title,
    title_description: inputs.title_description,
    content: inputs.content,
  };

  let inputsFilled = true;

  for (const property in contentJSON) {
    if (contentJSON[property] === "" || contentJSON[property] === undefined) {
      setFormValidationErrors((previousFormValidationErrors) => ({
        ...previousFormValidationErrors,
        [property]: "This field is required.",
      }));

      inputsFilled = false;
    }
  }

  return inputsFilled;
};

export const createFormValidationForWeeklyEngagementEndoscopy = ({
  inputs,
  setFormValidationErrors,
}) => {
  let contentJSON = {
    title: inputs.title,
    sub_title: inputs.sub_title,
    title_description: inputs.title_description,
    content: inputs.content,
    emailName: inputs.emailName,
    subjectLine: inputs.subjectLine,
  };

  let inputsFilled = true;

  for (const property in contentJSON) {
    if (contentJSON[property] === "" || contentJSON[property] === undefined) {
      setFormValidationErrors((previousFormValidationErrors) => ({
        ...previousFormValidationErrors,
        [property]: "This field is required.",
      }));

      inputsFilled = false;
    }
  }

  return inputsFilled;
};

export const createFormValidationForTrialRecapUpdateCommunications = ({
  inputs,
  setFormValidationErrors,
}) => {
  let contentJSON = {
    title: inputs.title,
    sub_title: inputs.sub_title,
    title_description: inputs.title_description,
    content: inputs.content,
    emailName: inputs.emailName,
    subjectLine: inputs.subjectLine,
  };

  let inputsFilled = true;

  for (const property in contentJSON) {
    if (contentJSON[property] === "" || contentJSON[property] === undefined) {
      setFormValidationErrors((previousFormValidationErrors) => ({
        ...previousFormValidationErrors,
        [property]: "This field is required.",
      }));

      inputsFilled = false;
    }
  }

  return inputsFilled;
};
