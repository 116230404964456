import * as ActionTypes from '../actions/ActionTypes';

const initState = {
    productEducation: [],
    error: '',
    isFetchingProductEducation: false,
    productEducationSearchResults: false,
    productEducationSearchResultsError: '',

    productEducationSingle: {},
    productEducationSingleError: '',

    isFetchingContentList: false,
    contentList: [],
    contentListError: '',
    isLastPageInCompetitiveIntel: false,
    competitiveIntelSearchResults: false,
    competitiveIntelSearchResultsError: '',
    isFetchingCompetitiveIntelSearchResults: false,

    isFetchingContentFolderList: false,
    competitiveIntelFolder: false,
    competitiveIntelFolderError: '',
    isLastPageInCompetitiveIntelFolder: false,
}

const productEducationReducer = (state = initState, action) => {
    switch (action.type)
    {
        case ActionTypes.REQUEST_PRODUCT_EDUCATION:
            return {
                ...state,
                isFetchingProductEducation: true
            }

        case ActionTypes.GET_PRODUCT_EDUCATION_SUCCESS:
            return {
                ...state,
                productEducation: action.productEducation,
                error: '',
                isFetchingProductEducation: false
            }

        case ActionTypes.GET_PRODUCT_EDUCATION_ERROR:
            return {
                ...state,
                error: action.error,
                isFetchingProductEducation: false
            }

        case ActionTypes.GET_PRODUCT_EDUCATION_SINGLE_SUCCESS:
            return {
                ...state,
                productEducationSingle: action.productEducationSingle,
                productEducationSingleError: ''
            }

        case ActionTypes.GET_PRODUCT_EDUCATION_SINGLE_ERROR:
            return {
                ...state,
                productEducationSingleError: action.productEducationSingleError
            }

        case ActionTypes.GET_PRODUCT_EDUCATION_SEARCH_RESULTS_SUCCESS:
            return {
                ...state,
                productEducationSearchResults: action.productEducationSearchResults,
                productEducationSearchResultsError: ''
            }

        case ActionTypes.GET_PRODUCT_EDUCATION_SEARCH_RESULTS_ERROR:
            return {
                ...state,
                productEducationSearchResultsError: action.productEducationSearchResultsError
            }

        case ActionTypes.REQUEST_COMPETITIVE_INTEL_SEARCH_RESULTS:
            return {
                ...state,
                isFetchingCompetitiveIntelSearchResults: true
            }

        case ActionTypes.GET_COMPETITIVE_INTEL_SEARCH_RESULTS_SUCCESS:
            return {
                ...state,
                competitiveIntelSearchResults: action.competitiveIntelSearchResults,
                competitiveIntelSearchResultsError: '',
                isFetchingCompetitiveIntelSearchResults: false
            }

        case ActionTypes.GET_COMPETITIVE_INTEL_SEARCH_RESULTS_ERROR:
            return {
                ...state,
                competitiveIntelSearchResults: [],
                competitiveIntelSearchResultsError: action.competitiveIntelSearchResultsError,
                isFetchingCompetitiveIntelSearchResults: false
            }

        case ActionTypes.REQUEST_CONTENT_LIST:
            return {
                ...state,
                isFetchingContentList: true
            }

        case ActionTypes.GET_CONTENT_LIST_SUCCESS:
            let competitiveIntelArray;
            let isLastPageInCompetitiveIntel = false;
            const FIRST_PAGE = 1;

            if (action.contentList === undefined)
            {
                competitiveIntelArray = state.contentList;
                isLastPageInCompetitiveIntel = true;
            }
            else if (action.currentPageNumber === FIRST_PAGE)
            {
                competitiveIntelArray = action.contentList;
                isLastPageInCompetitiveIntel = false;
            }
            else if (action.contentList && state.contentList)
            {
                competitiveIntelArray = [ ...state.contentList, ...action.contentList ];
            }

            if (competitiveIntelArray.length === action.totalRecords)
            {
                isLastPageInCompetitiveIntel = true;
            }

            return {
                ...state,
                isFetchingContentList: false,
                contentList: competitiveIntelArray,
                isLastPageInCompetitiveIntel: isLastPageInCompetitiveIntel,
                contentListError: ''
            }

        case ActionTypes.GET_CONTENT_LIST_ERROR:
            return {
                ...state,
                isFetchingContentList: false,
                contentListError: action.contentListError
            }

        case ActionTypes.REQUEST_CONTENT_FOLDER_LIST:
            return {
                ...state,
                isFetchingContentFolderList: true
            }

        case ActionTypes.GET_CONTENT_FOLDER_LIST_SUCCESS:
            let competitiveIntelFolderArray;
            let isLastPageInCompetitiveIntelFolder = false;
            const FIRST_PAGE_FOLDER = 1;

            if (action.competitiveIntelFolder === undefined)
            {
                if (state.competitiveIntelFolder === false)
                {
                    competitiveIntelFolderArray = [];
                } else
                {
                    competitiveIntelFolderArray = state.competitiveIntelFolder;
                }
                isLastPageInCompetitiveIntelFolder = true;
            }
            else if (action.currentPageNumber === FIRST_PAGE_FOLDER)
            {
                competitiveIntelFolderArray = action.competitiveIntelFolder;
                isLastPageInCompetitiveIntelFolder = false;
            }
            else if (action.competitiveIntelFolder && state.competitiveIntelFolder)
            {
                competitiveIntelFolderArray = [ ...state.competitiveIntelFolder, ...action.competitiveIntelFolder ];
            }

            if (competitiveIntelFolderArray.length === action.totalRecords)
            {
                isLastPageInCompetitiveIntelFolder = true;
            }

            return {
                ...state,
                isFetchingContentFolderList: false,
                competitiveIntelFolder: competitiveIntelFolderArray,
                isLastPageInCompetitiveIntelFolder: isLastPageInCompetitiveIntelFolder,
                competitiveIntelFolderError: ''
            }

        case ActionTypes.GET_CONTENT_FOLDER_LIST_ERROR:
            return {
                ...state,
                isFetchingContentFolderList: false,
                contentFolderListError: action.contentFolderListError
            }

        case ActionTypes.RESET_PRODUCT_EDUCATION_SINGLE_IN_REDUCER:
            return {
                ...state,
                productEducationSingle: {}
            }

        default:
            return state;
    }
}

export default productEducationReducer;