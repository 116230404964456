import React, { Component, createRef } from 'react';
import { Popover } from 'reactstrap';
import classes from '../CreateOrEdit.module.css';
import Toggle from 'react-toggle';
import { cloneDeep } from 'lodash';
import '../../../../BusinessUnitSelection/BusinessUnitSelection.module.css';

class MultipleChoice extends Component {
	state = {
		requiredResponse: false,
		answers: [],
		question: '',
		allowMultipleResponses: false,
		id: this.props.id,
		order: this.props.order,
		type: 'multiple',
		popoverOpen: false
	};

	constructor(props) {
		super(props);
		if (props.item) {
			this.state = {
				requiredResponse: props.item.requiredResponse,
				questionAnswers: props.item.questionAnswers,
				question: props.item.question,
				allowMultipleResponses: props.item.allowMultipleResponses,
				id: props.item.id,
				type: props.item.type
			};
		}
	}

	allowMultipleResponsesRef = createRef();
	requiredResponseRef = createRef();

	handleTextAreaChange = (ev) => {
		this.setState(
			{
				question: ev.currentTarget.value
			},
			() => {
				this.props.handleQuestionChange(this.state);
			}
		);
	};

	handleAnswerChange = (ev, indexOfAnswer) => {
		let cloneAnswers = cloneDeep(this.state.questionAnswers);

		cloneAnswers[indexOfAnswer] = { ...cloneAnswers[indexOfAnswer], options: ev.currentTarget.value };

		this.setState(
			{
				questionAnswers: cloneAnswers
			},
			() => {
				this.props.handleQuestionChange(this.state);
			}
		);
	};

	handleAddAnswer = (ev) => {
		ev.preventDefault();

		const cloneAnswers = cloneDeep(this.state.questionAnswers);
		cloneAnswers.push({ options: '', justAdded: true });

		this.setState(
			{
				questionAnswers: cloneAnswers
			},
			() => {
				this.props.handleQuestionChange(this.state);
			}
		);
	};

	handleRemoveAnswer = (index) => {
		let cloneAnswers = cloneDeep(this.state.questionAnswers);
		cloneAnswers.splice(index, 1);

		this.setState(
			{
				questionAnswers: cloneAnswers
			},
			() => {
				this.props.handleQuestionChange(this.state);
			}
		);
	};

	handleRequiredResponse = () => {
		this.setState(
			{
				requiredResponse: !this.state.requiredResponse
			},
			() => {
				this.props.handleQuestionChange(this.state);
			}
		);

		this.requiredResponseRef.current.classList.toggle(classes['form-survey__card-settings-option-label--enabled']);
	};

	handleAllowMultipleResponses = () => {
		this.setState(
			{
				allowMultipleResponses: !this.state.allowMultipleResponses
			},
			() => {
				this.props.handleQuestionChange(this.state);
			}
		);

		this.allowMultipleResponsesRef.current.classList.toggle(
			classes['form-survey__card-settings-option-label--enabled']
		);
	};

	toggleMenu = () => {
		this.setState({
			popoverOpen: !this.state.popoverOpen
		});
	};

	render() {
		const IDOfQuestion = this.state.id;
		const { popoverOpen } = this.state;

		return (
			<div className={classes['form-survey__card']}>
				<div className={classes['form-survey__card-title-container']}>
					<span className={classes['form-survey__card-question-number']}>{this.props.order}</span>
					<h3 className={classes['form-survey__card-title']}>Multiple Choice</h3>
					<span>
						<i className={[ classes['form-survey__arrow-down-icon'], 'las la-angle-down' ].join(' ')} />
					</span>
					<span className={classes['form-survey__card-menu-icon']}>
						<i className="las la-ellipsis-h" id="multiple-menu" />
						<Popover
							placement="left"
							isOpen={popoverOpen}
							target={`multiple-menu`}
							toggle={this.toggleMenu}
						>
							<p
								className={classes['popover_option']}
								style={styles.popover_action_red}
								onClick={() => {
									this.setState({ popoverOpen: false });
									this.props.onDelete();
								}}
							>
								REMOVE
							</p>
						</Popover>
					</span>
				</div>
				<div className={classes['form-survey__card-group']}>
					<label className={classes['form-survey__card-label']} htmlFor={`question-${IDOfQuestion}`}>
						question
					</label>
					<textarea
						className={classes['form-survey__card-textarea']}
						type="text"
						id={`question-${IDOfQuestion}`}
						placeholder="question"
						name={`question-${IDOfQuestion}`}
						value={this.state.question}
						onChange={this.handleTextAreaChange}
					/>
				</div>

				<div className={classes['form-survey__card-settings-container']}>
					<h3
						className={[
							classes['form-survey__card-label'],
							classes['form-survey__card-label-settings']
						].join(' ')}
					>
						settings
					</h3>
					<div className={classes['form-survey__card-settings-options-container']}>
						<div className={classes['form-survey__card-settings-option']}>
							<div className={classes['form-survey__card-toggle-container']}>
								<Toggle
									onChange={this.handleRequiredResponse}
									defaultChecked={this.state.requiredResponse}
									className="toggle-yellow"
									icons={false}
									name={`required-response-${IDOfQuestion}`}
									id={`required-response-${IDOfQuestion}`}
								/>
							</div>
							<label
								ref={this.requiredResponseRef}
								htmlFor={`required-response-${IDOfQuestion}`}
								className={classes['form-survey__card-settings-option-label']}
							>
								<div className={classes['form-survey__card-settings-icon-container']}>
									<i className="las la-lock" />{' '}
								</div>
								Make this a required response
							</label>
						</div>
						<div className={classes['form-survey__card-settings-option']}>
							<div className={classes['form-survey__card-toggle-container']}>
								<Toggle
									onChange={this.handleAllowMultipleResponses}
									defaultChecked={this.state.allowMultipleResponses}
									className="toggle-yellow"
									icons={false}
									id={`allow-multiple-responses-${IDOfQuestion}`}
									name={`allow-multiple-responses-${IDOfQuestion}`}
								/>
							</div>
							<label
								ref={this.allowMultipleResponsesRef}
								htmlFor={`allow-multiple-responses-${IDOfQuestion}`}
								className={classes['form-survey__card-settings-option-label']}
							>
								<div className={classes['form-survey__card-settings-icon-container']}>
									<i className="las la-check" />{' '}
								</div>
								Allow multiple responses
							</label>
						</div>
					</div>
				</div>

				<div
					className={[
						classes['form-survey__card-answers-container'],
						classes['form-survey__card-answers-container-margin']
					].join(' ')}
				>
					<h3 className={classes['form-survey__card-label']}>answers</h3>

					<div className={classes['form-survey__card-single-answer-container']}>
						{this.state.questionAnswers.map((answer, index) => {
							return (
								<div key={index} className={classes['form-survey__card-single-answer']}>
									<div
										onClick={() => this.handleRemoveAnswer(index)}
										className={classes['form-survey__card-single-answer-icon-container']}
									>
										<i className="las la-times" />
									</div>
									<div className={classes['form-survey__card-single-answer-input-container']}>
										<input
											onChange={(ev) => this.handleAnswerChange(ev, index)}
											value={answer.options !== null ? answer.options : answer}
											placeholder="answer"
											className={classes['form-survey__card-single-answer-input']}
										/>
										{/* <i className="las la-bars" /> */}
									</div>
								</div>
							);
						})}
					</div>
					<button className={classes['form-survey__card-button']} onClick={this.handleAddAnswer}>
						add an answer
					</button>
				</div>
			</div>
		);
	}
}
const styles = {
	popover_action_red: {
		color: 'red',
		margin: 5
	}
};

export default MultipleChoice;
