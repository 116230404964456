import React, { useState, useEffect } from 'react';

import SelectPersonList from '../../../../../Components/SelectPersonList/SelectPersonList';
import ComponentLoading from '../../../../../Components/ComponentLoading/ComponentLoading';

import classes from '../CustomizeEmail.module.css';

import { useDispatch, useSelector } from 'react-redux';
import { getRecipientsAction } from '../../../../../Store/actions/trials/engagementEmailsAction';
import { useParams } from 'react-router-dom';

const SecondView = ({ inputs, handleInputChange }) => {
    const dispatch = useDispatch();
    const { id, engagementEmailID } = useParams();

    useEffect(() => {
        dispatch(getRecipientsAction({ trialID: id, userTemplateID: engagementEmailID }));
    }, []);

    const isFetchingRecipients = useSelector(state => state.engagementEmails.isFetchingRecipients);
    const recipientsFromAPI = useSelector(state => state.engagementEmails.recipients);

    const [ people, setPeople ] = useState([]);

    useEffect(() => {
        if (recipientsFromAPI)
        {
            let addTypeToSurgeons = recipientsFromAPI.surgeons.map(person => {
                return {
                    ...person,
                    type: 'surgeon'
                }
            });

            let addTypeToStakeholders = recipientsFromAPI.stakeholders.map(person => {
                return {
                    ...person,
                    type: 'stakeholder'
                }
            });

            let concatSurgeonsAndStakeholders = addTypeToSurgeons.concat(addTypeToStakeholders);

            let selectedPeople = concatSurgeonsAndStakeholders.filter(person => person.is_selected === "1");
            handleInputChange({
                target: {
                    name: 'recipients',
                    value: selectedPeople
                }
            });

            setPeople(concatSurgeonsAndStakeholders);

            handleInputChange({
                target: {
                    name: 'recipientsAddedByEmail',
                    value: recipientsFromAPI.custom
                }
            });
        }
    }, [ recipientsFromAPI ]);

    const [ currentView, setCurrentView ] = useState('all');

    useEffect(() => {
        if (recipientsFromAPI)
        {
            if (currentView === 'all')
            {
                setPeople(recipientsFromAPI.surgeons.concat(recipientsFromAPI.stakeholders));
            } else if (currentView === 'surgeons')
            {
                setPeople(recipientsFromAPI.surgeons);
            } else if (currentView === 'stakeholders')
            {
                setPeople(recipientsFromAPI.stakeholders);
            } else if (currentView === 'add by email')
            {
                setPeople([]);
            }
        }
    }, [ currentView ])

    const handleCurrentView = (ev) => {
        let chosenView = ev.target.getAttribute('data-view');
        if (!chosenView) return;
        document.querySelector(`.${ classes[ 'second-view__button--active' ] }`)
            .classList
            .remove(classes[ 'second-view__button--active' ]);

        ev
            .target
            .classList
            .add(classes[ "second-view__button--active" ]);

        setCurrentView(chosenView);
    }

    const [ recipientsAddedByEmail, setRecipientsAddedByEmail ] = useState('');
    const handleAddedByEmailChange = (ev) => {
        if (ev.target.value.includes(','))
        {
            ev.target.value = ev.target.value.replace(',', '');
            if (!isValidEmailAddress(ev.target.value)) return alert(`${ ev.target.value } is not a valid email`);
            if (inputs.recipientsAddedByEmail.includes(ev.target.value))
            {
                return setRecipientsAddedByEmail('');
            }

            let cloneRecipientsAddedByEmail = JSON.parse(JSON.stringify(inputs.recipientsAddedByEmail));
            cloneRecipientsAddedByEmail.push(ev.target.value)

            handleInputChange({
                target: {
                    name: 'recipientsAddedByEmail',
                    value: cloneRecipientsAddedByEmail
                }
            });

            return setRecipientsAddedByEmail('');
        }

        setRecipientsAddedByEmail(ev.target.value);
    }

    const handleRemoveEmail = (event, email) => {
        event.preventDefault();

        let updatedRecipientsEmails = inputs.recipientsAddedByEmail.filter(emailStoredInState => {
            return email !== emailStoredInState;
        });

        handleInputChange({
            target: {
                name: 'recipientsAddedByEmail',
                value: updatedRecipientsEmails
            }
        });
    }

    const isValidEmailAddress = (emailAddress) => {
        var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
        return pattern.test(emailAddress);
    }

    return (
        <div className={ classes[ "second-view__container" ] }>
            <div
                onClick={ handleCurrentView }
                className={ classes[ "second-view__buttons" ] }>
                <button
                    data-view="all"
                    className={ [ classes[ "second-view__button" ], classes[ "second-view__button--active" ] ].join(" ") }>
                    All
                </button>
                <button
                    data-view="surgeons"
                    className={ classes[ "second-view__button" ] }>
                    Surgeons
                </button>
                <button
                    data-view="stakeholders"
                    className={ classes[ "second-view__button" ] }>
                    Stakeholders
                </button>
                <button
                    data-view="add by email"
                    className={ classes[ "second-view__button" ] }>
                    Add By Email
                </button>
            </div>

            { isFetchingRecipients ? (
                <ComponentLoading />
            ) : (
                    <form onSubmit={ (event) => event.preventDefault() } className={ classes[ "second-view__form" ] }>
                        { currentView === 'add by email' ? (
                            <div className={ classes[ "second-view__input-container" ] }>
                                <input
                                    type="text"
                                    onChange={ handleAddedByEmailChange }
                                    value={ recipientsAddedByEmail }
                                    name="recipientsAddedByEmail"
                                    className={ classes[ "second-view__input" ] }
                                    placeholder="Enter email addresses separated by commas" />

                                <div className={ classes[ "add-by-email-tags" ] }>
                                    { inputs?.recipientsAddedByEmail.map(email => {
                                        return (
                                            <div
                                                key={ email }
                                                className={ classes[ "add-by-email-tags__tag" ] }>
                                                { email }
                                                <button
                                                    onClick={ (event) => handleRemoveEmail(event, email) }
                                                    className={ classes[ "add-by-email-tags__remove-button" ] }>
                                                    <i className="las la-times"></i>
                                                </button>
                                            </div>
                                        );
                                    }) }

                                </div>
                            </div>
                        ) : (
                                <SelectPersonList handleInputChange={ handleInputChange } inputs={ inputs } people={ people } personType="customize-email-select-person" />
                            ) }
                    </form>
                ) }
        </div>
    )
}


export default SecondView;