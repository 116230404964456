import React, { Component } from "react";
import StatsTrialsSelectedSurveyEval from "../../../../Section/StatsTrialsSelectedSurveyEval";
import TrialsSurveyResponses from "../../../../Section/TrialsSurveyResponses";
import TrialsSurveyActionTabs from "../../../../Section/TrialsSurveyActionTabs";
import Preview from '../Preview/Preview';
import DuplicatePopUp from '../DuplicatePopUp/DuplicatePopUp';
import DeletePopUp from '../DeletePopUp/DeletePopUp';

import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { getRespondentsForSurveyAction, getSurveyTemplateAction,downloadBlankSurvey } from '../../../../Store/actions/surveysAction';
import { getRespondentsForEvaluationAction, getEvaluationTemplateAction,downloadBlankEvaluation } from '../../../../Store/actions/evaluationsAction';

class SurveysAndEvaluationsInformation extends Component {
    state = {
        surveyPreviewQuestionairePopupIsOpen: false,
        surveyAndEvaluationDuplicateFormPopUpIsOpen: false,
        surveyAndEvaluationDeletePopUpIsOpen: false
    }

    toggleSurveyAndEvaluationDuplicateFormPopUp = () => {
        this.setState({
            surveyAndEvaluationDuplicateFormPopUpIsOpen: !this.state.surveyAndEvaluationDuplicateFormPopUpIsOpen
        });
    }
    toggleSurveyAndEvaluationDeletePopUp = () => {
        this.setState({
            surveyAndEvaluationDeletePopUpIsOpen: !this.state.surveyAndEvaluationDeletePopUpIsOpen
        });
    }

    componentDidMount () {
        const { id, trialID, type } = this.props.match.params;
        
        if(type === 'survey') {
            this.props.getSurveyRespondents({ trialID, templateID: id });
            this.props.getSurvey({ trialID: trialID, templateID: id })
        } else {
            this.props.getEvaluationRespondents({ trialID, templateID: id });
            this.props.getEvaluation({ trialID: trialID, templateID: id })
        }
    }

    toggleSurveyPreviewQuestionairePopup = () => {
        this.setState({
            surveyPreviewQuestionairePopupIsOpen: !this.state.surveyPreviewQuestionairePopupIsOpen
        });
    }

    downloadBlankTemplate = () => {

        const { id, trialID, type } = this.props.match.params;
        
        if(type === 'survey') {
            this.props.getBlankSurvey({ trialID: trialID, templateID: id })
        } else {
            this.props.getBlankEvaluation({ trialID: trialID, templateID: id })

        }
    }
    render () {
        let pageURL = window.location.href;
        let lastURLSegment = pageURL.substr(pageURL.lastIndexOf('/') + 1).replace(/-/g, ' ');
        const trialSelected = lastURLSegment.replace(/\w\S*/g, w => (w.replace(/^\w/, c => c.toUpperCase())));
        const { type } = this.props.match.params;
        const { surveysRespondents, evaluationsRespondents, survey, evaluation } = this.props;
        const selectedTemplate = type === 'survey' ? survey : evaluation;
        const selectedRespondents = type === 'survey' ? surveysRespondents : evaluationsRespondents;
        return (
            <>
                <div className="page pb-80">
                    <div className="tab-title-survey">
                        <StatsTrialsSelectedSurveyEval
                            selectedSurveyEval={ selectedTemplate}
                            selectedRespondents={selectedRespondents }
                        />
                        <TrialsSurveyActionTabs
                            type={type}
                            resultsEnabled={selectedRespondents.filter(r => r.general_status === '1').length > 0}
                            selectedSurveyEval={ selectedTemplate}
                            openPreviewSurveyPopup={ this.toggleSurveyPreviewQuestionairePopup }
                            toggleSurveyAndEvaluationDuplicateFormPopUp={ this.toggleSurveyAndEvaluationDuplicateFormPopUp }
                            toggleSurveyAndEvaluationDeletePopUp={ this.toggleSurveyAndEvaluationDeletePopUp }
                            toggleSurveyCreateOrEditQuestionairePopup={this.toggleSurveyCreateOrEditQuestionairePopup}
                            trialSelected={ trialSelected }
                            deleteEnabled={selectedTemplate.created_by_id === localStorage.getItem('user_id')}
                            downloadBlankTemplate={this.downloadBlankTemplate}
                        />
                    </div>
                    <TrialsSurveyResponses
                        selectedRespondents={selectedRespondents}
                    />
                </div >

                
				{this.state.surveyPreviewQuestionairePopupIsOpen && (
					<Preview
						surveysAndEvaluations={ selectedTemplate}
						surveyPreviewQuestionairePopupIsOpen={this.state.surveyPreviewQuestionairePopupIsOpen}
						toggleSurveyPreviewQuestionairePopup={this.toggleSurveyPreviewQuestionairePopup}
					/>
                )}
                
                <DuplicatePopUp
                    templateName={selectedTemplate.template_name+ " copy"}
					duplicatingATemplate={true}
					surveyAndEvaluationDuplicateFormPopUpIsOpen={this.state.surveyAndEvaluationDuplicateFormPopUpIsOpen}
					toggleSurveyAndEvaluationDuplicateFormPopUp={this.toggleSurveyAndEvaluationDuplicateFormPopUp} />

                <DeletePopUp
                    templateName={selectedTemplate.template_name}
					surveyAndEvaluationDeletePopUpIsOpen={this.state.surveyAndEvaluationDeletePopUpIsOpen}
					toggleSurveyAndEvaluationDeletePopUp={this.toggleSurveyAndEvaluationDeletePopUp} />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        evaluation: state.evaluations.selectedTemplate,
        survey: state.surveys.selectedTemplate,
        surveysRespondents: state.surveys.surveysRespondents,
        evaluationsRespondents: state.evaluations.evaluationsRespondents,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getEvaluation: (parameters) => {
            dispatch(getEvaluationTemplateAction(parameters))

        },
        getSurvey: (parameters) => {
            dispatch(getSurveyTemplateAction(parameters))

        },
        getBlankEvaluation: (parameters) => {
            dispatch(downloadBlankEvaluation(parameters))

        },
        getBlankSurvey: (parameters) => {
            dispatch(downloadBlankSurvey(parameters))

        },

        getEvaluationRespondents: (parameters) => {
            dispatch(getRespondentsForEvaluationAction(parameters))
        },
        getSurveyRespondents: (parameters) => {
            dispatch(getRespondentsForSurveyAction(parameters))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SurveysAndEvaluationsInformation));