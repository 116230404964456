import React from 'react';
import classes from './Search.module.css';

const Search = ({ searchValue, onChange, handleClearButtonOnClick }) => {
	return (
		<div className={classes['search']}>
			<i className={[ classes['search__icon'], 'las la-search' ].join(' ')} />
			<input
				value={ searchValue }
				className={classes['search__input']}
				placeholder="Search... "
				type="text"
				onChange={ event => onChange(event.target.value) }
			/>

			<button
				type="button"
				onClick={ handleClearButtonOnClick }
				className={classes['search__clear-button']}>
				<i className={ [ classes[ 'search__clear-button-icon' ], 'las la-times-circle' ].join(' ') }></i>
			</button>
		</div>
	);
};

export default Search;
