import React, { useState } from 'react';
import classes from '../FormQuestions.module.css';

const RatingQuestion = ({
	question,
	questionNumber,
	answeredQuestionsIDS,
	selectAnswer,
	isReadOnly,
	containsError
}) => {
	const IS_READ_ONLY = isReadOnly;

	const [ selectedOptions, setSelectedOptions ] = useState([]);

	const handleSelect = (optionId) => {
		let updatedSelection = selectedOptions;
		if (updatedSelection.includes(optionId)) {
			updatedSelection = updatedSelection.filter((option) => option !== optionId);
		} else {
			updatedSelection = [ optionId ];
		}
		setSelectedOptions(updatedSelection);
		selectAnswer(question.id, updatedSelection);
	};

	return (
		<div className={[ classes['form-survey__group'], containsError ? classes['form_input_error'] : '' ].join(' ')}>
			<h3 className={classes['form-survey__question']}>
				{questionNumber + 1}. {question.questions} {question.is_required === '1' ? <span>*</span> : null}
			</h3>
			<div>
				<div className={classes['form-survey__options-container']}>
					<label className={classes['form-survey__option-label']} htmlFor={`option-${question.questions}`}>
						{question.min_rating_label}
					</label>

					<label className={classes['form-survey__option-label']} htmlFor={`option-${question.questions}`}>
						{question.max_rating_label}
					</label>
				</div>
				<div className={classes['form-survey__options']}>
					{question.question_options.map((answer) => {
						let isAnswered =
							answeredQuestionsIDS.includes(answer.option_id) ||
							selectedOptions.includes(answer.option_id);
						return (
							<div key={answer.option_id}>
								<input
									className={classes['form-survey__input-radio']}
									id={`option-${answer.option_id}`}
									name={`option-${answer.option_id}`}
									type="radio"
									checked={isAnswered}
									readOnly={IS_READ_ONLY}
									onClick={() => handleSelect(answer.option_id)}
								/>
								<label
									htmlFor={`option-${answer.option_id}`}
									className={classes['form-survey__option-radio']}
								/>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default RatingQuestion;
