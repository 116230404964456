import React from 'react';

import EmailPreview from '../../EmailPreview/EmailPreview';
import WeeklyEngagementEndoscopy from './EmailPreview/WeeklyEngagementEndoscopy';
import TrialRecapUpdateCommunications from './EmailPreview/TrialRecapUpdateCommunications';

import classes from '../CustomizeEmail.module.css';

const ThirdView = ({ inputs, engagementEmailDataFromAPI }) => {
    let formatData = {
        content_json: inputs,
        template_type: engagementEmailDataFromAPI.template_type
    }

    const selectEmailTemplateToDisplay = () => {
        if (engagementEmailDataFromAPI?.template_type === 'weekly_engagement')
        {
            return <WeeklyEngagementEndoscopy emailPreview={ inputs } />
        } else if (engagementEmailDataFromAPI?.template_type === 'integration_trial_complete')
        {
            return <TrialRecapUpdateCommunications emailPreview={ inputs } />
        }
        else
        {
            return <EmailPreview emailPreview={ formatData } />
        }
    }

    return (
        <div>
            <div className={ classes[ "section" ] }>
                <div className={ classes[ "label" ] }>
                    email template
                    <div className={ classes[ "label__horizontal-line" ] }></div>
                </div>
                <div className={ classes[ "section__text" ] }>{ engagementEmailDataFromAPI.admin_template_name } </div>
            </div>
            <div className={ classes[ "section" ] }>
                <div className={ classes[ "label" ] }>
                    email name
                    <div className={ classes[ "label__horizontal-line" ] }></div>
                </div>
                <div className={ classes[ "section__text" ] }>{ inputs.emailName }</div>
            </div>
            <div className={ classes[ "section" ] }>
                <div className={ classes[ "label" ] }>
                    subject line
                    <div className={ classes[ "label__horizontal-line" ] }></div>
                </div>
                <div className={ classes[ "section__text" ] }>{ inputs.subjectLine }</div>
            </div>
            <div className={ classes[ "section" ] }>
                <div className={ classes[ "label" ] }>
                    recipients
                    <div className={ classes[ "label__horizontal-line" ] }></div>
                </div>
                <div className={ classes[ "section__text" ] }>
                    { inputs.recipients.map(person => {
                        return (
                            <div key={ person.id }>
                                <span className={ classes[ "review-and-confirm__person-name" ] }>{ person.name }</span> ({ person.email })
                            </div>
                        );
                    }) }

                    { inputs.recipientsAddedByEmail.map(email => <div key={ email }>{ email }</div>) }
                </div>
            </div>
            <div className="preview-email-container customize-email__preview-wrapper">
                { selectEmailTemplateToDisplay() }
            </div>
        </div>
    )
}

export default ThirdView;