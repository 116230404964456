import React, { useState } from 'react';

import AddCustomer from '../MultipleStepsModalsForm/AddCustomer/AddCustomer';

import { getCustomerDetailsAction } from '../../../Store/actions/trialsAction';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const CustomerDetails = (props) => {
	const { customer } = props;

	const [ currentlyEditingCustomerInformation, setCurrentlyEditingCustomerInformation ] = useState(false);
	const toggleCurrentlyEditingCustomerInforamation = () => setCurrentlyEditingCustomerInformation(previousCurrentlyEditingCustomerInformation => !previousCurrentlyEditingCustomerInformation)

	const { id } = useParams();
	const dispatch = useDispatch();
	const callbackAfterSuccessfulUpdate = () => {
		dispatch(getCustomerDetailsAction({ customerId: id }));
		setCurrentlyEditingCustomerInformation(false);
	}

	let swappingElement;

	if (currentlyEditingCustomerInformation) {
		swappingElement = (
			<AddCustomer callback={ callbackAfterSuccessfulUpdate } isInModal={ false } prefilledData={ customer } />
		);
	} else {
		swappingElement = (
			<React.Fragment>
				<div className="line-grey-original" />
				<ul className="dashboard-stats customer__information">
					<li className="my-stats">
						ADDRESS
					<div className="stats-survey-date">{ customer.address }</div>
					</li>
					<li className="my-stats">
						ADDRESS 2
					<div className="stats-survey-date">{ customer.address2 }</div>
					</li>
				</ul>
				<ul className="dashboard-stats customer__information">
					<li className="my-stats">
						CITY
					<div className="stats-survey-date">{ customer.city }</div>
					</li>
					<li className="my-stats">
						STATE
					<div className="stats-survey-date">{ customer.state_name }</div>
					</li>
					<li className="my-stats">
						ZIP CODE
					<div className="stats-survey-date">{ customer.zipcode }</div>
					</li>
				</ul>

				<ul className="dashboard-stats customer__information">
					<li className="my-stats">
						CUSTOMER #
					<div className="stats-survey-date">{ customer.account_number }</div>
					</li>
					<li className="my-stats">
						WEBSITE
					<div className="stats-survey-date">{ customer.website }</div>
					</li>
					<li className="my-stats">
						PHONE
					<div className="stats-survey-date">{ customer.phone }</div>
					</li>
				</ul>
			</React.Fragment>
		)
	}

	const [ isEditMenuOpen, setIsEditMenuOpen ] = useState(false);
	
	return (
		<div className="customer-information-header">
			<ul className="stats-myName">
				<li className="myStats-title"> {customer.name}</li>
			</ul>
			<div className="line-blk-original" />

			<div
				onClick={ () => setIsEditMenuOpen(previousIsEditMenuOpen => !previousIsEditMenuOpen) } 
				className="person-information-header-menu-container">
				<button className="person-information-header__menu-button-icon">
					<i className="las la-ellipsis-h person-information-header__menu-icon"></i>
				</button>

				{ isEditMenuOpen ? (
					<div className="person-information-header-menu">
						<button
							onClick={ toggleCurrentlyEditingCustomerInforamation } 
							className="customer-information-header-menu__text">
							edit
						</button>
					</div>
				) : (null) }
			</div>

			<div className="dashboard-stats">
				<div className="stats-survey">
					TOTAL CASES<br />
					<span className="number">{ customer.total_cases }</span>
				</div>
				<div className="stats-survey">
					TOTAL TRIALS<br />
					<span className="number">{ customer.total_trial }</span>
				</div>
			</div>

			{ swappingElement }
		</div>
	);
};

export default CustomerDetails;
