import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import OverlayHeaderForPopups from '../../../../Components/OverlayHeaderForPopups/OverlayHeaderForPopups';
import FormQuestions from '../FormQuestions/FormQuestions';

import classes from './Preview.module.css';

class Preview extends Component {
	render() {
		const {
			surveyPreviewQuestionairePopupIsOpen,
			toggleSurveyPreviewQuestionairePopup,
			surveysAndEvaluations
		} = this.props;
		console.log(surveysAndEvaluations);
		let questionsArray = surveysAndEvaluations.question_list;

		return (
			<React.Fragment>
				<Modal
					id="form-survey"
					className={classes['form-survey-container']}
					isOpen={surveyPreviewQuestionairePopupIsOpen}
					toggle={toggleSurveyPreviewQuestionairePopup}
					backdrop="static"
				>
					<OverlayHeaderForPopups
						title="Preview"
						togglePopup={toggleSurveyPreviewQuestionairePopup}
						buttonSubmitCallback={false}
					/>
					<FormQuestions questionsArray={questionsArray} />
				</Modal>
			</React.Fragment>
		);
	}
}

export default Preview;
