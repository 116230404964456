import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { Modal, Form, FormGroup, Label, Input, InputGroupAddon, InputGroupText } from 'reactstrap';
import OverlayHeaderForPopups from '../Components/OverlayHeaderForPopups/OverlayHeaderForPopups';
import ErrorMessage from '../Components/ErrorMessage/ErrorMessage';

import { postLoginAction, updateUserInformationAction } from '../Store/actions/authActions';
import { connect } from 'react-redux';

import ProfileImage from '../Utils/ProfileImage';
import formatPhoneNumber from '../Utils/formatPhoneNumber';

class Profile extends Component {
	state = {
		email: '',
		firstName: '',
		lastName: '',
		number: '',
		profileImage: '',
		populateOnce: false,
		formValidation: {},
		tempUser: ''
	};

	static getDerivedStateFromProps(props, state) {
		if (state.populateOnce === false && props.userData.firstName) {
			let { email, firstName, lastName, phone, profileImage } = props.userData;

			return {
				email: email,
				firstName: firstName,
				lastName: lastName,
				number: phone,
				profileImage: profileImage,
				populateOnce: true
			};
		}

		return null;
	}

	componentDidMount() {
		this.props.postLogin(localStorage.getItem('email'), false);
	}

	handleChange = (ev) => {
		this.setState({ editing: true });
		if (ev.currentTarget.id === 'number') {
			let phoneNumber = formatPhoneNumber(ev.currentTarget.value);
			if (phoneNumber !== false) {
				return this.setState({ number: phoneNumber });
			}

			return;
		}

		if (ev.currentTarget.id === 'email') {
			return;
		}

		this.setState({
			[ev.currentTarget.id]: ev.currentTarget.value
		});
	};

	handleSubmit = (ev) => {
		ev.preventDefault();
		this.setState({ editing: false });

		let formValidation = {};

		if (this.state.firstName === '') {
			formValidation.firstName = 'Missing First Name';
		}

		if (this.state.lastName === '') {
			formValidation.lastName = 'Missing Last Name';
		}

		if (this.state.number.replace(/\D/gm, '').length !== 10) {
			formValidation.number = 'Invalid Phone Number';
		}

		if (formValidation.firstName || formValidation.lastName || formValidation.number) {
			return this.setState({ formValidation: formValidation });
		}

		let formData = new FormData();

		formData.append('first_name', this.state.firstName);
		formData.append('last_name', this.state.lastName);
		formData.append('phone', this.state.number);
		formData.append('profile_image', this.state.profileImage);
		formData.append('user_id', localStorage.getItem('user_id'));
		this.props.updateUserInformation({ formData, toggleMyProfileModal: this.props.toggleMyProfileModal });
	};

	render() {
		const { isMyProfileModalOpen, toggleMyProfileModal, error, postUserInformationError } = this.props;

		if (error) return <Redirect to="/auth" />;

		return (
			<Modal
				id="profile"
				className="profile"
				isOpen={isMyProfileModalOpen}
				toggle={toggleMyProfileModal}
				backdrop="static"
			>
				<OverlayHeaderForPopups
					title="My profile"
					togglePopup={toggleMyProfileModal}
					buttonSubmitCallback={this.handleSubmit}
					editing={this.state.editing}
				/>

				<div className="flex-center profile__display-information">
					<ProfileImage
						profile={{ profileImage: this.state.profileImage }}
						cb={null}
						profileImageClasses="profile__display-information-profile-image"
					/>
					<h3 className="profile__display-information-name">
						<span>{this.state.firstName}</span> <span>{this.state.lastName}</span>
					</h3>
				</div>

				<Form id="form" className="profile-form">
					<FormGroup>
						<Label className="profile-form__label" for="firstName">
							FIRST NAME
						</Label>
						<Input
							className="profile-form__input"
							type="text"
							name="firstName"
							id="firstName"
							onChange={this.handleChange}
							placeholder="First Name"
							value={this.state.firstName}
						/>

						{this.state.formValidation.firstName ? (
							<ErrorMessage
								message={this.state.formValidation.firstName}
								customClasses="justify-content-start py-2 px-0"
							/>
						) : null}
					</FormGroup>
					<FormGroup>
						<Label className="profile-form__label" for="lastName">
							LAST NAME
						</Label>
						<Input
							className="profile-form__input"
							type="text"
							name="lastName"
							id="lastName"
							onChange={this.handleChange}
							placeholder="Last Name"
							value={this.state.lastName}
						/>

						{this.state.formValidation.lastName ? (
							<ErrorMessage
								message={this.state.formValidation.lastName}
								customClasses="justify-content-start py-2 px-0"
							/>
						) : null}
					</FormGroup>
					<FormGroup>
						<Label className="profile-form__label" for="number">
							NUMBER
						</Label>
						<Input
							className="profile-form__input"
							type="text"
							name="number"
							id="number"
							onChange={this.handleChange}
							placeholder="(000) 000-0000"
							value={this.state.number}
						/>

						{this.state.formValidation.number ? (
							<ErrorMessage
								message={this.state.formValidation.number}
								customClasses="justify-content-start py-2 px-0"
							/>
						) : null}
					</FormGroup>
					<FormGroup>
						<Label className="profile-form__label" for="email">
							EMAIL
						</Label>
						<InputGroupAddon addonType="append">
							<Input
								className="profile-form__input"
								type="email"
								name="email"
								id="email"
								onChange={this.handleChange}
								placeholder="first.last@stryker.com"
								disabled={true}
								value={this.state.email}
							/>
							<InputGroupText className="profile-form__icon">
								<i className="las la-lock" />
							</InputGroupText>
						</InputGroupAddon>
					</FormGroup>
				</Form>

				{localStorage.getItem('user_type') === 'admin' && (
					<div className="adminLogin">
						<h4>LOGIN AS USER</h4>
						<input
							value={this.state.tempUser}
							onChange={(e) => {
								this.setState({ tempUser: e.target.value });
							}}
						/>
						<button
							onClick={() => {
								this.props.postLogin(this.state.tempUser, true);
							}}
						>
							LOGIN
						</button>
					</div>
				)}
				{postUserInformationError ? <ErrorMessage message={postUserInformationError} /> : null}
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userData: state.auth.userData,
		error: state.auth.error,
		postUserInformationError: state.auth.postUserInformationError
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		postLogin: (email, redirect) => {
			dispatch(postLoginAction(email, redirect));
		},

		updateUserInformation: (parameters) => {
			dispatch(updateUserInformationAction(parameters));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
