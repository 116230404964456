const createImageURL = (item) => {
	let imageURL = `${process.env.REACT_APP_API_URL}images/competitors/`;
	let IMAGE_URL_CREATED = false;

	if (!item.icon_selected || !item.icon) {
		let imageName = item.name.toLowerCase().replace(' ', '-').replace('&', '-');
		imageURL = imageURL + imageName + '.png';
		IMAGE_URL_CREATED = true;
	} else {
		if (item.is_selected === '1') {
			imageURL = item.icon_selected;
		} else {
			imageURL = item.icon;
		}
	}

	return [ imageURL, IMAGE_URL_CREATED ];
};

export default createImageURL;
