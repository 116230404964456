import React from 'react';

import LogoPreview from '../../Shared/LogoPreview/LogoPreview';
import Footer from '../../Shared/Footer/Footer';
import AddDashedButton from '../../../../../Components/AddDashedButton/AddDashedButton';

import TrialRecapUpdateClasses from '../TrialRecapUpdate/TrialRecapUpdate.module.css';
import classes from './WeeklyEngagement.module.css';
import emailPreviewClasses from '../EmailPreview.module.css'

const WeeklyEngagement = ({ emailPreview }) => {
    const emailPreviewContent = emailPreview?.content?.split(/[\r\n]+/g);

    return (
        <div>
            <LogoPreview />

            <div className={ classes[ "weekly-engagement__header" ] }>
                <h2 className={ classes[ "weekly-engagement__header-heading" ] }>
                    { emailPreview.title } <span className={ classes[ "weekly-engagement__header-heading-subtext" ] }> { emailPreview.sub_title } </span>
                </h2>
                <div className={ classes[ "weekly-engagement__header-image-container" ] }>
                    <img
                        className={ classes[ "weekly-engagement__header-image" ] }
                        src="/images/email/popup-email-bg.png"
                        alt="workstation" />
                </div>
                <p className={ classes[ "weekly-engagement__header-text" ] }>
                    { emailPreview.title_description }
                </p>
            </div>

            <div className={ classes[ "weekly-engagement__paragraph-container" ] }>
                { emailPreviewContent?.map((item, index) => {
                    return (
                        <p
                            key={ index }
                            className={ emailPreviewClasses[ "email-preview__paragraph" ] }>
                            { item }
                        </p>
                    );
                }) }
            </div>

            <div className={ classes[ "weekly-engagement__section" ] }>
                <h2 className={ classes[ "weekly-engagement__section-heading" ] }>
                    Weekly <br /> <span className={ classes[ "weekly-engagement__section-heading-subtext" ] }>Statistics</span>
                </h2>
                <div className={ [ emailPreviewClasses[ "ribbon" ], "m-0" ].join(" ") }></div>
            </div>

            <div className={ TrialRecapUpdateClasses[ "trial-recap__pie-chart-container" ] }>
                <div className={ TrialRecapUpdateClasses[ "trial-recap__pie-chart-image-container" ] }>
                    <img className={ TrialRecapUpdateClasses[ "trial-recap__pie-chart-image" ] }
                        src="/images/email/stat-graph01.png"
                        alt="pie chart" />
                </div>

                <div className={ TrialRecapUpdateClasses[ "trial-recap__pie-chart" ] }>
                    <p className={ TrialRecapUpdateClasses[ "trial-recap__question" ] }>
                        Which activities do you believe take time away from patient care?
                    </p>
                    <div className={ TrialRecapUpdateClasses[ "trial-recap__pie-chart-label-container" ] }>
                        <div className={ [ TrialRecapUpdateClasses[ "trial-recap__pie-chart-label-percentage" ], TrialRecapUpdateClasses[ "trial-recap__pie-chart-label-percentage--purple-one" ] ].join(" ") }>45%</div>
                        <div className={ TrialRecapUpdateClasses[ "trial-recap__pie-chart-label-text" ] }>
                            Pre-operative device setup
                        </div>
                    </div>
                    <div className={ TrialRecapUpdateClasses[ "trial-recap__pie-chart-label-container" ] }>
                        <div className={ [ TrialRecapUpdateClasses[ "trial-recap__pie-chart-label-percentage" ], TrialRecapUpdateClasses[ "trial-recap__pie-chart-label-percentage--purple-two" ] ].join(" ") }>45%</div>
                        <div className={ TrialRecapUpdateClasses[ "trial-recap__pie-chart-label-text" ] }>
                            Pre-operative device setup
                        </div>
                    </div>
                    <div className={ TrialRecapUpdateClasses[ "trial-recap__pie-chart-label-container" ] }>
                        <div className={ [ TrialRecapUpdateClasses[ "trial-recap__pie-chart-label-percentage" ], TrialRecapUpdateClasses[ "trial-recap__pie-chart-label-percentage--purple-three" ] ].join(" ") }>45%</div>
                        <div className={ TrialRecapUpdateClasses[ "trial-recap__pie-chart-label-text" ] }>
                            Pre-operative device setup
                        </div>
                    </div>
                    <div className={ TrialRecapUpdateClasses[ "trial-recap__pie-chart-label-container" ] }>
                        <div className={ [ TrialRecapUpdateClasses[ "trial-recap__pie-chart-label-percentage" ], TrialRecapUpdateClasses[ "trial-recap__pie-chart-label-percentage--purple-four" ] ].join(" ") }>45%</div>
                        <div className={ TrialRecapUpdateClasses[ "trial-recap__pie-chart-label-text" ] }>
                            Pre-operative device setup
                        </div>
                    </div>
                </div>
            </div>

            <div className={ TrialRecapUpdateClasses[ "trial-recap__horizontal-bar-graph-container" ] }>
                <img
                    className={ TrialRecapUpdateClasses[ "trial-recap__horizontal-bar-graph-image" ] }
                    src="/images/email/stat-graph03.png"
                    alt="bar graph" />
            </div>

            <div className={ [ TrialRecapUpdateClasses[ "trial-recap__pie-chart-container" ], "mb-5" ].join(" ") }>
                <div className={ TrialRecapUpdateClasses[ "trial-recap__pie-chart-image-container" ] }>
                    <img
                        className={ TrialRecapUpdateClasses[ "trial-recap__pie-chart-image" ] }
                        src="/images/email/stat-graph04.png"
                        alt="pie chart" />
                </div>

                <div className={ TrialRecapUpdateClasses[ "trial-recap__pie-chart" ] }>
                    <p className={ TrialRecapUpdateClasses[ "trial-recap__question" ] }>
                        Which activities do you believe take time away from patient care?
                    </p>
                    <div className={ TrialRecapUpdateClasses[ "trial-recap__pie-chart-label-container" ] }>
                        <div className={ [ TrialRecapUpdateClasses[ "trial-recap__pie-chart-label-percentage" ], TrialRecapUpdateClasses[ "trial-recap__pie-chart-label-percentage--green-one" ] ].join(" ") }>12%</div>
                        <div className={ TrialRecapUpdateClasses[ "trial-recap__pie-chart-label-text" ] }>
                            Printed
                        </div>
                    </div>
                    <div className={ TrialRecapUpdateClasses[ "trial-recap__pie-chart-label-container" ] }>
                        <div className={ [ TrialRecapUpdateClasses[ "trial-recap__pie-chart-label-percentage" ], TrialRecapUpdateClasses[ "trial-recap__pie-chart-label-percentage--green-two" ] ].join(" ") }>88%</div>
                        <div className={ TrialRecapUpdateClasses[ "trial-recap__pie-chart-label-text" ] }>
                            Sent electronically to you
                        </div>
                    </div>
                </div>
            </div>

            <div className={ classes[ "weekly-engagement__button-container" ] }>
                <AddDashedButton text="Add another statistic" />
            </div>


            <div className={ classes[ "weekly-engagement__mission" ] }>
                <div className={ classes[ "weekly-engagement__mission-title" ] }>
                    { emailPreview.mission_title }
                </div>

                <div className={ classes[ "weekly-engagement__mission-text" ] }>
                    { emailPreview.mission_description }
                </div>
            </div>

            <Footer text={ emailPreview.footer } />
        </div>
    )
}

export default WeeklyEngagement;