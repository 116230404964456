import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import _ from 'lodash';
import Search from '../../../../Utils/Search';
import Paginate from '../../../../Utils/Paginate';
import Pagination from '../../../../Components/Pagination';
import SurveysAndEvaluationsView from './Views/SurveysAndEvaluationsView';
import classes from './SurveysAndEvaluations.module.css';
import { getSurveysAction, getTemplatesAction, getCrushTemplatesAction } from '../../../../Store/actions/surveysAction';
import TemplatesView from './Views/TemplatesView';
import { connect } from 'react-redux';
import Create from '../CreateOrEdit/Create';
import { withRouter } from 'react-router-dom';
import LoadMoreButton from '../../../../Components/LoadMoreButton/LoadMoreButton';
import NoResults from '../../../../Components/NoResults/NoResults';

class Surveys extends Component {
	state = {
		currentView: 0,
		sortColumn: {
			path: 'template_name',
			order: 'asc'
		},

		searchValue: '',
		surveyCreateOrEditQuestionairePopupIsOpen: false
	};

	componentDidMount = () => {
		const params = new URLSearchParams(this.props.location.search);
		const section = params.get('section');
		if (section !== null) {
			this.handleTabView(parseInt(section));
		} else {
			params.append('section', 0);
			window.location.replace(window.location.pathname + '?' + params.toString());
		}
		//this.props.getSurveys({ trialID: this.props.trialID, page: this.props.surveysPage });
	};

	handleSort = (path) => {
		const sortColumn = JSON.parse(JSON.stringify(this.state.sortColumn));

		if (sortColumn.order === 'asc') {
			this.setState({ sortColumn: { path, order: 'desc' } });
		} else if (sortColumn.order === 'desc') {
			this.setState({ sortColumn: { path, order: 'asc' } });
		}
	};

	handleInputChange = (event) => {
		this.setState({
			searchValue: event.currentTarget.value
		});
	};

	filterSurveysBySearch = () => {
		if (this.state.currentView === 0) {
			return this.props.surveys.filter((survey) => {
				return survey.template_name.toLowerCase().includes(this.state.searchValue.toLowerCase().trim());
			});
		} else if (this.state.currentView === 1) {
			return this.props.templates.filter((template) => {
				return template.template_name.toLowerCase().includes(this.state.searchValue.toLowerCase().trim());
			});
		} else {
			return this.props.crushTemplates.filter((crushTemplate) => {
				return crushTemplate.template_name.toLowerCase().includes(this.state.searchValue.toLowerCase().trim());
			});
		}
	};

	handlePageChange = (page, viewToPaginate) => {
		this.setState({
			[viewToPaginate]: page
		});
	};

	handleTabView = (currentView) => {
		switch (currentView) {
			case 0:
				this.props.getSurveys({ trialID: this.props.trialID, page: this.props.surveysPage });
				break;
			case 1:
				this.props.getSurveyTemplates({ trialID: this.props.trialID, page: this.props.templatesPage });
				break;
			case 2:
				this.props.getSurveyCrushTemplates({
					trialID: this.props.trialID,
					page: this.props.crushTemplatesPage
				});
				break;
		}
		this.setState({ currentView: currentView });
	};

	chooseSection = (selectedSection) => {
		const params = new URLSearchParams(this.props.location.search);
		const section = params.get('section');
		if (!section) {
			params.append('section', selectedSection);
		} else {
			params.set('section', selectedSection);
		}

		window.location.replace(window.location.pathname + '?' + params.toString());
	};

	toggleSurveyCreateOrEditQuestionairePopup = () => {
		this.setState({
			surveyCreateOrEditQuestionairePopupIsOpen: !this.state.surveyCreateOrEditQuestionairePopupIsOpen
		});
	};

	loadMoreSurveys = () => {
		this.props.getSurveys({ trialID: this.props.trialID, page: this.props.surveysPage });

		this.setState({
			searchValue: ''
		});
	};

	loadMoreTemplates = () => {
		this.props.getSurveyTemplates({ trialID: this.props.trialID, page: this.props.templatesPage });

		this.setState({
			searchValue: ''
		});
	};

	loadMoreCrushTemplates = () => {
		this.props.getSurveyCrushTemplates({ trialID: this.props.trialID, page: this.props.crushTemplatesPage });

		this.setState({
			searchValue: ''
		});
	};
	render() {
		const {
			isFetchingSurveys,
			surveys,
			surveysPageLimitReached,
			isFetchingSurveyTemplates,
			templates,
			templatesPageLimitReached,
			isFetchingSurveyCrushTemplates,
			crushTemplates,
			crushTemplatesPageLimitReached
		} = this.props;

		const filterSurveysBySearch = this.filterSurveysBySearch();

		return (
			<React.Fragment>
				<Container className={classes['tab-title-main']}>
					<Row>
						<Col>
							<div className="d-flex justify-content-between align-items-center">
								<h4 className="section-title trials">Surveys</h4>
								<button
									className="button-open-modal"
									onClick={this.toggleSurveyCreateOrEditQuestionairePopup}
								>
									<img
										src="/images/icon-plus.png"
										className="button-open-modal__image"
										alt="plus symbol"
									/>
								</button>
							</div>
						</Col>
					</Row>
					<div className="nav-pagelink">
						<div
							onClick={() => this.chooseSection(0)}
							className={0 === this.state.currentView ? 'pagelink-sml active' : 'pagelink-sml inactive'}
						>
							Surveys
						</div>
						<div
							onClick={() => this.chooseSection(1)}
							className={1 === this.state.currentView ? 'pagelink-sml active' : 'pagelink-sml inactive'}
						>
							My Templates
						</div>
						<div
							onClick={() => this.chooseSection(2)}
							className={2 === this.state.currentView ? 'pagelink-sml active' : 'pagelink-sml inactive'}
						>
							Crush Templates
						</div>
					</div>
					<div className="hr-bk-expand-ml-mr-15" />
					<Search searchingValue={this.state.searchValue} handleInputChange={this.handleInputChange} />
				</Container>

				{this.state.currentView === 0 ? (
					<React.Fragment>
						<SurveysAndEvaluationsView
							surveysAndEvaluations={filterSurveysBySearch}
							onSort={this.handleSort}
							type="surveys"
							isFetchingSurveys={isFetchingSurveys}
						/>
						{filterSurveysBySearch.length === 0 && !isFetchingSurveys ? (
							<NoResults message="Add templates from crush templates to your trial" />
						) : null}
						{!surveysPageLimitReached &&
						surveys.length > 0 &&
						!isFetchingSurveys && <LoadMoreButton handleClick={this.loadMoreSurveys} />}
					</React.Fragment>
				) : null}

				{this.state.currentView === 1 ? (
					<React.Fragment>
						<TemplatesView
							templates={filterSurveysBySearch}
							onSort={this.handleSort}
							view="template-surveys"
							isFetchingSurveyTemplates={isFetchingSurveyTemplates}
						/>
						{filterSurveysBySearch.length === 0 && !isFetchingSurveyTemplates ? <NoResults /> : null}
						{!templatesPageLimitReached &&
						templates.length > 0 &&
						!isFetchingSurveyTemplates && <LoadMoreButton handleClick={this.loadMoreTemplates} />}
					</React.Fragment>
				) : null}

				{this.state.currentView === 2 ? (
					<React.Fragment>
						<TemplatesView
							templates={filterSurveysBySearch}
							onSort={this.handleSort}
							view="template-surveys"
							isFetchingSurveyCrushTemplates={isFetchingSurveyCrushTemplates}
						/>
						{filterSurveysBySearch.length === 0 && !isFetchingSurveyCrushTemplates ? <NoResults /> : null}
						{!crushTemplatesPageLimitReached &&
						crushTemplates.length > 0 &&
						!isFetchingSurveyCrushTemplates && <LoadMoreButton handleClick={this.loadMoreCrushTemplates} />}
					</React.Fragment>
				) : null}

				{this.state.surveyCreateOrEditQuestionairePopupIsOpen ? (
					<Create
						type="survey"
						surveyCreateOrEditQuestionairePopupIsOpen={this.state.surveyCreateOrEditQuestionairePopupIsOpen}
						toggleSurveyCreateOrEditQuestionairePopup={this.toggleSurveyCreateOrEditQuestionairePopup}
					/>
				) : null}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		isFetchingSurveys: state.surveys.isFetchingSurveys,
		surveys: state.surveys.surveys,
		surveysPage: state.surveys.surveysPage,
		surveysPageLimitReached: state.surveys.surveysPageLimitReached,
		surveysError: state.surveys.surveysError,

		isFetchingSurveyTemplates: state.surveys.isFetchingSurveyTemplates,
		templates: state.surveys.templates,
		templatesPage: state.surveys.templatesPage,
		templatesPageLimitReached: state.surveys.templatesPageLimitReached,
		templatesError: state.surveys.templatesError,

		isFetchingSurveyCrushTemplates: state.surveys.isFetchingSurveyCrushTemplates,
		crushTemplates: state.surveys.crushTemplates,
		crushTemplatesPage: state.surveys.crushTemplatesPage,
		crushTemplatesPageLimitReached: state.surveys.crushTemplatesPageLimitReached,
		crushTemplatesError: state.surveys.crushTemplatesError,

		surveyResults: state.surveys.surveyResults,
		surveyResultsPage: state.surveys.surveyResultsPage,
		surveyResultsPageLimitReached: state.surveys.surveyResultsPageLimitReached,
		surveyResultsError: state.surveys.surveyResultsError
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getSurveys: ({ trialID, page }) => {
			dispatch(getSurveysAction({ trialID, page }));
		},

		getSurveyTemplates: ({ trialID, page }) => {
			dispatch(getTemplatesAction({ trialID, page }));
		},

		getSurveyCrushTemplates: ({ trialID, page }) => {
			dispatch(getCrushTemplatesAction({ trialID, page }));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Surveys));
