import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import ProfileImage from '../../../Utils/ProfileImage';
import LevelOfInfluence from '../../../Utils/LevelOfInfluence/LevelOfInfluence';

import {
	deleteSurgeonAction,
	deleteStakeholderAction,
	getSpecialitiesAction,
	getStakeholderTitlesAction,
	postSurgeonAction,
	postStakeholderAction,
	getTrialPeopleStakeholderOrSurgeonAction
} from '../../../Store/actions/trialsAction';
import { useDispatch, useSelector } from 'react-redux';

import useHandleSubmit from '../../../Hooks/useHandleSubmit';

const StatsMyHistory = (props) => {
	const { person } = props;

	const { id } = useParams();
	useEffect(() => {
		dispatch(getStakeholderTitlesAction(id || person.trialID));
		dispatch(getSpecialitiesAction());
	}, []);

	const specialitiesArray = useSelector((state) => state.trials.specialitiesArray.speciality);
	const specialitiesError = useSelector((state) => state.trials.specialitiesError);

	const stakeholderTitlesArray = useSelector((state) => state.trials.stakeholderTitlesArray);
	const stakeholderTitlesError = useSelector((state) => state.trials.stakeholderTitlesError);

	const [ isEditOrDeleteMenuOpen, setIsEditOrDeleteMenuOpen ] = useState(false);
	const [ currentlyEditingPerson, setCurrentlyEditingPerson ] = useState(false);

	const dispatch = useDispatch();
	const history = useHistory();
	const handleDeletePerson = () => {
		const redirect = () => history.goBack();
		if (person.title === 'surgeons') {
			return dispatch(deleteSurgeonAction({ trialID: id, personID: person.id, callback: redirect }));
		}
		return dispatch(deleteStakeholderAction({ trialID: id, personID: person.id, callback: redirect }));
	};

	const handleSuccessfullyUpdatedSurgeonOrStakeholderRequest = () => {
		setCurrentlyEditingPerson(false);
		setIsEditOrDeleteMenuOpen(false);
		dispatch(getTrialPeopleStakeholderOrSurgeonAction(person.id, person.title));
	};

	const handleCustomFormSubmit = () => {
		let formData = new FormData();
		formData.append('email', inputs.email);
		formData.append('secondary_email', person.secondaryEmail);
		formData.append('mobile_phone', inputs.mobile_phone);
		formData.append('work_phone', inputs.work_phone);
		formData.append('rating', inputs.rating);
		formData.append('facility', '');
		formData.append('customer_id', person.customerID);
		formData.append('first_name', inputs.firstName);
		formData.append('last_name', inputs.lastName);
		formData.append('trial_id', id || person.trialID);
		formData.append('user_id', localStorage.getItem('user_id'));
		if (inputs.personTitleAndID !== '') {
			let [ personID, title ] = inputs.personTitleAndID.split('-');

			if (person.title === 'surgeons') {
				formData.append('specialty_id', personID);
			} else {
				formData.append('title', title);
				formData.append('title_id', personID);
			}
		}

		if (person.title === 'surgeons') {
			formData.append('surgeon_id', person.id);
			dispatch(postSurgeonAction({ formData, callback: handleSuccessfullyUpdatedSurgeonOrStakeholderRequest }));
		} else {
			formData.append('stakeholder_id', person.id);
			dispatch(
				postStakeholderAction({ formData, callback: handleSuccessfullyUpdatedSurgeonOrStakeholderRequest })
			);
		}
	};

	const { inputs, handleInputChange, handleSubmit } = useHandleSubmit({
		callback: handleCustomFormSubmit,
		initialState: {
			email: person.email,
			firstName: person.firstName,
			lastName: person.lastName,
			mobile_phone: person.mobilePhone,
			work_phone: person.workPhone,
			rating: person.rating,
			personTitleAndID: `${person.personTitleID}-${person.personTitle}`
		}
	});

	window.onclick = (e) => {
		if(!e.target.classList.contains("la-ellipsis-h")) {
			setIsEditOrDeleteMenuOpen(false)
		}
	}

	return (
		<div className="person-information-header">
			<ul className="stats-myName">
				<li className="d-flex align-items-center justify-content-center">
					<ProfileImage profile={person} cb={null} profileImageClasses="" />
				</li>
				<li className="myStats-title">
					{' '}
					{person.firstName} {person.lastName}
				</li>
				{/* <li className="myStats-dots"><img src={ person.thumbnail } className="dots-img-original" alt="" /></li> */}
				<li className="myStats-category">{person.category}</li>
			</ul>

			<div className="person-information-header-menu-container">
				<button
					onClick={() =>
						setIsEditOrDeleteMenuOpen((previousIsEditOrDeleteMenuOpen) => !previousIsEditOrDeleteMenuOpen)}
					className="person-information-header__menu-button-icon"
				>
					<i className="las la-ellipsis-h person-information-header__menu-icon" />
				</button>

				{isEditOrDeleteMenuOpen ? (
					<div className="person-information-header-menu popoverMenu">
						<button
							onClick={() =>
								setCurrentlyEditingPerson(
									(previousCurrentlyEditingPerson) => !previousCurrentlyEditingPerson
								)}
							className="person-information-header-menu__text"
						>
							edit
						</button>
						<button onClick={handleDeletePerson} className="person-information-header-menu__text">
							delete
						</button>
					</div>
				) : null}
			</div>

			{currentlyEditingPerson ? (
				<form className="edit-trial-form">
					<div className="edit-trial-form__influence">
						<LevelOfInfluence
							handleInputChange={handleInputChange}
							amountOfPrefilledCircles={person.rating}
						/>
					</div>
					<div className="edit-trial-form__inputs-container">
						<div className="edit-trial-form__group">
							<label className="edit-trial-form__label" htmlFor="account">
								account
							</label>
							<input
								value={person.customerName}
								readOnly={true}
								className="edit-trial-form__input"
								type="text"
								id="account"
								name="account"
							/>
						</div>
						<div className="edit-trial-form__group">
							<label className="edit-trial-form__label" htmlFor="mobile_phone">
								mobile
							</label>
							<input
								onChange={handleInputChange}
								value={inputs.mobile_phone}
								className="edit-trial-form__input"
								type="tel"
								id="mobile_phone"
								name="mobile_phone"
							/>
						</div>
						<div className="edit-trial-form__group">
							{person.title === 'surgeons' ? (
								<React.Fragment>
									<label className="edit-trial-form__label" htmlFor="personTitleAndID">
										specialty
									</label>
									<select
										onChange={handleInputChange}
										value={inputs.personTitleAndID}
										className="edit-trial-form__input"
										name="personTitleAndID"
										id="personTitleAndID"
									>
										{specialitiesArray.map((specialty) => {
											return (
												<option value={`${specialty.id}-${specialty.name}`} key={specialty.id}>
													{specialty.name}
												</option>
											);
										})}
									</select>
								</React.Fragment>
							) : (
								<React.Fragment>
									<label className="edit-trial-form__label" htmlFor="personTitleAndID">
										title
									</label>
									<select
										onChange={handleInputChange}
										value={inputs.personTitleAndID}
										name="personTitleAndID"
										id="personTitleAndID"
									>
										{stakeholderTitlesArray.map((stakeholderTitle) => {
											return (
												<option
													value={`${stakeholderTitle.id}-${stakeholderTitle.options}`}
													key={stakeholderTitle.id}
												>
													{stakeholderTitle.options}
												</option>
											);
										})}
									</select>
								</React.Fragment>
							)}
						</div>
						<div className="edit-trial-form__group">
							<label className="edit-trial-form__label" htmlFor="work_phone">
								office
							</label>
							<input
								onChange={handleInputChange}
								value={inputs.work_phone}
								className="edit-trial-form__input"
								type="tel"
								id="work_phone"
								name="work_phone"
							/>
						</div>
						<div className="edit-trial-form__group">
							<label className="edit-trial-form__label" htmlFor="firstName">
								First Name
							</label>
							<input
								onChange={handleInputChange}
								value={inputs.firstName}
								className="edit-trial-form__input"
								type="text"
								id="firstName"
								name="firstName"
							/>
						</div>
						<div className="edit-trial-form__group">
							<label className="edit-trial-form__label" htmlFor="lastName">
								Last Name
							</label>
							<input
								onChange={handleInputChange}
								value={inputs.lastName}
								className="edit-trial-form__input"
								type="text"
								id="lastName"
								name="lastName"
							/>
						</div>
						<div className="edit-trial-form__group">
							<label className="edit-trial-form__label" htmlFor="email">
								email
							</label>
							<input
								onChange={handleInputChange}
								value={inputs.email}
								className="edit-trial-form__input"
								type="email"
								id="email"
								name="email"
							/>
						</div>
					</div>

					<div className="edit-trial-form__button-container">
						<button onClick={handleSubmit} className="yellow-button">
							save contact info
						</button>
					</div>
				</form>
			) : (
				<React.Fragment>
					<div className="line-blk-original" />
					<div className="dashboard-stats">
						<div className="stats-survey">
							TOTAL CASES<br />
							<span className="number">{person.totalCase}</span>
						</div>
						<div className="stats-survey">
							TOTAL TRIALS<br />
							<span className="number">{person.totalTrial}</span>
						</div>
					</div>
					<div className="line-grey-original" />
					<ul className="dashboard-stats person__information">
						<li className="my-stats">
							CUSTOMER
							<div className="stats-survey-date">{person.customerName}</div>
						</li>
						<li className="my-stats">
							EMAIL
							<div className="stats-survey-date">{person.email}</div>
						</li>
						<li className="my-stats">
							MOBILEPHONE
							<div className="stats-survey-date">{person.mobilePhone}</div>
						</li>
						<li className="my-stats">
							WORK PHONE
							<div className="stats-survey-date">{person.workPhone}</div>
						</li>
					</ul>
				</React.Fragment>
			)}
		</div>
	);
};

export default StatsMyHistory;
