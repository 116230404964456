import * as ActionTypes from '../actions/ActionTypes';

const initState = {
	isFetchingTrials: false,
	trials: [],
	trialsError: '',
	lastPageInTrials: false,

	isFetchingTrialStatistics: false,
	trialStatistics: [],
	trialStatisticsError: '',

	deleteTrial: false,
	deleteTrialError: '',

	trialDetail: {},
	trialDetailError: '',
	isFetchingTrialDetails: null,
	trialMain: {},
	trialMainError: '',

	trialPeople: [],
	trialPeopleError: '',
	isLastPageInTrialPeople: false,
	isFetchingTrialPeople: null,
	trialPeopleStakeholderOrSurgeon: [],
	trialPeopleStakeholderOrSurgeonError: '',

	teammatesForSearch: [],
	teammatesForSearchError: '',

	postTeammatesError: '',
	deleteTeammateError: '',

	teammates: [],
	teammatesError: '',

	stakeholderTitlesArray: [],
	stakeholderTitlesError: '',

	specialitiesArray: [],
	specialitiesError: '',
	postSpecialitiesError: '',
	deleteSpecialitiesError: '',

	isFetchingCompetitorsArray: false,
	competitorsArray: [],
	competitorsError: '',
	deleteCompetitorsError: '',
	postCompetitorsError: '',

	createdPersonID: '',

	postSurgeonsError: '',
	deleteSurgeonError: '',

	postStakeholderError: '',
	deleteStakeholderError: '',

	availableCustomers: [],
	availableCustomersError: '',

	customerDetails: {},
	customerDetailsError: '',

	trialStatusError: '',

	putTrialInformationError: '',

	getStates: [],
	getStatesError: ''
};

const trialsReducer = (state = initState, action) => {
	const FIRST_PAGE = 1;
	const MAX_RESULTS_TO_BE_LAST_PAGE = 10;

	switch (action.type)
	{
		case ActionTypes.REQUEST_TRIALS:
			return {
				...state,
				isFetchingTrials: true
			};

		case ActionTypes.GET_TRIALS_SUCCESS:
			let trials;
			let isLastPageInTrials = false;

			if (action.trials === undefined)
			{
				trials = state.trials;
				isLastPageInTrials = true;
			} else if (action.currentPageNumber === FIRST_PAGE)
			{
				trials = action.trials;
				isLastPageInTrials = false;
			} else if (action.trials && state.trials)
			{
				trials = [ ...state.trials, ...action.trials ];
			}

			if (Array.isArray(action.trials) && action.trials.length < MAX_RESULTS_TO_BE_LAST_PAGE)
			{
				isLastPageInTrials = true;
			}

			return {
				...state,
				trials: trials,
				trialsError: '',
				isLastPageInTrials: isLastPageInTrials,
				isFetchingTrials: false
			};

		case ActionTypes.GET_TRIALS_ERROR:
			return {
				...state,
				trialsError: action.trialsError,
				isFetchingTrials: false
			};

		case ActionTypes.DELETE_TRIAL_SUCCESS:
			return {
				...state,
				deleteTrialError: ''
			};

		case ActionTypes.DELETE_TRIAL_ERROR:
			return {
				...state,
				deleteTrialError: action.deleteTrialError
			};

		case ActionTypes.REQUEST_TRIAL_DETAILS:
			return {
				...state,
				isFetchingTrialDetails: true
			};

		case ActionTypes.GET_TRIAL_DETAIL_SUCCESS:
			return {
				...state,
				trialDetail: action.trialDetail,
				isFetchingTrialDetails: false,
				trialDetailError: ''
			};

		case ActionTypes.GET_TRIAL_DETAIL_ERROR:
			return {
				...state,
				trialDetailError: action.trialDetailError,
				isFetchingTrialDetails: false
			};

		case ActionTypes.GET_TRIAL_MAIN_SUCCESS:
			return {
				...state,
				trialMain: action.trialMain,
				trialMainError: ''
			};

		case ActionTypes.GET_TRIAL_MAIN_ERROR:
			return {
				...state,
				trialMainError: action.trialMainError
			};

		case ActionTypes.REQUEST_TRIAL_PEOPLE:
			return {
				...state,
				isFetchingTrialPeople: true
			};

		case ActionTypes.GET_TRIAL_PEOPLE_SUCCESS:
			let trialPeople;
			let isLastPageInTrialPeople = false;

			if (action.trialPeople === undefined)
			{
				trialPeople = state.trialPeople;
				isLastPageInTrialPeople = true;
			} else if (action.currentPageNumber === FIRST_PAGE)
			{
				trialPeople = action.trialPeople;
				isLastPageInTrialPeople = false;
			} else if (action.trialPeople && state.trialPeople)
			{
				trialPeople = [ ...state.trialPeople, ...action.trialPeople ];
			}

			if (Array.isArray(action.trialPeople) && action.trialPeople.length < MAX_RESULTS_TO_BE_LAST_PAGE)
			{
				isLastPageInTrialPeople = true;
			}

			return {
				...state,
				trialPeople: trialPeople,
				isLastPageInTrialPeople: isLastPageInTrialPeople,
				isFetchingTrialPeople: false,
				trialPeopleError: ''
			};

		case ActionTypes.GET_TRIAL_PEOPLE_ERROR:
			return {
				...state,
				isFetchingTrialPeople: false,
				trialPeopleError: action.trialPeopleError,
				trialPeople: []
			};

		case ActionTypes.GET_TRIAL_PEOPLE_STAKEHOLDER_OR_SURGEON_SUCCESS:
			return {
				...state,
				trialPeopleStakeholderOrSurgeon: action.trialPeopleStakeholderOrSurgeon,
				trialPeopleStakeholderOrSurgeonError: ''
			};

		case ActionTypes.GET_TRIAL_PEOPLE_STAKEHOLDER_OR_SURGEON_ERROR:
			return {
				...state,
				trialPeopleStakeholderOrSurgeonError: action.trialPeopleStakeholderOrSurgeonError
			};

		case ActionTypes.GET_TEAMMATES_SUCCESS:
			return {
				...state,
				teammates: action.teammates,
				teammatesError: ''
			};

		case ActionTypes.GET_TEAMMATES_ERROR:
			return {
				...state,
				teammatesError: action.teammatesError,
				teammates: []
			};

		case ActionTypes.GET_TEAMMATES_FOR_SEARCH_SUCCESS:
			return {
				...state,
				teammatesForSearch: action.teammatesForSearch,
				teammatesForSearchError: ''
			};

		case ActionTypes.GET_TEAMMATES_FOR_SEARCH_ERROR:
			return {
				...state,
				teammatesForSearchError: action.teammatesForSearchError
			};

		case ActionTypes.GET_STAKEHOLDER_TITLES_SUCCESS:
			return {
				...state,
				stakeholderTitlesArray: action.stakeholderTitlesArray,
				stakeholderTitlesError: ''
			};

		case ActionTypes.GET_STAKEHOLDER_TITLES_ERROR:
			return {
				...state,
				stakeholderTitlesError: action.stakeholderTitlesError
			};

		case ActionTypes.REQUEST_SPECIALITIES:
			return {
				...state,
				isFetchingSpecialities: true
			}

		case ActionTypes.GET_SPECIALITIES_SUCCESS:
			return {
				...state,
				specialitiesArray: action.specialitiesArray,
				specialitiesError: '',
				isFetchingSpecialities: false
			};

		case ActionTypes.GET_SPECIALITIES_ERROR:
			return {
				...state,
				specialitiesError: action.specialitiesError,
				isFetchingSpecialities: false
			};

		case ActionTypes.POST_SPECIALITIES_SUCCESS:
			return {
				...state,
				postSpecialitiesError: ''
			};

		case ActionTypes.POST_SPECIALITIES_ERROR:
			return {
				...state,
				postSpecialitiesError: action.postSpecialitiesError
			};

		case ActionTypes.DELETE_SPECIALITIES_SUCCESS:
			return {
				...state,
				deleteSpecialitiesError: ''
			};

		case ActionTypes.DELETE_SPECIALITIES_ERROR:
			return {
				...state,
				deleteSpecialitiesError: action.deleteSpecialitiesError
			};

		case ActionTypes.POST_TEAMMATE_SUCCESS:
			return {
				...state,
				postTeammates: action.postTeammates,
				postTeammatesError: ''
			};

		case ActionTypes.POST_TEAMMATE_ERROR:
			return {
				...state,
				postTeammatesError: action.postTeammatesError
			};

		case ActionTypes.DELETE_TEAMMATE_SUCCESS:
			return {
				...state,
				deleteTeammateError: ''
			};

		case ActionTypes.DELETE_TEAMMATE_ERROR:
			return {
				...state,
				deleteTeammateError: action.deleteTeammateError
			};

		case ActionTypes.REQUEST_COMPETITORS:
			return {
				...state,
				isFetchingCompetitorsArray: true,
			}

		case ActionTypes.GET_COMPETITORS_SUCCESS:
			return {
				...state,
				competitorsArray: action.competitorsArray,
				competitorsError: '',
				isFetchingCompetitorsArray: false
			};

		case ActionTypes.GET_COMPETITORS_ERROR:
			return {
				...state,
				competitorsError: action.competitorsError,
				isFetchingCompetitorsArray: false
			};

		case ActionTypes.POST_COMPETITORS_SUCCESS:
			return {
				...state,
				postCompetitorsError: ''
			};

		case ActionTypes.POST_COMPETITORS_ERROR:
			return {
				...state,
				postCompetitorsError: action.postCompetitorsError
			};

		case ActionTypes.DELETE_COMPETITORS_SUCCESS:
			return {
				...state,
				deleteCompetitorsError: ''
			};

		case ActionTypes.DELETE_COMPETITORS_ERROR:
			return {
				...state,
				deleteCompetitorsError: action.deleteCompetitorsError
			};

		case ActionTypes.POST_SURGEONS_SUCCESS:
			return {
				...state,
				postSurgeonsError: '',
				createdPersonID: action.createdPersonID
			};

		case ActionTypes.POST_SURGEONS_ERROR:
			return {
				...state,
				postSurgeonsError: action.postSurgeonsError,
				createdPersonID: ''
			};

		case ActionTypes.DELETE_SURGEON_SUCCESS:
			return {
				...state,
				deleteSurgeonError: ''
			};

		case ActionTypes.DELETE_SURGEON_ERROR:
			return {
				...state,
				deleteSurgeonError: action.deleteSurgeonError
			};

		case ActionTypes.POST_STAKEHOLDER_SUCCESS:
			return {
				...state,
				postStakeholderError: '',
				createdPersonID: action.createdPersonID
			};

		case ActionTypes.POST_STAKEHOLDER_ERROR:
			return {
				...state,
				postStakeholderError: action.postStakeholderError,
				createdPersonID: ''
			};

		case ActionTypes.RESET_CREATED_PERSON_ID_IN_TRIALS_REDUCER:
			return {
				...state,
				createdPersonID: ''
			}

		case ActionTypes.DELETE_STAKEHOLDER_SUCCESS:
			return {
				...state,
				deleteStakeholderError: ''
			};

		case ActionTypes.DELETE_STAKEHOLDER_ERROR:
			return {
				...state,
				deleteStakeholderError: action.deleteStakeholderError
			};

		case ActionTypes.REQUEST_GET_TRIALS_AVAILABLE_CUSTOMERS:
			return {
				...state,
				isFetchingGetTrialsAvailableCustomer: true
			}

		case ActionTypes.GET_TRIALS_AVAILABLE_CUSTOMERS_SUCCESS:
			return {
				...state,
				availableCustomers: action.availableCustomers,
				availableCustomersError: '',
				isFetchingGetTrialsAvailableCustomer: false
			};

		case ActionTypes.GET_TRIALS_AVAILABLE_CUSTOMERS_ERROR:
			return {
				...state,
				availableCustomersError: action.availableCustomersError,
				isFetchingGetTrialsAvailableCustomer: false
			};

		case ActionTypes.REQUEST_TRIAL_STATISTICS:
			return {
				...state,
				isFetchingTrialStatistics: true,
			}

		case ActionTypes.GET_TRIAL_STATISTICS_SUCCESS:
			return {
				...state,
				isFetchingTrialStatistics: false,
				trialStatistics: action.trialStatistics,
				trialStatisticsError: ''
			};

		case ActionTypes.GET_TRIAL_STATISTICS_ERROR:
			return {
				...state,
				isFetchingTrialStatistics: false,
				trialStatisticsError: action.trialStatisticsError
			};

		case ActionTypes.GET_CUSTOMER_DETAILS_SUCCESS:
			return {
				...state,
				customerDetails: action.customerDetails,
				customerDetailsError: ''
			};

		case ActionTypes.GET_CUSTOMER_DETAILS_ERROR:
			return {
				...state,
				customerDetailsError: action.customerDetailsError
			};

		case ActionTypes.PUT_TRIAL_STATUS_SUCCESS:
			return {
				...state,
				putTrialStatusError: ''
			};

		case ActionTypes.PUT_TRIAL_STATUS_ERROR:
			return {
				...state,
				putTrialStatusError: action.putTrialStatusError
			};

		case ActionTypes.PUT_TRIAL_INFORMATION_SUCCESS:
			return {
				...state,
				putTrialInformationError: ''
			};

		case ActionTypes.PUT_TRIAL_INFORMATION_ERROR:
			return {
				...state,
				putTrialInformationError: action.putTrialInformationError
			};

		case ActionTypes.GET_STATES_SUCCESS:
			return {
				...state,
				getStates: action.getStates,
				getStatesError: ''
			};

		case ActionTypes.GET_STATES_ERROR:
			return {
				...state,
				getStatesError: action.getStatesError
			};

		default:
			return state;
	}
};

export default trialsReducer;
