import React, { useEffect, useState } from 'react';
import useEffectAfterFirstRender from '../../../../../Hooks/useEffectAfterFirstRender';
import classes from '../../../MultipleStepsModalsForm/InputElements.module.css';
import trialSummaryClasses from '../../TrialSummary.module.css';

const EditCards = ({ dataFromAPI, currentlyEditing, liftSelectedAndDeselectedArrays, type }) => {
    const [ deselectedArray, setDeselectedArray ] = useState([]);
    const [ selectedArray, setSelectedArray ] = useState([]);

    useEffect(() => {
        setSelectedArray(dataFromAPI.filter(speciality => {
            if (speciality.is_selected === "1")
            {
                return true
            }
            return false
        }).map(speciality => speciality.id));

    }, [ dataFromAPI ])

    const handleSelectType = (ev, itemID) => {
        let activeElement = ev.target.parentNode;
        let [ activeImage, activeSelectedImage ] = activeElement.querySelectorAll(`.${ trialSummaryClasses[ "trial-summary__card-image--specialty" ] }`);
        activeSelectedImage.classList.toggle('hidden');
        activeImage.classList.toggle('hidden');

        if (selectedArray.includes(itemID))
        {
            let cloneDeselectedArray = JSON.parse(JSON.stringify(deselectedArray));
            cloneDeselectedArray.push(itemID);
            setDeselectedArray(cloneDeselectedArray);

            let cloneSelectedArray = JSON.parse(JSON.stringify(selectedArray));
            let indexOfSelectedSpeciality = cloneSelectedArray.findIndex(ID => ID === itemID);
            cloneSelectedArray.splice(indexOfSelectedSpeciality, 1);
            setSelectedArray(cloneSelectedArray);
        } else if (!selectedArray.includes(itemID))
        {
            let cloneSelectedArray = JSON.parse(JSON.stringify(selectedArray));
            cloneSelectedArray.push(itemID);
            setSelectedArray(cloneSelectedArray);

            if (deselectedArray.includes(itemID))
            {
                let cloneDeselectedArray = JSON.parse(JSON.stringify(deselectedArray));
                let indexOfSelectedSpeciality = cloneDeselectedArray.findIndex(ID => ID === itemID);
                cloneDeselectedArray.splice(indexOfSelectedSpeciality, 1);
                setDeselectedArray(cloneDeselectedArray);
            }
        }
    }

    useEffectAfterFirstRender(() => {
        if (currentlyEditing === false)
        {
            liftSelectedAndDeselectedArrays(selectedArray, deselectedArray);
        }
    }, [ currentlyEditing ]);

    return (
        <div className={ [ trialSummaryClasses[ "trial-summary__card-select-container" ], currentlyEditing ? ("") : ("hidden") ].join(" ") }>
            {dataFromAPI?.map(item => {
                let isSelected = selectedArray.includes(item.id);
                return (
                    <label
                        htmlFor={ item.name }
                        key={ item.id }
                        className={ [ classes[ "form__select-image-container" ], trialSummaryClasses[ "trial-summary__image-container" ] ].join(" ") }>
                        <span className={ [ classes[ "form__select-image-border" ], isSelected ? (trialSummaryClasses[ "trial-summary__card-image--active" ]) : ("") ].join(" ") }>
                            <img
                                className={ [ trialSummaryClasses[ "trial-summary__card-image--specialty" ], isSelected ? ("hidden") : ("") ].join(" ") }
                                src={ item.icon }
                                alt={ item.name } />
                            <img
                                className={ [ trialSummaryClasses[ "trial-summary__card-image--specialty" ], isSelected ? ("") : ("hidden") ].join(" ") }
                                src={ item.icon_selected }
                                alt={ item.name } />
                        </span>

                        <input
                            onClick={ (ev) => handleSelectType(ev, item.id) }
                            value={ item.name }
                            id={ item.name }
                            className="hidden"
                            name={ type }
                            type="checkbox" />
                        <span className={ [ trialSummaryClasses[ "trial-summary__card-name" ], isSelected ? (trialSummaryClasses[ "trial-summary__card-name--active" ]) : ("") ].join(" ") }>
                            { item.name }
                        </span>
                    </label>
                );
            }) }
        </div>
    );
}

export default EditCards;