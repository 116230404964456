import React from "react";
import classes from "./TrialSummary.module.css";

import createImageURL from './createImageURL';

export default (item) => {
    const [ imageURL, IMAGE_URL_CREATED ] = createImageURL(item);
    if (item.is_selected === "0") return null;

    return (
        <div
            key={ item.id }
            className={ [ "d-board-item", classes[ "trial-summary__card-wrapper" ] ].join(" ") }>
            <div
                style={ { backgroundImage: `url(${ imageURL })` } }
                className={ [ "d-board-thumb", classes[ "trial-summary__card-image" ], item.is_selected === "1" ? (classes[ "trial-summary__card-image--active" ]) : (IMAGE_URL_CREATED ? (classes[ "trial-summary__select-card-image--contrast" ]) : (null)) ].join(" ") }></div>
            <div
                className={ [ classes[ "trial-summary__card-name" ], item.is_selected === "1" ? (classes[ "trial-summary__card-name--active" ]) : (null) ].join(" ") }> { item.name }</div>
        </div>
    );
}