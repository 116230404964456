import React, { Component } from 'react';
import classes from './OverlayHeaderForPopups.module.css';

class OverlayHeaderForPopups extends Component {
	render() {
		const { title, buttonSubmitCallback, togglePopup, editing } = this.props;

		return (
			<div
				className={[
					classes['overlay__header'],
					buttonSubmitCallback ? classes['overlay__header--button'] : classes['overlay__header--no-button']
				].join(' ')}
			>
				<div className={classes['overlay__close']} onClick={togglePopup}>
					<i className="las la-times" />
				</div>
				<p className={classes['overlay__heading']}>{title}</p>
				{buttonSubmitCallback ? (
					<button
						onClick={buttonSubmitCallback}
						className={`${classes['overlay__button']} ${editing ? classes['editing'] : ''}`}
						disabled={!editing}
					>
						save
					</button>
				) : null}
			</div>
		);
	}
}

export default OverlayHeaderForPopups;
