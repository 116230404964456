import React from 'react';

import classes from './LogoPreview.module.css';

const LogoPreview = ({ removeMargin }) => {
    return (
        <div className={ [ classes[ "logo-container" ], removeMargin ? "m-0" : "" ].join(" ") }>
            <img
                className={ classes[ "logo" ] }
                src="/images/email/stryker-logo.png" alt="stryker logo" />
        </div>
    )
}

export default LogoPreview;