import React from 'react';
import { Container } from 'reactstrap';

import List from '../../../../../Components/List/List';
import ComponentLoading from '../../../../../Components/ComponentLoading/ComponentLoading';

const TemplatesView = (props) => {
	const { 
		templates, 
		onSort,
		view, 
		isFetchingSurveyTemplates, 
		isFetchingSurveyCrushTemplates, 
		isFetchingEvaluationTemplates,
		isFetchingEvaluationCrushTemplates } = props;

	return (
		<Container>
			<List
				list={templates}
				actions={null}
				sortAlphabeticallyByTitle={onSort}
				sortBy="template_name"
				view={view}
			/>
			
			{ isFetchingSurveyTemplates ||
				isFetchingSurveyCrushTemplates ||
				isFetchingEvaluationTemplates ||
				isFetchingEvaluationCrushTemplates ? (
					<ComponentLoading 
						message="Loading..."
						removeHeight={ true } />
				) : (null) }
		</Container>
	);
};

export default TemplatesView;
