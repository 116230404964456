import React from 'react';

import classes from './InServiceRecap.module.css';
import emailPreviewClasses from '../EmailPreview.module.css';

import Footer from '../../Shared/Footer/Footer';
import LogoPreview from '../../Shared/LogoPreview/LogoPreview';

const InServiceRecapCommunications = ({ emailPreview }) => {
    const emailPreviewContent = emailPreview?.content.split(/[\r\n]+/g);

    const attendeesLineOne = emailPreview.inservice_attendees_line1.replace(/&bull;/g, '').replaceAll(String.fromCharCode(8226), '').split('\n');

    const BASE_URL = process.env.REACT_APP_API_URL;
    let facilityImage = BASE_URL + emailPreview.facility_image;
    let facilityLogo = BASE_URL + emailPreview.facility_logo;

    if (emailPreview.facility_image === "")
    {
        facilityImage = '/images/email/facility-image-placeholder.png';
    }

    if (emailPreview.facility_logo === "")
    {
        facilityLogo = '/images/email/facility-logo-placeholder.png';
    }

    return (
        <div>
            <LogoPreview />
            <div className={ classes[ "header" ] }>
                <div className={ classes[ "header__left" ] }>
                    <div className={ classes[ "header__image-container" ] }>
                        <img
                            className={ classes[ "header__image" ] }
                            src={ facilityImage }
                            alt="facility image" />
                    </div>
                    <div className={ classes[ "header__image-container" ] }>
                        <img
                            className={ classes[ "header__image" ] }
                            src={ facilityLogo }
                            alt="facility logo"
                        />
                    </div>
                </div>

                <div className={ classes[ "header__right" ] }>
                    <div className={ classes[ "header__heading" ] }>
                        <div className={ classes[ "header__text-light" ] }>
                            { emailPreview.title }
                        </div>
                        <div className={ emailPreviewClasses[ "header__text-bold" ] }>
                            { emailPreview.sub_title }
                        </div>
                    </div>

                    <div className={ emailPreviewClasses[ "ribbon" ] }></div>

                    <div className={ classes[ "header__description" ] }>
                        { emailPreview.title_description }
                    </div>
                </div>
            </div>

            <div className={ classes[ "paragraphs-container" ] }>
                <div className={ [ emailPreviewClasses[ "email-preview__paragraph" ], "light-text" ].join(" ") }>
                    { emailPreview.recipient_name }
                </div>

                { emailPreviewContent?.map((item, index) => {
                    return (
                        <p
                            key={ index }
                            className={ emailPreviewClasses[ "email-preview__paragraph" ] }>
                            { item }
                        </p>
                    );
                }) }
            </div>

            { emailPreview?.inservice_attendees_is_on === "1" ? (
                <div className={ classes[ "section" ] }>
                    <div className="flex-shrink-0">
                        <img
                            className={ classes[ "section__image" ] }
                            src="/images/email/recap-11.png"
                            alt="workstation" />
                    </div>

                    <div className={ classes[ "section__item" ] }>
                        <div className={ [ classes[ "section__number" ], classes[ "section__number--light" ] ].join(" ") }>
                            { emailPreview.inservice_attendees_number }
                        </div>
                        <div className={ classes[ "section__text-light" ] }>
                            { emailPreview.inservice_attendees_title }
                        </div>
                        <div className={ classes[ "section__text-bold" ] }>
                            { emailPreview.inservice_attendees_subtitle }
                        </div>

                        <div className={ emailPreviewClasses[ "ribbon" ] }></div>

                        <div className={ classes[ "tutorial" ] }>
                            <div className={ classes[ "tutorial__text" ] }>
                                Tutorials:
                        </div>

                            <ul className={ classes[ "tutorial__list" ] }>
                                { attendeesLineOne.map((item, index) => {
                                    return (
                                        <li
                                            key={ index }
                                            className={ classes[ "tutorial__list-item" ] }>
                                            {item }
                                        </li>
                                    );
                                }) }
                            </ul>
                        </div>
                    </div>
                </div>
            ) : (null) }

            <Footer text={ emailPreview.footer } />
        </div>
    );
}

export default InServiceRecapCommunications;