import axios from 'axios';

export default (req) => {
	const authToken = localStorage.getItem('AUTH_TOKEN');
	const instance = axios.create({
		baseURL: `${process.env.REACT_APP_API_URL}`,
		headers: { 'Content-Type': 'application/json' },
		responseType: 'json'
	});
	if (authToken) {
		instance.defaults.headers.common.Authorization = `Bearer ${authToken}`;
	}
	return instance;
};
