import React from 'react';
import { Col } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import '../assets/css/app.css';

const Intelligence = () => {
	const { id } = useParams();
	return (
		<div className="tab-section">
			<Col className="tab-tabs">
				<div className="d-board-sect-title pb-1">Intelligence</div>
				<div className="toolkit">
					{/* <div className="d-board-item">
						<Link to="/talktracks/specialty">
							<div className="d-board-thumb talk" />
							<div>TALK TRACKS</div>
						</Link>
					</div> */}
					<div className="d-board-item">
						<Link to={`/trial/${id}/intelligence/relatedTrials`} className="">
							<div className="d-board-thumb relatedtrials" />
							<div className="">RELATED TRIALS</div>
						</Link>
					</div>
					{/* <div className="d-board-item">
						<Link to="/competitiveintel" className="">
							<div className="d-board-thumb compcollateral" />
							<div className="">COMP INTEL</div>
						</Link>
					</div> */}
				</div>
			</Col>
		</div>
	);
};

export default Intelligence;
