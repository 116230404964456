import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LevelOfInfluence from '../../../../../Utils/LevelOfInfluence/LevelOfInfluence';
import classes from './AddPerson.module.css';
import useHandleSubmit from '../../../../../Hooks/useHandleSubmit';

import {
    postSurgeonAction,
    getSpecialitiesAction,
    postStakeholderAction,
    getStakeholderTitlesAction
} from '../../../../../Store/actions/trialsAction';
import { useDispatch, useSelector } from 'react-redux';

const AddPerson = ({ toggleModal, accountID, type }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSpecialitiesAction(id));
        dispatch(getStakeholderTitlesAction(id));
    }, []);

    const specialitiesArray = useSelector(state => state.trials.specialitiesArray.speciality);
    const specialitiesError = useSelector(state => state.trials.specialitiesError);

    const stakeholderTitlesArray = useSelector(state => state.trials.stakeholderTitlesArray);
    const stakeholderTitlesError = useSelector(state => state.trials.stakeholderTitlesError);

    const handleCancelClick = (ev) => {
        ev.preventDefault();
        toggleModal();
    }

    const { id } = useParams();
    const handleCustomFormSubmit = () => {
        let formData = new FormData();
        formData.append('first_name', inputs.firstName);
        formData.append('last_name', inputs.lastName);
        formData.append('email', inputs.email);
        formData.append('rating', inputs.rating);
        formData.append('facility', '');
        formData.append('customer_id', inputs.accountID);
        formData.append('trial_id', id);
        formData.append('user_id', localStorage.getItem('user_id'));
        const [ personID, title ] = inputs.personTitleAndID.split("-");
        formData.append('title', title);
        if (type === 'Surgeons')
        {
            formData.append('specialty_id', personID);
            dispatch(postSurgeonAction({ formData }));
        } else
        {
            formData.append('title_id', 0);
            dispatch(postStakeholderAction({ formData }));
        }

        toggleModal();
    }

    const { inputs, handleInputChange, handleSubmit } = useHandleSubmit({
        callback: handleCustomFormSubmit,
        initialState: {
            firstName: '',
            lastName: '',
            rating: '',
            email: '',
            personTitleAndID: '1-CV',
            accountID: accountID
        }
    });

    useEffect(() => {
        handleInputChange({
            target: {
                name: 'accountID',
                value: accountID
            }
        });
    }, [ accountID ]);


    return (
        <div className={ classes[ "container" ] }>
            <h2 className={ classes[ "title" ] }>
                add people to my trial
                <div className={ classes[ "title--line" ] }></div>
            </h2>

            <form
                onSubmit={ handleSubmit }
                className={ classes[ "form" ] }>
                <div className={ classes[ "form-group" ] }>
                    <label
                        className={ classes[ "label" ] }
                        htmlFor="firstName">
                        first name &#42;
                    </label>
                    <input
                        onChange={ handleInputChange }
                        value={ inputs.firstName }
                        className={ classes[ "input" ] }
                        type="text"
                        name="firstName"
                        id="firstName"
                        required />
                </div>
                <div className={ classes[ "form-group" ] }>
                    <label
                        className={ classes[ "label" ] }
                        htmlFor="lastName">
                        last name &#42;
                    </label>
                    <input
                        onChange={ handleInputChange }
                        value={ inputs.lastName }
                        className={ classes[ "input" ] }
                        type="text"
                        name="lastName"
                        id="lastName"
                        required />
                </div>
                <div className={ classes[ "form-group" ] }>
                    <div className={ classes[ "label" ] }>
                        level of influence
                    </div>
                    <LevelOfInfluence handleInputChange={ handleInputChange } />
                </div>
                <div className={ classes[ "form-group" ] }>
                    <label
                        className={ classes[ "label" ] }
                        htmlFor="email">
                        email
                    </label>
                    <input
                        onChange={ handleInputChange }
                        value={ inputs.email }
                        className={ classes[ "input" ] }
                        type="email"
                        name="email"
                        id="email" />
                </div>
                <div className={ classes[ "form-group" ] }>
                    <label
                        className={ classes[ "label" ] }
                        htmlFor="personTitleAndID">
                        { type === "Surgeons" ? ("specialty") : ("title") }
                    </label>
                    <select
                        onChange={ handleInputChange }
                        className={ classes[ "select" ] }
                        name="personTitleAndID"
                        id="personTitleAndID">
                        { type === "Surgeons" ? (
                            specialitiesArray?.map(speciality => {
                                return (
                                    <option
                                        value={ `${ speciality.id }-${ speciality.name }` }
                                        key={ speciality.id }>
                                        { speciality.name }
                                    </option>
                                )
                            })
                        ) : (
                                stakeholderTitlesArray?.map(stakeholderTitle => {
                                    return (
                                        <option
                                            value={ `${ stakeholderTitle.id }-${ stakeholderTitle.options }` }
                                            key={ stakeholderTitle.id }>
                                            { stakeholderTitle.options }
                                        </option>
                                    )
                                })
                            ) }
                    </select>
                </div>

                <div className={ classes[ "form-group--buttons" ] }>
                    <button
                        onClick={ handleCancelClick }
                        className={ [ classes[ "button" ], classes[ "button--cancel" ] ].join(" ") }>
                        cancel
                    </button>

                    <button className={ [ classes[ "button" ], classes[ "button--submit" ] ].join(" ") }>
                        add to trial
                    </button>
                </div>
            </form>
        </div>
    );
}

export default AddPerson;