import * as ActionTypes from '../actions/ActionTypes';

const initState = {
	case: [],
	caseError: '',
	isFetchingCase: false,
	postCaseError: '',
	updateCaseError: '',
	deleteCaseError: '',

	surgeonsForCreatingACase: [],
	surgeonsForCreatingACaseError: '',
	isLastPageInSurgeonsForCreatingACase: false,
	isFetchingCaseSurgeons: false,

	surgeon: {},
	surgeonError: '',
	getCaseDetailsForEditAndDeletePage: {},
	getCaseDetailsForEditAndDeletePageError: '',

	postSurgeonToTrialError: ''
};

const casesReducer = (state = initState, action) => {
	const FIRST_PAGE = 1;
	const MAX_RESULTS_TO_BE_LAST_PAGE = 10;

	switch (action.type) {
		case ActionTypes.REQUEST_GET_CASE:
			return {
				...state,
				isFetchingCase: true
			};

		case ActionTypes.GET_CASE_SUCCESS:
			return {
				...state,
				case: action.case,
				caseError: '',
				isFetchingCase: false
			};

		case ActionTypes.GET_CASE_ERROR:
			return {
				...state,
				case: [],
				caseError: action.caseError,
				isFetchingCase: false
			};

		case ActionTypes.POST_CASE_SUCCESS:
			return {
				...state,
				postCaseError: ''
			};

		case ActionTypes.POST_CASE_ERROR:
			return {
				...state,
				postCaseError: action.postCaseError
			};

		case ActionTypes.PUT_CASE_SUCCESS:
			return {
				...state,
				getCaseDetailsForEditAndDeletePage: {},
				surgeon: {},
				putCaseError: ''
			};

		case ActionTypes.PUT_CASE_ERROR:
			return {
				...state,
				putCaseError: action.putCaseError
			};

		case ActionTypes.DELETE_CASE_SUCCESS:
			return {
				...state,
				getCaseDetailsForEditAndDeletePage: {},
				surgeon: {},
				deleteCaseError: ''
			};

		case ActionTypes.DELETE_CASE_ERROR:
			return {
				...state,
				deleteCaseError: action.deleteCaseError
			};

		case ActionTypes.REQUEST_CASE_SURGEONS:
			return {
				...state,
				isFetchingCaseSurgeons: true
			}

		case ActionTypes.GET_CASE_SURGEONS_SUCCESS:
			let surgeonsForCreatingACase;
			let isLastPageInSurgeonsForCreatingACase = false;
			let surgeonsForCreatingACaseFromAction = action.surgeonsForCreatingACase;
			let surgeonsForCreatingACaseFromState = state.surgeonsForCreatingACase;
			if (surgeonsForCreatingACaseFromAction === undefined)
			{
				surgeonsForCreatingACase = surgeonsForCreatingACaseFromState;
				isLastPageInSurgeonsForCreatingACase = true;
			} else if (action.currentPageNumber === FIRST_PAGE)
			{
				surgeonsForCreatingACase = surgeonsForCreatingACaseFromAction;
				isLastPageInSurgeonsForCreatingACase = false;
			} else if (surgeonsForCreatingACaseFromAction && surgeonsForCreatingACaseFromState)
			{
				surgeonsForCreatingACase = [ ...surgeonsForCreatingACaseFromState, ...surgeonsForCreatingACaseFromAction ];
			}

			if (Array.isArray(surgeonsForCreatingACaseFromAction) && surgeonsForCreatingACaseFromAction.length < MAX_RESULTS_TO_BE_LAST_PAGE)
			{
				isLastPageInSurgeonsForCreatingACase = true;
			}

			return {
				...state,
				surgeonsForCreatingACase: surgeonsForCreatingACase,
				isLastPageInSurgeonsForCreatingACase: isLastPageInSurgeonsForCreatingACase,
				surgeonsForCreatingACaseError: '',
				isFetchingCaseSurgeons: false
			};

		case ActionTypes.GET_CASE_SURGEONS_ERROR:
			return {
				...state,
				surgeonsForCreatingACase: [],
				surgeonsForCreatingACaseError: action.surgeonsForCreatingACaseError,
				isFetchingCaseSurgeons: false
			};

		case ActionTypes.REQUEST_GET_SURGEON:
			return {
				...state,
				isFetchingSurgeon: true
			}

		case ActionTypes.GET_SURGEON_SUCCESS:
			return {
				...state,
				surgeon: action.surgeon,
				surgeonError: '',
				isFetchingSurgeon: false
			};

		case ActionTypes.GET_SURGEON_ERROR:
			return {
				...state,
				surgeon: [],
				surgeonError: action.surgeonError,
				isFetchingSurgeon: false
			};

		case ActionTypes.GET_CASE_DETAILS_FOR_EDIT_AND_DELETE_PAGE_SUCCESS:
			return {
				...state,
				getCaseDetailsForEditAndDeletePage: action.getCaseDetailsForEditAndDeletePage,
				getCaseDetailsForEditAndDeletePageError: '',
				postSurgeonToTrialError: ''
			};

		case ActionTypes.GET_CASE_DETAILS_FOR_EDIT_AND_DELETE_PAGE_ERROR:
			return {
				...state,
				getCaseDetailsForEditAndDeletePage: {},
				getCaseDetailsForEditAndDeletePageError: action.getCaseDetailsForEditAndDeletePageError
			};

		case ActionTypes.POST_SURGEON_TO_TRIAL_SUCCESS:
			return {
				...state,
				postSurgeonToTrialError: ''
			};

		case ActionTypes.POST_SURGEON_TO_TRIAL_ERROR:
			return {
				...state,
				postSurgeonToTrialError: action.postSurgeonToTrialError
			};

		case ActionTypes.DELETE_SURGEON_FROM_TRIAL_SUCCESS:
			return {
				...state,
				deleteSurgeonFromTrialError: ''
			};

		case ActionTypes.DELETE_SURGEON_FROM_TRIAL_ERROR:
			return {
				...state,
				deleteSurgeonFromTrialError: action.deleteSurgeonFromTrialError
			};

		default:
			return state;
	}
};

export default casesReducer;
