import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Modal } from "reactstrap";
import classes from "./TrialSummary.module.css";

import { useDispatch, useSelector } from "react-redux";
import {
    getTrialMainAction,
    getTrialDetailAction,
    getTrialPeopleAction,
    postSpecialitiesAction,
    deleteSpecialitiesAction,
    getCompetitorsAction,
    deleteCompetitorsAction,
    postCompetitorsAction,
    putTrialInformationAction
} from "../../../Store/actions/trialsAction";

import EditCards from "./Forms/EditCards/EditCards";
import EditCardCompetitors from "./Forms/EditCards/EditCardsCompetitors";
import ConfirmDeleteTrialModal from "./Forms/ConfirmDeleteTrial/ConfirmDeleteTrial";
import SummaryList from "./SummaryList";
import TrialPeopleTable from "./TrialPeopleTable";
import NoResults from "../../../Components/NoResults/NoResults";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import useHandleSubmit from '../../../Hooks/useHandleSubmit';

import { formatDateToMonthDayYear, preventTypingInDateInputs } from '../../../Utils/dates';
import ComponentLoading from "../../../Components/ComponentLoading/ComponentLoading";
import CloseModalButton from "../../../Components/CloseModalButton/CloseModalButton";

const TrialSummary = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTrialMainAction(id));
        dispatch(getTrialDetailAction(id));
        dispatch(getTrialPeopleAction({ trialID: id }));
        dispatch(getCompetitorsAction(id));
    }, []);

    const { trial_details } = useSelector(state => state.trials.trialDetail);
    const trialDetailError = useSelector(state => state.trials.trialDetailError);

    const customerSection = trial_details ? (
        <tr>
            <td className="icon-img">
                <img src="/images/icon-hospital.png" alt="hospital icon" />
            </td>
            <td className="file-details">
                <ul className="">
                    <li>{ trial_details.name }</li>
                    <li className="length">
                        { trial_details.address } { trial_details.city } { trial_details.zipcode } <span className="uploaded"><br />{ trial_details.total_trial } TRIALS</span>
                    </li>
                </ul>
            </td>
        </tr>
    ) : (<tr><td><ComponentLoading message="Loading..." /></td></tr>);

    const isFetchingTrialPeople = useSelector(state => state.trials.isFetchingTrialPeople);
    const trialPeople = useSelector(state => state.trials.trialPeople);
    const trialPeopleError = useSelector(state => state.trials.trialPeopleError);

    const stakeholders = trialPeople ? trialPeople.filter(person => person.type === "stakeholder") : ([]);
    const surgeons = trialPeople ? trialPeople.filter(person => person.type === "surgeon") : ([]);

    const { information, speciality } = useSelector(state => state.trials.trialMain);

    const [ confirmDeleteTrialModal, setConfirmDeleteTrialModal ] = useState(false);
    const toggleConfirmDeleteTrialModal = () => setConfirmDeleteTrialModal(previousConfirmDeleteTrialModal => !previousConfirmDeleteTrialModal);

    const [ currentlyEditingSpecialities, setCurrentlyEditingSpecialities ] = useState(false);
    const toggleEditSpecialtiesCards = () => setCurrentlyEditingSpecialities(previousCurrentlyEditingSpecialities => !previousCurrentlyEditingSpecialities);

    const isFetchingCompetitorsArray = useSelector(state => state.trials.isFetchingCompetitorsArray)
    const competitorsArray = useSelector(state => state.trials.competitorsArray);
    const competitorsError = useSelector(state => state.trials.competitorsError);

    const [ currentlyEditingCompetitors, setCurrentlyEditingCompetitors ] = useState(false);
    const toggleEditCompetitorsCards = () => setCurrentlyEditingCompetitors(previousCurrentlyEditingCompetitors => !previousCurrentlyEditingCompetitors);

    const liftSelectedAndDeselectedSpecialitiesArrays = (selectedArray, deselectedArray) => {
        selectedArray.forEach(specialitiesID => dispatch(postSpecialitiesAction({ trialID: id, specialities: specialitiesID })));
        deselectedArray.forEach(specialitiesID => dispatch(deleteSpecialitiesAction({ trialID: id, specialities: specialitiesID })));
    }

    const liftSelectedAndDeselectedCompetitorsArrays = (selectedArray, deselectedArray) => {
        selectedArray.forEach(competitorID => dispatch(postCompetitorsAction({ trialID: id, competitorID: competitorID })));
        deselectedArray.forEach(competitorID => dispatch(deleteCompetitorsAction({ trialID: id, competitorID: competitorID })));
    }

    const [ currentlyEditingTrialDetails, setCurrentlyEditingTrialDetails ] = useState(false);

    const handleEditTrialDetailsSubmit = () => {
        setCurrentlyEditingTrialDetails(previousCurrentlyEditingTrialDetails => !previousCurrentlyEditingTrialDetails);

        dispatch(putTrialInformationAction({
            ...inputs,
            trialID: id
        }));
    }

    const putTrialInformationError = useSelector(state => state.trials.putTrialInformationError);

    const { inputs, handleInputChange, handleSubmit } = useHandleSubmit({
        callback: handleEditTrialDetailsSubmit,
        initialState: {
            trial_name: '',
            start_date: '',
            budget_cycle: '',
            end_date: '',
            expected_purchase: ''
        }
    });

    useEffect(() => {
        if (information)
        {
            for (const property in information)
            {
                if (property !== 'trial_name' && information[ property ])
                {
                    handleInputChange({
                        target: {
                            name: property,
                            value: information[ property ]
                        }
                    });
                } else
                {
                    handleInputChange({
                        target: {
                            name: property,
                            value: information[ property ]
                        }
                    });
                }
            }
        }
    }, [ information ]);

    const [ selectedSpecialtiesAmount, setSelectedSpecialtiesAmount ] = useState(null);
    useEffect(() => {
        if (speciality)
        {
            let selectedSpecialities = speciality?.filter(singleSpeciality => {
                return singleSpeciality.is_selected === "1";
            });

            setSelectedSpecialtiesAmount(selectedSpecialities.length);
        }
    }, [ speciality ]);

    const [ selectedCompetitorsAmount, setSelectedCompetitorsAmount ] = useState(null);
    useEffect(() => {
        if (competitorsArray)
        {
            let selectedCompetitors = competitorsArray?.filter(competitor => {
                return competitor.is_selected === "1";
            });

            setSelectedCompetitorsAmount(selectedCompetitors.length);
        }
    }, [ competitorsArray ]);

    function handleDatePickerSelect (date) {
        handleInputChange({
            target: {
                name: this.name,
                value: formatDateToMonthDayYear(date)
            }
        });
    }

    let trialDetailsSection
    if (information)
    {
        if (currentlyEditingTrialDetails)
        {
            trialDetailsSection = (
                <form className="edit-trial-details-form">
                    <div className="edit-trial-form__group">
                        <label
                            className="edit-trial-form__label"
                            htmlFor="trial_name">
                            trial name
                        </label>
                        <input
                            onChange={ handleInputChange }
                            value={ inputs.trial_name }
                            className="edit-trial-form__input"
                            type="text"
                            name="trial_name"
                            id="trial_name" />
                    </div>
                    <div className="edit-trial-form__inputs-container">
                        <div className="edit-trial-form__group">
                            <label
                                className="edit-trial-form__label"
                                htmlFor="start_date">
                                trial start date
                            </label>

                            <div className="position-relative date-inputs-container full-width-form-element">
                                <DatePicker
                                    className="edit-trial-form__input"
                                    name="start_date"
                                    id="start_date"
                                    onSelect={ handleDatePickerSelect }
                                    onChangeRaw={ preventTypingInDateInputs }
                                    selected={ inputs.start_date ? (new Date(inputs.start_date.replace(/ /g, ''))) : ("") } />

                                <i className="las la-calendar input__icon"></i>
                            </div>
                        </div>
                        <div className="edit-trial-form__group">
                            <label
                                className="edit-trial-form__label"
                                htmlFor="budget_cycle">
                                budget cycle
                            </label>

                            <div className="position-relative date-inputs-container full-width-form-element">
                                <DatePicker
                                    className="edit-trial-form__input"
                                    name="budget_cycle"
                                    id="budget_cycle"
                                    onSelect={ handleDatePickerSelect }
                                    onChangeRaw={ preventTypingInDateInputs }
                                    selected={ inputs.budget_cycle ? (new Date(inputs.budget_cycle.replace(/ /g, ''))) : ("") } />
                                <i className="las la-calendar input__icon"></i>
                            </div>
                        </div>
                        <div className="edit-trial-form__group">
                            <label
                                className="edit-trial-form__label"
                                htmlFor="end_date">
                                trial end date
                            </label>

                            <div className="position-relative date-inputs-container full-width-form-element">
                                <DatePicker
                                    className="edit-trial-form__input"
                                    name="end_date"
                                    id="end_date"
                                    onSelect={ handleDatePickerSelect }
                                    onChangeRaw={ preventTypingInDateInputs }
                                    selected={ inputs.end_date ? (new Date(inputs.end_date.replace(/ /g, ''))) : ("") } />
                                <i className="las la-calendar input__icon"></i>
                            </div>
                        </div>
                        <div className="edit-trial-form__group">
                            <label
                                className="edit-trial-form__label"
                                htmlFor="expected_purchase">
                                expected purchase
                            </label>

                            <div className="position-relative date-inputs-container full-width-form-element">
                                <DatePicker
                                    className="edit-trial-form__input"
                                    name="expected_purchase"
                                    id="expected_purchase"
                                    onSelect={ handleDatePickerSelect }
                                    onChangeRaw={ preventTypingInDateInputs }
                                    selected={ inputs.expected_purchase ? (new Date(inputs.expected_purchase.replace(/ /g, ''))) : ("") } />
                                <i className="las la-calendar input__icon"></i>
                            </div>
                        </div>
                    </div>
                </form>
            );
        } else
        {
            trialDetailsSection = (
                <div>
                    <div className="trial-name">
                        <div className="label-sml">TRIAL NAME</div>
                        <div className={ classes[ "trial-details__information" ] }>
                            { information.trial_name }
                        </div>
                    </div>
                    <ul className="mt-3 pt-3">
                        <li className="trial-name">
                            <div className="label-sml">TRIAL START DATE</div>
                            <div className={ classes[ "trial-details__information" ] }>
                                { information.start_date }
                            </div>
                        </li>
                        <li className="trial-name mt-3">
                            <div className="label-sml">TRIAL END DATE</div>
                            <div className={ classes[ "trial-details__information" ] }>
                                { information.end_date }
                            </div>
                        </li>
                    </ul>
                    <ul className="mt-3 pt-3">
                        <li className="trial-name">
                            <div className="label-sml">BUDGET CYCLE</div>
                            <div className={ classes[ "trial-details__information" ] }>
                                { information.budget_cycle }
                            </div>
                        </li>
                        <li className="trial-name mt-3">
                            <div className="label-sml">EXPECTED PURCHASE</div>
                            <div className={ classes[ "trial-details__information" ] }>
                                { information.expected_purchase }
                            </div>
                        </li>
                    </ul>
                </div>
            );
        }
    } else
    {
        trialDetailsSection = (
            <ComponentLoading message="Loading..." />
        );
    }

    return (
        <>
            <Container className="page pb-80">
                <Row className="tab-title-main">
                    <Col sm={ 12 }>
                        <h1 className="section-title mar-tp-neg12">Trial summary </h1>
                    </Col>
                </Row>

                <Row>
                    <Col sm={ 12 }>
                        <div className="tab-text pb-0">
                            <div className={ [ classes[ "trial-summary__card-title" ], "tab-title" ].join(" ") }>Customer</div>
                            <div className="line-blk-shrink mb-1" />
                            <table className="table talktracks-single">
                                <tbody>
                                    { customerSection }
                                </tbody>
                            </table>
                        </div>

                        <div className="tab-text">
                            <div className={ [ classes[ "trial-summary__card-title" ], "tab-title" ].join(" ") }>
                                Trial details

                                { information ? (
                                    currentlyEditingTrialDetails ? (
                                        <button
                                            className={ classes[ "trial-summary__card-complete-button" ] }
                                            onClick={ handleSubmit }>
                                            DONE
                                        </button>
                                    ) : (
                                            <i
                                                onClick={ () => setCurrentlyEditingTrialDetails(true) }
                                                className={ [ "las la-edit", classes[ "trial-summary__pencil-icon" ] ].join(" ") }></i>
                                        )
                                ) : (null) }
                            </div>
                            <div className="line-blk-shrink" />

                            { trialDetailsSection }
                        </div>
                    </Col>
                </Row>

                <TrialPeopleTable
                    accountID={ trial_details?.account_id }
                    people={ stakeholders }
                    type="Stakeholders"
                    isLoading={ isFetchingTrialPeople } />
                <TrialPeopleTable
                    accountID={ trial_details?.account_id }
                    people={ surgeons }
                    type="Surgeons"
                    isLoading={ isFetchingTrialPeople } />

                <Row>
                    <Col sm={ 12 }>
                        <div className="tab-text mt-0" >
                            <div className={ [ classes[ "trial-summary__card-title" ], "tab-title" ].join(" ") }>
                                Specialties

                                { speciality ? (
                                    currentlyEditingSpecialities ? (
                                        <button
                                            className={ classes[ "trial-summary__card-complete-button" ] }
                                            onClick={ toggleEditSpecialtiesCards }>
                                            DONE
                                        </button>
                                    ) : (
                                            <i
                                                onClick={ toggleEditSpecialtiesCards }
                                                className={ [ "las la-edit", classes[ "trial-summary__pencil-icon" ] ].join(" ") }></i>
                                        )
                                ) : (null) }
                            </div>
                            <div className="line-blk-shrink"> </div>
                            <div className={ [ classes[ "trial-summary__card-images-container" ], currentlyEditingSpecialities ? ("hidden") : (null) ].join(" ") }>
                                { speciality?.length ? (
                                    speciality.map(SummaryList)
                                ) : (
                                        speciality?.length === 0 ? (
                                            <NoResults />
                                        ) : (
                                                <ComponentLoading message="Loading..." />
                                            )
                                    ) }

                                { selectedSpecialtiesAmount === 0 && speciality?.length !== 0 ? (
                                    <NoResults message="no specialties selected" />
                                ) : (null) }
                            </div>
                            <EditCards
                                liftSelectedAndDeselectedArrays={ liftSelectedAndDeselectedSpecialitiesArrays }
                                currentlyEditing={ currentlyEditingSpecialities }
                                dataFromAPI={ speciality ? (speciality) : [] }
                                type="speciality" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={ 12 }>
                        <div className="tab-text mt-0">
                            <div className={ [ classes[ "trial-summary__card-title" ], "tab-title" ].join(" ") }>
                                Competitors

                                { isFetchingCompetitorsArray ? (null) : (
                                    currentlyEditingCompetitors ? (
                                        <button
                                            className={ classes[ "trial-summary__card-complete-button" ] }
                                            onClick={ toggleEditCompetitorsCards }>
                                            DONE
                                        </button>
                                    ) : (
                                            <i
                                                onClick={ toggleEditCompetitorsCards }
                                                className={ [ "las la-edit", classes[ "trial-summary__pencil-icon" ] ].join(" ") }></i>
                                        )
                                ) }
                            </div>
                            <div className="line-blk-shrink" />
                            <div className={ [ classes[ "trial-summary__card-select-container--competitors" ], classes[ "trial-summary__card-images-container" ], currentlyEditingCompetitors ? ("hidden") : (null) ].join(" ") }>
                                { competitorsArray?.length ? (
                                    competitorsArray.map(SummaryList)
                                ) : (
                                        competitorsArray?.length === 0 && !isFetchingCompetitorsArray ? (
                                            <NoResults />
                                        ) : (
                                                <ComponentLoading message="Loading..." />
                                            )
                                    ) }

                                { selectedCompetitorsAmount === 0 && competitorsArray?.length !== 0 && !isFetchingCompetitorsArray ? (
                                    <NoResults message="no competitors selected" />
                                ) : (null) }
                            </div>
                            <EditCardCompetitors
                                liftSelectedAndDeselectedArrays={ liftSelectedAndDeselectedCompetitorsArrays }
                                currentlyEditing={ currentlyEditingCompetitors }
                                dataFromAPI={ competitorsArray ? (competitorsArray) : [] }
                                type="competitor" />
                        </div>
                    </Col>
                    {/* <button className="btn-large-edit mt-3" disabled>EDIT TRIAL</button> */ }
                    <button
                        onClick={ toggleConfirmDeleteTrialModal }
                        className="btn-large-coral mt-3">
                        DELETE TRIAL
                    </button>
                </Row>
            </Container>
            <Modal
                isOpen={ confirmDeleteTrialModal }
                toggle={ toggleConfirmDeleteTrialModal }
                centered
                backdrop="static">
                <CloseModalButton onClick={ toggleConfirmDeleteTrialModal } />

                <ConfirmDeleteTrialModal toggleConfirmDeleteTrialModal={ toggleConfirmDeleteTrialModal } />
            </Modal>
        </>
    );
}


export default TrialSummary;
