import React from 'react';

import classes from './CreateRatingCircles.module.css'

export default ({ amountOfCircles, classesToAdd }) => {
    const MAX = 5;
    let circles = [];
    let coloredCircles = parseInt(amountOfCircles, 10);
    let remainingCircles = MAX - coloredCircles;

    for (let i = 0; i < coloredCircles; i++)
    {
        circles.push(<div key={ i } className={ [ classes[ 'circle' ], classes[ `circle-${ i }` ], classesToAdd ].join(' ') }></div>)
    }

    if (remainingCircles)
    {
        for (let i = 0; i < remainingCircles; i++)
        {
            circles.push(<div key={ i + coloredCircles } className={ [ classes[ 'circle' ], classes[ 'circle--border' ], classesToAdd ].join(" ") }></div>);
        }

    }
    return circles;
}