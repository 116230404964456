import React, { useState, useEffect } from 'react';
import classes from './DeleteComment.module.css';
import { deleteChecklistCommentAction } from '../../../../../Store/actions/checklistsAction';
import { useDispatch, useSelector } from 'react-redux';
import useHandleSubmit from '../../../../../Hooks/useHandleSubmit';

const DeleteComment = ({ toggleModal, trialID, item, comment }) => {
	const dispatch = useDispatch();

	const handleCancelClick = (ev) => {
		ev.preventDefault();
		toggleModal();
	};

	const handleChecklistSubmit = () => {
		dispatch(deleteChecklistCommentAction({ trialID: trialID, itemID: inputs.itemId, commentID: comment.id }));
		toggleModal();
	};

	const { inputs, handleInputChange, handleSubmit } = useHandleSubmit({
		callback: handleChecklistSubmit,
		initialState: {
			itemId: item.id
		}
	});

	return (
		<form className={classes['form']} onSubmit={handleSubmit}>
			<label className={classes['form__label']} htmlFor="addChecklist">
				Are you sure?
			</label>
			<p>
				<b>You are deleting this comment from CRUSH.</b>
				<br />
				<br />
				All information related to this comment will be permanently removed. This can not be undone.
			</p>
			<div className={classes['form__buttons-container']}>
				<button
					onClick={handleCancelClick}
					className={[ classes['form__button'], classes['form__button--cancel'] ].join(' ')}
				>
					cancel
				</button>
				<button className={[ classes['form__button'], classes['form__button--cansubmit'] ].join(' ')}>
					delete
				</button>
			</div>
		</form>
	);
};

export default DeleteComment;
