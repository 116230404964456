import React, { Component } from 'react';
import { Modal } from 'reactstrap';

import CreateOrEditClasses from '../CreateOrEdit/CreateOrEdit.module.css';
import classes from './DeletePopUp.module.css';

import { deleteSurveyAction } from '../../../../Store/actions/surveysAction';
import { deleteEvaluationAction } from '../../../../Store/actions/evaluationsAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CloseModalButton from '../../../../Components/CloseModalButton/CloseModalButton';

class DeletePopUp extends Component {
	state = {
		templateName: ''
	};

	cancelPopUp = (ev) => {
		ev.preventDefault();
		this.props.toggleSurveyAndEvaluationDeletePopUp();
	};

	cloneTemplate = (saveInto) => {
		const { trialID, id, type } = this.props.match.params;

		const templateName = this.state.templateName === '' ? this.props.templateName : this.state.templateName;

		const parameters = {
			trialID: trialID,
			templateID: id,
			templateName: templateName,
			saveInto: saveInto
		};

		type === 'survey' ? this.props.deleteSurvey(parameters) : this.props.deleteEvaluation(parameters);
	};

	handleSurveyNameChange = (ev) => {
		this.setState({
			templateName: ev.currentTarget.value
		});
	};

	render() {
		const { surveyAndEvaluationDeletePopUpIsOpen, toggleSurveyAndEvaluationDeletePopUp } = this.props;
		return (
			<Modal
				className={classes['form-duplicate-pop-up']}
				id="form-duplicate-survey-and-evaluation"
				isOpen={surveyAndEvaluationDeletePopUpIsOpen}
				toggle={toggleSurveyAndEvaluationDeletePopUp}
				backdrop="static"
			>
				<CloseModalButton onClick={ toggleSurveyAndEvaluationDeletePopUp } />
				
				<form className={classes['form-duplicate']}>
					<h2 className={classes['form-duplicate__title']}>Delete template</h2>
					<p>The responses will also be deleted and cannot be undone. Are you sure you want to delete?</p>
					<button
						className={classes['form-duplicate__submit']}
						onClick={(e) => {
							e.preventDefault();
							this.cloneTemplate('trial');
						}}
					>
						delete
					</button>

					<button onClick={this.cancelPopUp} className={classes['form-duplicate__cancel']}>
						cancel
					</button>
				</form>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		deleteSurveyAndEvaluationError: state.surveysAndEvaluations.deleteSurveyAndEvaluationError
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		deleteSurvey: (parameters) => {
			dispatch(deleteSurveyAction(parameters));
		},
		deleteEvaluation: (parameters) => {
			dispatch(deleteEvaluationAction(parameters));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeletePopUp));
