import React from 'react';

import LogoPreview from '../../../Shared/LogoPreview/LogoPreview';
import Footer from '../../../Shared/Footer/Footer';
import DisplayChart from '../../../../../../Components/DisplayChart/DisplayChart';
import DisplayBarChart from '../../../../../../Components/DisplayBarChart/DisplayBarChart';

import classes from '../../../EmailPreview/WeeklyEngagement/WeeklyEngagement.module.css';
import emailPreviewClasses from '../../../EmailPreview/EmailPreview.module.css';
import customizeEmailClasses from '../../CustomizeEmail.module.css';

const WeeklyEngagement = ({ emailPreview }) => {
    const emailPreviewContent = emailPreview?.content.split(/[\r\n]+/g);

    return (
        <div>
            <LogoPreview />

            <div className={ classes[ "weekly-engagement__header" ] }>
                <h2 className={ classes[ "weekly-engagement__header-heading" ] }>
                    { emailPreview.title } <span className={ classes[ "weekly-engagement__header-heading-subtext" ] }> { emailPreview.sub_title } </span>
                </h2>
                <div className={ classes[ "weekly-engagement__header-image-container" ] }>
                    <img
                        className={ classes[ "weekly-engagement__header-image" ] }
                        src="/images/email/popup-email-bg.png"
                        alt="workstation" />
                </div>
                <p className={ classes[ "weekly-engagement__header-text" ] }>
                    { emailPreview.title_description }
                </p>
            </div>

            <div className={ classes[ "weekly-engagement__paragraph-container" ] }>
                { emailPreviewContent?.map((item, index) => {
                    return (
                        <p
                            key={ index }
                            className={ emailPreviewClasses[ "email-preview__paragraph" ] }>
                            { item }
                        </p>
                    );
                }) }
            </div>

            { emailPreview.weekly_statistic_is_on === "1" ? (
                <div className={ classes[ "weekly-engagement__section" ] }>
                    <h2 className={ classes[ "weekly-engagement__section-heading" ] }>
                        Weekly <br /> <span className={ classes[ "weekly-engagement__section-heading-subtext" ] }>Statistics</span>
                    </h2>
                    <div className={ [ emailPreviewClasses[ "ribbon" ], "m-0" ].join(" ") }></div>

                    <div className={ customizeEmailClasses[ "weekly-engagement__charts-preview" ] }>
                        { emailPreview?.weekly_statistic?.map(weeklyStatistic => {
                            return weeklyStatistic.questions_type === "rating" ? (
                                <DisplayBarChart
                                    key={ weeklyStatistic.id }
                                    chartStatistics={ weeklyStatistic }
                                    removeButtons={ true }
                                    onConfirmAndReviewPage={ true } />
                            ) : (
                                    <DisplayChart
                                        key={ weeklyStatistic.id }
                                        chartStatistics={ weeklyStatistic }
                                        removeButtons={ true }
                                        onConfirmAndReviewPage={ true } />
                                );
                        }) }
                    </div>
                </div>
            ) : (null) }

            { emailPreview.mission_statistic_is_on === "1" ? (
                <div className={ classes[ "weekly-engagement__mission" ] }>
                    <div className={ classes[ "weekly-engagement__mission-title" ] }>
                        { emailPreview.mission_title }
                    </div>

                    <div className={ classes[ "weekly-engagement__mission-text" ] }>
                        { emailPreview.mission_description }
                    </div>
                </div>
            ) : (null) }

            <Footer text={ emailPreview.footer } />
        </div>
    )
}

export default WeeklyEngagement;