import * as ActionTypes from '../actions/ActionTypes';

const initState = {
    error: '',
    userData: {},
    postUserInformationError: '',
    BU: [],
    BUError: '',
    BUPostError: ''
}

const tracksReducers = (state = initState, action) => {
    switch (action.type)
    {
        case ActionTypes.POST_LOGIN_SUCCESS:
            return {
                ...state,
                error: '',
                userData: action.userData
            }

        case ActionTypes.POST_LOGIN_ERROR:
            return {
                ...state,
                error: action.error
            }

        case ActionTypes.POST_USER_INFORMATION_SUCCESS:
            return {
                ...state,
                postUserInformationError: '',
                userData: action.userData
            }

        case ActionTypes.POST_USER_INFORMATION_ERROR:
            return {
                ...state,
                postUserInformationError: action.postUserInformationError
            }

        case ActionTypes.GET_BU_SUCCESS:
            return {
                ...state,
                BU: action.BU,
                BUPostError: '',
                BUError: ''
            }

        case ActionTypes.GET_BU_ERROR:
            return {
                ...state,
                BUError: action.BUError
            }

        case ActionTypes.POST_BU_ERROR:
            return {
                ...state,
                BUPostError: action.BUPostError
            }

        default:
            return state;
    }
}

export default tracksReducers;