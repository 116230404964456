/*---------------- COMPETITIVE INTELS     --------------------*/

export const intels = [
  {
    id: 1,
    type: "folder",
    name: "My Example Folder Name A",
    thumbnail: "/images/icon-folder.jpg",
    modifiedDate: "MODIFIED OCT 17, 2018",
    category: { id: "99999", name: "downloaded" },
    favorite: false,
  },
  {
    id: 2,
    type: "pdf",
    name: "Example fileName U.pdf",
    thumbnail: "/images/example2.jpg",
    modifiedDate: "MODIFIED JAN 17, 2020",
    category: { id: "88888", name: "update" },
    favorite: true,
  },
  {
    id: 3,
    type: "pdf",
    name: "Example fileName C.pdf",
    thumbnail: "/images/example.jpg",
    modifiedDate: "MODIFIED JAN 17, 2020",
    category: { id: "99999", name: "downloaded" },
    favorite: true,
  },
  {
    id: 4,
    name: "Example fileName X.mp4",
    thumbnail: "/images/example4.jpg",
    modifiedDate: "MODIFIED MAY 17, 2019",
    category: "",
    favorite: true,
  },
  {
    id: 5,
    type: "pdf",
    name: "Example fileName E.pdf",
    thumbnail: "/images/example3.jpg",
    modifiedDate: "MODIFIED MAY 17, 2019",
    category: "",
    favorite: true,
  },
  {
    id: 6,
    type: "folder",
    name: "My Example Folder Name F",
    thumbnail: "/images/icon-folder.jpg",
    modifiedDate: "MODIFIED MAY 17, 2019",
    category: { id: "99999", name: "downloaded" },
    favorite: false,
  },
  {
    id: 7,
    type: "folder",
    name: "My Example Folder Name W",
    thumbnail: "/images/icon-folder.jpg",
    modifiedDate: "MODIFIED MAY 17, 2019",
    category: "",
    favorite: false,
  },
  {
    id: 8,
    type: "pdf",
    name: "Example fileName L.pdf",
    thumbnail: "/images/example3.jpg",
    modifiedDate: "MODIFIED MAY 17, 2019",
    category: "",
    favorite: false,
  },
  {
    id: 9,
    name: "Example fileName Q.mp4",
    thumbnail: "/images/example4.jpg",
    modifiedDate: "MODIFIED MAY 17, 2019",
    category: { id: "99999", name: "downloaded" },
    favorite: false,
  },
  {
    id: 10,
    type: "pdf",
    name: "Example fileName T.mp4",
    thumbnail: "/images/example4.jpg",
    modifiedDate: "MODIFIED MAY 17, 2019",
    category: { id: "99999", name: "downloaded" },
    favorite: true,
  },
];
export function getIntels () {
  return intels.filter(i => i);
}

export const activities = [
  { id: "", name: "All files" },
  { id: "99999", name: "downloaded" },
];

export function getCategories () {
  return activities.filter(a => a);
}

/*---------------- TALK TRACKS  --------------------*/
export const talkTracks = [
  {
    id: 110000000,
    type: "track",
    name: "Arthroscopy",
    noOfTracks: 18,
    active: "active",
    category: { id: "8881", name: "Specialty" },
  },
  {
    id: 120000000,
    type: "track",
    name: "Bariatric",
    noOfTracks: 16,
    active: "disabled",
    category: { id: "8881", name: "Specialty" },
  },
  {
    id: 1300000000,
    type: "track",
    name: "Coloretal",
    noOfTracks: 14,
    active: "disabled",
    category: { id: "8881", name: "Specialty" },
  },
  {
    id: 1400000000,
    type: "track",
    name: "ENT",
    noOfTracks: 13,
    active: "disabled",
    category: { id: "8881", name: "Specialty" },
  },
  {
    id: 1500000000,
    type: "track",
    name: "General",
    noOfTracks: 18,
    active: "disabled",
    category: { id: "8881", name: "Specialty" },
  },
  {
    id: 1600000000,
    type: "track",
    name: "GYN",
    noOfTracks: 18,
    active: "disabled",
    category: { id: "8881", name: "Specialty" },
  },
  {
    id: 1700000000,
    type: "track",
    name: "Skull",
    noOfTracks: 18,
    active: "disabled",
    category: { id: "8881", name: "Specialty" },
  },
  {
    id: 1800000000,
    type: "track",
    name: "Urology",
    noOfTracks: 18,
    active: "disabled",
    category: { id: "8881", name: "Specialty" },
  },
  {
    id: 1900000000,
    type: "track",
    name: "VATS",
    noOfTracks: 18,
    active: "disabled",
    category: { id: "8881", name: "Specialty" },
  },
  {
    id: 210000000,
    type: "track",
    name: "Anesthesia",
    noOfTracks: 18,
    category: { id: "8882", name: "Stakeholder" },
  },
  {
    id: 220000000,
    type: "track",
    name: "C-Suite",
    noOfTracks: 16,
    category: { id: "8882", name: "Stakeholder" },
  },
  {
    id: 23000000,
    type: "track",
    name: "IT",
    noOfTracks: 14,
    category: { id: "8882", name: "Stakeholder" },
  },
  {
    id: 230000000,
    type: "track",
    name: "OR Administration",
    noOfTracks: 13,
    category: { id: "8882", name: "Stakeholder" },
  },
  {
    id: 240000000,
    type: "track",
    name: "VAC Member",
    noOfTracks: 18,
    category: { id: "8882", name: "Stakeholder" },
  },
  {
    id: 250000000,
    type: "track",
    name: "VP Perioptic Services",
    noOfTracks: 18,
    category: { id: "8882", name: "Stakeholder" },
  }
]

export const TalkSpecialty = [
  {
    id: 110000000,
    type: "track",
    name: "Arthroscopy",
    noOfTracks: 18,
    active: "active",
    category: "Arthroscopy",
  },
  {
    id: 120000000,
    type: "track",
    name: "Bariatric",
    noOfTracks: 16,
    active: "disabled",
    category: "Bariatric",
  },
  {
    id: 1300000000,
    type: "track",
    name: "Coloretal",
    noOfTracks: 14,
    active: "disabled",
    category: "Coloretal",
  },
  {
    id: 1400000000,
    type: "track",
    name: "ENT",
    noOfTracks: 13,
    active: "disabled",
    category: "ENT",
  },
  {
    id: 1500000000,
    type: "track",
    name: "General",
    noOfTracks: 18,
    active: "disabled",
    category: "General",
  },
  {
    id: 1600000000,
    type: "track",
    name: "GYN",
    noOfTracks: 18,
    active: "disabled",
    category: "GYN",
  },
  {
    id: 1700000000,
    type: "track",
    name: "Skull",
    noOfTracks: 18,
    active: "disabled",
    category: "Skull",
  },
  {
    id: 1800000000,
    type: "track",
    name: "Urology",
    noOfTracks: 18,
    active: "disabled",
    category: "Urology",
  },
  {
    id: 1900000000,
    type: "track",
    name: "VATS",
    noOfTracks: 18,
    active: "disabled",
    category: "VATS",
  },
]

export const TalkStakeholder = [
  {
    id: 210000000,
    type: "track",
    name: "Anesthesia",
    noOfTracks: 18,
  },
  {
    id: 220000000,
    type: "track",
    name: "C-Suite",
    noOfTracks: 16,
  },
  {
    id: 23000000,
    type: "track",
    name: "IT",
    noOfTracks: 14,
  },
  {
    id: 230000000,
    type: "track",
    name: "OR Administration",
    noOfTracks: 13,
  },
  {
    id: 240000000,
    type: "track",
    name: "VAC Member",
    noOfTracks: 18,
  },
  {
    id: 250000000,
    type: "track",
    name: "VP Perioptic Services",
    noOfTracks: 18,
  }
]

export const anesthesia = [
  {
    id: 111001010,
    name: "placeholder file A",
    active: "active"
  },
  {
    id: 111001011,
    name: "placeholder file B",
    active: "active"
  },
  {
    id: 111001012,
    name: "placeholder file C",
    active: "active"
  },
]
export const anthroscopy = [
  {
    id: 111001000,
    name: "1588",
    active: "active"
  },
  {
    id: 111002000,
    name: "IRIS",
    active: "disabled"
  },
  {
    id: 111003000,
    name: "Clarity",
    active: "disabled"
  },
  {
    id: 111004000,
    name: "General",
    active: "disabled"
  },
  {
    id: 111005000,
    name: "DRE",
    active: "disabled"
  },
  {
    id: 111006000,
    name: "De-SAT",
    active: "disabled"
  },
  {
    id: 111007000,
    name: "Device Control",
    active: "disabled"
  },
  {
    id: 111008000,
    name: "Voice Control",
    active: "disabled"
  },
  {
    id: 111009000,
    name: "Cloud Surgeon Profiles",
    active: "disabled"
  },
  {
    id: 111010000,
    name: "Connectivity",
    active: "disabled"
  },
  {
    id: 111011000,
    name: "Precision AC Camera",
    active: "disabled"
  },
  {
    id: 111012000,
    name: "Auto Annotations",
    active: "disabled"
  },
  {
    id: 111013000,
    name: "PneumoSure",
    active: "disabled"
  },
  {
    id: 111014000,
    name: "PneumoClear",
    active: "disabled"
  },
  {
    id: 111015000,
    name: "CrossFire2",
    active: "disabled"
  },
  {
    id: 111016000,
    name: "CrossBlade",
    active: "disabled"
  },
  {
    id: 111017000,
    name: "Formula",
    active: "disabled"
  },
  {
    id: 111018000,
    name: "CrossFlow",
    active: "disabled"
  },

]

export const A1588 = [
  {
    id: 111001000,
    name: "1588",
    questions: [
      {
        id: 1,
        question: " Do you ever have challenges seeing deep into posterior compartment of the knee when doinga menisectomy due to lighting?"
      },
      {
        id: 2,
        question: "Do you feel your image quality or visualization is challeneged during significant resections or debridements?"
      },
      {
        id: 3,
        question: "Would it be useful if you could maintain your image quality throughout these procedures?"
      }
    ],
    points: [
      {
        id: 11,
        point: "The 1588 AIM platform is designed to enhance visualization by highlighting the posterior compartment with DRE and improving visual acuity."
      },
      {
        id: 12,
        point: "Device Control allows the user to have full control over critical devices in the OR from programmable buttons on the Camera Head."
      },
      {
        id: 13,
        point: "9 Surgical Specialties built into the camera to address the specific color and lighting needs of individual surgical procedures."
      },
    ],
    audio: [
      {
        id: 11002001,
        name: "Example Podcase"
      },
      {
        id: 11002002,
        name: "1588 Arthroscopy Interview"
      },
      {
        id: 11002003,
        name: "1588 Testimony"
      },
      {
        id: 11002004,
        name: "Example Audio Clip"
      },
      {
        id: 11002005,
        name: "Example Audio Clip with a Lighting"
      },
      {
        id: 11002006,
        name: "1588 Anthroscopy Interview"
      },
      {
        id: 11002007,
        name: "1588 Testimony"
      },
    ]
  }
]

export const audio = [
  {
    id: 911001000,
    name: "Example Podcast",
    thumbnail: "/images/icon-audio.jpg",
    length: "1 HR 22 MIN",
    uploaded: "UPLOADED: 10 - 01 -2015"
  },
  {
    id: 911002000,
    name: "1588 Anthroscopy Interview",
    thumbnail: "/images/icon-audio.jpg",
    length: "34 MIN",
    uploaded: "UPLOADED: 10 - 01 -2015"
  },
  {
    id: 911003000,
    name: "Example Testimony",
    thumbnail: "/images/icon-audio.jpg",
    length: "1 HR 46 MIN",
    uploaded: "UPLOADED: 10 - 01 -2015"
  },
  {
    id: 911004000,
    name: "Example Audio Clip",
    thumbnail: "/images/icon-audio.jpg",
    length: "18 MIN",
    uploaded: "UPLOADED: 10 - 01 -2015"
  },
  {
    id: 911005000,
    name: "Example Audio Clip with Lecture",
    thumbnail: "/images/icon-audio.jpg",
    length: "1 HR 22 MIN",
    uploaded: "UPLOADED: 10 - 01 -2015"
  },
]

/* export function getTalkTracks () {
 return talkTracks.filter(i => i);
} */

/* Level-1 */
export function getTalkSpecialty () {
  return TalkSpecialty.filter(i => i);
}
/* Level-1 */
export function getTalkStakeholder () {
  return TalkStakeholder.filter(i => i);
}
/* Level-1: Between Specialty & Stakeholder */
export const categoriesTalkT = [
  { id: "8881", name: "Specialty" },
  { id: "8882", name: "Stakeholder" },
];

/* Level-1: Between Specialty & Stakeholder */
export function getCategoriesTalkT () {
  return categoriesTalkT.filter(a => a);
}


/* Level-2 (Sub) -- anthroscopy is representational
results: 1588, Auto Annotation, Clarity,... */
export function getAnthroscopy () {
  return anthroscopy.filter(i => i);
}
export function getAnesthesia () {
  return anesthesia.filter(i => i);
}

/* Level-3 (SubSingle) -- details for a single file
 data here is representational*/
export function getA1588 () {
  return A1588;
}


/*----------        ----------------*/
export const camera1688 = [

  {
    id: 111,
    type: "pdf",
    name: "A Example fileName.pdf",
    thumbnail: "/images/example5.jpg",
    modifiedDate: "MODIFIED JAN 17, 2020",
    downloaded: "DOWNLOADED",
    favorite: true,
  },
  {
    id: 222,
    type: "pdf",
    name: "C Example fileName.pdf",
    thumbnail: "/images/example7.jpg",
    modifiedDate: "MODIFIED JAN 17, 2020",
    downloaded: "DOWNLOADED",
    favorite: false,
  },
  {
    id: 333,
    name: "B Example fileName.mp4",
    thumbnail: "/images/example6.jpg",
    modifiedDate: "MODIFIED MAY 17, 2019",
    downloaded: "DOWNLOADED",
    favorite: false,
  },
]

/*----------  TRIALS  ----------------*/
export const trials = [
  {
    id: 1110000,
    name: "A Valley View Med SPY Trial",
    institution: "VALLEY VIEW MEDICAL CENTER",
    createdDate: "CREATED: 01 - 15 - 2020",
    startDate: "02-04-2020",
    endDate: "11-04-2020",
    active: true,
    status: { id: "991", name: "Active trials" },
    people: [
      {
        id: 222220,
        name: "Peter Halfinger"
      },
      {
        id: 222221,
        name: "Douglas Smith"
      }, {
        id: 222222,
        name: "Elaine Chapman"
      }, {
        id: 222223,
        name: "Niles Peppertrout"
      },
      {
        id: 222224,
        name: "Charles Douglas"
      },
    ],
    speciales: [
      {
        id: 333330,
        name: "BARIATRIC",
        status: false
      },
      {
        id: 333331,
        name: "COLORECTAL",
        status: false
      },
      {
        id: 333332,
        name: "CV",
        status: false
      },
      {
        id: 333333,
        name: "ENT",
        status: false
      },
      {
        id: 333334,
        name: "GENERAL",
        status: false
      },
      {
        id: 333335,
        name: "GI",
        status: false
      },
      {
        id: 333336,
        name: "ONC",
        status: false
      },
      {
        id: 333337,
        name: "ORTHO",
        status: false
      },
      {
        id: 333338,
        name: "PEDIATRIC",
        status: false
      },
      {
        id: 333339,
        name: "SKULL",
        status: false
      },
      {
        id: 333340,
        name: "URO",
        status: false
      },
      {
        id: 333341,
        name: "GYN",
        status: false
      },
      {
        id: 333342,
        name: "BREAST/RECON",
        status: false
      },
      {
        id: 333343,
        name: "TRAUMA",
        status: true
      }
    ],
    competitors: [
      {
        id: 444440,
        name: "ARTHREX",
        status: false
      },
      {
        id: 444441,
        name: "CONMED LINVATEC",
        status: true
      },
      {
        id: 444442,
        name: "DEPUY MITEK",
        status: false
      },
      {
        id: 444443,
        name: "OLYMPUS",
        status: false
      },
      {
        id: 444444,
        name: "S&N",
        status: false
      },
      {
        id: 444445,
        name: "STERIS",
        status: false
      },
      {
        id: 444446,
        name: "STORZ",
        status: true
      }
    ]
  },
  {
    id: 111001,
    name: "Valley View 222 Intro",
    institution: "VALLEY VIEW MEDICAL CENTER",
    createdDate: "CREATED: 10 - 01 - 2015",
    active: false,
    status: { id: "992", name: "Archived trials" }
  },
  {
    id: 1110002,
    name: "Valley View Med SPY Trial",
    institution: "VALLEY VIEW MEDICAL CENTER",
    createdDate: "CREATED: 10 - 01 - 2015",
    active: true,
    status: { id: "991", name: "Active trials" }
  },
  {
    id: 1110003,
    name: "Valley View 1688 Intro",
    institution: "VALLEY VIEW MEDICAL CENTER",
    createdDate: "CREATED: 10 - 01 - 2015",
    active: true,
    status: { id: "991", name: "Active trials" }
  },
  {
    id: 1110004,
    name: "Valley View 555 Intro",
    institution: "VALLEY VIEW MEDICAL CENTER",
    createdDate: "CREATED: 10 - 01 - 2015",
    active: false,
    status: { id: "992", name: "Archived trials" }
  },
  {
    id: 1110005,
    name: "UCI Medical trial 555 Intro",
    institution: "UCI Medical Center",
    createdDate: "CREATED: 10 - 01 - 2015",
    active: true,
    status: { id: "991", name: "Active trials" }
  },
  {
    id: 1110006,
    name: "UCI Medical trial 123 Intro",
    institution: "UCI Medical Center",
    createdDate: "CREATED: 10 - 01 - 2015",
    active: false,
    status: { id: "992", name: "Archived trials" }
  },
  {
    id: 1110007,
    name: "UCI Medical trial 333 Intro",
    institution: "UCI Medical Center",
    createdDate: "CREATED: 10 - 01 - 2015",
    active: true,
    status: { id: "991", name: "Active trials" }
  },
  {
    id: 1110008,
    name: "UCI Medical trial 888 Intro",
    institution: "UCI Medical Center",
    createdDate: "CREATED: 10 - 01 - 2015",
    active: true,
    status: { id: "991", name: "Active trials" }
  }
]

export const institution = [
  {
    id: 111110,
    name: "Valley View Medical Center",
    address: "12345 Main st, Example City, ST 12345",
    no_of_trials: 5,
    trial: [
      {
        id: 111000,
        name: "A Valley View Med SPY Trial",
      },
      {
        id: 111001,
        name: "Valley View 222 Intro"
      },
      {
        id: 1110002,
        name: "Valley View Med SPY Trial"
      },
      {
        id: 1110003,
        name: "Valley View 1688 Intro"
      },
      {
        id: 1110004,
        name: "Valley View 555 Intro"
      }
    ]
  },
  {
    id: 111111,
    name: "UCI Medical Center",
    address: "54321 University Dr, Irvine, CA 12345",
    no_of_trials: 4,
    trial: [
      {
        id: 1110005,
        name: "UCI Medical trial 555 Intro"
      },
      {
        id: 1110006,
        name: "UCI Medical trial 123 Intro"
      },
      {
        id: 1110007,
        name: "UCI Medical trial 333 Intro"
      },
      {
        id: 1110008,
        name: "UCI Medical trial 888 Intro"
      }
    ]
  }
]

export const myData = [
  {
    id: 212220,
    user_name: "Peter Halfinger",
    user_id: "ph1234",
    email: "ph@stryker.com",
    password: "1234",
    trials: [
      {
        id: 111000,
        name: "A Valley View Med SPY Trial",
        cases: [
          {
            id: 100123,
            specialty: "Colorectal case",
            procedure: "shoulder",
            surgens: "Adam Durn",
            case_date: "03.15.20",
            no_of_cases: 1,
            your_note: "This is a challenging case that took 10 hours to complete.",
            customer: "Hill Halley",
            customer_comment: "I can move my shoulder for 90 degrees up and down. Had minor aches."
          },
          {
            id: 100658,
            specialty: "CV case",
            procedure: "hip",
            surgens: "Ahley Jeff",
            case_date: "04.05.20",
            no_of_cases: 2,
            your_note: "The procedure took 6 hours to complete. Reposition the off-alignment joints.",
            customer: "Joe Joy",
            customer_comment: "Able to walk after 2 months. I was given the best care at Stryker. Thank you."
          },
          {
            id: 142944,
            specialty: "ENT cases",
            procedure: "chest",
            surgens: "Ahley Jeff",
            case_date: "03.255.20",
            no_of_cases: 1,
            your_note: "X-ray is an essential step for the procudure.",
            customer: "Lily Lam",
            customer_comment: "I do not experience any cough or congestion on my chest following the procedure."
          },
          {
            id: 142944,
            specialty: "General case",
            procedure: "thigh",
            surgens: "Marry Anne",
            case_date: "05.25.20",
            no_of_cases: 2,
            your_note: "Muscle stitches.",
            customer: "George Warm",
            customer_comment: "The surgeon explained things well and I can understand it easily."
          },
        ],
        checklists: [ {
          id: 429470,
          name: "CUSTOMIZE HUB PER SURGEON"
        },
        {
          id: 429472,
          name: "CUSTOMIZE HUB PER FACILITY"
        },
        {
          id: 4294703,
          name: "CONVERSATIONS"
        },
        {
          id: 4294724,
          name: "BIOMED"
        },
        {
          id: 4294725,
          name: "SPD CONVERSATIONS"
        },
        {
          id: 4294726,
          name: "GATHER"
        },
        {
          id: 4294727,
          name: "MISCELANEOUS"
        },
        {
          id: 4294728,
          name: "REQUEST YOUR EQUIPMENT"
        },
        {
          id: 4294729,
          name: "TALK TO STAKEHOLDERS"
        },
        {
          id: 4294730,
          name: "GET THE CART READY"
        },
        {
          id: 4294731,
          name: "DEMO PREPARATION"
        },
        {
          id: 4294732,
          name: "GET THE TABLE READY"
        },
        {
          id: 4294733,
          name: "IN-SERVICE PREPARATION"
        },
        {
          id: 4294734,
          name: "GET THE LIGHTS READY"
        },
        ]
      }
    ]
  }
]

export const trialprogress = [
  {
    id: 1111000,
    type: "trialslist",
    name: "Review your checklists",
    thumbnail: "/images/icon-checklist.png",
    completed: "13 COMPLETED",
    remaining: "13 REMAINING",
    active: "disabled inactive",
  },
  {
    id: 2222000,
    type: "trialslist",
    name: "Send engagement emails",
    thumbnail: "/images/icon-email.png",
    completed: "5 COMPLETED",
    remaining: "10 REMAINING",
    active: "active",
  },
  {
    id: 3333000,
    type: "trialslist",
    name: "Fill out surveys & evals",
    thumbnail: "/images/icon-survey.png",
    completed: "13 COMPLETED",
    remaining: "13 REMAINING",
    active: "active",
  },
  {
    id: 4444000,
    type: "trialslist",
    name: "Complete in-service training",
    thumbnail: "/images/icon-survey.png",
    completed: "20 COMPLETED",
    remaining: "0 REMAINING",
    active: "disabled inactive",
  },
]

export const mySurveysEvals = [
  {
    id: 11100011,
    type: "survey",
    name: "CRUSH Standard Survey",
    noOfQuestion: 12,
    noOfRespondent: 8,
    createdDate: "10 -01 -2018",
    active: "active",
    surveyEval: { id: "88", name: "My surveys & evals" },
  },
  {
    id: 11100012,
    type: "survey",
    name: "Valley View - Product Satisfaction Ev...",
    noOfQuestion: 28,
    noOfRespondent: 14,
    createdDate: "10 -01 -2018",
    active: "disabled",
    surveyEval: { id: "88", name: "My surveys & evals" },
  },
  {
    id: 11100013,
    type: "survey",
    name: "Valley View - Product Survey",
    noOfQuestion: 12,
    noOfRespondent: 0,
    createdDate: "10 -01 -2018",
    active: "disabled",
    surveyEval: { id: "88", name: "My surveys & evals" },
  },
  {
    id: 11100014,
    type: "survey",
    name: "General Satisfaction Evaluation",
    noOfQuestion: 15,
    noOfRespondent: 32,
    createdDate: "10 -01 -2018",
    active: "disabled",
    surveyEval: { id: "88", name: "My surveys & evals" },
  },
  {
    id: 11100015,
    type: "survey",
    name: "CRUSH Standard Survey B",
    noOfQuestion: 12,
    noOfRespondent: 0,
    createdDate: "10 -01 -2018",
    active: "disabled",
    surveyEval: { id: "88", name: "My surveys & evals" },
  },
  {
    id: 11100016,
    type: "survey",
    name: "Survey Template A",
    noOfQuestion: 12,
    noOfRespondent: 0,
    createdDate: "10 -01 -2018",
    active: "disabled",
    surveyEval: { id: "89", name: "Template" },
  },
  {
    id: 11100017,
    type: "survey",
    name: "Survey Template B",
    noOfQuestion: 12,
    noOfRespondent: 0,
    createdDate: "10 -01 -2018",
    active: "disabled",
    surveyEval: { id: "89", name: "Template" },
  },
]

export const surveyTemplates = [
  {
    id: 11100021,
    type: "survey",
    name: "1688 Survey: Staff",
    noOfQuestion: 10,
    createdDate: "10 -01 -2018",
    surveyEval: { id: "89", name: "Template" }
  },
  {
    id: 11100031,
    type: "survey",
    name: "1688 Survey: General - Surgeon",
    noOfQuestion: 7,
    createdDate: "10 -01 -2018",
  },
  {
    id: 11100041,
    type: "survey",
    name: "1688 Survey: Ortho - Surgeon",
    noOfQuestion: 8,
    createdDate: "10 -01 -2018",
  },
  {
    id: 11100051,
    type: "survey",
    name: "Survey: Staff",
    noOfQuestion: 15,
    createdDate: "10 -01 -2018",
  },
  {
    id: 11100061,
    type: "survey",
    name: "Survey: General - Surgeon",
    noOfQuestion: 10,
    createdDate: "10 -01 -2018",
  },
  {
    id: 11100071,
    type: "survey",
    name: "Survey: Ortho - Surgeon",
    noOfQuestion: 10,
    createdDate: "10 -01 -2018",
  },
]

export const responses = [
  {
    id: 1110021,
    type: "ENT",
    firstname: "Peter",
    lastname: "Halinger",
    category: "ENT",
    completeDate: "10 -01 -2018",
  },
  {
    id: 11100022,
    type: "SKULL",
    firstname: "Douglas",
    lastname: "Smith",
    category: "ENT",
    completeDate: "10 -01 -2018",
  },
  {
    id: 11100023,
    type: "CV",
    firstname: "Elaine",
    lastname: "Chapman",
    category: "ENT",
    completeDate: "10 -01 -2018",
  },
  {
    id: 11100024,
    type: "SKULL",
    firstname: "John",
    lastname: "Goldman",
    category: "ENT",
    completeDate: "10 -01 -2018",
  },
  {
    id: 11100025,
    type: "CV",
    firstname: "Niles",
    lastname: "Peppertrout",
    category: "ENT",
    completeDate: "10 -01 -2018",
  },
]

export const chooseRespondent = [
  {
    id: 1110021,
    type: "ENT",
    firstname: "Peter",
    lastname: "Halinger",
    thumbnail: "/images/dot1.jpg",
    category: "ENT",
    completed: 1,
    resGroup: { id: "78", name: "surgeon" },
  },
  {
    id: 11100022,
    type: "SKULL",
    firstname: "Douglas",
    lastname: "Smith",
    thumbnail: "/images/dot2.jpg",
    category: "ENT",
    completed: 8,
    resGroup: { id: "78", name: "surgeon" },
  },
  {
    id: 11100023,
    type: "CV",
    firstname: "Elaine",
    lastname: "Chapman",
    thumbnail: "/images/dot3.jpg",
    category: "ENT",
    completed: 18,
    resGroup: { id: "78", name: "surgeon" },
  },
  {
    id: 11100024,
    type: "SKULL",
    firstname: "John",
    lastname: "Goldman",
    thumbnail: "/images/dot4.jpg",
    category: "ENT",
    completed: 1,
    resGroup: { id: "79", name: "stakeholders" },
  },
]

export const user = [
  {
    id: 1110021,
    type: "ENT",
    firstname: "Peter",
    lastname: "Halinger",
    thumbnail: "/images/dot1.jpg",
    category: "ENT",
    completed: 1,
    completedDate: "10 -01 -2018",
    collectedBy: "John Hemingway",
  }
]

export const myAccount=
{
  id: 1110021,
  type: "ENT",
  firstname: "Peter",
  lastname: "Halinger",
  thumbnail: "/images/dot1.jpg",
  category: "ENT",
  completed: 1,
  completedDate: "10 -01 -2018",
  collectedBy: "John Hemingway",
}

export const myEmails=[
  {
    id:20000,
    name: "Pre-trial Commeunication",
    receipients: "",
    lastEdited: "",
    lastSent: "10 - 18 - 2019",
    status: "create",
    statusGroup:{ id: "22", name: "Create" },
  },
  {
    id:20100,
    name: "In-Service invite",
    receipients: "",
    lastEdited: "",
    lastSent: "10 - 18 - 2019",
    status: "create",
    statusGroup:{ id: "22", name: "Create" },
  },
  {
    id:20200,
    name: "In-Service recap",
    receipients: "",
    lastEdited: "",
    lastSent: "10 - 18 - 2019",
    status: "create",
    statusGroup:{ id: "22", name: "Create" },
  },
  {
    id:40000,
    name: "In-Service invite",
    receipients: "12",
    lastEdited: "10 - 05 - 2019",
    lastSent: "",
    status: "draft",
    statusGroup:{ id: "23", name: "Draft" },
  },
  {
    id:40100,
    name: "Weekly engagement",
    receipients: "6",
    lastEdited: "10 - 30 - 2019",
    lastSent: "",
    status: "draft",
    statusGroup:{ id: "23", name: "Draft" },
  },
  {
    id:60100,
    name: "In-Service invite",
    receipients: "6",
    lastEdited: "10 - 11- 2019",
    lastSent: "",
    status: "sent",
    statusGroup:{ id: "24", name: "Sent" },
  },
  {
    id:60200,
    name: "In-Service recap",
    receipients: "2",
    lastEdited: "10 - 11- 2019",
    lastSent: "",
    status: "sent",
    statusGroup:{ id: "24", name: "Sent" },
  },
  {
    id:60300,
    name: "In-Service invite",
    receipients: "23",
    lastEdited: "10 - 11- 2019",
    lastSent: "",
    status: "sent",
    statusGroup:{ id: "24", name: "Sent" },
  },
  {
    id:60400,
    name: "Weekly engagement",
    receipients: "8",
    lastEdited: "10 - 11- 2019",
    lastSent: "",
    status: "sent",
    statusGroup:{ id: "24", name: "Sent" },
  },
]

export const trialSummary = [
  {
    id: 1110021,
    type: "trial summary",
    name: "Valley View Medical Center",
    institution: "12345 MAIN ST, EXAMPLE CITY, ST 12345",
    thumbnail: "/images/icon-hospital.png",
    no_of_trials: 12,
    trialDetails: {
      "trialName": "Valley View Med SPY Trial",
      "startDate": "04 - 04 - 2019",
      "endDate": "04 - 04 - 2019",
      "budgetCycle": "04 - 04 - 2019",
      "expectedPurchase": "04 - 04 - 2019",
    },
  }
]
export const trialSummaryPeople = [ {
  id: 1110021,
  type: "ENT",
  firstname: "Peter",
  lastname: "Halinger",
  thumbnail: "/images/dot1.jpg",
  category: "EORTHONT",
  completed: "12 CASES COMPLETED",
},
{
  id: 11100022,
  type: "SKULL",
  firstname: "Douglas",
  lastname: "Smith",
  thumbnail: "/images/dot3.jpg",
  category: "CEO",
  completed: ""
},
{
  id: 11100023,
  type: "CV",
  firstname: "Elaine",
  lastname: "Chapman",
  thumbnail: "/images/dot2.jpg",
  category: "COO",
  completed: ""
},
{
  id: 11100024,
  type: "SKULL",
  firstname: "Niles",
  lastname: "Peppertrout",
  thumbnail: "/images/dot4.jpg",
  category: "ORTHO",
  completed: "1 CASE COMPLETED",
},
{
  id: 11100025,
  type: "SKULL",
  firstname: "Charles",
  lastname: "Douglas",
  thumbnail: "/images/dot2.jpg",
  category: "CHARGE NURSE",
  completed: "",
}
]

export const trialCases = [
  {
    id: 1234,
    name: "Colorectal case",
    noOfCases: 1,
    percentage: 16.67,
    thumbnail: "/images/icon-colorectal.png"
  },
  {
    id: 1235,
    name: "CV case",
    noOfCases: 1,
    percentage: 16.67,
    thumbnail: "/images/icon-cv.png"
  },
  {
    id: 1236,
    name: "ENT cases",
    noOfCases: 3,
    percentage: 50.00,
    thumbnail: "/images/icon-ent.png"
  },
  {
    id: 1237,
    name: "General case",
    noOfCases: 1,
    percentage: 16.67,
    thumbnail: "/images/icon-general.png"
  }
]
export const surgeons = [
  {
    id: 1110021,
    type: "ENT",
    firstname: "Louise",
    lastname: "Lou",
    category: "ENT",
    noOfCase: 1,
    lastCase: "10 -01 -2018",
  },
  {
    id: 11100022,
    type: "SKULL",
    firstname: "Ted",
    lastname: "Karlsbed",
    category: "ENT",
    noOfCase: 1,
    lastCase: "10 -01 -2018",
  },
  {
    id: 11100023,
    type: "CV",
    firstname: "Han",
    lastname: "Chapman",
    category: "ENT",
    noOfCase: 4,
    lastCase: "10 -01 -2018",
  },
  {
    id: 11100024,
    type: "SKULL",
    firstname: "Steve",
    lastname: "Wood",
    category: "ENT",
    noOfCase: 2,
    lastCase: "10 -01 -2018",
  },
  {
    id: 11100025,
    type: "CV",
    firstname: "Sidney",
    lastname: "Smith",
    category: "ENT",
    noOfCase: 1,
    createdDate: "10 -01 -2018",
  },
]

export const trialsPeople = [
  {
    id: 1110016,
    title: "Stakeholder",
    firstname: "Joseph",
    lastname: "Halley",
    category: "ORTHO",
    noOfCase: 12,
    lastCase: "10 -01 -2018",
    thumbnail: "/images/dot4.jpg",
    group: { id: "99", name: "stakeholders" }
  },
  {
    id: 11100017,
    title: "Stakeholder",
    firstname: "Douglas",
    lastname: "Cline",
    category: "ENT",
    noOfCase: 8,
    lastCase: "10 -01 -2018",
    thumbnail: "/images/dot4.jpg",
    group: { id: "99", name: "stakeholders" }
  },
  {
    id: 11100019,
    title: "Stakeholder",
    firstname: "Ken",
    lastname: "Dubios",
    category: "ENT",
    noOfCase: 18,
    lastCase: "10 -01 -2018",
    thumbnail: "/images/dot4.jpg",
    group: { id: "99", name: "stakeholders" }
  },
  {
    id: 1110021,
    title: "Surgeons",
    firstname: "Louise",
    lastname: "Lou",
    category: "ENT",
    noOfCase: 1,
    lastCase: "10 -01 -2018",
    thumbnail: "/images/dot4.jpg",
    group: { id: "98", name: "surgeon" }
  },
  {
    id: 11100022,
    title: "Surgeons",
    firstname: "Ted",
    lastname: "Karlsbed",
    category: "ENT",
    noOfCase: 1,
    lastCase: "10 -01 -2018",
    thumbnail: "/images/dot4.jpg",
    group: { id: "98", name: "surgeon" }
  },
  {
    id: 11100023,
    title: "Surgeons",
    firstname: "Han",
    lastname: "Chapman",
    category: "ENT",
    noOfCase: 4,
    lastCase: "10 -01 -2018",
    thumbnail: "/images/dot4.jpg",
    group: { id: "98", name: "surgeon" }
  },
  {
    id: 11100024,
    title: "Surgeons",
    firstname: "Steve",
    lastname: "Wood",
    category: "ENT",
    noOfCase: 2,
    lastCase: "10 -01 -2018",
    thumbnail: "/images/dot4.jpg",
    group: { id: "98", name: "surgeon" }
  },
  {
    id: 11100025,
    title: "Surgeons",
    firstname: "Sidney",
    lastname: "Smith",
    category: "ENT",
    noOfCase: 1,
    createdDate: "10 -01 -2018",
    thumbnail: "/images/dot4.jpg",
    group: { id: "98", name: "surgeon" }
  }
]

export const selectedSurvey = {
  id: 111000,
  name: "CRUSH Standard Survey",
  institution: "VALLEY VIEW MEDICAL CENTER",
  createdDate: "10 - 01 - 18",
  lastModified: "11 - 14 - 18",
  lastResponse: "04 - 23 -19",
  questions: 12,
  responsesInThisTrial: 8,
  totalResponse: 23,
  active: true,
  status: { id: "991", name: "Active trials" },
  people: [
    {
      id: 1110021,
      type: "ENT",
      firstname: "Peter",
      lastname: "Halinger",
      category: "ENT",
      completed: true,
      completeDate: "10 - 01 - 2018",
    },
    {
      id: 11100022,
      type: "SKULL",
      firstname: "Douglas",
      lastname: "Smith",
      category: "ENT",
      completed: true,
      completeDate: "10 - 01 - 2018",
    },
    {
      id: 11100023,
      type: "CV",
      firstname: "Elaine",
      lastname: "Chapman",
      category: "ENT",
      completed: true,
      completeDate: "10 - 01 - 2018",
    },
    {
      id: 11100024,
      type: "SKULL",
      firstname: "John",
      lastname: "Goldman",
      category: "ENT",
      completed: true,
      completeDate: "10 - 01 - 2018",
    },
    {
      id: 11100025,
      type: "CV",
      firstname: "Niles",
      lastname: "Peppertrout",
      category: "ENT",
      completed: true,
      completeDate: "10 - 01 - 2018",
    }
  ]
}

//People in my trial
export const myStats = {
  id: 1110016,
  title: "Stakeholder",
  firstname: "Joseph",
  lastname: "Halley",
  category: "ORTHO",
  totalCase: 27,
  totalTrials: 8,
  customer: "A Valley View Medical Center",
  email: "J.Hallwy@valleyviewmed.com",
  mobilePhone: "111-111-1111",
  workPhone: "222-222-2222",
  lastCase: "10 -01 -2018",
  thumbnail: "/images/dot4.jpg",
  group: { id: "99", name: "stakeholders" },
  myTrialHistory:
    [
      {
        id: 1010,
        name: "Valley View Med SPY Trial",
        institution: "Valley View Medical Center",
        createdDate: "10 - 01 - 2019",
        active: true,
      },
      {
        id: 1011,
        name: "Valley View 1688 Intro A",
        institution: "Valley View Medical Center",
        createdDate: "10 - 01 - 2019",
        active: false,
      },
      {
        id: 1012,
        name: "Valley View Med SPY Trial",
        institution: "Valley View Medical Center",
        createdDate: "10 - 01 - 2019",
        active: true,
      },
      {
        id: 1013,
        name: "Valley View 1688 Intro B",
        institution: "Valley View Medical Center",
        createdDate: "10 - 01 - 2019",
        active: false,
      },
      {
        id: 1014,
        name: "Valley View 1688 Intro C",
        institution: "Valley View Medical Center",
        createdDate: "10 - 01 - 2019",
        active: true,
      }
    ],
  myCaseHistory: [
    {
      id: 1020,
      name: "Shoulder Anthroscopy A",
      institution: "Valley View Medical Center",
      procedureDate: "10 - 01 - 2019",
    },
    {
      id: 1021,
      name: "Shoulder Anthroscopy B",
      institution: "Valley View Medical Center",
      procedureDate: "11 - 15 - 2016",
    },
    {
      id: 1022,
      name: "Shoulder Anthroscopy C",
      institution: "Valley View Medical Center",
      procedureDate: "12 - 08 - 2018",
    },
    {
      id: 1023,
      name: "Shoulder Anthroscopy D",
      institution: "Valley View Medical Center",
      procedureDate: "09 - 04 - 2015",
    },
    {
      id: 1024,
      name: "Shoulder Anthroscopy E",
      institution: "Valley View Medical Center",
      procedureDate: "08 - 21 - 20157",
    }
  ]
}

//Questionaire
export const crushQuestionaire=[
  {
    id:10,
    question:" Printed Picture Quality*",
    type:"scale",
    option:[]
  },
  {
    id:20,
    question:"Multi-Specialty Capabilities*",
    type:"scale",
    option:[]
  },
  {
    id:30,
    question:"Wireless Technology*",
    type:"scale",
    option:[]
  },
  {
    id:40,
    question:"How much time do you think was saved by utilizing IRIS ureteral stents for ureteral detection?",
    type:"radio",
    option:[
      {
        id:1,
        ans: "0 - 3 min"
      },
      {
        id:2,
        ans: "4 - 7 min"
      },
      {
        id:3,
        ans: "8 - 10 min"
      },
      {
        id:4,
        ans: "10+ min"
      },
    ]
  },
  {
    id:50,
    question:"Did the use of advanced imaging help you visually assess bloodflow? Did the use of advanced imaging help you visually asses bloodflow?",
    type:"radio",
    option:[
      {
        id:1,
        ans: "Yes"
      },
      {
        id:2,
        ans: "No"
      },
      {
        id:3,
        ans: "N/A"
      }
    ]
  },
  {
    id:60,
    question:"Which piece of equipment helped you in an unexpected way?*",
    type:"textarea",
    option:[]
  }
]

export function getCrushQuestionaire () {
  return crushQuestionaire.filter(i => i);
}

export function getTrials () {
  return trials.filter(i => i);
}

export const trialStatus = [
  { id: "", name: "All trials" },
  { id: "991", name: "Active trials" },
  { id: "992", name: "Archived trials" }
];

export function getStatus () {
  return trialStatus.filter(a => a);
}
export function getTrialProgress () {
  return trialprogress.filter(i => i);
}
export function getTrialCases () {
  return trialCases.filter(i => i);
}
export function getSurgeons () {
  return surgeons.filter(i => i);
}
export function getTrialsPeople () {
  return trialsPeople.filter(i => i);
}
export const trialsPeopleCat = [
  { id: "", name: "All people" },
  { id: "98", name: "Surgeons" },
  { id: "99", name: "Stakeholders" },
]

export function getGroup () {
  return trialsPeopleCat.filter(i => i);
}

export function getMySurveysEvals () {
  return mySurveysEvals.filter(a => a);
}

export const surveyEval = [
  { id: "88", name: "My surveys" },
  { id: "89", name: "Template" }
]

export function getSurveyEval () {
  return surveyEval.filter(i => i);
}

export function getChooseRespondent () {
  return chooseRespondent.filter(i => i);
}

export const respondentGroup = [
  { id: "", name: "All people" },
  { id: "78", name: "surgeon" },
  { id: "79", name: "stakeholders" },
]

export function getRespondentGroup () {
  return respondentGroup.filter(i => i);
}

export function getSelectedSurvey() {
  return selectedSurvey.filter(i => i);
}

export function getMyEmails() {
  return myEmails.filter(i => i);
}

export const statusGroups = [
  { id: "22", name: "Create" },
  { id: "23", name: "Draft" },
  { id: "24", name: "Sent" },
]

export function getEmailStatusGroup() {
  return statusGroups.filter(i => i);
}


