import React from 'react';

import EditEmail from '../EditEmail/EditEmail';

import classes from '../CustomizeEmail.module.css';

const FirstView = ({ handleInputChange, inputs, formValidationErrors, engagementEmailDataFromAPI }) => {
    return (
        <React.Fragment>
            <div className={ classes[ "section" ] }>
                <div className={ classes[ "label" ] }>
                    email template
                    <div className={ classes[ "label__horizontal-line" ] }></div>
                </div>
                <h2 className={ classes[ "section__text" ] }>{ engagementEmailDataFromAPI?.admin_template_name }</h2>
            </div>

            <form className={ classes[ "form" ] }>
                <div className={ classes[ "form-group" ] }>
                    <label
                        className={ classes[ "label" ] }
                        htmlFor="emailName">
                        email name
                        <div className={ classes[ "label__horizontal-line" ] }></div>
                    </label>
                    <input
                        onChange={ handleInputChange }
                        value={ inputs.emailName || "" }
                        className={ classes[ "input" ] }
                        name="emailName"
                        id="emailName"
                        style={ {border: 'none',
	borderBottom: '1px solid #e2e3e3',
	borderRadius: '0'}}
                        placeholder="Enter an email name (required)"
                        type="text" />
                    <div className={ classes[ "input__error" ] }>
                        { formValidationErrors.emailName }
                    </div>
                </div>
                <div className={ classes[ "form-group" ] }>
                    <label
                        className={ classes[ "label" ] }
                        htmlFor="subjectLine">
                        subject line
                        <div className={ classes[ "label__horizontal-line" ] }></div>
                    </label>
                    <input
                        onChange={ handleInputChange }
                        value={ inputs.subjectLine || "" }
                        className={ classes[ "input" ] }
                        style={ {border: 'none',
	borderBottom: '1px solid #e2e3e3',
	borderRadius: '0'}}
                        name="subjectLine"
                        id="subjectLine"
                        placeholder="Enter an email subject line (required)"
                        type="text" />
                    <div className={ classes[ "input__error" ] }>
                        { formValidationErrors.subjectLine }
                    </div>
                </div>
                <div className={ classes[ "preview" ] }>
                    <div
                        className={ [ classes[ "label" ], classes[ "label--padding" ] ].join(" ") }>
                        edit email
                        <div className={ classes[ "label__horizontal-line" ] }></div>
                    </div>

                    <div className="preview-email-container">
                        <EditEmail
                            inputs={ inputs }
                            handleInputChange={ handleInputChange }
                            engagementEmailDataFromAPI={ engagementEmailDataFromAPI }
                            formValidationErrors={ formValidationErrors } />
                    </div>
                </div>
            </form>
        </React.Fragment>
    );
}

export default FirstView;