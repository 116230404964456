import React, { Component } from "react";
import { Link } from "react-router-dom";
import '../assets/css/app.css';

class Burger extends Component {
    state = { menuOpen: false }

    handleMenuClick () {
        this.setState({ menuOpen: !this.state.menuOpen });
    }

    handleLinkClick () {
        this.setState({ menuOpen: false });
    }

    render () {
        const styles =
        {
            burgerContainer: {
                position: "absolute",
                top: "9px",
                right: "2px",
                zIndex: "26",
            },
            body: {
                display: "flex",
                flexDirection: "column",
                width: "100vw",
                right: "10px",
            },
            favorite: {
                fontFamily: "EgyptienneURWLig",
                fontSize: "1.2rem",
                letterSpacing: ".5px",
                padding: "8px 79px",
            },
            download: {
                fontFamily: "EgyptienneURWLig",
                fontSize: "1.2rem",
                letterSpacing: ".5px",
                color: "#9d9d9d",
                padding: "8px 70px",
            },
            cancel: {
                fontFamily: "EgyptienneURWLig",
                fontSize: "1.2rem",
                letterSpacing: ".5px",
                padding: "8px 83px",
            },
        }
        const menu = [
            <Link to="" className="favorite" style={ styles.favorite }>Favorite</Link>,
            // <div to="" className="download" style={ styles.download }>Download</div>,
            <Link to="" className="cancel" style={ styles.cancel }>Cancel</Link>,
        ]
        const menuItems = menu.map((val, index) => {
            return (
                <MenuItem
                    key={ index }
                    delay={ `${ index * 0.1 }s` }
                    onClick={ () => { this.handleLinkClick(); } }>{ val }
                </MenuItem>
            )
        });

        return (
            <div>
                <div style={ styles.burgerContainer }>
                    <MenuButton open={ this.state.menuOpen } onClick={ () => this.handleMenuClick() } />
                </div>
                <Menu open={ this.state.menuOpen }>
                    { menuItems }
                </Menu>

            </div >
        )
    }
}

class MenuItem extends Component {
    state = { hover: false }
    handleHover () {
        this.setState({ hover: !this.state.hover });
    }
    render () {
        const styles = {
            container: {
                opacity: 0,
                paddingTop: "5px",
                animation: "1s appear forwards",
                animationDelay: this.props.delay,
            },
            menuItem: {
                fontWeight: "300",
                lineHeight: "1",
                padding: "12px 0",
                cursor: "pointer",
                backgroundColor: this.state.hover ? "#e9e9e9 " : "#fff",
                animation: ".5s fadeInSome forwards",
                border: "1px solid #aaa",
                borderRadius: "4px",
                animationDelay: this.props.delay
            },
        }
        return (
            <div style={ styles.container }>
                <div
                    style={ styles.menuItem }
                    onMouseEnter={ () => { this.handleHover(); } }
                    onMouseLeave={ () => { this.handleHover(); } }
                    onClick={ this.props.onClick } >
                    { this.props.children }
                </div>
                <div style={ styles.line } />
            </div>
        )
    }
}

class Menu extends Component {
    state = {
        open: this.props.open ? this.props.open : false
    }

    static getDerivedStateFromProps (nextProps, prevState) {
        if (nextProps.open !== prevState.open)
        {
            return { open: nextProps.open };
        }
        else return null;
    }

    render () {
        const styles = {
            container: {
                right: "10px",
                height: this.state.open ? "25%" : 0,
                zIndex: 30,
                textAlign: "center"
            },
            menuList: {
                marginTop: "-120px",
                width: "50%",
                float: "right",
                marginRight: "10px",
            },
        }
        return (
            <>
                <div style={ styles.container }>
                    {
                        this.state.open ? <div style={ styles.overlay } /> : null
                    }
                </div>
                <div style={ styles.container }>
                    {
                        this.state.open ?
                            (<div style={ styles.menuList }> { this.props.children } </div>) : null
                    }
                </div>
            </>
        )
    }
}

class MenuButton extends Component {
    state = {
        open: this.props.open ? this.props.open : false,
        color: this.props.color ? this.props.color : "#9d9d9d",
    }

    static getDerivedStateFromProps (nextProps, prevState) {
        if (nextProps.open !== prevState.open)
        {
            return { open: nextProps.open };
        }
        else return null;
    }

    handleClick () {
        this.setState({ open: !this.state.open });
    }

    render () {
        const styles = {
            container: {
                height: "48px",
                width: "48px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                padding: "4px"
            },
            line: {
                height: "3px",
                width: "3px",
                borderRadius: "2px",
                background: "#5a5a5a",
                transition: ".5s ease-in-out",
            },
            lineLeft: {
                transform: this.state.open ? "translateX(-5px)" : "none",
                transformOrigin: "top left",
                marginRight: "4px",
            },
            lineMiddle: {
                transform: this.state.open ? "translateX(-3px)" : "none",
                opacity: this.state.open ? 1 : 1,
            },
            lineRight: {
                transformOrigin: "top right",
                marginLeft: "4px",
            },
        }
        return (
            <div style={ styles.container }
                onClick={ this.props.onClick ? this.props.onClick :
                    () => { this.handleClick(); } }>
                <div style={ { ...styles.line, ...styles.lineLeft } } />
                <div style={ { ...styles.line, ...styles.lineMiddle } } />
                <div style={ { ...styles.line, ...styles.lineRight } } />
            </div>
        )
    }
};

export default Burger;

