import React from 'react';

import classes from './ErrorMessage.module.css';

const ErrorMessage = ({ message, customClasses = "" }) => {
    return (
        <p className={ [ classes[ "error-message" ], customClasses ].join(" ") }>
            <i className="las la-exclamation-triangle"></i> { message }
        </p>
    );
}

export default ErrorMessage;