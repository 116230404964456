import React, { useState, useEffect } from 'react';
import { Container, Modal } from 'reactstrap';
import Stats from '../Section/Stats';
import CrushToolkit from '../Section/CrushToolkit';
import ProductEducation from '../Section/ProductEducation';
import logo from '../assets/images/CrushLogo-2019.png';
import '../assets/css/app.css';

import Profile from './Profile';
import ProfileImage from '../Utils/ProfileImage';
import BusinessUnitSelection from '../Components/BusinessUnitSelection/BusinessUnitSelection';
import SubmitButton from '../Components/FormButtons/SubmitButton';
import PWAPrompt from 'react-ios-pwa-prompt';

import { isSafari, browserVersion, isMobile } from 'react-device-detect';

import { getBusinessUnitAction } from '../Store/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import Trials from './Trials';

const Dashboard = (props) => {
	const { networkConnected } = props;

	const [ isMyProfileModalOpen, setIsMyProfileModalOpen ] = useState(false);

	const toggleMyProfileModal = () => setIsMyProfileModalOpen(previousIsMyProfileModalOpen => !previousIsMyProfileModalOpen);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getBusinessUnitAction());
	}, []);

	const businessUnitsArrayFromAPI = useSelector(state => state.auth.BU);
	const getBusinessUnitsError = useSelector(state => state.auth.BUError);

	useEffect(() => {
		let BUsAreSelected = businessUnitsArrayFromAPI.findIndex(BU => BU.is_selected === "1");
		if (BUsAreSelected === -1 && businessUnitsArrayFromAPI.length)
		{
			setIsBusinessUnitSelectionModalOpen(true);
		}

	}, [ businessUnitsArrayFromAPI ])

	const [ isBusinessUnitSelectionModalOpen, setIsBusinessUnitSelectionModalOpen ] = useState(false);
	const toggleBusinessUnitSelectionModal = () => setIsBusinessUnitSelectionModalOpen(previousIsBusinessUnitSelectionModalOpen => !previousIsBusinessUnitSelectionModalOpen);

	const profileImageOrText = localStorage.getItem('profile_image');
	const userCounters = props.userCounters;
	const userCountersError = props.userCountersError;

	let [ installProperties, setInstallProperties ] = useState({
		deviceOS: '',
		installPrompt: null
	});

	useEffect(() => {
		if (window.screen.width <= 900) {
			window.addEventListener('beforeinstallprompt', (event) => {
				// For older browsers
				event.preventDefault();
				setInstallProperties(previousInstallProperties => ({
					...previousInstallProperties,
					installPrompt: event,
					deviceOS: 'android'
				}));
			});

			if (!window.matchMedia('(display-mode: standalone)').matches)
			{
				if (!(isSafari && browserVersion < 13) && isMobile)
				{
					install();
				}
			}
		}
	}, []);

	const install = async () => {
		if (isSafari) {
			setInstallProperties(previousInstallProperties => ({ 
				...previousInstallProperties,
				deviceOS: 'apple'
			}));
		} else if (installProperties.installPrompt) {
			installProperties.installPrompt.prompt();
			let outcome = await installProperties.installPrompt.userChoice;
			if (outcome.outcome === 'accepted')
			{
				setInstallProperties(previousInstallProperties => ({
					...previousInstallProperties, 
					deviceOS: ''
				}));
			}
		}
	}

	const resetDeviceOSInInstallProperties = () => {
		setInstallProperties(previousInstallProperties => ({
			...previousInstallProperties,
			deviceOS: ''
		}));
	}

	return (
		<div className="page dashboard-container">
			<Container className="dashboard-top">
				<div className="dashboard-bar">
					<img src={logo} alt="logo" />
					<div className="network-connection">
						<div className="on-off">
							{networkConnected ? (
								<span>
									ONLINE <i className="las la-check-circle" />
								</span>
							) : (
								<span>
									{/* OFFLINE */}
									<i className="las la-time-circle" />
								</span>
							)}
						</div>
						<ProfileImage
							profile={ { profileImage: profileImageOrText } }
							cb={ toggleMyProfileModal }
							profileImageClasses="profile__image--small"
						/>
					</div>
				</div>
				<Stats userCountersError={userCountersError} userCounters={userCounters} />
			</Container>
			{/*<br />
			 <CrushToolkit />
			<ProductEducation /> */}
			<Trials />
			<Modal
				isOpen={ isBusinessUnitSelectionModalOpen }
				toggle={ toggleBusinessUnitSelectionModal }
				centered
				backdrop="static">
				<BusinessUnitSelection toggleBusinessUnitSelectionModal={ toggleBusinessUnitSelectionModal } />
			</Modal>
			{isMyProfileModalOpen && <Profile isMyProfileModalOpen={ isMyProfileModalOpen } toggleMyProfileModal={ toggleMyProfileModal } /> }

			{ installProperties.deviceOS === 'android' ? (
				<Modal 
					isOpen={ installProperties.deviceOS === 'android' }
					toggle={ resetDeviceOSInInstallProperties }
					centered
					className="install-modal">
						<h1 className="install-modal-title">
							install app
						</h1>
						<div className="install-modal-text">
							This website offers app capabilities. Please add it to your home screen to improve performance and enable offline functionalities.
						</div>

						<SubmitButton handleClick={ install } text="add to homescreen" />
				</Modal>
			) : (null) }

			{ installProperties.deviceOS === 'apple' ? (
				<PWAPrompt 
					timesToShow={ 10 }
					delay={ 10 }
					debug={ true }
					copyTitle="Install App"
					permanentlyHideOnDismiss={ true }
					copyBody="This website offers app capabilities. Please add it to your home screen to improve performance and enable offline functionalities."
					copyClosePrompt={ [ <span className="install-modal-done-text">DONE</span> ] }/>
			) : (null) }
		</div>
	);
};

export default Dashboard;
