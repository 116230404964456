import React, { useState, useEffect } from 'react';
import { Container } from 'reactstrap';
import _ from 'lodash';
import Search from '../Utils/Search';

import TableTrialsRespondent from '../Components/TableTrialsRespondent';
import { ListGroupTrialsRespondent } from '../Components/ListGroup';
import { withRouter } from 'react-router-dom';
import { getRespondentsForSurveyAction } from '../Store/actions/surveysAction';
import { getRespondentsForEvaluationAction } from '../Store/actions/evaluationsAction';
import { connect } from 'react-redux';

const availableTypes = [
	{ id: 'all', name: 'All' },
	{ id: 'surgeon', name: 'Surgeons' },
	{ id: 'stakeholder', name: 'Stakeholders' }
];

const ChooseRespondent = ({
	surveysRespondents,
	evaluationsRespondents,
	match,
	getEvaluationRespondents,
	getSurveyRespondents
}) => {
	const { type, trialID, id } = match.params;
	const surveyEvalRepondents = type === 'survey' ? surveysRespondents : evaluationsRespondents;

	const [ selectedType, setSelectedType ] = useState(availableTypes[0]);
	const [ sortColumn, setSortColumn ] = useState({ path: 'name', order: 'asc' });
	const [ respondentsFetched, setRespondentsFetched ] = useState(false);

	useEffect(() => {
		type === 'survey'
			? getSurveyRespondents({ trialID: trialID, templateID: id })
			: getEvaluationRespondents({ trialID: trialID, templateID: id });
		setRespondentsFetched(true);
	}, respondentsFetched);

	const handleSort = (path) => {
		if (sortColumn.path === path) {
			sortColumn.order === 'asc' ? setSortColumn({ path, order: 'desc' }) : setSortColumn({ path, order: 'asc' });
		} else {
			sortColumn.path = path;
			setSortColumn({ path, order: 'asc' });
		}
	};

	const respondentFiltered = surveyEvalRepondents.filter(
		(res) => res.type === selectedType.id || selectedType.id === 'all'
	);
	const sorted = _.orderBy(respondentFiltered, [ sortColumn.path ], [ sortColumn.order ]);
	return (
		<React.Fragment>
			<Container className="tab-title-main">
				<img src="images/icon-plus.png" className="plus mt-2" alt="" />
				<h4 className="section-title">Choose Respondent</h4>
				<div className="nav-pagelink">
					<ListGroupTrialsRespondent
						items={availableTypes}
						onItemSelect={setSelectedType}
						selectedItem={selectedType}
					/>
				</div>
				<div className="hr-bk-expand-ml-mr-15" />
				<Search />
			</Container>
			<TableTrialsRespondent respondentFiltered={sorted} onSort={handleSort} />
		</React.Fragment>
	);
};
const mapStateToProps = (state) => {
	return {
		surveysRespondents: state.surveys.surveysRespondents,
		evaluationsRespondents: state.evaluations.evaluationsRespondents
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getEvaluationRespondents: (parameters) => {
			dispatch(getRespondentsForEvaluationAction(parameters));
		},
		getSurveyRespondents: (parameters) => {
			dispatch(getRespondentsForSurveyAction(parameters));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChooseRespondent));
