import React from 'react';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import ProfileImage from '../Utils/ProfileImage';

import listClasses from '../Components/List/List.module.css';

const PeopleCases = (props) => {
	const { specialistCases } = props;

	return (
		<Col>
			<div className="tab-rt-extend">
				<div className="tab-title">Surgeon</div>
				<div className="line-blk-shrink2" />
				<table className="table talktracks-single">
					<tbody>
						{specialistCases ? (
							specialistCases.map((item, index) => {
								if (item.surgeon_case_count > 0) {
									return (
										<tr key={index}>
											<td>
												<Link
													to={'surgeon/' + item.id}
													key={item.id}
													value={item.speciality_name}
												>
													<div className="person-details-container">
														<ProfileImage
															profile={item}
															cb={null}
															profileImageClasses="profile__image--list"
														/>
														<div className="person-details">
															<div className={listClasses['list__item-name']}>
																{item.surgeon_name}
															</div>
															<div className={listClasses['list__item-description']}>
																{item.speciality_name}
															</div>
															<div className={listClasses['list__item-light-gray']}>
																{item.surgeon_case_count} CASES | LAST CASE:{' '}
																{item.case_date}
															</div>
														</div>
													</div>
												</Link>
											</td>
										</tr>
									);
								}
							})
						) : null}
					</tbody>
				</table>
			</div>
		</Col>
	);
};

export default PeopleCases;
