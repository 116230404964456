import React from 'react';

import LogoPreview from '../../Shared/LogoPreview/LogoPreview';
import Footer from '../../Shared/Footer/Footer';

import classes from './TrialRecapUpdate.module.css';
import emailPreviewClasses from '../EmailPreview.module.css';

const TrialRecapUpdateCommunications = ({ emailPreview }) => {
    const emailPreviewContent = emailPreview?.content.split(/[\r\n]+/g);
    if (emailPreview.facility_image === "") { emailPreview.facility_image = '/images/email/facility-image-placeholder.png' }
    if (emailPreview.facility_logo === "") { emailPreview.facility_logo = '/images/email/facility-logo-placeholder.png' }

    return (
        <div>
            <LogoPreview />
            <div>
                <div className={ classes[ "trial-recap__header" ] }>
                    <div className={ classes[ "trial-recap__header-item" ] }>

                        <div className={ classes[ "trial-recap__header-heading" ] }>
                            { emailPreview.title }
                        </div>
                        <div className={ classes[ "trial-recap__header-heading-subtext" ] }>
                            { emailPreview.sub_title }
                        </div>
                        <img className={ classes[ "trial-recap__header-image" ] } src="/images/email/customize-email-bg-1.jpg" alt="rectangles" />
                    </div>

                    <div className={ classes[ "trial-recap__header-item" ] }>
                        <div className={ classes[ "trial-recap__header-image-container" ] }>
                            <img
                                className={ classes[ "trial-recap__header-image" ] }
                                src={ emailPreview.facility_image }
                                alt="facility image" />
                        </div>
                        <div className={ classes[ "trial-recap__header-image-container" ] }>
                            <img
                                className={ classes[ "trial-recap__header-image" ] }
                                src={ emailPreview.facility_logo }
                                alt="facility logo " />
                        </div>
                    </div>
                </div>
            </div>
            <p className={ [ classes[ "trial-recap__quote" ], "border-0" ].join(" ") }>
                { emailPreview.title_description }
            </p>

            <div className={ classes[ "trial-recap__paragraphs-container" ] }>
                { emailPreviewContent?.map((item, index) => {
                    return (
                        <p
                            key={ index }
                            className={ emailPreviewClasses[ "email-preview__paragraph" ] }>
                            { item }
                        </p>
                    );
                }) }
            </div>

            <div className={ classes[ "trial-recap__counters-container" ] }>
                <div className={ classes[ "trial-recap__counter" ] }>
                    <div className={ classes[ "trial-recap__counter-number" ] }>
                        12
                    </div>
                    <div className={ classes[ "trial-recap__counter-participating" ] }>
                        Participating
                    </div>
                    <div className={ classes[ "trial-recap__counter-type" ] }>
                        surgical specialties
                    </div>
                </div>
                <div className={ classes[ "trial-recap__counter" ] }>
                    <div className={ classes[ "trial-recap__counter-number" ] }>
                        7
                    </div>
                    <div className={ classes[ "trial-recap__counter-participating" ] }>
                        Participating
                    </div>
                    <div className={ classes[ "trial-recap__counter-type" ] }>
                        cases
                    </div>
                </div>
            </div>

            <div className={ classes[ "trial-recap__person-quote-container" ] }>
                <div className={ classes[ "trial-recap__person-quote-icon-container" ] }>
                    <img
                        className={ classes[ "trial-recap__person-quote-icon" ] }
                        src="/images/email/quote-trial-template.png" alt="quote icon" />
                </div>

                <p className={ classes[ "trial-recap__person-quote" ] }>
                    “Example surgeon or stakeholder response. These are responses to “text response” questions in the surveys and evaluations that can not be modified. They can only be selected and displayed in full length.”
                </p>

                <div className={ classes[ "trial-recap__person-quote-name" ] }>
                    - Example Surgeon
                </div>
            </div>

            <div className={ classes[ "trial-recap__statistics" ] }>
                <div className={ classes[ "trial-recap__statistics-wrapper" ] }>
                    <h2 className={ classes[ "trial-recap__statistics-wrapper-title" ] }>
                        Trial <span className={ classes[ "trial-recap__statistics-wrapper-subtext" ] }>statistics</span>
                    </h2>
                    <div className={ [ emailPreviewClasses[ "ribbon" ], "m-0" ].join(" ") }></div>
                </div>

                <div className={ classes[ "trial-recap__pie-chart-container" ] }>
                    <div className={ classes[ "trial-recap__pie-chart-image-container" ] }>
                        <img className={ classes[ "trial-recap__pie-chart-image" ] }
                            src="/images/email/stat-graph01.png"
                            alt="pie chart" />
                    </div>

                    <div className={ classes[ "trial-recap__pie-chart" ] }>
                        <p className={ classes[ "trial-recap__question" ] }>
                            Which activities do you believe take time away from patient care?
                        </p>
                        <div className={ classes[ "trial-recap__pie-chart-label-container" ] }>
                            <div className={ [ classes[ "trial-recap__pie-chart-label-percentage" ], classes[ "trial-recap__pie-chart-label-percentage--purple-one" ] ].join(" ") }>45%</div>
                            <div className={ classes[ "trial-recap__pie-chart-label-text" ] }>
                                Pre-operative device setup
                            </div>
                        </div>
                        <div className={ classes[ "trial-recap__pie-chart-label-container" ] }>
                            <div className={ [ classes[ "trial-recap__pie-chart-label-percentage" ], classes[ "trial-recap__pie-chart-label-percentage--purple-two" ] ].join(" ") }>45%</div>
                            <div className={ classes[ "trial-recap__pie-chart-label-text" ] }>
                                Pre-operative device setup
                            </div>
                        </div>
                        <div className={ classes[ "trial-recap__pie-chart-label-container" ] }>
                            <div className={ [ classes[ "trial-recap__pie-chart-label-percentage" ], classes[ "trial-recap__pie-chart-label-percentage--purple-three" ] ].join(" ") }>45%</div>
                            <div className={ classes[ "trial-recap__pie-chart-label-text" ] }>
                                Pre-operative device setup
                            </div>
                        </div>
                        <div className={ classes[ "trial-recap__pie-chart-label-container" ] }>
                            <div className={ [ classes[ "trial-recap__pie-chart-label-percentage" ], classes[ "trial-recap__pie-chart-label-percentage--purple-four" ] ].join(" ") }>45%</div>
                            <div className={ classes[ "trial-recap__pie-chart-label-text" ] }>
                                Pre-operative device setup
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={ classes[ "trial-recap__horizontal-bar-graph-container" ] }>
                <img
                    className={ classes[ "trial-recap__horizontal-bar-graph-image" ] }
                    src="/images/email/stat-graph03.png"
                    alt="bar graph" />
            </div>

            <div className={ [ classes[ "trial-recap__pie-chart-container" ], "mb-5" ].join(" ") }>
                <div className={ classes[ "trial-recap__pie-chart-image-container" ] }>
                    <img
                        className={ classes[ "trial-recap__pie-chart-image" ] }
                        src="/images/email/stat-graph04.png"
                        alt="pie chart" />
                </div>

                <div className={ classes[ "trial-recap__pie-chart" ] }>
                    <p className={ classes[ "trial-recap__question" ] }>
                        Which activities do you believe take time away from patient care?
                    </p>
                    <div className={ classes[ "trial-recap__pie-chart-label-container" ] }>
                        <div className={ [ classes[ "trial-recap__pie-chart-label-percentage" ], classes[ "trial-recap__pie-chart-label-percentage--green-one" ] ].join(" ") }>12%</div>
                        <div className={ classes[ "trial-recap__pie-chart-label-text" ] }>
                            Printed
                        </div>
                    </div>
                    <div className={ classes[ "trial-recap__pie-chart-label-container" ] }>
                        <div className={ [ classes[ "trial-recap__pie-chart-label-percentage" ], classes[ "trial-recap__pie-chart-label-percentage--green-two" ] ].join(" ") }>88%</div>
                        <div className={ classes[ "trial-recap__pie-chart-label-text" ] }>
                            Sent electronically to you
                        </div>
                    </div>
                </div>
            </div>

            <Footer text={ emailPreview.footer } />
        </div>
    );
}

export default TrialRecapUpdateCommunications;