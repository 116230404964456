import React, { useState, useEffect } from 'react';

import CancelButton from '../../../../../Components/FormButtons/CancelButton';
import SubmitButton from '../../../../../Components/FormButtons/SubmitButton';
import NoResults from '../../../../../Components/NoResults/NoResults';
import ComponentLoading from '../../../../../Components/ComponentLoading/ComponentLoading';

import classes from './Forms.module.css';

import { useDispatch, useSelector } from 'react-redux';
import { getEngagementEmailVerbatimAction } from '../../../../../Store/actions/trials/engagementEmailsAction';
import { useParams } from 'react-router-dom';

const AddAVerbatim = ({ toggleAddAVerbatimModal, handleInputChange, inputs, setCurrentlyChangingVerbatimComment, currentlyChangingVerbatimComment }) => {
    const dispatch = useDispatch();

    const { id } = useParams();

    const engagementEmailVerbatim = useSelector(state => state.engagementEmails.engagementEmailVerbatim);
    const engagementEmailVerbatimError = useSelector(state => state.engagementEmails.engagementEmailVerbatimError);
    const isFetchingEngagementEmailVerbatim = useSelector(state => state.engagementEmails.isFetchingEngagementEmailVerbatim);

    useEffect(() => {
        dispatch(getEngagementEmailVerbatimAction(id));
    }, []);

    const [ commentFromChosenVerbatim, setCommentFromChosenVerbatim ] = useState(false);
    const [ verbatim, setVerbatim ] = useState({});

    const handleSubmit = (event, commentFromChosenVerbatim) => {
        event.preventDefault();

        if (commentFromChosenVerbatim === false) return;

        let cloneVerbatimComments = JSON.parse(JSON.stringify(inputs.verbatim));

        if (currentlyChangingVerbatimComment)
        {
            cloneVerbatimComments = inputs.verbatim.filter(verbatimCommentsStoredInState => verbatimCommentsStoredInState.comment_id !== currentlyChangingVerbatimComment.comment_id);
        }

        cloneVerbatimComments.push(commentFromChosenVerbatim);

        handleInputChange({
            target: {
                name: 'verbatim',
                value: cloneVerbatimComments
            }
        });

        setCurrentlyChangingVerbatimComment(false);

        setCommentFromChosenVerbatim(false);

        toggleAddAVerbatimModal(event);
    }

    const goBack = (event) => {
        event.preventDefault();
        setVerbatim({});
    }

    if (isFetchingEngagementEmailVerbatim)
    {
        return (
            <ComponentLoading message="Loading..." />
        );
    }

    return (
        <form className={ classes[ "form" ] }>
            <h2 className={ classes[ "form__title" ] }>
                Select a verbatim
            </h2>

            <div className={ [ classes[ "form__options" ], verbatim.comments ? ("hidden") : ("") ].join(" ") }>
                { engagementEmailVerbatim.length ?
                    (
                        engagementEmailVerbatim?.map(verbatim => {
                            return (
                                <div
                                    key={ verbatim.id }
                                    onClick={ () => setVerbatim(verbatim) }
                                    className={ classes[ "form__option" ] }>
                                    { verbatim.questions } <i className={ [ "las la-angle-right", classes[ "form__option-icon" ] ].join(" ") }></i>
                                </div>
                            );
                        })
                    ) : (
                        <NoResults message="you don't have any questions right now." />
                    ) }

                <div className={ classes[ "form__buttons-container" ] }>
                    <CancelButton handleClick={ toggleAddAVerbatimModal } />
                </div>
            </div>

            <div className={ verbatim.comments ? ("") : ("hidden") }>
                { verbatim.comments?.map(commentFromChosenVerbatim => {
                    let isChosen;
                    if (inputs.verbatim === undefined) isChosen = -1;
                    isChosen = (inputs?.verbatim.length > 0 ? inputs.verbatim : []).findIndex(verbatimCommentStoredInState => verbatimCommentStoredInState.comment_id === commentFromChosenVerbatim.comment_id);

                    let isChangingVerbatimComment = commentFromChosenVerbatim.comment_id === currentlyChangingVerbatimComment.comment_id;

                    return (
                        <div
                            key={ commentFromChosenVerbatim.comment_id }
                            className={ [ classes[ "form__group" ], "border-bottom-0" ].join(" ") } >
                            <input
                                disabled={ isChangingVerbatimComment ? false : isChosen !== -1 }
                                defaultChecked={ isChangingVerbatimComment }
                                onClick={ () => setCommentFromChosenVerbatim(commentFromChosenVerbatim) }
                                type="radio"
                                name="select-verbatim-option"
                                id={ `select-verbatim-option-${ commentFromChosenVerbatim.comment_id }` }
                                value={ commentFromChosenVerbatim.comment_id }
                                className="hidden-radio-input" />

                            <label
                                htmlFor={ `select-verbatim-option-${ commentFromChosenVerbatim.comment_id }` }
                                className="input-checkmark-circle">
                                <i className="las la-check" />
                            </label>

                            <label
                                className={ classes[ "form__label" ] }
                                htmlFor={ `select-verbatim-option-${ commentFromChosenVerbatim.comment_id }` }>
                                <span className={ classes[ "form__select-light" ] }>
                                    { commentFromChosenVerbatim.comment }
                                </span>
                                <span className={ classes[ "form__select-text" ] }>
                                    - { commentFromChosenVerbatim.people_name }
                                </span>
                            </label>
                        </div>
                    );
                }) }

                <div className={ classes[ "form__buttons-container" ] }>
                    <CancelButton handleClick={ toggleAddAVerbatimModal } />

                    <SubmitButton handleClick={ (event) => handleSubmit(event, commentFromChosenVerbatim) } isGray={ true } />
                </div>

                <button onClick={ goBack } className="left-arrow-icon-button">
                    <img className="left-arrow-icon" src="/images/email/icon-left-arrow-big.png" alt="left arrow icon" />
                </button>
            </div>
        </form>
    );
}

export default AddAVerbatim;