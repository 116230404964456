import React from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import FormElementsClasses from '../FormElements.module.css';
import InputElementsClasses from '../InputElements.module.css';
import useHandleSubmit from '../../../../../Hooks/useHandleSubmit';

const AddTrialDetails = ({ submittedTrialDetails, submitTrialDetails }) => {
	const handleTrialDetailSubmit = () => {
		submitTrialDetails(inputs);
	};

	const { inputs, handleInputChange, handleSubmit } = useHandleSubmit({
		callback: handleTrialDetailSubmit,
		initialState: submittedTrialDetails
			? {
					trialName: submittedTrialDetails.trial_name,
					trialStartDate: submittedTrialDetails.start_date,
					budgetCycle: submittedTrialDetails.end_date,
					trialEndDate: submittedTrialDetails.budget_cycle,
					expectedPurchase: submittedTrialDetails.expected_purchase
				}
			: {
					trialName: '',
					trialStartDate: '',
					budgetCycle: '',
					trialEndDate: '',
					expectedPurchase: ''
				}
	});


	function handleDatePickerSelect (date)  {
		handleInputChange({
			target: {
				name: this.name,
				value: date
			}
		});
	}

	const handleDateChangeRaw = (event) => {
		event.preventDefault();
	}

	return (
		<form className={FormElementsClasses['form']} onSubmit={handleSubmit}>
			<div className={FormElementsClasses['form__header-row']}>
				<h2 className={FormElementsClasses['form__title']}>Add trial details</h2>
				<button className={FormElementsClasses['form__button']}>
					next <i className="las la-angle-right" />
				</button>
			</div>
			<div className={InputElementsClasses['form__group']}>
				<label className={InputElementsClasses['form__label']} htmlFor="trialName">
					trial name (required)
				</label>
				<input
					className={InputElementsClasses['form__input']}
					type="text"
					id="trialName"
					name="trialName"
					required
					value={inputs.trialName}
					onChange={handleInputChange}
				/>
			</div>
			<div className="date-inputs-container">
				<div className={InputElementsClasses['form__group']}>
					<label className={InputElementsClasses['form__label']} htmlFor="trialStartDate">
						trial start date
					</label>
					<DatePicker
						className={ InputElementsClasses[ 'form__input' ] }
						id="trialStartDate"
						name="trialStartDate"
						onSelect={ handleDatePickerSelect }
						onChangeRaw={ handleDateChangeRaw }
						selected={ inputs.trialStartDate } />
					<i className="las la-calendar input__icon"></i>
				</div>
				<div className={ InputElementsClasses[ 'form__group' ] }>
					<label className={ InputElementsClasses[ 'form__label' ] } htmlFor="trialEndDate">
						trial end date
					</label>
					<DatePicker
						className={ InputElementsClasses[ 'form__input' ] }
						id="trialEndDate"
						name="trialEndDate"
						onSelect={ handleDatePickerSelect }
						onChangeRaw={ handleDateChangeRaw }
						selected={ inputs.trialEndDate } />
					<i className="las la-calendar input__icon"></i>
				</div>
				<div className={InputElementsClasses['form__group']}>
					<label className={InputElementsClasses['form__label']} htmlFor="budgetCycle">
						budget cycle
					</label>
					<DatePicker
						className={ InputElementsClasses[ 'form__input' ] }
						id="budgetCycle"
						name="budgetCycle"
						onSelect={ handleDatePickerSelect }
						onChangeRaw={ handleDateChangeRaw }
						selected={ inputs.budgetCycle } />

					<i className="las la-calendar input__icon"></i>
				</div>
				<div className={InputElementsClasses['form__group']}>
					<label className={InputElementsClasses['form__label']} htmlFor="expectedPurchase">
						expected purchase
					</label>
					<DatePicker
						className={ InputElementsClasses[ 'form__input' ] }
						id="expectedPurchase"
						name="expectedPurchase"
						onSelect={ handleDatePickerSelect }
						onChangeRaw={ handleDateChangeRaw }
						selected={ inputs.expectedPurchase } />
					<i className="las la-calendar input__icon"></i>
				</div>
			</div>
		</form>
	);
};

export default AddTrialDetails;
