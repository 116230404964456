import React from 'react';
import '../assets/css/app.css';

const StatsTrialsSelectedSurveyEval = (props) => {
	const { selectedSurveyEval, selectedRespondents } = props;
	let responseCount = 0;
	selectedRespondents && selectedRespondents.forEach((r) => (r.general_status === '1' ? responseCount++ : false));

	return (
		<div>
			<h4 className="section-title-survey">{selectedSurveyEval.template_name}</h4>
			<div className="line-blk-original" />
			<div className="dashboard-stats surveys-and-evaluations__statistics">
				<div className="stats-survey">
					<div className="surveys-and-evaluations__statistics-label">RESPONSES IN THIS TRIAL</div>
					<br />
					<span className="number">{responseCount}</span>
				</div>
				<div className="stats-survey">
					<div className="surveys-and-evaluations__statistics-label">TOTAL RESPONDENTS</div>
					<br />
					<span className="number">{selectedRespondents.length}</span>
				</div>
			</div>
			<div className="line-grey-original" />
			<div className="dashboard-stats ">
				<div className="stats-survey-detail">
					<div className="surveys-and-evaluations__details-label">CREATED</div>
					<div className="stats-survey-date surveys-and-evaluations__details-text">
						{selectedSurveyEval.created_date}
					</div>
				</div>
				<div className="stats-survey-detail">
					<div className="surveys-and-evaluations__details-label">LAST MODIFIED</div>
					<div className="stats-survey-date surveys-and-evaluations__details-text">
						{selectedSurveyEval.updated_date || selectedSurveyEval.created_date}
					</div>
				</div>
			</div>
			<div className="dashboard-stats">
				<div className="stats-survey-detail">
					<div className="surveys-and-evaluations__details-label">QUESTIONS</div>
					<div className="stats-survey-date surveys-and-evaluations__details-text">
						{selectedSurveyEval.questions}
					</div>
				</div>
				{/* 
				<div className="stats-survey-detail">
					<div className="surveys-and-evaluations__details-label">LAST RESPONSE</div>
					<div className="stats-survey-date surveys-and-evaluations__details-text">
						{selectedSurveyEval.last_taken}
					</div>
				</div> */}
			</div>

			<div className="line-grey-original mb-3" />
		</div>
	);
};

export default StatsTrialsSelectedSurveyEval;
