import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import Search from '../../../../Utils/Search';
import List from '../../../../Components/List/List';
import { itemDetailAction } from '../../../../Store/actions/relatedTrialActions';
import { Modal } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import _ from 'lodash';
import NoResults from '../../../../Components/NoResults/NoResults';
import ComponentLoading from '../../../../Components/ComponentLoading/ComponentLoading';

class RelatedTrials extends Component {
	state = {
		selectedCategory: 'competitor',
		sortType: 'name',
		sortColumn: {
			path: 'name',
			order: 'desc'
		},
		searchValue: ''
	};

	componentDidMount() {
		const { itemId, itemType } = this.props.match.params;
		this.props.getItemDetails(itemId, itemType);
		this.setState({ selectedCategory: itemType });
	}

	filterRelatedTrialItemDetailsBySearch = () => {
		return this.props.relatedTrialItemDetails.filter(trial => {
			return trial.name.toLowerCase().includes(this.state.searchValue.toLowerCase().trim());
		});
	};

	handleSort = (path) => {
		const { sortColumn } = this.state;
		if (sortColumn.path === path) {
			sortColumn.order === 'asc'
				? this.setState({ sortColumn: { path, order: 'desc' } })
				: this.setState({ sortColumn: { path, order: 'asc' } });
		} else {
			sortColumn.path = path;
			this.setState({ sortColumn: { path, order: 'asc' } });
		}
	};

	handleInputChange = (event) => {
		this.setState({
			searchValue: event.currentTarget.value
		});
	};

	render() {
		const { itemId, itemType, categoryName } = this.props.match.params;
		const { selectedCategory, sortType, sortColumn } = this.state;

		const filterRelatedTrialItemDetailsBySearch = this.filterRelatedTrialItemDetailsBySearch();
		const sortedRelatedTrialItemDetails = _.orderBy(filterRelatedTrialItemDetailsBySearch, [ sortColumn.path ], [ sortColumn.order ]);

		return (
			<div>
				<Container className="tab-title-main">
					<h1 className="section-title">Related trials</h1>
					<div className="nav-pagelink">
						<div className={`pagelink-sml mr-3half  inactive`} onClick={this.props.history.goBack}>
							{itemType === 'competitor' ? (
								'Competitor'
							) : itemType === 'specialities' ? (
								'Specialties'
							) : (
								''
							)}
						</div>
						<span className="triangle mr-2 grey"> ▸ </span>
						<div className={`pagelink-sml active`}>{decodeURIComponent(categoryName)}</div>
					</div>
					<div className="hr-bk-expand-ml-mr-15" />
					<Search searchingValue={ this.state.searchValue } handleInputChange={ this.handleInputChange } />
				</Container>

				<Container>
					<List
						list={ sortedRelatedTrialItemDetails }
						selectedCategory={selectedCategory}
						actions={null}
						sortAlphabeticallyByTitle={this.handleSort}
						sortBy="name"
						view="related-trial-item-detail"
					/>

					{ this.props.isFetchingRelatedTrialItemDetails ? (
						<ComponentLoading />
					) : (null) }

					{ sortedRelatedTrialItemDetails.length === 0 && !this.props.isFetchingRelatedTrialItemDetails ? (
						<NoResults />
					) : null }
				</Container>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		relatedTrialItemDetails: state.relatedTrials.getRelatedTrialDetail,
		isFetchingRelatedTrialItemDetails: state.relatedTrials.isFetchingRelatedTrialItemDetails
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getItemDetails: (itemID, itemType) => {
			dispatch(itemDetailAction({ itemID: itemID, itemType: itemType }));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RelatedTrials));
