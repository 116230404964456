import React, { createRef, Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { postTeammateAction, getTeammatesForSearchAction } from '../../../Store/actions/trialsAction';

import classes from './AddATeammate.module.css';

import { Form, FormGroup, Input, Label, Button, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import ErrorMessage from '../../../Components/ErrorMessage/ErrorMessage';
import NoResults from '../../../Components/NoResults/NoResults';

class AddATeammate extends Component {
	searchEmailRef = createRef();

	state = {
		email: '',
		addedTeammates: [],
		person: {},
		formValidationError: '',
		teammateAdded: false,
		addedTeammate: null
	};

	handleSubmit = (ev) => {
		if (ev)
		{
			ev.preventDefault();
		}

		this.setState({ formValidationError: '' });
		if (!this.state.person.email)
		{
			this.setState({ formValidationError: 'Please select a user from the list' });
		} else {

			const trialID = this.props.match.params.id;
			this.props.postTeammate(this.state.person, trialID);
			this.setState({teammateAdded: true, addedTeammate: this.state.person })
			this.clearSearch()
		}

	};

	handleChange = (ev) => {
		const { searchEmailRef } = this;

		if (ev.currentTarget.value !== '')
		{
			searchEmailRef.current.classList.remove('hidden');
		} else
		{
			searchEmailRef.current.classList.add('hidden');
		}

		this.setState({ email: ev.currentTarget.value, teammateAdded:false, person: {} }, () => this.props.getTeammatesForSearch(this.state.email));
	};

	choosePerson = (person) => {
		const { searchEmailRef } = this;
		searchEmailRef.current.classList.add('hidden');

		this.setState({ person: person, email: person.email});
	};

	clearSearch = () => {
		const { searchEmailRef } = this;
		searchEmailRef.current.classList.add('hidden');

		this.setState({ email: '', person: {} });
	};

	render () {
		const { searchEmailRef, choosePerson, handleSubmit, handleChange, clearSearch } = this;
		const { teammatesForSearch, teammatesForSearchError, postTeammatesError } = this.props;
		let person = this.state.addedTeammate ? (
			<div className={ classes[ 'person-added' ] }>
				<p className={ classes[ 'person-added__name' ] }>{ this.state.addedTeammate.email }</p>
				<p className={ classes[ 'person-added__description' ] }>has been added to your team</p>
			</div>
		) : null;

		return (
			<div className={ classes[ 'container' ] }>
				<Form className={ classes[ 'form' ] } onSubmit={ handleSubmit }>
					<FormGroup className={ classes[ 'form__form-group' ] }>
						<Label className={ classes[ 'form__label' ] } for="email">
							Add a teammate
						</Label>
						<InputGroup>
							<InputGroupAddon onClick={ clearSearch } addonType="prepend">
								<InputGroupText className={ classes[ 'form__input--icon' ] }>
									<i className="las la-times-circle" />
								</InputGroupText>
							</InputGroupAddon>
							<Input
								onChange={ handleChange }
								className={ classes[ 'form__input' ] }
								type="email"
								name="email"
								id="email"
								value={ this.state.email }
								placeholder="Email"
								autoComplete="off"
							/>
						</InputGroup>
					</FormGroup>
					<div ref={ searchEmailRef } className={ [ 'hidden', classes[ 'drop-down-container' ] ].join(' ') }>
						{ teammatesForSearchError ? (
							<div className={ classes[ 'drop-down' ] }>
								<NoResults />
							</div>
						) : (
								teammatesForSearch?.map((teammate) => {
									return (
										<div
											className={ classes[ 'drop-down' ] }
											onClick={ () => choosePerson(teammate) }
											key={ teammate.id }
										>
											<div className={ classes[ 'drop-down__name' ] }>{ teammate.first_name } { teammate.last_name }</div>
											<div className={ classes[ 'drop-down__email' ] }>{ teammate.email }</div>
										</div>
									);
								})
							) }
					</div>
					<Button color="link" className={ classes[ 'form__button' ] }>
						add teammate
					</Button>
				</Form>

				{ this.state.formValidationError ? (
					<ErrorMessage message={ this.state.formValidationError } />
				) : (null) }

				{ postTeammatesError && (
					<ErrorMessage message={ postTeammatesError } />
				) }

				{this.state.teammateAdded && !postTeammatesError && 
					<div>
						{/* <p>{this.state.email} was added to the trial</p> */}
						{person}
					</div>
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		teammatesForSearch: state.trials.teammatesForSearch,
		teammatesForSearchError: state.trials.teammatesForSearchError,
		postTeammatesError: state.trials.postTeammatesError
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		postTeammate: (person, trialID) => {
			dispatch(postTeammateAction(person, trialID));
		},

		getTeammatesForSearch: (search) => {
			dispatch(getTeammatesForSearchAction(search));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddATeammate));
