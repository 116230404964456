import React from "react";
import { Input } from "reactstrap";
import "./search.css"

const Search = (props) => {
	const { handleFilter, content, callback, handleInputChange, searchingValue, disabled } = props;

	return (
		<div className="input-icon search">
			<i className="las la-search" />
			<Input 
				disabled={ disabled }
				value={ searchingValue } 
				onChange={ (e) => {
					if (handleFilter) handleFilter(e, content, callback);
					if (handleInputChange) handleInputChange(e);
				} } 
				className="search__input" 
				placeholder="Search..." />
			<hr className="mt-10p" />
		</div>
	);
};

export default Search;
