import React, { useState, useEffect } from "react";
import "./tabAudio.css"

import List from '../List/List';
import { Modal } from 'reactstrap';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import listClasses from '../List/List.module.css';

import { getGroupTrackAudioAction } from '../../Store/actions/tracksAction';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { indexedDBAPI } from '../../indexedDBAPI';

const TabAudio = props => {
    const { productSubId, productId, type } = useParams();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getGroupTrackAudioAction({ productId, productSubId, type }));
    }, []);

    let getGroupTrackAudio = useSelector((state) => {
        return state.tracks.groupTrackAudio;
    });

    const [ isAudioPlayerOpen, setIsAudioPlayerOpen ] = useState(false);
    const toggleAudioPlayerModal = () => setIsAudioPlayerOpen(previousIsAudioPlayerOpen => !previousIsAudioPlayerOpen);

    const [ selectedAudioInformation, setSelectedAudioInformation ] = useState({});

    const activateAudioPlayerModal = (audioInformation) => {
        setIsAudioPlayerOpen(true);
        setSelectedAudioInformation(audioInformation);
    }

    const [ audioObjectsFromIndexedDB, setAudioObjectsFromIndexedDB ] = useState([]);

    useEffect(() => {
        indexedDBAPI.openIndexedDB((arrayFromIndexedDB) => {
            storeAudioObjectsFromIndexedDBIntoState(arrayFromIndexedDB)
        }, 'AUDIO');
    }, []);

    const storeAudioObjectsFromIndexedDBIntoState = (arrayFromIndexedDB) => {
        let audioObjectsFromIndexedDB = arrayFromIndexedDB.map(audioObject => {
            return {
                path: audioObject.path,
                blob: audioObject.data.blob
            };
        });

        setAudioObjectsFromIndexedDB(audioObjectsFromIndexedDB);
    }

    const getAllAudioObjectsFromIndexedDBAndStoreIntoState = () => {
        indexedDBAPI.getAllFromObjectStore((arrayFromIndexedDB) => {
            storeAudioObjectsFromIndexedDBIntoState(arrayFromIndexedDB)
        }, 'AUDIO');
    }

    const storeAudioIntoIndexedDB = (event, audioObject) => {
        event.stopPropagation();

        event.currentTarget.querySelector('#saveForOffline').textContent = 'Downloading...';

        fetch(audioObject.file_name)
            .then(res => {
                return res.blob();
            })
            .then(blob => {
                audioObject.blob = blob;

                setSelectedAudioInformation(previousSelectedAudioInformation => ({
                    ...previousSelectedAudioInformation,
                    isDownloaded: true,
                    file_name: URL.createObjectURL(blob)
                }));

                indexedDBAPI.storeGetResponseIntoIndexedDB(
                    audioObject,
                    audioObject.file_name,
                    'AUDIO',
                    getAllAudioObjectsFromIndexedDBAndStoreIntoState
                );
            });
    }

    getGroupTrackAudio = getGroupTrackAudio.map(audio => {
        let audioObjectStoredInIndexedDB = audioObjectsFromIndexedDB.find(audioObjectFromIndexedDB => audioObjectFromIndexedDB.path === audio.file_name);

        if (audioObjectStoredInIndexedDB)
        {
            return {
                ...audio,
                isDownloaded: true,
                file_name: URL.createObjectURL(audioObjectStoredInIndexedDB.blob)
            }
        }

        return {
            ...audio,
            isDownloaded: false
        }
    });

    getGroupTrackAudio.activateAudioPlayerModal = activateAudioPlayerModal;
    getGroupTrackAudio.storeAudioIntoIndexedDB = storeAudioIntoIndexedDB;

    return (
        <div className="tab-audio" >
            <div className="tab-title">Audio tracks</div>
            <div className="line-blk-shrink2" />
            <List
                list={ getGroupTrackAudio }
                actions={ null }
                view="audio"
                removeSort={ true } />
            {/* <div className="tab-button">
                <Link to={ "/talktracks/specialty/audio/" + groupName + "/" + productId + "/" + lastURLSegment + "/" + productSubId } >VIEW ALL AUDIO TRACKS </Link>
            </div> */}

            <Modal
                className="audio-player-modal"
                isOpen={ isAudioPlayerOpen }
                toggle={ toggleAudioPlayerModal }
                backdrop="static">
                <button
                    onClick={ toggleAudioPlayerModal }
                    className="audio-player__close-button">
                    <img src="/images/audio-player/angle-down.png" alt="angle down" className="audio-player__close-button-image" />
                </button>

                <AudioPlayer
                    src={ selectedAudioInformation.file_name }
                    customAdditionalControls={ [] }
                    customVolumeControls={ [] }
                    showDownloadProgress={ false }
                    customIcons={
                        {
                            play: (
                                <div className="audio-player__play-and-pause-container">
                                    <i className="las la-play audio-player__play-and-pause-icon"></i>
                                </div>
                            ),
                            pause: (
                                <div className="audio-player__play-and-pause-container">
                                    <i className="las la-pause audio-player__play-and-pause-icon"></i>
                                </div>
                            ),
                            rewind: <i className="las la-backward audio-player__rewind-icon"></i>,
                            forward: <i className="las la-forward audio-player__forward-icon"></i>
                        }
                    } />

                <div className="audio-player__audio-information">
                    <div className={ listClasses[ "list__item-content" ] }>
                        <div className={ listClasses[ "list__item-name" ] }>{ selectedAudioInformation.name }</div>
                        <div className={ listClasses[ "list__item-gray" ] }>{ selectedAudioInformation.duration }</div>
                        <div className={ listClasses[ "list__item-date" ] }>UPLOADED: { selectedAudioInformation.created_date }</div>
                    </div>

                    { selectedAudioInformation.isDownloaded ? (
                        <div className="list__item-information">
                            <img
                                className="list__text-inline-image"
                                src="/images/icon-cloud-download.png"
                                alt="cloud icon" />
                            <span>
                                Available Offline
                            </span>
                        </div>
                    ) : (
                            <button
                                onClick={ (event) => storeAudioIntoIndexedDB(event, selectedAudioInformation) }
                                className="audio-list__download-and-downloaded-button-icon list__item-information">
                                <img
                                    className="list__text-inline-image"
                                    src="/images/icon-cloud-download.png"
                                    alt="cloud icon" />
                                <span id="saveForOffline">
                                    Save for offline
                                </span>
                            </button>
                        ) }

                </div>

                <p className="audio-player__text">
                    { selectedAudioInformation.description }
                </p>
            </Modal>
        </div >);
}

export default TabAudio;

