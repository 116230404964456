import * as ActionTypes from '../actions/ActionTypes';

const initState = {
	isFetchingRelatedTrials: false,
	getRelatedTrials: {
		competitor: [],
		specialities: []
	},
	getRelatedTrialsError: '',

	isFetchingRelatedTrialItemDetails: false,
	getRelatedTrialDetail: [],
	getRelatedTrialDetailErr: ''
};

const relatedTrialsReducer = (state = initState, action) => {
	switch (action.type) {
		case ActionTypes.REQUEST_RELATED_TRIALS:
			return {
				...state,
				isFetchingRelatedTrials: true
			}

		case ActionTypes.GET_RELATED_TRIAL_SUCCESS:
			return {
				...state,
				getRelatedTrials: action.getRelatedTrials,
				getRelatedTrialsError: '',
				isFetchingRelatedTrials: false
			};

		case ActionTypes.GET_RELATED_TRIAL_ERROR:
			return {
				...state,
				getRelatedTrialsError: action.getChecklistsError,
				isFetchingRelatedTrials: false
			};

		case ActionTypes.REQUEST_RELATED_TRIAL_ITEM_DETAILS:
			return {
				...state,
				isFetchingRelatedTrialItemDetails: true
			};

		case ActionTypes.GET_RELATED_TRIAL_DETAIL_SUCCESS:
			return {
				...state,
				isFetchingRelatedTrialItemDetails: false,
				getRelatedTrialDetail: action.getRelatedTrialDetail,
				getRelatedTrialDetailErr: ''
			};

		case ActionTypes.GET_RELATED_TRIAL_DETAIL_ERROR:
			return {
				...state,
				isFetchingRelatedTrialItemDetails: false,
				getRelatedTrialDetailErr: action.getRelatedTrialDetailErr
			};

		default:
			return state;
	}
};

export default relatedTrialsReducer;
