/** @format */

import React from "react";

import PreTrialCommunicationThankYouEndoscopy from "./PreTrialCommunicationThankYouEndoscopy";
import PreTrialCommunicationThankYouCommunications from "./PreTrialCommunicationThankYouCommunications";
import TrialRecapUpdateEndoscopy from "./TrialRecapUpdateEndoscopy";
import InServiceInviteCommunications from "./InServiceInviteCommunications";
import InServiceInviteEndoscopy from "./InServiceInviteEndoscopy";
import InServiceInviteMed from "./InServiceInviteMed";
import InServiceRecapCommunications from "./InServiceRecapCommunications";
import InServiceRecapEndoscopy from "./InServiceRecapEndoscopy";
import WeeklyEngagementEndoscopy from "./WeeklyEngagementEndoscopy";
import TrialRecapUpdateCommunications from "./TrialRecapUpdateCommunications";

const EditEmail = ({
  handleInputChange,
  inputs,
  engagementEmailDataFromAPI,
  formValidationErrors,
}) => {
  let editEmailTemplate = null;
  if (engagementEmailDataFromAPI?.template_type === "pre_trial") {
    editEmailTemplate = (
      <PreTrialCommunicationThankYouEndoscopy
        inputs={inputs}
        handleInputChange={handleInputChange}
        formValidationErrors={formValidationErrors}
      />
    );
  } else if (
    engagementEmailDataFromAPI?.template_type === "integration_trial"
  ) {
    editEmailTemplate = (
      <PreTrialCommunicationThankYouCommunications
        inputs={inputs}
        handleInputChange={handleInputChange}
        formValidationErrors={formValidationErrors}
      />
    );
  } else if (engagementEmailDataFromAPI?.template_type === "service_invite") {
    editEmailTemplate = (
      <InServiceInviteCommunications
        inputs={inputs}
        handleInputChange={handleInputChange}
        formValidationErrors={formValidationErrors}
      />
    );
  } else if (
    engagementEmailDataFromAPI?.template_type === "service_invite_endo"
  ) {
    editEmailTemplate = (
      <InServiceInviteEndoscopy
        inputs={inputs}
        handleInputChange={handleInputChange}
        formValidationErrors={formValidationErrors}
      />
    );
  } else if (
    engagementEmailDataFromAPI?.template_type === "service_invite_med"
  ) {
    editEmailTemplate = (
      <InServiceInviteMed
        inputs={inputs}
        handleInputChange={handleInputChange}
        formValidationErrors={formValidationErrors}
      />
    );
  } else if (engagementEmailDataFromAPI?.template_type === "service_recap") {
    editEmailTemplate = (
      <InServiceRecapCommunications
        inputs={inputs}
        handleInputChange={handleInputChange}
        formValidationErrors={formValidationErrors}
      />
    );
  } else if (
    engagementEmailDataFromAPI?.template_type === "service_recap_endo"
  ) {
    editEmailTemplate = (
      <InServiceRecapEndoscopy
        inputs={inputs}
        handleInputChange={handleInputChange}
        formValidationErrors={formValidationErrors}
      />
    );
  } else if (engagementEmailDataFromAPI?.template_type === "trial_recap") {
    editEmailTemplate = (
      <TrialRecapUpdateEndoscopy
        inputs={inputs}
        handleInputChange={handleInputChange}
        engagementEmailDataFromAPI={engagementEmailDataFromAPI}
        formValidationErrors={formValidationErrors}
      />
    );
  } else if (
    engagementEmailDataFromAPI?.template_type === "weekly_engagement"
  ) {
    editEmailTemplate = (
      <WeeklyEngagementEndoscopy
        inputs={inputs}
        handleInputChange={handleInputChange}
        formValidationErrors={formValidationErrors}
      />
    );
  } else if (
    engagementEmailDataFromAPI?.template_type === "integration_trial_complete"
  ) {
    editEmailTemplate = (
      <TrialRecapUpdateCommunications
        inputs={inputs}
        handleInputChange={handleInputChange}
        formValidationErrors={formValidationErrors}
      />
    );
  } else {
    editEmailTemplate = <p>template does not exist</p>;
  }
  return editEmailTemplate;
};

export default EditEmail;
