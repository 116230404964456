import React from 'react';
import { Row } from 'reactstrap';

const StatsTrials = (props) => {
	const { mainCount } = props;

	if (mainCount) {
		const { cases_completed, trial_day_left, influencers_impacted } = mainCount;

		let splitInfluencersImpacted = influencers_impacted.split('/');

		return (
			<Row className="dashboard-stats adj-m trial-statistics">
				<div>
					<label className="trial-statistics__label">CASES COMPLETED</label>
					<div className="number dkgrey">{cases_completed}</div>
				</div>
				<div>
					<label className="trial-statistics__label">TRIAL DAYS LEFT</label>
					<div className="number dkgrey">{trial_day_left}</div>
				</div>
				<div>
					<label className="trial-statistics__label">INFLUENCERS IMPACTED</label>
					<div className="number dkgrey">
						{splitInfluencersImpacted[0]}
						<span className="denominator">/{splitInfluencersImpacted[1]}</span>
					</div>
				</div>
			</Row>
		);
	}

	return null;
};

export default StatsTrials;
