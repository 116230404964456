import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import StatsMyHistory from "./StatsMyHistory";
import TableTrialsMyTrialHistory from "./TrialHistory";
import TableTrialsMyCaseHistory from "./TrialCaseHistory";
import { selectedSurvey } from "../../../Data/database";
import { connect } from 'react-redux';

import { getCustomerDetailsAction } from '../../../Store/actions/trialsAction';
import CustomerDetails from "./CustomerDetails";
import ComponentLoading from "../../../Components/ComponentLoading/ComponentLoading";

class TrialCustomerHistory extends Component {

    componentDidMount () {
        const { id } = this.props.match.params;
        this.props.getCustomerDetails({customerId: id})
    }

    render () {
        const { customerDetails } = this.props;
        return (
            <>
                <div className="page pb-80">
                    <div className="tab-title-survey">

                        { customerDetails &&
                            <CustomerDetails
                                customer={ customerDetails.account_details || {} }
                            /> }
                    </div>

                    { customerDetails &&
                        <TableTrialsMyTrialHistory
                            trials={ customerDetails.trial_history ? customerDetails.trial_history.map(t => {return{id: t.trial_id, name: t.trial_name, createdDate: t.created_date, active: t.trial_status === 'active'}}) : []}
                        /> }

                    { customerDetails &&
                        <TableTrialsMyCaseHistory
                            cases={ customerDetails.case_history ? customerDetails.case_history.map(c => {return{trialID: c.trial_id, name: c.case_procedure, procedureDate: c.case_date, id: c.id}}) : [] }
                        /> }

                    { !customerDetails ? (<ComponentLoading message="Loading..."/>) : (null) }
                </div >
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        customerDetails: state.trials.customerDetails,
        customerDetailsError: state.trials.customerDetailsError
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCustomerDetails: ({customerId}) => {
            dispatch(getCustomerDetailsAction({customerId}));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TrialCustomerHistory));
