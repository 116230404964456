import * as ActionTypes from '../actions/ActionTypes';

const initState = {
	isFetchingSurveys: false,
	surveys: [],
	surveysPage: 1,
	surveysPageLimitReached: false,
	surveysError: '',

	isFetchingSurveyTemplates: false,
	templates: [],
	templatesPage: 1,
	templatesPageLimitReached: false,
	templatesError: '',

	isFetchingSurveyCrushTemplates: false,
	crushTemplates: [],
	crushTemplatesPage: 1,
	crushTemplatesPageLimitReached: false,
	crushTemplatesError: '',

	surveyResults: [],
	surveyResultsPage: 1,
	surveyResultsPageLimitReached: false,
	surveyResultsError: '',

	createSurveysTemplateName: {},
	createSurveysTemplateNameError: '',

	createSurveysQuestionError: '',
	duplicateSurveyError: '',

	surveysRespondents: [],
	surveysRespondentsError: '',

	selectedTemplate: {
		active_for_fillout: '',
		created_by: '',
		created_by_id: '',
		created_date: '',
		crush_template_id: '',
		last_taken: '',
		ordering: '',
		question_list: [],
		respondents: '',
		template_id: '',
		template_name: 'LOADING',
		trial_id: '',
		updated_date: ''
	},
	selectedTemplateError: {},

	completedSurvey: {},
	completedSurveyError: '',

	surveyResultSummary: {},
	surveyResultSummaryError: ''
};

const surveysReducer = (state = initState, action) => {
	switch (action.type) {
		case ActionTypes.REQUEST_GET_SURVEYS:
			return {
				...state,
				isFetchingSurveys: true
			};

		case ActionTypes.GET_SURVEYS_SUCCESS:
			let surveys = state.surveys;
			 if (action.surveysPage === 1 && action.surveysPageLimitReached) {
				surveys = action.surveys;
			}else if (
				(action.surveysPage > state.surveysPage || action.surveysPageLimitReached) &&
				action.surveys.length > 0
			) {
				surveys = [ ...surveys, ...action.surveys ];
			} 
			return {
				...state,
				surveys: surveys,
				surveysPage: action.surveysPage,
				surveysPageLimitReached: action.surveysPageLimitReached,
				surveysError: '',
				isFetchingSurveys: false
			};

		case ActionTypes.GET_SURVEYS_ERROR:
			return {
				...state,
				surveysError: action.surveysError,
				isFetchingSurveys: false
			};

		case ActionTypes.REQUEST_GET_SURVEY_TEMPLATES:
			return {
				...state,
				isFetchingSurveyTemplates: true
			};

		case ActionTypes.GET_SURVEY_TEMPLATES_SUCCESS:
			let templates = state.templates;
			 if (action.templatesPage === 1 && action.templatesPageLimitReached) {
				templates = action.templates;
			} else if (
				(action.templatesPage > state.templatesPage || action.templatesPageLimitReached) &&
				action.templates.length > 0
			) {
				templates = [ ...templates, ...action.templates ];
			}
			return {
				...state,
				templates: templates,
				templatesPage: action.templatesPage,
				templatesPageLimitReached: action.templatesPageLimitReached,
				templatesError: '',
				isFetchingSurveyTemplates: false
			};

		case ActionTypes.GET_SURVEY_TEMPLATES_ERROR:
			return {
				...state,
				templatesError: action.templatesError,
				templates: [],
				isFetchingSurveyTemplates: false
			};

		case ActionTypes.REQUEST_GET_SURVEY_CRUSH_TEMPLATES:
			return {
				...state,
				isFetchingSurveyCrushTemplates: true
			};

		case ActionTypes.GET_SURVEY_CRUSH_TEMPLATES_SUCCESS:
			let crushTemplates = state.crushTemplates;
			if (
				(action.crushTemplatesPage > state.crushTemplatesPage || action.crushTemplatesPageLimitReached) &&
				action.crushTemplates.length > 0
			) {
				crushTemplates = [ ...crushTemplates, ...action.crushTemplates ];
			} else if (action.crushTemplatesPage === 1 && action.crushTemplatesPageLimitReached) {
				crushTemplates = action.crushTemplates;
			}
			return {
				...state,
				crushTemplates: crushTemplates,
				crushTemplatesPage: action.crushTemplatesPage,
				crushTemplatesPageLimitReached: action.crushTemplatesPageLimitReached,
				crushTemplatesError: '',
				isFetchingSurveyCrushTemplates: false
			};

		case ActionTypes.GET_SURVEY_CRUSH_TEMPLATES_ERROR:
			return {
				...state,
				crushTemplates: [],
				crushTemplatesError: action.crushTemplatesError,
				isFetchingSurveyCrushTemplates: false
			};

		case ActionTypes.GET_SURVEY_RESULTS_SUCCESS:
			let surveyResults = state.surveyResults;
			if (action.page > state.surveyResultsPage && action.surveyResults.length > 0) {
				surveyResults = [ ...surveyResults, ...action.surveyResults ];
			}
			return {
				...state,
				surveyResults: surveyResults,
				surveyResultsPage: action.page,
				surveyResultsPageLimitReached: action.limitReached,
				surveyResultsError: ''
			};

		case ActionTypes.GET_SURVEY_RESULTS_ERROR:
			return {
				...state,
				surveyResultsError: action.surveysError
			};

		case ActionTypes.CREATE_SURVEYS_TEMPLATE_NAME_SUCCESS:
			return {
				...state,
				createSurveysTemplateName: action.createSurveysTemplateName,
				createSurveysTemplateNameError: ''
			};

		case ActionTypes.CREATE_SURVEYS_TEMPLATE_NAME_ERROR:
			return {
				...state,
				createSurveysTemplateNameError: action.createSurveysTemplateNameError
			};

		case ActionTypes.CREATE_SURVEYS_QUESTION_SUCCESS:
			return {
				...state,
				createSurveysQuestionError: ''
			};

		case ActionTypes.CREATE_SURVEYS_QUESTION_ERROR:
			return {
				...state,
				createSurveysQuestionError: action.createSurveysQuestionError
			};

		case ActionTypes.GET_TEMPLATES_SUCCESS:
			return {
				...state,
				templates: action.templates,
				templatesError: ''
			};

		case ActionTypes.GET_TEMPLATES_ERROR:
			return {
				...state,
				templatesError: action.templatesError,
				templates: []
			};

		case ActionTypes.GET_SURVEY_TEMPLATE_SUCCESS:
			return {
				...state,
				selectedTemplate: action.selectedTemplate,
				selectedTemplateError: ''
			};

		case ActionTypes.GET_SURVEY_TEMPLATE_ERROR:
			return {
				...state,
				selectedTemplateError: action.selectedTemplateError,
				selectedTemplate: []
			};

		case ActionTypes.GET_SURVEYS_RESPONDENTS_SUCCESS:
			return {
				...state,
				surveysRespondents: action.surveysRespondents,
				surveysRespondentsError: ''
			};

		case ActionTypes.GET_SURVEYS_RESPONDENTS_ERROR:
			return {
				...state,
				surveysRespondentsError: action.surveysRespondentsError
			};

		case ActionTypes.DUPLICATE_SURVEYS_SUCCESS:
			return {
				...state,
				duplicateSurveyError: ''
			};

		case ActionTypes.DUPLICATE_SURVEYS_ERROR:
			return {
				...state,
				duplicateSurveyError: action.duplicateSurveyError
			};

		case ActionTypes.GET_COMPLETED_SURVEY_SUCCESS:
			return {
				...state,
				completedSurvey: action.completedSurvey,
				completedSurveyError: ''
			};

		case ActionTypes.GET_COMPLETED_SURVEY_ERROR:
			return {
				...state,
				completedSurvey: {},
				completedSurveyError: action.completedSurveyError
			};

		case ActionTypes.GET_SURVEY_SUMMARY_SUCCESS:
			return {
				...state,
				surveyResultSummary: action.surveyResultSummary,
				surveyResultSummaryError: ''
			};

		case ActionTypes.GET_SURVEY_SUMMARY_ERROR:
			return {
				...state,
				surveyResultSummary: {},
				surveyResultSummaryError: action.surveyResultSummaryError
			};
		case ActionTypes.EDIT_SURVEY_QUESTION_SUCCESS:
			if (state.selectedTemplate)
			{
				const sTemplate = state.selectedTemplate;
				const sTemplateQuestions = sTemplate.question_list.map(question => { 
					return question.id === action.question.id ? action.question : question;
				});
				sTemplate.question_list = sTemplateQuestions;
				return {
					...state,
					selectedTemplate: sTemplate,
					selectedTemplateError: ''
				};
			}

		default:
			return state;
	}
};

export default surveysReducer;
