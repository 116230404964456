import React from 'react';
import "./fileSingleTalkTrk.css"

//Talk Tracks- Sub Single File
const FileSingleTalkTrk = props => {
    const { filteredTalk } = props;

    return (
        <>
            <div className="tab-text">
                <div className="tab-title">Probing questions</div>
                <div className="line-blk-shrink" />
                { filteredTalk.map(item => (
                    <ul key={ item.id } className="m-3">
                        {item.questions.map(item => (
                            <li key={ item.id } className="text-list">{ item.question }</li>)) }
                    </ul>
                )) }
            </div>
            <div className="tab-text">
                <div className="tab-title">Talking points</div>
                <div className="line-blk-shrink" />
                { filteredTalk.map(item => (
                    <ul key={ item.id } className="m-3">
                        {item.points.map(item => (
                            <li key={ item.id } className="text-list">{ item.point }</li>)) }
                    </ul>
                )) }
            </div>
        </>
    );
}

export default FileSingleTalkTrk;