import React, { useState, useEffect } from 'react';
import { Container } from 'reactstrap';
import Search from '../Utils/Search';
import Pagination from '../Components/Pagination';
import TableTalkTrkSub from '../Components/TableTalkTrkSub';
import Paginate from '../Utils/Paginate';
import { getCategoriesTalkT } from '../Data/database';
import { ListGrpSpecial } from '../Components/ListGroup';
import _ from 'lodash';
import List from '../Components/List/List';

import {
    resetTalkTrackSingleInReducerAction
} from '../Store/actions/tracksAction';

import NoResults from '../Components/NoResults/NoResults';
import ErrorMessage from '../Components/ErrorMessage/ErrorMessage';

import { useDispatch } from 'react-redux';

const TalkTracksSub = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetTalkTrackSingleInReducerAction());
    }, []);

    const categories = getCategoriesTalkT();
    const pageSize = 5;
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ selectedCategory, setSelectedCategory ] = useState(props);
    const [ sortColumn, setSortColumn ] = useState({ path: "name", order: "asc" });

    const handleCategorySelect = category => {
        setSelectedCategory(category);
        setCurrentPage(1);
    };

    const handleSort = path => {
        if (sortColumn.path === path)
        {
            (sortColumn.order === "asc")
                ? setSortColumn({ path, order: "desc" })
                : setSortColumn({ path, order: "asc" });
        } else
        {
            sortColumn.path = path;
            setSortColumn({ path, order: "asc" })
        }
    }

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const {
        specialtyFromAPI,
        specialtyError,
        isFetchingSpecialty
    } = props;

    let foundSpecialty = specialtyFromAPI.find(specialty => {
        return specialty.id === props.match.params.productId
    });

    const [ specialty, setSpecialty ] = useState({});

    useEffect(() => {
        if (foundSpecialty)
        {
            setSpecialty(foundSpecialty);
            setSpecialtyProducts(foundSpecialty.product);
        }
    }, [ foundSpecialty ]);


    let [ specialtyProducts, setSpecialtyProducts ] = useState([]);
    const filterSpecialtyProductsBySearch = (ev) => {
        let filteredSpecialtyProductsArray = foundSpecialty?.product.filter((specialtyProduct) => {
            if (specialtyProduct.name.toLowerCase().includes(ev.currentTarget.value.toLowerCase().trim()))
            {
                return true;
            }

            return false;
        });

        setSpecialtyProducts(filteredSpecialtyProductsArray);
    }

    const sortedSpecialtyProducts = _.orderBy(specialtyProducts, [ sortColumn.path ], [ sortColumn.order ]).map((product) => { return { ...product, type: 'specialty', parentId: foundSpecialty ? (foundSpecialty.id) : (null) } });
    const paginatedSpecialtyProducts = sortedSpecialtyProducts;//Paginate(sortedSpecialtyProducts, currentPage, pageSize);

    return (
        <>
            <Container className="tab-title-main">
                <h1 className="section-title">Talk Tracks</h1>
                <div className="nav-pagelink">
                    <ListGrpSpecial
                        items={ categories }
                        onItemSelect={ handleCategorySelect }
                        selectedItem={ selectedCategory }
                        selectedName={ specialty.name || '' }
                    />
                </div>
                <div className="hr-bk-expand-ml-mr-15" />
                <Search handleFilter={ filterSpecialtyProductsBySearch } />
            </Container>
            <Container>
                <List
                    list={ paginatedSpecialtyProducts }
                    actions={ null }
                    sortAlphabeticallyByTitle={ handleSort }
                    sortBy="name"
                    view="talk-tracks-sub-page"
                    isLoading={ isFetchingSpecialty } />

                { specialtyError ? (
                    <ErrorMessage message={ specialtyError } />
                ) : (null) }

                { !isFetchingSpecialty && paginatedSpecialtyProducts.length === 0 ? (
                    <NoResults />
                ) : (null) }

                {/* <Pagination
                    itemsCount={ paginatedSpecialtyProducts.length }
                    pageSize={ pageSize }
                    currentPage={ currentPage }
                    onPageChange={ handlePageChange }
                /> */}
            </Container>
        </>
    );
}

export default TalkTracksSub;

