import React, { useEffect } from "react";
import { Switch, Route, useParams } from "react-router-dom";

import NavBar from "../Navi/NavBar";
import TrialsInfo from "../Pages/Trial/TrialInfo/TrialInfo";
import TrialsOrganizationPeople from "../Pages/TrialsOrganizationPeople";
import AddATeammate from '../Pages/Trial/AddATeammate/AddATeammate';
import TrialSummary from "../Pages/Trial/TrialSummary/TrialSummary";
import TrialPeopleHistory from "../Pages/Trial/TrialPeopleHistory/TrialPeopleHistory";
import SurgeonInformation from "../Pages/Trial/SurgeonInformation/SurgeonInformation";
import SurgeonInformationEditAndDelete from "../Pages/Trial/SurgeonInformation/SurgeonInformationEditAndDelete/SurgeonInformationEditAndDelete";
import Cases from "../Pages/Trial/Cases/Cases";
import TrialChecklist from "../Pages/Trial/TrialChecklist/TrialChecklist";
import TrialChecklistComments from "../Pages/Trial/TrialChecklist/TrialChecklistComments/TrialChecklistComments";
import EngagementEmails from "../Pages/Trial/EngagementEmails/EngagementEmails";
import CustomizeEmail from "../Pages/Trial/EngagementEmails/CustomizeEmail/CustomizeEmail";
import EmailResults from '../Pages/Trial/EngagementEmails/EmailResults/EmailResults';
import RelatedTrials from "../Pages/Trial/RelatedTrials/RelatedTrials";
import ItemDetails from "../Pages/Trial/RelatedTrials/ItemDetails/ItemDetails";

import CreateTrial from '../Pages/Trial/CreateTrial/Home/Home';

import { useDispatch, useSelector } from 'react-redux';
import {
    getTrialDetailAction,
    resetTrialsReducerAction
} from '../Store/actions/trialsAction';

const DefaultContainer = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        if (id !== 'create')
        {
            dispatch(resetTrialsReducerAction());
            dispatch(getTrialDetailAction(id));
        }
    }, [ id ]);

    const trialDetail = useSelector(state => state.trials.trialDetail);
    const isFetchingTrialDetails = useSelector(state => state.trials.isFetchingTrialDetails);
    const trialDetailError = useSelector(state => state.trials.trialDetailError);

    return (
        <React.Fragment>
            <NavBar />
            <Switch>
                <Route exact path="/trial/create"><CreateTrial /></Route>
                <Route exact path="/trial/:id/add-a-teammate"> <AddATeammate /> </Route>
                <Route exact path="/trial/:id/engagement-email" ><EngagementEmails /></Route>
                <Route exact path="/trial/:id/engagement-email/:engagementEmailID/customize-email" ><CustomizeEmail /></Route>
                <Route exact path="/trial/:id/engagement-emails/:engagementEmailID/results"> <EmailResults /> </Route>
                <Route exact path="/trial/:id">
                    <TrialsInfo
                        mainCount={ trialDetail?.main_count }
                        crushTrialProgressData={ trialDetail?.workflow }
                        trialStatusText={ trialDetail?.trial_details?.trial_status }
                        name={ trialDetail?.trial_details?.name }
                        isFetchingTrialDetails={ isFetchingTrialDetails } />
                </Route>
                <Route exact path="/trial/:id/organization/people" ><TrialsOrganizationPeople accountID={ trialDetail?.trial_details?.account_id } /></Route>
                <Route exact path="/trial/:id/organization/trial-summary" ><TrialSummary /></Route>
                <Route exact path="/trial/:id/organization/people/:type/history/:stakeholderOrSurgeonID/:name" ><TrialPeopleHistory /></Route>
                <Route exact path="/trial/:id/organization/cases/surgeon/:surgeonID" ><SurgeonInformation /></Route>
                <Route exact path="/trial/:id/organization/cases/surgeon/:surgeonID/edit/:caseID" ><SurgeonInformationEditAndDelete /></Route>
                <Route exact path="/trial/:id/organization/cases/" ><Cases accountID={ trialDetail?.trial_details?.account_id } /></Route>
                <Route exact path="/trial/:id/organization/checklist/:checklistID" ><TrialChecklistComments /></Route>
                <Route exact path="/trial/:id/organization/checklist/" ><TrialChecklist /></Route>
                <Route exact path="/trial/:id/intelligence/relatedTrials/" ><RelatedTrials /></Route>
                <Route exact path="/trial/:id/intelligence/relatedTrials/:itemType/:categoryName/:itemId" ><ItemDetails /></Route>
            </Switch>
        </React.Fragment >
    )
}

export default DefaultContainer;