import * as ActionTypes from '../actions/ActionTypes';

const initState = {
	surveysAndEvaluations: [],
	surveysAndEvaluationsError: '',
	templates: false,
	templatesError: '',
	createSurveysAndEvaluationsTemplateName: {},
	createSurveysAndEvaluationsTemplateNameError: '',
	createSurveysAndEvaluationsQuestionError: '',
	duplicateSurveyAndEvaluationError: '',

	completedEvaluation: {},
	completedEvaluationError: ''
};

const surveysAndEvaluationsReducer = (state = initState, action) => {
	switch (action.type) {
		case ActionTypes.GET_SURVEYS_AND_EVALUATIONS_SUCCESS:
			return {
				...state,
				surveysAndEvaluations: action.surveysAndEvaluations,
				surveysAndEvaluationsError: ''
			};

		case ActionTypes.GET_SURVEYS_AND_EVALUATIONS_ERROR:
			return {
				...state,
				surveysAndEvaluationsError: action.surveysAndEvaluationsError,
				surveysAndEvaluations: []
			};

		case ActionTypes.CREATE_SURVEYS_AND_EVALUATIONS_TEMPLATE_NAME_SUCCESS:
			return {
				...state,
				createSurveysAndEvaluationsTemplateName: action.createSurveysAndEvaluationsTemplateName,
				createSurveysAndEvaluationsTemplateNameError: ''
			};

		case ActionTypes.CREATE_SURVEYS_AND_EVALUATIONS_TEMPLATE_NAME_ERROR:
			return {
				...state,
				createSurveysAndEvaluationsTemplateNameError: action.createSurveysAndEvaluationsTemplateNameError
			};

		case ActionTypes.CREATE_SURVEYS_AND_EVALUATIONS_QUESTION_SUCCESS:
			return {
				...state,
				createSurveysAndEvaluationsQuestionError: ''
			};

		case ActionTypes.CREATE_SURVEYS_AND_EVALUATIONS_QUESTION_ERROR:
			return {
				...state,
				createSurveysAndEvaluationsQuestionError: action.createSurveysAndEvaluationsQuestionError
			};

		case ActionTypes.GET_TEMPLATES_SUCCESS:
			return {
				...state,
				templates: action.templates,
				templatesError: ''
			};

		case ActionTypes.GET_TEMPLATES_ERROR:
			return {
				...state,
				templatesError: action.templatesError,
				templates: []
			};

		case ActionTypes.GET_SURVEYS_AND_EVALUATIONS_RESPONDENTS_SUCCESS:
			return {
				...state,
				surveysAndEvaluationsRespondents: action.surveysAndEvaluationsRespondents,
				surveysAndEvaluationsRespondentsError: ''
			};

		case ActionTypes.GET_SURVEYS_AND_EVALUATIONS_RESPONDENTS_ERROR:
			return {
				...state,
				surveysAndEvaluationsRespondentsError: action.surveysAndEvaluationsRespondentsError
			};

		case ActionTypes.DUPLICATE_SURVEYS_AND_EVALUATIONS_SUCCESS:
			return {
				...state,
				duplicateSurveyAndEvaluationError: ''
			};

		case ActionTypes.DUPLICATE_SURVEYS_AND_EVALUATIONS_ERROR:
			return {
				...state,
				duplicateSurveyAndEvaluationError: action.duplicateSurveyAndEvaluationError
			};

		case ActionTypes.GET_COMPLETED_EVALUATION_SUCCESS:
			return {
				...state,
				completedEvaluation: action.completedEvaluation,
				completedEvaluationError: ''
			};

		case ActionTypes.GET_COMPLETED_EVALUATION_ERROR:
			return {
				...state,
				completedEvaluation: {},
				completedEvaluationError: action.completedEvaluationError
			};

		default:
			return state;
	}
};

export default surveysAndEvaluationsReducer;
