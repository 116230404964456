import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import Search from '../../../Utils/Search';
import List from '../../../Components/List/List';
import { relatedTrialListingAction } from '../../../Store/actions/relatedTrialActions';
import { Modal } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import _ from 'lodash';
import NoResults from '../../../Components/NoResults/NoResults';

class RelatedTrials extends Component {
	state = {
		selectedCategory: 'competitor',
		sortType: 'name',
		sortColumn: {
			path: 'name',
			order: 'desc'
		},
		searchValue: ''
	};

	componentDidMount() {
		const trialID = this.props.match.params.id;
		this.props.getRelatedTrials(trialID);
	}

	filterCategoryBySearch = () => {
		let cloneRelatedTrials = JSON.parse(JSON.stringify(this.props.relatedTrials));
		return cloneRelatedTrials[this.state.selectedCategory]
			.filter((trial) => {
				return trial.name.toLowerCase().includes(this.state.searchValue.toLowerCase().trim());
		});
	};

	handleSort = (path) => {
		const { sortColumn } = this.state;
		if (sortColumn.path === path) {
			sortColumn.order === 'asc'
				? this.setState({ sortColumn: { path, order: 'desc' } })
				: this.setState({ sortColumn: { path, order: 'asc' } });
		} else {
			sortColumn.path = path;
			this.setState({ sortColumn: { path, order: 'asc' } });
		}
	};

	handleInputChange = (event) => {
		this.setState({
			searchValue: event.currentTarget.value
		});
	}

	render() {
		const trialID = this.props.match.params.id;
		const { relatedTrials, isFetchingRelatedTrials } = this.props;
		const { selectedCategory, sortType, sortColumn } = this.state;

		let filterCategoryBySearch = this.filterCategoryBySearch();

		const sortedCategory = _.orderBy(
			filterCategoryBySearch,
			[ sortColumn.path ],
			[ sortColumn.order ]
		);

		return (
			<div>
				<Container className="tab-title-main">
					<h1 className="section-title">Related trials</h1>
					<div className="nav-pagelink">
						<div
							className={`pagelink-sml mr-3half  ${selectedCategory === 'competitor'
								? 'active'
								: 'inactive'} `}
							onClick={() => this.setState({ selectedCategory: 'competitor' })}
						>
							Competitor
						</div>
						<div
							className={`pagelink-sml ${selectedCategory === 'specialities' ? 'active' : 'inactive'} `}
							onClick={() => this.setState({ selectedCategory: 'specialities' })}
						>
							Specialties
						</div>
					</div>
					<div className="hr-bk-expand-ml-mr-15" />
					<Search searchingValue={ this.state.searchValue } handleInputChange={ this.handleInputChange  } />
				</Container>

				<Container>
					<List
						list={sortedCategory}
						selectedCategory={selectedCategory}
						actions={null}
						sortAlphabeticallyByTitle={this.handleSort}
						sortBy="name"
						view="related-trials"
						isLoading={ isFetchingRelatedTrials }
					/>

					{ sortedCategory.length === 0 && 
						!isFetchingRelatedTrials ? (
						<NoResults />
					) : (null) }
				</Container>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		relatedTrials: state.relatedTrials.getRelatedTrials,
		isFetchingRelatedTrials: state.relatedTrials.isFetchingRelatedTrials
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getRelatedTrials: (trialID) => {
			dispatch(relatedTrialListingAction(trialID));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RelatedTrials));
