import React, { useState, useEffect } from 'react';
import classes from '../../InputElements.module.css';

import { useDispatch, useSelector } from 'react-redux';
import { getStakeholderTitlesAction } from '../../../../../Store/actions/trialsAction';
import { useParams } from 'react-router-dom';
import LevelOfInfluence from '../../../../../Utils/LevelOfInfluence/LevelOfInfluence';

const SecondView = (props) => {
	const { inputs, handleInputChange } = props;

	const dispatch = useDispatch();
	const { id } = useParams();
	useEffect(() => {
		dispatch(getStakeholderTitlesAction(id ? id : ''));
	}, []);

	const stakeholderTitlesArray = useSelector((state) => state.trials.stakeholderTitlesArray);
	const stakeholderTitlesError = useSelector((state) => state.trials.stakeholderTitlesError);

	const [ currentView, setCurrentView ] = useState(0);
	const selectType = () => {
		if (currentView === 0) {
			handleInputChange({
				target: {
					name: 'type',
					value: 'stakeholder'
				}
			});
			return setCurrentView(1);
		}

		handleInputChange({
			target: {
				name: 'type',
				value: 'surgeon'
			}
		});
		return setCurrentView(0);
	};

	const [ isNewTitle, setIsNewTitle ] = useState(false);
	const checkIfDropDownValueIsNewTitle = (ev) => {
		handleInputChange(ev);
		if (ev.target.value === '1-Add new title') return setIsNewTitle(true);

		return setIsNewTitle(false);
	};

	const handleStakeholderTitleTextChange = (ev) => {
		if (isNewTitle) {
			handleInputChange(ev);
		}
	};

	return (
		<div className={classes['form__groups-container']}>
			<div className={classes['form__select-type-container']}>
				<div className={classes['form__select-type-wrapper']}>
					<label
						onClick={selectType}
						htmlFor="select-surgeon"
						className={
							currentView === 0 ? (
								[
									classes['form__select-type'],
									classes['form__select-type--selected-stakeholder']
								].join(' ')
							) : (
								classes['form__select-type']
							)
						}
					>
						surgeon
					</label>
					<input className="hidden" name="type" id="select-surgeon" value="surgeon" type="radio" />
				</div>
				<div className={classes['form__select-type-wrapper']}>
					<label
						onClick={selectType}
						htmlFor="select-stakeholder"
						className={
							currentView === 1 ? (
								[ classes['form__select-type'], classes['form__select-type--selected-surgeon'] ].join(
									' '
								)
							) : (
								classes['form__select-type']
							)
						}
					>
						stakeholder
					</label>
					<input className="hidden" name="type" id="select-stakeholder" value="stakeholder" type="radio" />
				</div>
			</div>
			<div className={classes['form__group']}>
				<div className={classes['form__label']}>influence</div>
				<LevelOfInfluence handleInputChange={handleInputChange} />
			</div>
			{currentView === 1 ? (
				<div className={classes['form__group']}>
					<label className={classes['form__label']} htmlFor="personTitleAndID">
						title
					</label>
					<select
						onChange={checkIfDropDownValueIsNewTitle}
						className={classes['form__select']}
						name="personTitleAndID"
						id="personTitleAndID"
					>
						{stakeholderTitlesArray.map((stakeholderTitle) => {
							return (
								<option
									value={`${stakeholderTitle.id}-${stakeholderTitle.options}`}
									key={stakeholderTitle.id}
								>
									{stakeholderTitle.options}
								</option>
							);
						})}
					</select>
					{isNewTitle ? (
						<label htmlFor="stakeholderTitleText">
							<input
								onChange={handleStakeholderTitleTextChange}
								value={inputs.stakeholderTitleText}
								className={classes['form__input']}
								type="text"
								id="stakeholderTitleText"
								name="stakeholderTitleText"
							/>
						</label>
					) : null}
				</div>
			) : null}
		</div>
	);
};

export default SecondView;
