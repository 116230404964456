/** @format */

export const createFormDataPreTrialCommunicationThankYouEndoscopy = ({
  engagementEmailDataFromAPI,
  inputs,
  id,
}) => {
  let contentJSON = {
    account_name: inputs.account_name,
    content: inputs.content,
    facility_image: inputs.facility_image,
    facility_logo: inputs.facility_logo,
    footer: inputs.footer,
    head_line: inputs.head_line,
    header_two: inputs.header_two,
    video: inputs.video,
  };

  let formData = new FormData();
  formData.append("trial_id", id);
  formData.append("user_id", localStorage.getItem("user_id"));
  if (engagementEmailDataFromAPI.user_template_id) {
    formData.append("template_id", engagementEmailDataFromAPI.user_template_id);
  }
  formData.append(
    "admin_template_id",
    engagementEmailDataFromAPI.admin_template_id
  );
  formData.append("name", inputs.emailName);
  formData.append("template_type", engagementEmailDataFromAPI.template_type);
  formData.append("template_status", "draft");
  if (inputs.subjectLine) {
    formData.append("subject", inputs.subjectLine);
  } else {
    formData.append("subject", "");
  }

  for (const property in contentJSON) {
    formData.append(property, contentJSON[property]);
  }

  return formData;
};

export const createFormDataForInServiceInviteCommunications = ({
  engagementEmailDataFromAPI,
  inputs,
  id,
}) => {
  let contentJSON = {
    title: inputs.title,
    sub_title: inputs.sub_title,
    title_description: inputs.title_description,
    when_title: inputs.when_title,
    when_content: inputs.when_content,
    when_time: inputs.when_time,
    where_title: inputs.where_title,
    where_content: inputs.where_content,
    where_content_two: inputs.where_content_two,
    what_title: inputs.what_title,
    what_content: inputs.what_content,
    what_content_two: inputs.what_content_two,
    content_fname_lname: inputs.content_fname_lname,
    content: inputs.content,
  };

  let formData = new FormData();
  formData.append("trial_id", id);
  formData.append("user_id", localStorage.getItem("user_id"));
  if (engagementEmailDataFromAPI.user_template_id) {
    formData.append("template_id", engagementEmailDataFromAPI.user_template_id);
  }
  formData.append(
    "admin_template_id",
    engagementEmailDataFromAPI.admin_template_id
  );
  formData.append("name", inputs.emailName);
  formData.append("template_type", engagementEmailDataFromAPI.template_type);
  formData.append("template_status", "draft");
  if (inputs.subjectLine) {
    formData.append("subject", inputs.subjectLine);
  } else {
    formData.append("subject", "");
  }

  for (const property in contentJSON) {
    formData.append(property, inputs[property]);
  }

  return formData;
};

export const createFormDataForInServiceInviteMed = ({
  engagementEmailDataFromAPI,
  inputs,
  id,
}) => {
  let contentJSON = {
    title: inputs.title,
    sub_title: inputs.sub_title,
    title_description: inputs.title_description,
    when_title: inputs.when_title,
    when_content: inputs.when_content,
    when_time: inputs.when_time,
    where_title: inputs.where_title,
    where_content: inputs.where_content,
    where_content_two: inputs.where_content_two,
    what_title: inputs.what_title,
    what_content: inputs.what_content,
    what_content_two: inputs.what_content_two,
    content_fname_lname: inputs.content_fname_lname,
    content: inputs.content,
  };

  let formData = new FormData();
  formData.append("trial_id", id);
  formData.append("user_id", localStorage.getItem("user_id"));
  if (engagementEmailDataFromAPI.user_template_id) {
    formData.append("template_id", engagementEmailDataFromAPI.user_template_id);
  }
  formData.append(
    "admin_template_id",
    engagementEmailDataFromAPI.admin_template_id
  );
  formData.append("name", inputs.emailName);
  formData.append("template_type", engagementEmailDataFromAPI.template_type);
  formData.append("template_status", "draft");
  if (inputs.subjectLine) {
    formData.append("subject", inputs.subjectLine);
  } else {
    formData.append("subject", "");
  }

  for (const property in contentJSON) {
    formData.append(property, inputs[property]);
  }

  return formData;
};

export const createFormDataForInServiceInviteEndoscopy = ({
  engagementEmailDataFromAPI,
  inputs,
  id,
}) => {
  let contentJSON = {
    title: inputs.title,
    sub_title: inputs.sub_title,
    title_description: inputs.title_description,
    when_title: inputs.when_title,
    when_content: inputs.when_content,
    when_time: inputs.when_time,
    where_title: inputs.where_title,
    where_content: inputs.where_content,
    where_content_two: inputs.where_content_two,
    what_title: inputs.what_title,
    what_content: inputs.what_content,
    what_content_two: inputs.what_content_two,
    content_fname_lname: inputs.content_fname_lname,
    content: inputs.content,
  };

  let formData = new FormData();
  formData.append("trial_id", id);
  formData.append("user_id", localStorage.getItem("user_id"));
  if (engagementEmailDataFromAPI.user_template_id) {
    formData.append("template_id", engagementEmailDataFromAPI.user_template_id);
  }
  formData.append(
    "admin_template_id",
    engagementEmailDataFromAPI.admin_template_id
  );
  formData.append("name", inputs.emailName);
  formData.append("template_type", engagementEmailDataFromAPI.template_type);
  formData.append("template_status", "draft");
  if (inputs.subjectLine) {
    formData.append("subject", inputs.subjectLine);
  } else {
    formData.append("subject", "");
  }

  for (const property in contentJSON) {
    formData.append(property, inputs[property]);
  }

  return formData;
};

export const createFormDataForInServiceRecapCommunications = ({
  engagementEmailDataFromAPI,
  inputs,
  id,
}) => {
  let contentJSON = {
    content: inputs.content,
    facility_image: inputs.facility_image,
    facility_logo: inputs.facility_logo,
    title: inputs.title,
    sub_title: inputs.sub_title,
    recipient_name: inputs.recipient_name,
    title_description: inputs.title_description,
    inservice_attendees_number: inputs.inservice_attendees_number,
    inservice_attendees_title: inputs.inservice_attendees_title,
    inservice_attendees_subtitle: inputs.inservice_attendees_subtitle,
    inservice_attendees_line1: inputs.inservice_attendees_line1,
    inservice_attendees_line2: inputs.inservice_attendees_line2,
    inservice_attendees_line3: inputs.inservice_attendees_line3,
    inservice_attendees_line4: inputs.inservice_attendees_line4,
    inservice_attendees_is_on: inputs.inservice_attendees_is_on,
  };

  let formData = new FormData();
  formData.append("trial_id", id);
  formData.append("user_id", localStorage.getItem("user_id"));
  if (engagementEmailDataFromAPI.user_template_id) {
    formData.append("template_id", engagementEmailDataFromAPI.user_template_id);
  }
  formData.append(
    "admin_template_id",
    engagementEmailDataFromAPI.admin_template_id
  );
  formData.append("name", inputs.emailName);
  formData.append("template_type", engagementEmailDataFromAPI.template_type);
  formData.append("template_status", "draft");
  if (inputs.subjectLine) {
    formData.append("subject", inputs.subjectLine);
  } else {
    formData.append("subject", "");
  }

  for (const property in contentJSON) {
    formData.append(property, inputs[property]);
  }

  return formData;
};

export const createFormDataForPreTrialCommunicationThankYouCommunications = ({
  engagementEmailDataFromAPI,
  inputs,
  id,
}) => {
  let contentJSON = {
    account_name: inputs.account_name,
    head_line: inputs.head_line,
    content: inputs.content,
    header_two: inputs.header_two,
    video: inputs.video,
  };

  let formData = new FormData();
  formData.append("trial_id", id);
  formData.append("user_id", localStorage.getItem("user_id"));
  if (engagementEmailDataFromAPI.user_template_id) {
    formData.append("template_id", engagementEmailDataFromAPI.user_template_id);
  }
  formData.append(
    "admin_template_id",
    engagementEmailDataFromAPI.admin_template_id
  );
  formData.append("name", inputs.emailName);
  formData.append("template_type", engagementEmailDataFromAPI.template_type);
  formData.append("template_status", "draft");
  if (inputs.subjectLine) {
    formData.append("subject", inputs.subjectLine);
  } else {
    formData.append("subject", "");
  }

  for (const property in contentJSON) {
    formData.append(property, inputs[property]);
  }

  return formData;
};

export const createFormDataForInServiceRecapEndoscopy = ({
  engagementEmailDataFromAPI,
  inputs,
  id,
}) => {
  let contentJSON = {
    title: inputs.title,
    sub_title: inputs.sub_title,
    facility_image: inputs.facility_image,
    facility_logo: inputs.facility_logo,
    title_description: inputs.title_description,
    content: inputs.content,
    recipient_name: inputs.recipient_name,
    inservice_attendees_is_on: inputs.inservice_attendees_is_on,
    inservice_attendees_number: inputs.inservice_attendees_number,
    inservice_attendees_title: inputs.inservice_attendees_title,
    inservice_attendees_subtitle: inputs.inservice_attendees_subtitle,
    inservice_attendees_line1: inputs.inservice_attendees_line1,
    sterile_processing_preparation_is_on:
      inputs.sterile_processing_preparation_is_on,
    sterile_processing_preparation_title:
      inputs.sterile_processing_preparation_title,
    sterile_processing_preparation_subtitle:
      inputs.sterile_processing_preparation_subtitle,
    sterile_processing_preparation_contact_name:
      inputs.sterile_processing_preparation_contact_name,
    sterile_processing_preparation_line1:
      inputs.sterile_processing_preparation_line1,
    pharmacy_preparation_is_on: inputs.pharmacy_preparation_is_on,
    pharmacy_preparation_title: inputs.pharmacy_preparation_title,
    pharmacy_preparation_subtitle: inputs.pharmacy_preparation_subtitle,
    pharmacy_preparation_contact_name: inputs.pharmacy_preparation_contact_name,
    pharmacy_preparation_line1: inputs.pharmacy_preparation_line1,
    pharmacy_preparation_s_name1: inputs.pharmacy_preparation_s_name1,
  };

  let formData = new FormData();
  formData.append("trial_id", id);
  formData.append("user_id", localStorage.getItem("user_id"));
  if (engagementEmailDataFromAPI.user_template_id) {
    formData.append("template_id", engagementEmailDataFromAPI.user_template_id);
  }
  formData.append(
    "admin_template_id",
    engagementEmailDataFromAPI.admin_template_id
  );
  formData.append("name", inputs.emailName);
  formData.append("template_type", engagementEmailDataFromAPI.template_type);
  formData.append("template_status", "draft");
  if (inputs.subjectLine) {
    formData.append("subject", inputs.subjectLine);
  } else {
    formData.append("subject", "");
  }

  for (const property in contentJSON) {
    formData.append(property, inputs[property]);
  }

  return formData;
};

export const createFormDataForTrialRecapUpdateEndoscopy = ({
  engagementEmailDataFromAPI,
  inputs,
  id,
}) => {
  let contentJSON = {
    title: inputs.title,
    sub_title: inputs.sub_title,
    facility_image: inputs.facility_image,
    facility_logo: inputs.facility_logo,
    title_description: inputs.title_description,
    content: inputs.content,
    advanced_imaging_is_on: inputs.advanced_imaging_is_on,
    advanced_imaging: inputs.advanced_imaging,
    advanced_imaging_1: inputs.advanced_imaging_1,
    statistic_is_on: inputs.statistic_is_on,
    statistic: inputs.statistic,
    verbatim_is_on: inputs.verbatim_is_on,
    verbatim: inputs.verbatim,
    survey_responses_is_on: inputs.survey_responses_is_on,
    survey_responses: inputs.survey_responses,
    weekly_statistic_is_on: inputs.weekly_statistic_is_on,
    weekly_statistic: inputs.weekly_statistic,
    survey_responses_title: inputs.survey_responses_title,
    survey_responses_sub_title: inputs.survey_responses_sub_title,
    trial_id: id,
    user_id: localStorage.getItem("user_id"),
    admin_template_id: engagementEmailDataFromAPI.admin_template_id,
    name: inputs.emailName,
    template_type: engagementEmailDataFromAPI.template_type,
    template_status: "draft",
  };

  if (engagementEmailDataFromAPI.user_template_id) {
    contentJSON.template_id = engagementEmailDataFromAPI.user_template_id;
  }

  if (inputs.subjectLine) {
    contentJSON.subject = inputs.subjectLine;
  } else {
    contentJSON.subject = "";
  }

  return contentJSON;
};

export const createFormDataForWeeklyEngagementEndoscopy = ({
  engagementEmailDataFromAPI,
  inputs,
  id,
}) => {
  let contentJSON = {
    title: inputs.title,
    sub_title: inputs.sub_title,
    title_description: inputs.title_description,
    content: inputs.content,
    weekly_statistic_is_on: inputs.weekly_statistic_is_on,
    weekly_statistic: inputs.weekly_statistic,
    mission_statistic_is_on: inputs.mission_statistic_is_on,
    mission_title: inputs.mission_title,
    mission_description: inputs.mission_description,
    trial_id: id,
    user_id: localStorage.getItem("user_id"),
    admin_template_id: engagementEmailDataFromAPI.admin_template_id,
    name: inputs.emailName,
    template_type: engagementEmailDataFromAPI.template_type,
    template_status: "draft",
  };

  if (engagementEmailDataFromAPI.user_template_id) {
    contentJSON.template_id = engagementEmailDataFromAPI.user_template_id;
  }

  if (inputs.subjectLine) {
    contentJSON.subject = inputs.subjectLine;
  } else {
    contentJSON.subject = "";
  }

  return contentJSON;
};

export const createFormDataForTrialRecapUpdateCommunications = ({
  engagementEmailDataFromAPI,
  inputs,
  id,
}) => {
  let contentJSON = {
    title: inputs.title,
    sub_title: inputs.sub_title,
    facility_image: inputs.facility_image,
    facility_logo: inputs.facility_logo,
    title_description: inputs.title_description,
    content: inputs.content,
    statistic_is_on: inputs.statistic_is_on,
    statistic: inputs.statistic,
    verbatim_is_on: inputs.verbatim_is_on,
    verbatim: inputs.verbatim,
    weekly_statistic_is_on: inputs.weekly_statistic_is_on,
    weekly_statistic: inputs.weekly_statistic,
  };

  let formData = new FormData();
  formData.append("trial_id", id);
  formData.append("user_id", localStorage.getItem("user_id"));
  if (engagementEmailDataFromAPI.user_template_id) {
    formData.append("template_id", engagementEmailDataFromAPI.user_template_id);
  }
  formData.append(
    "admin_template_id",
    engagementEmailDataFromAPI.admin_template_id
  );
  formData.append("name", inputs.emailName);
  formData.append("template_type", engagementEmailDataFromAPI.template_type);
  formData.append("template_status", "draft");
  if (inputs.subjectLine) {
    formData.append("subject", inputs.subjectLine);
  } else {
    formData.append("subject", "");
  }

  for (const property in contentJSON) {
    formData.append(property, inputs[property]);
  }

  return formData;
};
