import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'

import { useParams } from "react-router-dom"
import { Container, Row } from "reactstrap";
import { getCategoriesTalkT } from "../Data/database";
import FileSingleTalkTrk from "../Components/FileSingleTalkTrk";
import TabAudio from "../Components/TabAudio";
import { ListGrpSpeSingle } from "../Components/ListGroup";
import ComponentLoading from "../Components/ComponentLoading/ComponentLoading";

import { getTalkTrackSingleAction } from '../Store/actions/tracksAction';

import { formatContent } from '../Utils/formatContent';
import ErrorMessage from "../Components/ErrorMessage/ErrorMessage";

const TalkTrack = props => {
    const { productId, productSubId, fileName, groupName, type } = useParams();
    window.gtag('event', groupName, {
        'event_category': 'Talk Track',
        'event_label': fileName
    });

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getTalkTrackSingleAction({ productId, productSubId, type: type }));
    }, []);


    const { specialtyFromAPI, stakeholdersFromAPI } = props;

    const [ talkTalkSingleInformation, setTalkTrackSingleInformation ] = useState({})
    useEffect(() => {
        let formatObjectToStoreInState = {};

        if (specialtyFromAPI.length && type === 'specialty')
        {
            let specialty = specialtyFromAPI.filter(specialty => specialty.id === productId)[ 0 ];
            formatObjectToStoreInState.name = specialty.name;
            let specialtyProduct = specialty.product.filter(specialtyProduct => specialtyProduct.id === productSubId)[ 0 ];
            formatObjectToStoreInState.productName = specialtyProduct.name;
        } else if (stakeholdersFromAPI.length && type === 'stakeholder')
        {
            let stakeholder = stakeholdersFromAPI.filter(stakeholder => stakeholder.id === productId)[ 0 ];
            formatObjectToStoreInState.name = stakeholder.name;
            let stakeholderProduct = stakeholder.product.filter(stakeholderProduct => stakeholderProduct.id === productSubId)[ 0 ];
            formatObjectToStoreInState.productName = stakeholderProduct.name;
        }

        setTalkTrackSingleInformation(formatObjectToStoreInState)
    }, [ specialtyFromAPI, stakeholdersFromAPI ]);

    const getGroupTrackSingle = useSelector((state) => {
        return state.tracks.groupTrackSingle;
    });

    let formatQuestions;
    if (getGroupTrackSingle && getGroupTrackSingle.probing_question?.length === 1)
    {
        formatQuestions = formatContent(getGroupTrackSingle.probing_question[ 0 ].content);
        formatQuestions.pop();
        formatQuestions = formatQuestions.map((question, id) => {
            return {
                id: id,
                question: question
            }
        });
    }

    let formatTalkingPoints;

    if (getGroupTrackSingle && getGroupTrackSingle.talking_point?.length === 1)
    {
        formatTalkingPoints = formatContent(getGroupTrackSingle.talking_point[ 0 ].content);
        formatTalkingPoints.pop();
        formatTalkingPoints = formatTalkingPoints.map((question, id) => {
            return {
                id: id,
                point: question
            }
        })
    }

    const formatData = {
        id: 0,
        questions: formatQuestions,
        points: formatTalkingPoints
    }

    const groupTrackSingleError = useSelector((state) => state.tracks.groupTrackSingleError);

    const categories = getCategoriesTalkT();
    const [ selectedCategory, setSelectedCategory ] = useState(props);
    //for in-page nav
    const selectedNameRaw = props.match.params.groupName.split('/').pop().replace('%20', '');
    const selectedName = selectedNameRaw.charAt(0).toUpperCase() + selectedNameRaw.slice(1);

    const handleCategorySelect = category => {
        setSelectedCategory(category);
    };

    const filteredTalk = [ formatData ];

    const isFetchingTalkTrackSingle = useSelector(state => state.tracks.isFetchingTalkTrackSingle);

    if (isFetchingTalkTrackSingle)
    {
        return (
            <ComponentLoading message="Loading..." />
        );
    }

    return (
        <>
            <Container className="page pb-80">
                <Row className="tab-title-main">
                    <div>
                        <h1 className="talk-track-single-title">{ talkTalkSingleInformation.name }</h1>
                        <h2 className="section-title mar-tp-neg12">{ talkTalkSingleInformation.productName }</h2>
                    </div>
                    <div className="path-nested">
                        <ListGrpSpeSingle
                            items={ categories }
                            onItemSelect={ handleCategorySelect }
                            selectedItem={ selectedCategory }
                            selectedName={ talkTalkSingleInformation.name }
                            lastURLSegment={ talkTalkSingleInformation.productName || '' }
                            history={ props.history }
                            type={ type }
                        />
                    </div>
                </Row>
                <div >
                    <FileSingleTalkTrk filteredTalk={ formatData.questions ? filteredTalk : [] } />
                    <TabAudio
                        selectedName={ selectedName }
                        lastURLSegment={ fileName }
                        groupName={ groupName }
                    />
                </div>
                { groupTrackSingleError ? (
                    <ErrorMessage message={ groupTrackSingleError } />
                ) : (null) }

            </Container>
        </>
    );
}


export default TalkTrack;
