import React, { useState, useEffect } from 'react';
import classes from '../../Pages/Trial/MultipleStepsModalsForm/InputElements.module.css';

const LevelOfInfluence = ({ handleInputChange, amountOfPrefilledCircles }) => {
    useEffect(() => {
        if (amountOfPrefilledCircles)
        {
            fillCircles(amountOfPrefilledCircles);
        }
    }, []);

    const [ chosenCircle, setChosenCircle ] = useState(false);

    const fillCirclesOnMouseOver = (chosenCircleValue) => {
        fillCircles(chosenCircleValue);
    }

    const resetCirclesColorOnMouseOut = () => {
        if (!chosenCircle)
        {
            return resetCirclesColor();
        }
        return fillCircles(chosenCircle);
    }

    const fillCirclesOnClick = (chosenCircleValue) => {
        if (chosenCircleValue)
        {
            setChosenCircle(chosenCircleValue);
            handleInputChange({
                target: {
                    name: 'rating',
                    value: chosenCircleValue
                }
            });

            fillCircles(chosenCircleValue);
        }
    }

    function fillCircles (chosenCircleValue) {
        let circles = document.querySelectorAll(`.${ classes[ 'form__influence-circle' ] }`);
        if (chosenCircleValue)
        {
            resetCirclesColor();
            circles = [ ...circles ].slice(0, chosenCircleValue);
            circles.forEach(circle => {
                circle.classList.add(classes[ 'form__influence-circle--active' ]);
            });
        }
    }

    function resetCirclesColor () {
        let circles = document.querySelectorAll(`.${ classes[ 'form__influence-circle' ] }`);

        circles.forEach(circle => {
            circle.classList.remove(classes[ 'form__influence-circle--active' ]);
        });
    }

    return (
        <div
            onClick={ (ev) => fillCirclesOnClick(ev.target.dataset.circle) }
            onMouseOver={ (ev) => fillCirclesOnMouseOver(ev.target.dataset.circle) }
            onMouseOut={ (ev) => resetCirclesColorOnMouseOut(ev.target.dataset.circle) }
            className={ classes[ "form__influence-circles-container" ] }>
            <div
                data-circle="1"
                className={ classes[ "form__influence-circle" ] }></div>
            <div
                data-circle="2"
                className={ classes[ "form__influence-circle" ] }></div>
            <div
                data-circle="3"
                className={ classes[ "form__influence-circle" ] }></div>
            <div
                data-circle="4"
                className={ classes[ "form__influence-circle" ] }></div>
            <div
                data-circle="5"
                className={ classes[ "form__influence-circle" ] }></div>
        </div>
    )
}

export default LevelOfInfluence;