import React from 'react';
import { Switch, Route } from "react-router-dom";
import NavBar from "../../../Navi/NavBar";
import Collateral from '../../../Pages/Trial/Engagements/Collateral/Collateral';
import CollateralItem from '../../../Pages/Trial/Engagements/Collateral/CollateralItem/CollateralItem';

const EngagementsRoutes = () => {
    return (
        <React.Fragment>
            <NavBar />
            <Switch>
                <Route exact path="/trial/engagement/collateral"> <Collateral /> </Route>
                <Route exact path="/trial/engagement/collateral-item"> <CollateralItem /> </Route>
            </Switch>
        </React.Fragment>
    )
}

export default EngagementsRoutes;