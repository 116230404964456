import React, { Component } from 'react';

import { connect } from 'react-redux';
import {
	getCaseAction,
	getSurgeonsForCreatingAndUpdatingACaseAction,
	downloadCasesPDFSummary,
	downloadVerbatimsPDFSummary
} from '../../../Store/actions/casesAction';
import { withRouter } from 'react-router-dom';

import CreateCaseModal from '../CreateCaseModal/CreateCaseModal';
import PeopleCases from '../../../Section/PeopleCases';
import CasesStats from './Components/CasesStats/CasesStats';
import ComponentLoading from '../../../Components/ComponentLoading/ComponentLoading';
import ErrorMessage from '../../../Components/ErrorMessage/ErrorMessage';

class TrialOrganizationCases extends Component {
	state = {
		createCaseModalIsOpen: false
	};

	toggleCreateCaseModal = () => {
		this.setState({
			createCaseModalIsOpen: !this.state.createCaseModalIsOpen
		});
	};

	componentDidMount() {
		const trialID = this.props.match.params.id;
		this.props.getCase(trialID);
	}

	render() {
		const { analytics: caseAnalytics, specialist_cases: specialistCases, case_count: caseCount, icg_count: ICGCount } = this.props.case;

		const { surgeonsForCreatingACase, isFetchingCase } = this.props;

		const { toggleCreateCaseModal } = this;
		const { createCaseModalIsOpen } = this.state;

		if (isFetchingCase) {
			return <ComponentLoading message="Loading..." isFullHeight={true} />;
		}

		return (
			<div>
				<CasesStats
					caseAnalytics={caseAnalytics}
					caseCount={caseCount}
					ICGCount={ICGCount}
					toggleCreateCaseModal={toggleCreateCaseModal}
					downloadCaseSummary={() => this.props.downloadCaseSummary(this.props.match.params.id)}
					downloadVerbatimsSummary={() => this.props.downloadVerbatimsSummary(this.props.match.params.id)}
				/>
				<PeopleCases specialistCases={specialistCases} />
				<CreateCaseModal
					createCaseModalIsOpen={createCaseModalIsOpen}
					toggleCreateCaseModal={toggleCreateCaseModal}
					accountID={this.props.accountID}
				/>

				{this.props.caseError ? <ErrorMessage message={this.props.caseError} /> : null}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		case: state.cases.case,
		caseError: state.cases.caseError,
		isFetchingCase: state.cases.isFetchingCase,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getCase: (trialID) => {
			dispatch(getCaseAction(trialID));
		},

		downloadCaseSummary: (trialID) => {
			dispatch(downloadCasesPDFSummary(trialID));
		},
		downloadVerbatimsSummary: (trialID) => {
			dispatch(downloadVerbatimsPDFSummary(trialID));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TrialOrganizationCases));
