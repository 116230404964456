export default function formatPhoneNumber (phonenumber) {
    if (phonenumber.replace('(', '').replace(')', '').replace('-', '').replace(' ', '').match(/\D/gm)) return false;

    const cleanNumbers = phonenumber.replace(/\D/gm, '');
    const MAX_AMOUT_OF_NUMBERS = 11;
    if (cleanNumbers.length === MAX_AMOUT_OF_NUMBERS) return false;

    const areaCode = cleanNumbers.substring(0, 3);
    const middleNumbers = cleanNumbers.substring(3, 6)
    const lastNumbers = cleanNumbers.substring(6, 10)

    if (cleanNumbers.length > 6)
    {
        return `(${ areaCode }) ${ middleNumbers }-${ lastNumbers }`;
    } else if (cleanNumbers.length > 3)
    {
        return `(${ areaCode }) ${ middleNumbers }`;
    }

    return `${ phonenumber }`;
}