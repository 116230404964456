import React, { useState, useEffect } from 'react';
import { Container } from 'reactstrap';
import Search from '../Utils/Search';
import Pagination from '../Components/Pagination';
import TableTalkTrkSub from '../Components/TableTalkTrkSub';
import Paginate from '../Utils/Paginate';
import { getCategoriesTalkT } from '../Data/database';
import { ListGrpStake } from '../Components/ListGroup';
import _ from 'lodash';
import List from '../Components/List/List';

import {
    resetTalkTrackSingleInReducerAction
} from '../Store/actions/tracksAction';

import NoResults from '../Components/NoResults/NoResults';
import ErrorMessage from '../Components/ErrorMessage/ErrorMessage';

import { useDispatch } from 'react-redux';

const TalkTracksSub = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetTalkTrackSingleInReducerAction());
    }, [])

    const categories = getCategoriesTalkT();
    const pageSize = 10;
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ selectedCategory, setSelectedCategory ] = useState(props);
    const [ sortColumn, setSortColumn ] = useState({ path: "name", order: "asc" });

    const handleCategorySelect = category => {
        setSelectedCategory(category);
        setCurrentPage(1);
    };

    const handleSort = path => {
        if (sortColumn.path === path)
        {
            (sortColumn.order === "asc")
                ? setSortColumn({ path, order: "desc" })
                : setSortColumn({ path, order: "asc" });
        } else
        {
            sortColumn.path = path;
            setSortColumn({ path, order: "asc" })
        }
    }

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const {
        stakeholdersFromAPI,
        stakeholdersError,
        isFetchingStakeholders
    } = props;

    let foundStakeholder = stakeholdersFromAPI.find(stakeholder => {
        return stakeholder.id === props.match.params.productId
    });

    const [ stakeholder, setStakeholder ] = useState({});

    useEffect(() => {
        if (foundStakeholder)
        {
            setStakeholder(foundStakeholder);
            setStakeholderProducts(foundStakeholder.product);
        }
    }, [ foundStakeholder ]);

    let [ stakeholderProducts, setStakeholderProducts ] = useState([]);

    const filterStakeholdersProductsBySearch = (ev) => {
        let filteredStakeholderProductsArray = foundStakeholder?.product.filter((stakeholderProduct) => {
            return stakeholderProduct.name.toLowerCase().includes(ev.currentTarget.value.toLowerCase().trim());
        });

        setStakeholderProducts(filteredStakeholderProductsArray);
    }

    const sortedStakeholderProducts = _.orderBy(stakeholderProducts, [ sortColumn.path ], [ sortColumn.order ]).map((product) => { return { ...product, type: 'stakeholder', parentId: foundStakeholder ? (foundStakeholder.id) : (null) } });
    const paginatedStakeholderProducts = Paginate(sortedStakeholderProducts, currentPage, pageSize);

    return (
        <>
            <Container className="tab-title-main">
                <h1 className="section-title">Talk Tracks</h1>
                <div className="nav-pagelink">
                    <ListGrpStake
                        items={ categories }
                        onItemSelect={ handleCategorySelect }
                        selectedItem={ selectedCategory }
                        selectedName={ stakeholder.name || '' }
                    />
                </div>
                <div className="hr-bk-expand-ml-mr-15" />
                <Search handleFilter={ filterStakeholdersProductsBySearch } />
            </Container>
            <Container>
                <List
                    list={ paginatedStakeholderProducts }
                    actions={ null }
                    sortAlphabeticallyByTitle={ handleSort }
                    sortBy="name"
                    view="talk-tracks-sub-page"
                    isLoading={ isFetchingStakeholders } />

                { stakeholdersError ? (
                    <ErrorMessage message={ stakeholdersError } />
                ) : (null) }

                { !isFetchingStakeholders && paginatedStakeholderProducts.length === 0 ? (
                    <NoResults />
                ) : (null) }

                <Pagination
                    itemsCount={ paginatedStakeholderProducts.length }
                    pageSize={ pageSize }
                    currentPage={ currentPage }
                    onPageChange={ handlePageChange }
                />
            </Container>
        </>
    );
}

export default TalkTracksSub;

