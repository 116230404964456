import * as ActionTypes from './ActionTypes';

import API from '../../API/API';

export const getPeopleAction = ({ search = '', type = 'all', page = 1, sortBy = 'first_name', orderBy = 'ASC' }) => {
	return (dispatch, getState) => {

		dispatch({ type: ActionTypes.REQUEST_GET_PEOPLE });

		API()
			.post(`/api/v7/people/getpeople`, {
				user_id: localStorage.getItem('user_id'),
				type: type,
				search: search,
				sort_by: sortBy,
				order_by: orderBy
			})
			.then(function(res) {
				if (res.data.message === 'No record found.') {
					return dispatch({
						type: ActionTypes.GET_PEOPLE_SUCCESS,
						getPeople: []
					});
				}

				return dispatch({
					type: ActionTypes.GET_PEOPLE_SUCCESS,
					getPeople: res.data.data.resultData
				});
			})
			.catch((err) => {
				return dispatch({ type: ActionTypes.GET_PEOPLE_ERROR, getPeopleError: err });
			});
	};
};
