import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect, withRouter } from 'react-router-dom';
import DashboardRoute from './DashboardRoute';
import AuthRoute from './AuthRoute';
import DefaultContainer from './DefaultContainer';
import TrialRoute from './TrialRoute';
import TrialSurveyEvalsRoute from './TrialSurveyEvalRoutes';
import CompetitiveIntelRoute from './CompetitiveIntelRoute';
import TalkTracksRoute from './TalkTracksRoute';
import ScrollToTop from '../Components/ScrollToTop';
import PendingContent from '../Pages/PendingContent';
import ProductEducationSub from '../Pages/ProductEducationSub';
import BusinessUnitSelection from '../Pages/BusinessUnitSelection/BusinessUnitSelection';
import NavBar from '../Navi/NavBar';
import NavBarFile from '../Navi/NavBarFile';
import NavBarComponent from '../Components/NavBar/NavBar';
import BurgerDots from '../Navi/BurgerDots';
import PDFViewer from '../Components/PDFViewer/PDFViewer';
import EngagementsRoutes from './Trial/Engagements/EngagementsRoutes';

import '../assets/css/app.css';

import { getBusinessUnitAction } from '../Store/actions/authActions';
import { postSSOLoginAction } from '../Store/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import PeopleRoute from './PeopleRoutes';
import CustomersRoutes from './CustomersRoutes';
import ComponentLoading from '../Components/ComponentLoading/ComponentLoading';

function App(props) {
	//if a request fails with an unathorized statement this must be removed to force user to login again
	const authenticated = localStorage.getItem('user_id');

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getBusinessUnitAction());
	}, []);

	const BU = useSelector((state) => {
		return state.auth.BU;
	});

	return (
		<BrowserRouter>
			<ScrollToTop />
			<Switch>
				<Route
					path="/auth"
					component={() => {
						const params = new URLSearchParams(props.location.search);
						const jwt = params.get('jwt');
						if (jwt) {
							dispatch(postSSOLoginAction(jwt));
							return <ComponentLoading message="Logging in with SSO" />;
						} else {
							window.location.href = `https://s2sp.simple.science/simplesaml/relay/token.php?target=sykcrush.com`;
							return null;
						}
					}}
				/>
				{!authenticated && <Redirect to="/auth" />}
				<Route exact path="/" component={DashboardRoute} />
				<Route
					exact
					path="/businessunit"
					render={() => (
						<React.Fragment>
							<NavBar />
							<BusinessUnitSelection />
						</React.Fragment>
					)}
				/>
				<Route path="/trial/:trialID/surveyevals" component={TrialSurveyEvalsRoute} />
				{/* <Route path="/trials" component={DefaultContainer} /> */}
				<Route path="/trial/:id" component={TrialRoute} />
				<Route path="/trial/engagement" component={EngagementsRoutes} />

				{/* <Route path="/competitiveintel" component={CompetitiveIntelRoute} />
				<Route path="/talktracks" component={TalkTracksRoute} />
				<Route path="/product-education/:id" component={ProductEducationSub} /> */}
				<Route path="/people" component={PeopleRoute} />
				<Route path="/customers" component={CustomersRoutes} />
				<Route path="/PendingContent">
					<PendingContent />
				</Route>
				<Route
					exact
					path="/pdf-viewer"
					render={(props) => (
						<React.Fragment>
							<NavBarComponent />
							<PDFViewer {...props} />
							{/* <NavBarFile { ...props } /> */}
							{/* <BurgerDots /> */}
						</React.Fragment>
					)}
				/>

				<Route path="/">
					<Redirect to="/" />
				</Route>
			</Switch>
		</BrowserRouter>
	);
}

export default withRouter(App);
