import React, { Component } from 'react';
import { Route } from "react-router-dom";
import Burger from "../Navi/Burger";
import Dashboard from "../Pages/Dashboard";
import { connect } from 'react-redux';
import { getUserCountersAction } from '../Store/actions/usersCountersAction';

class DashboardRoute extends Component {

    componentDidMount () {
        this.props.getUserCounters();
    }

    render () {
        return (
            <div>
                <Route exact path="/" >
                    <Burger /><Dashboard
                        path="dashboard"
                        userCounters={ this.props.userCounters }
                        userCountersError={ this.props.userCountersError } />
                </Route>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userCounters: state.userCounters.userCounters,
        userCountersError: state.userCounters.userCountersError
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserCounters: () => {
            dispatch(getUserCountersAction());
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardRoute);