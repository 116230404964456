import React from 'react';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';

const SurveyActionTabs = (props) => {
	const {
		trialSelected,
		openPreviewSurveyPopup,
		toggleSurveyAndEvaluationDuplicateFormPopUp,
		toggleSurveyAndEvaluationDeletePopUp,
		resultsEnabled,
		deleteEnabled,
		type,
		downloadBlankTemplate
	} = props;
	const location = trialSelected.replace(/ /g, '-');
	let path = window.location.pathname.endsWith('/')
		? window.location.pathname.substring(0, window.location.pathname.length - 1)
		: window.location.pathname;

	return (
		<Col>
			<div className="tab-bar">
				<i className="las la-edit square-grey" />
				<Link to={`${path}/respondents`} className="tab-bar-text">
					Fill out
					<span>
						<img src="/images/icon-arrow.jpg" className="angle2" alt="" />
					</span>
				</Link>
			</div>

			<div className="tab-bar">
				<i className="las la-eye square-white" />
				<div onClick={openPreviewSurveyPopup} className="tab-bar-text">
					Preview
					<span>
						<img src="/images/icon-arrow.jpg" className="angle2" alt="" />
					</span>
				</div>
			</div>

			<div className="tab-bar">
				<i className="las la-file-export square-white" />
				<div className="tab-bar-text" onClick={downloadBlankTemplate}>
					Export blank {type}
					<span>
						<img src="/images/icon-arrow.jpg" className="angle2" alt="" />
					</span>
				</div>
			</div>

			<div className="tab-bar">
				<i className="las la-chart-bar square-white" />
				<Link to={resultsEnabled ? location + '/result' : '#'} className="tab-bar-text">
					View Result
					<span>
						<img src="/images/icon-arrow.jpg" className="angle2" alt="" />
					</span>
				</Link>
			</div>

			<div className="tab-bar">
				<i className="las la-copy square-white" />
				<div onClick={toggleSurveyAndEvaluationDuplicateFormPopUp} className="tab-bar-text">
					Duplicate
					<span>
						<img src="/images/icon-arrow.jpg" className="angle2" alt="" />
					</span>
				</div>
			</div>
			{deleteEnabled && (
				<div className="tab-bar">
					<i className="las la-trash square-red" />
					<div onClick={toggleSurveyAndEvaluationDeletePopUp} className="tab-bar-text">
						Delete
						<span>
							<img src="/images/icon-arrow.jpg" className="angle2" alt="" />
						</span>
					</div>
				</div>
			)}
		</Col>
	);
};

export default SurveyActionTabs;
