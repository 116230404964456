import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEngagementEmailResultsAction } from '../../../../Store/actions/trials/engagementEmailsAction';
import { useParams } from 'react-router-dom';

import Stats from '../../../../Components/Stats/Stats';
import CreateRatingCircles from '../../../../Utils/CreateRatingCircles/CreateRatingCircles';
import EmailPreview from '../EmailPreview/EmailPreview';
import ErrorMessage from '../../../../Components/ErrorMessage/ErrorMessage';
import WeeklyEngagementEndoscopy from '../CustomizeEmail/Views/EmailPreview/WeeklyEngagementEndoscopy';
import TrialRecapUpdateCommunications from '../CustomizeEmail/Views/EmailPreview/TrialRecapUpdateCommunications';

import classes from './EmailResults.module.css';

const EmailResults = () => {
    const dispatch = useDispatch();
    const { id, engagementEmailID } = useParams();
    useEffect(() => {
        const formatParameters = {
            trialID: id,
            engagementEmailID
        };

        dispatch(getEngagementEmailResultsAction(formatParameters));
    }, []);

    const engagementEmailResults = useSelector((state) => {
        return state.engagementEmails.engagementEmailResults
    });

    const engagementEmailResultsError = useSelector((state) => state.engagementEmails.engagementEmailResultsError);

    const emailResults = engagementEmailResults?.email_result;
    const engagementEmailResultsOpenedStats = {
        number: emailResults?.opened.count,
        text: 'opened',
        percentage: emailResults?.opened.percentage.replace('%', ''),
        percentageBarColor: '#ffb500',
    }

    const engagementEmailResultsClickedStats = {
        number: emailResults?.clicked.count,
        text: 'clicked',
        percentage: emailResults?.clicked.percentage.replace('%', ''),
        percentageBarColor: '#b83c27',
    }

    const engagementEmailResultsBouncedStats = {
        number: emailResults?.bounced.count,
        text: 'bounced',
        percentage: emailResults?.bounced.percentage.replace('%', ''),
        percentageBarColor: '#b83c27',
    }

    const engagementEmailDetails = engagementEmailResults.template_detail;

    const engagementEmailRecipientsStakeholders = engagementEmailResults?.recipients?.stakeholder;
    const engagementEmailRecipientsSurgeons = engagementEmailResults?.recipients?.surgeons;
    const engagementEmailRecipientsAddedByEmail = engagementEmailResults?.recipients?.custom;

    let emailPreview;

    if (engagementEmailResults)
    {
        emailPreview = {
            content_json: engagementEmailResults?.template_content,
            template_type: engagementEmailResults?.template_detail.template_type
        }
    }

    const selectEmailTemplateToDisplay = () => {
        if (emailPreview?.template_type === 'weekly_engagement')
        {
            return (
                <WeeklyEngagementEndoscopy emailPreview={ emailPreview.content_json } />
            );
        } else if (emailPreview?.template_type === 'integration_trial_complete')
        {
            return (
                <TrialRecapUpdateCommunications emailPreview={ emailPreview.content_json } />
            );
        }
        else
        {
            return (
                <EmailPreview emailPreview={ emailPreview } />
            );
        }
    }

    const envelopeIcon = (item) => {
        if (item.is_sent === "1" && item.is_clicked === "1" && item.is_open === "1")
        {
            return (
                <div className={ [ classes[ "email-results-section__list-item-image-container" ], classes[ "-red" ] ].join(" ") }>
                    <img
                        src="/images/email/envelope-warning.png"
                        alt="envelope warning icon"
                        className={ classes[ "email-results-section__list-item-image" ] } />
                </div>
            );
        } else if (item.is_sent === "0")
        {
            return (
                <div className={ [ classes[ "email-results-section__list-item-image-container" ], classes[ "-gray" ] ].join(" ") }>
                    <img
                        src="/images/email/envelope-closed.png"
                        alt="envelope closed icon"
                        className={ classes[ "email-results-section__list-item-image" ] } />
                </div>
            );
        } else if (item.is_open === "0")
        {
            return (
                <div className={ [ classes[ "email-results-section__list-item-image-container" ], classes[ "-yellow" ] ].join(" ") }>
                    <img
                        src="/images/email/envelope-open.png"
                        className={ classes[ "email-results-section__list-item-image" ] } />
                    { item.is_clicked === "1" ? (
                        <div className={ classes[ "email-results-section__list-item-icon-notification" ] }></div>
                    ) : (null) }
                </div>
            );
        }
    }

    return (
        <div className={ classes[ "email-results-container" ] }>
            <div className={ classes[ "email-results__title-container" ] }>
                <h1 className={ classes[ "email-results__title" ] }>Email Results</h1>
                { engagementEmailResultsError ? (
                    <ErrorMessage message={ engagementEmailResultsError } />
                ) : (null) }

            </div>

            <div className={ classes[ "email-results-section" ] }>
                <div className={ classes[ "email-results-section__stats-header" ] }>
                    <div className={ classes[ "email-results-section__stats-header-left" ] }>
                        { emailResults?.recipients_count } <span className={ classes[ "email-results-section__header-light" ] }>recipients</span>
                    </div>
                   {/*  <div className={ classes[ "email-results-section__header-image-container" ] }>
                        <img
                            className={ classes[ "email-results-section__header-image" ] }
                            src="/images/email/upload-icon.png"
                            alt="upload icon" />
                    </div> */}
                </div>

                <div className={ classes[ "email-results-section__stats-container" ] }>
                    <div className={ classes[ "email-results-section__stat-container" ] }>
                        <Stats item={ engagementEmailResultsOpenedStats } />
                    </div>
                    <div className={ classes[ "email-results-section__stat-container" ] }>
                        <Stats item={ engagementEmailResultsClickedStats } />
                    </div>
                    <div className={ classes[ "email-results-section__stat-container" ] }>
                        <Stats item={ engagementEmailResultsBouncedStats } />
                    </div>
                </div>
            </div>

            <div className={ classes[ "email-results-section" ] }>
                <div className={ classes[ "email-results-section__header" ] }>
                    <h2 className={ classes[ "email-results-section__header-text" ] }>
                        details
                    </h2>
                </div>

                <ul className={ classes[ "email-results-section__list" ] }>
                    <li className={ classes[ "email-results-section__list-item" ] }>
                        <div className={ classes[ "email-results-section__list-item-text" ] }>
                            { engagementEmailDetails?.admin_template_name }
                        </div>
                        <div className={ classes[ "email-results-section__list-item-text-light" ] }>
                            Email template
                        </div>
                    </li>
                    <li className={ classes[ "email-results-section__list-item" ] }>
                        <div className={ classes[ "email-results-section__list-item-text" ] }>
                            { engagementEmailDetails?.user_template_name }
                        </div>
                        <div className={ classes[ "email-results-section__list-item-text-light" ] }>
                            Email name
                        </div>
                    </li>
                    <li className={ classes[ "email-results-section__list-item" ] }>
                        <div className={ classes[ "email-results-section__list-item-text" ] }>
                            { engagementEmailDetails?.subject }
                        </div>
                        <div className={ classes[ "email-results-section__list-item-text-light" ] }>
                            Subject line
                        </div>
                    </li>
                    <li className={ classes[ "email-results-section__list-item" ] }>
                        <div className={ classes[ "email-results-section__list-item-text" ] }>
                            { engagementEmailDetails?.send_date }
                        </div>
                        <div className={ classes[ "email-results-section__list-item-text-light" ] }>
                            Sent date
                        </div>
                    </li>
                </ul>
            </div>

            <div className={ classes[ "email-results-section" ] }>
                <div className={ classes[ "email-results-section__header" ] }>
                    <h2 className={ classes[ "email-results-section__header-text" ] }>
                        recipients <span className={ classes[ "email-results-section__header-text-light" ] }>- surgeons</span>
                    </h2>
                </div>

                <ul className={ classes[ "email-results-section__list" ] }>
                    { engagementEmailRecipientsSurgeons?.map(surgeon => {
                        let envelope = envelopeIcon(surgeon);

                        return (
                            <li
                                key={ surgeon.id }
                                className={ [ classes[ "email-results-section__list-item" ], classes[ "-recipients" ] ].join(" ") }>
                                <div>
                                    <div className={ classes[ "email-results-section__list-item-name" ] }>
                                        { surgeon.name }
                                    </div>
                                    <div className={ classes[ "email-results-section__list-item-circles-container" ] }>
                                        <CreateRatingCircles amountOfCircles={ surgeon.rating } classesToAdd={ classes[ "email-results-section__list-item-circle" ] } />
                                    </div>
                                    <div className={ classes[ "email-results-section__list-item-email" ] }>
                                        { surgeon.email }
                                    </div>
                                </div>

                                { /* envelope */ }
                            </li>
                        );
                    }) }
                </ul>
            </div>

            <div className={ classes[ "email-results-section" ] }>
                <div className={ classes[ "email-results-section__header" ] }>
                    <h2 className={ classes[ "email-results-section__header-text" ] }>
                        recipients <span className={ classes[ "email-results-section__header-text-light" ] }>- stakeholders</span>
                    </h2>
                </div>

                <ul className={ classes[ "email-results-section__list" ] }>
                    { engagementEmailRecipientsStakeholders?.map(stakeholder => {
                        let envelope = envelopeIcon(stakeholder);

                        return (
                            <li
                                key={ stakeholder.id }
                                className={ [ classes[ "email-results-section__list-item" ], classes[ "-recipients" ] ].join(" ") }>
                                <div>
                                    <div className={ classes[ "email-results-section__list-item-name" ] }>
                                        { stakeholder.name }
                                    </div>
                                    <div className={ classes[ "email-results-section__list-item-circles-container" ] }>
                                        <CreateRatingCircles amountOfCircles={ stakeholder.rating } classesToAdd={ classes[ "email-results-section__list-item-circle" ] } />
                                    </div>
                                    <div className={ classes[ "email-results-section__list-item-email" ] }>
                                        { stakeholder.email }
                                    </div>
                                </div>

                                { envelope }
                            </li>
                        );
                    }) }
                </ul>
            </div>

            <div className={ classes[ "email-results-section" ] }>
                <div className={ classes[ "email-results-section__header" ] }>
                    <h2 className={ classes[ "email-results-section__header-text" ] }>
                        recipients <span className={ classes[ "email-results-section__header-text-light" ] }>- added by email</span>
                    </h2>
                </div>
                <ul className={ classes[ "email-results-section__list" ] }>
                    { engagementEmailRecipientsAddedByEmail?.map(recipientAddedByEmail => {
                        let envelope = envelopeIcon(recipientAddedByEmail);
                        return (
                            <li
                                key={ recipientAddedByEmail.id }
                                className={ classes[ "email-results-section__list-item" ] }>
                                <div className={ classes[ "email-results-section__list-item-left" ] }>
                                    <div className={ classes[ "email-results-section__list-item-left-image-container" ] }>
                                        <img
                                            className={ classes[ "email-results-section__list-item-left-image" ] }
                                            src="/images/icon-plus.png"
                                            alt="plus icon" />
                                    </div>
                                    <div className={ classes[ "email-results-section__list-item-email" ] }>
                                        { recipientAddedByEmail.email }
                                    </div>
                                </div>
                                { envelope }
                            </li>
                        )
                    }) }


                </ul>
            </div>
            <div className={ classes[ "email-results-section__header" ] }>
                <h2 className={ classes[ "email-results-section__header-text" ] }>
                    email preview
                </h2>
            </div>
            <div className={ classes[ "email-results__preview-container" ] }>
                <div className="preview-email-container customize-email__preview-wrapper">
                    { selectEmailTemplateToDisplay() }
                </div>
            </div>
        </div>
    );
}

export default EmailResults;