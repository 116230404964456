const handleToggleChange = ({ event, handleInputChange }) => {
    let convertBooleanToNumberString = "0";

    if (event.target.checked) convertBooleanToNumberString = "1";
    handleInputChange({
        target: {
            name: event.target.name,
            value: convertBooleanToNumberString
        }
    });
}

export default handleToggleChange;