import React, { useState, useEffect } from 'react';

import CancelButton from '../../../../../Components/FormButtons/CancelButton';
import SubmitButton from '../../../../../Components/FormButtons/SubmitButton';
import ComponentLoading from '../../../../../Components/ComponentLoading/ComponentLoading';

import classes from './Forms.module.css';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getTrialStatisticsAction } from '../../../../../Store/actions/trialsAction';

const AddTrialStatistic = ({ toggleAddATrialStatisticModal, handleInputChange, inputs, changingStatistic, setChangingStatistic }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const isFetchingTrialStatistics = useSelector(state => state.trials.isFetchingTrialStatistics);
    const trialStatistics = useSelector(state => state.trials.trialStatistics);
    const trialStatisticsError = useSelector(state => state.trials.trialStatisticsError);

    useEffect(() => {
        dispatch(getTrialStatisticsAction(id));
    }, []);

    const [ chosenTrialStatistic, setChosenTrialStatistic ] = useState(false);

    const handleChooseStatisic = (trialStatistic) => {
        setChosenTrialStatistic(trialStatistic);
    }

    const inputStatistic = inputs.statistic === "" ? [] : inputs.statistic;
    const handleAddTrialStatistics = (event) => {
        event.preventDefault();

        if (chosenTrialStatistic === false) return;

        let cloneTrialStatistics = JSON.parse(JSON.stringify(inputStatistic));

        if (changingStatistic)
        {
            cloneTrialStatistics = inputStatistic.filter(trialStatisticStoredInState => trialStatisticStoredInState.slug !== changingStatistic.slug);
        }

        if (chosenTrialStatistic.slug === 'custom')
        {
            if (customTrialStatistic.count === '' || customTrialStatistic.description === '' || customTrialStatistic.label === '') return alert('All fields are required.');

            cloneTrialStatistics.push(customTrialStatistic);
        } else
        {
            cloneTrialStatistics.push(chosenTrialStatistic);
        }


        handleInputChange({
            target: {
                name: 'statistic',
                value: cloneTrialStatistics
            }
        });

        setChangingStatistic(false);

        setChosenTrialStatistic(false);

        toggleAddATrialStatisticModal();
    }

    const [ customTrialStatistic, setCustomTrialStatistic ] = useState({
        count: '',
        description: '',
        label: '',
        slug: 'custom',
    });

    const handleCustomTrialStatisticInputChange = (event) => {
        event.persist();
        setCustomTrialStatistic(previousCustomTrialStatistic => {
            return {
                ...previousCustomTrialStatistic,
                [ event.target.name ]: event.target.value
            }
        });
    }

    if (isFetchingTrialStatistics)
    {
        return (
            <ComponentLoading />
        );
    }

    return (
        <form className={ classes[ "form" ] }>
            <h2 className={ classes[ "form__title" ] }>
                Select a statistic
            </h2>
            { trialStatistics.map(statistic => {
                let isChosen = inputStatistic.findIndex(statisticStoredInState => statisticStoredInState.slug === statistic.slug);

                let isChangingStatistic = statistic.slug === changingStatistic.slug;

                return (
                    <div
                        key={ statistic.name }
                        className={ classes[ "form__group" ] }>
                        <input
                            disabled={ isChangingStatistic ? false : isChosen !== -1 }
                            defaultChecked={ isChangingStatistic }
                            onClick={ () => handleChooseStatisic(statistic) }
                            type="radio"
                            name="trialStatistic"
                            id={ statistic.name }
                            value={ statistic.name }
                            className="hidden-radio-input" />

                        <label className="input-checkmark-circle" htmlFor={ statistic.name }>
                            <i className="las la-check" />
                        </label>

                        <label
                            className={ classes[ "form__label" ] }
                            htmlFor={ statistic.name }>
                            { statistic.name }
                        </label>
                    </div>
                )
            }) }

            { chosenTrialStatistic?.slug === "custom" ? (
                <div className={ classes[ "add-trial-statistic__custom-form-group" ] }>
                    <label className={ classes[ "add-trial-statistic__label" ] }>
                        <input
                            onChange={ handleCustomTrialStatisticInputChange }
                            className={ classes[ "add-trial-statistic__input" ] }
                            type="text"
                            name="count"
                            placeholder="#"
                            maxLength="3" />
                    </label>

                    <label className={ classes[ "add-trial-statistic__label" ] }>
                        <input
                            onChange={ handleCustomTrialStatisticInputChange }
                            className={ classes[ "add-trial-statistic__input" ] }
                            type="text"
                            name="description"
                            placeholder="customize"
                            maxLength="9" />
                    </label>

                    <label className={ classes[ "add-trial-statistic__label" ] }>
                        <input
                            onChange={ handleCustomTrialStatisticInputChange }
                            className={ classes[ "add-trial-statistic__input" ] }
                            type="text"
                            name="label"
                            placeholder="your text"
                            maxLength="9" />
                    </label>
                </div>
            ) : (null) }

            <div className={ classes[ "form__buttons-container" ] }>
                <CancelButton handleClick={ toggleAddATrialStatisticModal } />

                <SubmitButton handleClick={ handleAddTrialStatistics } />
            </div>
        </form>
    )
}

export default AddTrialStatistic;