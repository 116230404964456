import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/fonts/EgyptienneURWLig/EgyptienneURWLig.ttf';
import './assets/fonts/FuturaStd/FuturaStd-Bold.ttf';
import './assets/fonts/FuturaStd/FuturaStd-Book.ttf';
import * as serviceWorker from './serviceWorker';

// react redux
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';

import swConfig from './swConfig';
import thunk from 'redux-thunk';
import rootReducer from './Store/reducers/rootReducer';

import { pdfjs } from 'react-pdf';
const pdfjsWorker = import('pdfjs-dist/build/pdf.worker.entry');
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const store = createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register(swConfig);
