import React, { useState, useEffect } from 'react';
import { Modal } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getTrialsAvailableCustomers } from '../../../../../Store/actions/trialsAction';
import listClasses from '../../../../../Components/List/List.module.css';
import Search from '../../Search/Search';

import classes from '../FormElements.module.css';

import AddCustomer from '../../../MultipleStepsModalsForm/AddCustomer/AddCustomer';
import SelectPersonList from '../../../../../Components/SelectPersonList/SelectPersonList';
import useHandleSubmit from '../../../../../Hooks/useHandleSubmit';
import ComponentLoading from '../../../../../Components/ComponentLoading/ComponentLoading';
import NoResults from '../../../../../Components/NoResults/NoResults';
import CloseModalButton from '../../../../../Components/CloseModalButton/CloseModalButton';

const SelectCustomer = ({
	submittedCustomer,
	getCustomers,
	customers,
	submitCustomerSelection,
	isFetchingGetTrialsAvailableCustomer
}) => {
	const [ selectedCustomer, setSelectedCustomer ] = useState({});
	const [ customersFetched, setCustomersFetched ] = useState(false);

	const [ isModalOpen, setIsModalOpen ] = useState(false);

	const toggleModal = () => setIsModalOpen((previousIsModalOpen) => !previousIsModalOpen);

	const openModalInForm = (ev) => {
		ev.preventDefault();

		toggleModal();
	};

	useEffect(
		() => {
			getCustomers();
			if (submittedCustomer) setSelectedCustomer(submittedCustomer);
			setCustomersFetched(true);
		},
		[ customersFetched ]
	);

	const passBackSelectedCustomer = () => {
		submitCustomerSelection(selectedCustomer);
	};

	const { handleSubmit } = useHandleSubmit({
		callback: passBackSelectedCustomer
	});

	const [ searchValue, setSearchValue ] = useState('');

	const filterCustomersBySearch = () => {
		return customers.filter((customer) => {
			return customer.name.toLowerCase().includes(searchValue.toLowerCase().trim());
		});
	};

	const handleClearButtonOnClick = () => {
		setSearchValue('');
	};

	return (
		<form className={classes['form']} onSubmit={handleSubmit}>
			<div className={classes['form__header-row']}>
				<h2 className={classes['form__title']}>Select customer</h2>
				<button className={classes['form__button']}>
					next <i className="las la-angle-right" />
				</button>
			</div>
			<div className={classes['form__header-row']}>
				<Search
					searchValue={searchValue}
					onChange={setSearchValue}
					handleClearButtonOnClick={handleClearButtonOnClick}
				/>
				<button onClick={openModalInForm} className={classes['form__image-container']}>
					<img className={classes['form__image']} src="/images/icon-plus.png" alt="plus symbol icon" />
				</button>
			</div>

			{isFetchingGetTrialsAvailableCustomer ? (
				<ComponentLoading message="Loading..." />
			) : (
				<SelectPersonList
					people={filterCustomersBySearch()}
					selectedPeople={selectedCustomer}
					personType="customer"
					callback={(person) => setSelectedCustomer(person)}
				/>
			)}

			{filterCustomersBySearch().length === 0 && !isFetchingGetTrialsAvailableCustomer ? (
				<NoResults />
			) : null}

			<Modal
				isOpen={ isModalOpen }
				toggle={ toggleModal }
				centered
				backdrop="static">
				<CloseModalButton onClick={ toggleModal } />

				<AddCustomer callback={toggleModal} isInModal={true} />
			</Modal>
		</form>
	);
};

const mapStateToProps = (state) => {
	return {
		customers: state.trials.availableCustomers,
		isFetchingGetTrialsAvailableCustomer: state.trials.isFetchingGetTrialsAvailableCustomer
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getCustomers: () => {
			dispatch(getTrialsAvailableCustomers());
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SelectCustomer));
