import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

import classes from './SurgeonInformation.module.css';

import { connect } from 'react-redux';
import { getSurgeonAction } from '../../../Store/actions/casesAction';

import ProfileImage from '../../../Utils/ProfileImage';
import CreateRatingCircles from '../../../Utils/CreateRatingCircles/CreateRatingCircles';
import ComponentLoading from '../../../Components/ComponentLoading/ComponentLoading';
import ErrorMessage from '../../../Components/ErrorMessage/ErrorMessage';

class SurgeonInformation extends Component {
	componentDidMount() {
		const { id, surgeonID } = this.props.match.params;
		this.props.getSurgeon({ trialID: id, surgeonID: surgeonID });
	}

	render() {
		if (this.props.isFetchingSurgeon) {
			return (
				<ComponentLoading 
					message="Loading..."
					isFullHeight={ true } />
			)
		}

		const {
			surgeon_data: surgeonData,
			current_case_count: trialCasesCount,
			total_case_count: totalCasesCount,
			cases
		} = this.props.surgeon;

		const { id, surgeonID } = this.props.match.params;

		const surgeonHeader = surgeonData ? (
			<React.Fragment>
				<div className={classes['surgeon-information__header']}>
					<ProfileImage
						profile={{ profileImage: surgeonData.profile_image, id: surgeonData.id }}
						cb={null}
						profileImageClasses={`profile__image--medium ${classes['profile-image__font']}`}
					/>
					<div className={classes['surgeon-information__header-right-container']}>
						<p className={classes['surgeon-information__header-name']}>
							{surgeonData.first_name} {surgeonData.last_name}
						</p>

						<div className="circles">
							<CreateRatingCircles amountOfCircles={surgeonData.rating} />
						</div>

						<p className={classes['surgeon-information__header-speciality']}>
							{surgeonData.speciality_name}
						</p>
					</div>
				</div>

				<div className={classes['surgeon-information__cases-count']}>
					<div className={classes['surgeon-information__cases-count-item']}>
						<p className={classes['surgeon-information__cases-count-number']}>{trialCasesCount}</p>
						<p className={classes['surgeon-information__cases-count-label']}>CASE IN THIS TRIAL</p>
					</div>
					<div className={classes['surgeon-information__cases-count-item']}>
						<p className={classes['surgeon-information__cases-count-number']}>{totalCasesCount}</p>
						<p className={classes['surgeon-information__cases-count-label']}>CASES IN ALL TRIALS</p>
					</div>

					<div className={classes['view-profile-button-container']}>
						<Link
							to={`/trial/${id}/organization/people/surgeons/history/${surgeonID}/stryker`}
							className={classes['view-profile-button']}
						>
							<i
								className={[ classes['view-profile-button-icon'], 'las la-user-circle' ].join(' ')}
							/>{' '}
							view profile
						</Link>
					</div>
				</div>
			</React.Fragment>
		) : null;

		return (
			<div className={classes['surgeon-information']}>
				{surgeonHeader}

				<div className={classes['surgeon-information__case-header']}>
					<h2 className={classes['surgeon-information__case-header-title']}>
						cases{' '}
						<span className={classes['surgeon-information__case-header-title--color']}>
							- in this trial
						</span>
					</h2>
				</div>

				<div className={classes['surgeon-information__case-container']}>
					{cases ? (
						cases.map((caseInformation) => {
							return (
								<Link
									to={`${this.props.match.url}/edit/${caseInformation.id}`}
									className={classes['surgeon-information-case']}
									key={caseInformation.id}
								>
									<div className={classes['surgeon-information-case__item-left']}>
										<div className={classes['surgeon-information-case__procedure']}>
											{caseInformation.case_procedure}
										</div>
										{/* <div className={classes['surgeon-information-case__equipment']}>
											Equipment used: {caseInformation.equipment}
										</div> */}
										<div className={classes['surgeon-information-case__date']}>
											procedure date: {caseInformation.case_date}
										</div>
										<div className={classes['surgeon-information-case__username']}>
											submitted by: {caseInformation.user_name}
										</div>
									</div>
									<div className={classes['surgeon-information-case__item-right']}>
										<i className="las la-angle-right" />
									</div>
								</Link>
							);
						})
					) : null}
				</div>

				{ this.props.surgeonError ? (
					<ErrorMessage message={ this.props.surgeonError } />
				) : (null) }
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		isFetchingSurgeon: state.cases.isFetchingSurgeon,
		surgeon: state.cases.surgeon,
		surgeonError: state.cases.surgeonError,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getSurgeon: (parameters) => {
			dispatch(getSurgeonAction(parameters));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SurgeonInformation));
