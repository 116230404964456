import React, { Component } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import classes from './PDFViewer.module.css'
import { withRouter } from 'react-router-dom';


class PDFViewer extends Component {
    state = {
        numPages: null
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages: numPages })
    }

    render () {
        const params = new URLSearchParams(this.props.location.search);
        const file = params.get('file');

        const { numPages } = this.state;

        const pages = [];
        for (let p = 0; p < numPages; p++)
        {
            pages.push(
                <div key={ `page_${ p }` }
                    className={ classes[ "PDF-viewer__page-container" ] }>
                    <Page
                        className={ classes[ "PDF-viewer__page" ] }
                        pageNumber={ p + 1 } />
                </div>
            );
        }

        return (
            <div className={ classes[ "PDF-viewer" ] }>
                <Document
                    file={ file }
                    onLoadSuccess={ this.onDocumentLoadSuccess }
                    onLoadError={ (error) => alert('Error while loading document! ' + error.message) }
                >
                    { pages.map((page) => page) }
                </Document>
            </div>
        );
    }


}

export default withRouter(PDFViewer);