import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import LogoPreview from '../../Shared/LogoPreview/LogoPreview';
import Footer from '../../Shared/Footer/Footer';
import AddDashedButton from '../../../../../Components/AddDashedButton/AddDashedButton';
import AddTrialStatistic from '../Forms/AddTrialStatistic';
import AddAVerbatim from '../Forms/AddAVerbatim';
import AddASurveyOrEvaluationStatistic from '../Forms/AddASurveyOrEvaluationStatistic';
import DisplayChart from '../../../../../Components/DisplayChart/DisplayChart';
import DisplayBarChart from '../../../../../Components/DisplayBarChart/DisplayBarChart';
import TrialStatistic from '../../../../../Components/TrialStatistic/TrialStatistic';
import EngagementEmailVerbatim from '../../../../../Components/EngagementEmailVerbatim/EngagementEmailVerbatim';
import ReactEasyCrop from '../../../../../Components/ReactEasyCrop/ReactEasyCrop';
import CloseModalButton from '../../../../../Components/CloseModalButton/CloseModalButton';
import { Modal } from 'reactstrap';

import emailPreviewClasses from '../../EmailPreview/EmailPreview.module.css';
import classes from '../../EmailPreview/TrialRecapUpdate/TrialRecapUpdate.module.css';
import customizeEmailClasses from '../CustomizeEmail.module.css';

import { postTemplateImagesToEngagementEmailAction } from '../../../../../Store/actions/trials/engagementEmailsAction';
import { useDispatch, useSelector } from 'react-redux';

import Toggle from 'react-toggle';

import handleToggleChange from './handleToggleChange';
import resetInputFileValue from '../../../../../Utils/resetInputFileValue';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

const SortableItem = SortableElement((
    {
        weeklyStatistic,
        handleRemoveSurveyOrEvaluationStatistic,
        handleCurrentlyChangingSurveyOrEvaluationStatistic
    }) => {
    return weeklyStatistic.questions_type === "rating" ? (
        <DisplayBarChart
            key={ weeklyStatistic.id }
            removeFunction={ event => handleRemoveSurveyOrEvaluationStatistic(event, weeklyStatistic) }
            swapFunction={ event => handleCurrentlyChangingSurveyOrEvaluationStatistic(event, weeklyStatistic) }
            chartStatistics={ weeklyStatistic } />
    ) : (
            <DisplayChart
                key={ weeklyStatistic.id }
                removeFunction={ event => handleRemoveSurveyOrEvaluationStatistic(event, weeklyStatistic) }
                swapFunction={ event => handleCurrentlyChangingSurveyOrEvaluationStatistic(event, weeklyStatistic) }
                chartStatistics={ weeklyStatistic } />
        );
});

const SortableList = SortableContainer((
    {
        weekly_statistic,
        handleRemoveSurveyOrEvaluationStatistic,
        handleCurrentlyChangingSurveyOrEvaluationStatistic
    }) => {
    return (
        <div>
            {
                weekly_statistic?.map((weeklyStatistic, index) => {
                    return (
                        <SortableItem
                            key={ `item-${ weeklyStatistic.id }` }
                            index={ index }
                            weeklyStatistic={ weeklyStatistic }
                            handleRemoveSurveyOrEvaluationStatistic={ handleRemoveSurveyOrEvaluationStatistic }
                            handleCurrentlyChangingSurveyOrEvaluationStatistic={ handleCurrentlyChangingSurveyOrEvaluationStatistic } />
                    );
                })
            }
        </div>
    );
});

const TrialRecapUpdateEndoscopy = ({ inputs, handleInputChange, engagementEmailDataFromAPI, formValidationErrors }) => {
    const dispatch = useDispatch();
    const { id, engagementEmailID } = useParams();


    const [ isImageEditingOpen, setIsImageEditingOpen ] = useState(false);
    const toggleIsImageEditingOpen = () => setIsImageEditingOpen(previousIsImageEditingOpen => !previousIsImageEditingOpen);

    const [ uploadedImage, setUploadedImage ] = useState(null);

    const handleImageEditing = (event) => {
        let imageFile = event.target.files[ 0 ];
        setIsImageEditingOpen(true);
        let imageDimensions = {
            height: 136,
            width: 233
        };

        if (event.target.name === 'facility_logo')
        {
            imageDimensions = {
                height: 92,
                width: 460
            }
        }

        setUploadedImage({
            name: event.target.name,
            dimensions: imageDimensions,
            file: URL.createObjectURL(imageFile)
        });
    }

    const handleImageUpload = (imageObject) => {
        let formData = new FormData();
        formData.append('template_id', engagementEmailID);
        formData.append('trial_id', id);
        formData.append('user_id', localStorage.getItem('user_id'));

        if (imageObject.name === 'facility_image')
        {
            formData.append('facility_image', imageObject.file);
            formData.append('facility_logo', inputs.facility_logo);
        } else if (imageObject.name === 'facility_logo')
        {
            formData.append('facility_image', inputs.facility_image);
            formData.append('facility_logo', imageObject.file);
        }

        dispatch(postTemplateImagesToEngagementEmailAction(formData));
    }

    const BASE_URL = process.env.REACT_APP_API_URL;
    let facilityImage = BASE_URL + inputs.facility_image;
    let facilityLogo = BASE_URL + inputs.facility_logo;

    if (inputs.facility_image === "")
    {
        facilityImage = '/images/email/facility-image-placeholder.png';
    }

    if (inputs.facility_logo === "")
    {
        facilityLogo = '/images/email/facility-logo-placeholder.png';
    }

    const postTemplateImagesEngagementEmailError = useSelector(state => state.engagementEmails.postTemplateImagesEngagementEmailError);


    const [ isAddATrialStatisticModalOpen, setIsAddATrialStatisticModalOpen ] = useState(false);
    const toggleAddATrialStatisticModal = (event) => {
        if (event) event.preventDefault();

        return setIsAddATrialStatisticModalOpen(previousIsAddATrialStatisticModalOpen => !previousIsAddATrialStatisticModalOpen);
    }

    const handleRemoveTrialStatistic = (event, statistic) => {
        event.preventDefault();
        let updatedTrialStatistics = inputs.statistic.filter(trialStatisticStoredInState => trialStatisticStoredInState.name !== statistic.name);

        handleInputChange({
            target: {
                name: 'statistic',
                value: updatedTrialStatistics
            }
        });
    }

    let [ changingStatistic, setChangingStatistic ] = useState(false);
    const handleChangeTrialStatistic = (event, statistic) => {
        event.preventDefault();
        setChangingStatistic(statistic);
        toggleAddATrialStatisticModal();
    }

    const [ isAddAVerbatimModalOpen, setIsAddAVerbatimModalOpen ] = useState(false);
    const toggleAddAVerbatimModal = (event) => {
        if (event) event.preventDefault();
        setIsAddAVerbatimModalOpen(previousIsAddAVerbatimModalOpen => !previousIsAddAVerbatimModalOpen)
    };

    let [ currentlyChangingVerbatimComment, setCurrentlyChangingVerbatimComment ] = useState(false);
    const handleCurrentlyChangingVerbatimComment = (event, verbatimComment) => {
        event.preventDefault();
        setCurrentlyChangingVerbatimComment(verbatimComment);
        toggleAddAVerbatimModal();
    }

    const handleRemoveVerbatimComment = (event, verbatim) => {
        event.preventDefault();
        let updatedVerbatim = inputs.verbatim.filter(verbatimCommentStoredInState => verbatimCommentStoredInState.comment_id !== verbatim.comment_id);

        handleInputChange({
            target: {
                name: 'verbatim',
                value: updatedVerbatim
            }
        });
    }

    const [ isAddASurveyOrEvaluationStatisticModalOpen, setIsAddASurveyOrEvaluationStatisticModalOpen ] = useState(false);
    const toggleAddASurveyOrEvaluationStatisticModalOpen = (event) => {
        if (event) event.preventDefault();
        setIsAddASurveyOrEvaluationStatisticModalOpen(previousIsAddASurveyOrEvaluationStatisticModalOpen => !previousIsAddASurveyOrEvaluationStatisticModalOpen)
    };

    const handleRemoveSurveyOrEvaluationStatistic = (event, statistic) => {
        event.preventDefault();
        let updatedWeekyStatistic = inputs.weekly_statistic.filter(statisticStoredInState => statisticStoredInState.id !== statistic.id);

        handleInputChange({
            target: {
                name: 'weekly_statistic',
                value: updatedWeekyStatistic
            }
        });
    }

    let [ currentlyChangingSurveyOrEvaluationStatistic, setCurrentlyChangingSurveyOrEvaluationStatistic ] = useState(false);
    const handleCurrentlyChangingSurveyOrEvaluationStatistic = (event, surveyOrEvaluationStatistic) => {
        event.preventDefault();

        setCurrentlyChangingSurveyOrEvaluationStatistic(surveyOrEvaluationStatistic);
        toggleAddASurveyOrEvaluationStatisticModalOpen();
    }

    const onSortEnd = ({ oldIndex, newIndex }) => {
        handleInputChange({
            target: {
                name: 'weekly_statistic',
                value: arrayMove(inputs.weekly_statistic, oldIndex, newIndex)
            }
        });
    }

    const shouldCancelStart = (event) => {
        if (event.target.id === 'icon-swap' || event.target.id === 'icon-delete')
        {
            return true;
        }
    }

    return (
        <div className="customize-email__preview-wrapper">
            <LogoPreview />
            <div className={ classes[ "trial-recap__header" ] }>
                <div className={ classes[ "trial-recap__header-item" ] }>

                    <div className={ classes[ "trial-recap__header-heading" ] }>
                        <label htmlFor="title" className="hidden">
                            title
                        </label>

                        <textarea
                            onChange={ handleInputChange }
                            value={ inputs.title }
                            name="title"
                            id="title"
                            className={ customizeEmailClasses[ "email-preview__textarea" ] }></textarea>

                        { formValidationErrors?.title ? (
                            <span className={ customizeEmailClasses[ "input__error" ] }>{ formValidationErrors?.title }</span>
                        ) : (null) }
                    </div>
                    <div className={ classes[ "trial-recap__header-heading-subtext" ] }>
                        <label htmlFor="sub_title" className="hidden">
                            sub title
                        </label>

                        <textarea
                            onChange={ handleInputChange }
                            value={ inputs.sub_title }
                            className={ customizeEmailClasses[ "email-preview__textarea" ] }
                            name="sub_title"
                            id="sub_title"></textarea>

                        { formValidationErrors?.sub_title ? (
                            <span className={ customizeEmailClasses[ "input__error" ] }>{ formValidationErrors?.sub_title }</span>
                        ) : (null) }
                    </div>

                    <img className={ classes[ "trial-recap__header-image" ] } src="/images/email/customize-email-bg-1.jpg" alt="rectangles" />
                </div>

                <div className={ classes[ "trial-recap__header-item" ] }>
                    <div className={ classes[ "trial-recap__header-image-container" ] }>
                        <label htmlFor="facility_image">
                            <img
                                className={ [ classes[ "trial-recap__header-image" ], "cursor" ].join(" ") }
                                src={ facilityImage }
                                alt="facility image" />
                        </label>

                        <input
                            onChange={ handleImageEditing }
                            onClick={ resetInputFileValue }
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg"
                            name="facility_image"
                            id="facility_image" />
                    </div>
                    <div className={ classes[ "trial-recap__header-image-container" ] }>
                        <label htmlFor="facility_logo">
                            <img
                                className={ [ classes[ "trial-recap__header-image" ], "cursor" ].join(" ") }
                                src={ facilityLogo }
                                alt="facility logo" />
                        </label>
                        <input
                            onChange={ handleImageEditing }
                            onClick={ resetInputFileValue }
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg"
                            name="facility_logo"
                            id="facility_logo" />
                    </div>
                </div>
            </div>

            <div className={ classes[ "trial-recap__quote" ] }>
                <label htmlFor="title_description" className="hidden">
                    title description
                </label>

                <textarea
                    onChange={ handleInputChange }
                    value={ inputs.title_description }
                    className={ customizeEmailClasses[ "email-preview__textarea" ] }
                    name="title_description"
                    id="title_description"></textarea>

                { formValidationErrors?.title_description ? (
                    <span className={ customizeEmailClasses[ "input__error" ] }>{ formValidationErrors?.title_description }</span>
                ) : (null) }
            </div>

            <div className="customize-email__content-textarea-container">
                <label htmlFor="content" className="hidden">
                    content
                </label>

                <textarea
                    onChange={ handleInputChange }
                    value={ inputs.content }
                    name="content"
                    id="content"
                    cols="75"
                    rows="15"
                    className="customize-email__content-textarea"></textarea>

                { formValidationErrors?.content ? (
                    <span className={ customizeEmailClasses[ "input__error" ] }>{ formValidationErrors?.content }</span>
                ) : (null) }
            </div>

            <div className={ classes[ "trial-recap__gallery-section" ] }>
                <div className={ classes[ "trial-recap__gallery-section-header" ] }>
                    <h2 className={ classes[ "trial-recap__gallery-section-heading" ] }>Advanced Imaging Modalities</h2>
                    <div className={ emailPreviewClasses[ "ribbon" ] }></div>
                </div>

                <div className={ classes[ "trial-recap__gallery-container" ] }>
                    <div className={ classes[ "trial-recap__gallery-wrapper" ] }>
                        { inputs?.advanced_imaging?.map((item, index) => {
                            return (
                                <div
                                    key={ index }
                                    className={ classes[ "trial-recap__gallery-item" ] }>
                                    <img
                                        className={ classes[ "trial-recap-gallery__image" ] }
                                        src={ `${ BASE_URL }/${ item.url }` } alt={ item.name } />
                                    <div
                                        className={ classes[ "trial-recap-gallery__text" ] }>
                                        { item.name }
                                    </div>
                                </div>
                            )
                        }) }
                        { inputs?.advanced_imaging_1?.map((item, index) => {
                            return (
                                <div
                                    key={ index }
                                    className={ classes[ "trial-recap__gallery-item" ] }>
                                    <img
                                        className={ classes[ "trial-recap-gallery__image" ] }
                                        src={ `${ BASE_URL }/${ item.url }` } alt={ item.name } />
                                    <div
                                        className={ classes[ "trial-recap-gallery__text" ] }>
                                        { item.name }
                                    </div>
                                </div>
                            )
                        }) }
                    </div>

                    <div className="text-center">
                        <Toggle
                            onChange={ event => handleToggleChange({ event, handleInputChange }) }
                            checked={ inputs.advanced_imaging_is_on === "1" }
                            className={ [ customizeEmailClasses[ "toggle" ], "toggle-yellow" ].join(" ") }
                            icons={ true }
                            name="advanced_imaging_is_on"
                            id="advanced_imaging_is_on" />
                    </div>
                </div>
                { inputs.advanced_imaging_is_on === "0" ? (
                    <div className={ customizeEmailClasses[ "overlay" ] } ></div>
                ) : (null) }
            </div>

            <div className={ customizeEmailClasses[ "customize-email__add-a-statistic-container" ] }>
                <div className={ [ "trial-statistics-container", inputs?.statistic?.length === 0 ? "justify-content-center" : "" ].join(" ") }>
                    { inputs?.statistic?.map(statistic => {
                        return <TrialStatistic
                            key={ statistic.slug }
                            trialStatistic={ statistic }
                            handleRemoveTrialStatistic={ event => handleRemoveTrialStatistic(event, statistic) }
                            handleChangeTrialStatistic={ event => handleChangeTrialStatistic(event, statistic) } />
                    }) }

                    { inputs?.statistic?.length < 2 ? (
                        <button
                            onClick={ toggleAddATrialStatisticModal }
                            className={ customizeEmailClasses[ "customize-email__add-a-statistic-button" ] }>
                            <img
                                className={ customizeEmailClasses[ "customize-email__add-a-statistic-button-icon" ] }
                                src="/images/icon-plus.png"
                                alt="plus symbol" />
                            <span className={ customizeEmailClasses[ "customize-email__add-a-statistic-button-text" ] }>Add a statistic</span>
                        </button>
                    ) : (null) }
                </div>

                { inputs.statistic_is_on === "0" ? (
                    <div className={ customizeEmailClasses[ "overlay" ] } ></div>
                ) : (null) }

                <div className="text-center">
                    <Toggle
                        onChange={ event => handleToggleChange({ event, handleInputChange }) }
                        checked={ inputs.statistic_is_on === "1" }
                        className={ [ customizeEmailClasses[ "toggle" ], "toggle-yellow" ].join(" ") }
                        icons={ true }
                        name="statistic_is_on"
                        id="statistic_is_on" />
                </div>
            </div>

            <div className={ customizeEmailClasses[ "customize-email__add-dashed-button-container" ] }>
                <div className="engagement-email-verbatim-container">
                    { inputs?.verbatim?.map(verbatimComment => {
                        return (
                            <EngagementEmailVerbatim
                                key={ verbatimComment.comment_id }
                                verbatimComment={ verbatimComment }
                                handleRemoveVerbatimComment={ event => handleRemoveVerbatimComment(event, verbatimComment) }
                                handleCurrentlyChangingVerbatimComment={ event => handleCurrentlyChangingVerbatimComment(event, verbatimComment) } />
                        )
                    }) }
                </div>
                <AddDashedButton handleClick={ toggleAddAVerbatimModal } text="Add a verbatim" />

                <div className="text-center">
                    <Toggle
                        onChange={ event => handleToggleChange({ event, handleInputChange }) }
                        checked={ inputs.verbatim_is_on === "1" }
                        className={ [ customizeEmailClasses[ "toggle" ], "toggle-yellow" ].join(" ") }
                        icons={ true }
                        name="verbatim_is_on"
                        id="verbatim_is_on" />
                </div>

                { inputs.verbatim_is_on === "0" ? (
                    <div className={ customizeEmailClasses[ "overlay" ] } ></div>
                ) : (null) }
            </div>

            <div className={ classes[ "trial-recap__responses" ] }>
                <h2 className={ classes[ "trial-recap__responses-title" ] }>
                    { inputs.survey_responses_title } <br /> <span className={ classes[ "trial-recap__responses-subtext" ] }>{ inputs.survey_responses_sub_title }</span>
                </h2>
                <div className={ emailPreviewClasses[ "ribbon" ] }></div>

                <div className={ customizeEmailClasses[ "customize-email__add-dashed-button-container--padding" ] }>
                    <SortableList
                        shouldCancelStart={ shouldCancelStart }
                        onSortEnd={ onSortEnd }
                        weekly_statistic={ inputs?.weekly_statistic }
                        handleRemoveSurveyOrEvaluationStatistic={ handleRemoveSurveyOrEvaluationStatistic }
                        handleCurrentlyChangingSurveyOrEvaluationStatistic={ handleCurrentlyChangingSurveyOrEvaluationStatistic } />

                    <AddDashedButton handleClick={ toggleAddASurveyOrEvaluationStatisticModalOpen } text="Add a statistic" />

                    <div className="text-center">
                        <Toggle
                            onChange={ event => handleToggleChange({ event, handleInputChange }) }
                            checked={ inputs.weekly_statistic_is_on === "1" }
                            className={ [ customizeEmailClasses[ "toggle" ], "toggle-yellow" ].join(" ") }
                            icons={ true }
                            name="weekly_statistic_is_on"
                            id="weekly_statistic_is_on" />
                    </div>
                </div>
                { inputs.weekly_statistic_is_on === "0" ? (
                    <div className={ customizeEmailClasses[ "overlay" ] } ></div>
                ) : (null) }
            </div>

            <Footer text={ engagementEmailDataFromAPI?.content_json.footer } />

            <Modal
                isOpen={ isAddATrialStatisticModalOpen }
                toggle={ toggleAddATrialStatisticModal }
                centered
                backdrop="static">
                <CloseModalButton onClick={ toggleAddATrialStatisticModal } />

                <AddTrialStatistic
                    toggleAddATrialStatisticModal={ toggleAddATrialStatisticModal }
                    handleInputChange={ handleInputChange }
                    inputs={ inputs }
                    changingStatistic={ changingStatistic }
                    setChangingStatistic={ setChangingStatistic } />
            </Modal>

            <Modal
                isOpen={ isAddAVerbatimModalOpen }
                toggle={ toggleAddAVerbatimModal }
                centered
                backdrop="static">
                <CloseModalButton onClick={ toggleAddAVerbatimModal } />

                <AddAVerbatim
                    toggleAddAVerbatimModal={ toggleAddAVerbatimModal }
                    inputs={ inputs }
                    handleInputChange={ handleInputChange }
                    currentlyChangingVerbatimComment={ currentlyChangingVerbatimComment }
                    setCurrentlyChangingVerbatimComment={ setCurrentlyChangingVerbatimComment } />
            </Modal>

            <Modal
                isOpen={ isAddASurveyOrEvaluationStatisticModalOpen }
                toggle={ toggleAddASurveyOrEvaluationStatisticModalOpen }
                centered
                backdrop="static">
                <CloseModalButton onClick={ toggleAddASurveyOrEvaluationStatisticModalOpen } />

                <AddASurveyOrEvaluationStatistic
                    toggleAddASurveyOrEvaluationStatisticModalOpen={ toggleAddASurveyOrEvaluationStatisticModalOpen }
                    inputs={ inputs }
                    handleInputChange={ handleInputChange }
                    currentlyChangingSurveyOrEvaluationStatistic={ currentlyChangingSurveyOrEvaluationStatistic }
                    setCurrentlyChangingSurveyOrEvaluationStatistic={ setCurrentlyChangingSurveyOrEvaluationStatistic } />
            </Modal>

            <Modal
                isOpen={ isImageEditingOpen }
                toggle={ toggleIsImageEditingOpen }
                centered
                backdrop="static">
                <CloseModalButton onClick={ toggleIsImageEditingOpen } />

                <ReactEasyCrop
                    image={ uploadedImage }
                    toggleIsImageEditingOpen={ toggleIsImageEditingOpen }
                    onSuccessCallback={ handleImageUpload } />
            </Modal>
        </div>
    )
}

export default TrialRecapUpdateEndoscopy;