/** @format */

import {
  createFormDataPreTrialCommunicationThankYouEndoscopy,
  createFormDataForPreTrialCommunicationThankYouCommunications,
  createFormDataForInServiceInviteCommunications,
  createFormDataForInServiceInviteMed,
  createFormDataForInServiceInviteEndoscopy,
  createFormDataForInServiceRecapCommunications,
  createFormDataForInServiceRecapEndoscopy,
  createFormDataForTrialRecapUpdateEndoscopy,
  createFormDataForWeeklyEngagementEndoscopy,
  createFormDataForTrialRecapUpdateCommunications,
} from "./createFormDataForTemplateTypes";

const createFormData = ({ engagementEmailDataFromAPI, inputs, id }) => {
  if (engagementEmailDataFromAPI.template_type === "service_invite") {
    return createFormDataForInServiceInviteCommunications({
      engagementEmailDataFromAPI,
      inputs,
      id,
    });
  } else if (
    engagementEmailDataFromAPI.template_type === "service_invite_med"
  ) {
    return createFormDataForInServiceInviteMed({
      engagementEmailDataFromAPI,
      inputs,
      id,
    });
  } else if (
    engagementEmailDataFromAPI.template_type === "service_invite_endo"
  ) {
    return createFormDataForInServiceInviteEndoscopy({
      engagementEmailDataFromAPI,
      inputs,
      id,
    });
  } else if (engagementEmailDataFromAPI.template_type === "service_recap") {
    return createFormDataForInServiceRecapCommunications({
      engagementEmailDataFromAPI,
      inputs,
      id,
    });
  } else if (
    engagementEmailDataFromAPI.template_type === "service_recap_endo"
  ) {
    return createFormDataForInServiceRecapEndoscopy({
      engagementEmailDataFromAPI,
      inputs,
      id,
    });
  } else if (engagementEmailDataFromAPI.template_type === "integration_trial") {
    return createFormDataForPreTrialCommunicationThankYouCommunications({
      engagementEmailDataFromAPI,
      inputs,
      id,
    });
  } else if (engagementEmailDataFromAPI.template_type === "trial_recap") {
    return createFormDataForTrialRecapUpdateEndoscopy({
      engagementEmailDataFromAPI,
      inputs,
      id,
    });
  } else if (
    engagementEmailDataFromAPI?.template_type === "weekly_engagement"
  ) {
    return createFormDataForWeeklyEngagementEndoscopy({
      engagementEmailDataFromAPI,
      inputs,
      id,
    });
  } else if (
    engagementEmailDataFromAPI?.template_type === "integration_trial_complete"
  ) {
    return createFormDataForTrialRecapUpdateCommunications({
      engagementEmailDataFromAPI,
      inputs,
      id,
    });
  } else {
    return createFormDataPreTrialCommunicationThankYouEndoscopy({
      engagementEmailDataFromAPI,
      inputs,
      id,
    });
  }
};

export default createFormData;
