import React, { useRef, useEffect } from 'react';

import Chart from "chart.js";

import createChartColors from './createChartColors';

const PieChart = ({ data }) => {
    let chartRef = useRef(null);
    const getLabels = data.map(item => ` ${ item.label }`);
    const getPercentages = data.map(item => item.percentage).slice(0, 10);

    useEffect(() => {
        const ctx = chartRef.current.getContext("2d");

        new Chart(ctx, {
            type: "pie",
            data: {
                datasets: [ {
                    data: getPercentages,
                    label: 'pie chart data',
                    backgroundColor: createChartColors(getPercentages.length)
                } ],
                labels: getLabels,
            },
            options: {
                responsive: true,
                legend: {
                    display: false
                },
                animation: {
                    duration: 0
                }
            }
        });
    });

    return (
        <canvas ref={ chartRef }></canvas>
    );
}

export default PieChart;