import React, { useRef, useEffect } from 'react';

import Chart from 'chart.js';

import createChartColors from './createChartColors';

const DoughnutChart = ({ chartId, data, onChartDone, question }) => {
	let chartRef = useRef(null);
	let best = 0;
	const getLabels = data.map((item) => item.label);
	const getPercentages = data.map((item, index) => {
		if (item.percentage > data[best].percentage) best = index;
		return item.percentage;
	});
	useEffect(() => {
		const ctx = chartRef.current.getContext('2d');

		const chart = new Chart(ctx, {
			type: question.questions_type === 'yesno' ? 'pie' : 'doughnut',
			data: {
				datasets: [
					{
						data: getPercentages,
						label: 'pie chart data',
						backgroundColor: createChartColors(getPercentages.length)
					}
				],
				labels: getLabels
			},
			options: {
				responsive: true,
				legend: {
					display: false
				},
				animation: {
					duration: 0
				}
			}
		});
		if (onChartDone) onChartDone(chartId, chart.toBase64Image());
	});

	return (
		<div className="chartContainer">
			<canvas ref={chartRef} />
			{question.questions_type === 'yesno' && (
				<div className="bestLabel">
					{parseFloat(data[best].percentage).toFixed(0)}%
					<br />
					{data[best].label}
				</div>
			)}
		</div>
	);
};

export default DoughnutChart;
