import React from "react";
import { Container} from "reactstrap";
import {SurveyTitle, MultipleChoice, YesNo, TextResponse, Rating, DragDropRanking} from "../Form/FormTrialsSurveyEdit";

const TrialSummary = () => {
    
    return (
        <>
            <Container className="page pb-50 pt-5">                   
                <SurveyTitle />
                <MultipleChoice />
                <YesNo />
                <TextResponse />
                <Rating />
                <DragDropRanking />
            </Container >
        </>
    );
}

export default TrialSummary;


