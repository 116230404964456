import * as ActionTypes from '../actions/ActionTypes';

const initState = {
    userCounters: {
        active_trial_count: 0,
        total_people_count: 0,
        total_customer_count: 0
    },
    userCountersError: '',
}

const userCountersReducer = (state = initState, action) => {
    switch (action.type)
    {
        case ActionTypes.GET_USER_COUNTERS_SUCCESS:
            return {
                ...state,
                userCounters: action.userCounters,
                userCountersError: ''
            }

        case ActionTypes.GET_USER_COUNTERS_ERROR:
            return {
                ...state,
                userCountersError: action.userCountersError
            }

        default:
            return state;
    }
}

export default userCountersReducer;