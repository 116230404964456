import * as ActionTypes from '../actions/ActionTypes';

const initState = {
	getPeople: [],
	getPeopleError: '',
	isFetchingGetPeople: false
};

const peopleReducer = (state = initState, action) => {
	switch (action.type) {
		case ActionTypes.GET_PEOPLE_SUCCESS:
			return {
				...state,
				getPeople: action.getPeople,
				getPeopleError: '',
				isFetchingGetPeople: false
			};

		case ActionTypes.GET_PEOPLE_ERROR:
			return {
				...state,
				getPeopleError: action.getPeopleError,
				isFetchingGetPeople: false
			};

		case ActionTypes.REQUEST_GET_PEOPLE:
			return {
				...state,
				isFetchingGetPeople: true
			}

		default:
			return state;
	}
};

export default peopleReducer;
