import React from 'react';
import { Switch, Route } from 'react-router-dom';
import NavBar from '../Navi/NavBar';

import Customers from '../Pages/Customers';
import TrialPeopleHistory from '../Pages/Trial/TrialPeopleHistory/TrialPeopleHistory';
import TrialCustomerHistory from '../Pages/Trial/TrialPeopleHistory/TrialCustomerHistory';

const CustomersRoute = () => {
	return (
		<React.Fragment>
			<NavBar />
			<Switch>
				<Route exact path="/customers">
					<Customers />
				</Route>
				<Route exact path="/customers/:id">
					<TrialCustomerHistory />
				</Route>
			</Switch>
		</React.Fragment>
	);
};

export default CustomersRoute;
