import React, { Component, createRef } from 'react';
import { Popover } from 'reactstrap';
import classes from '../CreateOrEdit.module.css';
import Toggle from 'react-toggle';
import '../../../../BusinessUnitSelection/BusinessUnitSelection.module.css';

class YesNo extends Component {
	state = {
		question: '',
		requiredResponse: false,
		allowNAResponse: false,
		questionAnswers: [
			{ option_id: 0, options: 'yes', is_display: '1' },
			{ option_id: 1, options: 'no', is_display: '1' }
		],
		order: this.props.order,
		isDisplay: true,
		id: this.props.id,
		type: 'yesno',
		popoverOpen: false
	};
	constructor(props) {
		super(props);
		if (props.item) {
			this.state = {
				question: props.item.question,
				requiredResponse: props.item.requiredResponse,
				allowNAResponse: props.item.allowNAResponse,
				questionAnswers: props.item.questionAnswers,
				id: props.id,
				isDisplay: props.item.isDisplay,
				order: props.item.order,
				type: 'yesno',
				popoverOpen: false
			};
		}
	}

	allowNAResponseRef = createRef();
	requiredResponseRef = createRef();

	handleTextAreaChange = (ev) => {
		this.setState(
			{
				question: ev.currentTarget.value
			},
			() => {
				this.props.handleQuestionChange(this.state);
			}
		);
	};

	handleRequiredResponse = () => {
		this.setState(
			{
				requiredResponse: !this.state.requiredResponse
			},
			() => {
				this.props.handleQuestionChange(this.state);
			}
		);

		this.requiredResponseRef.current.classList.toggle(classes['form-survey__card-settings-option-label--enabled']);
	};

	handleAllowNAResponse = () => {
		let answers = this.state.questionAnswers;
		const naResponse = answers.find((o) => o.options == 'N/A');
		if (!this.state.allowNAResponse) {
			!naResponse && answers.push({ option_id: 2, options: 'N/A', is_display: '1' });
		} else {
			answers = answers.filter((a) => a.options !== 'N/A');
		}
		this.setState(
			{
				allowNAResponse: !this.state.allowNAResponse,
				questionAnswers: answers
			},
			() => {
				this.props.handleQuestionChange(this.state);
			}
		);

		this.allowNAResponseRef.current.classList.toggle(classes['form-survey__card-settings-option-label--enabled']);
	};

	handleAnswerChange = (ev) => {
		let cloneAnswers = this.state.questionAnswers;
		let splitInputID = ev.currentTarget.id.split('-')[2];

		cloneAnswers[splitInputID] = ev.currentTarget.value;

		this.setState(
			{
				questionAnswers: cloneAnswers
			},
			() => {
				this.props.handleQuestionChange(this.state);
			}
		);
	};
	toggleMenu = () => {
		this.setState({
			popoverOpen: !this.state.popoverOpen
		});
	};

	render() {
		const IDOfQuestion = this.props.item.id;
		const popoverOpen = this.state.popoverOpen;
		const { question, requiredResponse, allowNAResponse, questionAnswers } = this.props.item;

		return (
			<div className={classes['form-survey__card']} onClick={() => this.state.popoverOpen && this.toggleMenu()}>
				<div className={classes['form-survey__card-title-container']}>
					<span className={classes['form-survey__card-question-number']}>{this.props.order}</span>
					<h3 className={classes['form-survey__card-title']}>Yes/No</h3>
					{this.props.allowMove && (
						<span>
							<i className={[ classes['form-survey__arrow-down-icon'], 'las la-angle-down' ].join(' ')} />
						</span>
					)}
					<span className={classes['form-survey__card-menu-icon']}>
						<i className="las la-ellipsis-h" id={`yesno-menu-${IDOfQuestion}`} />
						<Popover
							placement="left"
							isOpen={popoverOpen}
							target={`yesno-menu-${IDOfQuestion}`}
							toggle={() => {
								console.log(IDOfQuestion);
								this.toggleMenu();
							}}
						>
							<p
								className={[ classes['popover_option'], 'futurastdbook' ].join(' ')}
								style={styles.popover_action_red}
								onClick={() => {
									console.log(IDOfQuestion);
									this.toggleMenu();
									this.props.onDelete(IDOfQuestion);
								}}
							>
								REMOVE
							</p>
						</Popover>
					</span>
				</div>
				<div className={classes['form-survey__card-group']}>
					<label className={classes['form-survey__card-label']} htmlFor={`question-${IDOfQuestion}`}>
						question
					</label>
					<textarea
						className={classes['form-survey__card-textarea']}
						type="text"
						id={`question-${IDOfQuestion}`}
						name={`question-${IDOfQuestion}`}
						onChange={this.handleTextAreaChange}
						value={question}
						placeholder="question"
					/>
				</div>

				<div className={classes['form-survey__card-settings-container']}>
					<h3
						className={[
							classes['form-survey__card-label'],
							classes['form-survey__card-label-settings']
						].join(' ')}
					>
						settings
					</h3>
					<div className={classes['form-survey__card-settings-options-container']}>
						<div className={classes['form-survey__card-settings-option']}>
							<div className={classes['form-survey__card-toggle-container']}>
								<Toggle
									onChange={this.handleRequiredResponse}
									defaultChecked={requiredResponse}
									className="toggle-yellow"
									icons={false}
									id={`required-response-${IDOfQuestion}`}
									name={`required-response-${IDOfQuestion}`}
								/>
							</div>
							<label
								ref={this.requiredResponseRef}
								htmlFor={`required-response-${IDOfQuestion}`}
								className={classes['form-survey__card-settings-option-label']}
							>
								<div className={classes['form-survey__card-settings-icon-container']}>
									<i className="las la-lock" />{' '}
								</div>
								Make this a required response
							</label>
						</div>
						<div className={classes['form-survey__card-settings-option']}>
							<div className={classes['form-survey__card-toggle-container']}>
								<Toggle
									onChange={this.handleAllowNAResponse}
									checked={allowNAResponse}
									className="toggle-yellow"
									icons={false}
									id={`allow-NA-response-${IDOfQuestion}`}
									name={`allow-NA-response-${IDOfQuestion}`}
								/>
							</div>
							<label
								ref={this.allowNAResponseRef}
								htmlFor={`allow-NA-response-${IDOfQuestion}`}
								className={classes['form-survey__card-settings-option-label']}
							>
								<div className={classes['form-survey__card-settings-icon-container']}>
									<i className="las la-comment-slash" />
								</div>
								Allow "N/A" response
							</label>
						</div>
					</div>
				</div>

				<div className={classes['form-survey__card-answers-container']}>
					<h3 className={classes['form-survey__card-label']}>answers</h3>

					<div className={classes['form-survey__card-single-answer-container']}>
						{questionAnswers.map((answer, index) => {
							return (
								<div
									key={`${IDOfQuestion}-ans-${index}`}
									className={classes['form-survey__card-single-answer']}
								>
									<div className={classes['form-survey__card-single-answer-input-container']}>
										<input
											onChange={this.handleAnswerChange}
											value={answer.options}
											disabled={true}
											className={classes['form-survey__card-single-answer-input']}
										/>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
}
const styles = {
	popover_action_red: {
		color: 'red',
		margin: 5
	}
};

export default YesNo;
