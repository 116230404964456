import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import NavBar from '../Navi/NavBar';
import SurveysAndEvaluations from '../Pages/Trial/SurveysAndEvaluations/Home/SurveysAndEvaluations';
import SurveysAndEvaluationsInformation from '../Pages/Trial/SurveysAndEvaluations/SurveysAndEvaluationsInformation/SurveysAndEvaluationsInformation';
import SurveysAndEvaluationsTemplateInformation from '../Pages/Trial/SurveysAndEvaluations/SurveysAndEvaluationsTemplateInformation/SurveysAndEvaluationsTemplateInformation';
import TrialsSurveyEvalResults from '../Pages/Trial/SurveysAndEvaluations/TrialSurveyEvalResults/TrialSurveyEvalResults';
import TrialsSurveyEvalResult from '../Pages/TrialsSurveyEvalResult';
import NavBarEditSurveyEval from '../Navi/NavBarEditSurveyEval';
import TrialsSurveyEvalEdit from '../Pages/TrialsSurveyEvalEdit';
import TrialsFilloutChooseRespondent from '../Pages/TrialsFilloutChooseRespondent';
import PersonResponse from '../Pages/Trial/SurveysAndEvaluations/PersonResponse/PersonResponse';

import { withRouter } from 'react-router-dom';
import { getRespondentsForSurveyAction, getSurveyTemplateAction } from '../Store/actions/surveysAction';
import { getRespondentsForEvaluationAction, getEvaluationTemplateAction } from '../Store/actions/evaluationsAction';

import { connect } from 'react-redux';
import TrialsFilloutRespondentSurveyEval from '../Pages/TrialsFilloutRespondentSurveyEval';
import TrialSurveyEvalResultSummary from '../Pages/Trial/SurveysAndEvaluations/TrialSurveyEvalResults/TrialSurveyEvalResultSummary';

class TrialSurveyRoute extends Component {
	componentDidMount() {
		const { trialID } = this.props.match.params;
	}

	render() {
		const { trialID } = this.props.match.params;
		const { evaluations, surveys } = this.props;
		return (
			<React.Fragment>
				<NavBar />
				<Switch>
					<Route exact path="/trial/:trialID/surveyevals">
						<SurveysAndEvaluations trialID={trialID} />
					</Route>
					<Route exact path="/trial/:trialID/surveyevals/:type/templates/:id">
						<SurveysAndEvaluationsTemplateInformation />
					</Route>
					<Route exact path="/trial/:trialID/surveyevals/:type/:id">
						<SurveysAndEvaluationsInformation />
					</Route>
					<Route exact path="/trial/:trialID/surveyevals/:type/:id/person-response/:personType/:personID">
						<PersonResponse />
					</Route>
					<Route exact path="/trial/:trialID/surveyevals/:type/:id/respondents">
						<TrialsFilloutChooseRespondent />
					</Route>
					<Route exact path="/trial/:trialID/surveyevals/:type/:id/result">
						<TrialSurveyEvalResultSummary />
					</Route>
					<Route exact path="/trial/:trialID/surveyevals/:type/:id/fillout/person/:personType/:personID">
						<TrialsFilloutRespondentSurveyEval
							evaluation={evaluations.selectedTemplate}
							survey={surveys.selectedTemplate}
						/>
					</Route>
					<Route exact path="/trial/:trialID/surveyevals/:type/:id/review/person/:personType/:personID">
						<TrialsSurveyEvalResults
							evaluation={evaluations.selectedTemplate}
							survey={surveys.selectedTemplate}
						/>
					</Route>
					<Route exact path="/trial/:trialID/surveyevals/:type/:id/edit">
						<NavBarEditSurveyEval />
						<TrialsSurveyEvalEdit />
					</Route>
				</Switch>
			</React.Fragment>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		evaluations: {
			...state.evaluations
		},
		surveys: {
			...state.surveys
		}
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getEvaluation: (parameters) => {
			dispatch(getEvaluationTemplateAction(parameters));
		},
		getSurvey: (parameters) => {
			dispatch(getSurveyTemplateAction(parameters));
		},

		getEvaluationRespondents: (parameters) => {
			dispatch(getRespondentsForEvaluationAction(parameters));
		},
		getSurveyRespondents: (parameters) => {
			dispatch(getRespondentsForSurveyAction(parameters));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TrialSurveyRoute));
