import React from 'react';

import classes from '../../EmailResults/EmailResults.module.css';

import PreTrialCommunicationThankYouContent from '../Shared/PreTrialCommunicationThankYouContent';
import LogoPreview from '../../Shared/LogoPreview/LogoPreview';
import Footer from '../../Shared/Footer/Footer';

const PreTrialCommunicationThankYouEndoscopy = ({ emailPreview }) => {
    const BASE_URL = process.env.REACT_APP_API_URL;
    let facilityImage = BASE_URL + emailPreview.facility_image;
    let facilityLogo = BASE_URL + emailPreview.facility_logo;

    if (emailPreview.facility_image === "")
    {
        facilityImage = '/images/email/facility-image-placeholder.png';
    }

    if (emailPreview.facility_logo === "")
    {
        facilityLogo = '/images/email/facility-logo-placeholder.png';
    }

    return (
        <div className={ classes[ "email-results-section" ] }>
            <div className={ classes[ "email-results-preview-container" ] }>
                <LogoPreview />

                <div
                    className={ classes[ "email-results-preview__header" ] }>

                    <div className={ classes[ "email-results-preview__header-left" ] }>
                        <div className={ classes[ "email-results-preview__title" ] }>
                            { emailPreview?.account_name }
                        </div>
                        <h2 className={ classes[ "email-results-preview__headline" ] }>
                            { emailPreview?.head_line }
                        </h2>
                    </div>

                    <div className={ classes[ "email-results-preview__header-right" ] }>
                        <img
                            className={ classes[ "email-results-preview__header-image" ] }
                            src={ facilityImage } alt="facility image" />
                        <img
                            className={ classes[ "email-results-preview__header-image" ] }
                            src={ facilityLogo } alt="facility logo" />
                    </div>
                </div>

                <div className={ classes[ "email-results-border" ] }></div>

                <PreTrialCommunicationThankYouContent
                    emailPreviewImagePath="/images/email/video-img_new.png"
                    emailPreview={ emailPreview } />
                <Footer text={ emailPreview.footer } />
            </div>
        </div>
    );
}

export default PreTrialCommunicationThankYouEndoscopy;