import React, { useState } from 'react';

import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage';

import SubmitButton from '../../Components/FormButtons/SubmitButton';
import CancelButton from '../../Components/FormButtons/CancelButton';

import classes from './ReactEasyCrop.module.css';

const ReactEasyCrop = ({ image, onSuccessCallback, toggleIsImageEditingOpen }) => {
    const [ crop, setCrop ] = useState({ x: 0, y: 0 });
    const [ zoom, setZoom ] = useState(1);
    const [ croppedAreaPixels, setCroppedAreaPixels ] = useState(null)

    const [ rotation, setRotation ] = useState(0)
    const rotate = (degrees) => {
        setRotation(previousRotation => previousRotation + degrees);
    }

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }

    function dataURLtoBlob (dataurl) {
        let arr = dataurl.split(','),
            mime = arr[ 0 ].match(/:(.*?);/)[ 1 ],
            bstr = atob(arr[ 1 ]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--)
        {
            u8arr[ n ] = bstr.charCodeAt(n);
        }
        return new Blob([ u8arr ], { type: mime });
    }

    const handleSubmit = async () => {
        try
        {
            const croppedImage = await getCroppedImg(
                image.file,
                croppedAreaPixels,
                rotation
            )
            onSuccessCallback({
                name: image.name,
                file: dataURLtoBlob(croppedImage)
            });

            toggleIsImageEditingOpen();
        } catch (error)
        {
            console.error(error)
        }
    };

    return (
        <div className="modal-buttons">
            <div className={ classes[ "image-editing__image" ] }>
                <Cropper
                    image={ image.file }
                    crop={ crop }
                    zoom={ zoom }
                    rotation={ rotation }
                    zoomWithScroll={ false }
                    showGrid={ false }
                    aspect={ image.dimensions.width / image.dimensions.height }
                    onCropChange={ setCrop }
                    onZoomChange={ setZoom }
                    onCropComplete={ onCropComplete } />
            </div>
            <div className={ classes[ "image-editing__controls" ] }>
                <button
                    className={ [ classes[ "image-editing__rotate-button" ], classes[ "image-editing__rotate-button-left" ] ].join(" ") }
                    onClick={ () => rotate(-90) }>
                    <i className="las la-undo-alt"></i>
                </button>
                <button
                    className={ [ classes[ "image-editing__rotate-button" ], classes[ "image-editing__rotate-button-right" ] ].join(" ") }
                    onClick={ () => rotate(90) }>
                    <i className="las la-redo-alt"></i>
                </button>
                <i className={ [ "las la-image", classes[ "image-editing__small-icon" ] ].join(" ") }></i>
                <input
                    className={ classes[ "image-editing__slider" ] }
                    type="range"
                    value={ zoom }
                    min="1"
                    max="2"
                    step="0.1"
                    onChange={ event => setZoom(event.target.value) } />
                <i className={ [ "las la-image", classes[ "image-editing__large-icon" ] ].join(" ") }></i>
            </div>

            <div className="cancel-and-submit-buttons-container">
                <CancelButton handleClick={ toggleIsImageEditingOpen } />

                <SubmitButton handleClick={ handleSubmit } />
            </div>
        </div>
    );
}

export default ReactEasyCrop;
