/*
    storing API responses / request into IndexdDB
*/

exports.indexedDBAPI = (function () {

    var db;

    function retrieveGetResponseFromIndexedDB (path, cb, storeName) {
        let objectStore = db.transaction([ storeName ]).objectStore(storeName);

        let pathIndex = objectStore.index("path");
        let request = pathIndex.get(path);

        request.onsuccess = function () {
            console.log('successfully retrieve from indexedDB', request.result);
            cb(request.result)
        };

        request.onerror = function () {
            console.log('error retrieve from indexedDB' + request.error);
        }
    }

    function storeGetResponseIntoIndexedDB (objectToStore, path, storeName, onSuccessCallback) {
        let objectStore = db.transaction([ storeName ], 'readwrite').objectStore(storeName);

        let record = {
            data: objectToStore,
            path: path
        }

        let request = objectStore.put(record);

        request.onsuccess = function () {
            console.log('successfully stored into indexedDB: ' + request.result);
            if (onSuccessCallback)
            {
                onSuccessCallback();
            }
        };


        request.onerror = function () {
            console.log('error storing into indexedDB' + request.error);
        }
    }


    const openIndexedDB = (cb, storeName) => {
        let request = window.indexedDB.open(storeName, 8);

        request.onerror = function (e) {
            console.log('failed to open db', e)
        }

        request.onsuccess = function () {
            console.log('open DB', request.result)
            db = request.result;
            getAllFromObjectStore(cb, storeName)
        }

        if (storeName === 'COMPETITIVE_INTEL')
        {
            request.onupgradeneeded = function (e) {
                let db = e.target.result;

                let objectStoreGET = db.createObjectStore('COMPETITIVE_INTEL', { keyPath: 'path', unique: true });
                objectStoreGET.createIndex('path', 'path', { unique: false });
            };
        } else if (storeName === 'AUDIO')
        {
            request.onupgradeneeded = function (e) {
                let db = e.target.result;

                let objectStoreGET = db.createObjectStore('AUDIO', { keyPath: 'path', unique: true });
                objectStoreGET.createIndex('path', 'path', { unique: false });
            };
        }
    }

    function getAllFromObjectStore (cb, storeName) {
        let objectStore = db.transaction([ storeName ]).objectStore(storeName);

        let request = objectStore.getAll()

        request.onsuccess = function () {
            console.log('successfully retrieve from indexedDB', request.result);
            cb(request.result)
        };

        request.onerror = function () {
            console.log('error retrieve from indexedDB' + request.error);
        }
    }

    return {
        storeGetResponseIntoIndexedDB,
        retrieveGetResponseFromIndexedDB,
        getAllFromObjectStore,
        openIndexedDB
    }
})();