import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	getChecklistCommentsAction,
	putChecklistItemAction,
	putChecklistsItemStatus,
	postChecklistCommentAction,
	deleteChecklistTeammateAction
} from '../../../../Store/actions/checklistsAction';

import { getTeammatesAction } from '../../../../Store/actions/trialsAction';
import { Modal } from 'reactstrap';
import checklistClasses from '../TrialChecklist.module.css';
import classes from './TrialChecklistComments.module.css';
import ProfileImage from '../../../../Utils/ProfileImage';
import PopoverButton from '../../../../Components/Popups/PopoverEllipsis';
import DeleteChecklistItem from '../DeleteChecklistItem/DeleteChecklistItem';
import AssignItem from '../AssignItem/AssignItem';
import DeleteComment from './DeleteComment/DeleteComment';
import EditComment from './EditComment/EditComment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CloseModalButton from '../../../../Components/CloseModalButton/CloseModalButton';

class TrialChecklist extends Component {
	state = {
		newComment: '',
		focusedComment: null,
		editingItemDetails: false,
		isDeleteChecklistItemModalOpen: false,
		isAssignItemModalOpen: false,
		isDeletingCommentModalOpen: false,
		isEditingCommentModalOpen: false,
		newItemDetails: {
			title: '',
			description: '',
			format_due_date: ''
		}
	};

	componentDidMount() {
		const trialID = this.props.match.params.id;
		const checklistID = this.props.match.params.checklistID;

		this.props.getChecklistComments({ trialID, checklistID });
		this.props.getTeammates({ trialID });
	}

	toggleEditItemDetails = () => {
		this.setState({ newItemDetails: { ...this.props.checklistCommentsAndChecklist.checklist } });
		this.setState({ editingItemDetails: !this.state.editingItemDetails });
	};

	updateChecklistItem = () => {
		const trialID = this.props.match.params.id;
		const { checklist } = this.props.checklistCommentsAndChecklist;
		const { newItemDetails } = this.state;
		this.props.updateChecklistItem({
			trialID: trialID,
			title: newItemDetails.title,
			groupID: checklist.group_id,
			itemID: checklist.id,
			description: newItemDetails.description,
			dueDate: newItemDetails.format_due_date
		});
		this.toggleEditItemDetails();
	};

	toggleDeleteChecklistItemModal = () => {
		this.setState({
			isDeleteChecklistItemModalOpen: !this.state.isDeleteChecklistItemModalOpen
		});
	};

	toggleAssignItemModal = () => {
		this.setState({
			isAssignItemModalOpen: !this.state.isAssignItemModalOpen
		});
	};

	toggleDeleteCommentModal = (comment) => {
		this.setState({
			isDeletingCommentModalOpen: !this.state.isDeletingCommentModalOpen,
			focusedComment: !this.state.isDeletingCommentModalOpen ? comment : null
		});
	};

	toggleEditCommentModal = (comment) => {
		this.setState({
			isEditingCommentModalOpen: !this.state.isEditingCommentModalOpen,
			focusedComment: !this.state.isEditingCommentModalOpen ? comment : null
		});
	};

	submitComment = () => {
		const trialID = this.props.match.params.id;
		const { checklist } = this.props.checklistCommentsAndChecklist;
		this.props.postComment({ trialID: trialID, itemID: checklist.id, commentText: this.state.newComment });
		this.setState({ newComment: '' });
	};

	render() {
		const trialID = this.props.match.params.id;
		const { checklist, comments } = this.props.checklistCommentsAndChecklist;
		const { newComment, editingItemDetails, newItemDetails } = this.state;
		if (checklist && checklist.teammate_id) {
			checklist.assignedTeammate = this.props.teammates.find((tm) => tm.id == checklist.teammate_id);
		}
		const checklistCommentsHeader =
			checklist && checklist.title ? (
				<div className={checklistClasses['checklist']}>
					{checklist.is_completed === '1' ? (
						<div
							className={checklistClasses['circle__completed']}
							onClick={() => {
								this.props.toggleChecklistItem({
									trialID: trialID,
									itemID: checklist.id,
									isCompleted: '0'
								});
							}}
						>
							<i className="las la-check" />
						</div>
					) : (
						<div
							className={checklistClasses['circle']}
							onClick={() => {
								this.props.toggleChecklistItem({
									trialID: trialID,
									itemID: checklist.id,
									isCompleted: '1'
								});
							}}
						/>
					)}
					<div className={checklistClasses['checklist__details']}>
						{!editingItemDetails ? (
							<div>
								<div className={checklistClasses['checklist__wrapper']}>
									<div className="flex-grow-1 flex-basis-0">
										<div className={checklistClasses['checklist__item']}>
											<p className={checklistClasses['checklist__item-title']}>
												{checklist.title}
											</p>

											<PopoverButton
												itemId={1}
												onEdit={this.toggleEditItemDetails}
												onDelete={() => this.toggleDeleteChecklistItemModal()}
											/>
										</div>

										<div className={checklistClasses['checklist__item']}>
											<p className={checklistClasses['checklist__text']}>
												{checklist.description}
											</p>
										</div>
									</div>
								</div>
								<div className={[ classes['checklist__item--dates'] ].join(' ')}>
									<div className={checklistClasses['checklist__item']}>
										<p className="my-0">Created:</p>
										<p className="my-0">{checklist.format_created_date}</p>
									</div>
									<div className={checklistClasses['checklist__item']}>
										<p className="my-0">Due:</p>
										<p className="my-0">{checklist.format_due_date}</p>
									</div>
								</div>
							</div>
						) : (
							<div>
								<div>
									<label className={classes['checklist__fw-input-label']}>Title:</label>
									<input
										className={classes['checklist__fw-input-text']}
										value={newItemDetails.title}
										onChange={(e) =>
											this.setState({
												newItemDetails: { ...this.state.newItemDetails, title: e.target.value }
											})}
									/>

									<label className={classes['checklist__fw-input-label']}>Description:</label>
									<input
										className={classes['checklist__fw-input-text']}
										value={newItemDetails.description}
										onChange={(e) =>
											this.setState({
												newItemDetails: {
													...this.state.newItemDetails,
													description: e.target.value
												}
											})}
									/>
									<label className={classes['checklist__fw-input-label']}>Due: </label>
									<DatePicker
										type="date"
										onClick={this.chooseDueDate}
										className={classes['checklist__fw-input-text']}
										placeholderText="Enter a due date"
										onChange={(date) => {
											const formatDate = `${date.getMonth() +
												1} - ${date.getDate()} - ${date.getFullYear()}`;

											this.setState({
												newItemDetails: {
													...this.state.newItemDetails,
													format_due_date: formatDate
												}
											});
										}}
										selected={
											newItemDetails.format_due_date ? (
												new Date(newItemDetails.format_due_date.replace(/ /g, ''))
											) : (
												''
											)
										}
										popperPlacement="bottom-start"
										popperModifiers={{
											offset: {
												enabled: true,
												offset: '-125px, 10px'
											},
											preventOverflow: {
												enabled: true,
												escapeWithReference: false,
												boundariesElement: 'viewport'
											}
										}}
									/>
								</div>
								<div>
									<button
										className={classes['checklist__edit-cancel']}
										onClick={this.toggleEditItemDetails}
									>
										CANCEL
									</button>
									<button
										className={classes['checklist__edit-submit']}
										onClick={this.updateChecklistItem}
									>
										SUBMIT
									</button>
								</div>
							</div>
						)}

						{!checklist.assignedTeammate ? (
							<div className={checklistClasses['checklist__add-new-item-container']}>
								<button
									className={checklistClasses['checklist__add-new-item']}
									onClick={this.toggleAssignItemModal}
								>
									CLICK HERE TO ASSIGN THIS ACTION ITEM
								</button>
							</div>
						) : (
							<div className={classes['checklist__item-assigned-container']}>
								<p>Assigned to:</p>
								<div className={classes['checklist__item-assigned-teammate']}>
									<div>
										<ProfileImage
											profile={checklist.assignedTeammate}
											cb={null}
											profileImageClasses="profile__image--xtra-small"
										/>
									</div>
									<p>
										{checklist.assignedTeammate.first_name} {checklist.assignedTeammate.last_name}
									</p>
								</div>
								<div
									className={classes['checklist-item-remove-teammate']}
									onClick={() =>
										this.props.removeTeammate({
											trialId: trialID,
											itemId: checklist.id,
											teammateId: checklist.teammate_id
										})}
								>
									<i className="las la-times" />
								</div>
							</div>
						)}
					</div>
				</div>
			) : null;

		const commentsList =
			comments &&
			comments.map((comment) => {
				return (
					<div className={classes['comment-wrapper']}>
						<div>
							<ProfileImage
								profile={comment}
								cb={null}
								profileImageClasses="profile__image--xtra-small"
							/>
						</div>
						<div className="ml-2">
							<p className={classes['checklist-comment__name']}>
								{comment.first_name} {comment.last_name}
							</p>
							<p className={classes['checklist-comment__comment']}>{comment.comment}</p>
							<p className={checklistClasses['checklist__text']}>
								Posted on {comment.format_created_date} -{' '}
								<span
									className={classes['checklist__item-comment-action']}
									onClick={() => this.toggleEditCommentModal(comment)}
								>
									EDIT
								</span>{' '}
								-{' '}
								<span
									className={classes['checklist__item-comment-action']}
									onClick={() => this.toggleDeleteCommentModal(comment)}
								>
									DELETE
								</span>{' '}
							</p>
						</div>
					</div>
				);
			});

		return (
			<div className="padding-top-90 container">
				<h2 className={classes['checklist-comment__title']}>Item detail</h2>
				{checklistCommentsHeader}
				<div className={classes['comment-container']}>{commentsList}</div>
				<div className={classes['checklist__item-comment-input-container']}>
					<textarea
						className={classes['checklist__item-new-comment-textarea']}
						placeholder="Add a comment"
						value={newComment}
						onChange={(text) => {
							console.log(text);
							this.setState({ newComment: text.target.value });
						}}
					/>
					<button
						disabled={!newComment.length > 0}
						className={
							newComment.length > 0 ? (
								classes['checklist__item-comment-submit-btn-on']
							) : (
								classes['checklist__item-comment-submit-btn']
							)
						}
						onClick={this.submitComment}
					>
						SUBMIT
					</button>
				</div>

				<Modal
					centered
					isOpen={this.state.isDeleteChecklistItemModalOpen}
					toggle={this.toggleDeleteChecklisItemtModal}
					backdrop="static"
				>
					<CloseModalButton onClick={ this.toggleDeleteChecklistItemModal } />

					<DeleteChecklistItem
						toggleModal={this.toggleDeleteChecklistItemModal}
						trialID={this.props.match.params.id}
						item={checklist}
					/>
				</Modal>

				<Modal
					centered
					isOpen={ this.state.isAssignItemModalOpen }
					toggle={ this.toggleAssignItemModal }
					backdrop="static">
					<CloseModalButton onClick={ this.toggleAssignItemModal } />

					<AssignItem
						teammates={this.props.teammates}
						toggleModal={this.toggleAssignItemModal}
						trialID={this.props.match.params.id}
						item={checklist}
					/>
				</Modal>

				<Modal
					centered
					isOpen={ this.state.isDeletingCommentModalOpen }
					toggle={ this.toggleDeleteCommentModal }
					backdrop="static">
					<CloseModalButton onClick={ this.toggleDeleteCommentModal } />

					<DeleteComment
						toggleModal={this.toggleDeleteCommentModal}
						trialID={this.props.match.params.id}
						item={checklist}
						comment={this.state.focusedComment}
					/>
				</Modal>

				<Modal
					centered 
					isOpen={ this.state.isEditingCommentModalOpen } 
					toggle={ this.toggleEditCommentModal }
					backdrop="static">
					<CloseModalButton onClick={ this.toggleEditCommentModal } />

					<EditComment
						toggleModal={this.toggleEditCommentModal}
						trialID={this.props.match.params.id}
						checklist={checklist}
						comment={this.state.focusedComment}
					/>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		checklistCommentsAndChecklist: state.checklists.checklistComments,
		teammates: state.trials.teammates
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getChecklistComments: ({ trialID, checklistID }) => {
			dispatch(getChecklistCommentsAction({ trialID, checklistID }));
		},
		updateChecklistItem: (updateParams) => {
			dispatch(putChecklistItemAction(updateParams));
		},
		toggleChecklistItem: (updateParams) => {
			dispatch(putChecklistsItemStatus(updateParams));
		},
		postComment: (commentParams) => {
			dispatch(postChecklistCommentAction(commentParams));
		},
		getTeammates: ({ trialID }) => {
			dispatch(getTeammatesAction(trialID));
		},
		removeTeammate: ({ trialId, itemId, teammateId }) => {
			dispatch(deleteChecklistTeammateAction({ itemID: itemId, teammateID: teammateId, trialID: trialId }));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TrialChecklist));
