import React, { Component } from 'react';

import { Modal } from 'reactstrap';

import classes from './CreateEmailNameModal.module.css';

import {
    clonePreviousEmailAction,
    postTemplateToEngagementEmailAction
} from '../../../../../Store/actions/trials/engagementEmailsAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import createFormData from '../../Shared/createFormData';
import CloseModalButton from '../../../../../Components/CloseModalButton/CloseModalButton';

class CreateEmailNameModal extends Component {
    state = {
        emailName: ''
    }

    componentDidUpdate (prevProps, prevState) {
        if (this.props.engagementEmailData.name !== prevProps.engagementEmailData.name ||
            this.props.engagementEmailData.user_template_name !== prevProps.engagementEmailData.user_template_name)
        {
            this.setState({
                emailName: this.props.engagementEmailData.name || this.props.engagementEmailData.user_template_name
            });
        }
    }

    handleChange = (ev) => {
        this.setState({
            [ ev.currentTarget.name ]: ev.currentTarget.value
        });
    }

    handleFormCancel = (ev) => {
        ev.preventDefault();
        this.props.toggleModal();
    }

    handleFormSubmit = (ev) => {
        ev.preventDefault();
        const parameters = {
            trialID: this.props.match.params.id,
            userTemplateName: this.state.emailName,
            cloneTemplateID: this.props.engagementEmailData.user_template_id
        }

        let inputs = {
            ...this.props.engagementEmailData.content_json,
            emailName: this.state.emailName
        };

        const engagementEmailDataFromAPI = {
            admin_template_id: this.props.engagementEmailData.id || this.props.engagementEmailData.admin_template_id,
            template_type: this.props.engagementEmailData.template_type
        }

        let id = this.props.match.params.id;

        let formData = createFormData({ engagementEmailDataFromAPI, inputs, id });

        const redirectToCustomizeEmailPage = (cloneEngagementEmailID) => {
            this.props.history.push(`${ this.props.history.location.pathname }/${ cloneEngagementEmailID }/customize-email`);
        }

        if (this.props.isPreviousEmail)
        {
            return this.props.clonePreviousEmailAction({ parameters, redirect: redirectToCustomizeEmailPage });
        }

        this.props.postTemplateToEngagementEmail({ formData, redirect: redirectToCustomizeEmailPage });
    }

    render () {
        const { isModalOpen, toggleModal } = this.props;

        return (
            <Modal
                isOpen={ isModalOpen }
                toggle={ toggleModal }
                centered
                backdrop="static">
                <CloseModalButton onClick={ toggleModal } />

                <form>
                    <label
                        className={ classes[ "form-create-email-name__label" ] }
                        htmlFor="emailName">
                        Name this email
                    </label>
                    <input
                        value={ this.state.emailName }
                        onChange={ this.handleChange }
                        className={ classes[ "form-create-email-name__input" ] }
                        type="text"
                        placeholder="This name is for you only"
                        name="emailName"
                        id="emailName" />

                    <button
                        onClick={ this.handleFormSubmit }
                        className={ [ classes[ "form-create-email-name__button" ], classes[ "form-create-email-name__button--submit" ] ].join(" ") }>
                        save &#38; create email
                    </button>
                    <button
                        onClick={ this.handleFormCancel }
                        className={ classes[ "form-create-email-name__button" ] }>
                        cancel
                    </button>
                </form>
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        clonePreviousEmailAction: ({ parameters, redirect }) => {
            dispatch(clonePreviousEmailAction({ parameters, redirect }));
        },
        postTemplateToEngagementEmail: (parameters) => {
            dispatch(postTemplateToEngagementEmailAction(parameters));
        }
    }
}

export default connect(null, mapDispatchToProps)(withRouter(CreateEmailNameModal));