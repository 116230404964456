import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import SelectCustomer from '../FormElements/SelectCustomer/SelectCustomer';
import AddTrialDetails from '../FormElements/AddTrialDetails/AddTrialDetails';
import { Modal } from 'reactstrap';

import classes from './Home.module.css';
import CancelTrial from './CancelTrialModal/CancelTrialModal';
import SelectPeople from '../FormElements/SelectPeople/SelectPeople';
import SelectSpecialties from '../FormElements/SelectSpecialties/SelectSpecialties';
import SelectCompetitors from '../FormElements/SelectCompetitors/SelectCompetitors';
import ReviewAndSubmit from '../FormElements/ReviewAndSubmit/reviewAndSubmit';
import CloseModalButton from '../../../../Components/CloseModalButton/CloseModalButton';

const Home = ({ history }) => {
	const [ formNavigation, setFormNavigation ] = useState([
		{
			name: 'Select customer'
		},
		{
			name: 'Add trial details'
		},
		{
			name: 'Select people'
		},
		{
			name: 'Select specialties'
		},
		{
			name: 'Select competitors'
		},
		{
			name: 'Review & confirm'
		}
	]);

	const [ trialParams, setTrialParams ] = useState({
		customer_id: '',
		customer: {},
		competitor_id: '',
		competitors: {},
		specialty_id: '',
		specialties: {},
		trial_name: '',
		start_date: '',
		end_date: '',
		budget_cycle: '',
		expected_purchase: '',
		people_id: [ '' ],
		people: {}
	});

	const cancelTrialSetup = () => {
		history.goBack();
	};

	const setSelectedCustomer = (customer) => {
		if (customer.id >= 0) {
			setTrialParams({ ...trialParams, customer_id: customer.id, customer: customer });
			formCompleted ? setCurrentViewForForm(5) : setCurrentViewForForm(1);
		}
	};

	const setTrialDetails = (trialDetails) => {
		if (trialDetails.trialName) {
			setTrialParams({
				...trialParams,
				trial_name: trialDetails.trialName,
				start_date: trialDetails.trialStartDate,
				end_date: trialDetails.trialEndDate,
				budget_cycle: trialDetails.budgetCycle,
				expected_purchase: trialDetails.expectedPurchase
			});
			formCompleted ? setCurrentViewForForm(5) : setCurrentViewForForm(2);
		}
	};

	const setSelectedPeople = (people) => {
		const selectedPeopleIds = Object.keys(people);
		if (selectedPeopleIds.length >= 0) {
			setTrialParams({ ...trialParams, people_id: selectedPeopleIds, people: people });
			formCompleted ? setCurrentViewForForm(5) : setCurrentViewForForm(3);
		}
	};

	const setSelectedSpecialties = (specialties) => {
		const selectedSpecialtyIds = Object.keys(specialties).join(',');
		if (selectedSpecialtyIds.length > 0) {
			setTrialParams({ ...trialParams, specialty_id: selectedSpecialtyIds, specialties: specialties });
			formCompleted ? setCurrentViewForForm(5) : setCurrentViewForForm(4);
		}
	};
	const setSelectedCompetitors = (competitors) => {
		const selectedCompetitorIds = Object.keys(competitors).join(',');
		if (selectedCompetitorIds.length > 0) {
			setTrialParams({ ...trialParams, competitor_id: selectedCompetitorIds, competitors: competitors });
			setCurrentViewForForm(5);
			setFormCompleted(true);
		}
	};

	const returnToPage = (pageIndex) => {
		setCurrentViewForForm(pageIndex);
	};

	const [ formCompleted, setFormCompleted ] = useState(false);

	const [ currentViewForForm, setCurrentViewForForm ] = useState(0);

	const [ isCancelTrialSetupModalOpen, setIsCancelTrialSetupModalOpen ] = useState(false);

	const toggleCancelTrialSetupModal = () => {
		setIsCancelTrialSetupModalOpen(!isCancelTrialSetupModalOpen);
	};
	let formView;

	switch (currentViewForForm) {
		case 0:
			formView = (
				<SelectCustomer
					submittedCustomer={trialParams.customer}
					submitCustomerSelection={setSelectedCustomer}
				/>
			);
			break;
		case 1:
			formView = (
				<AddTrialDetails
					submittedTrialDetails={{
						trial_name: trialParams.trial_name,
						start_date: trialParams.start_date,
						end_date: trialParams.end_date,
						budget_cycle: trialParams.budget_cycle,
						expected_purchase: trialParams.expected_purchase
					}}
					submitTrialDetails={setTrialDetails}
				/>
			);
			break;
		case 2:
			formView = (
				<SelectPeople
					submittedPeople={trialParams.people}
					submitPeopleSelection={setSelectedPeople}
					selectedCustomerID={trialParams.customer_id}
				/>
			);
			break;
		case 3:
			formView = (
				<SelectSpecialties
					submittedSpecialties={trialParams.specialties}
					submitSpecialtySelection={setSelectedSpecialties}
				/>
			);
			break;
		case 4:
			formView = (
				<SelectCompetitors
					submittedCompetitors={trialParams.competitors}
					submitCompetitorsSelection={setSelectedCompetitors}
				/>
			);
			break;
		case 5:
			formView = (
				<ReviewAndSubmit
					goToPage={returnToPage}
					customer={trialParams.customer}
					details={{
						trial_name: trialParams.trial_name,
						start_date: trialParams.start_date,
						end_date: trialParams.end_date,
						budget_cycle: trialParams.budget_cycle,
						expected_purchase: trialParams.expected_purchase
					}}
					people={Object.values(trialParams.people)}
					specialties={Object.values(trialParams.specialties)}
					competitors={Object.values(trialParams.competitors)}
					trialDetails={trialParams}
					finishSetup={toggleCancelTrialSetupModal}
				/>
			);
			break;
	}

	return (
		<div className={classes['create-trial']}>
			<div className={classes['create-trial-header']}>
				<button className={classes['create-trial__cancel-button']} onClick={toggleCancelTrialSetupModal}>
					<i className={[ classes['create-trial__cancel-icon'], 'las la-times' ].join(' ')} />
					cancel
				</button>
				<h1 className={classes['create-trial__title']}>Create new trial</h1>
				<div className={classes['form-navigation']}>
					{formNavigation.map((formElement, index) => {
						return (
							<div
								className={[
									classes['form-navigation__container'],
									index <= currentViewForForm ? classes['active'] : ''
								].join(' ')}
								key={index}
							>
								{formElement.name}
								<i
									className={[
										classes['form-navigation__right-arrow-icon'],
										'las la-angle-right'
									].join(' ')}
								/>
							</div>
						);
					})}
				</div>
			</div>
			{formView}

			<Modal
				centered
				isOpen={ isCancelTrialSetupModalOpen }
				toggle={ toggleCancelTrialSetupModal }
				backdrop="static">
				<CloseModalButton onClick={ toggleCancelTrialSetupModal } />
				
				<CancelTrial toggleModal={toggleCancelTrialSetupModal} cancelTrialSetup={cancelTrialSetup} />
			</Modal>
		</div>
	);
};

export default withRouter(Home);
