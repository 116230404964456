import React, { useEffect } from 'react';

import classes from '../../InputElements.module.css';

import { useDispatch, useSelector } from 'react-redux';
import { getTrialsAvailableCustomers } from '../../../../../Store/actions/trialsAction';
import listClasses from '../../../../../Components/List/List.module.css';

import ComponentLoading from '../../../../../Components/ComponentLoading/ComponentLoading';

const CustomerView = ({ handleInputChange }) => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getTrialsAvailableCustomers());
	}, []);

	const isFetchingGetTrialsAvailableCustomer = useSelector(state => state.trials.isFetchingGetTrialsAvailableCustomer);
	const customersArray = useSelector((state) => state.trials.availableCustomers);
	const availableCustomersError = useSelector((state) => state.trials.availableCustomersError);

	/* const handleSelectType = (ev) => {
		handleInputChange(ev);
		let activeElement = document.querySelector('label[data-active="1"]');
		if (activeElement) {
			let [ activeImage, activeSelectedImage ] = activeElement.querySelectorAll(
				`.${classes['form__select-image']}`
			);
			activeSelectedImage.classList.add('hidden');
			activeImage.classList.remove('hidden');
			activeElement.dataset.active = '0';
		}

		let notActiveElement = ev.target.parentNode;
		let [ notActiveImage, notActiveSelectedImage ] = notActiveElement.querySelectorAll(
			`.${classes['form__select-image']}`
		);
		notActiveSelectedImage.classList.remove('hidden');
		notActiveImage.classList.add('hidden');
		notActiveElement.dataset.active = '1';
    }; */

	const selectPerson = (ev, person) => {
		handleInputChange(ev);
		const searchForActiveClass = document.querySelector(`.${classes['list__item--active']}`);

		if (searchForActiveClass) {
			searchForActiveClass.classList.remove(classes['list__item--active']);
		}

		let listItems = document.querySelectorAll(`.${listClasses['list__item']}`);

		listItems.forEach((item) => {
			item.classList.add(classes['list__item--opacity-low']);
		});

		ev.currentTarget.classList.add(classes['list__item--active']);
		ev.currentTarget.parentNode.classList.remove(classes['list__item--opacity-low']);
		//setSelectedCustomer(person);
	};

	if (isFetchingGetTrialsAvailableCustomer) {
		return (
			<ComponentLoading />
		);
	}

	return (
		<div className={classes['form__select-images']}>
			{customersArray.map((item) => {
				return (
					<label className={listClasses['list__item']} htmlFor={`customer-${item.id}`} key={item.id}>
						<div className={[ listClasses['list__item-content'], listClasses['-line-height'] ].join(' ')}>
							<div className={listClasses['list__item-name']}>{item.name}</div>
							<div className={listClasses['list__item-gray']}>
								{item.address} {item.address2}, {item.city} {item.zip}{' '}
							</div>
							<div className={listClasses['list__item-gray']}>trials: {item.total_trial} </div>
						</div>
						<input
							onClick={(ev) => selectPerson(ev, item)}
							className={[ classes['list__item-radio'], 'hidden' ].join(' ')}
							type="checkbox"
							value={item.id}
							name="customerId"
							id={`customer-${item.id}`}
						/>
						<div className={classes['checkmark-circle']}>
							<i className={[ classes['checkmark-circle__checkmark'], 'las la-check' ].join(' ')} />
						</div>
					</label>
				);
			})}
		</div>
	);
};

export default CustomerView;
