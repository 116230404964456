import React, { useEffect, useState } from 'react';
import useEffectAfterFirstRender from '../../../../Hooks/useEffectAfterFirstRender';

import classes from '../EngagementEmails.module.css';

import { getTemplateEngagementEmailsAction } from '../../../../Store/actions/trials/engagementEmailsAction';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';


import { Container, Modal, ModalBody } from 'reactstrap';
import List from '../../../../Components/List/List';
import EmailPreview from '../EmailPreview/EmailPreview';
import CreateEmailNameModal from '../Modals/CreateEmailNameModal/CreateEmailNameModal';
import LoadMoreButton from '../../../../Components/LoadMoreButton/LoadMoreButton';
import ComponentLoading from '../../../../Components/ComponentLoading/ComponentLoading';
import ErrorMessage from '../../../../Components/ErrorMessage/ErrorMessage';
import NoResults from '../../../../Components/NoResults/NoResults';
import CloseModalButton from '../../../../Components/CloseModalButton/CloseModalButton';

import _ from 'lodash';

const TemplateEngagementEmailsCreate = ({ searchValue, setSearchValue }) => {
	const [ sortAlphabeticallyDetailsObject, setSortAlphabeticallyDetailsObject ] = useState({
		pathUsedToSortAlphabetically: 'name',
		order: 'asc'
	});
	const [ templateEngagementEmailsArray, setTemplateEngagementEmailsArray ] = useState([]);
	const [ pageCounterForAPI, setPageCounterForAPI ] = useState(1);

	const dispatch = useDispatch();
	const { id } = useParams();
	useEffect(() => {
		if (pageCounterForAPI === 1 && templateEngagementEmailsFromAPI.length === 0) {
			dispatch(getTemplateEngagementEmailsAction({ trialID: id, currentPageNumber: pageCounterForAPI }));
		}
	}, []);

	const isFetchingTemplateEngagementEmails = useSelector(state => state.engagementEmails.isFetchingTemplateEngagementEmails);
	const templateEngagementEmailsFromAPI = useSelector((state) => state.engagementEmails.templateEngagementEmails);
	const templateEngagementEmailsErrorFromAPI = useSelector(
		(state) => state.engagementEmails.templateEngagementEmailsError
	);
	const isLastPageInTemplateEngagementEmails = useSelector(
		(state) => state.engagementEmails.isLastPageInTemplateEngagementEmails
	);

	useEffect(
		() => {
			setTemplateEngagementEmailsArray(templateEngagementEmailsFromAPI);
		},
		[ templateEngagementEmailsFromAPI ]
	);

	useEffectAfterFirstRender(
		() => {
			dispatch(getTemplateEngagementEmailsAction({ trialID: id, currentPageNumber: pageCounterForAPI }));
		},
		[ pageCounterForAPI ]
	);

	const handleLoadMore = () => {
		setPageCounterForAPI((previousPageCounter) => previousPageCounter + 1);

		setSearchValue('');
	};

	const handleSortAlphabeticallyByTitle = (pathUsedToSortAlphabetically) => {
		if (sortAlphabeticallyDetailsObject.order === 'asc')
		{
			setSortAlphabeticallyDetailsObject({ pathUsedToSortAlphabetically, order: 'desc' });
		} else if (sortAlphabeticallyDetailsObject.order === 'desc')
		{
			setSortAlphabeticallyDetailsObject({ pathUsedToSortAlphabetically, order: 'asc' });
		}
	};

	const templateEngagementEmailsAlphabeticallySorted = _.orderBy(
		templateEngagementEmailsArray,
		[ sortAlphabeticallyDetailsObject.pathUsedToSortAlphabetically ],
		[ sortAlphabeticallyDetailsObject.order ]
	);

	const BUs = localStorage.getItem('BU');
	const filterTemplateEngagementEmails = templateEngagementEmailsAlphabeticallySorted.filter((item) => {
		if(!BUs.includes(item.bu_id)) {
			return false;
		}

		if (item.name.toLowerCase().includes(searchValue.toLowerCase().trim()))
		{
			return true;
		}

		return false;
	});

	const [ engagementEmailData, setEngagementEmailData ] = useState({})
	const [ isCreateEmailNameModalOpen, setIsCreateEmailNameModalOpen ] = useState(false);
	const toggleCreateEmailNameModal = (engagementEmailData) => {
		if (engagementEmailData?.content_json)
		{
			setEngagementEmailData(engagementEmailData);
		}
		setIsCreateEmailNameModalOpen(previousIsCreateEmailNameModalOpen => !previousIsCreateEmailNameModalOpen);
	}

	const [ isEmailPreviewModalOpen, setIsEmailPreviewModalOpen ] = useState(false);
	function toggleEmailPreviewModal (engagementEmailData) {
		if (engagementEmailData?.content_json)
		{
			setEngagementEmailData(engagementEmailData);
		}
		setIsEmailPreviewModalOpen(previousIsEmailPreviewModalOpen => !previousIsEmailPreviewModalOpen);
	}

	const actions = [
		{
			callback: toggleCreateEmailNameModal,
			link: false,
			icon: <i className="las la-pen" />
		},
		{
			callback: toggleEmailPreviewModal,
			link: false,
			icon: <i className="las la-eye" />
		}
	];

	return (
		<Container>
			<List
				list={ filterTemplateEngagementEmails }
				actions={ actions }
				sortAlphabeticallyByTitle={ handleSortAlphabeticallyByTitle }
				sortBy="name"
				view="create-template-engagement-emails" />

			{ filterTemplateEngagementEmails.length === 0 && !isFetchingTemplateEngagementEmails ? (
				<NoResults />
			) : (null) }

			{ isFetchingTemplateEngagementEmails ? (
				<ComponentLoading 
					message="Loading..."
					removeHeight={ true } />
			) : (null) }

			{ templateEngagementEmailsErrorFromAPI ? (
				<ErrorMessage message={ templateEngagementEmailsErrorFromAPI } />
			) : (null) }

			{ !isLastPageInTemplateEngagementEmails && !isFetchingTemplateEngagementEmails ? (
				<LoadMoreButton handleClick={ handleLoadMore } />
			) : (null) }

			<CreateEmailNameModal
				engagementEmailData={ engagementEmailData }
				toggleModal={ toggleCreateEmailNameModal }
				isModalOpen={ isCreateEmailNameModalOpen } />

			<Modal
				centered
				isOpen={ isEmailPreviewModalOpen }
				toggle={ toggleEmailPreviewModal }
				backdrop="static">
				<CloseModalButton onClick={ toggleEmailPreviewModal } />

				<ModalBody className={ classes[ "email-preview-modal" ] }>
					<EmailPreview emailPreview={ engagementEmailData } />
				</ModalBody>
			</Modal>
		</Container>
	);
};

export default TemplateEngagementEmailsCreate;
