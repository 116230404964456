import React, { Component } from 'react';

import classes from './ListStar.module.css';

class ListSort extends Component {
	state = {
		enabled: false
	};

	starSort = () => {
		this.setState({ enabled: this.props.sortByFavorite() });
	};

	render() {
		return (
			<i
				onClick={this.starSort}
				className={[ classes['star'], 'las la-star', this.state.enabled ? classes['enabled'] : '' ].join(' ')}
			/>
		);
	}
}

export default ListSort;
