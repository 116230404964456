const getInitialsOfFirstAndLastName = (name) => {
	if (name === undefined) return '';

	const splitName = name.toUpperCase();
	const nextSpace = splitName.indexOf(' ');
	if (nextSpace > 0 && nextSpace !== splitName.length - 1) {
		return splitName.charAt(0) + splitName.charAt(nextSpace + 1);
	}
	return splitName.charAt(0);
};

export default getInitialsOfFirstAndLastName;
