import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classes from '../FormElements.module.css';
import { getSpecialitiesAction } from '../../../../../Store/actions/trialsAction';

import ComponentLoading from '../../../../../Components/ComponentLoading/ComponentLoading';

import trialSummaryClasses from '../../../TrialSummary/TrialSummary.module.css';
import InputElementclasses from '../../../MultipleStepsModalsForm/InputElements.module.css';

import useHandleSubmit from '../../../../../Hooks/useHandleSubmit';

const SelectSpecialties = ({ 
	submittedSpecialties,
	specialties,
	getSpecialties,
	submitSpecialtySelection,
	isFetchingSpecialities
}) => {
	const [ selectedSpecialties, setSelectedSpecialties ] = useState({});
	const [ specialtiesFetched, setSpecialtiesFetched ] = useState(false);

	const selectSpecialty = (ev, specialty) => {
		//console.log(ev.currentTarget.parentNode.classList(classes['list__item--active']));
		ev.currentTarget.parentNode.classList.toggle('selected');
		const cloneSelectedSpecialties= JSON.parse(JSON.stringify(selectedSpecialties));
		if (!cloneSelectedSpecialties[specialty.id]) {
			cloneSelectedSpecialties[specialty.id] = specialty;
		} else {
			delete cloneSelectedSpecialties[specialty.id];
		}

		setSelectedSpecialties(cloneSelectedSpecialties);
	};

	useEffect(
		() => {
			getSpecialties();
			if (submittedSpecialties) setSelectedSpecialties(submittedSpecialties);
			setSpecialtiesFetched(true);
		},
		[ specialtiesFetched ]
	);

	const passBackSelectedSpecialties = () => {
		submitSpecialtySelection(selectedSpecialties);
	};

	const { handleSubmit } = useHandleSubmit({
		callback: passBackSelectedSpecialties
	});

	const type = 'specialty';

	if (isFetchingSpecialities) {
		return (
			<ComponentLoading />
		);
	}

	return (
		<form className={classes['form']} onSubmit={handleSubmit}>
			<div className={classes['form__header-row']}>
				<h2 className={classes['form__title']}>Select specialties</h2>
				<button className={classes['form__button']}>
					next <i className="las la-angle-right" />
				</button>
			</div>
			{specialties.map((item) => {
				const isSelected = selectedSpecialties[item.id];

				return (
					<label
						htmlFor={item.name}
						key={item.id}
						className={[
							trialSummaryClasses['trial-summary__image-container'],
							'specialty_select_list_item',
							submittedSpecialties[item.id] ? classes['list__item--active'] : ''
						].join(' ')}
					>
						<span
							className={[
								InputElementclasses['form__select-image-border'],
								isSelected ? trialSummaryClasses['trial-summary__card-image--active'] : null
							].join(' ')}
						>
							<img
								className={[
									trialSummaryClasses['trial-summary__card-image--specialty'],
									isSelected ? ("") : ("hidden")
								].join(' ')}
								src={item.icon_selected}
								alt={item.name}
							/>
							<img
								className={[
									trialSummaryClasses['trial-summary__card-image--specialty'],
									isSelected ? ("hidden") : ("")
								].join(' ')}
								src={item.icon}
								alt={item.name}
							/>
						</span>

						<input
							onClick={(ev) => selectSpecialty(ev, item)}
							value={item.name}
							id={item.name}
							className="hidden"
							name={type}
							type="checkbox"
						/>
						<span className={ [ trialSummaryClasses[ 'trial-summary__card-name' ], isSelected ? (trialSummaryClasses[ 'trial-summary__card-name--active' ]) : ('') ].join(' ')}>
							{item.name}
						</span>
					</label>
				);
			})}
		</form>
	);
};

const mapStateToProps = (state) => {
	if (state.trials.specialitiesArray.speciality) {
		return {
			specialties: state.trials.specialitiesArray.speciality,
			isFetchingSpecialities: state.trials.isFetchingSpecialities
		};
	} else {
		return {
			specialties: [],
			isFetchingSpecialities: state.trials.isFetchingSpecialities
		};
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		getSpecialties: () => {
			dispatch(getSpecialitiesAction({}));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SelectSpecialties));
