import React from 'react';
import classes from './LoadMoreButton.module.css';

const LoadMoreButton = (props) => {
    const { handleClick } = props;
    return (
        <div
            className={ classes[ "button-container" ] }>
            <button
                className={ classes[ "button" ] }
                onClick={ handleClick }>
                Load More
            </button>
        </div>
    );
}

export default LoadMoreButton;