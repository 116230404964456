import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCompletedSurveyAction } from '../../../../Store/actions/surveysAction';
import FormQuestions from '../FormQuestions/FormQuestions';


const QuestionsAnswered = ({ surveyAndEvaluationInformation }) => {
    const { trialID, personType, personID, surveyAndEvaluationID } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCompletedSurveyAction({ trialID, personType, personID, templateID: surveyAndEvaluationID }));
    }, []);

    const completedEvaluation = useSelector(state => state.surveysAndEvaluations.completedEvaluation);

    return (
        <FormQuestions answeredQuestionsArray={ completedEvaluation?.evaluation_data } questionsArray={ surveyAndEvaluationInformation?.question_list } />
    )
}

export default QuestionsAnswered;