import React from 'react';

import AddChecklistClasses from '../../TrialChecklist/AddChecklist/AddChecklist.module.css';
import classes from './SendTestEmail.module.css';

import useHandleSubmit from '../../../../Hooks/useHandleSubmit';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sendTestEngagementEmailAction } from '../../../../Store/actions/trials/engagementEmailsAction';

const SendTestEmail = ({ toggleModal }) => {
    const handleCancel = (event) => {
        event.preventDefault();
        toggleModal();
    }

    const dispatch = useDispatch();
    const { id, engagementEmailID } = useParams();
    const handleFormSubmit = () => {
        let emails = inputs.email.split(',');

        for (let i = 0; i < emails.length; i++)
        {
            dispatch(sendTestEngagementEmailAction({ trialID: id, userTemplateID: engagementEmailID, email: emails[ i ] }));
        }
        toggleModal();
    }

    const { inputs, handleInputChange, handleSubmit } = useHandleSubmit({
        callback: handleFormSubmit,
        initialState: {
            email: ''
        }
    });

    const sendTestEngagementEmailError = useSelector((state) => state.engagementEmails.sendTestEngagementEmailError);

    return (
        <form className={ AddChecklistClasses[ "form" ] }>
            <label
                className={ AddChecklistClasses[ "form__label" ] }
                htmlFor="email">
                Send a test email
            </label>
            <input
                onChange={ handleInputChange }
                value={ inputs.email }
                className={ AddChecklistClasses[ "form__input" ] }
                placeholder="Enter email addresses separated by commas"
                name="email"
                id="email"
                type="email"
                required
                multiple />

            <div className={ AddChecklistClasses[ "form__buttons-container" ] }>
                <button
                    onClick={ handleCancel }
                    className={ [ AddChecklistClasses[ "form__button" ], AddChecklistClasses[ "form__button--cancel" ] ].join(" ") }>
                    cancel
                </button>

                <button
                    onSubmit={ handleSubmit }
                    onClick={ handleSubmit }
                    className={ [ AddChecklistClasses[ "form__button" ], classes[ "form__button--submit" ] ].join(" ") }>
                    send
                </button>
            </div>
        </form>
    );
}


export default SendTestEmail;