import React, { Component } from 'react';
import classes from './NavBar.module.css';
import { withRouter } from 'react-router-dom';

class NavBar extends Component {
	handleGoBack = () => {
		this.props.history.goBack();
	};

	render() {
		const params = new URLSearchParams(this.props.location.search);
		const title = params.get('title');

		const page = params.get('page');
		let filePath = params.get('filePath');
		if (!filePath) {
			filePath = params.get('file');
		}

		return (
			<div className={classes['navbar-container']}>
				<div className={classes['navbar']}>
					<div className={classes['navbar__back-button-container']} onClick={this.handleGoBack}>
						<i className={[ classes['navbar__back-button-arrow'], 'las la-angle-left' ].join(' ')} />
						<span className={classes['navbar__back-button-text']}>BACK</span>
					</div>

					<div className={classes['navbar__title']}>{title}</div>

					{page !== 'CI' ? (
						<a href={`mailto:?subject=${title}&body=${filePath}`}>
							<i className={[ classes['navbar__mail-button'], 'las la-envelope' ].join(' ')} />
						</a>
					) : (
						''
					)}
				</div>
				<div className={classes['navbar-gradient']} />
			</div>
		);
	}
}

export default withRouter(NavBar);
