import React from 'react';

import LogoPreview from '../../../Shared/LogoPreview/LogoPreview';
import Footer from '../../../Shared/Footer/Footer';
import TrialStatistic from '../../../../../../Components/TrialStatistic/TrialStatistic';
import EngagementEmailVerbatim from '../../../../../../Components/EngagementEmailVerbatim/EngagementEmailVerbatim';
import DisplayChart from '../../../../../../Components/DisplayChart/DisplayChart';
import DisplayBarChart from '../../../../../../Components/DisplayBarChart/DisplayBarChart';

import classes from '../../../EmailPreview/TrialRecapUpdate/TrialRecapUpdate.module.css';
import emailPreviewClasses from '../../../EmailPreview/EmailPreview.module.css';
import customizeEmailClasses from '../../CustomizeEmail.module.css';

const TrialRecapUpdateCommunications = ({ emailPreview }) => {
    const emailPreviewContent = emailPreview?.content.split(/[\r\n]+/g);

    const BASE_URL = process.env.REACT_APP_API_URL;
    let facilityImage = BASE_URL + emailPreview.facility_image;
    let facilityLogo = BASE_URL + emailPreview.facility_logo;

    if (emailPreview.facility_image === "")
    {
        facilityImage = '/images/email/facility-image-placeholder.png';
    }

    if (emailPreview.facility_logo === "")
    {
        facilityLogo = '/images/email/facility-logo-placeholder.png';
    }

    return (
        <div>
            <LogoPreview />
            <div>
                <div className={ classes[ "trial-recap__header" ] }>
                    <div className={ classes[ "trial-recap__header-item" ] }>

                        <div className={ classes[ "trial-recap__header-heading" ] }>
                            { emailPreview.title }
                        </div>
                        <div className={ classes[ "trial-recap__header-heading-subtext" ] }>
                            { emailPreview.sub_title }
                        </div>
                        <img className={ classes[ "trial-recap__header-image" ] } src="/images/email/customize-email-bg-1.jpg" alt="rectangles" />
                    </div>

                    <div className={ classes[ "trial-recap__header-item" ] }>
                        <div className={ classes[ "trial-recap__header-image-container" ] }>
                            <img
                                className={ classes[ "trial-recap__header-image" ] }
                                src={ facilityImage }
                                alt="facility image" />
                        </div>
                        <div className={ classes[ "trial-recap__header-image-container" ] }>
                            <img
                                className={ classes[ "trial-recap__header-image" ] }
                                src={ facilityLogo }
                                alt="facility logo" />
                        </div>
                    </div>
                </div>
            </div>
            <p className={ [ classes[ "trial-recap__quote" ], "border-0" ].join(" ") }>
                { emailPreview.title_description }
            </p>

            <div className={ classes[ "trial-recap__paragraphs-container" ] }>
                { emailPreviewContent?.map((item, index) => {
                    return (
                        <p
                            key={ index }
                            className={ emailPreviewClasses[ "email-preview__paragraph" ] }>
                            { item }
                        </p>
                    );
                }) }
            </div>

            { emailPreview?.statistic_is_on === "1" ? (
                <div className={ classes[ "trial-recap__counters-container" ] }>
                    { emailPreview?.statistic.length > 0?emailPreview.statistic.map(statistic => {
                        return (
                            <TrialStatistic
                                key={ statistic.slug }
                                trialStatistic={ statistic }
                                removeButtons={ true } />
                        );
                    }):'' }
                </div>
            ) : (null) }

            { emailPreview?.verbatim_is_on === "1" ? (
                <div className={ classes[ "trial-recap__person-quote-container" ] }>
                    { emailPreview?.verbatim.length> 0?emailPreview.verbatim.map(verbatimComment => {
                        return (
                            <EngagementEmailVerbatim
                                key={ verbatimComment.comment_id }
                                verbatimComment={ verbatimComment }
                                removeButtons={ true } />
                        );
                    }): '' }
                </div>
            ) : (null) }

            { emailPreview?.weekly_statistic_is_on === "1" ? (
                <div className={ classes[ "trial-recap__statistics" ] }>
                    <div className={ classes[ "trial-recap__statistics-wrapper" ] }>
                        <h2 className={ classes[ "trial-recap__statistics-wrapper-title" ] }>
                            Trial <span className={ classes[ "trial-recap__statistics-wrapper-subtext" ] }>statistics</span>
                        </h2>
                        <div className={ [ emailPreviewClasses[ "ribbon" ], "m-0" ].join(" ") }></div>

                        <div className={ customizeEmailClasses[ "weekly-engagement__charts-preview" ] }>
                            { emailPreview?.weekly_statistic.length>0?emailPreview.weekly_statistic.map(weeklyStatistic => {
                                return weeklyStatistic.questions_type === "rating" ? (
                                    <DisplayBarChart
                                        key={ weeklyStatistic.id }
                                        chartStatistics={ weeklyStatistic }
                                        removeButtons={ true }
                                        onConfirmAndReviewPage={ true } />
                                ) : (
                                        <DisplayChart
                                            key={ weeklyStatistic.id }
                                            chartStatistics={ weeklyStatistic }
                                            removeButtons={ true }
                                            onConfirmAndReviewPage={ true } />
                                    );
                            }):'' }
                        </div>
                    </div>
                </div>
            ) : (null) }

            <Footer text={ emailPreview.footer } />
        </div>
    );
}

export default TrialRecapUpdateCommunications;