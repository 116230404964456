import React from 'react';
import { Col } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import '../assets/css/app.css';

const Organization = (props) => {
	const { trialName } = props;
	const { id } = useParams();

	return (
		<div className="tab-section">
			<Col className="tab-tabs">
				<div className="d-board-sect-title pb-1">Organization</div>
				<div className="toolkit">
					<div className="d-board-item">
						<Link to={`/trial/${id}/organization/people`}>
							<div className="d-board-thumb people" />
							<div className="">PEOPLE</div>
						</Link>
					</div>
					<div className="d-board-item">
						<Link to={ `/trial/${ id }/organization/cases/` }>
							<div className="d-board-thumb cases" />
							<div className="">CASES</div>
						</Link>
					</div>
					<div className="d-board-item">
						<Link to={`/trial/${id}/organization/checklist/`} className="">
							<div className="d-board-thumb checklist" />
							<div className="">CHECKLISTS</div>
						</Link>
					</div>
				</div>
				<div className="toolkit">
					<div className="d-board-item">
						<Link to={`/trial/${id}/organization/trial-summary`}>
							<div className="d-board-thumb trialsummary" />
							<div>TRIAL SUMMARY</div>
						</Link>
					</div>
					<div className="d-board-item">
						{/*  <Link to={ "/trials/" + trialName + "/Organization/notes" }>
                            <div className="d-board-thumb notes" />
                            <div>NOTES</div>
                        </Link> */}
					</div>
					<div className="d-board-item" />
				</div>
			</Col>
		</div>
	);
};

export default Organization;
