const colors = [
    "#AF6D08",
    "#85458A",
    "#1C5687",
    "#4C7D7A",
    "#545857",
    "#EFE2CD",
    "#E7DAE8",
    "#C9DDE7",
    "#DBE5E4",
    "#DDDEDD",
    "#CFA768",
    "#B68FB9",
    "#779AB7",
    "#94B1AF",
    "#989B9A",
    "#BF8A36",
    "#9D6AA1",
    "#49789F",
    "#709795",
    "#767979"
];


const createChartColors = () => {
    return colors;
}

export default createChartColors;