import React from 'react';

import ChartButtons from '../ChartButtons/ChartButtons';

import classes from './TrialStatistic.module.css';

const TrialStatistic = ({ trialStatistic, handleRemoveTrialStatistic, handleChangeTrialStatistic, removeButtons = false }) => {
    return (
        <div className={ classes[ "trial-statistic" ] }>
            <div className={ classes[ "trial-statistic__number" ] }>
                { trialStatistic.count || trialStatistic.number }
            </div>
            <div className={ classes[ "trial-statistic__text" ] }>
                { trialStatistic.label }
            </div>
            <div className={ classes[ "trial-statistic__subtext" ] }>
                { trialStatistic.description }
            </div>
            {removeButtons ? (null) : (
                <div className={ classes[ "trial-statistic__buttons" ] }>
                    <ChartButtons removeFunction={ handleRemoveTrialStatistic } swapFunction={ handleChangeTrialStatistic } />
                </div>
            ) }
        </div>
    );
}

export default TrialStatistic;